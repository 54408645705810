import { createAsyncAction, createAction } from 'typesafe-actions';
import { EventClickArg, DateSelectArg } from '@fullcalendar/react';
import { EventDetailType } from 'store/schedule/types';

import {
  GetSlotDataRequestPayload,
  SlotCreationPayloadType,
  SlotUpdationPayloadType,
  TeacherAvailabilityPayloadType,
  TeacherAvailabilityType,
  TimeTableDataType,
  WeeksList,
  SlotUpdationSuccessType,
  SlotDeleteType,
} from './types';

export const loadTimeTableData = createAsyncAction(
  '@@timeTableData/LOAD_REQUEST',
  '@@timeTableData/LOAD_SUCCESS',
  '@@timeTableData/LOAD_FAILURE',
)<GetSlotDataRequestPayload, TimeTableDataType, string>();

export const currentSemesterWeeks = createAsyncAction(
  '@@timeTableWeekData/LOAD_REQUEST',
  '@@timeTableWeekData/LOAD_SUCCESS',
  '@@timeTableWeekData/LOAD_FAILURE',
)<void, WeeksList, string, null>();

export const createTimeTableSlot = createAsyncAction(
  '@@timeTableSlotCreation/LOAD_REQUEST',
  '@@timeTableSlotCreation/LOAD_SUCCESS',
  '@@timeTableSlotCreation/LOAD_FAILURE',
)<SlotCreationPayloadType, void, string>();

export const updateTimeTableSlot = createAsyncAction(
  '@@timeTableSlotUpdation/LOAD_REQUEST',
  '@@timeTableSlotUpdation/LOAD_SUCCESS',
  '@@timeTableSlotUpdation/LOAD_FAILURE',
)<SlotUpdationPayloadType, SlotUpdationSuccessType, string>();

export const loadTeacherAvailability = createAsyncAction(
  '@@timeTableDataTeacherAvailability/LOAD_REQUEST',
  '@@timeTableDataTeacherAvailability/LOAD_SUCCESS',
  '@@timeTableDataTeacherAvailability/LOAD_FAILURE',
)<TeacherAvailabilityPayloadType, TeacherAvailabilityType, TeacherAvailabilityType>();

export const loadPublishStatus = createAsyncAction(
  '@@loadPublishStatus/LOAD_REQUEST',
  '@@loadPublishStatus/LOAD_SUCCESS',
  '@@loadPublishStatus/LOAD_FAILURE',
)<GetSlotDataRequestPayload, boolean, string>();

export const resetCurrentSemesterWeeks = createAction('@@resetCurrentSemesterWeeks')();
export const enableResetButton = createAction('@@enableResetButton')();
export const deleteSlot = createAction('@@deleteSlot')<SlotDeleteType>();

export const resetTeacherDetail = createAction('@@resetTeacherDetail')();
export const resetTimetableData = createAction('@@resetTimetableData')();

export const setActiveTab = createAction('@@gradeWiseSchedule/ACTIVE_TAB')<{ activeTab: number }>();

export const setSelectedEvent = createAction('@@gradeWiseSchedule/SELECTED_EVENT')<{
  selectedEvent: EventClickArg | null;
}>();

export const setCloseSlotModal = createAction('@@gradeWiseSchedule/CLOSE_SLOT_MODAL')<{
  selectedSlot: DateSelectArg | null;
  editEvent: EventDetailType | null;
  showSlotModal: boolean;
}>();

export const setSelectedSlot = createAction('@@gradeWiseSchedule/SELECTED_SLOT')<{
  selectedSlot: DateSelectArg | null;
  showSlotModal: boolean;
}>();

export const setEditSlot = createAction('@@gradeWiseSchedule/EDIT_SLOT')<{
  editEvent: EventDetailType | null;
  showSlotModal: boolean;
  showDeleteSlot: boolean;
}>();
