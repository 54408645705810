import styled, { css } from 'styled-components';
import { customScroll } from 'modules/GradeOneToThree/styled-mixin';

type NavbarListItemProps = {
  isActive?: boolean;
};

export const Root = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 11px;
  display: flex;
  justify-content: flex-end;
`;

export const NavbarList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  max-width: max-content;
  border: solid 4px ${(p) => p.theme.colors.navigation.border};
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.navigation.background};
  ${customScroll}
`;

export const NavbarListItem = styled.li<NavbarListItemProps>`
  font-size: 1.3rem;
  font-weight: bold;
  font-family: ${(p) => p.theme.font.family.children};
  padding: 12px 14px;
  justify-content: center;
  display: flex;
  letter-spacing: -0.55px;
  align-items: center;
  height: 50px;
  cursor: pointer;
  gap: 5px;

  span {
    display: inline-block;
    white-space: nowrap;

    &:hover {
      color: ${(p) => p.theme.colors.navigation.activeColor};
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${(p) => p.theme.colors.navigation.activeColor};
      span {
        color: ${(p) => p.theme.colors.navigation.activeColor};
      }
    `};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      font-family: ${(p) => p.theme.font.family.arabic};
      font-size: ${(p) => p.theme.font.size.secondary};

      &:last-child {
        border-right: 0;
        border-left: 0;
      }
    `};
`;
