import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  getExamsAPI,
  getStudentsScoreListAPI,
  getStudentSubjectsScoreListAPI,
} from 'utils/apiEndpoints';

import { TGradeMappingPayload, TStudentScorePayload, TStudentSubjectsScorePayload } from './types';

export async function fetchGradeMappingsAPI(payload: TGradeMappingPayload) {
  const { branch, ...params } = payload;
  const response = await API.get(getExamsAPI(branch || 0), {
    params: snakecaseKeys(params, { deep: true }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchStudentsScoreListAPI({ examId, ...payload }: TStudentScorePayload) {
  const response = await API.get(getStudentsScoreListAPI(examId), {
    params: snakecaseKeys(payload, { deep: true }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchStudentsSubjectScoreListAPI({
  gradeID,
  semesterID,
  branch,
  ...payload
}: TStudentSubjectsScorePayload) {
  const response = await API.get(getStudentSubjectsScoreListAPI(gradeID, semesterID, branch), {
    params: snakecaseKeys(payload, { deep: true }),
  });
  return camelcaseKeys(response.data, { deep: true });
}
