import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getLearningProfiles } from './api';
import { loadLearningProfilesAction } from './action';

function* loadLearningProfiles(): SagaIterator<void> {
  try {
    const data = yield call(getLearningProfiles);
    yield put(loadLearningProfilesAction.success(data));
  } catch (error) {
    yield put(loadLearningProfilesAction.failure(error));
  }
}

function* learningProfilesSaga(): SagaIterator<void> {
  yield all([takeEvery(loadLearningProfilesAction.request, loadLearningProfiles)]);
}

export default learningProfilesSaga;
