import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { TeacherType } from 'store/shoolLists/types';
import {
  loadB2BTeacherStats,
  loadB2BTeacherStudentsList,
  loadB2BTeacherLiveSessions,
  loadB2BTeacherClassRoomOptions,
  loadB2BTeacherStudentProfile,
  studentAttachment,
  loadB2BTeacherStudentsListFilter,
} from './actions';
import {
  getB2bTeacherStats,
  getB2bTeacherStudentList,
  getB2bTeacherSessionList,
  getB2bTeacherClassRoomOptions,
  getB2bTeacherStudentProfile,
  postTeacherStudentAttachment,
} from './api';
import { StudentListResponse } from './types';

function* loadTeacherStatsRequest({
  payload,
}: ReturnType<typeof loadB2BTeacherStats.request>): SagaIterator<void> {
  try {
    const result: any = yield call(getB2bTeacherStats, payload);
    yield put(loadB2BTeacherStats.success(result));
  } catch (error) {
    yield put(loadB2BTeacherStats.failure(error));
  }
}

function* loadTeacherStudentsListRequest({
  payload,
}: ReturnType<typeof loadB2BTeacherStudentsList.request>): SagaIterator<void> {
  try {
    const list: StudentListResponse = yield call(getB2bTeacherStudentList, payload);
    yield put(loadB2BTeacherStudentsList.success(list));
  } catch (error) {
    yield put(loadB2BTeacherStudentsList.failure(error));
  }
}

function* loadTeacherStudentsListFilterRequest({
  payload,
}: ReturnType<typeof loadB2BTeacherStudentsListFilter.request>): SagaIterator<void> {
  try {
    const list: Array<TeacherType> = yield call(getB2bTeacherStudentList, payload);
    yield put(loadB2BTeacherStudentsListFilter.success(list));
  } catch (error) {
    yield put(loadB2BTeacherStudentsListFilter.failure(error));
  }
}

function* loadB2BTeacherLiveSessionsRequest({
  payload,
}: ReturnType<typeof loadB2BTeacherLiveSessions.request>): SagaIterator<void> {
  try {
    const list: any = yield call(getB2bTeacherSessionList, payload);
    yield put(loadB2BTeacherLiveSessions.success(list));
  } catch (error) {
    yield put(loadB2BTeacherLiveSessions.failure(error));
  }
}

function* loadB2BTeacherStudentClassRoomOptionsRequest({
  payload,
}: ReturnType<typeof loadB2BTeacherClassRoomOptions.request>): SagaIterator<void> {
  try {
    const list: any = yield call(getB2bTeacherClassRoomOptions, payload);
    yield put(loadB2BTeacherClassRoomOptions.success(list));
  } catch (error) {
    yield put(loadB2BTeacherClassRoomOptions.failure(error));
  }
}

function* loadB2BTeacherStudentProfileRequest({
  payload,
}: ReturnType<typeof loadB2BTeacherStudentProfile.request>): SagaIterator<void> {
  try {
    const list: any = yield call(getB2bTeacherStudentProfile, payload);
    yield put(loadB2BTeacherStudentProfile.success(list));
  } catch (error) {
    yield put(loadB2BTeacherStudentProfile.failure(error));
  }
}

function* b2bTeacherStudentAttachment(): SagaIterator {
  yield all([takeEvery(studentAttachment.request, postTeacherStudentAttachment)]);
}

function* b2bTeacherSaga(): SagaIterator {
  yield all([takeEvery(loadB2BTeacherStats.request, loadTeacherStatsRequest)]);
  yield all([
    takeEvery(loadB2BTeacherStudentsListFilter.request, loadTeacherStudentsListFilterRequest),
  ]);
  yield all([takeEvery(loadB2BTeacherStudentsList.request, loadTeacherStudentsListRequest)]);
  yield all([takeEvery(loadB2BTeacherLiveSessions.request, loadB2BTeacherLiveSessionsRequest)]);
  yield all([
    takeEvery(loadB2BTeacherClassRoomOptions.request, loadB2BTeacherStudentClassRoomOptionsRequest),
  ]);
  yield all([takeEvery(loadB2BTeacherStudentProfile.request, loadB2BTeacherStudentProfileRequest)]);
  yield all([takeEvery(studentAttachment.request, b2bTeacherStudentAttachment)]);
}

export default b2bTeacherSaga;
