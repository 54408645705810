import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  loadSemestersAction,
  loadNextYearSemestersAction,
  fetchSemesterWeeksAction,
} from './actions';
import { getSemestersDetail, getNextYearSemestersDetail, fetchSemesterWeeksAPI } from './api';
import { SemesterType, SemesterWeekType } from './types';

function* loadSemestersRequest(): SagaIterator<void> {
  try {
    const semesters: SemesterType[] = yield call(getSemestersDetail);
    yield put(loadSemestersAction.success(semesters));
  } catch (error) {
    yield put(loadSemestersAction.failure(error));
  }
}

function* loadNextYearSemestersRequest(): SagaIterator<void> {
  try {
    const semesters: SemesterType[] = yield call(getNextYearSemestersDetail);
    yield put(loadNextYearSemestersAction.success(semesters));
  } catch (error) {
    yield put(loadNextYearSemestersAction.failure(error));
  }
}

function* fetchSemesterWeeksSaga({
  payload,
}: ReturnType<typeof fetchSemesterWeeksAction.request>): SagaIterator<void> {
  try {
    const weeks: SemesterWeekType[] = yield call(fetchSemesterWeeksAPI, payload);
    yield put(fetchSemesterWeeksAction.success(weeks));
  } catch (error) {
    yield put(fetchSemesterWeeksAction.failure(error));
  }
}

function* semestersSaga(): SagaIterator {
  yield all([
    takeEvery(loadSemestersAction.request, loadSemestersRequest),
    takeEvery(loadNextYearSemestersAction.request, loadNextYearSemestersRequest),
    takeLatest(fetchSemesterWeeksAction.request, fetchSemesterWeeksSaga),
  ]);
}

export default semestersSaga;
