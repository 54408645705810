import { createReducer, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';

import { TPaginatedResult } from 'store/common';

import {
  fetchGradeMappingsAction,
  addGradeMappingAction,
  updateGradeMappingAction,
  fetchStudentsScoreAction,
  updateStudentScoreAction,
  updateScorePublishStatusAction,
  markAllScoresPublishedAction,
  clearStudentScoresAction,
  fetchStudentSubjectsScoreAction,
  updateIssueCertificateStatusAction,
} from './actions';
import * as actions from './actions';
import { TGradingExam, TStudentScore, TStudentScoreExam, TStudentSubjectScore } from './types';

type TGradingSystemActions = ActionType<typeof actions>;

const gradeMappings = combineReducers({
  data: createReducer<TPaginatedResult<TGradingExam>, TGradingSystemActions>({
    results: [] as TGradingExam[],
  } as TPaginatedResult<TGradingExam>)
    .handleAction(fetchGradeMappingsAction.success, (_, { payload }) => payload)
    .handleAction(fetchGradeMappingsAction.failure, () => ({} as TPaginatedResult<TGradingExam>))
    .handleAction(actions.updateExamScoreStatusAction, (state, { payload: { id, status } }) => {
      const updatedResult = state.results.map((mapping) => {
        if (mapping.id === id) {
          const updatedMapping = { ...mapping, status };
          return { ...updatedMapping };
        }
        return mapping;
      });
      return { ...state, results: updatedResult };
    })
    .handleAction(addGradeMappingAction, (state, action) => {
      const { currentPage, results } = state;
      if ((currentPage === 1 || !currentPage) && results) {
        const newResults =
          action.payload.pageSize > results.length ? results : results.slice(0, -1);
        // only add at top of first page if new mapping added
        const updatedResult = [action.payload, ...newResults];
        return { ...state, results: updatedResult };
      }
      return state;
    })
    .handleAction(updateGradeMappingAction, (state, { payload }) => {
      const updatedResult = state.results?.map((gradeMapping) =>
        gradeMapping.id === payload.id ? payload : gradeMapping,
      );
      return { ...state, results: updatedResult };
    }),
  loading: createReducer<boolean, TGradingSystemActions>(true)
    .handleAction(fetchGradeMappingsAction.request, () => true)
    .handleAction(
      [fetchGradeMappingsAction.success, fetchGradeMappingsAction.failure],
      () => false,
    ),
  error: createReducer<string, TGradingSystemActions>('')
    .handleAction(fetchGradeMappingsAction.failure, (_, { payload }) => payload)
    .handleAction([fetchGradeMappingsAction.request, fetchGradeMappingsAction.success], () => ''),
});

const studentsScore = combineReducers({
  data: createReducer<TPaginatedResult<TStudentScore>, TGradingSystemActions>(
    {} as TPaginatedResult<TStudentScore>,
  )
    .handleAction(fetchStudentsScoreAction.success, (_, { payload }) => payload)
    .handleAction(
      [fetchStudentsScoreAction.failure, clearStudentScoresAction],
      () => ({} as TPaginatedResult<TStudentScore>),
    )
    .handleAction(updateStudentScoreAction, (state, { payload }) => {
      const updatedResult = state.results?.map((score) => {
        // same student is assumed since we are updating for single student in one dialog
        if (payload.length && score.id === payload[0].student) {
          let allExams = [];
          if (score.exams.length) {
            const examsToUpdate: Record<number, TStudentScoreExam> = payload.reduce((acc, item) => {
              acc[`${item.student}-${item.teacherGradingExam}`] = item;
              return acc;
            }, {});

            const updatedExams = score.exams.length
              ? score.exams.map((exam) => {
                  const key = `${exam.student}-${exam.teacherGradingExam}`;
                  if (examsToUpdate[key]) {
                    const updatedExam = { ...exam, ...examsToUpdate[key] };
                    delete examsToUpdate[key];
                    return updatedExam;
                  }
                  return exam;
                })
              : payload;
            const remainingScores = Array.from(Object.values(examsToUpdate));
            allExams = updatedExams.concat(remainingScores);
          } else {
            allExams = payload;
          }

          const averageScore = allExams.length
            ? allExams.reduce((acc, item) => acc + item.weightedScore, 0)
            : 0;
          return { ...score, exams: allExams, averageScorePercentage: averageScore };
        }
        return score;
      });
      return { ...state, results: updatedResult };
    })
    .handleAction(updateScorePublishStatusAction, (state, { payload: { studentID } }) => {
      const updatedResult = state.results.map((score) => {
        if (score.id === studentID) {
          const updatedExams = score.exams.map((item) => ({ ...item, isPublished: true }));
          return { ...score, exams: updatedExams };
        }
        return score;
      });
      return { ...state, results: updatedResult };
    })
    .handleAction(markAllScoresPublishedAction, (state) => {
      const updatedResult = state.results.map((score) => {
        const updatedExams = score.exams.map((item) => ({ ...item, isPublished: true }));
        return { ...score, exams: updatedExams };
      });
      return { ...state, results: updatedResult };
    }),
  loading: createReducer<boolean, TGradingSystemActions>(true)
    .handleAction(fetchStudentsScoreAction.request, () => true)
    .handleAction(
      [fetchStudentsScoreAction.success, fetchStudentsScoreAction.failure],
      () => false,
    ),
  error: createReducer<string, TGradingSystemActions>('')
    .handleAction(fetchStudentsScoreAction.failure, (_, { payload }) => payload)
    .handleAction([fetchStudentsScoreAction.request, fetchStudentsScoreAction.success], () => ''),
});

const studentSubjectsScore = combineReducers({
  data: createReducer<TPaginatedResult<TStudentSubjectScore>, TGradingSystemActions>(
    {} as TPaginatedResult<TStudentSubjectScore>,
  )
    .handleAction(fetchStudentSubjectsScoreAction.success, (_, { payload }) => payload)
    .handleAction(
      [fetchStudentsScoreAction.failure, clearStudentScoresAction],
      () => ({} as TPaginatedResult<TStudentSubjectScore>),
    )
    .handleAction(updateIssueCertificateStatusAction, (state, payload) => {
      const results = state.results?.map((score) =>
        score.id === payload.payload.studentID ? { ...score, isCertificateIssued: true } : score,
      );
      return { ...state, results };
    }),
  loading: createReducer<boolean, TGradingSystemActions>(true)
    .handleAction(fetchStudentSubjectsScoreAction.request, () => true)
    .handleAction(
      [fetchStudentSubjectsScoreAction.success, fetchStudentSubjectsScoreAction.failure],
      () => false,
    ),
  error: createReducer<string, TGradingSystemActions>('')
    .handleAction(fetchStudentSubjectsScoreAction.failure, (_, { payload }) => payload)
    .handleAction(
      [fetchStudentSubjectsScoreAction.request, fetchStudentSubjectsScoreAction.success],
      () => '',
    ),
});

const gradingSystemReducer = combineReducers({
  gradeMappings,
  studentsScore,
  studentSubjectsScore,
});

export default gradingSystemReducer;
