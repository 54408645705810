import { createAction, createAsyncAction } from 'typesafe-actions';
import { GenericCallbackType } from 'utils/types';
import {
  AccountType,
  LoginType,
  LoginOtpType,
  ResetPasswordPayloadType,
  SetPasswordType,
  UpdateAccountType,
  AccountFieldTypes,
  ForgetPasswordPayloadType,
  UploadAvatarPayloadType,
  OtpVerifyRequestType,
  OtpVerifyResponseType,
  GenerateOtpRequestType,
  GenerateOtpResponseType,
  OTPDataType,
  OtpVerifyPayloadType,
} from './types';
import { SubscriptionSate } from './adapter';
import { OtpSendRequestType } from '../registration/types';

export const loadAccountAction = createAsyncAction(
  '@@account/LOAD_REQUEST',
  '@@account/LOAD_SUCCESS',
  '@@account/LOAD_FAILURE',
)<undefined, AccountType, string>();

export const updateAccountAction = createAsyncAction(
  '@@account/UPDATE_REQUEST',
  '@@account/UPDATE_SUCCESS',
  '@@account/UPDATE_FAILURE',
)<Partial<UpdateAccountType>, Partial<AccountType>, string>();

export const inviteParentAction = createAsyncAction(
  '@@accountInviteParent/UPDATE_REQUEST',
  '@@accountInviteParent/UPDATE_SUCCESS',
  '@@accountInviteParent/UPDATE_FAILURE',
)<string, Partial<AccountType>, string>();

export const updateAccountPhoneAction = createAsyncAction(
  '@@accountPhone/UPDATE_REQUEST',
  '@@accountPhone/UPDATE_SUCCESS',
  '@@accountPhone/UPDATE_FAILURE',
)<Partial<AccountType>, Partial<AccountType>, string>();

export const updateAccountFirstNameAction = createAsyncAction(
  '@@accountFirstName/UPDATE_REQUEST',
  '@@accountFirstName/UPDATE_SUCCESS',
  '@@accountFirstName/UPDATE_FAILURE',
)<Partial<AccountType>, Partial<AccountType>, string>();

export const updateAccountLastNameAction = createAsyncAction(
  '@@accountLastName/UPDATE_REQUEST',
  '@@accountLastName/UPDATE_SUCCESS',
  '@@accountLastName/UPDATE_FAILURE',
)<Partial<AccountType>, Partial<AccountType>, string>();

export const updateAccountSubscription = createAsyncAction(
  '@@accountSubscription/UPDATE_REQUEST',
  '@@accountSubscription/UPDATE_SUCCESS',
  '@@accountSubscription/UPDATE_FAILURE',
)<undefined, SubscriptionSate, string>();

export const uploadAvatarAction = createAsyncAction(
  '@@account/UPLOAD_AVATAR_REQUEST',
  '@@account/UPLOAD_AVATAR_SUCCESS',
  '@@account/UPLOAD_AVATAR_FAILURE',
)<UploadAvatarPayloadType, AccountType, string>();

export const resetPasswordAction = createAsyncAction(
  '@@account/RESET_PASSWORD_REQUEST',
  '@@account/RESET_PASSWORD_SUCCESS',
  '@@account/RESET_PASSWORD_FAILURE',
)<ResetPasswordPayloadType, undefined, string>();

export const resetPasswordActionV2 = createAsyncAction(
  '@@account/RESET_PASSWORD_V2_REQUEST',
  '@@account/RESET_PASSWORD_V2_SUCCESS',
  '@@account/RESET_PASSWORD_V2_FAILURE',
)<ForgetPasswordPayloadType, undefined, string>();

export const forgetPasswordActionV2 = createAsyncAction(
  '@@account/FORGET_PASSWORD_REQUEST',
  '@@account/FORGET_PASSWORD_SUCCESS',
  '@@account/FORGET_PASSWORD_FAILURE',
)<ResetPasswordPayloadType, undefined, string>();

export const setPasswordAction = createAsyncAction(
  '@@account/SET_PASSWORD_REQUEST',
  '@@account/SET_PASSWORD_SUCCESS',
  '@@account/SET_PASSWORD_FAILURE',
)<SetPasswordType, undefined, string>();

export const loginAction = createAsyncAction(
  '@@account/LOGIN_REQUEST',
  '@@account/LOGIN_SUCCESS',
  '@@account/LOGIN_FAILURE',
)<LoginType, any, string>();

export const loginOtpAction = createAsyncAction(
  '@@account/LOGIN_OTP_REQUEST',
  '@@account/LOGIN_OTP_SUCCESS',
  '@@account/LOGIN_OTP_FAILURE',
)<LoginOtpType, any, string>();

export const logoutAction = createAsyncAction(
  '@@account/LOGOUT_REQUEST',
  '@@account/LOGOUT_SUCCESS',
  '@@account/LOGOUT_FAILURE',
)<undefined, undefined, string>();

export const getCSRFAction = createAsyncAction(
  '@@account/GET_CSRF_REQUEST',
  '@@account/GET_CSRF_SUCCESS',
  '@@account/GET_CSRF_FAILURE',
)<undefined, undefined, string>();

export const activateAccountAction = createAsyncAction(
  '@@account/ACTIVATE_REQUEST',
  '@@account/ACTIVATE_SUCCESS',
  '@@account/ACTIVATE_FAILURE',
)<{ token: string; callback: GenericCallbackType }, undefined, string>();

export const confirmEmailAction = createAsyncAction(
  '@@account/CONFIRM_EMAIL_REQUEST',
  '@@account/CONFIRM_EMAIL_SUCCESS',
  '@@account/CONFIRM_EMAIL_FAILURE',
)<{ token: string; callback: GenericCallbackType }, undefined, string>();

export const generateLoginOtpAction = createAsyncAction(
  '@@account/GENERATE_LOGIN_OTP_REQUEST',
  '@@account/GENERATE_LOGIN_OTP_SUCCESS',
  '@@account/GENERATE_LOGIN_OTP_FAILURE',
)<GenerateOtpRequestType, GenerateOtpResponseType, string>();

export const verifyLoginOtpAction = createAsyncAction(
  '@@account/LOGIN_OTP_VERIFY_REQUEST',
  '@@account/LOGIN_OTP_VERIFY_SUCCESS',
  '@@account/LOGIN_OTP_VERIFY_FAILURE',
)<OtpVerifyRequestType, OtpVerifyResponseType, string>();

export const generateAccountOTPAction = createAsyncAction(
  '@@account/ACCOUNT_OTP_GENERATE_REQUEST',
  '@@account/ACCOUNT_OTP_GENERATE_SUCCESS',
  '@@account/ACCOUNT_OTP_GENERATE_FAILURE',
)<Partial<OtpSendRequestType>, OTPDataType, string>();

export const verifyAccountOTPAction = createAsyncAction(
  '@@account/ACCOUNT_OTP_VERIFY_REQUEST',
  '@@account/ACCOUNT_OTP_VERIFY_SUCCESS',
  '@@account/ACCOUNT_OTP_VERIFY_FAILURE',
)<OtpVerifyPayloadType, Pick<OtpVerifyResponseType, 'isOtpVerified'>, string>();

export const clearOTPDataAction = createAction('@@account/OTP_RESET')();

export const resetAccountAction = createAction('@@account/RESET')();

export const resetAccountDetailsAction = createAction('@@accountDetails/RESET')();

export const resetAccountErrorsAction = createAction('@@account/ERRORS_RESET')();

export const updateAccountRoleAction = createAction('@@account/UPDATE_ROLE')<number>();

export const updateAutoDeductAction = createAction(
  '@@payment/AUTO_DEDUCT_PAYMENT_CHECK',
)<boolean>();

export const updateAccountFormAction = createAction(
  '@@account/UPDATE_ACCOUNT_FORM',
)<AccountFieldTypes>();
