import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useClickAway, useEvent, useKey } from 'react-use';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getSvgURL } from 'lib';
import { useScreenInclude } from 'hooks/useScreenInclude';
import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import { setUserGuideMenuStateAction } from 'store/orientation/actions';
import { userGuideMenuStateSelector } from 'store/orientation/selectors';

import HelpMenus from './HelpMenus';
import styles from '../LogoutMenu/LogoutMenu.module.scss';

const Help = () => {
  const { formatMessage: fm } = useIntl();
  const dispatch = useDispatch();
  const currentEl = useRef(null);
  const isGradeOneToThree = useIsGradeOneToThree();
  const isMobile = useScreenInclude(['xs', 'sm']);

  const isOpenMenu = useSelector(userGuideMenuStateSelector);

  const setOpenMenu = useCallback(
    (value?: boolean) => {
      const boolValue = typeof value === 'boolean' ? value : undefined;
      dispatch(setUserGuideMenuStateAction(boolValue ?? !isOpenMenu));
    },
    [dispatch, isOpenMenu],
  );

  useClickAway(currentEl, () => {
    if (isOpenMenu) setOpenMenu(false);
  });
  useKey('Escape', () => setOpenMenu(false));
  useEvent('blur', () => setOpenMenu(false));

  const helpTitle = fm({
    id: 'dashboard.welcome.banner.get-started',
    defaultMessage: 'Get Started',
  });

  return (
    <div className={styles.user_guide} ref={currentEl} id="header-help">
      <div
        className={cn(styles.topheader__operation, styles.iconText, {
          [styles.grade]: isGradeOneToThree,
        })}
        onClick={() => setOpenMenu()}
      >
        <img src={getSvgURL('user-guide')} alt={helpTitle} />
        {!(isGradeOneToThree || isMobile) && <>{helpTitle}</>}
      </div>
      <HelpMenus isOpenMenu={isOpenMenu} setOpenMenu={setOpenMenu} closeHelpTour={setOpenMenu} />
    </div>
  );
};

export default Help;
