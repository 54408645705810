import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';
import {
  loadStudentMaterialAction,
  downloadStudentMaterialAction,
  loadTeacherMaterialAction,
  updatePreparationMaterialAction,
  uploadPreparationMaterialAction,
  deletePreparationMaterialAction,
} from './actions';
import * as actions from './actions';

export type PreparationMaterialActionType = ActionType<typeof actions>;

const data = createReducer<Partial<any>, PreparationMaterialActionType>({} as any)
  .handleAction(loadStudentMaterialAction.success, (state, action) => ({
    ...state,
    studentPreparationMaterial: action.payload,
  }))
  .handleAction(downloadStudentMaterialAction.success, (state, action) => ({
    ...state,
    completionRateBySubject: action.payload,
  }))
  .handleAction(loadTeacherMaterialAction.success, (state, action) => ({
    ...state,
    teacherPreparationMaterial: action.payload,
  }))
  .handleAction(updatePreparationMaterialAction.success, (state, action) => ({
    ...state,
    updatePreparationMaterial: action.payload,
  }))
  .handleAction(deletePreparationMaterialAction.success, (state, action) => ({
    ...state,
    deletePreparationMaterial: action.payload,
  }));

const fetching = combineReducers({
  loadStudentPreparationMaterial: createReducer<boolean, PreparationMaterialActionType>(false)
    .handleAction([loadStudentMaterialAction.request], () => true)
    .handleAction(
      [loadStudentMaterialAction.success, loadStudentMaterialAction.failure],
      () => false,
    ),
  downloadStudentPreparationMaterial: createReducer<boolean, PreparationMaterialActionType>(true)
    .handleAction([downloadStudentMaterialAction.request], () => true)
    .handleAction(
      [downloadStudentMaterialAction.success, downloadStudentMaterialAction.failure],
      () => false,
    ),
  loadTeacherPreparationMaterial: createReducer<boolean, PreparationMaterialActionType>(false)
    .handleAction([loadTeacherMaterialAction.request], () => true)
    .handleAction(
      [loadTeacherMaterialAction.success, loadTeacherMaterialAction.failure],
      () => false,
    ),
  updateTeacherPreparationMaterial: createReducer<boolean, PreparationMaterialActionType>(false)
    .handleAction(
      [updatePreparationMaterialAction.request, uploadPreparationMaterialAction.request],
      () => true,
    )
    .handleAction(
      [
        updatePreparationMaterialAction.success,
        updatePreparationMaterialAction.failure,
        uploadPreparationMaterialAction.success,
        uploadPreparationMaterialAction.failure,
      ],
      () => false,
    ),
  deleteTeacherPreparationMaterial: createReducer<boolean, PreparationMaterialActionType>(true)
    .handleAction([deletePreparationMaterialAction.request], () => true)
    .handleAction(
      [deletePreparationMaterialAction.success, deletePreparationMaterialAction.failure],
      () => false,
    ),
});

const errors = combineReducers({
  loadStudentMaterial: createReducer<string, PreparationMaterialActionType>('')
    .handleAction(loadStudentMaterialAction.failure, (state, action) => action.payload)
    .handleAction([loadStudentMaterialAction.success], () => ''),
  downloadStudentMaterial: createReducer<string, PreparationMaterialActionType>('')
    .handleAction(downloadStudentMaterialAction.failure, (state, action) => action.payload)
    .handleAction([downloadStudentMaterialAction.success], () => ''),
  loadTeacherMaterial: createReducer<string, PreparationMaterialActionType>('')
    .handleAction(loadTeacherMaterialAction.failure, (state, action) => action.payload)
    .handleAction([loadTeacherMaterialAction.success], () => ''),
  updateTeacherMaterial: createReducer<string, PreparationMaterialActionType>('')
    .handleAction(updatePreparationMaterialAction.failure, (state, action) => action.payload)
    .handleAction([updatePreparationMaterialAction.success], () => ''),
  deleteTeacherMaterial: createReducer<string, PreparationMaterialActionType>('')
    .handleAction(deletePreparationMaterialAction.failure, (state, action) => action.payload)
    .handleAction([deletePreparationMaterialAction.success], () => ''),
});

const preparationMaterialReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type PreparationMaterialType = StateType<typeof preparationMaterialReducer>;

export default preparationMaterialReducer;
