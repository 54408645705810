import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  loadCourseTilesProgressAction,
  loadCompletionRateBySubjectAction,
  loadCourseProgressAction,
} from './actions';
import { getCompletionRateBySubject, getCourseScore, getCourseTiles } from './api';

function* loadProgressRequest(
  action: ReturnType<typeof loadCourseTilesProgressAction.request>,
): SagaIterator<void> {
  try {
    const courseTiles = yield call(getCourseTiles, action.payload);
    yield put(loadCourseTilesProgressAction.success(courseTiles));
  } catch (error) {
    yield put(loadCourseTilesProgressAction.failure(error));
  }
}

function* loadCompletionRateBySubject({
  payload,
}: ReturnType<typeof loadCompletionRateBySubjectAction.request>): SagaIterator<void> {
  try {
    const completionRateBySubject: number = yield call(getCompletionRateBySubject, payload);
    yield put(loadCompletionRateBySubjectAction.success({ completionRateBySubject }));
  } catch (error) {
    yield put(loadCompletionRateBySubjectAction.failure(error));
  }
}

function* loadCourseProgress({
  payload,
}: ReturnType<typeof loadCourseProgressAction.request>): SagaIterator<void> {
  try {
    const score = yield call(getCourseScore, payload);
    yield put(loadCourseProgressAction.success(score));
  } catch (error) {
    yield put(loadCourseProgressAction.failure(error || error.detail));
  }
}

function* progressSaga(): SagaIterator {
  yield all([
    takeEvery(loadCourseTilesProgressAction.request, loadProgressRequest),
    takeEvery(loadCompletionRateBySubjectAction.request, loadCompletionRateBySubject),
    takeEvery(loadCourseProgressAction.request, loadCourseProgress),
  ]);
}

export default progressSaga;
