import { defineMessages } from 'react-intl';

const messages = defineMessages({
  upgradeButton: {
    id: 'account.subscription.upgrade-plan',
    defaultMessage: 'Upgrade plan',
  },
  cancelButton: {
    id: 'account.account.cancel-button',
    defaultMessage: 'Cancel',
  },
  heading: {
    id: 'subscription.error.heading',
    defaultMessage: 'Limit Reached',
  },
  description: {
    id: 'subscription.error.description',
    defaultMessage: 'Further content is not accessible. Please upgrade your plan',
  },
});

export default messages;
