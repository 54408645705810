import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';

import * as actions from './actions';
import { loadRewardStatsAction } from './actions';
import { RewardStatResponse } from './types';

export type RewardsType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, RewardsType>(true)
  .handleAction([loadRewardStatsAction.request], () => true)
  .handleAction([loadRewardStatsAction.success, loadRewardStatsAction.failure], () => false);

const data = createReducer<RewardStatResponse, RewardsType>({} as RewardStatResponse).handleAction(
  loadRewardStatsAction.success,
  (state, action) => action.payload,
);

const errors = createReducer<any, RewardsType>({})
  .handleAction(loadRewardStatsAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadRewardStatsAction.success, (state) => ({
    ...state,
    loading: '',
  }));

const RewardsReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export type RewardsStateType = StateType<typeof RewardsReducer>;

export default RewardsReducer;
