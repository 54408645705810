import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { IconType } from 'lib';
import LayoutContext from 'contexts/LayoutContext';

import { useInAppView } from 'hooks/useInAppView';
import { useTitle } from 'hooks/useTitle';
import { getUserType, getGradeType } from 'utils/getUserType';
import { getUserTitle } from 'utils/getUserTitle';
import { isSummerSemester } from 'utils/summerSemesterUtils';

import { SchoolRole } from 'store/account/types';
import {
  disabledServicesSelector,
  isAdminOrBranchManagerRoleSelector,
  isSchoolTeacherSelector,
  selectorAccountData,
} from 'store/account/selectors';
import { selectCurrentBranch } from 'store/shoolBranches/selectors';
import Tour from 'components/TourManagement/TourManagement';

import { DISABLE_EXAMS_USER_EMAIL } from 'constants/settings';

import { Sidebar } from './components/Sidebar/Sidebar';
import { SidebarTypes } from '../components/Sidebar/types';
import Header from './components/Header';
import Breadcrumbs, { BreadcrumbType } from './components/Breadcrumbs';
import { getStudentMenu } from './menus/student';
import { LayoutBaseTypes } from '../type';
import styles from './LayoutBase.module.scss';
import { getMenuItems } from '../menus';

export type LayoutProps = {
  title: string;
  subtitle?: string;
  layoutType?: LayoutBaseTypes.ALW | LayoutBaseTypes.B2B;
  breadcrumbs?: BreadcrumbType[];
  isLoading?: boolean;
  showHamburger?: boolean;
  hideSideMenu?: boolean;
  isSummerProgram?: boolean;
  isChild?: boolean;
  icon?: IconType;
  hideSideNav?: boolean;
};

function LayoutBase({ children }: { children?: ReactNode }) {
  const [isOpen, setOpen] = useState(false);
  const [layoutProps, setLayoutProps] = useState({} as LayoutProps);

  const {
    layoutType = LayoutBaseTypes.ALW,
    title,
    subtitle,
    breadcrumbs,
    showHamburger,
    isSummerProgram,
    isChild,
    hideSideMenu,
    icon,
    hideSideNav,
  } = layoutProps;

  const {
    firstName,
    lastName,
    role,
    schoolRole,
    adminRole,
    grade: { gradeValue },
    gender,
    plainPassword = '',
    email,
    profileImage: { imageUrlLarge: imageUrl },
    hasQiyasAccess,
  } = useSelector(selectorAccountData);
  const disabledServicesStudent = useSelector(disabledServicesSelector);

  const isALW = layoutType === LayoutBaseTypes.ALW;
  const userType = getUserType(role, schoolRole, adminRole) || '';
  const gradeType = getGradeType(gradeValue);

  const isGrade11Or12Student = userType === 'student' && (gradeValue === 11 || gradeValue === 12);
  const isAdminOrBranchManagerRole = useSelector(isAdminOrBranchManagerRoleSelector);
  const isSchoolTeacherRole = useSelector(isSchoolTeacherSelector);
  const { allowB2BGrading } = useSelector(selectCurrentBranch);

  const profileDetails = {
    srcLarge: imageUrl,
    src: imageUrl,
    role: getUserTitle(userType, gender),
    password: plainPassword,
    email,
    firstName,
    lastName,
  };
  const isDisableExamStudent = email === DISABLE_EXAMS_USER_EMAIL;

  const { isInAppView } = useInAppView();
  const menuItems = getStudentMenu(
    schoolRole === SchoolRole.schoolStudent,
    gradeValue,
    gender,
    disabledServicesStudent,
    isGrade11Or12Student,
    isDisableExamStudent,
    hasQiyasAccess,
  );

  const adminMenuItems = getMenuItems(
    userType,
    gender,
    gradeValue,
    [],
    false,
    false,
    allowB2BGrading,
  );

  useTitle(title, isALW);

  const handleSetLayoutProps = useCallback((props: LayoutProps) => {
    setLayoutProps(props);
  }, []);

  useEffect(() => {
    document.body.classList[isOpen ? 'add' : 'remove']('sidebar-open');
  }, [isOpen]);

  return (
    <LayoutContext.Provider value={handleSetLayoutProps}>
      <Tour userType={userType} gradeType={gradeType}>
        <div className={styles.layoutBase}>
          {!isInAppView && !hideSideMenu && !hideSideNav && (
            <Sidebar
              sidebarType={isALW ? SidebarTypes.ALW : SidebarTypes.B2B}
              menuItems={
                isAdminOrBranchManagerRole || isSchoolTeacherRole ? adminMenuItems : menuItems
              }
              isOpen={isOpen}
              setIsOpen={() => {
                setOpen(false);
              }}
              showHamburger={!!showHamburger}
            />
          )}
          <main className={styles.layoutBase__main}>
            <div
              className={cn(styles.dashboard, {
                [styles.desktopHamburger]: showHamburger,
                [styles.summerProgram]: isSummerSemester && isSummerProgram,
              })}
            >
              <div className={styles.dashboard__container}>
                <Header
                  title={title}
                  subtitle={subtitle}
                  profileDetails={profileDetails}
                  handleOpenSidebar={() => {
                    setOpen(true);
                  }}
                  showHamburger={!!showHamburger}
                  isSummerProgram={!!isSummerProgram}
                  isChild={isChild}
                  userType={userType}
                  icon={icon}
                />
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <div className={cn(styles.row, styles['layout-body'], 'layout-body')}>
                  {children}
                </div>
              </div>
            </div>
          </main>
        </div>
      </Tour>
    </LayoutContext.Provider>
  );
}

export default LayoutBase;
