import React, { FunctionComponent, ReactNode } from 'react';
import cn from 'classnames';
import { MarginTypes } from './Margin.types';
import styles from './Margin.module.scss';

export type MarginProps = {
  children: ReactNode;
  top?: keyof typeof MarginTypes;
  bottom?: keyof typeof MarginTypes;
  isInline?: boolean;
};

export const Margin: FunctionComponent<MarginProps> = ({
  children,
  top,
  bottom,
  isInline = false,
}) => {
  const cssClasses = cn({
    [styles.displayInline]: isInline,
    [styles[`top-${top}`]]: top,
    [styles[`bottom-${bottom}`]]: bottom,
  });

  return React.createElement('div', { className: cssClasses }, children);
};
