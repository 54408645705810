export const msgs = {
  welcomeTitle: {
    id: 'orientation.video.welcome.title',
    defaultMessage: 'Welcome',
  },
  advanceLearningPlatformTitle: {
    id: 'orientation.video.advance.learning.world',
    defaultMessage: 'Advance learning platform!',
  },
  orientationStepsDescription: {
    id: 'orientation.video.orientation.step.description',
    defaultMessage:
      'We have exciting content for you. Learn to use the platform by clicking the next button.',
  },
  nextButtonTitle: {
    id: 'tour.nextButton.label',
    defaultMessage: 'Next',
  },
  welcomeDescription: {
    id: 'orientation.video.welcome.description',
    defaultMessage: 'We are happy to see you on',
  },
  skipButtonTitle: {
    id: 'tour.skipButton.label',
    defaultMessage: 'Skip',
  },
  demoDescription: {
    id: 'orientation.video.watch.demo.description',
    defaultMessage: 'Watch the video tutorial to learn using “{title}”',
  },
  exploreButtonTitle: {
    id: 'orientation.video.button.explore',
    defaultMessage: 'Explore',
  },
};
