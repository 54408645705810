// eslint-disable-next-line import/no-unresolved
import LOADER from 'assets/svg/squares.svg?react';

export enum ICONS {
  'add-circle',
  'arrow-back',
  'arrow-down',
  'arrow-top',
  'arrow-forward',
  'arrow-right',
  'arrow-left',
  'arrow-up',
  book,
  box,
  camera,
  card,
  chatbubble,
  'checkmark-box',
  circle,
  'checkmark-circle',
  close,
  'ellipsis-horizontal',
  facebook,
  google,
  info,
  logout,
  'menu-outline',
  notifications,
  play,
  search,
  trash,
  videocam,
  construct,
  person,
  twitter,
  help,
  star,
  'star-half',
  'star-outline',
  'hourglass-outline',
  'school-outline',
  'cloud-upload',
  'play-upload',
  schedule,
  'checkmark-outline',
  visa,
  master,
  mada,
  stc_pay,
  dashboard,
  'learning-path',
  'content-library',
  'full-screen',
  feedback,
  'notification-bell',
  'branch-detail',
  teacher,
  students,
  'switch-branch',
  exercise,
  report,
  'group-chat',
  exam,
  'question-bank',
  calender,
  'lesson-week',
  'overdue-lesson',
  'alert-icon',
  reward,
  'video-playlist',
  pacman,
  'admission-test',
  assignment,
  loader,
  'exam-image',
  'question-icon',
  'native-events',
  'calendar-month',
}

export enum Sizes {
  s16 = 's16',
  s20 = 's20',
  s24 = 's24',
  s32 = 's32',
  s40 = 's40',
  s60 = 's60',
  s95 = 's95',
  s100 = 's100',
  s140 = 's140',
  s40_80 = 's40_80',
  s30_90 = 's30_90',
  auto = 'auto',
}

export enum Colors {
  primary = 'primary',
  secondary = 'secondary',
  dark = 'dark',
  light = 'light',
  white = 'white',
  error = 'error',
  default = 'default',
  success = 'success',
}

export const internalIcons = [
  {
    name: 'loader',
    component: LOADER,
  },
];
