import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  PreferencesType,
  PreferencesRequestType,
  ResultType,
  UpdateNotificationsPayload,
} from './types';

export const loadPreferencesAction = createAsyncAction(
  '@@preferences/LOAD_REQUEST',
  '@@preferences/LOAD_SUCCESS',
  '@@preferences/LOAD_FAILURE',
)<undefined, PreferencesType, string>();

export const notificationPreferencesAction = createAction(
  '@@notificationPreferences/LOAD',
)<PreferencesRequestType>();

export const resetPreferencesAction = createAction('@@preferences/RESET')();

export const updatePreferencesAction = createAsyncAction(
  '@@preferences/UPDATE_REQUEST',
  '@@preferences/UPDATE_SUCCESS',
  '@@preferences/UPDATE_FAILURE',
)<PreferencesType | ResultType, PreferencesType, string>();

export const updateNotificationPreferencesAction = createAsyncAction(
  '@@preferences/UPDATE_NOTIFICATION_REQUEST',
  '@@preferences/UPDATE_NOTIFICATION_SUCCESS',
  '@@preferences/UPDATE_NOTIFICATION_FAILURE',
)<UpdateNotificationsPayload, ResultType & { grade?: number }, string>();

export const updateAvatarLoader = createAction('@@preferences/UPDATE_AVATAR')<boolean>();
