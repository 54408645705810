import { createAction, createAsyncAction } from 'typesafe-actions';

import { OrientationResponseType, OrientationVideoRequestType } from './types';

export const orientationVideoAction = createAsyncAction(
  '@@orientation/LOAD_ORIENTATION_VIDEO_REQUEST',
  '@@orientation/LOAD_ORIENTATION_VIDEO_SUCCESS',
  '@@orientation/LOAD_ORIENTATION_VIDEO_FAILURE',
)<OrientationVideoRequestType, OrientationResponseType[], string>();

export const orientationDemoAction = createAsyncAction(
  '@@orientation/LOAD_ORIENTATION_QUICK_DEMO_REQUEST',
  '@@orientation/LOAD_ORIENTATION_QUICK_DEMO_SUCCESS',
  '@@orientation/LOAD_ORIENTATION_QUICK_DEMO_FAILURE',
)<OrientationVideoRequestType, OrientationResponseType, string>();

export const resetOrientationDataAction = createAction('@@orientation/DATA_RESET')();

export const setUserGuideMenuStateAction = createAction(
  '@@orientation/SET_USER_GUIDE_MENU_STATE',
)<boolean>();
