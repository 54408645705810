import camelcaseKeys from 'camelcase-keys';
import API from '../../utils/api';
import { LessonType } from './types';

export async function getLessons(unitId: string): Promise<Array<LessonType>> {
  const response = await API.get(`/api/mentora/units/${unitId}/lessons`);

  return camelcaseKeys(
    response.data.map((lesson: LessonType) => ({
      ...lesson,
      progress: Math.round(lesson.progress * 100),
    })),
    { deep: true },
  );
}
