export enum SSOType {
  'login' = 'login',
  'account' = 'account',
}

export enum SSOProvider {
  'facebook' = 'oa2-facebook',
  'google' = 'oa2-google-oauth2',
}

export enum SSOErrorType {
  'server' = 'server',
  'register' = 'register',
}

export type SSOAccountType = {
  providerId: SSOProvider;
  name: string;
  remoteId: string;
};
