import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  loadAdditionalContentLSuccessAction,
  loadLessonsWeekScheduleAction,
  resetLessonsWeekScheduleAction,
  updateAdditionalContentAction,
  loadExercisesAction,
  loadExamsAction,
} from './actions';
import { ExamsListType, LessonsWeekListType, LessonWeekType } from './types';

export type LessonsWeekListActionType = ActionType<typeof actions>;

export type LessonsWeekActionType = ActionType<typeof actions>;

export type ExercisesListActionType = ActionType<typeof actions>;

export type ExamsListActionType = ActionType<typeof actions>;

const initialState: any = {
  current: [],
  overdue: [],
  exercises: {
    accomplishedExercises: '',
    exercisesLessonsArray: [],
    totalAvailableExercises: '',
  },
  exams: {
    accomplishedExams: '',
    examsLessonsArray: [],
    totalAvailableExams: '',
  },
};

const data = combineReducers({
  lessons: createReducer<LessonsWeekListType, LessonsWeekListActionType>(initialState).handleAction(
    resetLessonsWeekScheduleAction,
    () => initialState,
  ),
  additional: createReducer<LessonWeekType[], LessonsWeekActionType>([])
    .handleAction(loadAdditionalContentLSuccessAction, (state, { payload }) => payload)
    .handleAction(updateAdditionalContentAction, (state, { payload }) =>
      state.map((lesson) =>
        String(lesson.lessonId) === String(payload) ? { ...lesson, isCompleted: true } : lesson,
      ),
    ),
  exercises: createReducer<any, ExercisesListActionType>(initialState.exercises).handleAction(
    loadExercisesAction.success,
    (state, { payload }) => payload,
  ),
  exams: createReducer<ExamsListType, ExamsListActionType>(initialState.exams).handleAction(
    loadExamsAction.success,
    (state, { payload }) => payload,
  ),
});

const fetching = combineReducers({
  load: createReducer<boolean, LessonsWeekActionType>(true)
    .handleAction(loadLessonsWeekScheduleAction.request, () => true)
    .handleAction(loadExercisesAction.request, () => true)
    .handleAction(loadExamsAction.request, () => true)
    .handleAction(
      [
        loadLessonsWeekScheduleAction.success,
        loadLessonsWeekScheduleAction.failure,
        loadExercisesAction.success,
        loadExercisesAction.failure,
        loadExamsAction.success,
        loadExamsAction.failure,
      ],
      () => false,
    )
    .handleAction(resetLessonsWeekScheduleAction, () => true),
});

const errors = combineReducers({
  load: createReducer<string, LessonsWeekActionType>('')
    .handleAction(loadLessonsWeekScheduleAction.failure, (state, { payload }) => payload)
    .handleAction(resetLessonsWeekScheduleAction, () => ''),
});

const lessonsWeekScheduleReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type lessonsWeekScheduleType = StateType<typeof lessonsWeekScheduleReducer>;

export default lessonsWeekScheduleReducer;
