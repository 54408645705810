import { getPngURL, getSvgURL } from 'lib';

const ScssAssetsList = [
  {
    name: '--btn-red',
    url: getPngURL('btn-red'),
  },
  {
    name: '--btn-orange',
    url: getPngURL('btn-orange'),
  },
  {
    name: '--arrow',
    url: getPngURL('arrow'),
  },
  {
    name: '--arrow-down',
    url: getPngURL('arrow-down'),
  },
  {
    name: '--body-bg',
    url: getPngURL('body-bg'),
  },
  {
    name: '--body-bg-blue',
    url: getPngURL('body-bg-blue'),
  },
  {
    name: '--body-bg-dark',
    url: getPngURL('body-bg-dark'),
  },
  {
    name: '--body-bg-pink',
    url: getPngURL('body-bg-pink'),
  },
  {
    name: '--connect',
    url: getPngURL('connect'),
  },
  {
    name: '--content-library-bg',
    url: getPngURL('content-library-bg'),
  },
  {
    name: '--cross-icon',
    url: getPngURL('cross-icon'),
  },
  {
    name: '--graduate-caps',
    url: getPngURL('graduate-caps'),
  },
  {
    name: '--loader',
    url: getPngURL('loader'),
  },
  {
    name: '--loader-grey',
    url: getPngURL('loader-grey'),
  },
  {
    name: '--modal-bg',
    url: getPngURL('modal-bg'),
  },
  {
    name: '--profile-bg',
    url: getPngURL('profile-bg'),
  },
  {
    name: '--profile-bg-dark',
    url: getPngURL('profile-bg-dark'),
  },
  {
    name: '--reward-header-bg',
    url: getPngURL('reward-header-bg'),
  },
  {
    name: '--reward-header-bg-arabic',
    url: getPngURL('reward-header-bg-arabic'),
  },
  {
    name: '--schedulem_bg',
    url: getPngURL('schedulem_bg'),
  },
  {
    name: '--subscription-selected',
    url: getSvgURL('subscription-selected'),
  },
  {
    name: '--logo',
    url: getSvgURL('logo'),
  },
  {
    name: '--arrow-path',
    url: getSvgURL('arrow-path'),
  },
  {
    name: '--large-btn',
    url: getSvgURL('large-btn'),
  },
  {
    name: '--summer-program-bg',
    url: getPngURL('summer-program-bg'),
  },
  {
    name: '--empty_pattern-bg',
    url: getPngURL('empty-pattern'),
  },
  {
    name: '--ars-header-img',
    url: getSvgURL('ars-header-img'),
  },
  {
    name: '--student-exercise-desktop-bg',
    url: getSvgURL('student-exercise-desktop'),
  },
  {
    name: '--student-exercise-mobile-bg',
    url: getSvgURL('student-exercise-mobile'),
  },
  {
    name: '--student-reinforcement-desktop-bg',
    url: getSvgURL('student-reinforcement-desktop'),
  },
  {
    name: '--student-reinforcement-mobile-bg',
    url: getSvgURL('student-reinforcement-mobile'),
  },
  {
    name: '--rouded-vector-bg',
    url: getSvgURL('rounded-vector'),
  },
  {
    name: '--orientation-vector-bg',
    url: getSvgURL('orientation-demo'),
  },
];

ScssAssetsList.forEach(({ name, url }) =>
  document.documentElement.style.setProperty(name, `url(${url})`),
);
