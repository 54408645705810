import { createReducer, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { SemesterErrorsType, SemesterDataType, SemesterWeekType } from './types';
import {
  loadSemestersAction,
  loadNextYearSemestersAction,
  resetSemesterAction,
  fetchSemesterWeeksAction,
} from './actions';
import * as actions from './actions';

export type SemesterActionType = ActionType<typeof actions>;

export const semesterDetailsInitialState = {
  nextYearSemesterDetails: [],
  semesterDetails: [],
};

const isLoading = createReducer<boolean, SemesterActionType>(true)
  .handleAction([loadSemestersAction.request, resetSemesterAction], () => true)
  .handleAction([loadSemestersAction.success, loadSemestersAction.failure], () => false)
  .handleAction(
    [loadNextYearSemestersAction.success, loadNextYearSemestersAction.failure],
    () => false,
  );

const data = createReducer<SemesterDataType, SemesterActionType>(semesterDetailsInitialState)
  .handleAction(loadSemestersAction.success, (state, action) => ({
    ...state,
    ...{ semesterDetails: action.payload },
  }))
  .handleAction(loadNextYearSemestersAction.success, (state, action) => ({
    ...state,
    ...{ nextYearSemesterDetails: action.payload },
  }))
  .handleAction(resetSemesterAction, () => ({ semesterDetails: [], nextYearSemesterDetails: [] }));

const errors = createReducer<SemesterErrorsType, SemesterActionType>({})
  .handleAction(loadSemestersAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadSemestersAction.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(loadNextYearSemestersAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadNextYearSemestersAction.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(resetSemesterAction, () => ({}));

const semesterWeeks = combineReducers({
  data: createReducer<SemesterWeekType[], SemesterActionType>([]).handleAction(
    fetchSemesterWeeksAction.success,
    (_, { payload }) => payload,
  ),
  loading: createReducer<boolean, SemesterActionType>(true)
    .handleAction(fetchSemesterWeeksAction.request, () => true)
    .handleAction(
      [fetchSemesterWeeksAction.success, fetchSemesterWeeksAction.failure],
      () => false,
    ),
  error: createReducer<string, SemesterActionType>('')
    .handleAction(fetchSemesterWeeksAction.failure, (_, { payload }) => payload)
    .handleAction([fetchSemesterWeeksAction.request, fetchSemesterWeeksAction.success], () => ''),
});

const semesterReducer = combineReducers({
  isLoading,
  data,
  errors,
  semesterWeeks,
});

export default semesterReducer;
