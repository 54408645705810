import React, { FunctionComponent, useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Formik as Form, Field, FieldProps, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';

import { Dialog, SelectField, Loader, IconCommon, getPngURL } from 'lib';
import { Button } from 'modules/GradeOneToThree/elements';
import { GradeTextAreaField } from 'modules/GradeOneToThree/lib';
import { ROUTES } from 'constants/routes';
import { FAKE_EMAIL } from 'constants/settings';
import HelpDisclaimer from 'components/LayoutBase/components/Header/Feedback/HelpDisclaimer';

import { selectorSupportFetching } from 'store/support/selectors';
import { selectorAccount } from 'store/account/selectors';
import { sendSupportMessageAction } from 'store/support/actions';

import {
  Root,
  DialogWrapper,
  Heading,
  IconWrapper,
  ButtonWrapper,
  DialogImage,
  SubHeading,
} from './elements';

import { messages } from './messages';

type HelpDialogProps = {
  closeDialog: () => void;
  isDialogOpen: boolean;
};

type RequestOptionsType = { value: string; label: string };

type InitialValuesType = {
  request?: RequestOptionsType;
  description?: string;
};

const HelpDialog: FunctionComponent<HelpDialogProps> = ({ closeDialog, isDialogOpen }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMessageSent, setIsMessageSent] = useState(false);

  const isSupportFetching = useSelector(selectorSupportFetching);
  const { data: account } = useSelector(selectorAccount);

  const options = useMemo(
    () => [
      {
        value: 'content',
        label: formatMessage(messages.contentOption),
      },
      {
        value: 'technical',
        label: formatMessage(messages.technicalOption),
      },
      {
        value: 'other',
        label: formatMessage(messages.otherOption),
      },
    ],
    [formatMessage],
  );

  const validateForm = useCallback(
    (values: InitialValuesType) => {
      const formErrors: Record<string, string> = {};

      if (!values?.request) {
        formErrors.request = formatMessage(messages.formFieldRequestValidation);
      }

      if (!values?.description) {
        formErrors.description = formatMessage(messages.formFieldDescriptionValidation);
      }

      return formErrors;
    },
    [formatMessage],
  );

  const handleCloseCompletedMessage = () => setIsMessageSent(false);

  const onSubmit = (values: InitialValuesType): void => {
    const { request, description } = values;

    if (request && description)
      dispatch(
        sendSupportMessageAction.request({
          subject: request?.label,
          description,
          callback: () => {
            setIsMessageSent(true);
          },
        }),
      );
  };

  if (account?.email?.includes(FAKE_EMAIL))
    return (
      <Dialog isVisible={isDialogOpen}>
        <DialogWrapper>
          <Root>
            <IconWrapper onClick={closeDialog}>
              <IconCommon className="icon-cancel" />
            </IconWrapper>
            <Heading>
              {formatMessage(messages[isMessageSent ? 'requestSentInfo' : 'requestTitle'])}
            </Heading>
            <div className="inlineMsg inlineMsg-warning no-shadow mb-0 p-15">
              <HelpDisclaimer
                actionHandler={() => {
                  closeDialog();
                  history.push(ROUTES.account);
                }}
              />
            </div>
          </Root>
        </DialogWrapper>
      </Dialog>
    );

  return (
    <Dialog isVisible={isDialogOpen}>
      <DialogWrapper>
        <Root>
          <IconWrapper onClick={closeDialog}>
            <IconCommon className="icon-cancel" />
          </IconWrapper>
          <div>
            {isSupportFetching ? (
              <Loader type="component" />
            ) : (
              <>
                <Heading>
                  {formatMessage(isMessageSent ? messages.requestSentInfo : messages.requestTitle)}
                </Heading>
                <SubHeading>
                  {formatMessage(
                    isMessageSent ? messages.requestCompletedInfo : messages.requestDescription,
                    { email: account?.email },
                  )}
                </SubHeading>
                {isMessageSent ? (
                  <ButtonWrapper>
                    <Button size="sm" color="orange" onClick={handleCloseCompletedMessage}>
                      {formatMessage(messages.requestCompletedButtonLabel)}
                    </Button>
                  </ButtonWrapper>
                ) : (
                  <Form
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validate={validateForm}
                    validateOnMount
                  >
                    {({
                      handleSubmit,
                      setFieldTouched,
                      setFieldValue,
                      isValid,
                    }: FormikProps<InitialValuesType>) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <Field name="request">
                            {({ field: { name }, meta }: FieldProps) => (
                              <SelectField
                                title={formatMessage(messages.formFieldRequestLable)}
                                options={options}
                                placeholder={formatMessage(messages.formFieldRequestPlaceholder)}
                                onBlur={() => setFieldTouched(name)}
                                onChange={(
                                  value: RequestOptionsType,
                                  actionMeta: { action: string },
                                ) => {
                                  if (actionMeta.action === 'select-option') {
                                    setFieldValue(name, value);
                                    setFieldValue('description', '');
                                    setTimeout(() => {
                                      setFieldTouched(name);
                                    }, 100);
                                  }
                                }}
                                error={meta.touched && meta.error}
                                className="grade"
                              />
                            )}
                          </Field>
                          <Field name="description">
                            {({ field, meta }: FieldProps) => (
                              <GradeTextAreaField
                                {...field}
                                title={formatMessage(messages.formFieldDescriptionLabel)}
                                placeholder={formatMessage(
                                  messages.formFieldDescriptionPlaceholder,
                                )}
                                rows={5}
                                error={meta.touched && meta.error}
                                fullWidth
                              />
                            )}
                          </Field>
                          <ButtonWrapper>
                            <Button size="sm" color="orange" disabled={!isValid}>
                              {formatMessage(messages.submitButtonLabel)}
                            </Button>
                          </ButtonWrapper>
                        </form>
                      );
                    }}
                  </Form>
                )}
              </>
            )}
          </div>
        </Root>
        <DialogImage
          src={getPngURL('smile-face', true)}
          className="smile_face"
          alt={formatMessage(messages.dialogImage)}
        />
      </DialogWrapper>
    </Dialog>
  );
};

export default HelpDialog;
