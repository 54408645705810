import camelcaseKeys from 'camelcase-keys';
import { dataScheduleForTodayAdapter } from './adapter';
import API from '../../utils/api';
import { ScheduleForTodayType } from './types';

export const getScheduleForToday = async (
  startDate: Date,
  endDate: Date,
  student?: string,
): Promise<ScheduleForTodayType[]> => {
  const response = await API.get('/api/mentora/calendar/events/', {
    params: {
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      student,
    },
  });

  return dataScheduleForTodayAdapter(camelcaseKeys(response.data, { deep: true }));
};
