import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { sendSupportMessageAction } from './actions';
import { sendSupportEmail } from './api';
import { SupportMessageRequestType } from './types';

function* sendSupportEmailAction({
  payload,
}: ReturnType<typeof sendSupportMessageAction.request>): SagaIterator<void> {
  try {
    const { subject, description, callback } = payload;
    const supportEmail: SupportMessageRequestType = yield call(sendSupportEmail, {
      subject,
      description,
    });
    yield put(sendSupportMessageAction.success(supportEmail));
    yield call(callback);
  } catch (error) {
    yield put(sendSupportMessageAction.failure(error));
  }
}

function* supportSaga(): SagaIterator {
  yield all([takeEvery(sendSupportMessageAction.request, sendSupportEmailAction)]);
}

export default supportSaga;
