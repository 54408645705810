import React from 'react';
import { useIntl } from 'react-intl';

import { getPngURL, getSvgURL, SVG } from 'lib';
import { ThemeTypes, useTheme } from 'contexts/ThemeContext';

import { CloudImage, MoonImage, RocketImage, Stars, SunImage } from './elements';

const Background = () => {
  const { theme } = useTheme();
  const { formatMessage } = useIntl();

  const nightTheme = theme === ThemeTypes.night;

  return (
    <>
      {nightTheme ? (
        <>
          <MoonImage
            src={getPngURL('moon-01', true)}
            alt={formatMessage({
              id: 'dashboard.background-image.label',
              defaultMessage: 'moon',
            })}
          />
          <RocketImage
            src={getSvgURL('rocket-image', true)}
            alt={formatMessage({
              id: 'dashboard.background-image.animation.icon',
              defaultMessage: 'rocket-icon',
            })}
          />
          <Stars />
        </>
      ) : (
        <>
          <SVG name="base-bg" isGradeOneToThree />
          <SunImage
            src={getPngURL('sun', true)}
            alt={formatMessage({
              id: 'dashboard.background-image.label',
              defaultMessage: 'moon',
            })}
          />
          <CloudImage src={getSvgURL('clouds-birds', true)} alt="Cloud" />
        </>
      )}
    </>
  );
};

export default Background;
