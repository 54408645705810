import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  FiltersDataType,
  ContentLibraryRequestType,
  ContentLibraryType,
  ContentLibraryStudentsByGradeType,
  AssignContentRequestType,
  AssignContentResponseType,
  CompleteContentRequestType,
  UploadAndAssignContentRequestType,
  SelfAssignContentRequestType,
  LoadClassroomStudentsRequestType,
  LiveEventsType,
} from './types';

export const loadFiltersDataAction = createAsyncAction(
  '@@contentLibrary/LOAD_FILTERS_REQUEST',
  '@@contentLibrary/LOAD_FILTERS_SUCCESS',
  '@@contentLibrary/LOAD_FILTERS_FAILURE',
)<string, FiltersDataType, string>();

export const loadContentDataAction = createAsyncAction(
  '@@contentLibrary/LOAD_CONTENT_REQUEST',
  '@@contentLibrary/LOAD_CONTENT_SUCCESS',
  '@@contentLibrary/LOAD_CONTENT_FAILURE',
)<ContentLibraryRequestType, ContentLibraryType[], string>();

export const loadStudentDataAction = createAsyncAction(
  '@@contentLibrary/LOAD_STUDENTS_REQUEST',
  '@@contentLibrary/LOAD_STUDENTS_SUCCESS',
  '@@contentLibrary/LOAD_STUDENTS_FAILURE',
)<undefined, ContentLibraryStudentsByGradeType[], string>();

export const loadSubjectsAction = createAsyncAction(
  '@@contentLibrary/LOAD_SUBJECTS_SUCCESS',
  '@@contentLibrary/LOAD_SUBJECTS_REQUEST',
  '@@contentLibrary/LOAD_SUBJECTS_FAILURE',
)<undefined, any, string>();

export const loadGradesAction = createAsyncAction(
  '@@contentLibrary/LOAD_GRADES_SUCCESS',
  '@@contentLibrary/LOAD_GRADES_REQUEST',
  '@@contentLibrary/LOAD_GRADES_FAILURE',
)<undefined, any, string>();

export const loadGradeStudentsAction = createAsyncAction(
  '@@contentLibrary/LOAD_GRADE_STUDENTS_SUCCESS',
  '@@contentLibrary/LOAD_GRADE_STUDENTS_REQUEST',
  '@@contentLibrary/LOAD_GRADE_STUDENTS_FAILURE',
)<{ grade: string }, any, string>();

export const loadClassroomStudentsAction = createAsyncAction(
  '@@contentLibrary/LOAD_CLASSROOM_STUDENTS_SUCCESS',
  '@@contentLibrary/LOAD_CLASSROOM_STUDENTS_REQUEST',
  '@@contentLibrary/LOAD_CLASSROOM_STUDENTS_FAILURE',
)<LoadClassroomStudentsRequestType, any, string>();

export const assignContentDataAction = createAsyncAction(
  '@@contentLibrary/ASSIGN_CONTENT_REQUEST',
  '@@contentLibrary/ASSIGN_CONTENT_SUCCESS',
  '@@contentLibrary/ASSIGN_CONTENT_FAILURE',
)<AssignContentRequestType, AssignContentRequestType, string>();

export const uploadAndAssignContentAction = createAsyncAction(
  '@@contentLibrary/UPLOAD_AND_ASSIGN_CONTENT_SUCCESS',
  '@@contentLibrary/UPLOAD_AND_ASSIGN_CONTENT_REQUEST',
  '@@contentLibrary/UPLOAD_AND_ASSIGN_CONTENT_FAILURE',
)<UploadAndAssignContentRequestType, UploadAndAssignContentRequestType, string>();

export const selfAssignContentAction = createAsyncAction(
  '@@contentLibrary/SELF_ASSIGN_CONTENT_SUCCESS',
  '@@contentLibrary/SELF_ASSIGN_CONTENT_REQUEST',
  '@@contentLibrary/SELF_ASSIGN_CONTENT_FAILURE',
)<SelfAssignContentRequestType, any, string>();

export const completeContentDataAction = createAsyncAction(
  '@@contentLibrary/COMPLETE_CONTENT_REQUEST',
  '@@contentLibrary/COMPLETE_CONTENT_SUCCESS',
  '@@contentLibrary/COMPLETE_CONTENT_FAILURE',
)<CompleteContentRequestType, Partial<AssignContentResponseType>, string>();

export const resetSubjectsContentLibraryAction = createAction('@@contentLibrary/RESET_SUBJECTS')();
export const liveEventsAction = createAction('@@contentLibrary/LIVE_EVENTS')<LiveEventsType[]>();
export const resetAssignContentLibraryAction = createAction('@@contentLibrary/RESET_ASSIGN')();

export const resetContentLibraryAction = createAction('@@contentLibrary/RESET')();
