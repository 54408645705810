import React from 'react';
import cn from 'classnames';

import styles from '../InputField.module.scss';
import { InputBaseProps } from '../Input/Input.types';
import { InputBase } from '../Input/InputBase';

export type TextAreaProps = InputBaseProps & {
  rows?: number;
};

export const TextArea = ({ rows = 4, maxLength, ...restProps }: TextAreaProps) => {
  return (
    <InputBase
      {...restProps}
      render={(inputProps) => (
        <textarea
          className={cn(styles['form-control'], styles['fullw-textarea'])}
          {...inputProps}
          rows={rows}
          maxLength={maxLength}
        />
      )}
    />
  );
};
