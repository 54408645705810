import { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';

import API from 'utils/api';
import {
  getTeacherQuestionsAPI,
  getChildTeacherDetailAPI,
  deleteTeacherQuestionAPI,
  postQuestionAPI,
  getChildDetailAPI,
  postUpdateAnswerAPI,
  getTeacherQuestionsStatusAPI,
} from 'utils/apiEndpoints';

import {
  PaginatedTeacherQuestion,
  ChildTeacherDetail,
  TeacherDetailQuestionsPayload,
  QuestionDeletePayload,
  AskQuestionPayload,
  ChildDetailPayload,
  ChildDetail,
  PostAnswerPayload,
  TeacherAnswer,
} from './types';

export async function getTeacherQuestions({
  childId,
  teacherId,
  pageNo,
}: TeacherDetailQuestionsPayload): Promise<PaginatedTeacherQuestion> {
  const response = await API.get(getTeacherQuestionsAPI(childId, teacherId), {
    params: { page: pageNo },
  });
  return camelCaseKeys(response.data, { deep: true });
}

export async function getTeacherQuestionsStatusData(): Promise<any> {
  const response = await API.get(getTeacherQuestionsStatusAPI());
  return camelCaseKeys(response.data, { deep: true });
}

export async function deleteTeacherQuestion({
  childId,
  teacherId,
  questionId,
}: Omit<QuestionDeletePayload, 'callback'>): Promise<void> {
  await API.delete(deleteTeacherQuestionAPI(childId, teacherId, questionId));
}

export async function getChildTeacherDetail({
  childId,
  teacherId,
}: Omit<TeacherDetailQuestionsPayload, 'pageNo'>): Promise<ChildTeacherDetail> {
  const response = await API.get(getChildTeacherDetailAPI(childId, teacherId));
  return camelCaseKeys(response.data, { deep: true });
}

export async function getChildDetail({ childId }: ChildDetailPayload): Promise<ChildDetail> {
  const response = await API.get(getChildDetailAPI(childId));
  return camelCaseKeys(response.data, { deep: true });
}

export async function postUpdateAnswer({
  childId,
  teacherId,
  questionId,
  answer,
  updateAnswer,
}: Omit<PostAnswerPayload, 'callback'>): Promise<TeacherAnswer> {
  const formData = new FormData();
  formData.append('answer', answer);

  let response = {} as AxiosResponse;
  const API_REQ = updateAnswer ? API.put : API.post;

  response = await API_REQ(postUpdateAnswerAPI(childId, teacherId, questionId), formData);

  return camelCaseKeys(response.data, { deep: true });
}

export async function askQuestion({
  childId,
  teacherId,
  question,
}: AskQuestionPayload): Promise<ChildTeacherDetail> {
  const response = await API.post(postQuestionAPI(childId, teacherId), {
    question,
  });
  return camelCaseKeys(response.data, { deep: true });
}
