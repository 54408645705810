import { defineMessages } from 'react-intl';

type messagesType = Record<string, { id: string; defaultMessage: string }>;

export const messages: messagesType = defineMessages({
  requestTitle: {
    id: 'help.modal.request.title',
    defaultMessage: 'How can we help you?',
  },
  requestDescription: {
    id: 'help.modal.request.description',
    defaultMessage: 'Select a type of issue do you have and give us a short description.',
  },
  formFieldRequestLable: {
    id: 'help.modal.request.request',
    defaultMessage: 'What is your request about?',
  },
  formFieldRequestPlaceholder: {
    id: 'help.modal.request.select-issue.placeholder',
    defaultMessage: 'Please select an issue',
  },
  formFieldDescriptionLabel: {
    id: 'help.modal.request.subtitle',
    defaultMessage: 'Description',
  },
  formFieldDescriptionPlaceholder: {
    id: 'help.modal.request.describe.placeholder',
    defaultMessage: 'Describe your issue, please.',
  },
  submitButtonLabel: {
    id: 'help.modal.request.submit',
    defaultMessage: 'Submit',
  },
  requestSentInfo: {
    id: 'help.modals.completed.title',
    defaultMessage: 'Your request has been sent!',
  },
  requestCompletedInfo: {
    id: 'help.modal.completed.description',
    defaultMessage: 'We will answer you as soon as possible on your email {email}',
  },
  requestCompletedButtonLabel: {
    id: 'help.modal.completed.submit',
    defaultMessage: 'Okay',
  },
  contentOption: {
    id: 'help.modal.content',
    defaultMessage: 'Content',
  },
  technicalOption: {
    id: 'help.modal.technical',
    defaultMessage: 'Technical',
  },
  otherOption: {
    id: 'help.modal.other',
    defaultMessage: 'Other',
  },
  formFieldRequestValidation: {
    id: 'help.modal.select-issue.validation',
    defaultMessage: 'Request is required field, please fill it out.',
  },
  formFieldDescriptionValidation: {
    id: 'help.modal.describe.validation',
    defaultMessage: 'Description is required field, please fill it out.',
  },
  dialogImage: {
    id: 'lessons.dialog.icon.label',
    defaultMessage: 'lesson-dialog',
  },
  helpWarning: {
    id: 'help.add.email.message',
    defaultMessage: 'To access this feature, please confirm your email from the ',
  },
  update: { id: 'student.form.updatePassword', defaultMessage: 'Update' },
});
