export enum gradeTypeEnum {
  lOWER_GRADES = 'lower_grades',
  UPPER_GRADES = 'upper_grades',
}

export enum userTypeEnum {
  STUDENT = 'student',
  MENTOR = 'mentor',
  TEACHER = 'teahcer',
  PARENT = 'parent',
  ALW_TEACHER = 'alw_teacher',
  SCHOOL_ADMIN = 'school_admin',
  BRANCH_MANAGER = 'branch_manager',
}
