import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  getWeeklyPlanAction,
  getWeekLessonsToDoAction,
  getWeekLiveSessionsAction,
  getChildTeachersAction,
  getParentsQuestionsAction,
  getLessonDetailsAction,
} from './actions';
import {
  getWeeklyPlan,
  getWeekLessonsToDo,
  getWeekLiveSessions,
  getChildTeachers,
  getParentQuestions,
  getLessonDetails,
} from './api';
import { WeeklyPlanItemType } from './types';

function* getWeeklyPlanSaga(): SagaIterator<void> {
  try {
    const weeklyPlanData: WeeklyPlanItemType[] = yield call(getWeeklyPlan);
    yield put(getWeeklyPlanAction.success(weeklyPlanData));
  } catch (error) {
    yield put(getWeeklyPlanAction.failure(error));
  }
}

function* getWeekLessonsToDoSaga({
  payload,
}: ReturnType<typeof getWeekLessonsToDoAction.request>): SagaIterator<void> {
  try {
    const data = yield call(getWeekLessonsToDo, payload);
    yield put(getWeekLessonsToDoAction.success(data));
  } catch (error) {
    yield put(getWeekLessonsToDoAction.failure(error));
  }
}

function* getWeekLiveSessionsSaga({
  payload,
}: ReturnType<typeof getWeekLiveSessionsAction.request>): SagaIterator<void> {
  try {
    const data = yield call(getWeekLiveSessions, payload);
    yield put(getWeekLiveSessionsAction.success(data));
  } catch (error) {
    yield put(getWeekLiveSessionsAction.failure(error));
  }
}

function* getChildTeachersSaga({
  payload,
}: ReturnType<typeof getChildTeachersAction.request>): SagaIterator<void> {
  try {
    const data = yield call(getChildTeachers, payload);
    yield put(getChildTeachersAction.success(data));
  } catch (error) {
    yield put(getChildTeachersAction.failure(error));
  }
}

function* getParentQuestionsSaga({
  payload,
}: ReturnType<typeof getParentsQuestionsAction.request>): SagaIterator<void> {
  try {
    const data = yield call(getParentQuestions, payload);
    yield put(getParentsQuestionsAction.success(data));
  } catch (error) {
    yield put(getParentsQuestionsAction.failure(error));
  }
}

function* getLessonDetailsSaga({
  payload,
}: ReturnType<typeof getLessonDetailsAction.request>): SagaIterator<void> {
  try {
    const { unitLessonDetails } = yield call(getLessonDetails, payload);
    yield put(getLessonDetailsAction.success(unitLessonDetails));
  } catch (error) {
    yield put(getLessonDetailsAction.failure(error));
  }
}

function* DashboardSaga(): SagaIterator {
  yield all([
    takeEvery(getWeeklyPlanAction.request, getWeeklyPlanSaga),
    takeEvery(getWeekLiveSessionsAction.request, getWeekLiveSessionsSaga),
    takeEvery(getWeekLessonsToDoAction.request, getWeekLessonsToDoSaga),
    takeEvery(getChildTeachersAction.request, getChildTeachersSaga),
    takeEvery(getParentsQuestionsAction.request, getParentQuestionsSaga),
    takeEvery(getLessonDetailsAction.request, getLessonDetailsSaga),
  ]);
}

export default DashboardSaga;
