import camelcaseKeys from 'camelcase-keys';
import API from '../../utils/api';
import {
  b2bTeacherStatsApi,
  b2bTeacherStudentsApi,
  b2bTeacherSessionsApi,
  b2bTeacherClassRoomOptionsApi,
  b2bTeacherStudentProfileAPI,
  b2bTeacherStudentAttachmentAPI,
} from '../../utils/apiEndpoints';
import { getSelectedDuration } from '../../utils/helpers';

export type param = {
  start_date?: string;
  end_date?: string;
  page?: number;
  page_size?: number;
  name?: string;
  sort_by?: string;
  sort_type?: string;
};

export async function getB2bTeacherStats(payload: any): Promise<any> {
  const duration = getSelectedDuration(payload.statusType, payload.semesterStartDate);

  const queryParams: param = {
    ...duration,
  };

  const response = await API.get(b2bTeacherStatsApi(payload.teacherID), {
    params: queryParams,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getB2bTeacherStudentList(payload: any): Promise<any> {
  const queryParams: any = {
    page: payload.currentPage,
    page_size: payload.pageSize,
    class: payload.selectedClassRoom,
    pagination: payload.pagination ?? undefined,
    sort_by: payload.sorting?.id,
    sort_type: payload.sorting?.desc ? 'desc' : 'asc',
  };

  if (payload.searchText?.trim()) {
    queryParams.name = payload.searchText?.trim();
  }

  const response = await API.get(b2bTeacherStudentsApi(payload.teacherID), {
    params: queryParams,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getB2bTeacherSessionList(payload: any): Promise<any> {
  const duration = getSelectedDuration(payload.statusType, payload.semesterStartDate);

  const queryParams: param = {
    page: payload.currentPage,
    page_size: payload.pageSize,
    sort_by: payload.sorting?.id,
    sort_type: payload.sorting?.desc ? 'desc' : 'asc',
    ...duration,
  };

  if (payload.searchText?.trim()) {
    queryParams.name = payload.searchText?.trim();
  }

  const response = await API.get(b2bTeacherSessionsApi(payload.teacherID), {
    params: queryParams,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getB2bTeacherClassRoomOptions(payload: any): Promise<any> {
  const response = await API.get(b2bTeacherClassRoomOptionsApi(payload.teacherID));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getB2bTeacherStudentProfile(payload: any): Promise<any> {
  const { teacherID, studentID } = payload;
  const response = await API.get(b2bTeacherStudentProfileAPI(teacherID, studentID));
  return camelcaseKeys(response.data, { deep: true });
}

export async function postTeacherStudentAttachment(payload: any): Promise<any> {
  const { file, studentID } = payload;
  const data = new FormData();
  data.append('file', file);

  const response = await API.post(b2bTeacherStudentAttachmentAPI(studentID), data);
  return camelcaseKeys(response.data, { deep: true });
}
