import React from 'react';
import { FormattedRelativeTime, FormattedTime, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Lang } from 'lang';
import cn from 'classnames';

import { NewButton, ButtonTypes } from 'lib';

import { getFormattedLabel } from 'utils/formattedLocals';
import { isArabicMode } from 'utils/helpers';
import { formatDateWithLocale, isToday, currentTimeDiff } from 'utils/date-time';

import { NotificationsType, NotificationType } from 'store/notifications/types';

import style from './NotificationItem.module.scss';

export type NotificationItemProps = NotificationsType & {
  onClose: Function;
  isGradeOneToThree?: boolean;
};

export const NotificationItem = ({
  textEn,
  textAr,
  isRead,
  notifyAt,
  notificationType,
  meta,
  isGradeOneToThree,
  actions,
}: NotificationItemProps) => {
  const { locale, formatDate, formatTime } = useIntl();
  const history = useHistory();

  const replaceSubject = (text: string, subjectName: string): string => {
    return text.replace('<subject_name>', getFormattedLabel(subjectName, isArabicMode(locale)));
  };

  const replaceDateTime = (text: string, datetime: Date): string => {
    const date = formatDate(datetime, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const time = formatTime(datetime, {
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h12',
    });
    if (text.includes('<date>') && text.includes('<time>') && isArabicMode(locale)) {
      const initialNotification = text.split('<date>').pop();
      return `${initialNotification} ${date} الساعة ${time}`;
    }
    return text.replace('<date>', date).replace('<time>', time);
  };

  let text = locale === Lang.ar ? textAr : textEn;

  const dateTime = meta?.datetime || meta?.startTime;
  if (dateTime) {
    text = replaceDateTime(text, new Date(dateTime));
  }

  if (meta?.subjectName) {
    text = replaceSubject(text, meta?.subjectName);
  }

  if (text.includes('<teacher_name>') && meta.teacherName) {
    text = text.replace('<teacher_name>', meta.teacherName);
  }

  if (text.includes('<subject>') && meta.subject) {
    text = text.replace('<subject>', getFormattedLabel(meta.subject, isArabicMode(locale)));
  }

  if (text.includes('<time>') && meta.time) {
    text = text.replace('<time>', formatDateWithLocale(meta.time, locale, 'hh:mm a'));
  }

  if (text.includes('<datetime>') && meta.datetime) {
    text = text.replace(
      '<datetime>',
      formatDate(meta.datetime, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    );
  }

  return (
    <>
      <div
        // onClick={(): void => handleClick()}   //may be used in future
        className={cn(style.notificationItem, {
          [style.isRead]: isRead,
          [style.grade]: isGradeOneToThree,
          [style.system]: [
            NotificationType.System,
            NotificationType.ContentAssigned,
            NotificationType.NewBadge,
          ].includes(notificationType),
          [style.one]: notificationType === NotificationType.OneToOne,
          [style.live]: notificationType === NotificationType.LiveClass,
        })}
      >
        <div className={style.notificationItem__text}>{text}</div>
        <div className={style.notificationItem__time}>
          {isToday(new Date(notifyAt)) ? (
            <FormattedRelativeTime
              value={currentTimeDiff(notifyAt, 'secs')}
              numeric="auto"
              style="short"
              updateIntervalInSeconds={1}
            />
          ) : (
            <FormattedTime value={notifyAt} hour12 />
          )}
        </div>
      </div>
      {actions?.map(({ href, title }) => (
        <NewButton key={href} style={[ButtonTypes.buttonCard]} onClick={() => history.push(href)}>
          {title}
        </NewButton>
      ))}
    </>
  );
};
