import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';

export type sendParentInviteType = {
  name: string;
  value: string;
  callback?: (isSuccess: boolean, error?: string) => void;
};

export const sendParentInvite = async ({ name, value }: sendParentInviteType): Promise<void> => {
  const body = snakeCaseKeys({ [name]: value });
  await API.post('api/alw/invitation/parent', body);
};
