import * as React from "react";
const SvgSquares = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", height: 60, width: 60, viewBox: "0 0 64 64", ...props }, /* @__PURE__ */ React.createElement("title", null, "Loading..."), /* @__PURE__ */ React.createElement("g", { strokeLinecap: "square", strokeWidth: 2, fill: "none", stroke: "#1f2b7a", strokeLinejoin: "miter", className: "nc-icon-wrapper", strokeMiterlimit: 10 }, /* @__PURE__ */ React.createElement("g", { className: "nc-loop_squares-64" }, /* @__PURE__ */ React.createElement("rect", { x: 2, y: 2, width: 26, height: 26, "data-cap": "butt", transform: "translate(15.623487999998034 15.623487999998034) scale(0.5117660000000614)", style: {
  opacity: 0.7070596000000369
}, strokeLinecap: "butt" }), /* @__PURE__ */ React.createElement("rect", { x: 36, y: 36, width: 26, height: 26, "data-cap": "butt", transform: "translate(20.423487999998038 20.423487999998038) scale(0.3617660000000613)", style: {
  opacity: 0.6170596000000368
}, strokeLinecap: "butt" }), /* @__PURE__ */ React.createElement("rect", { x: 36, y: 2, width: 26, height: 26, "data-cap": "butt", transform: "translate(25.223487999998035 25.223487999998035) scale(0.2117660000000614)", style: {
  opacity: 0.5270596000000368
}, strokeLinecap: "butt", stroke: "#1f2b7a" }), /* @__PURE__ */ React.createElement("rect", { x: 2, y: 36, width: 26, height: 26, "data-cap": "butt", transform: "translate(10.823487999998036 10.823487999998036) scale(0.6617660000000614)", style: {
  opacity: 0.7970596000000368
}, strokeLinecap: "butt", stroke: "#1f2b7a" })), /* @__PURE__ */ React.createElement("script", null, '!function(){function t(t,e){for(var n in e)t.setAttribute(n,e[n])}function e(t){this.element=t,this.rect=[this.element.getElementsByTagName("rect")[2],this.element.getElementsByTagName("rect")[1],this.element.getElementsByTagName("rect")[0],this.element.getElementsByTagName("rect")[3]],this.animationId,this.start=null,this.init()}if(!window.requestAnimationFrame){var n=null;window.requestAnimationFrame=function(t,e){var i=(new Date).getTime();n||(n=i);var a=Math.max(0,16-(i-n)),r=window.setTimeout(function(){t(i+a)},a);return n=i+a,r}}e.prototype.init=function(){var t=this;this.animationId=window.requestAnimationFrame(t.triggerAnimation.bind(t))},e.prototype.reset=function(){var t=this;window.cancelAnimationFrame(t.animationId)},e.prototype.triggerAnimation=function(e){var n=this;this.start||(this.start=e);var i=e-this.start,a=Math.min(i/50,24.5);1225>i||(this.start=this.start+1225);var s=[],o=[],m=[],c=[];for(r=0;4>r;r++){var h=1+.15*r;s[r]=Math.min(Math.abs(h-a/10),1),o[r]=32*(1-s[r]),m[r]=32*(1-s[r]),c[r]=.6*s[r]+.4,t(this.rect[r],{transform:"translate("+o[r]+" "+m[r]+") scale("+s[r]+")",style:"opacity:"+c[r]+";"})};if(document.documentElement.contains(this.element))window.requestAnimationFrame(n.triggerAnimation.bind(n))};var i=document.getElementsByClassName("nc-loop_squares-64"),a=[];if(i)for(var r=0;i.length>r;r++)!function(t){a.push(new e(i[t]))}(r);document.addEventListener("visibilitychange",function(){"hidden"==document.visibilityState?a.forEach(function(t){t.reset()}):a.forEach(function(t){t.init()})})}();')));
export default SvgSquares;
