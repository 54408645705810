import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import { AxiosResponse } from 'axios';

import API from 'utils/api';
import {
  getQuestionBankSubjectsAPI,
  getLessonLearningObjectsAPI,
  getLessonUnitsAPI,
  getLessonResultsAPI,
  getSampleQuestionsAPI,
  getLearningObjectivesProblemAPI,
  getTeacherClassesAPI,
  getTeacherSubjectsAPI,
  getTeacherUnitsAPI,
  getTeacherLessonsAPI,
  getAnswerDetailOptionsAPI,
  createDeleteQuestionAPI,
  getQuestionListAPI,
  uploadQuestionImageAPI,
  getSubjectGradesAPI,
  learningObjectiveSmartScoreAPI,
  updateExpectedAnswersAPI,
  createLearningObjectiveAPI,
} from 'utils/apiEndpoints';

import {
  learningObjectPayload,
  fetchLessonsPayload,
  fetchUnitsPayload,
  teacherQuestionLocatorsPayload,
  questionListPayloadType,
  questionListResponse,
  uploadQuestionImagePayload,
  fetchClassesSearchPayload,
  TeacherSubjectParam,
  TeacherGradesType,
  CreateQuestionResponse,
  teacherUnitsAndLessonsType,
  FetchLearningObjectiveSmartScorePayload,
  UpdateLearningObjectiveSmartScorePayload,
  UpdateExpectedAnswersPayload,
  LearningObjectiveSmartScoreResponse,
  CreateLearningObjectPayload,
  UpdateLearningObjectPayload,
  CreateQuestionPayload,
  fetchSampleQuestionsPayload,
} from './types';
import { subjectsAdapter, subjectsClassesAdapter } from './adapter';

export async function loadSubjects(search?: string) {
  const response = await API.get(getQuestionBankSubjectsAPI(search));
  return camelcaseKeys(subjectsAdapter(response.data), { deep: true });
}

export async function getLessonLearningObjects({
  subjectId,
  lessonKey,
  page,
}: learningObjectPayload) {
  const params: { page?: number } = {};
  if (page !== undefined) {
    params.page = page;
  }
  const response = await API.get(getLessonLearningObjectsAPI(subjectId, lessonKey), {
    params,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getLessonUnits(payload: fetchUnitsPayload) {
  const response = await API.get(getLessonUnitsAPI(payload.unitKey, payload?.searchQuery));
  const [data] = camelcaseKeys(subjectsAdapter(response.data), { deep: true });
  return data || {};
}

export async function getLessonResults({ subjectId, lessonKey }: learningObjectPayload) {
  const response = await API.get(getLessonResultsAPI(subjectId, lessonKey));
  return response.data;
}

export async function getSampleQuestion({
  subjectId,
  lessonLearningObjectiveId,
  pageNumber,
}: fetchSampleQuestionsPayload) {
  const response = await API.get(
    getSampleQuestionsAPI(subjectId, lessonLearningObjectiveId, pageNumber),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function getLearningObjectiveProblems({
  subjectId,
  learningObjectId,
  lessonKey,
  pageSize,
}: fetchLessonsPayload) {
  const response = await API.get(
    getLearningObjectivesProblemAPI(subjectId, learningObjectId, lessonKey, pageSize),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function archiveQuestion(questionId: number): Promise<void> {
  const response = await API.delete(createDeleteQuestionAPI(questionId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeacherClassesData(payload: fetchClassesSearchPayload) {
  const response = await API.get(getTeacherClassesAPI(payload?.searchQuery));
  return subjectsClassesAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function getQuestionList(
  payload: questionListPayloadType,
): Promise<questionListResponse> {
  const { currentPage, pageSize, lessonId, courseId } = payload;
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
  };

  const response = await API.get(getQuestionListAPI(courseId, lessonId), {
    params: queryParams,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeacherSubjects({ gradeId }: TeacherSubjectParam) {
  const response = await API.get(getTeacherSubjectsAPI(gradeId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeacherUnits({ subjectKey }: teacherQuestionLocatorsPayload) {
  const response = await API.get(getTeacherUnitsAPI(subjectKey));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeacherLessons({
  subjectKey,
  unitKey,
}: teacherQuestionLocatorsPayload): Promise<Array<teacherUnitsAndLessonsType>> {
  const response = await API.get(getTeacherLessonsAPI(subjectKey, unitKey));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getAnswerDetailOptions() {
  const response = await API.get(getAnswerDetailOptionsAPI());
  return camelcaseKeys(response.data, { deep: true });
}

export async function uploadQuestionImage({ image, problem_key }: uploadQuestionImagePayload) {
  const formData = new FormData();
  problem_key && formData.append('problem_key', problem_key);
  formData.append('image', image);
  const response = await API.post(uploadQuestionImageAPI(), formData);
  return camelcaseKeys(response.data, { deep: true });
}

export async function createEditQuestion({
  isEditing,
  questionGroupId,
  ...restPayload
}: Omit<CreateQuestionPayload, 'callback'>): Promise<CreateQuestionResponse> {
  let response = {} as AxiosResponse<CreateQuestionResponse>;
  if (isEditing) {
    response = await API.patch(
      `${createDeleteQuestionAPI()}${questionGroupId}/`,
      snakeCaseKeys(restPayload, { deep: true }),
    );
  } else {
    response = await API.post(
      createDeleteQuestionAPI(),
      snakeCaseKeys(restPayload, { deep: true }),
    );
  }
  return camelcaseKeys(response.data, { deep: true });
}

export async function getSubjectGrades(): Promise<Array<TeacherGradesType>> {
  const response = await API.get(getSubjectGradesAPI());
  return camelcaseKeys(response.data, { deep: true });
}

export async function getLeaningObjectiveSmartScore({
  subjectId,
  lessonKey,
  learningObjectiveId,
}: FetchLearningObjectiveSmartScorePayload): Promise<LearningObjectiveSmartScoreResponse> {
  const response = await API.get(
    learningObjectiveSmartScoreAPI(subjectId, lessonKey, learningObjectiveId),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateLeaningObjectiveSmartScore({
  subjectId,
  lessonKey,
  learningObjectiveId,
  smartScore,
  problemKey,
}: UpdateLearningObjectiveSmartScorePayload): Promise<LearningObjectiveSmartScoreResponse> {
  const response = await API.post(
    learningObjectiveSmartScoreAPI(subjectId, lessonKey, learningObjectiveId),
    { smart_score: smartScore, problem_key: problemKey },
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateExpectedAnswers({
  learningObjectiveId,
  expectedCorrectAnswers,
}: UpdateExpectedAnswersPayload): Promise<void> {
  await API.patch(
    updateExpectedAnswersAPI(learningObjectiveId),
    snakeCaseKeys({ expectedCorrectAnswers }),
  );
}

export async function createUpdateLearningObjective(
  payload: CreateLearningObjectPayload | UpdateLearningObjectPayload,
) {
  const ApiCall = 'id' in payload ? API.put : API.post;
  const response = await ApiCall(createLearningObjectiveAPI(), snakeCaseKeys(payload));
  return camelcaseKeys(response.data, { deep: true });
}
