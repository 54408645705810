import { SagaIterator } from 'redux-saga';
import camelCaseKeys from 'camelcase-keys';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  createTimeTableSlot,
  updateTimeTableSlot,
  currentSemesterWeeks,
  loadTeacherAvailability,
  loadTimeTableData,
  enableResetButton,
  loadPublishStatus,
} from './actions';
import {
  createSlot,
  updateSlot,
  getTeacherAvailability,
  getTimeTableData,
  getWeeksData,
  getPublishStatus,
} from './api';
import { TeacherDetailType, TimeTableDataType, WeeksList, SlotUpdationSuccessType } from './types';

function* handleTimeTableRequest({
  payload,
}: ReturnType<typeof loadTimeTableData.request>): SagaIterator<void> {
  try {
    const timeTableData: TimeTableDataType = yield call(getTimeTableData, payload);
    yield put(loadTimeTableData.success(timeTableData));
  } catch (error) {
    yield put(loadTimeTableData.failure(error));
  } finally {
    payload.callback?.();
  }
}

function* handPublishStatusRequest({
  payload,
}: ReturnType<typeof loadPublishStatus.request>): SagaIterator<void> {
  try {
    const status: { publishStatus: boolean } = yield call(getPublishStatus, payload);
    yield put(loadPublishStatus.success(status.publishStatus));
  } catch (error) {
    yield put(loadPublishStatus.failure(error));
  }
}

function* handleSlotCreationRequest({
  payload,
}: ReturnType<typeof createTimeTableSlot.request>): SagaIterator<void> {
  try {
    const timeTableData = yield call(createSlot, payload);
    yield put(createTimeTableSlot.success(timeTableData));
    payload.callback(true, undefined);
  } catch (error) {
    yield put(createTimeTableSlot.failure(error?.error?.join(',')));
    payload.callback(false, undefined, error?.error?.[0]);
  }
}

function* handleSlotUpdationRequest({
  payload,
}: ReturnType<typeof updateTimeTableSlot.request>): SagaIterator<void> {
  try {
    const updateSlotResponse = yield call(updateSlot, payload);

    const slotUpdationSuccessData: SlotUpdationSuccessType = {
      prevSlotId: payload.slotId,
      newSlotId: updateSlotResponse?.id,
      published: updateSlotResponse?.published,
      start: new Date(payload.slot.startDate as Date),
      end: new Date(payload.slot.endDate as Date),
    };

    yield put(updateTimeTableSlot.success(slotUpdationSuccessData));
    yield put(enableResetButton());
    payload.callback(true, payload.slotId);
  } catch (error) {
    yield put(updateTimeTableSlot.failure(error?.error?.join(',')));
    payload.callback(false, payload.slotId, error?.error?.[0]);
  }
}

function* handleWeekRequest(): SagaIterator<void> {
  try {
    const weeksData: WeeksList = yield call(getWeeksData);
    yield put(currentSemesterWeeks.success(weeksData));
  } catch (error) {
    yield put(currentSemesterWeeks.failure(error));
  }
}

function* handleTeacherAvailabilityRequest({
  payload,
}: ReturnType<typeof loadTeacherAvailability.request>): SagaIterator<void> {
  try {
    const teacherAvailability: TeacherDetailType = yield call(getTeacherAvailability, payload);
    yield put(
      loadTeacherAvailability.success({
        teacherDetail: teacherAvailability,
      }),
    );
  } catch (error) {
    yield put(
      loadTeacherAvailability.failure({ errorMessage: camelCaseKeys(error, { deep: true }) }),
    );
  }
}

function* timetableSaga(): SagaIterator {
  yield all([takeEvery(loadPublishStatus.request, handPublishStatusRequest)]);
  yield all([takeEvery(loadTimeTableData.request, handleTimeTableRequest)]);
  yield all([takeEvery(createTimeTableSlot.request, handleSlotCreationRequest)]);
  yield all([takeEvery(updateTimeTableSlot.request, handleSlotUpdationRequest)]);
  yield all([takeEvery(currentSemesterWeeks.request, handleWeekRequest)]);
  yield all([takeEvery(loadTeacherAvailability.request, handleTeacherAvailabilityRequest)]);
}

export default timetableSaga;
