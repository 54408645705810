import { PaymentPlans, Subjects, CardBrandType } from 'store/subscriptions/types';

export enum SubscriptionFields {
  PLAN = 'plan',
  SUBJECTS_SERVICES = 'subjectsServices',
  SERVICE = 'service',
  BRAND = 'brand',
  SUBJECTS = 'subject',
  CURRENT_STEP = 'currentStep',
  TOTAL_PRICE = 'totalPrice',
  DISCOUNTED_PRICE = 'discountedPrice',
  CHILDREN = 'children',
  CURRENT_USER_ID = 'currentUserId',
  CURRENT_CHILD_GRADE = 'currentChildGrade',
  CURRENT_USER_INDEX = 'currentUserIndex',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  GRADE = 'grade',
  isDraftedView = 'isDraftedView',
  COUPON_DATA = 'couponData',
  DISCOUNT = 'discount',
  VOUCHER_CODE = 'voucherCode',
}

export enum DraftedActions {
  ADD_CHILD = 'add_child',
  UPGRADE_PLAN = 'upgrade_plan',
  ADD_SERVICES = 'add_services',
  PENDING_PAYMENT = 'pending_payment',
  ADD_PLAN = 'add_plan',
}

export enum DraftedUserParams {
  NEW_USER = 'newUserId',
  USER = 'user',
}

export type childrenSubjects = {
  userId: string;
  subjects: Subjects[];
};

export enum SubscriptionPages {
  PLAN = 'plan',
  SUBJECT = 'subject',
  SERVICE = 'services',
  SUMMARY = 'summary',
}

export type ChildSubscriptionInfo = {
  userId: number;
  subjects: Subjects[];
  plan: PaymentPlans | undefined;
  lastSuccessfulPlan?: PaymentPlans;
  totalPrice: number;
  amountPaid: number;
  name?: string;
  grade?: number;
  draftable?: boolean;
  isAdd?: boolean;
  clear?: boolean;
  voucherCode?: string;
  isVoucherActive?: boolean;
  isAllowedFreePlan?: boolean;
};

export type CouponDataType = {
  couponCode: string;
  percentageDiscount: number;
  isVoucher: boolean;
};

export type FormType = {
  isDraftedView?: boolean;
  firstName: string;
  lastName: string;
  grade: number | string;
  children: ChildSubscriptionInfo[];
  totalPrice: number;
  currentUserIndex: number;
  currentStep: number;
  brand?: CardBrandType;
  currentChildGrade: number | undefined;
  currentUserId: number | undefined;
  isAnyChildEnrolled?: boolean;
  couponData?: CouponDataType;
  discountedPrice?: number;
  discount?: number;
  isAllowedFreePlan?: boolean;
  isVoucherActive?: boolean;
};
