import { useIntl } from 'react-intl';

type FormatMessageType = {
  id: string;
  defaultMessage: string;
};

export type UseFormatMessageType = (id: string, defaultMessage: string, options?: object) => string;

export const useFormat: UseFormatMessageType = (id, defaultMessage, options) => {
  const { formatMessage } = useIntl();
  const message = formatMessage(
    {
      id,
      defaultMessage,
    },
    { ...options },
  );
  return message;
};

export const useFormatMessages = (
  messagesObject: Record<string, FormatMessageType>,
): Record<string, string> => {
  const { formatMessage } = useIntl();
  const formattedMessages: Record<string, string> = {};

  Object.entries(messagesObject).forEach((message) => {
    const [key, value] = message;
    formattedMessages[key] = formatMessage({
      id: value.id,
      defaultMessage: value.defaultMessage,
    });
  });

  return formattedMessages;
};
