import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  loadAssignedSubjectsAction,
  addAssignedSubjectsAction,
  resetAssignedSubjectsAction,
} from './actions';
import { SemesterSubjectType } from './types';

export type AssignedSubjectActionType = ActionType<typeof actions>;

export const initialState = {
  data: [],
  fetching: {
    isLoading: true,
    add: false,
  },
  errors: {
    load: '',
    add: '',
  },
};

const data = createReducer<SemesterSubjectType[], AssignedSubjectActionType>(initialState.data)
  .handleAction(loadAssignedSubjectsAction.success, (state, action) => {
    return [...state, ...action.payload];
  })
  .handleAction(resetAssignedSubjectsAction, () => initialState.data);

const fetching = combineReducers({
  isLoading: createReducer<boolean, AssignedSubjectActionType>(initialState.fetching.isLoading)
    .handleAction(loadAssignedSubjectsAction.request, () => true)
    .handleAction(
      [loadAssignedSubjectsAction.success, loadAssignedSubjectsAction.failure],
      () => false,
    )
    .handleAction(resetAssignedSubjectsAction, () => initialState.fetching.isLoading),
  add: createReducer<boolean, AssignedSubjectActionType>(initialState.fetching.add)
    .handleAction([addAssignedSubjectsAction.request], () => true)
    .handleAction(
      [addAssignedSubjectsAction.success, addAssignedSubjectsAction.failure],
      () => false,
    )
    .handleAction(resetAssignedSubjectsAction, () => initialState.fetching.add),
});

const errors = combineReducers({
  load: createReducer<string, AssignedSubjectActionType>(initialState.errors.load)
    .handleAction(loadAssignedSubjectsAction.failure, (state, { payload }) => payload)
    .handleAction(loadAssignedSubjectsAction.success, () => initialState.errors.load)
    .handleAction(resetAssignedSubjectsAction, () => initialState.errors.load),
  add: createReducer<string, AssignedSubjectActionType>(initialState.errors.add)
    .handleAction(addAssignedSubjectsAction.failure, (state, { payload }) => payload)
    .handleAction(addAssignedSubjectsAction.success, () => initialState.errors.add)
    .handleAction(resetAssignedSubjectsAction, () => initialState.errors.add),
});

const assignedSubjectsReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type AssignedSubjectsStateType = StateType<typeof assignedSubjectsReducer>;

export default assignedSubjectsReducer;
