import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';

import { CountryListType } from 'store/account/types';

import {
  registrationAction,
  registrationChildrenAction,
  invitationAccountAction,
  registrationParentAction,
  otpVerificationAction,
  otpSendAction,
  otpClearAction,
  resetRegistrationAction,
  countryListAction,
} from './actions';
import * as actions from './actions';

export type RegistrationActionType = ActionType<typeof actions>;

export const initialState = {
  fetching: {
    registration: false,
    registrationParent: false,
    registrationChild: false,
    invitationAccount: false,
    countryList: false,
    otpVerification: false,
  },
  errors: {
    registration: '',
    registrationParent: '',
    registrationChild: '',
    invitationAccount: '',
    countryList: '',
  },
  data: {
    is_otp_verified: false,
    source: '',
    resend_otp_time: 0,
    success: false,
    signature: '',
    countryList: [] as CountryListType[],
  },
};

const data = createReducer<typeof initialState.data, RegistrationActionType>(initialState.data)
  .handleAction(
    [
      otpVerificationAction.success,
      otpSendAction.success,
      registrationAction.success,
      otpClearAction.success,
    ],
    (state, action) => ({ ...state, ...action.payload }),
  )
  .handleAction(countryListAction.success, (state, action) => ({
    ...state,
    countryList: [...action.payload],
  }))
  .handleAction(resetRegistrationAction, () => initialState.data);

const fetching = combineReducers({
  registration: createReducer<boolean, RegistrationActionType>(initialState.fetching.registration)
    .handleAction(registrationAction.request, () => true)
    .handleAction([registrationAction.success, registrationAction.failure], () => false),
  registrationChild: createReducer<boolean, RegistrationActionType>(
    initialState.fetching.registrationChild,
  )
    .handleAction(registrationChildrenAction.request, () => true)
    .handleAction(
      [registrationChildrenAction.success, registrationChildrenAction.failure],
      () => false,
    ),
  registrationParent: createReducer<boolean, RegistrationActionType>(
    initialState.fetching.registrationParent,
  )
    .handleAction(registrationParentAction.request, () => true)
    .handleAction(
      [registrationParentAction.success, registrationParentAction.failure],
      () => false,
    ),
  invitationAccount: createReducer<boolean, RegistrationActionType>(
    initialState.fetching.invitationAccount,
  )
    .handleAction([invitationAccountAction.request], () => true)
    .handleAction([invitationAccountAction.success, invitationAccountAction.failure], () => false),
  countryList: createReducer<boolean, RegistrationActionType>(initialState.fetching.countryList)
    .handleAction(countryListAction.request, () => true)
    .handleAction([countryListAction.success, countryListAction.failure], () => false),
  otpVerification: createReducer<boolean, RegistrationActionType>(
    initialState.fetching.otpVerification,
  )
    .handleAction(otpVerificationAction.request, () => true)
    .handleAction(otpVerificationAction.success, () => false)
    .handleAction(otpVerificationAction.failure, () => false),
});

const errors = combineReducers({
  registration: createReducer<string, RegistrationActionType>(initialState.errors.registration)
    .handleAction(registrationAction.failure, (state, { payload }) => payload)
    .handleAction(registrationAction.success, () => ''),
  registrationParent: createReducer<string, RegistrationActionType>(
    initialState.errors.registrationParent,
  )
    .handleAction(registrationParentAction.failure, (state, { payload }) => payload)
    .handleAction(registrationParentAction.success, () => ''),
  registrationChild: createReducer<string, RegistrationActionType>(
    initialState.errors.registrationChild,
  )
    .handleAction(registrationChildrenAction.failure, (state, { payload }) => payload)
    .handleAction(registrationChildrenAction.success, () => ''),
  invitationAccount: createReducer<string, RegistrationActionType>(
    initialState.errors.invitationAccount,
  )
    .handleAction(invitationAccountAction.failure, (state, { payload }) => payload)
    .handleAction(invitationAccountAction.success, () => initialState.errors.invitationAccount)
    .handleAction(countryListAction.failure, (state, { payload }) => payload)
    .handleAction(countryListAction.success, () => initialState.errors.countryList),
});

const registrationReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type RegistrationStateType = StateType<typeof registrationReducer>;

export default registrationReducer;
