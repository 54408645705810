import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Dialog, getPngURL, getSvgURL, IconCommon } from 'lib';
import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import styles from './Orientation.module.scss';
import { OrientationContext } from './OrientationContext';
import WelcomeContent from './WelcomeContent';
import OrientationVideos from './OrientationVideos';

const OrientationDialog = ({ isWelcomeDialog }: { isWelcomeDialog: boolean }) => {
  const { formatMessage } = useIntl();
  const { handleOrientationClose, isOrientationOpen } = useContext(OrientationContext);
  const isGradeOneToThree = useIsGradeOneToThree();

  const backgroundImages = isWelcomeDialog
    ? [
        { name: 'orientation-01', className: 'orientationVideo__img01' },
        { name: 'orientation-02', className: 'orientationVideo__img02' },
      ]
    : [{ name: 'orientation-demo', className: 'orientationVideo__img02' }];

  return (
    <Dialog
      isVisible={isOrientationOpen}
      classNames={cn(styles.modal, isGradeOneToThree && styles.gradeModal)}
    >
      <div className={cn(styles.modal__imagesWrap, isWelcomeDialog ? styles.medium : styles.large)}>
        <div
          className={cn(styles.modal__dialog, isWelcomeDialog ? styles.medium : styles.large)}
          role="document"
        >
          <span className={styles.modal__close} onClick={handleOrientationClose}>
            <IconCommon className="icon-cancel" />
          </span>
          <div className={cn(styles.modal__body, 'text-center')}>
            <div
              className={cn(styles.orientationVideo, {
                [styles.orientationVideoDemo]: !isWelcomeDialog,
                [styles.gradeOrientationVideo]: isGradeOneToThree,
              })}
            >
              {isWelcomeDialog ? <WelcomeContent /> : <OrientationVideos />}
            </div>
            {backgroundImages.map(({ name, className }) => (
              <img
                key={name}
                src={getSvgURL(name)}
                className={styles[className]}
                alt="orientationVideo"
              />
            ))}
          </div>
        </div>

        {isGradeOneToThree && (
          <img
            src={getPngURL('smile-face', true)}
            alt={formatMessage({
              id: 'exit.exam.dialog.image.label',
              defaultMessage: 'smile face',
            })}
            className={styles.smile_face}
          />
        )}
      </div>
    </Dialog>
  );
};

export default OrientationDialog;
