import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { inviteParentsActions } from './actions';
import { sendParentInvite } from './api';

function* inviteParent({
  payload: { callback, ...payload },
}: ReturnType<typeof inviteParentsActions.request>): SagaIterator<void> {
  try {
    yield call(sendParentInvite, payload);
    callback && callback(true);
    yield put(inviteParentsActions.success(payload.value));
  } catch (error) {
    callback && callback(false, error as string);
    yield put(inviteParentsActions.failure(error));
  }
}

function* inviteParentsSaga(): SagaIterator<void> {
  yield all([takeEvery(inviteParentsActions.request, inviteParent)]);
}

export default inviteParentsSaga;
