import { createAction, createAsyncAction } from 'typesafe-actions';

import { TPaginatedResult } from 'store/common';
import {
  MappingStatusType,
  TGradeMappingPayload,
  TGradingExam,
  TStudentScore,
  TStudentScoreExam,
  TStudentScorePayload,
  TStudentSubjectScore,
  TStudentSubjectsScorePayload,
} from './types';

export const fetchGradeMappingsAction = createAsyncAction(
  '@@GRADING_SYSTEM/FETCH_GRADE_MAPPINGS_REQUEST',
  '@@GRADING_SYSTEM/FETCH_GRADE_MAPPINGS_SUCCESS',
  '@@GRADING_SYSTEM/FETCH_GRADE_MAPPINGS_FAILURE',
)<TGradeMappingPayload, TPaginatedResult<TGradingExam>, string>();

export const addGradeMappingAction = createAction('@@GRADING_SYSTEM/ADD_GRADE_MAPPING')<
  TGradingExam & { pageSize: number }
>();

export const updateGradeMappingAction = createAction(
  '@@GRADING_SYSTEM/UPDATE_GRADE_MAPPING',
)<TGradingExam>();

export const fetchStudentsScoreAction = createAsyncAction(
  '@@GRADING_SYSTEM/FETCH_STUDENTS_SCORE_REQUEST',
  '@@GRADING_SYSTEM/FETCH_STUDENTS_SCORE_SUCCESS',
  '@@GRADING_SYSTEM/FETCH_STUDENTS_SCORE_FAILURE',
)<TStudentScorePayload, TPaginatedResult<TStudentScore>, string>();

export const updateStudentScoreAction = createAction('@@GRADING_SYSTEM/UPDATE_STUDENT_SCORE')<
  TStudentScoreExam[]
>();

export const updateScorePublishStatusAction = createAction(
  '@@GRADING_SYSTEM/UPDATE_SCORE_PUBLISH_STATUS',
)<{ studentID: number }>();

export const updateExamScoreStatusAction = createAction(
  '@@GRADING_SYSTEM/UPDATE_EXAM_SCORE_STATUS',
)<{ id: number; status: MappingStatusType }>();

export const markAllScoresPublishedAction = createAction(
  '@@GRADING_SYSTEM/MARK_ALL_RECORDS_PUBLISHED',
)();

export const clearStudentScoresAction = createAction('@@GRADING_SYSTEM/CLEAR_STUDENT_RECORDS')();

export const fetchStudentSubjectsScoreAction = createAsyncAction(
  '@@GRADING_SYSTEM/FETCH_STUDENTS_SUBJECT_SCORE_REQUEST',
  '@@GRADING_SYSTEM/FETCH_STUDENTS_SUBJECT_SCORE_SUCCESS',
  '@@GRADING_SYSTEM/FETCH_STUDENTS_SUBJECT_SCORE_FAILURE',
)<TStudentSubjectsScorePayload, TPaginatedResult<TStudentSubjectScore>, string>();

export const updateIssueCertificateStatusAction = createAction(
  '@@GRADING_SYSTEM/UPDATE_ISSUE_CERTIFICATE_STATUS',
)<{ studentID: number }>();
