import { combineReducers } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import {
  loadSubjectsAction,
  loadSemesterSubjects,
  resetSubjectsAction,
  assignSubjectsAction,
} from './actions';
import { SubjectsErrorsType } from './types';

export type SubjectActionType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, SubjectActionType>(true)
  .handleAction([loadSubjectsAction.request, resetSubjectsAction], () => true)
  .handleAction([loadSubjectsAction.success, loadSubjectsAction.failure], () => false)
  .handleAction([loadSemesterSubjects.success, loadSemesterSubjects.failure], () => false)
  .handleAction([assignSubjectsAction.success, assignSubjectsAction.failure], () => false);

const data = createReducer<any, SubjectActionType>({})
  .handleAction(loadSubjectsAction.success, (state, action) => ({
    ...state,
    subjectsList: action.payload,
  }))
  .handleAction(resetSubjectsAction, () => [])
  .handleAction(loadSemesterSubjects.success, (state, { payload }) => ({
    ...state,
    semesterSubjects: payload,
  }))
  .handleAction(assignSubjectsAction.success, (state, { payload }) => ({
    ...state,
    assignSubjects: payload,
  }));

const errors = createReducer<SubjectsErrorsType, SubjectActionType>({})
  .handleAction(loadSubjectsAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadSubjectsAction.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(assignSubjectsAction.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(assignSubjectsAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(resetSubjectsAction, () => ({}));

const subjectReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export default subjectReducer;
