import { map } from 'lodash/fp';
import { ScheduleForTodayType, ScheduleForTodayResponseType } from './types';

export const dataScheduleForTodayAdapter = map(
  ({ endDate, startDate, ...rest }: ScheduleForTodayResponseType): ScheduleForTodayType => ({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    ...rest,
  }),
);
