import { ActionType, createReducer, StateType } from 'typesafe-actions';
import { noop } from 'lodash';
import { combineReducers } from 'redux';
import * as actions from './actions';
import { closeVideoPopup, openVideoPopup } from './actions';
import { VideoPopupType } from './types';

export type ModalActionType = ActionType<typeof actions>;

const initialVideoPopupState = {
  title: '',
  date: new Date(),
  videoUrl: '',
  isOpen: false,
  onClose: noop,
  eventId: '',
};

const modalReducer = combineReducers({
  videoPopup: createReducer<VideoPopupType, ModalActionType>(initialVideoPopupState)
    .handleAction(openVideoPopup, (state, { payload }) => ({ ...payload, isOpen: true }))
    .handleAction(closeVideoPopup, () => initialVideoPopupState),
});

export type ModalReducerType = StateType<typeof modalReducer>;
export default modalReducer;
