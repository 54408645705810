import { createSelector } from 'reselect';

import { DEFAULT_COUNTRY_CODE } from 'constants/common';

import { RootStateType } from '../types';
import { RegistrationStateType } from './reducer';

export const selectRegistration = (state: RootStateType): RegistrationStateType =>
  state.registration;

export const countryListSelector = createSelector(
  selectRegistration,
  ({ data }) => data.countryList,
);

export const selectCountryList = createSelector(selectRegistration, ({ data, fetching }) => ({
  countryList: data.countryList,
  fetchingCountryList: fetching.countryList,
}));

export const selectDefaultCountry = createSelector(countryListSelector, (countries) => {
  const defaultCountry = countries.find((country) => country.isDefault);
  const isoCode = defaultCountry?.isoCode ?? DEFAULT_COUNTRY_CODE;
  return isoCode.toLowerCase();
});
