import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Tour from 'reactour';

import { OrientationStateTypes, OrientationVideosType } from 'store/orientation/types';
import { orientationDemoAction } from 'store/orientation/actions';
import { orientationDemoErrorSelector, orientationDemoSelector } from 'store/orientation/selectors';
import { selectorAccount } from 'store/account/selectors';

import { ButtonTypes, IconCommon, NewButton } from 'lib';
import { isArabicMode } from 'utils/helpers';
import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import OrientationDialog from './OrientationDialog';
import { OrientationContext } from './OrientationContext';
import {
  isOrientationVideoWatched,
  markOrientationVideoWatched,
  THRESHOLD_ORIENTATION_LIMIT,
} from './utils';
import HelpMenus from '../LayoutBase/v2/components/Header/Help/HelpMenus';
import Help from '../LayoutBase/v2/components/Header/Help';
import styles from './Orientation.module.scss';
import { msgs } from './messages';

type PropsType = {
  userType: string;
  isOrientationOpenByDefault?: boolean;
};

const Orientation = ({ userType, isOrientationOpenByDefault = true }: PropsType) => {
  const { formatMessage: fm, locale } = useIntl();
  const dispatch = useDispatch();
  const [isOrientationOpen, setOrientationOpen] = useState(true);
  const [showHelpTour, setShowHelpTour] = useState(false);
  const [orientationData, setOrientationData] = useState<OrientationVideosType | null>(null);
  const [orientationState, setOrientationState] = useState<OrientationStateTypes>(
    OrientationStateTypes.WELCOME,
  );

  const orientationDemoData = useSelector(orientationDemoSelector);
  const orientationDemoError = useSelector(orientationDemoErrorSelector);
  const {
    fetching: { load: isLoadingAccount },
    data: account,
  } = useSelector(selectorAccount);

  const isGradeOneToThree = useIsGradeOneToThree();
  const isVideoMarkWatched = isOrientationVideoWatched();
  const isArabic = isArabicMode(locale);

  const { userLoginCount } = account || {};
  const isWelcomeDialog = orientationState === OrientationStateTypes.WELCOME;
  const isValidThreshold =
    !isLoadingAccount &&
    !orientationDemoError &&
    !isVideoMarkWatched &&
    userLoginCount <= THRESHOLD_ORIENTATION_LIMIT;

  const gotoHelpTour = () => setShowHelpTour(true);

  const handleOrientationOpen = useCallback(() => setOrientationOpen(true), []);
  const closeHelpTour = useCallback(() => setShowHelpTour(false), [setShowHelpTour]);

  const changeStateToOrientationVideo = useCallback(
    () => setOrientationState(OrientationStateTypes.ORIENTATION_VIDEO),
    [],
  );

  const handleOrientationClose = useCallback(() => {
    setOrientationOpen(false);
    markOrientationVideoWatched();
  }, [setOrientationOpen]);

  const triggerOrientationVideo = useCallback(
    (data: OrientationVideosType) => {
      if (isWelcomeDialog) changeStateToOrientationVideo();
      setOrientationData(data);
    },
    [changeStateToOrientationVideo, isWelcomeDialog],
  );

  const triggerOrientationDemo = useCallback(() => {
    const actions = [
      {
        style: isGradeOneToThree
          ? [ButtonTypes.btnGrade, ButtonTypes.btnGradeSmall, ButtonTypes.btnGradeRed]
          : [ButtonTypes.buttonSecondary],
        title: fm(msgs.skipButtonTitle),
        acton: handleOrientationClose,
      },
      {
        style: isGradeOneToThree
          ? [ButtonTypes.btnGrade, ButtonTypes.btnGradeSmall, ButtonTypes.btnGradeOrange]
          : [ButtonTypes.buttonPrimary],
        title: `${fm(msgs.exploreButtonTitle)}`,
        icon: isGradeOneToThree && (
          <IconCommon
            className={isArabic ? 'icon-chevron-medium' : 'icon-chevron-medium-right'}
            space="sl10"
          />
        ),
        acton: () => {
          handleOrientationClose();
          gotoHelpTour();
        },
      },
    ];
    setOrientationData({
      data: orientationDemoData,
      renderActionButtons: () =>
        actions.map(({ style, acton, title, icon }) => (
          <NewButton key={title} style={style} onClick={acton}>
            {title}
            {icon}
          </NewButton>
        )),
    });
  }, [fm, orientationDemoData, handleOrientationClose, isArabic, isGradeOneToThree]);

  const orientationContextValue = useMemo(
    () => ({
      userType,
      isOrientationOpen,
      orientationData,
      handleOrientationClose,
      triggerOrientationVideo,
      changeStateToOrientationVideo,
      handleOrientationOpen,
    }),
    [
      userType,
      isOrientationOpen,
      orientationData,
      handleOrientationClose,
      triggerOrientationVideo,
      changeStateToOrientationVideo,
      handleOrientationOpen,
    ],
  );

  useEffect(() => {
    if (isValidThreshold) {
      if (!orientationDemoData) dispatch(orientationDemoAction.request({ userType }));
      else triggerOrientationDemo();
    }
  }, [dispatch, orientationDemoData, isValidThreshold, userType, triggerOrientationDemo]);

  useEffect(() => {
    setOrientationOpen(isOrientationOpenByDefault);
  }, [isOrientationOpenByDefault]);

  return (
    <OrientationContext.Provider value={orientationContextValue}>
      {orientationData && <OrientationDialog isWelcomeDialog={isWelcomeDialog} />}
      <Tour
        className={styles.helpTour}
        maskClassName={styles.tour__backdrop}
        steps={[
          {
            content: <HelpMenus isOpenMenu closeHelpTour={closeHelpTour} />,
            position: 'bottom',
            selector: '#header-help',
            stepInteraction: false,
          },
        ]}
        isOpen={showHelpTour}
        onRequestClose={() => setShowHelpTour(false)}
        showNumber={false}
        showCloseButton={false}
        showButtons={false}
        showNavigation={false}
        disableInteraction
      />
      <Help />
    </OrientationContext.Provider>
  );
};

export default Orientation;
