import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { studentGradeSelector } from 'store/account/selectors';

import { ONE_TO_THREE_GRADES } from 'constants/entities';
import { HIDE_GRADE_ONE_TO_THREE } from 'constants/settings';

export const useIsGradeOneToThree = (): boolean => {
  const studentGrade = useSelector(studentGradeSelector);

  const shouldHideGradeOneToThree = HIDE_GRADE_ONE_TO_THREE === 'true';

  return useMemo(() => {
    if (shouldHideGradeOneToThree) return false;

    return ONE_TO_THREE_GRADES.includes(studentGrade);
  }, [shouldHideGradeOneToThree, studentGrade]);
};
