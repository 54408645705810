import cn from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { Loader } from 'lib';
import styles from './PortalCard.module.scss';

export type PortalCardProps = {
  isLoading?: boolean;
  children: ReactNode;
};

const PortalCard: FunctionComponent<PortalCardProps> = ({ isLoading = false, children }) => {
  if (isLoading) return <Loader />;

  return <div className={cn(styles.portalcard, styles.row)}>{children}</div>;
};

export default PortalCard;
