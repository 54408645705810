import React, { ReactNode, useState } from 'react';
import cn from 'classnames';
import { noop } from 'lodash/fp';

import styles from '../InputField.module.scss';
import { FocusEventType, InputBaseProps, InputTypes } from './Input.types';

type InputProps = InputBaseProps & {
  render: (inputProps: any) => ReactNode;
};

export const InputBase = ({
  type = InputTypes.text,
  id,
  name,
  disabled = false,
  placeholder = '',
  value = '',
  required = false,
  isError = false,
  isInline = false,
  onBlur = noop,
  onFocus = noop,
  onChange = noop,
  onKeyDown = noop,
  onCopy = noop,
  onPaste = noop,
  onCut = noop,
  render,
  autoComplete,
  readOnly = false,
  maxLength,
  icon,
}: InputProps) => {
  const [isInputFocused, setInputFocused] = useState(false);

  const handleBlur: FocusEventType = (event) => {
    onBlur(event);
    setInputFocused(false);
  };

  const handleFocus: FocusEventType = (event) => {
    onFocus(event);
    setInputFocused(true);
  };

  const inputProps = {
    type: type !== InputTypes.search ? type : undefined,
    id,
    name,
    disabled,
    value,
    placeholder,
    required,
    onChange,
    onKeyDown,
    onCopy,
    onPaste,
    onCut,
    onFocus: handleFocus,
    onBlur: handleBlur,
    'aria-label': placeholder,
    autoComplete,
    readOnly,
    maxLength,
  };

  return (
    <div
      className={cn({
        [styles.isFocus]: isInputFocused,
        [styles.isDisabled]: disabled,
        [styles.isError]: isError,
        [styles.inline]: isInline,
        [styles.search]: type === InputTypes.search,
        [styles.hasIcon]: icon,
      })}
    >
      {render(inputProps)}
    </div>
  );
};
