import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';
import { sendFeedbackAction, resetFeedbackAction } from './actions';
import * as actions from './actions';

export type FeedbackActionType = ActionType<typeof actions>;

const fetching = combineReducers({
  send: createReducer<boolean, FeedbackActionType>(false)
    .handleAction(sendFeedbackAction.request, () => true)
    .handleAction([sendFeedbackAction.success, sendFeedbackAction.failure], () => false)
    .handleAction(resetFeedbackAction, () => false),
});

const errors = combineReducers({
  send: createReducer<string, FeedbackActionType>('')
    .handleAction(sendFeedbackAction.failure, (state, action) => action.payload)
    .handleAction(sendFeedbackAction.success, () => '')
    .handleAction(resetFeedbackAction, () => ''),
});

const feedbackReducer = combineReducers({
  fetching,
  errors,
});

export type FeedbackReducerStateType = StateType<typeof feedbackReducer>;

export default feedbackReducer;
