import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import { orientationVideosAPI, orientationQuicKDemoAPI } from 'utils/apiEndpoints';

import { OrientationResponseType, OrientationVideoRequestType } from './types';

const requestParams = (userType: string) => ({
  params: {
    user_type: userType,
  },
});

export async function fetchOrientationVideo({
  userType,
}: OrientationVideoRequestType): Promise<OrientationResponseType> {
  const response = await API.get(orientationVideosAPI(), requestParams(userType));
  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchOrientationDemo({
  userType,
}: OrientationVideoRequestType): Promise<OrientationResponseType> {
  const response = await API.get(orientationQuicKDemoAPI(), requestParams(userType));
  return camelcaseKeys(response.data, { deep: true });
}
