import { gradeDetailResponse, gradeDetailResult, parentDashboardReports } from './types';

export const gradeDetailAdapter = (gradeDetail: gradeDetailResponse): gradeDetailResponse => {
  const results = gradeDetail?.results?.map((data: gradeDetailResult) => {
    const fullName = `${data.firstName} ${data.lastName}`;
    return { ...data, fullName };
  });
  return { ...gradeDetail, results };
};

export const childrenReportsAdapter = (data: parentDashboardReports[]) => {
  return data?.map(({ subscriptionServices, ...child }) => {
    const currentSemester = child?.report?.semester;
    return {
      ...child,
      report: {
        ...child?.report,
        semester: Array.isArray(currentSemester) ? currentSemester : [currentSemester],
      },
      subscribedServices: subscriptionServices,
    };
  });
};
