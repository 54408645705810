export enum API_RESPONSE_TYPES {
  success = 'success',
  error = 'error',
}

export enum SIGNUP_VERSIONS {
  'v1' = 'v1',
  'v2' = 'v2',
}

export const ACCEPTED_FILE_TYPES = '.jpg, .jpeg, .png, .pdf, .docx';
export const ACCEPTED_FILE_FORMATS =
  '.jpg, .jpeg, .png, .pdf, .mp3, .mp4, .docx, .pptx, .ppt, .xlsx';
export const DATE_FORMAT_SLASH = 'dd/MM/yyyy';

export const DEFAULT_COUNTRY_CODE = 'sa';
export const PHONE_CODE_INDEX = 3;
