import { createAsyncAction, createAction } from 'typesafe-actions';
import { FeedbackRequestType } from './types';

export const sendFeedbackAction = createAsyncAction(
  '@@progress/SEND_FEEDBACK_REQUEST',
  '@@progress/SEND_FEEDBACK_SUCCESS',
  '@@progress/SEND_FEEDBACK_FAILURE',
)<FeedbackRequestType, undefined, string>();

export const resetFeedbackAction = createAction('@@feedback/RESET')();
