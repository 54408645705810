import React, { FunctionComponent, ReactElement, useCallback, useEffect } from 'react';
import { useHover } from 'react-use';
import { IconCommon } from '../IconCommon/IconCommon';
import styles from './Rate.module.scss';

export type FavoriteProps = {
  isSelected: boolean;
  index: number;
  isError?: boolean;
  setHoverIndex: Function | null;
  onChange: (index: number) => void;
};

export const RateItem: FunctionComponent<FavoriteProps> = ({
  isError = false,
  index,
  isSelected,
  setHoverIndex,
  onChange,
}) => {
  const handlerClick = useCallback(() => onChange(index + 1), [index, onChange]);
  const element = (): ReactElement => (
    <div className={styles.icon} role="button" onClick={handlerClick} onKeyPress={handlerClick}>
      {isSelected ? (
        <IconCommon className="icon-star-filled" size="s20" color={isError ? 'error' : 'primary'} />
      ) : (
        <IconCommon className="icon-star" size="s20" color={isError ? 'error' : 'silver'} />
      )}
    </div>
  );
  const [hoverable, isHovering] = useHover(element);

  useEffect(() => {
    if (setHoverIndex && isHovering) setHoverIndex(index + 1);
  }, [isHovering, setHoverIndex, index]);

  return hoverable;
};
