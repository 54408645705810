import { combineReducers } from 'redux';

import { ActionType, createReducer, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  getCSRFAction,
  inviteParentAction,
  loadAccountAction,
  loginAction,
  loginOtpAction,
  logoutAction,
  resetAccountAction,
  resetPasswordAction,
  setPasswordAction,
  updateAccountAction,
  uploadAvatarAction,
  updateAccountRoleAction,
  resetAccountErrorsAction,
  activateAccountAction,
  confirmEmailAction,
  forgetPasswordActionV2,
  resetPasswordActionV2,
  updateAccountPhoneAction,
  updateAccountFirstNameAction,
  updateAccountLastNameAction,
  resetAccountDetailsAction,
  updateAccountFormAction,
  updateAutoDeductAction,
  generateAccountOTPAction,
  verifyAccountOTPAction,
  clearOTPDataAction,
} from './actions';
import { AccountType, AccountFieldTypes, InvitationStatus, AccountOTPType } from './types';
import { inviteParentsActions } from '../inviteParents/actions';

export type AccountActionType = ActionType<typeof actions | typeof inviteParentsActions>;

export const initialState = {
  data: {} as AccountType,
  updateAccountOTP: {} as AccountOTPType,
  fetching: {
    load: true,
    pendingEmailRequest: false,
    pendingPhoneRequest: false,
    pendingPasswordRequest: false,
    pendingFirstNameRequest: false,
    pendingLastNameRequest: false,
    pendinginviteParentRequest: false,
    updateEmail: false,
    updateGender: false,
    updateInviteParent: false,
    updatePhone: false,
    uploadAvatar: false,
    resetPassword: false,
    setPassword: false,
    login: false,
    logout: false,
    getCsrf: true,
    activateAccount: false,
    confirmEmail: false,
    updateFirstName: false,
    updateLastName: false,
    inviteParent: false,
  },
  errors: {
    load: '',
    updateEmail: '',
    updateInviteParent: '',
    updatePhone: '',
    uploadAvatar: '',
    resetPassword: '',
    setPassword: '',
    login: '',
    logout: '',
    getCsrf: '',
    activateAccount: '',
    confirmEmail: '',
    updateFirstName: '',
    updateLastName: '',
    inviteParent: '',
  },
  otpdata: {
    otp_enabled: false,
    source: '',
    resend_otp_time: 0,
    success: false,
    signature: '',
  },
};

export const initialAccountStates = {
  updatedAccountEmail: '',
  isFormEdited: false,
  isFormUpdated: false,
};

const otpdata = createReducer<any, AccountActionType>(initialState.otpdata)
  .handleAction([loginAction.success, loginOtpAction.failure], (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .handleAction([loginOtpAction.success], () => ({}));

const data = createReducer<AccountType, AccountActionType>(initialState.data)
  .handleAction(
    [
      loadAccountAction.success,
      updateAccountAction.success,
      inviteParentAction.success,
      uploadAvatarAction.success,
      updateAccountPhoneAction.success,
      updateAccountFirstNameAction.success,
      updateAccountLastNameAction.success,
    ],
    (state, action) => ({ ...state, ...action.payload }),
  )
  .handleAction(loadAccountAction.failure, () => ({} as AccountType))
  .handleAction(resetAccountAction, () => ({} as AccountType))
  .handleAction(updateAccountRoleAction, (state, { payload }) => {
    return { ...state, role: payload, isAlwTeacherMentor: true };
  })
  .handleAction([inviteParentsActions.success], (state, { payload }) => ({
    ...state,
    parentInvitation: {
      status: InvitationStatus.sent,
      email: payload,
    },
  }))
  .handleAction(updateAutoDeductAction, (state, { payload }) => {
    return { ...state, isSubscriptionAutoRenewal: payload };
  });

const updateAccountOTP = createReducer<AccountOTPType, AccountActionType>(
  initialState.updateAccountOTP,
)
  .handleAction(generateAccountOTPAction.success, (state, { payload }) => ({
    ...state,
    otpData: payload,
  }))
  .handleAction(verifyAccountOTPAction.success, (state, { payload }) => ({ ...state, ...payload }))
  .handleAction(clearOTPDataAction, () => initialState.updateAccountOTP);

const fetching = combineReducers({
  load: createReducer<boolean, AccountActionType>(initialState.fetching.load)
    .handleAction(loadAccountAction.request, () => true)
    .handleAction([loadAccountAction.success, loadAccountAction.failure], () => false)
    .handleAction(resetAccountAction, () => initialState.fetching.load),
  pendingEmailRequest: createReducer<boolean, AccountActionType>(initialState.fetching.updateEmail)
    .handleAction(updateAccountAction.request, () => true)
    .handleAction([updateAccountAction.success, updateAccountAction.failure], () => false),
  pendinginviteParentRequest: createReducer<boolean, AccountActionType>(
    initialState.fetching.updateInviteParent,
  )
    .handleAction(inviteParentAction.request, () => true)
    .handleAction([inviteParentAction.success, inviteParentAction.failure], () => false),
  pendingPhoneRequest: createReducer<boolean, AccountActionType>(initialState.fetching.updatePhone)
    .handleAction(updateAccountPhoneAction.request, () => true)
    .handleAction([updateAccountPhoneAction.success, updateAccountAction.failure], () => false),
  pendingPasswordRequest: createReducer<boolean, AccountActionType>(
    initialState.fetching.resetPassword,
  )
    .handleAction(
      [resetPasswordAction.request, forgetPasswordActionV2.request, resetPasswordActionV2.request],
      () => true,
    )
    .handleAction(
      [
        resetPasswordAction.success,
        resetPasswordAction.failure,
        resetPasswordActionV2.success,
        resetPasswordActionV2.failure,
        forgetPasswordActionV2.success,
        forgetPasswordActionV2.failure,
      ],
      () => false,
    ),
  pendingFirstNameRequest: createReducer<boolean, AccountActionType>(
    initialState.fetching.updateFirstName,
  )
    .handleAction(updateAccountFirstNameAction.request, () => true)
    .handleAction(
      [updateAccountFirstNameAction.success, updateAccountFirstNameAction.failure],
      () => false,
    ),
  pendingLastNameRequest: createReducer<boolean, AccountActionType>(
    initialState.fetching.updateLastName,
  )
    .handleAction(updateAccountLastNameAction.request, () => true)
    .handleAction(
      [updateAccountLastNameAction.success, updateAccountLastNameAction.failure],
      () => false,
    ),
  updateEmail: createReducer<boolean, AccountActionType>(initialState.fetching.updateEmail)
    .handleAction(updateAccountAction.request, () => false)
    .handleAction([updateAccountAction.success], () => true)
    .handleAction(resetAccountDetailsAction, () => initialState.fetching.updateEmail),
  updateGender: createReducer<boolean, AccountActionType>(initialState.fetching.updateGender)
    .handleAction(updateAccountAction.request, () => false)
    .handleAction([updateAccountAction.success], () => true)
    .handleAction(resetAccountDetailsAction, () => initialState.fetching.updateGender),
  updateInviteParent: createReducer<boolean, AccountActionType>(initialState.fetching.updateEmail)
    .handleAction(inviteParentAction.request, () => false)
    .handleAction([inviteParentAction.success], () => true)
    .handleAction(resetAccountDetailsAction, () => initialState.fetching.updateInviteParent),
  updateFirstName: createReducer<boolean, AccountActionType>(initialState.fetching.updateFirstName)
    .handleAction(updateAccountFirstNameAction.request, () => false)
    .handleAction(updateAccountFirstNameAction.success, () => true)
    .handleAction(resetAccountDetailsAction, () => initialState.fetching.updateFirstName),
  updateLastName: createReducer<boolean, AccountActionType>(initialState.fetching.updateLastName)
    .handleAction(
      [updateAccountLastNameAction.request, updateAccountLastNameAction.failure],
      () => false,
    )
    .handleAction([updateAccountLastNameAction.success], () => true)
    .handleAction(resetAccountDetailsAction, () => initialState.fetching.updateLastName),
  updatePhone: createReducer<boolean, AccountActionType>(initialState.fetching.updatePhone)
    .handleAction(updateAccountPhoneAction.request, () => false)
    .handleAction([updateAccountPhoneAction.success], () => true)
    .handleAction(resetAccountDetailsAction, () => initialState.fetching.updatePhone),
  uploadAvatar: createReducer<boolean, AccountActionType>(initialState.fetching.uploadAvatar)
    .handleAction(uploadAvatarAction.request, () => true)
    .handleAction([uploadAvatarAction.success, uploadAvatarAction.failure], () => false)
    .handleAction(resetAccountAction, () => false),
  resetPassword: createReducer<boolean, AccountActionType>(initialState.fetching.resetPassword)
    .handleAction(resetPasswordAction.request, () => true)
    .handleAction([resetPasswordAction.success, resetPasswordAction.failure], () => false)
    .handleAction(resetAccountAction, () => initialState.fetching.resetPassword),
  setPassword: createReducer<boolean, AccountActionType>(initialState.fetching.setPassword)
    .handleAction(setPasswordAction.request, () => true)
    .handleAction([setPasswordAction.success, setPasswordAction.failure], () => false)
    .handleAction(resetAccountAction, () => initialState.fetching.setPassword),
  login: createReducer<boolean, AccountActionType>(initialState.fetching.login)
    .handleAction([loginAction.request], () => true)
    .handleAction([loginAction.success, loginAction.failure], () => false)
    .handleAction(resetAccountAction, () => initialState.fetching.login),
  logout: createReducer<boolean, AccountActionType>(initialState.fetching.logout)
    .handleAction([logoutAction.request], () => true)
    .handleAction([logoutAction.success, logoutAction.failure], () => false)
    .handleAction(resetAccountAction, () => initialState.fetching.logout),
  getCsrf: createReducer<boolean, AccountActionType>(initialState.fetching.getCsrf)
    .handleAction([getCSRFAction.request], () => true)
    .handleAction([getCSRFAction.success, getCSRFAction.failure], () => false)
    .handleAction(resetAccountAction, () => false),
  activateAccount: createReducer<boolean, AccountActionType>(initialState.fetching.activateAccount)
    .handleAction([activateAccountAction.request], () => true)
    .handleAction([activateAccountAction.success, activateAccountAction.failure], () => false)
    .handleAction(resetAccountAction, () => initialState.fetching.activateAccount),
  confirmEmail: createReducer<boolean, AccountActionType>(initialState.fetching.confirmEmail)
    .handleAction([confirmEmailAction.request], () => true)
    .handleAction([confirmEmailAction.success, confirmEmailAction.failure], () => false)
    .handleAction(resetAccountAction, () => initialState.fetching.confirmEmail),
});

const errors = combineReducers({
  load: createReducer<string, AccountActionType>(initialState.errors.load)
    .handleAction(loadAccountAction.failure, (state, { payload }) => payload)
    .handleAction(loadAccountAction.success, () => initialState.errors.load)
    .handleAction([resetAccountAction, resetAccountErrorsAction], () => initialState.errors.load),
  updateEmail: createReducer<string, AccountActionType>(initialState.errors.updateEmail)
    .handleAction(updateAccountAction.failure, (state, { payload }) => payload)
    .handleAction(updateAccountAction.success, () => initialState.errors.updateEmail)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.updateEmail,
    ),
  updateInviteParent: createReducer<string, AccountActionType>(
    initialState.errors.updateInviteParent,
  )
    .handleAction(inviteParentAction.failure, (state, { payload }) => payload)
    .handleAction(inviteParentAction.success, () => initialState.errors.updateInviteParent)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.updateInviteParent,
    ),
  updatePhone: createReducer<string, AccountActionType>(initialState.errors.updatePhone)
    .handleAction(updateAccountPhoneAction.failure, (state, { payload }) => payload)
    .handleAction(updateAccountPhoneAction.success, () => initialState.errors.updateEmail)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.updateEmail,
    ),
  updateFirstName: createReducer<string, AccountActionType>(initialState.errors.updateFirstName)
    .handleAction(updateAccountFirstNameAction.failure, (state, { payload }) => payload)
    .handleAction(updateAccountFirstNameAction.success, () => initialState.errors.updateFirstName)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.updateFirstName,
    ),
  updateLastName: createReducer<string, AccountActionType>(initialState.errors.updateLastName)
    .handleAction(updateAccountLastNameAction.failure, (state, { payload }) => payload)
    .handleAction(updateAccountLastNameAction.success, () => initialState.errors.updateLastName)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.updateLastName,
    ),
  uploadAvatar: createReducer<string, AccountActionType>(initialState.errors.uploadAvatar)
    .handleAction(uploadAvatarAction.failure, (state, { payload }) => payload)
    .handleAction(uploadAvatarAction.success, () => initialState.errors.uploadAvatar)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.uploadAvatar,
    ),
  resetPassword: createReducer<string, AccountActionType>(initialState.errors.resetPassword)
    .handleAction(resetPasswordAction.failure, (state, { payload }) => payload)
    .handleAction(resetPasswordAction.success, () => initialState.errors.resetPassword)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.resetPassword,
    ),
  setPassword: createReducer<string, AccountActionType>(initialState.errors.setPassword)
    .handleAction(setPasswordAction.failure, (state, { payload }) => payload)
    .handleAction(setPasswordAction.success, () => initialState.errors.setPassword)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.setPassword,
    ),
  login: createReducer<string, AccountActionType>(initialState.errors.login)
    .handleAction(loginAction.failure, (state, { payload }) => payload)
    .handleAction(loginAction.success, () => initialState.errors.login)
    .handleAction([resetAccountAction, resetAccountErrorsAction], () => initialState.errors.login),
  logout: createReducer<string, AccountActionType>(initialState.errors.logout)
    .handleAction(logoutAction.failure, (state, { payload }) => payload)
    .handleAction(logoutAction.success, () => initialState.errors.logout)
    .handleAction([resetAccountAction, resetAccountErrorsAction], () => initialState.errors.logout),
  getCsrf: createReducer<string, AccountActionType>(initialState.errors.getCsrf)
    .handleAction(getCSRFAction.failure, (state, { payload }) => payload)
    .handleAction(getCSRFAction.success, () => initialState.errors.getCsrf)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.getCsrf,
    ),
  activateAccount: createReducer<string, AccountActionType>(initialState.errors.activateAccount)
    .handleAction(activateAccountAction.failure, (state, { payload }) => payload)
    .handleAction(activateAccountAction.success, () => initialState.errors.activateAccount)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.activateAccount,
    ),
  confirmEmail: createReducer<string, AccountActionType>(initialState.errors.confirmEmail)
    .handleAction(confirmEmailAction.failure, (state, { payload }) => payload)
    .handleAction(confirmEmailAction.success, () => initialState.errors.confirmEmail)
    .handleAction(
      [resetAccountAction, resetAccountErrorsAction],
      () => initialState.errors.confirmEmail,
    ),
  otpError: createReducer<string, AccountActionType>(initialState.errors.confirmEmail)
    .handleAction(
      [generateAccountOTPAction.failure, verifyAccountOTPAction.failure],
      (state, { payload }) => payload,
    )
    .handleAction([generateAccountOTPAction.success, verifyAccountOTPAction.success], () => ''),
});

export const accountStateReducer = createReducer<AccountFieldTypes, AccountActionType>(
  initialAccountStates,
).handleAction([updateAccountFormAction], (state, { payload }) => ({
  ...state,
  ...payload,
}));
const accountReducer = combineReducers({
  data,
  otpdata,
  updateAccountOTP,
  fetching,
  errors,
});

export type AccountStateType = StateType<typeof accountReducer>;

export default accountReducer;
