import { createSelector } from 'reselect';

import { ClassroomItemType } from 'store/reports/types';

import { RootStateType } from '../types';
import { B2bTeacherStateType } from './reducer';

export const selectB2bTeacher = (state: RootStateType): B2bTeacherStateType => state.b2bTeacher;

export const selectSessionRecordsResult = (state: RootStateType): Array<any> =>
  state.b2bTeacher.data?.teacherLiveSessions?.results;

export const selectSessionRecordsPages = (state: RootStateType): any =>
  state.b2bTeacher.data?.teacherLiveSessions;

export const selectorB2bTeacherStatus = (state: RootStateType): any => state.b2bTeacher.data;

export const selectStudentsRecordsResult = (state: RootStateType): Array<any> =>
  state.b2bTeacher.data?.teacherStudentsList?.results;

export const selectStudentsRecordsPages = (state: RootStateType): any =>
  state.b2bTeacher.data.teacherStudentsList;

export const selectStudentClassRoomOptions = (state: RootStateType): ClassroomItemType[] =>
  state.b2bTeacher.data?.classRoomOptions;

export const studentProfileSelector = createSelector(
  selectB2bTeacher,
  ({ data }: any) => data?.studentProfile,
);
