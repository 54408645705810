import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useRouteMatch } from 'react-router-dom';

import { Loader } from 'lib';
import { isSummerSemester } from 'utils/summerSemesterUtils';
import { iSubjectsLessThanMinimumLimit } from 'utils/helpers';
import { ROUTES } from 'constants/routes';

import { selectorAccount } from 'store/account/selectors';
import { Role, SchoolRole, semesterSelectionGrades } from 'store/account/types';
import { selectorLearningProfiles } from 'store/learningProfiles/selectors';
import { selectorLocale } from 'store/preferences/selectors';

export interface PrivateRouteProps extends RouteProps {
  roles?: Role[];
  schoolRoles?: SchoolRole[];
}

const SchoolRoutes = ({ roles, schoolRoles, ...props }: PrivateRouteProps) => {
  const branchId = localStorage.getItem('schoolBranchId');
  const {
    fetching: { load: isLoadingAccount },
    data: account,
  } = useSelector(selectorAccount);
  const {
    fetching: { load: isLoadingLocale },
  } = useSelector(selectorLocale);
  const {
    fetching: { load: isLoadingLearningProfiles },
  } = useSelector(selectorLearningProfiles);
  const isBranchSelection = useRouteMatch(ROUTES.selectBranch);
  const isSelectSemesterPage = useRouteMatch(ROUTES.semesterSubjects);

  const { grade, schoolRole, subjectsEnrollmentCount } = account || {};
  const { minimumAllowedSubjects, gradeValue: studentGrade } = grade || {};

  if (isLoadingAccount || isLoadingLocale || isEmpty(account) || isLoadingLearningProfiles) {
    return <Loader />;
  }

  if (
    !isSummerSemester &&
    !isSelectSemesterPage &&
    schoolRole === SchoolRole.schoolStudent &&
    (studentGrade === semesterSelectionGrades.eleven ||
      studentGrade === semesterSelectionGrades.twelve) &&
    schoolRole === SchoolRole.schoolStudent &&
    iSubjectsLessThanMinimumLimit(subjectsEnrollmentCount, minimumAllowedSubjects)
  ) {
    return <Redirect to={ROUTES.semesterSubjects} />;
  }

  if (schoolRoles && !schoolRoles.includes(schoolRole)) {
    return <Redirect to={ROUTES.root} />;
  }
  if (isBranchSelection) {
    return <Route {...props} />;
  }

  if ([SchoolRole.schoolAdmin, SchoolRole.schoolBranchManager].includes(schoolRole) && !branchId) {
    const { pathname: currentPath } = window.location;
    return <Redirect to={`${ROUTES.selectBranch}?redirect_url=${currentPath}`} />;
  }

  return <Route {...props} />;
};

export default SchoolRoutes;
