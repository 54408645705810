import React, { FunctionComponent, memo } from 'react';

export const FieldCustomError: FunctionComponent<any> = memo(({ meta, children }) => {
  const { error, touched, visited } = meta;
  return (
    <div className={`form-group ${error && touched && visited ? 'error-group' : ' '}`}>
      {children}
      {error && touched && visited && (
        <span className="form-error" title={error}>
          <span className="metaerror">{error}</span>
        </span>
      )}
    </div>
  );
});

export default FieldCustomError;
