import { paginationReturnType } from 'store/reports/types';
import { EventType } from '../events/types';

export enum NotificationType {
  System = 'system-admin',
  OneToOne = '1-to-1',
  LiveClass = 'live-class',
  ContentAssigned = 'content-assigned',
  NewBadge = 'new-badge',
}

export type NotificationEventType = {
  teacherName?: string;
  time: string;
  datetime?: Date;
  contentId?: string;
  startTime?: Date;
  subjectName?: string;
  subject?: string;
  event?: {
    id: number;
    recordingLink: string;
    eventType?: EventType;
    endDate: Date;
    eventName: string;
    description: string;
    zoomLink: string;
    mentor: string;
    startDate: string;
    subject: {
      description: null | string;
      gradeId: number;
      id: string;
      illustration: string;
      subject: string;
    };
  };
};

export type UserMetaType = {
  datetime?: string;
  classroom?: number;
  recommendation?: number;
  studentCount?: number;
};

export type NotificationsReturnType = paginationReturnType & {
  results: Array<NotificationsType>;
};

export type NotificationsType = {
  actions: [];
  descriptionAr: null | string;
  descriptionEn: null | string;
  id: number;
  isRead: boolean;
  meta: NotificationEventType;
  notificationType: NotificationType;
  notifyAt: string;
  platformType: number;
  textAr: string;
  textEn: string;
  userMeta: UserMetaType;
};

export type NotificationsTypeGroupByDate = {
  [key: string]: NotificationsType[];
};

export type UnreadCountType = {
  count: number;
  hasRecommendations: boolean;
};

export type LoadNotificationRequestType = { page: number; callback?: (val: string) => void };

export type MarkNotificationsRequestType = {
  notifications: number[];
};

export type NotificationsData = UnreadCountType & {
  notifications: NotificationsReturnType;
};
