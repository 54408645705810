import React, { ReactNode, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useTitle } from 'hooks/useTitle';
import { useTheme, ThemeTypes } from 'contexts/ThemeContext';
import { ROUTES } from 'constants/routes';

import { getSvgURL, SVG } from 'lib';
import LayoutContext from 'contexts/LayoutContext';

import { Container } from 'modules/GradeOneToThree/elements';

import Tour from 'components/TourManagement/TourManagement';

import {
  isB2bStudentRoleSelector,
  schoolLogoSelector,
  selectorAccountData,
} from 'store/account/selectors';

import { getUserType, getGradeType } from 'utils/getUserType';

import { LayoutBaseTypes } from 'components/LayoutBase';

import Navbar from './Navbar';
import TopHeader from './TopHeader';
import SectionHeader from './Header';

import {
  Root,
  Header,
  HeaderWrapper,
  HeaderLogo,
  RightHeaderWrapper,
  MainSection,
  StickyBottom,
  Grass,
  FootBall,
} from './elements';
import Background from './Background';

type BaseLayoutProps = {
  title: string;
  subtitle?: string;
  showHeaderTitle?: boolean;
  hideStickyBottom?: boolean;
  layoutType?: LayoutBaseTypes;
};

const BaseLayout = ({ children }: { children: ReactNode }) => {
  const { formatMessage } = useIntl();
  const { theme } = useTheme();

  const [layoutProps, setLayoutProps] = useState<BaseLayoutProps>({} as BaseLayoutProps);

  const {
    title = '',
    showHeaderTitle = true,
    hideStickyBottom = false,
    layoutType = LayoutBaseTypes.ALW,
    subtitle,
  } = layoutProps;

  const isALW = layoutType === LayoutBaseTypes.ALW;

  const {
    role,
    schoolRole,
    grade: { gradeValue },
  } = useSelector(selectorAccountData);
  const isB2bStudent = useSelector(isB2bStudentRoleSelector);
  const schoolLogo = useSelector(schoolLogoSelector);
  const userType = getUserType(role, schoolRole) || '';
  const gradeType = getGradeType(gradeValue);
  const nightTheme = theme === ThemeTypes.night;

  useTitle(title, isALW);

  const handleSetLayoutProps = useCallback((props: BaseLayoutProps) => {
    setLayoutProps(props);
  }, []);

  const logo =
    isB2bStudent && schoolLogo
      ? schoolLogo
      : getSvgURL(nightTheme ? 'alw-logo' : 'logo-text', nightTheme && true);

  return (
    <LayoutContext.Provider value={handleSetLayoutProps}>
      <Root color={nightTheme ? 'night' : 'day'}>
        <Tour userType={userType} gradeType={gradeType}>
          <Background />
          <Header>
            <Container>
              <HeaderWrapper>
                <HeaderLogo>
                  <Link to={ROUTES.root}>
                    <img
                      src={logo}
                      alt={formatMessage({
                        id: 'dashboard.icon.alw',
                        defaultMessage: 'alw',
                      })}
                    />
                  </Link>
                </HeaderLogo>
                <RightHeaderWrapper>
                  <TopHeader />
                  <Navbar />
                </RightHeaderWrapper>
              </HeaderWrapper>
            </Container>
          </Header>
          <MainSection>
            <Container>
              {showHeaderTitle && <SectionHeader title={title} subHeading={subtitle} />}
              {children}
            </Container>
          </MainSection>
          {!nightTheme && (
            <StickyBottom hide={hideStickyBottom}>
              <Grass />
              <SVG name="base-monster" isGradeOneToThree />
              <FootBall
                src={getSvgURL('football', true)}
                alt={formatMessage({
                  id: 'dashboard.theme.football',
                  defaultMessage: 'Football',
                })}
              />
            </StickyBottom>
          )}
        </Tour>
      </Root>
    </LayoutContext.Provider>
  );
};

export default BaseLayout;
