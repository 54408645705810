import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import accountSaga from './account/sagas';
import assignedSubjectsSaga from './assignedSubjects/sagas';
import badgesSaga from './badges/saga';
import breadcrumbsSaga from './breadcrumbs/sagas';
import eventsSaga from './events/sagas';
import feedbackSaga from './feedback/sagas';
import gradesSaga from './grades/sagas';
import inviteParentsSaga from './inviteParents/sagas';
import learningProfilesSaga from './learningProfiles/saga';
import lessonPreviewSaga from './lessonPreview/sagas';
import lessonsSaga from './lessons/sagas';
import lessonsWeekScheduleSaga from './lessonsWeekSchedule/saga';
import preferencesSaga from './preferences/sagas';
import notificationsSaga from './notifications/sagas';
import progressSaga from './progress/sagas';
import preparationMaterialSaga from './preparationMaterial/sagas';
import registrationSaga from './registration/sagas';
import scheduleSaga from './schedule/sagas';
import scheduleForTodaySaga from './scheduleForToday/sagas';
import ssoSaga from './sso/sagas';
import avatarSaga from './DigitalTutor/sagas';
import childrenSubscriptionsSaga from './childrenSubscriptions/sagas';
import subjectsSaga from './subjects/sagas';
import subscriptionsSaga from './subscriptions/sagas';
import supportSaga from './support/sagas';
import unitsSaga from './units/sagas';
import semestersSaga from './semesters/sagas';
import contentLibrarySaga from './contentLibrary/sagas';
import paymentHistorySaga from './paymentHistory/saga';
import schoolBranchesSaga from './shoolBranches/sagas';
import schoolTeacherSaga from './schoolTeacher/saga';
import schoolStudentSaga from './schoolStudent/saga';
import teacherProfileSaga from './schoolTeacherProfile/sagas';
import studentProfileSaga from './studentProfile/sagas';
import schoolListsSaga from './shoolLists/sagas';
import b2bBulkImportSaga from './b2bBulkImport/sagas';
import assignTeachersGradesSaga from './assignTeacherGrades/sagas';
import b2bTeacherSaga from './b2bTeacher/sagas';
import tourSaga from './tourManagement/sagas';
import groupChatSaga from './groupChat/sagas';
import questionBankSaga from './questionBank/sagas';
import reportsSaga from './reports/sagas';
import rewardsSaga from './Rewards/sagas';
import semesterScheduleSaga from './SemesterSchedule/sagas';
import assignmentsSaga from './assignments/sagas';
import examsSaga from './exams/sagas';
import flexibleScheduleSaga from './flexibleSchedule/sagas';
import timetableSaga from './Timetable/sagas';
import promotionSaga from './promotion/sagas';
import systemConfigSaga from './systemConfig/sagas';
import dashboardSaga from './dashboard/sagas';
import childTeacherDetailQuestionSaga from './childTeacherDetailQuestion/sagas';
import recommendationSystemSaga from './recommendationSystem/sagas';
import nativeEventsSaga from './native-events/sagas';
import orientationSaga from './orientation/sagas';
import studentDashboardV2Saga from './student-dashboard/sagas';
import qiyasSaga from './qiyas/sagas';
import gradingSystemSaga from './grading-system/sagas';

export default function* rootSaga(): SagaIterator<void> {
  yield all([
    fork(accountSaga),
    fork(assignedSubjectsSaga),
    fork(badgesSaga),
    fork(breadcrumbsSaga),
    fork(childrenSubscriptionsSaga),
    fork(eventsSaga),
    fork(feedbackSaga),
    fork(gradesSaga),
    fork(inviteParentsSaga),
    fork(learningProfilesSaga),
    fork(lessonPreviewSaga),
    fork(lessonsSaga),
    fork(lessonsWeekScheduleSaga),
    fork(preferencesSaga),
    fork(notificationsSaga),
    fork(progressSaga),
    fork(preparationMaterialSaga),
    fork(rewardsSaga),
    fork(avatarSaga),
    fork(schoolBranchesSaga),
    fork(registrationSaga),
    fork(scheduleForTodaySaga),
    fork(scheduleSaga),
    fork(ssoSaga),
    fork(semesterScheduleSaga),
    fork(subjectsSaga),
    fork(subscriptionsSaga),
    fork(supportSaga),
    fork(unitsSaga),
    fork(semestersSaga),
    fork(contentLibrarySaga),
    fork(paymentHistorySaga),
    fork(schoolTeacherSaga),
    fork(schoolListsSaga),
    fork(schoolStudentSaga),
    fork(teacherProfileSaga),
    fork(studentProfileSaga),
    fork(b2bBulkImportSaga),
    fork(assignTeachersGradesSaga),
    fork(tourSaga),
    fork(timetableSaga),
    fork(groupChatSaga),
    fork(b2bTeacherSaga),
    fork(questionBankSaga),
    fork(reportsSaga),
    fork(assignmentsSaga),
    fork(examsSaga),
    fork(flexibleScheduleSaga),
    fork(promotionSaga),
    fork(systemConfigSaga),
    fork(dashboardSaga),
    fork(childTeacherDetailQuestionSaga),
    fork(recommendationSystemSaga),
    fork(nativeEventsSaga),
    fork(orientationSaga),
    fork(studentDashboardV2Saga),
    fork(qiyasSaga),
    fork(gradingSystemSaga),
  ]);
}
