import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import { addTeacherAction, loadTeacherOptionsAction } from './actions';
import { AddNewTeacherType } from './types';

export type SchoolTeacherActionType = ActionType<typeof actions>;

export const initialState = {
  data: {} as AddNewTeacherType,
};
const data = createReducer<any, SchoolTeacherActionType>(initialState.data)
  .handleAction(addTeacherAction.success, (state) => state)
  .handleAction(loadTeacherOptionsAction.success, (state, action) => ({
    ...state,
    ...action.payload,
  }));

const fetching = combineReducers({
  create: createReducer<boolean, SchoolTeacherActionType>(false)
    .handleAction(addTeacherAction.request, () => true)
    .handleAction([addTeacherAction.success, addTeacherAction.failure], () => false),
  options: createReducer<boolean, SchoolTeacherActionType>(false)
    .handleAction(loadTeacherOptionsAction.request, () => true)
    .handleAction(
      [loadTeacherOptionsAction.success, loadTeacherOptionsAction.failure],
      () => false,
    ),
});

const errors = combineReducers({
  create: createReducer<string, SchoolTeacherActionType>('')
    .handleAction(addTeacherAction.failure, (state, { payload }) => payload)
    .handleAction(addTeacherAction.success, () => ''),
  options: createReducer<string, SchoolTeacherActionType>('').handleAction(
    loadTeacherOptionsAction.failure,
    (state, { payload }) => payload,
  ),
});

const schoolTeacherReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type SchoolTeacherStateType = StateType<typeof schoolTeacherReducer>;

export default schoolTeacherReducer;
