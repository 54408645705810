import { ReactNode } from 'react';

export type OrientationResponseType = {
  titleEn: string;
  titleAr: string;
  videoEn: string;
  videoAr: string;
  featureIcon: string;
  isQuickDemo: boolean;
};

export type OrientationVideoRequestType = {
  userType: string;
};

export type OrientationInitialData = {
  demo: OrientationResponseType;
  featureVideos: OrientationResponseType[];
};

export type OrientationVideosType = {
  data: OrientationResponseType;
  renderActionButtons: () => ReactNode;
};

export enum OrientationStateTypes {
  WELCOME,
  ORIENTATION_VIDEO,
}

export type HelpMenuProps = {
  isOpenMenu: boolean;
  closeHelpTour?: () => void;
  setOpenMenu?: () => void;
};
