import { createAction, createAsyncAction } from 'typesafe-actions';
import { BadgesType } from './types';

export const loadBadgesAction = createAsyncAction(
  '@@badges/LOAD_REQUEST',
  '@@badges/LOAD_SUCCESS',
  '@@badges/LOAD_FAILURE',
)<undefined, BadgesType[], string>();

export const resetBadgesAction = createAction('@@badges/RESET')();
