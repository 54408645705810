import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';

import { SIGNUP_VERSIONS } from 'constants/common';

import * as actions from './actions';
import { loadSignupVersionAction } from './actions';
import { SignupVersionType } from './types';

export type systemConfigActionType = ActionType<typeof actions>;

const signupVersionInitialState = {
  value: SIGNUP_VERSIONS.v1,
  isLoading: true,
  error: '',
};

const signupVersion = createReducer<SignupVersionType, systemConfigActionType>(
  signupVersionInitialState,
)
  .handleAction(loadSignupVersionAction.request, (state) => ({ ...state, isLoading: true }))
  .handleAction(loadSignupVersionAction.success, (state, action) => ({
    isLoading: false,
    value: action.payload,
    error: '',
  }))
  .handleAction(loadSignupVersionAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }));

const systemConfigReducer = combineReducers({
  signupVersion,
});

export type SystemConfigStateType = StateType<typeof systemConfigReducer>;

export default systemConfigReducer;
