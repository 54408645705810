import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import { avatarAPI } from 'utils/apiEndpoints';

import { AvatarsResponseType } from './types';

export async function getAvatars(): Promise<AvatarsResponseType> {
  const response = await API.get(avatarAPI());
  return camelcaseKeys(response.data, { deep: true });
}
