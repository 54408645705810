import React, { useContext } from 'react';

import { IconType } from 'lib';

type BreadcrumbType = {
  label: string;
  url?: string;
};

enum LayoutBaseTypes {
  ALW,
  B2B,
}

type CommonLayoutProps = {
  title: string;
  subtitle?: string;
  layoutType?: LayoutBaseTypes;
  hideSideMenu?: boolean;
};

export type GradeOneToThreeLayoutProps = CommonLayoutProps & {
  showHeaderTitle?: boolean;
  hideStickyBottom?: boolean;
};

export type NonGradeOneToThreeLayoutProps = CommonLayoutProps & {
  breadcrumbs?: BreadcrumbType[];
  showHamburger?: boolean;
  isSummerProgram?: boolean;
  isChild?: boolean;
  hideSideNav?: boolean;
  icon?: IconType;
};

const LayoutContext = React.createContext<
  ((props: NonGradeOneToThreeLayoutProps) => void) | ((props: GradeOneToThreeLayoutProps) => void)
>(() => {});

export const useLayoutContext = () => useContext(LayoutContext);
export default LayoutContext;
