import { createAsyncAction, createAction } from 'typesafe-actions';
import { TourType, SetWatchedRequestType, TourRequestType } from './types';

export const loadTourAction = createAsyncAction(
  '@@tour/LOAD_REQUEST',
  '@@tour/LOAD_SUCCESS',
  '@@tour/LOAD_FAILURE',
)<Partial<TourRequestType>, TourType, string>();

export const setWatchedTourAction = createAsyncAction(
  '@@tour/SET_WATCHED_REQUEST',
  '@@tour/SET_WATCHED_SUCCESS',
  '@@tour/SET_WATCHED_FAILURE',
)<Partial<SetWatchedRequestType>, undefined, string>();

export const resetTourAction = createAction('@@tour/RESET')();
