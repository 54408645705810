import { createSelector } from 'reselect';

import { RootStateType } from '../types';
import { OrientationsStateType } from './reducer';

const selectorOrientation = (state: RootStateType): OrientationsStateType => state.orientation;

export const orientationDemoSelector = createSelector(
  selectorOrientation,
  ({ data }) => data?.demo,
);

export const orientationDemoErrorSelector = createSelector(
  selectorOrientation,
  ({ errors }) => errors?.demo,
);

export const orientationVideosSelector = createSelector(selectorOrientation, ({ data }) =>
  data?.featureVideos?.filter(({ isQuickDemo }) => !isQuickDemo),
);

export const orientationVideosLoadingSelector = createSelector(
  selectorOrientation,
  ({ fetching }) => fetching?.featureVideos,
);

export const userGuideMenuStateSelector = createSelector(
  selectorOrientation,
  ({ userGuideMenuState }) => userGuideMenuState,
);
