import {
  ScheduleEventType,
  ScheduleEventResponseType,
  TableViewResponseType,
  EventDetailResponseType,
  EventDetailType,
} from './types';

export const dataScheduleAdapter = ({
  id,
  description,
  endDate,
  eventType,
  eventSource,
  trainer,
  eventName,
  startDate,
  students,
  subject,
  zoomLink,
  recordingLink,
  trainerName,
  weekNumber,
  meetingType,
}: ScheduleEventResponseType): ScheduleEventType => ({
  id,
  eventType,
  eventSource,
  eventName,
  description,
  startDate: new Date(startDate),
  endDate: new Date(endDate),
  zoomLink,
  recordingLink,
  subject,
  students,
  trainer,
  trainerName,
  weekNumber,
  meetingType,
});

export const eventDetailAdapter = ({
  id,
  eventName,
  description,
  startDate,
  endDate,
  eventType,
  eventSource,
  recordings,
  subject,
  student,
  students,
  trainer,
  weekNumber,
  zoomLink,
  zoomMeetingId,
  zoomMeetingType,
  isOverflowStudent,
  isSessionConducted,
  zoomLimit,
  grade,
  noOfInvites,
  liveSessionSlot,
  attachmentsInfo,
  lastWeekLessons,
  rejectionReason,
  classrooms,
  rating,
  isInPersonClass,
}: EventDetailResponseType): EventDetailType => ({
  id,
  eventName,
  description,
  startDate: new Date(startDate),
  endDate: new Date(endDate),
  eventType,
  eventSource,
  recordings,
  subject,
  student,
  students,
  trainer,
  weekNumber,
  zoomLink,
  zoomMeetingId,
  zoomMeetingType,
  isSessionConducted,
  isOverflowStudent,
  zoomLimit,
  grade,
  noOfInvites,
  rating,
  liveSessionSlot,
  attachmentsInfo,
  lastWeekLessons,
  rejectionReason,
  classrooms,
  isInPersonClass,
});

export const tableViewSchedulesAdapter = ({
  count,
  currentPage,
  next,
  numPages,
  previous,
  results,
  start,
}: TableViewResponseType): TableViewResponseType => ({
  count,
  currentPage,
  next,
  numPages,
  previous,
  results,
  start,
});

export const dataSchedulesAdapter = (
  schedules: Array<ScheduleEventResponseType>,
): Array<ScheduleEventType> =>
  schedules.map(
    (schedule: ScheduleEventResponseType): ScheduleEventType => dataScheduleAdapter(schedule),
  );
