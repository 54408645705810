import React, { FunctionComponent, ChangeEvent, useState, ReactElement, useEffect } from 'react';
import cn from 'classnames';
import style from './Checkbox.module.scss';

export enum CheckboxColors {
  primary = 'primary',
  dark = 'dark',
  white = 'white',
  secondary = 'secondary',
}

export type CheckboxProps = {
  id?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: ReactElement | string;
  size?: 'regular' | 'small';
  tabIndex?: number;
  labelPosition?: 'left' | 'right';
  color?: keyof typeof CheckboxColors;
  onChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  name,
  id = name,
  value = '',
  checked = false,
  disabled = false,
  size = 'regular',
  label = '',
  labelPosition = 'right',
  tabIndex,
  onChange = (): void => {},
  color = CheckboxColors.dark,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      currentTarget: { checked },
    } = event;
    setIsChecked(checked);
    onChange(event, checked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label
      htmlFor={id}
      className={cn(style.container, {
        [style.disable]: disabled,
        [style.withLabel]: label,
        [style[size]]: size,
        [style[labelPosition]]: labelPosition,
        [style.checked]: isChecked,
      })}
    >
      <span className={cn(style['custom-checkbox'], style[color])}>
        <input
          id={id}
          name={name}
          value={value}
          type="checkbox"
          tabIndex={tabIndex}
          onChange={onChangeHandler}
          disabled={disabled}
          defaultChecked={checked}
          className={cn(style.checkbox)}
          checked={isChecked}
        />
      </span>
      {label && <span className={cn(style.label)}>{label}</span>}
    </label>
  );
};
