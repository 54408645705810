import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
  loadB2BTeacherStats,
  loadB2BTeacherStudentsList,
  loadB2BTeacherLiveSessions,
  loadB2BTeacherClassRoomOptions,
  loadB2BTeacherStudentProfile,
  studentAttachment,
  resetB2BTeacherStudentsList,
  loadB2BTeacherStudentsListFilter,
} from './actions';
import * as actions from './actions';

export type TeacherType = ActionType<typeof actions>;
export const initialState = {
  data: {
    results: [],
    teacherStudentsList: {
      results: [],
    },
    teacherLiveSessions: {
      results: [],
    },
  },
};

const isLoading = createReducer<boolean, TeacherType>(true)
  .handleAction(
    [
      loadB2BTeacherStats.request,
      loadB2BTeacherStudentsList.request,
      loadB2BTeacherLiveSessions.request,
      loadB2BTeacherClassRoomOptions.request,
      loadB2BTeacherStudentProfile.request,
      loadB2BTeacherStudentsListFilter.request,
      studentAttachment.request,
    ],
    () => true,
  )
  .handleAction(
    [
      loadB2BTeacherStudentsList.success,
      loadB2BTeacherLiveSessions.success,
      loadB2BTeacherStats.success,
      loadB2BTeacherStats.failure,
      loadB2BTeacherLiveSessions.success,
      loadB2BTeacherLiveSessions.failure,
      loadB2BTeacherClassRoomOptions.success,
      loadB2BTeacherClassRoomOptions.failure,
      loadB2BTeacherStudentProfile.success,
      loadB2BTeacherStudentProfile.failure,
      studentAttachment.success,
      studentAttachment.failure,
    ],
    () => false,
  );

const data = createReducer<any, TeacherType>(initialState.data)
  .handleAction(loadB2BTeacherStats.success, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .handleAction(loadB2BTeacherStudentsList.success, (state, action) => ({
    ...state,
    teacherStudentsList: { ...state.teacherStudentsList, ...action.payload },
  }))
  .handleAction(loadB2BTeacherStudentsListFilter.success, (state, action) => ({
    ...state,
    teacherStudentsList: { ...state.teacherStudentsList, results: action.payload },
  }))
  .handleAction(loadB2BTeacherLiveSessions.success, (state, action) => ({
    ...state,
    teacherLiveSessions: { ...state.teacherLiveSessions, ...action.payload },
  }))
  .handleAction(loadB2BTeacherClassRoomOptions.success, (state, action) => ({
    ...state,
    classRoomOptions: action.payload,
  }))
  .handleAction(loadB2BTeacherStudentProfile.success, (state, action) => ({
    ...state,
    studentProfile: action.payload,
  }))
  .handleAction(studentAttachment.success, (state, action) => ({
    ...state,
    studentAttachment: action.payload,
  }))
  .handleAction(resetB2BTeacherStudentsList, (state) => {
    return { ...state, teacherStudentsList: { results: [] } };
  });

const errors = createReducer<any, TeacherType>({})
  .handleAction(loadB2BTeacherStats.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadB2BTeacherStats.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(loadB2BTeacherStudentsList.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadB2BTeacherStudentsListFilter.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadB2BTeacherStudentsList.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(loadB2BTeacherLiveSessions.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadB2BTeacherLiveSessions.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(loadB2BTeacherClassRoomOptions.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadB2BTeacherClassRoomOptions.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(loadB2BTeacherStudentProfile.failure, (state, action) => ({
    ...state,
    errors: action.payload,
  }))
  .handleAction(loadB2BTeacherStudentProfile.success, (state) => ({
    ...state,
    errors: '',
  }))
  .handleAction(studentAttachment.failure, (state, action) => ({
    ...state,
    errors: action.payload,
  }))
  .handleAction(studentAttachment.success, (state) => ({
    ...state,
    errors: '',
  }));

const b2bTeacherReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export type B2bTeacherStateType = StateType<typeof b2bTeacherReducer>;

export default b2bTeacherReducer;
