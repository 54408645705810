import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';

import * as actions from './actions';
import {
  fetchQiyasExamDetailAction,
  updateQiyasExamDetailAction,
  fetchQuestionsByExamAction,
  updateQuestionsAction,
  clearQiyasExamAction,
  handleTemporaryQuestionAction,
  setMaxQuestionsLimitAction,
} from './actions';
import { TQiyasExam, TQiyasQuestion } from './types';

type QiyasActionsType = ActionType<typeof actions>;

const qiyasExamDetail = combineReducers({
  data: createReducer<TQiyasExam, QiyasActionsType>({} as TQiyasExam)
    .handleAction(fetchQiyasExamDetailAction.success, (_, { payload }) => payload)
    .handleAction(updateQiyasExamDetailAction, (state, { payload }) => ({ ...state, ...payload }))
    .handleAction(clearQiyasExamAction, () => ({} as TQiyasExam))
    .handleAction(updateQuestionsAction, (state, { payload: { mode, ...payload } }) => {
      // handles exam level count of questions
      const { sections, totalQuestions } = state;
      switch (mode) {
        case 'create':
          return {
            ...state,
            totalQuestions: totalQuestions + 1,
            sections: sections?.map((section) =>
              section.id === payload.questionGroup
                ? { ...section, totalQuestions: section.totalQuestions + 1 }
                : section,
            ),
          };
        case 'delete':
          return {
            ...state,
            totalQuestions: totalQuestions - 1,
            sections: sections?.map((section) =>
              section.id === payload.questionGroup
                ? { ...section, totalQuestions: section.totalQuestions - 1 }
                : section,
            ),
          };
        case 'update':
        default:
          return state;
      }
    })
    .handleAction(handleTemporaryQuestionAction, (state, { payload: { mode, ...payload } }) => {
      const { sections } = state;
      const updatedSections = sections.map((section) => {
        if (section.id === payload.questionGroup) {
          const temporaryQuestions = section.temporaryQuestions ?? [];

          switch (mode) {
            case 'create':
              return { ...section, temporaryQuestions: [...temporaryQuestions, payload] };
            case 'delete':
              return {
                ...section,
                temporaryQuestions: temporaryQuestions.filter(
                  (question) => question.id !== payload.id,
                ),
              };
            case 'update':
              return {
                ...section,
                temporaryQuestions: temporaryQuestions.map((question) =>
                  question.id === payload.id ? { ...question, ...payload } : question,
                ),
              };
            default:
              return section;
          }
        }
        return section;
      });
      return { ...state, sections: updatedSections };
    }),
  loading: createReducer<boolean, QiyasActionsType>(true)
    .handleAction(fetchQiyasExamDetailAction.request, () => true)
    .handleAction(
      [fetchQiyasExamDetailAction.success, fetchQiyasExamDetailAction.failure],
      () => false,
    ),
  error: createReducer<string, QiyasActionsType>('')
    .handleAction(fetchQiyasExamDetailAction.failure, (_, { payload }) => payload)
    .handleAction(
      [fetchQiyasExamDetailAction.request, fetchQiyasExamDetailAction.success],
      () => '',
    ),
});

const questionsByExam = combineReducers({
  data: createReducer<TQiyasQuestion[], QiyasActionsType>([])
    .handleAction(fetchQuestionsByExamAction.success, (_, { payload }) => payload)
    .handleAction(clearQiyasExamAction, () => [])
    .handleAction(updateQuestionsAction, (state, { payload: { mode, ...payload } }) => {
      switch (mode) {
        case 'create':
          return [...state, payload];

        case 'update':
          return state.map((question) => (question.id === payload.id ? payload : question));

        case 'delete':
          return state.filter((question) => question.id !== payload.id);

        default:
          return state;
      }
    }),
  loading: createReducer<boolean, QiyasActionsType>(true)
    .handleAction(fetchQuestionsByExamAction.request, () => true)
    .handleAction(
      [fetchQuestionsByExamAction.success, fetchQuestionsByExamAction.failure],
      () => false,
    ),
  error: createReducer<string, QiyasActionsType>('')
    .handleAction(fetchQuestionsByExamAction.failure, (_, { payload }) => payload)
    .handleAction(
      [fetchQuestionsByExamAction.request, fetchQuestionsByExamAction.success],
      () => '',
    ),
});

const maxQuestionsLimit = createReducer<number, QiyasActionsType>(100).handleAction(
  setMaxQuestionsLimitAction,
  (_, { payload }) => payload,
);

const qiyasReducer = combineReducers({ qiyasExamDetail, questionsByExam, maxQuestionsLimit });

export type QiyasStateType = StateType<typeof qiyasReducer>;

export default qiyasReducer;
