import classNames from 'classnames';
import { createElement, FunctionComponent, ReactNode } from 'react';
import styles from './Text.module.scss';
import { TextAlignments, TextColors, TextTypes } from './Text.types';

export type TextProps = {
  children: ReactNode;
  type?: keyof typeof TextTypes;
  align?: keyof typeof TextAlignments;
  color?: keyof typeof TextColors;
  hover?: keyof typeof TextColors;
  isInline?: boolean;
  noWrap?: boolean;
  tooltip?: string | null;
  disabled?: boolean;
};

export const Text: FunctionComponent<TextProps> = ({
  children,
  type = TextTypes.body2,
  align,
  color,
  hover,
  isInline = false,
  noWrap = false,
  tooltip = null,
  disabled = false,
}: TextProps) => {
  const Component = isInline ? 'span' : 'div';
  const cssClasses = classNames(styles[type], {
    [styles[`align-${align}`]]: align,
    [styles[`color-${color}`]]: color,
    [styles[`hover-${hover}`]]: hover,
    [styles.inline]: isInline,
    [styles.nowrap]: noWrap,
    [styles.disable]: disabled,
  });

  return createElement(Component, { className: cssClasses, title: tooltip }, children);
};
