import camelcaseKeys from 'camelcase-keys';
import API from '../../utils/api';
import { UnitResponseType, UnitType } from './types';

export const getUnitsResponseAdapter = (units: Array<UnitResponseType>): Array<UnitType> =>
  units.map((unit) => ({
    ...unit,
    dueDate: new Date(unit.dueDate),
    progress: Math.round(unit.progress * 100),
    semester: Number(unit.semester),
  }));

export async function getUnits(subjectId: string): Promise<Array<UnitType>> {
  const response = await API.get(`/api/mentora/subjects/${subjectId}/units`);

  return getUnitsResponseAdapter(camelcaseKeys(response.data, { deep: true }));
}
