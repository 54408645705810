import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Form } from 'react-final-form';
import { useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { ToastTypes, useToast } from 'contexts/ToastContext';
import { FAKE_EMAIL } from 'constants/settings';
import { ROUTES } from 'constants/routes';
import { useScreenInclude } from 'hooks/useScreenInclude';
import { NewButton, ButtonTypes, TextArea, FormFieldWithError, IconCommon, Rate } from 'lib';
import { selectBreadcrumbs } from 'store/breadcrumbs/selectors';
import { selectorAccount } from 'store/account/selectors';
import { resetFeedbackAction, sendFeedbackAction } from 'store/feedback/actions';
import { selectFeedback } from 'store/feedback/selectors';
import { FeedbackType, FeedbackTypes } from 'store/feedback/types';

import messages from './Feedback.messages';
import style from './Feedback.module.scss';
import HelpWarning from './HelpWarning';

type FeedbackFormProps = {
  onClose: () => void;
  isGradeOneToThree?: boolean;
};

export const getIsFeedbackContent = (
  pathname?: string,
  eventId?: string,
  subjectId?: string,
): boolean => !!(pathname?.startsWith(ROUTES.learningPath) && eventId && subjectId);

const FeedbackForm: FunctionComponent<FeedbackFormProps> = ({
  onClose,
  isGradeOneToThree,
}): ReactElement => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { eventId, subjectId } = useParams();
  const isMobile = useScreenInclude(['xs', 'sm']);
  const { addToast } = useToast();

  const {
    data: { email },
  } = useSelector(selectorAccount);
  const { data: breadcrumbsData } = useSelector(selectBreadcrumbs);
  const { fetching, errors } = useSelector(selectFeedback);

  const lessonId = breadcrumbsData?.lesson?.id;

  useEffect(() => {
    return (): void => {
      dispatch(resetFeedbackAction());
    };
  }, [dispatch]);

  const isFeedbackContent = useMemo(
    () => getIsFeedbackContent(pathname, eventId, subjectId),
    [pathname, eventId, subjectId],
  );

  const submitHandler = useCallback(
    ({ rating, text }: FeedbackType) => {
      dispatch(
        sendFeedbackAction.request({
          rating,
          text,
          type: isFeedbackContent ? FeedbackTypes.content : FeedbackTypes.system,
          subjectId,
          lessonId,
          eventId,
          callback: () => {
            addToast(
              formatMessage({
                id: 'header.feedback.submit-success',
                defaultMessage: 'Your feedback is submitted successfully!',
              }),
              ToastTypes.SUCCESS,
            );
            onClose();
          },
        }),
      );
    },
    [dispatch, onClose, subjectId, lessonId, eventId, isFeedbackContent],
  );

  if (email?.includes(FAKE_EMAIL)) return <HelpWarning />;

  return (
    <div className={cn(style.feedback, { [style.gradeFeedback]: isGradeOneToThree })}>
      <Form
        onSubmit={submitHandler}
        mutators={{
          setRating: ([value], state, utils): void => {
            utils.changeValue(state, 'rating', () => value);
          },
        }}
        render={({ handleSubmit, values = {}, form }): ReactElement => (
          <form className={style.feedback__form} onSubmit={handleSubmit}>
            <header className={style.feedback__header}>
              <h3>{formatMessage(messages.title)}</h3>
              <span role="button" className={style.feedback__close} onClick={onClose}>
                <IconCommon className="icon-cancel" size="s16" weight="bold" />
              </span>
            </header>
            <main className={style.feedback__main}>
              <p>
                {isFeedbackContent
                  ? formatMessage(messages.contentDescription)
                  : formatMessage(messages.systemDescription)}
              </p>
              <div className="mt-20" />
              <h5 className={style.feedback__heading}>
                {isFeedbackContent
                  ? formatMessage(messages.contentFavorite)
                  : formatMessage(messages.systemFavorite)}
              </h5>
              <div className="feedback__rating">
                <FormFieldWithError
                  name="rating"
                  render={(props: any): ReactNode => (
                    <Rate
                      {...props}
                      onChange={(rating: number): void => form.mutators.setRating(rating)}
                    />
                  )}
                />
              </div>
              <div className="mt-20" />
              <h5 className={style.feedback__heading}>{formatMessage(messages.comment)}</h5>
              <FormFieldWithError
                name="text"
                render={(props: any): ReactNode => (
                  <TextArea
                    {...props}
                    rows={isMobile ? 10 : 4}
                    placeholder={formatMessage(messages.placeholder)}
                  />
                )}
              />
              {errors.send && (
                <div className="mt-5">
                  <span className="lineError">{errors.send}</span>
                </div>
              )}
            </main>

            <footer className={style.feedback__footer}>
              <NewButton
                style={
                  isGradeOneToThree
                    ? [ButtonTypes.btnGrade, ButtonTypes.btnGradeSmall, ButtonTypes.btnGradeRed]
                    : [ButtonTypes.buttonSecondarySm]
                }
                onClick={onClose}
              >
                {formatMessage({
                  id: 'header.feedback.comment.cancel',
                  defaultMessage: 'Cancel',
                })}
              </NewButton>
              <NewButton
                style={
                  isGradeOneToThree
                    ? [ButtonTypes.btnGrade, ButtonTypes.btnGradeSmall, ButtonTypes.btnGradeOrange]
                    : [ButtonTypes.buttonPrimarySm]
                }
                disabled={fetching?.send || !values.rating}
              >
                {formatMessage({
                  id: 'header.feedback.comment.submit',
                  defaultMessage: 'Submit',
                })}
              </NewButton>
            </footer>
          </form>
        )}
      />
    </div>
  );
};

export default FeedbackForm;
