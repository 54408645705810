import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import API from '../../utils/api';
import { SupportMessageType } from './types';

export async function sendSupportEmail({
  subject,
  description,
}: SupportMessageType): Promise<SupportMessageType> {
  const response = await API.post(
    '/api/mentora/support/send-request/',
    snakeCaseKeys(
      {
        subject,
        description,
      },
      { deep: true },
    ),
  );

  return camelcaseKeys(response.data, { deep: true });
}
