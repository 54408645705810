import { createAsyncAction, createAction } from 'typesafe-actions';

import { FormType } from 'components/Subscription/types';
import {
  EnrollStudentPayloadType,
  LoadSubjectsPayloadType,
  PaymentPlans,
  Subjects,
  SubscriptionDraftPayload,
} from './types';

export const loadSubscriptionSubjects = createAsyncAction(
  '@@SubscriptionSubjectsV2/LOAD_REQUEST',
  '@@SubscriptionSubjectsV2/LOAD_SUCCESS',
  '@@SubscriptionSubjectsV2/LOAD_FAILURE',
)<LoadSubjectsPayloadType, Subjects[], string>();

export const loadSubscriptionPlans = createAsyncAction(
  '@@SubscriptionPlansV2/SEND_REQUEST',
  '@@SubscriptionPlansV2/SEND_SUCCESS',
  '@@SubscriptionPlansV2/SEND_FAILURE',
)<undefined, PaymentPlans[], string>();

export const loadCheckoutId = createAsyncAction(
  '@@SubscriptionCheckoutIdV2/LOAD_REQUEST',
  '@@SubscriptionCheckoutIdV2/LOAD_SUCCESS',
  '@@SubscriptionCheckoutIdV2/LOAD_FAILURE',
)<EnrollStudentPayloadType, string, string>();

export const loadDraftSubscriptionData = createAsyncAction(
  '@@SubscriptionDraftSubscriptionData/LOAD_REQUEST',
  '@@SubscriptionDraftSubscriptionData/LOAD_SUCCESS',
  '@@SubscriptionDraftSubscriptionData/LOAD_FAILURE',
)<Partial<SubscriptionDraftPayload>, FormType, string>();

export const clearDraftSubscriptionData = createAction(
  '@@SubscriptionDraftSubscriptionData/RESET_DATA',
)();
