import React, { FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import { useLockBodyScroll, useToggle } from 'react-use';
import { parseYouTubeUrl } from '../../utils/parseYouTubeUrl';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Margin } from '../Margin/Margin';
import { Text } from '../Text/Text';
import { Checkbox } from '../Checkbox/Checkbox';
import styles from './VideoPopup.module.scss';

export type VideoPopupType = {
  title: string;
  date?: Date;
  videoUrl?: string;
  isOpen: boolean;
  setIsShowModal?: () => void;
  isCompleted?: boolean;
  onCompleteContent?: () => void;
  isAssigned: boolean;
  onClose?: () => void;
};

export const VideoPopup: FunctionComponent<VideoPopupType> = ({
  title,
  date,
  setIsShowModal,
  isOpen,
  videoUrl,
  isCompleted,
  onCompleteContent,
  isAssigned,
}) => {
  const [locked, toggleLocked] = useToggle(false);
  const { formatDate, formatMessage } = useIntl();
  const recordingDate = formatDate(date, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  useLockBodyScroll(locked);
  const liveTitle = formatMessage({
    id: 'schedule.attach.video.popup.title',
    defaultMessage: 'Live class',
  });
  const videoId = videoUrl ? parseYouTubeUrl(videoUrl) : '';

  useEffect(() => {
    toggleLocked(isOpen);
  }, [isOpen, toggleLocked]);

  return (
    <Modal
      isOpen={isOpen}
      className={styles['video-popup']}
      overlayClassName={styles['video-popup__overlay']}
      onRequestClose={setIsShowModal}
    >
      <div className={styles['video-popup__body']}>
        <div className={styles['video-popup__header']}>
          <div>
            <Text type="heading2" color="white">
              {`${liveTitle}. ${title}`}
            </Text>
            <Margin top="m16">
              <Text color="white" type="subtitle2">
                {recordingDate}
              </Text>
            </Margin>
          </div>
          <div className={styles.closeContainer}>
            {isAssigned && (
              <Checkbox
                disabled={isCompleted}
                checked={isCompleted}
                color="white"
                label={
                  <Text type="body2" color="white">
                    {formatMessage({
                      id: 'content-library.preview.completed',
                      defaultMessage: "Mark this lesson as 'Completed'",
                    })}
                  </Text>
                }
                onChange={onCompleteContent}
              />
            )}
            <div>
              <Button type="link" onClick={setIsShowModal}>
                <Icon type="close" color="white" size="s24" />
              </Button>
            </div>
          </div>
        </div>
        <div className={styles['video-popup__iframe']}>
          <iframe
            className={styles.iframe}
            title={title}
            src={`https://www.youtube.com/embed/${videoId}?controls=0"`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </Modal>
  );
};
