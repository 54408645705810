import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  StudentDashboardDataResponse,
  TodaySchedulePayload,
  TodayScheduleType,
  OverdueLessonsPayloadType,
  OverdueLessonsResponseType,
  UnitsRequestPayload,
  UnitsType,
  AdditionalLessonsResponseType,
  AdditionalLessonsType,
  ChildUsernamePayload,
} from './types';

export const fetchStudentDashboardDataAction = createAsyncAction(
  '@@dashboard/FETCH_STUDENT_DASHBOARD_DATA_REQUEST',
  '@@dashboard/FETCH_STUDENT_DASHBOARD_DATA_SUCCESS',
  '@@dashboard/FETCH_STUDENT_DASHBOARD_DATA_FAILURE',
)<ChildUsernamePayload, StudentDashboardDataResponse, string>();

export const fetchTodayScheduleAction = createAsyncAction(
  '@@dashboard/FETCH_TODAY_SESSION_SCHEDULE_REQUEST',
  '@@dashboard/FETCH_TODAY_SESSION_SCHEDULE_SUCCESS',
  '@@dashboard/FETCH_TODAY_SESSION_SCHEDULE_FAILURE',
)<TodaySchedulePayload, TodayScheduleType[], string>();

export const fetchOverdueLessonsAction = createAsyncAction(
  '@@dashboard/FETCH_OVERDUE_LESSONS_REQUEST',
  '@@dashboard/FETCH_OVERDUE_LESSONS_SUCCESS',
  '@@dashboard/FETCH_OVERDUE_LESSONS_FAILURE',
)<OverdueLessonsPayloadType, OverdueLessonsResponseType, string>();

export const fetchUnitsBySubjectAction = createAsyncAction(
  '@@dashboard/FETCH_UNITS_BY_SUBJECT_REQUEST',
  '@@dashboard/FETCH_UNITS_BY_SUBJECT_SUCCESS',
  '@@dashboard/FETCH_UNITS_BY_SUBJECT_FAILURE',
)<UnitsRequestPayload, UnitsType[], string>();

export const fetchAdditionalLessonsAction = createAsyncAction(
  '@@dashboard/FETCH_ADDITIONAL_LESSONS_REQUEST',
  '@@dashboard/FETCH_ADDITIONAL_LESSONS_SUCCESS',
  '@@dashboard/FETCH_ADDITIONAL_LESSONS_FAILURE',
)<ChildUsernamePayload, AdditionalLessonsResponseType, string>();

export const updateAdditionalLessonAction = createAction(
  '@@dashboard/UPDATE_ADDITIONAL_LESSONS_REQUEST',
)<{ id: number; lessonType: AdditionalLessonsType }>();
