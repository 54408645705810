import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';

import * as actions from './actions';
import { bulkImportDataAction, loadNotificationsAction, markNotificationsAction } from './actions';
import { NotificationsReturnType, NotificationsData } from './types';

export type NotificationsActionType = ActionType<typeof actions>;

const initialState = {
  data: {} as NotificationsData,
  fetching: {
    load: false,
    update: false,
  },
  errors: {
    load: '',
    update: '',
  },
};

const data = createReducer<NotificationsData, NotificationsActionType>(initialState.data)
  .handleAction([loadNotificationsAction.success], (state, { payload }) => ({
    ...state,
    notifications: {
      ...payload,
      results: state.notifications?.results?.concat(payload.results) || payload.results,
    },
  }))
  .handleAction([actions.getNotificationsUnreadCountAction.success], (state, { payload }) => ({
    ...state,
    hasRecommendations: payload.hasRecommendations,
    count: payload.count,
  }))
  .handleAction([actions.resetNotificationsAction], (state) => ({
    ...state,
    notifications: {} as NotificationsReturnType,
  }));

const bulkImportStatus = createReducer<boolean, NotificationsActionType>(false).handleAction(
  bulkImportDataAction,
  (state, { payload }) => {
    return payload;
  },
);

const fetching = combineReducers({
  load: createReducer<boolean, NotificationsActionType>(true)
    .handleAction(loadNotificationsAction.request, () => true)
    .handleAction([loadNotificationsAction.success, loadNotificationsAction.failure], () => false),
  update: createReducer<boolean, NotificationsActionType>(false)
    .handleAction(markNotificationsAction.request, () => true)
    .handleAction([markNotificationsAction.success, markNotificationsAction.failure], () => false),
});

const errors = combineReducers({
  load: createReducer<string, NotificationsActionType>('')
    .handleAction(loadNotificationsAction.failure, (state, { payload }) => payload)
    .handleAction(loadNotificationsAction.success, () => ''),
  update: createReducer<string, NotificationsActionType>('')
    .handleAction(markNotificationsAction.failure, (state, { payload }) => payload)
    .handleAction(markNotificationsAction.success, () => ''),
});

const localeReducer = combineReducers({
  fetching,
  data,
  bulkImportStatus,
  errors,
});

export type NotificationsStateType = StateType<typeof localeReducer>;

export default localeReducer;
