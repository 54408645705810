import { getPngURL, getSvgURL } from 'lib';

import { ROUTES } from 'constants/routes';
import { DISABLE_ARS, DISABLE_B2C_GROUP_CHAT, DISABLE_B2C_REWARDS } from 'constants/settings';
import { isSummerSemester } from 'utils/summerSemesterUtils';
import { getIntl } from 'utils/intl';

import { Gender } from 'store/account/types';
import { SubscriptionServiceEnum } from 'store/subscriptions/types';
import { MenuItemTypes } from '../../components/Sidebar/types';

export const getStudentMenu = (
  isB2BStudent?: boolean,
  grade?: number | undefined,
  gender?: Gender | null,
  disabledServices = {} as Record<SubscriptionServiceEnum, boolean>,
  isQiyasVisible = false,
  isDisableExamStudent?: boolean,
  hasQiyasAccess = false,
): MenuItemTypes[] => {
  const { formatMessage } = getIntl();
  const menu: MenuItemTypes[] = [
    {
      id: 'sidebar__dashboard',
      path: ROUTES.root,
      label: formatMessage({ id: 'dashboard.title', defaultMessage: 'Dashboard' }),
      icon: 'dashboard',
      orderIndex: 1,
    },
    {
      id: 'sidebar__learning_path',
      path: ROUTES.learningPath,
      label: formatMessage({ id: 'learning-path.title', defaultMessage: 'Learning Path' }),
      icon: 'learning-path',
      orderIndex: 2,
      disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.RECORDED_CONTENT],
    },
    {
      id: 'sidebar__schedule',
      path: ROUTES.schedule,
      label: formatMessage({ id: 'schedule.title', defaultMessage: 'Schedule' }),
      icon: 'schedule',
      hide: isSummerSemester,
      orderIndex: 3,
      disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.WEEKLY_REVIEW_SESSIONS],
    },
    isDisableExamStudent
      ? {
          id: 'sidebar__qiyas',
          path: ROUTES.qiyas,
          label: formatMessage({ id: 'qiyas.title', defaultMessage: 'Qiyas Simulation' }),
          image: getSvgURL('qiyas-sidebar'),
          orderIndex: 3,
          disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.QIYAS_SIMULATION],
        }
      : {
          id: 'sidebar__assessment',
          path: '',
          label: formatMessage({ id: 'assessment.title', defaultMessage: 'Exercises & Exams' }),
          image: getPngURL('assessment'),
          hide: isSummerSemester,
          orderIndex: 4,
          subMenus: [
            ...(isQiyasVisible
              ? [
                  {
                    id: 'sidebar__qiyas',
                    path: ROUTES.qiyas,
                    label: formatMessage({ id: 'qiyas.title', defaultMessage: 'Qiyas Simulation' }),
                    image: getSvgURL('qiyas-sidebar'),
                    orderIndex: 3,
                    disabled:
                      !hasQiyasAccess && disabledServices[SubscriptionServiceEnum.QIYAS_SIMULATION],
                  },
                ]
              : []),
            {
              id: 'sidebar__exercises',
              path: ROUTES.exercises,
              label: formatMessage({ id: 'exercises.title', defaultMessage: 'Exercises' }),
              icon: 'exercise',
              hide: isSummerSemester,
              orderIndex: 4,
              disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.RECORDED_CONTENT],
            },
            {
              id: 'sidebar__reinforcements',
              path: ROUTES.quizExam,
              label: formatMessage({
                id: 'reinforcements.title',
                defaultMessage: 'Reinforcements',
              }),
              image: getSvgURL('reinforcements'),
              hide: isSummerSemester,
              orderIndex: 5,
              disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.RECORDED_CONTENT],
            },
            {
              id: 'sidebar__exams',
              path: ROUTES.studentExams,
              label: formatMessage({
                id: 'exams.examinations.title',
                defaultMessage: 'Examinations',
              }),
              icon: 'exam',
              hide: isSummerSemester,
              orderIndex: 6,
              disabled:
                !isB2BStudent && disabledServices[SubscriptionServiceEnum.EXERCISES_AND_EXAMS],
            },
            {
              id: 'sidebar__question_bank',
              path: ROUTES.questionBankSubjects,
              label: formatMessage({ id: 'question-bank.title', defaultMessage: 'Question Bank' }),
              image: getPngURL('question-bank'),
              hide: isSummerSemester,
              orderIndex: 8,
              disabled:
                !isB2BStudent && disabledServices[SubscriptionServiceEnum.EXERCISES_AND_EXAMS],
            },
            ...(isB2BStudent
              ? [
                  {
                    id: 'sidebar__assignments',
                    path: ROUTES.assignments,
                    label: formatMessage({
                      id: 'assignments.title',
                      defaultMessage: 'Assignments',
                    }),
                    image: getSvgURL('assignment'),
                    hide: isSummerSemester,
                    orderIndex: 7,
                  },
                ]
              : []),
          ],
        },
    {
      id: 'sidebar__content_library',
      path: ROUTES.contentLibrary,
      label: formatMessage({ id: 'content-library.title', defaultMessage: 'Content Library' }),
      icon: 'question-bank',
      hide: isSummerSemester,
      orderIndex: 9,
      disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.RECORDED_CONTENT],
    },
    {
      id: 'sidebar__reports',
      path: '',
      label: formatMessage({ id: 'b2bTeacher.reports.title', defaultMessage: 'Reports' }),
      image: getSvgURL('report'),
      hide: isSummerSemester,
      orderIndex: 10,
      subMenus: [
        {
          id: 'sidebar__weekly_reports',
          path: ROUTES.selfReport,
          label: formatMessage({
            id: 'accounts.notifications.weekly.reports.title',
            defaultMessage: 'Weekly reports',
          }),
          image: getSvgURL('weekly-report'),
          hide: isSummerSemester,
          orderIndex: 10,
        },
        ...(!DISABLE_ARS
          ? [
              {
                id: 'sidebar__recommendation_reports',
                path: ROUTES.recommendation,
                label: formatMessage({
                  id: 'reports.recommendations',
                  defaultMessage: 'recommendations',
                }),
                image: getPngURL('recommendation-report'),
                orderIndex: 11,
              },
            ]
          : []),
      ],
    },
  ];

  if (!DISABLE_B2C_GROUP_CHAT && gender) {
    menu.push({
      id: 'sidebar__chatgroup',
      path: ROUTES.groupChat,
      label: formatMessage({ id: 'group-chat.title', defaultMessage: 'Chat Group' }),
      icon: 'group-chat',
      orderIndex: 11,
    });
  }

  if (!DISABLE_B2C_REWARDS) {
    menu.push({
      id: 'sidebar__rewards',
      path: ROUTES.rewards,
      label: formatMessage({ id: 'side.menu.rewards.title', defaultMessage: 'Rewards' }),
      icon: 'reward',
      hide: isSummerSemester,
      orderIndex: 13,
    });
  }

  if (grade === 12) {
    menu.push({
      id: 'sidebar__preparation',
      path: ROUTES.preparation,
      label: formatMessage({
        id: 'preparation.material.nav.university.admission.test',
        defaultMessage: 'University Admission Tests',
      }),
      icon: 'admission-test',
      hide: isSummerSemester,
      orderIndex: 14,
    });
  }

  if (isB2BStudent) {
    menu.push({
      id: 'sidebar__chat',
      path: ROUTES.Chat,
      label: formatMessage({
        id: 'chat.teacher.title',
        defaultMessage: 'Teacher Communication',
      }),
      hide: isSummerSemester,
      icon: 'group-chat',
      orderIndex: 12,
    });

    if (!DISABLE_B2C_GROUP_CHAT && gender) {
      menu.push({
        id: 'sidebar__chatgroup',
        path: ROUTES.groupChat,
        label: formatMessage({ id: 'group-chat.title', defaultMessage: 'Chat Group' }),
        icon: 'group-chat',
        orderIndex: 11,
      });
    }

    if (DISABLE_B2C_REWARDS) {
      menu.push({
        id: 'sidebar__rewards',
        path: ROUTES.rewards,
        label: formatMessage({ id: 'side.menu.rewards.title', defaultMessage: 'Rewards' }),
        icon: 'reward',
        hide: isSummerSemester,
        orderIndex: 13,
      });
    }
  }

  return menu
    .sort((a: MenuItemTypes, b: MenuItemTypes) => {
      const order1 = a.orderIndex || 0;
      const order2 = b.orderIndex || 0;

      return order1 - order2;
    })
    .filter((m) => !m.hide);
};
