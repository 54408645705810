import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { orientationDemoAction, orientationVideoAction } from './actions';
import { fetchOrientationDemo, fetchOrientationVideo } from './api';

function* fetchOrientationVideoSaga({
  payload,
}: ReturnType<typeof orientationVideoAction.request>): SagaIterator<void> {
  try {
    const response = yield call(fetchOrientationVideo, payload);
    yield put(orientationVideoAction.success(response));
  } catch (error) {
    yield put(orientationVideoAction.failure(error));
  }
}

function* fetchOrientationDemoActionSaga({
  payload,
}: ReturnType<typeof orientationDemoAction.request>): SagaIterator<void> {
  try {
    const response = yield call(fetchOrientationDemo, payload);
    yield put(orientationDemoAction.success(response));
  } catch (error) {
    yield put(orientationDemoAction.failure(error));
  }
}

function* localeSaga(): SagaIterator {
  yield all([
    takeEvery(orientationVideoAction.request, fetchOrientationVideoSaga),
    takeEvery(orientationDemoAction.request, fetchOrientationDemoActionSaga),
  ]);
}

export default localeSaga;
