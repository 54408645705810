import { defineMessages } from 'react-intl';
import { sum } from 'lodash';

import { FREE_PLAN } from 'constants/entities';
import { PaymentPlans, Subjects } from 'store/subscriptions/types';

import {
  STRING_AND_SPECIAL_CHARS,
  CVV_CHAR_LIMIT,
  CARD_NUMBER_LIMIT,
  CARD_HOLDER_NAME_LIMIT,
  EXPIRY_DATE_CHAR_LIMIT,
} from './constants';
import { ChildSubscriptionInfo, FormType } from './types';

export const cardFormInitialValues = {
  paymentBrand: 0,
  cardNumber: '',
  cardHolderName: '',
  expiryDate: '',
  cvvNumber: '',
  expirationMonth: '',
  expirationYear: '',
};

export const messages = defineMessages({
  personalMentor: {
    id: 'subscription.featureList.mentor',
    defaultMessage: 'Personal Mentor',
  },
  personalMentorDescription: {
    id: 'subscription.featureList.mentor.description',
    defaultMessage: 'Your Mentor will direct you during all the learning process',
  },
  learningPath: {
    id: 'subscription.featureList.learningPath',
    defaultMessage: 'Personalize Learning Path',
  },
  learningPathDescription: {
    id: 'subscription.featureList.learningPath.description',
    defaultMessage: 'Learning content can be customized to your requirements',
  },
  lessonHeading: {
    id: 'subscription.featureList.lessonHeading',
    defaultMessage: '150+ hrs High quality teacher-led lessons',
  },
  lessonHeadingDescription: {
    id: 'subscription.featureList.lessonHeading.description',
    defaultMessage: 'Classes cover the entire National Curriculum',
  },
  cancel: {
    id: 'subscription.featureList.cancel',
    defaultMessage: 'Cancel anytime',
  },
  cancelDescription: {
    id: 'subscription.featureList.cancel.description',
    defaultMessage: 'You can cancel subscription anytime in My Account',
  },
  payment: {
    id: 'subscription.featureList.payment',
    defaultMessage: 'Payment methods',
  },
  paymentDescription: {
    id: 'subscription.featureList.payment.description',
    defaultMessage:
      'For monthly payment we can accept only Master Card/Visa, for semester you can use also MADA & STC pay.',
  },
  trialHeading: {
    id: 'subscription.featureList.trial.flexibility',
    defaultMessage: 'Flexibility',
  },
  trialDescription: {
    id: 'subscription.featureList.trial.description',
    defaultMessage: 'You can upgrade subscription anytime.',
  },
});

const fieldRequiredHandler = (val: string | number) => {
  if (val) return undefined;
  return 'fieldRequiredErrorMessage';
};

const checkCardLimit = (val: number) => {
  if (String(val).length !== CARD_NUMBER_LIMIT) {
    return 'invalidCardNumErrorMessage';
  }
  return undefined;
};

const checkFieldFormat = (val: string) => {
  const isValidFormat = /[\d]{2} *\/ *[\d]{2}/.test(val);
  if (isValidFormat) return undefined;
  return 'invalidFormatErrorMessage';
};

const validMonthAndYear = (val: string) => {
  // Parse the expiration date string into month and year components
  const [monthStr, yearStr] = val.split('/').map((x) => x.trim());

  // Check if the parsed month and year are valid numbers
  const month = Number(monthStr);

  if (Number.isNaN(month) || month < 1 || month > 12) {
    return `invalidMonthErrorMessage ${month}`;
  }

  const year = Number(yearStr);
  const currentYear = new Date().getFullYear();

  if (Number.isNaN(year) || year < currentYear || year > 2099) {
    return `invalidYearErrorMessage ${year}`;
  }

  if (year === currentYear && month < new Date().getMonth() + 1) {
    return 'invalidDate';
  }

  // The expiration date is valid
  return undefined;
};

const checkCvvLimit = (val: number) => {
  const cvvLength = String(val).length;
  if (cvvLength !== CVV_CHAR_LIMIT) {
    return 'invalidCVVErrorMessage';
  }
  return undefined;
};

export const validateValue = (value: string, pattern: string): string => {
  return value.replace(new RegExp(pattern, 'g'), '');
};

export const customCardFields = [
  {
    name: 'cardNumber',
    pattern: STRING_AND_SPECIAL_CHARS,
    title: { id: 'subscription.card.number.title', defaultMessage: 'Card Number' },
    placeholder: {
      id: 'subscription.card.placeholder.card.number',
      defaultMessage: 'Enter your card number',
    },
    column: 'col-md-8',
    maxLength: CARD_NUMBER_LIMIT,
    validate: (val: number) => fieldRequiredHandler(val) || checkCardLimit(val),
  },
  {
    name: 'expiryDate',
    title: { id: 'subscription.card.expiry.date', defaultMessage: 'Expiry Date' },
    placeholder: { id: 'subscription.card.placeholder.expiry.date', defaultMessage: 'MM / YY' },
    column: 'col-md-4',
    maxLength: EXPIRY_DATE_CHAR_LIMIT,
    validate: (val: string) =>
      fieldRequiredHandler(val) || checkFieldFormat(val) || validMonthAndYear(val),
  },
  {
    name: 'cardHolderName',
    title: { id: 'subscription.custom.card.holder.title', defaultMessage: 'Card Holder' },
    placeholder: {
      id: 'subscription.card.holder.placeholder',
      defaultMessage: 'Enter card holder name',
    },
    column: 'col-md-8',
    maxLength: CARD_HOLDER_NAME_LIMIT,
    validate: (val: string) => fieldRequiredHandler(val),
  },
  {
    name: 'cvvNumber',
    pattern: STRING_AND_SPECIAL_CHARS,
    title: { id: 'subscription.custom.card.cvv', defaultMessage: 'CVV' },
    placeholder: { id: 'subscription.custom.card.cvv.placeholder', defaultMessage: '123' },
    column: 'col-md-4',
    maxLength: CVV_CHAR_LIMIT,
    info: {
      heading: 'cvvCode',
      content: 'cvvCodeContent',
    },
    validate: (val: number) => fieldRequiredHandler(val) || checkCvvLimit(val),
  },
];
export const transformSubscriptionFormData = (
  formValues: FormType,
  id: number,
  currentPlan: PaymentPlans | undefined,
) => {
  let subscribers = formValues.children.map((child) => ({
    user_id: id,
    subjects: child.subjects.map((subject) => {
      return {
        subject_id: subject.id,
        services: subject.services
          .filter((service) => service.checked && !service.isEnrolled)
          .map((service) => ({ service_id: service.id })),
      };
    }),
  }));
  subscribers = subscribers.map((subscriber) => {
    const subjects = subscriber.subjects.filter((sub) => sub.services.length);
    return {
      ...subscriber,
      voucher_code: formValues?.couponData?.isVoucher
        ? formValues?.couponData?.couponCode
        : undefined,
      subjects,
    };
  });
  return {
    plan: currentPlan?.id,
    payment_brand_id: formValues.brand?.id,
    total_amount: formValues.discountedPrice || 0,
    subscribers: [...subscribers],
    coupon_code: formValues?.couponData?.isVoucher ? undefined : formValues?.couponData?.couponCode,
  };
};

export const getServicesDetail = (subjects: Subjects[]) => {
  let totalEnrolledServices = 0;
  let newServices = 0;
  subjects.forEach((subject) => {
    if (!subject.isQiyasSubject) {
      const enrolled = subject.services.filter((sr) => sr.isEnrolled).length;
      const newSer = subject.services.filter((sr) => sr.checked && !sr.isEnrolled).length;
      totalEnrolledServices += enrolled;
      newServices += newSer;
    }
  });
  return { totalEnrolledServices, newServices };
};

export const getQiyasSubjects = (subjects: Subjects[]) => {
  const qiyasSubjects = subjects.filter((sub) => sub.isQiyasSubject);
  const paidQiyasSubjects = qiyasSubjects.filter((sbj) => sbj.services?.[0].isEnrolled);
  const unpaidQiyasSubjects = qiyasSubjects.filter((sbj) => !sbj.services?.[0].isEnrolled);
  return { paidQiyasSubjects, unpaidQiyasSubjects };
};
export const getQiyasSubjectsPrice = (subject: Subjects[]) => {
  return subject.reduce((pre, subj) => {
    const price = subj.services.reduce((previous, ser) => +(ser.price || 0) + previous, 0);
    return price + pre;
  }, 0);
};
export const calculateSubscriptionPrice = (
  prices: number[],
  enrolledCount: number,
  draftCount: number,
  totalPrice: number,
) => {
  const lastValue = prices.at(-1);
  let cost = prices.slice(enrolledCount, enrolledCount + draftCount);
  if (cost.length < draftCount) {
    const repeated = Array(draftCount - cost.length).fill(lastValue);
    cost = cost.concat(repeated);
  }

  return Math.min(sum(cost), totalPrice);
};

export const calculateServicesPrices = (
  servicePrice: number[],
  maxPayableAmount: number,
  currentUser: ChildSubscriptionInfo,
  children: ChildSubscriptionInfo[],
  currentUserIndex: number,
  plan?: PaymentPlans,
) => {
  const { totalEnrolledServices, newServices } = getServicesDetail(currentUser.subjects);
  const { paidQiyasSubjects, unpaidQiyasSubjects } = getQiyasSubjects(currentUser.subjects);
  const qiyasPaidPrice = getQiyasSubjectsPrice(paidQiyasSubjects);
  const qiyasPrice = getQiyasSubjectsPrice(unpaidQiyasSubjects);

  const price =
    plan?.type === FREE_PLAN || currentUser.isVoucherActive
      ? 0
      : calculateSubscriptionPrice(
          servicePrice,
          totalEnrolledServices,
          newServices,
          maxPayableAmount - (Number(currentUser.amountPaid || 0) - qiyasPaidPrice),
        ) + qiyasPrice;
  const grandTotal =
    (children.reduce(
      (sum, curr, ind) => sum + (ind === currentUserIndex ? 0 : curr.totalPrice || 0),
      0,
    ) || 0) + price;

  return { price, grandTotal };
};

export const groupServicesByPrice = (prices: number[]) => {
  const result = [];
  let currentPrice = prices[0];
  let startIndex = 0;
  let previousSum = 0;

  for (let i = 0; i < prices.length; i += 1) {
    if (prices[i] !== currentPrice) {
      const endIndex = i - 1;
      const key =
        startIndex === endIndex ? `${startIndex + 1}` : `${startIndex + 1}-${endIndex + 1}`;
      result.push({ key, value: currentPrice, previousSum, limit: startIndex });
      previousSum += currentPrice * (endIndex - startIndex + 1);
      currentPrice = prices[i];
      startIndex = i;
    }
  }

  // Handle the last sequence
  const key =
    startIndex === prices.length - 1 ? `${startIndex + 1}` : `${startIndex + 1}-${prices.length}`;
  result.push({ key, value: currentPrice, previousSum, limit: +key - 1 });

  return result;
};
