import React, { ChangeEvent } from 'react';

import { TextAreaField } from 'lib';

import { TextAreaProps } from 'lib/InputFields/TextArea/TextArea';
import styles from './GradeTextAreaField.module.scss';

type TextAreaFieldProps = TextAreaProps & {
  title?: string;
  error?: string | boolean;
  helperText?: string;
  attachmentProps?: {
    loading?: boolean;
    attachmentFieldName: string;
    attachmentTypes: string;
    uploadHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  };
};

export const GradeTextAreaField = (props: TextAreaFieldProps) => {
  return (
    <div className={styles.grade}>
      <TextAreaField {...props} />
    </div>
  );
};
