import { createAsyncAction } from 'typesafe-actions';

import {
  ClassroomSubjectsResponseType,
  GetClassesPayload,
  TeacherSchedulesResponseType,
  TeacherSchedulesRequestType,
  WeeklyScheduleRequestPayload,
  WeeklyScheduleResponseType,
  SaveWeeklyScheduleRequestPayload,
} from './types';

export const getClassesAction = createAsyncAction(
  '@@flexibleSchedule/GET_CLASSROOM_SUBJECTS_REQUEST',
  '@@flexibleSchedule/GET_CLASSROOM_SUBJECTS_SUCCESS',
  '@@flexibleSchedule/GET_CLASSROOM_SUBJECTS_FAILURE',
)<GetClassesPayload, ClassroomSubjectsResponseType, string>();

export const getTeachersScheduleAction = createAsyncAction(
  '@@flexibleSchedule/GET_TEACHERS_SCHEDULE_REQUEST',
  '@@flexibleSchedule/GET_TEACHERS_SCHEDULE_SUCCESS',
  '@@flexibleSchedule/GET_TEACHERS_SCHEDULE_FAILURE',
)<TeacherSchedulesRequestType, TeacherSchedulesResponseType, string>();

export const getWeeklyScheduleAction = createAsyncAction(
  '@@flexibleSchedule/GET_WEEKLY_SCHEDULE_REQUEST',
  '@@flexibleSchedule/GET_WEEKLY_SCHEDULE_SUCCESS',
  '@@flexibleSchedule/GET_WEEKLY_SCHEDULE_FAILURE',
)<WeeklyScheduleRequestPayload, WeeklyScheduleResponseType, string>();

export const saveWeeklyScheduleAction = createAsyncAction(
  '@@flexibleSchedule/SAVE_WEEKLY_SCHEDULE_REQUEST',
  '@@flexibleSchedule/SAVE_WEEKLY_SCHEDULE_SUCCESS',
  '@@flexibleSchedule/SAVE_WEEKLY_SCHEDULE_FAILURE',
)<SaveWeeklyScheduleRequestPayload, WeeklyScheduleResponseType, string>();
