import 'regenerator-runtime/runtime';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';

import { ThemeProvider, ThemeTypes } from 'contexts/ThemeContext';
import { ToastProvider } from 'contexts/ToastContext';
import HeaderContextProvider from 'contexts/HeaderContext';

import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';
import { useStyledTheme } from 'modules/GradeOneToThree/hooks/useStyledTheme';

import { setDocumentLang } from 'utils/helpers';
import { getLocale, Lang, LOCALES } from 'lang';
import { Loader } from 'lib';

import { getCSRFAction } from 'store/account/actions';
import { selectorAccount } from 'store/account/selectors';
import { selectorLocale } from 'store/preferences/selectors';
import { loadSignupVersionAction } from 'store/systemConfig/actions';

import AppRoutes from './AppRoutes';
import EventPopup from '../EventPopup/EventPopup';

declare global {
  interface Document {
    querySelector: any;
  }
}

const App = () => {
  const dispatch = useDispatch();
  const {
    data: { prefLang, theme },
  } = useSelector(selectorLocale);
  const styledTheme = useStyledTheme();
  const isGradeOneToThree = useIsGradeOneToThree();

  const [locale, setLocale] = useState(getLocale(prefLang));
  const { fetching } = useSelector(selectorAccount);

  useEffect(() => {
    dispatch(getCSRFAction.request());
    dispatch(loadSignupVersionAction.request());
  }, [dispatch]);

  useEffect(() => {
    setLocale(getLocale(prefLang));
  }, [prefLang]);

  setDocumentLang(locale);

  if (fetching.getCsrf) return <Loader />;

  const accountTheme = !theme && isGradeOneToThree ? ThemeTypes.night : theme;

  return (
    <IntlProvider locale={locale} messages={LOCALES[locale].messages} defaultLocale={Lang.en}>
      <ToastProvider>
        <ThemeProvider theme={accountTheme}>
          <StyledThemeProvider theme={{ ...styledTheme, dir: LOCALES[locale].direction }}>
            <HeaderContextProvider>
              <BrowserRouter>
                <AppRoutes />
                <EventPopup />
              </BrowserRouter>
            </HeaderContextProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </ToastProvider>
    </IntlProvider>
  );
};

export default Sentry.withProfiler(App);
