import { SyntheticEvent } from 'react';

export enum Strategy {
  fixed = 'fixed',
  absolute = 'absolute',
}

export enum Placements {
  bottom = 'bottom',
  auto = 'auto',
  top = 'top',
  right = 'right',
  left = 'left',
  'auto-start' = 'auto-start',
  'auto-end' = 'auto-end',
  'top-start' = 'top-start',
  'top-end' = 'top-end',
  'bottom-start' = 'bottom-start',
  'bottom-end' = 'bottom-end',
  'right-start' = 'right-start',
  'right-end' = 'right-end',
  'left-start' = 'left-start',
  'left-end' = 'left-end',
}

export enum Types {
  tooltip = 'tooltip',
  modal = 'modal',
}

export type InputEventType = (e: SyntheticEvent) => void;
