import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import styles from './Dialog.module.scss';

export type DialogProps = {
  isVisible: boolean;
  onCloseDialog?: any;
  children: any;
  classNames?: string;
  overlayClasses?: string;
  bodyOpenClassName?: string;
  preventScroll?: boolean;
};
export const Dialog: FunctionComponent<DialogProps> = ({ ...props }) => {
  const { isVisible, onCloseDialog, children, classNames, overlayClasses, ...restProps } = props;
  return (
    <Modal
      isOpen={isVisible}
      overlayClassName={overlayClasses ?? styles['modal-backdrop']}
      className={classNames ?? styles.modal}
      onRequestClose={onCloseDialog}
      ariaHideApp={false}
      {...restProps}
    >
      {children}
    </Modal>
  );
};
