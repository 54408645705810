import { createAsyncAction } from 'typesafe-actions';

import {
  NewsAndUpdatesResponseType,
  VideosCardsResponseType,
  NewsAndUpdatesPayloadType,
} from './types';

export const fetchNewsAndUpdatesAction = createAsyncAction(
  '@@promotion/FETCH_NEWS_AND_UPDATES_REQUEST',
  '@@promotion/FETCH_NEWS_AND_UPDATES_SUCCESS',
  '@@promotion/FETCH_NEWS_AND_UPDATES_FAILURE',
)<NewsAndUpdatesPayloadType, NewsAndUpdatesResponseType, string>();

export const fetchVideosCardsAction = createAsyncAction(
  '@@promotion/FETCH_VIDEOS_CARDS_REQUEST',
  '@@promotion/FETCH_VIDEOS_CARDS_SUCCESS',
  '@@promotion/FETCH_VIDEOS_CARDS_FAILURE',
)<undefined, VideosCardsResponseType, string>();
