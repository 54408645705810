import { createAction, createAsyncAction } from 'typesafe-actions';

import { GenericCallbackType } from 'utils/types';

import { CountryListType } from '../account/types';
import {
  RegistrationRequestType,
  RegistrationChildrenRequestType,
  RegisterParentType,
  RegistrationResponseType,
  OtpSendRequestType,
  trackSignupRequestType,
  OtpVerificationRequestType,
} from './types';

export const registrationAction = createAsyncAction(
  '@@registration/SEND_REGISTRATION_REQUEST',
  '@@registration/SEND_REGISTRATION_SUCCESS',
  '@@registration/SEND_REGISTRATION_FAILURE',
)<RegistrationRequestType, RegistrationResponseType, string>();

export const registrationChildrenAction = createAsyncAction(
  '@@registration/SEND_REGISTRATION_CHILDREN_REQUEST',
  '@@registration/SEND_REGISTRATION_CHILDREN_SUCCESS',
  '@@registration/SEND_REGISTRATION_CHILDREN_FAILURE',
)<RegistrationChildrenRequestType, undefined, string>();

export const registrationParentAction = createAsyncAction(
  '@@registration/SEND_REGISTRATION_PARENT_REQUEST',
  '@@registration/SEND_REGISTRATION_PARENT_SUCCESS',
  '@@registration/SEND_REGISTRATION_PARENT_FAILURE',
)<RegisterParentType, undefined, string>();

export const invitationAccountAction = createAsyncAction(
  '@@registration/INVITATION_REQUEST',
  '@@registration/INVITATION_SUCCESS',
  '@@registration/INVITATION_FAILURE',
)<{ token: string; callback?: GenericCallbackType }, undefined, string>();

export const otpVerificationAction = createAsyncAction(
  '@@registration/OTP_REQUEST',
  '@@registration/OTP_SUCCESS',
  '@@registration/OTP_FAILURE',
)<OtpVerificationRequestType, any, string>();

export const otpSendAction = createAsyncAction(
  '@@registration/OTP_SENT_REQUEST',
  '@@registration/OTP_SENT_SUCCESS',
  '@@registration/OTP_SENT_FAILURE',
)<OtpSendRequestType, any, string>();

export const otpClearAction = createAsyncAction(
  '@@registration/OTP_CLEAR_REQUEST',
  '@@registration/OTP_CLEAR_SUCCESS',
  '@@registration/OTP_CLEAR_FAILURE',
)<{}, {}, string>();

export const countryListAction = createAsyncAction(
  '@@registration/COUNTRY_LIST_REQUEST',
  '@@registration/COUNTRY_LIST_SUCCESS',
  '@@registration/COUNTRY_LIST_FAILURE',
)<void, Array<CountryListType>, string>();

export const trackSignupAction = createAsyncAction(
  '@@registration/TRACK_SIGNUP_REQUEST',
  '@@registration/TRACK_SIGNUP_SUCCESS',
  '@@registration/TRACK_SIGNUP_FAILURE',
)<trackSignupRequestType, undefined, string>();

export const resetRegistrationAction = createAction('@@registration/RESET_REGISTRATION_REQUEST')();
