import { createAction, createAsyncAction } from 'typesafe-actions';
import { GradeType } from './types';

export const loadGradesAction = createAsyncAction(
  '@@grades/LOAD_REQUEST',
  '@@grades/LOAD_SUCCESS',
  '@@grades/LOAD_FAILURE',
)<undefined | string, GradeType[], string>();

export const loadGradeStudentsAction = createAsyncAction(
  '@@grades/LOAD_STUDENTS_REQUEST',
  '@@grades/LOAD_STUDENTS_SUCCESS',
  '@@grades/LOAD__STUDENTS_FAILURE',
)<any, any, string>();

export const resetGradeStudents = createAction('@@grades/RESET_STUDENTS')();
