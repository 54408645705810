import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import styles from './LogoutButton.module.scss';

type LogoutButtonProps = {
  onClick: () => void;
};

export const LogoutButton = ({ onClick }: LogoutButtonProps): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <button type="button" className={styles['logout-button']} onClick={onClick}>
      <span className={styles['logout-button__title']}>
        {formatMessage({
          id: 'account.account.logout-button',
          defaultMessage: 'Sign out',
        })}
      </span>
    </button>
  );
};
