import { createAsyncAction, createAction } from 'typesafe-actions';
import { SemesterType, SemesterWeekType, SemesterWeeksPayload } from './types';

export const loadSemestersAction = createAsyncAction(
  '@@semesters/LOAD_REQUEST',
  '@@semesters/LOAD_SUCCESS',
  '@@semesters/LOAD_FAILURE',
)<undefined, SemesterType[], string>();

export const loadNextYearSemestersAction = createAsyncAction(
  '@@semesters/LOAD_NEXT_REQUEST',
  '@@semesters/LOAD_NEXT_SUCCESS',
  '@@semesters/LOAD_NEXT_FAILURE',
)<undefined, SemesterType[], string>();

export const resetSemesterAction = createAction('@@semester/RESET')();

export const fetchSemesterWeeksAction = createAsyncAction(
  '@@semesters/FETCH_SEMESTER_WEEKS_REQUEST',
  '@@semesters/FETCH_SEMESTER_WEEKS_SUCCESS',
  '@@semesters/FETCH_SEMESTER_WEEKS_FAILURE',
)<SemesterWeeksPayload, SemesterWeekType[], string>();
