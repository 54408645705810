import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { schoolDetail, SchoolBrancheType, SchoolType } from './types';
import { loadSchoolBranches, selectBranch } from './actions';
import * as actions from './actions';

export type SchoolBranchesType = ActionType<typeof actions>;
export type SelectBranchType = ActionType<typeof selectBranch>;

const isLoading = createReducer<boolean, SchoolBranchesType>(true)
  .handleAction(loadSchoolBranches.request, () => true)
  .handleAction([loadSchoolBranches.success, loadSchoolBranches.failure], () => false);

export const schoolBranchesInitialState = {
  school: {} as SchoolType,
  branches: [],
};

const data = createReducer<schoolDetail, SchoolBranchesType>(
  schoolBranchesInitialState,
).handleAction(loadSchoolBranches.success, (state, action) => action.payload);
const selectedBranch = createReducer<SchoolBrancheType, SelectBranchType>(
  {} as SchoolBrancheType,
).handleAction(selectBranch, (state, action) => action.payload || state);

const errors = createReducer<any, SchoolBranchesType>({})
  .handleAction(loadSchoolBranches.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadSchoolBranches.success, (state) => ({
    ...state,
    loading: '',
  }));

const schoolBranchesReducer = combineReducers({
  isLoading,
  data,
  errors,
  selectedBranch,
});

export type SchoolBranchesStateType = StateType<typeof schoolBranchesReducer>;

export default schoolBranchesReducer;
