import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ToastTypes } from 'contexts/ToastContext';
import {
  loadGradeStudentsAction,
  loadGradesAction,
  loadSubjectsAction,
  loadFiltersDataAction,
  loadContentDataAction,
  loadStudentDataAction,
  assignContentDataAction,
  completeContentDataAction,
  uploadAndAssignContentAction,
  selfAssignContentAction,
  loadClassroomStudentsAction,
  liveEventsAction,
} from './actions';
import { mapperLessonWithLiveClassLessons } from './adapter';
import {
  getGradeStudents,
  getGrades,
  getSubjects,
  getFiltersData,
  getContentData,
  getStudentsData,
  assignContentData,
  completeContent,
  getLiveClassContentData,
  uploadAndAssignContent,
  selfAssignContent,
  getClassroomStudents,
} from './api';
import { updateAdditionalContentAction } from '../lessonsWeekSchedule/actions';

function* loadFiltersDataRequest({
  payload,
}: ReturnType<typeof loadFiltersDataAction.request>): SagaIterator<void> {
  try {
    const filtersData = yield call(getFiltersData, payload);
    yield put(loadFiltersDataAction.success(filtersData));
  } catch (error) {
    yield put(loadFiltersDataAction.failure(error));
  }
}

function* loadContentDataRequest({
  payload,
}: ReturnType<typeof loadContentDataAction.request>): SagaIterator<void> {
  try {
    const { grade, subject, searchText, semesterId } = payload;
    const [lessons, liveEvents] = yield all([
      call(getContentData, grade, subject, searchText, semesterId),
      call(getLiveClassContentData, grade, subject, searchText, semesterId),
    ]);
    const contentLibraryLessons = mapperLessonWithLiveClassLessons(lessons, liveEvents);
    yield put(loadContentDataAction.success(contentLibraryLessons));
    yield put(liveEventsAction(liveEvents));
  } catch (error) {
    yield put(loadContentDataAction.failure(error));
    yield put(liveEventsAction([]));
  }
}

function* loadStudentsDataRequest(): SagaIterator<void> {
  try {
    const students = yield call(getStudentsData);
    yield put(loadStudentDataAction.success(students));
  } catch (error) {
    yield put(loadStudentDataAction.failure(error));
  }
}

function* loadGradeStudentsRequest({ payload }: any): SagaIterator<void> {
  try {
    const { grade } = payload;
    const gradeStudents = yield call(getGradeStudents, grade);
    yield put(loadGradeStudentsAction.success(gradeStudents));
  } catch (error) {
    yield put(loadGradeStudentsAction.failure(error));
  }
}

function* loadGradesRequest(): SagaIterator<void> {
  try {
    const grades = yield call(getGrades);
    yield put(loadGradesAction.success(grades));
  } catch (error) {
    yield put(loadGradesAction.failure(error));
  }
}

function* loadSubjectsRequest(): SagaIterator<void> {
  try {
    const subjects = yield call(getSubjects);
    yield put(loadSubjectsAction.success(subjects));
  } catch (error) {
    yield put(loadSubjectsAction.failure(error));
  }
}

function* assignContentDataRequest({
  payload,
}: ReturnType<typeof assignContentDataAction.request>): SagaIterator<void> {
  try {
    const { students, role, contentId, event } = payload;
    const assignLessonResponse = yield call(assignContentData, students, role, contentId, event);
    yield put(assignContentDataAction.success(assignLessonResponse));
    payload?.callback(ToastTypes.SUCCESS);
  } catch (error) {
    if (error.length > 0) payload?.callback(ToastTypes.ERROR, error[0]);
    else payload?.callback(ToastTypes.ERROR);
    yield put(assignContentDataAction.failure(error));
  }
}

function* uploadAndAssignContentDataRequest({
  payload,
}: ReturnType<typeof uploadAndAssignContentAction.request>): SagaIterator<void> {
  try {
    const { students, role, subject, lessonName, file, removeFile, onUploadProgress } = payload;
    const uploadAndAssignLessonResponse = yield call(
      uploadAndAssignContent,
      students,
      role,
      subject,
      lessonName,
      file,
      onUploadProgress,
    );
    yield put(uploadAndAssignContentAction.success(uploadAndAssignLessonResponse));
    if (removeFile) {
      removeFile(file?.name || '');
    }
    payload.callback(ToastTypes.SUCCESS);
  } catch (error) {
    payload.callback(ToastTypes.ERROR);

    yield put(uploadAndAssignContentAction.failure(error));
  }
}

function* selfAssignContentRequest({
  payload,
}: ReturnType<typeof selfAssignContentAction.request>): SagaIterator<void> {
  try {
    const { contentId, event } = payload;
    const selfAssignResponse = yield call(selfAssignContent, contentId, event);
    yield put(selfAssignContentAction.success(selfAssignResponse));
    payload.callBack('success');
  } catch (error) {
    payload.callBack('error', error);
    yield put(selfAssignContentAction.failure(error));
  }
}

function* completeContentDataRequest({
  payload,
}: ReturnType<typeof completeContentDataAction.request>): SagaIterator<void> {
  try {
    const { lessonId } = payload;
    yield call(completeContent, lessonId);
    payload.callback?.();
    yield put(completeContentDataAction.success({ contentId: lessonId, isCompleted: true }));
    yield put(updateAdditionalContentAction(lessonId));
  } catch (error) {
    yield put(completeContentDataAction.failure(error));
  }
}

function* loadClassroomStudentsRequest({
  payload,
}: ReturnType<typeof loadClassroomStudentsAction.request>): SagaIterator<void> {
  try {
    const { teacherId, classroomId } = payload;
    const classroomStudents = yield call(getClassroomStudents, teacherId, classroomId);
    yield put(loadClassroomStudentsAction.success(classroomStudents));
  } catch (error) {
    yield put(loadClassroomStudentsAction.failure(error));
  }
}

function* scheduleSaga(): SagaIterator {
  yield all([takeEvery(loadFiltersDataAction.request, loadFiltersDataRequest)]);
  yield all([takeEvery(loadContentDataAction.request, loadContentDataRequest)]);
  yield all([takeEvery(loadStudentDataAction.request, loadStudentsDataRequest)]);
  yield all([takeEvery(loadGradeStudentsAction.request, loadGradeStudentsRequest)]);
  yield all([takeEvery(loadGradesAction.request, loadGradesRequest)]);
  yield all([takeEvery(loadSubjectsAction.request, loadSubjectsRequest)]);
  yield all([takeEvery(loadClassroomStudentsAction.request, loadClassroomStudentsRequest)]);
  yield all([takeEvery(assignContentDataAction.request, assignContentDataRequest)]);
  yield all([takeEvery(selfAssignContentAction.request, selfAssignContentRequest)]);
  yield all([takeEvery(uploadAndAssignContentAction.request, uploadAndAssignContentDataRequest)]);
  yield all([takeEvery(completeContentDataAction.request, completeContentDataRequest)]);
}

export default scheduleSaga;
