import { ORIENTATION_VIDEO_ENABLED_ROLES } from 'constants/settings';

export const THRESHOLD_ORIENTATION_LIMIT = 3;
export const WATCHED_ORIENTATION_VIDEO = 'mark_orientation_video_watched';

export const markOrientationVideoWatched = () =>
  localStorage.setItem(WATCHED_ORIENTATION_VIDEO, 'true');

export const removeOrientationVideoWatched = () =>
  localStorage.removeItem(WATCHED_ORIENTATION_VIDEO);

export const isOrientationVideoWatched = () =>
  Boolean(localStorage.getItem(WATCHED_ORIENTATION_VIDEO) === 'true');

export const getOrientationEnabledRoles = () =>
  ORIENTATION_VIDEO_ENABLED_ROLES?.split(',').map((role) => role.toLowerCase().trim());
