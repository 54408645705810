import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { Lang } from 'lang';

import { getMe } from '../account/api';
import { MeType } from '../account/types';
import { AccountStateType } from '../account/reducer';
import { selectorAccount } from '../account/selectors';
import {
  loadPreferencesAction,
  updateNotificationPreferencesAction,
  updatePreferencesAction,
  updateAvatarLoader,
} from './actions';
import { getPreferences, updatePreferences } from './api';
import { PreferencesLangType, PreferencesType } from './types';

function* loadPreferencesRequest(): SagaIterator<void> {
  try {
    const { username }: MeType = yield call(getMe);
    const preferences: PreferencesLangType = yield call(getPreferences, username);
    const prefLang = preferences?.['pref-lang'] || preferences?.prefLang;
    let updatePrefResponse: PreferencesLangType = { prefLang };
    if (!prefLang) {
      updatePrefResponse = yield call(updatePreferences, username, { prefLang: Lang.ar });
    }
    yield put(loadPreferencesAction.success({ ...preferences, ...updatePrefResponse }));
  } catch (error) {
    yield put(loadPreferencesAction.failure(error));
  }
}

function* updatePreferencesUpdate({
  payload,
}: ReturnType<typeof updatePreferencesAction.request>): SagaIterator<void> {
  try {
    if (payload.avatar) {
      yield put(updateAvatarLoader(true));
    }
    const {
      data: { username },
    }: AccountStateType = yield select(selectorAccount);

    let preferences: PreferencesType;
    if (username) {
      preferences = yield call(updatePreferences, username, payload);
    } else {
      preferences = payload;
    }

    yield put(updatePreferencesAction.success(preferences));
    yield put(updateAvatarLoader(false));
    if (payload.avatar && typeof payload.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    yield put(updatePreferencesAction.failure(error));
  }
}

function* updateNotificationPreferences({
  payload,
}: ReturnType<typeof updateNotificationPreferencesAction.request>): SagaIterator<void> {
  try {
    const { username, key, value, grade } = payload;
    const preferences = yield call(updatePreferences, username, { [key]: value });
    yield put(updateNotificationPreferencesAction.success({ grade, ...preferences }));
  } catch (error) {
    yield put(updateNotificationPreferencesAction.failure(error));
  }
}

function* preferencesSaga(): SagaIterator {
  yield all([
    takeEvery(loadPreferencesAction.request, loadPreferencesRequest),
    takeEvery(updatePreferencesAction.request, updatePreferencesUpdate),
    takeEvery(updateNotificationPreferencesAction.request, updateNotificationPreferences),
  ]);
}

export default preferencesSaga;
