import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import styles from './Spinner.module.scss';

type SpinnerProps = {
  className?: string;
};

export const Spinner: FunctionComponent<SpinnerProps> = ({ className }) => {
  return <div className={cn(styles.spinner, className, styles.darkSpinner)} />;
};

export default Spinner;
