import styled, { css } from 'styled-components';

import { spin, bounceInTop, sizeMobile, sizeTablet } from 'modules/GradeOneToThree/styled-mixin';

export const DialogWrapper = styled.div`
  margin: 80px auto 20px;
  position: relative;
  max-width: 550px;
  width: 100%;
`;

export const Root = styled.div`
  z-index: 1;
  padding: 30px 15px;
  position: relative;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.modal.background};
  border: 2px solid ${(p) => p.theme.colors.modal.border};
  font-size: ${(p) => p.theme.font.size.primary};
  font-weight: normal;
  color: ${(p) => p.theme.colors.modal.color};
  margin: 0 auto;
  width: 100%;

  ${sizeMobile(css`
    padding: 20px;
  `)};

  ${sizeTablet(css`
    padding: 40px;
  `)};
`;

export const Heading = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

export const SubHeading = styled.p`
  text-align: center;
  font-size: ${(p) => p.theme.font.size.secondary};
  margin-bottom: 20px;
`;

export const IconWrapper = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  -webkit-animation: ${spin} 6s infinite linear;
  animation: ${spin} 6s infinite linear;

  ${sizeMobile(css`
    top: 30px;
    right: 30px;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      right: unset;
      left: 15px;

      ${sizeMobile(css`
        right: unset;
        left: 30;
      `)};
    `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  button {
    margin-right: 20px;
  }

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      button {
        margin-right: 0;
        margin-left: 20px;
      }
    `};
`;

export const DialogImage = styled.img`
  position: absolute;
  top: -83px;
  left: 17px;
  animation: ${bounceInTop} 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      left: unset;
      right: 17px;
    `};
`;
