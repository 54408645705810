import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  loadSemesterScheduleClassesAction,
  loadSemesterScheduleSubjectsAction,
  loadSemesterScheduleFiltersAction,
} from './actions';
import {
  getSemesterScheduleClasses,
  getSemesterScheduleDetail,
  getSemesterScheduleFilters,
} from './api';
import {
  ScheduleFiltersType,
  SemesterScheduleClassType,
  SemesterScheduleSubjectType,
} from './types';

function* loadSemesterScheduleClasses(): SagaIterator<void> {
  try {
    const classes: SemesterScheduleClassType[] = yield call(getSemesterScheduleClasses);
    yield put(loadSemesterScheduleClassesAction.success(classes));
  } catch (error) {
    yield put(loadSemesterScheduleClassesAction.failure(error));
  }
}

function* loadSemesterScheduleFilters({
  payload,
}: ReturnType<typeof loadSemesterScheduleFiltersAction.request>): SagaIterator<void> {
  try {
    const filters: ScheduleFiltersType = yield call(getSemesterScheduleFilters, payload);
    yield put(loadSemesterScheduleFiltersAction.success(filters));
  } catch (error) {
    yield put(loadSemesterScheduleClassesAction.failure(error));
  }
}

function* loadSemesterScheduleSubjects({
  payload,
}: ReturnType<typeof loadSemesterScheduleSubjectsAction.request>): SagaIterator<void> {
  try {
    const subjects: SemesterScheduleSubjectType[] = yield call(getSemesterScheduleDetail, payload);
    yield put(loadSemesterScheduleSubjectsAction.success(subjects));
  } catch (error) {
    yield put(loadSemesterScheduleSubjectsAction.failure(error));
  }
}

function* semesterScheduleSaga(): SagaIterator {
  yield all([takeEvery(loadSemesterScheduleClassesAction.request, loadSemesterScheduleClasses)]);
  yield all([takeEvery(loadSemesterScheduleFiltersAction.request, loadSemesterScheduleFilters)]);
  yield all([takeEvery(loadSemesterScheduleSubjectsAction.request, loadSemesterScheduleSubjects)]);
}

export default semesterScheduleSaga;
