import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'header.feedback.title.desktop',
    defaultMessage: 'Help us to help you',
  },
  systemDescription: {
    id: 'header.feedback.system.description',
    defaultMessage: 'We’d like your feedback to improve our application.',
  },
  systemFavorite: {
    id: 'header.feedback.system.favorite',
    defaultMessage: 'How happy are you with Advanced Learning World app?',
  },
  contentDescription: {
    id: 'header.feedback.content.description',
    defaultMessage: 'Help us to provide the content you’d like.',
  },
  contentFavorite: {
    id: 'header.feedback.content.favorite',
    defaultMessage: 'How happy are you with the content?',
  },
  comment: {
    id: 'header.feedback.comment.title',
    defaultMessage: 'Comments (optional)',
  },
  placeholder: {
    id: 'header.feedback.comment.placeholder',
    defaultMessage: 'Provide additional comments',
  },
  helpWarning: {
    id: 'help.add.email.message',
    defaultMessage: 'To access this feature, please confirm your email from the ',
  },
  settings: {
    id: 'settings.title',
    defaultMessage: 'Settings',
  },
});

export default messages;
