import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getSvgURL, getPngURL } from 'lib';
import { getUserTitle } from 'utils/getUserTitle';
import { getUserType } from 'utils/getUserType';
import { ROUTES } from 'constants/routes';
import { DISABLE_ARS, DISABLE_B2C_RECOMMENDATIONS, DISABLE_B2C_REWARDS } from 'constants/settings';

import {
  selectorAccountData,
  isB2bStudentRoleSelector,
  isALWStudentRoleSelector,
} from 'store/account/selectors';
import { rewardStatsSelector } from 'store/Rewards/selectors';
import { loadRewardStatsAction } from 'store/Rewards/actions';

import LogoutMenu from 'components/LayoutBase/components/Header/LogoutMenu';
import NotificationMenu from 'components/LayoutBase/components/Header/NotificationMenu';
import Feedback from 'components/LayoutBase/components/Header/Feedback';
import RecommendationMenu from 'components/LayoutBase/components/Header/RecommendationMenu';
import OrientationDialog from 'components/Orientation';
import { getOrientationEnabledRoles } from 'components/Orientation/utils';

import {
  Root,
  LeftHeaderList,
  DashboardItem,
  RewardsLinkListItem,
  RewardsCoinListItem,
  PlusIconListItem,
  RightHeaderList,
  RightHeaderListItem,
} from './elements';

type ProfileDetailsType = {
  src: string;
  srcLarge: string;
  name: string;
  role: string;
  isGradeOneToThree?: boolean;
};

const TopHeader: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { totalPoints } = useSelector(rewardStatsSelector);

  useEffect(() => {
    dispatch(loadRewardStatsAction.request());
  }, [dispatch]);

  const {
    firstName,
    lastName,
    role,
    schoolRole,
    gender,
    profileImage: { imageUrlLarge: imageUrl },
  } = useSelector(selectorAccountData);
  const isB2BStudent = useSelector(isB2bStudentRoleSelector);
  const isB2CStudent = useSelector(isALWStudentRoleSelector);

  const userType = getUserType(role, schoolRole) || '';
  const orientationEnabledRoles = getOrientationEnabledRoles();
  const shouldOrientationVisible = orientationEnabledRoles?.some((role) => role === userType);

  const profileDetails: ProfileDetailsType = {
    srcLarge: imageUrl,
    src: imageUrl,
    name: `${firstName} ${lastName}`,
    role: getUserTitle(userType, gender),
    isGradeOneToThree: true,
  };

  return (
    <Root id="top-header">
      <LeftHeaderList>
        <DashboardItem id="dashboard">
          <Link to={ROUTES.root}>
            <img
              src={getSvgURL('dashboard', true)}
              alt={formatMessage({
                id: 'dashboard.title',
                defaultMessage: 'Dashboard',
              })}
            />
          </Link>
        </DashboardItem>
        {(isB2BStudent || !DISABLE_B2C_REWARDS) && (
          <>
            <RewardsLinkListItem>
              <Link to={ROUTES.rewards}>
                {formatMessage({
                  id: 'navbar.menu.rewards.title',
                  defaultMessage: 'My Rewards',
                })}
              </Link>
            </RewardsLinkListItem>

            <RewardsCoinListItem>{totalPoints ?? 0}</RewardsCoinListItem>

            <PlusIconListItem>
              <Link to={ROUTES.rewards}>
                <img
                  src={getPngURL('plus-icon', true)}
                  alt={formatMessage({
                    id: 'dasboard.icon.plus',
                    defaultMessage: 'plus',
                  })}
                />
              </Link>
            </PlusIconListItem>
          </>
        )}
      </LeftHeaderList>
      <RightHeaderList>
        {!DISABLE_ARS && (isB2BStudent || (isB2CStudent && !DISABLE_B2C_RECOMMENDATIONS)) && (
          <RightHeaderListItem>
            <RecommendationMenu isGradeOneToThree />
          </RightHeaderListItem>
        )}
        <RightHeaderListItem>
          {shouldOrientationVisible && <OrientationDialog userType={userType} />}
        </RightHeaderListItem>
        <RightHeaderListItem>
          <Feedback isGradeOneToThree />
        </RightHeaderListItem>
        <RightHeaderListItem>
          <NotificationMenu isGradeOneToThree />
        </RightHeaderListItem>
        <RightHeaderListItem>
          <LogoutMenu profileDetails={profileDetails} />
        </RightHeaderListItem>
      </RightHeaderList>
    </Root>
  );
};

export default TopHeader;
