import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  teacherGradesData,
  gradeDetailPayload,
  gradeDetailResponse,
  fetchStudentReportPayload,
  studentReportsResponse,
  statsScaleType,
  reportsGradeListPayload,
  reportsErrorPayload,
  parentDashboardReports,
  RecommendationsClassesPayload,
  classroomsListReturnType,
  ClassroomRecommendations,
  LiveClassRecommendationType,
  reportsStatsScalePayload,
} from './types';

export const fetchGradesAction = createAsyncAction(
  '@@reports/FETCH_GRADES_REQUEST',
  '@@reports/FETCH_GRADES_SUCCESS',
  '@@reports/FETCH_GRADES_FAILURE',
)<undefined | reportsGradeListPayload, Array<teacherGradesData>, string>();

export const fetchGradeDetailAction = createAsyncAction(
  '@@reports/FETCH_GRADE_DETAIL_REQUEST',
  '@@reports/FETCH_GRADE_DETAIL_SUCCESS',
  '@@reports/FETCH_GRADE_DETAIL_FAILURE',
)<gradeDetailPayload, gradeDetailResponse, string>();

export const studentReportsAction = createAsyncAction(
  '@@reports/FETCH_STUDENT_REPORTS_REQUEST',
  '@@reports/FETCH_STUDENT_REPORTS_SUCCESS',
  '@@reports/FETCH_STUDENT_REPORTS_FAILURE',
)<fetchStudentReportPayload, studentReportsResponse, string | reportsErrorPayload>();

export const reportsStatsScaleAction = createAsyncAction(
  '@@reports/STATS_SCALE_REQUEST',
  '@@reports/STATS_SCALE_SUCCESS',
  '@@reports/STATS_SCALE_FAILURE',
)<undefined, Array<statsScaleType>, string>();

export const reportsStatsScaleByGradeAction = createAsyncAction(
  '@@reports/STATS_SCALE_BY_GRADE_REQUEST',
  '@@reports/STATS_SCALE_BY_GRADE_SUCCESS',
  '@@reports/STATS_SCALE_BY_GRADE_FAILURE',
)<reportsStatsScalePayload, statsScaleType[], string>();

export const parentDashboardReportsAction = createAsyncAction(
  '@@reports/PARENT_DASHBOARD_REPORTS_REQUEST',
  '@@reports/PARENT_DASHBOARD_REPORTS_SUCCESS',
  '@@reports/PARENT_DASHBOARD_REPORTS_FAILURE',
)<undefined, Array<parentDashboardReports>, string>();
export const updateReportsChildAction = createAsyncAction(
  '@@reports/UPDATE_CHILD_REQUEST',
  '@@reports/UPDATE_CHILD_SUCCESS',
  '@@reports/UPDATE_CHILD_FAILURE',
)<any, any, string>();

export const fetchClassroomsAction = createAsyncAction(
  '@@reports/FETCH_CLASSROOOMS_REQUEST',
  '@@reports/FETCH_CLASSROOOMS_SUCCESS',
  '@@reports/FETCH_CLASSROOOMS_FAILURE',
)<any, any, string>();

export const fetchClassroomDetailsAction = createAsyncAction(
  '@@reports/FETCH_CLASSROOOMS_DETAILS_REQUEST',
  '@@reports/FETCH_CLASSROOOMS_DETAILS_SUCCESS',
  '@@reports/FETCH_CLASSROOOMS_DETAILS_FAILURE',
)<any, any, string>();

export const fetchStudentProgressClassesAction = createAsyncAction(
  '@@reports/FETCH_STUDENT_PROGRESS_CLASSES_REQUEST',
  '@@reports/FETCH_STUDENT_PROGRESS_CLASSES_SUCCESS',
  '@@reports/FETCH_STUDENT_PROGRESS_CLASSES_FAILURE',
)<any, any, string>();

export const fetchPerformanceStatsAction = createAsyncAction(
  '@@reports/FETCH_PERFORMANCE_STATS_REQUEST',
  '@@reports/FETCH_PERFORMANCE_STATS_SUCCESS',
  '@@reports/FETCH_PERFORMANCE_STATS_FAILURE',
)<any, any, string>();

export const fetchStatsStudentsListAction = createAsyncAction(
  '@@reports/FETCH_STATS_STUDENTS_LIST_REQUEST',
  '@@reports/FETCH_STATS_STUDENTS_LIST_SUCCESS',
  '@@reports/FETCH_STATS_STUDENTS_LIST_FAILURE',
)<any, any, string>();

export const sendTeacherEmailAction = createAsyncAction(
  '@@reports/SEND_TEACHER_EMAIL_REQUEST',
  '@@reports/SEND_TEACHER_EMAIL_SUCCESS',
  '@@reports/SEND_TEACHER_EMAIL_FAILURE',
)<any, any, string>();

export const fetchRecommendationClasses = createAsyncAction(
  '@@reports/FETCH_RECOMMENDATION_CLASSES_REQUEST',
  '@@reports/FETCH_RECOMMENDATION_CLASSES_SUCCESS',
  '@@reports/FETCH_RECOMMENDATION_CLASSES_FAILURE',
)<RecommendationsClassesPayload, classroomsListReturnType, string | reportsErrorPayload>();

export const fetchClassroomRecommendations = createAsyncAction(
  '@@reports/FETCH_CLASSROOM_RECOMMENDATION_REQUEST',
  '@@reports/FETCH_CLASSROOM_RECOMMENDATION_SUCCESS',
  '@@reports/FETCH_CLASSROOM_RECOMMENDATION_FAILURE',
)<RecommendationsClassesPayload, ClassroomRecommendations, string | reportsErrorPayload>();

export const fetchLiveClassRecommendations = createAsyncAction(
  '@@reports/FETCH_LIVE_CLASS_RECOMMENDATION_REQUEST',
  '@@reports/FETCH_LIVE_CLASS_RECOMMENDATION_SUCCESS',
  '@@reports/FETCH_LIVE_CLASS_RECOMMENDATION_FAILURE',
)<RecommendationsClassesPayload, LiveClassRecommendationType, string | reportsErrorPayload>();

export const resetReportsAction = createAction('@@reports/RESET__SUBJECTS_DATA')();

export const resetReportsSubjectsAction = createAction('@@reports/RESET_REPORTS_SUBJECTS_DATA')();
