import { TeacherType, TeacherStudentFilterPayload } from 'store/shoolLists/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const loadB2BTeacherStats = createAsyncAction(
  '@@b2bTeachers/LOAD_REQUEST',
  '@@b2bTeachers/LOAD_SUCCESS',
  '@@b2bTeachers/LOAD_FAILURE',
)<any, any, string>();

export const loadB2BTeacherStudentsList = createAsyncAction(
  '@@b2bTeachers/LOAD_STUDENTS_LIST_REQUEST',
  '@@b2bTeachers/LOAD_STUDENTS_LIST_SUCCESS',
  '@@b2bTeachers/LOAD_STUDENTS_LIST_FAILURE',
)<any, any, string>();

export const loadB2BTeacherStudentsListFilter = createAsyncAction(
  '@@b2bTeachers/LOAD_STUDENTS_LIST_FILTER_REQUEST',
  '@@b2bTeachers/LOAD_STUDENTS_LIST_FILTER_SUCCESS',
  '@@b2bTeachers/LOAD_STUDENTS_LIST_FILTER_FAILURE',
)<TeacherStudentFilterPayload, Array<TeacherType>, string>();

export const resetB2BTeacherStudentsList = createAction('@@b2bTeachers/RESET')();

export const loadB2BTeacherLiveSessions = createAsyncAction(
  '@@b2bTeachers/LOAD_STUDENTS_SESSION_LIST_REQUEST',
  '@@b2bTeachers/LOAD_SESSION_LIST_SUCCESS',
  '@@b2bTeachers/LOAD_SESSION_LIST_FAILURE',
)<any, any, string>();

export const loadB2BTeacherClassRoomOptions = createAsyncAction(
  '@@b2bTeachers/LOAD_STUDENTS_CLASSROOM_OPTIONS_REQUEST',
  '@@b2bTeachers/LOAD_STUDENTS_CLASSROOM_OPTIONS_SUCCESS',
  '@@b2bTeachers/LOAD__STUDENTS_CLASSROOM_OPTIONS_FAILURE',
)<any, any, string>();

export const loadB2BTeacherStudentProfile = createAsyncAction(
  '@@b2bTeachers/LOAD_STUDENTS_PROFILE_REQUEST',
  '@@b2bTeachers/LOAD_STUDENTS_PROFILE_SUCCESS',
  '@@b2bTeachers/LOAD__STUDENTS_PROFILE_FAILURE',
)<any, any, string>();

export const studentAttachment = createAsyncAction(
  '@@b2bTeachers/LOAD_STUDENTS_ATTACH_REQUEST',
  '@@b2bTeachers/LOAD_STUDENTS_ATTACH_SUCCESS',
  '@@b2bTeachers/LOAD__STUDENTS_ATTACH_FAILURE',
)<any, any, string>();
