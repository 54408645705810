import React, { FunctionComponent, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import messages from './Feedback.messages';
import style from './Feedback.module.scss';
import HelpDisclaimer from './HelpDisclaimer';

const HelpWarning: FunctionComponent = (): ReactElement => {
  const { formatMessage } = useIntl();
  const isGradeOneToThree = useIsGradeOneToThree();

  return (
    <div className={cn(style.feedback, { [style.gradeFeedback]: isGradeOneToThree })}>
      <div className={style.feedback__form}>
        <header className={style.feedback__header}>
          <h3>{formatMessage(messages.title)}</h3>
        </header>
        <div className={style.feedback__main}>
          <div className="inlineMsg inlineMsg-warning no-shadow mb-0 p-15">
            <HelpDisclaimer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpWarning;
