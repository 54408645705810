import React, { FunctionComponent, ReactNode } from 'react';
import { Loader } from 'lib';
import styles from './Body.module.scss';

export type NewBodyProps = {
  isLoading?: boolean;
  children: ReactNode;
};

const Body: FunctionComponent<NewBodyProps> = ({ isLoading = false, children }) => {
  if (isLoading) return <Loader />;

  return <div className={styles.body}>{children}</div>;
};

export default Body;
