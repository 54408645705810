import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { addStudentAction, loadStudentOptionsAction } from './actions';
import { addStudent, getStudentOptions } from './api';

function* createStudentRequest({
  payload: { callback, ...payload },
}: ReturnType<typeof addStudentAction.request>): SagaIterator<void> {
  try {
    yield call(addStudent, payload);
    yield put(addStudentAction.success());
    callback && callback('success');
  } catch (error) {
    yield put(addStudentAction.failure(error));
    callback && callback('fail', error);
  }
}

function* loadStudentOptionsRequest({ payload }: any): SagaIterator<void> {
  try {
    const studentOptions = yield call(getStudentOptions, payload);
    yield put(loadStudentOptionsAction.success(studentOptions));
  } catch (error) {
    yield put(loadStudentOptionsAction.failure(error));
  }
}

function* schoolStudentSaga(): SagaIterator {
  yield all([takeEvery(addStudentAction.request, createStudentRequest)]);
  yield all([takeEvery(loadStudentOptionsAction.request, loadStudentOptionsRequest)]);
}

export default schoolStudentSaga;
