import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import { bulkImportTeachers, bulkImportStudents } from './actions';
import { BulkImportType, BulkImportErrorsType } from './types';

export type BulkImportTeachersType = ActionType<typeof actions>;

const isLoading = combineReducers({
  isLoadingBulkStudents: createReducer<boolean, BulkImportTeachersType>(true)
    .handleAction(bulkImportStudents.request, () => true)
    .handleAction([bulkImportStudents.success, bulkImportStudents.failure], () => false),
  isLoadingBulkTeachers: createReducer<boolean, BulkImportTeachersType>(true)
    .handleAction(bulkImportTeachers.request, () => true)
    .handleAction([bulkImportTeachers.success, bulkImportTeachers.failure], () => false),
});

const data = createReducer<BulkImportType, BulkImportTeachersType>({} as BulkImportType)
  .handleAction(bulkImportTeachers.success, (state, action) => action.payload)
  .handleAction(bulkImportStudents.success, (state, action) => action.payload);

const errors = createReducer<BulkImportErrorsType, BulkImportTeachersType>({})
  .handleAction(bulkImportTeachers.failure, (state, action) => ({
    ...state,
    errors: action.payload,
  }))
  .handleAction(bulkImportTeachers.success, (state) => ({
    ...state,
    errors: '',
  }))
  .handleAction(bulkImportStudents.failure, (state, action) => ({
    ...state,
    errors: action.payload,
  }))
  .handleAction(bulkImportStudents.success, (state) => ({
    ...state,
    errors: '',
  }));

const b2bBulkImportReducer = combineReducers({
  isLoading,
  data,
  errors,
});
export type b2bBulkImportStateType = StateType<typeof b2bBulkImportReducer>;
export default b2bBulkImportReducer;
