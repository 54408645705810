import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { NewButton, ButtonTypes, SVG } from 'lib';
import { ROUTES } from 'constants/routes';
import LayoutForm from 'components/LayoutForm';

import styles from './ErrorView.module.scss';

type ErrorViewProps = {
  heading: string;
  subheading: string;
};

const ErrorView = ({ heading, subheading }: ErrorViewProps) => {
  const { formatMessage } = useIntl();

  return (
    <LayoutForm title={subheading}>
      <div className={styles.errorPage}>
        <div className={styles.errorPage__image}>
          <SVG name="error-page" />
          <span className={styles.errorPage__image__text}>{heading}</span>
        </div>
        <h1 className={styles.errorPage__title}>{subheading}</h1>
        <Link to={ROUTES.root}>
          <NewButton style={[ButtonTypes.buttonPrimary]}>
            {formatMessage({
              id: 'error.page.button',
              defaultMessage: 'Go to homepage',
            })}
          </NewButton>
        </Link>
      </div>
    </LayoutForm>
  );
};

export default ErrorView;
