export enum ButtonTypes {
  basic = 'basic',
  flat = 'flat',
  link = 'link',
  stroke = 'stroke',
}

export enum ButtonSizes {
  medium = 'medium',
  full = 'full',
}
