import { createAsyncAction, createAction } from 'typesafe-actions';
import { ChildrenSubscriptionsType } from './types';
import { Role } from '../account/types';
import { SubscriptionSate } from '../account/adapter';

export type CanceledSubscriptionErrorType = { message: string; userId: number | null };

export const loadChildrenSubscriptions = createAsyncAction(
  '@@studentSubscription/LOAD_REQUEST',
  '@@studentSubscription/LOAD_SUCCESS',
  '@@studentSubscription/LOAD_FAILURE',
)<undefined, ChildrenSubscriptionsType[], string>();

export const updateSubscription = createAsyncAction(
  '@@updateSubscription/UPDATE_REQUEST',
  '@@updateSubscription/UPDATE_SUCCESS',
  '@@updateSubscription/UPDATE_FAILURE',
)<undefined, { subscription: SubscriptionSate; userId: number }, string>();

export const cancelChildrenSubscriptionAction = createAsyncAction(
  '@@cancelStudentSubscription/LOAD_REQUEST',
  '@@cancelStudentSubscription/LOAD_SUCCESS',
  '@@cancelStudentSubscription/LOAD_FAILURE',
)<{ userId: number; role: Role }, undefined, CanceledSubscriptionErrorType>();

export const updateChildAutoDeductAction = createAction(
  '@@parent/CHILD_AUTO_DEDUCT_PAYMENT_CHECK',
)<{ childId: number; autoDeductionCheck: boolean }>();

export const resetErrorsSubscriptionsActions = createAction('@@resetErrors/RESET')();
