import arMessages from './messages/ar.json';
import enMessages from './messages/en.json';

export enum Lang {
  ar = 'ar',
  en = 'en-AU',
}

export enum Direction {
  ltr = 'ltr',
  rtl = 'rtl',
}

export const getLocale = (locale?: string): Lang => {
  const lang = locale || Lang.ar;

  if (lang.startsWith('en')) return Lang.en;
  if (lang.startsWith('ar')) return Lang.ar;

  return Lang.en;
};

export const LOCALES = {
  [Lang.ar]: { direction: Direction.rtl, messages: arMessages, name: 'العربية', shortName: 'AR' },
  [Lang.en]: { direction: Direction.ltr, messages: enMessages, name: 'English', shortName: 'EN' },
};

export enum LANGUAGES_LABEL {
  english = 'En',
  arabic = 'Ar',
}
