import 'external-svg-loader';
import React, { FunctionComponent } from 'react';

import {
  ASSETS_URL_SVG,
  ASSETS_URL_PNG,
  ASSETS_GRADE_ONE_TO_THREE_URL_SVG,
  ASSETS_GRADE_ONE_TO_THREE_URL_PNG,
} from './contants';

type SVGType = {
  name: string;
  isGradeOneToThree?: boolean;
  className?: string;
};

export const getSvgURL = (svgName: string, isGradeOneToThree = false): string =>
  isGradeOneToThree
    ? `${ASSETS_GRADE_ONE_TO_THREE_URL_SVG}${svgName}.svg`
    : `${ASSETS_URL_SVG}${svgName}.svg`;

export const getPngURL = (pngName: string, isGradeOneToThree = false): string =>
  isGradeOneToThree
    ? `${ASSETS_GRADE_ONE_TO_THREE_URL_PNG}${pngName}.png`
    : `${ASSETS_URL_PNG}${pngName}.png`;

export const SVG: FunctionComponent<SVGType> = ({
  name,
  isGradeOneToThree = false,
  className,
  ...rest
}) => {
  return (
    <svg
      data-src={getSvgURL(name, isGradeOneToThree)}
      data-js="enabled"
      className={className}
      {...rest}
    />
  );
};
