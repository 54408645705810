import React, { Fragment, useContext, ChangeEvent } from 'react';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Role } from 'store/account/types';
import {
  accountRoleSelector,
  isALWStudentRoleSelector,
  isAuthorOrAlwAdminSelector,
  isB2bStudentRoleSelector,
  isStudentRoleSelector,
} from 'store/account/selectors';

import { Icon, getPngURL, IconCommon } from 'lib';
import { Direction, Lang, LOCALES } from 'lang';

import { getFormattedLabel } from 'utils/formattedLocals';
import { isArabicMode } from 'utils/helpers';
import { isSummerSemester } from 'utils/summerSemesterUtils';

import { useScreenInclude } from 'hooks/useScreenInclude';
import { useInAppView } from 'hooks/useInAppView';

import { DashboardContext } from 'components/Dashboard/Dashboard.Context';
import OrientationDialog from 'components/Orientation';
import { getOrientationEnabledRoles } from 'components/Orientation/utils';

import { DashboardTypes } from 'constants/entities';
import { ROUTES } from 'constants/routes';
import { DISABLE_ARS, DISABLE_B2C_RECOMMENDATIONS } from 'constants/settings';

import NotificationMenu from './NotificationMenu/NotificationMenu';
import Feedback from './Feedback';
import styles from './Header.module.scss';
import RewardPoints from './RewardPoints';
import { LogoutMenu } from './LogoutMenu/LogoutMenu';
import { ProfileDetailsType } from '../Sidebar/types';
import { messages } from './Header.messages';
import { SwitchGroup } from './SwitchGroup/SwitchGroup';
import RecommendationMenu from './RecommendationMenu';

export type BreadcrumbType = {
  label: string;
  url?: string;
};

type ChangeItemType = {
  value?: DashboardTypes;
};

type HeaderProps = {
  title: string;
  subtitle?: string;
  breadcrumbs?: BreadcrumbType[];
  profileDetails: ProfileDetailsType;
  showHamburger?: boolean;
  isSummerProgram?: boolean;
  isChild?: boolean;
  handleOpenSidebar?: () => void;
  userType: string;
};
const Header = ({
  title,
  subtitle,
  breadcrumbs,
  profileDetails,
  showHamburger,
  isSummerProgram,
  handleOpenSidebar,
  isChild,
  userType,
}: HeaderProps) => {
  const { formatMessage, locale } = useIntl();
  const isMobile = useScreenInclude(['xs', 'sm']);
  const { isInAppView } = useInAppView();
  const { smartSwitcherEnabled, dashboardTab, setDashboardTab } = useContext(DashboardContext);
  const history = useHistory();

  const userRole = useSelector(accountRoleSelector);
  const isAuthorOrAlwAdmin = useSelector(isAuthorOrAlwAdminSelector);
  const isStudentRole = useSelector(isStudentRoleSelector);
  const isB2BStudent = useSelector(isB2bStudentRoleSelector);
  const isB2CStudent = useSelector(isALWStudentRoleSelector);

  const match = useRouteMatch({
    path: ROUTES.root,
    strict: true,
    sensitive: true,
  });

  const isStudentDashboard = match?.isExact && isStudentRole;

  const isRtl = LOCALES[locale as Lang].direction === Direction.rtl;
  const orientationEnabledRoles = getOrientationEnabledRoles();
  const shouldOrientationVisible = orientationEnabledRoles?.some((role) => role === userType);

  const DashboardTabs = [
    {
      caption: formatMessage(messages.dashboard),
      value: DashboardTypes.NormalView,
    },
    {
      caption: formatMessage(messages.smartView),
      value: DashboardTypes.SmartView,
    },
  ];

  const onChangeValueHandler = ({
    target: { value = DashboardTypes.SmartView },
  }: ChangeEvent<ChangeItemType>): void => {
    setDashboardTab?.(value);
  };

  const PageTitle = () => {
    return (
      <>
        {smartSwitcherEnabled && isStudentDashboard ? (
          <SwitchGroup
            name="SmartViewSwitcher"
            defaultValue={dashboardTab}
            items={DashboardTabs}
            type="smart"
            onChange={onChangeValueHandler}
          />
        ) : (
          <>
            <h1 className={styles.dashboard__title}>
              {isChild && (
                <span onClick={history.goBack} className="iconBack">
                  <IconCommon className="icon-chevron-medium" />
                </span>
              )}
              {title}
            </h1>
          </>
        )}
        {subtitle && <div className={styles.breadcrumbs}>{subtitle}</div>}
      </>
    );
  };

  return (
    <div
      className={cn(styles.row, styles.headerRow, {
        [styles.summerProgramHeader]: isSummerSemester && isSummerProgram,
      })}
    >
      <div className={cn(styles['col-3'], styles['col-md-6'], styles.dashboard__column)}>
        <div
          className={cn(styles.hamburger, {
            [styles.hide]: isInAppView,
            [styles.showDesktop]: showHamburger,
          })}
          role="button"
          onClick={handleOpenSidebar}
        >
          <Icon type="menu-outline" size={isMobile ? 's24' : 's32'} />
        </div>
        {!isMobile && <PageTitle />}
      </div>
      <div
        className={cn(styles['col-9'], styles['col-md-6'], styles.dashboard__column, {
          [styles.hide]: isInAppView,
        })}
      >
        <div className={styles.topheader}>
          {shouldOrientationVisible && <OrientationDialog userType={userType} />}
          <RewardPoints />
          {isStudentRole &&
            !DISABLE_ARS &&
            (isB2BStudent || (isB2CStudent && !DISABLE_B2C_RECOMMENDATIONS)) && (
              <RecommendationMenu />
            )}
          {userRole !== Role.mentor && !isAuthorOrAlwAdmin && <Feedback />}
          {!isAuthorOrAlwAdmin && <NotificationMenu />}
          <LogoutMenu profileDetails={profileDetails} />
        </div>
      </div>
      {isMobile && (
        <div className={cn('mt-20', styles['col-12'])}>
          <PageTitle />
        </div>
      )}
      <div className={styles['col-12']}>
        {breadcrumbs && (
          <div className={styles.breadcrumbs}>
            {breadcrumbs?.map(({ label, url }, index) => (
              <Fragment key={label}>
                {index < breadcrumbs?.length - 1 ? (
                  <NavLink to={url || '#'} className={styles.breadcrumbs__path} key={label}>
                    {label}
                  </NavLink>
                ) : (
                  <span
                    className={cn(styles.breadcrumbs__path, { [styles.active]: true })}
                    key={label}
                  >
                    {getFormattedLabel(label, isArabicMode(locale))}
                  </span>
                )}
              </Fragment>
            ))}
          </div>
        )}
      </div>
      {isSummerSemester && isSummerProgram && (
        <img
          src={getPngURL(isRtl ? 'summer-program-title-arabic' : 'summer-program-title')}
          className={styles.summerProgramHeader__img}
          alt={formatMessage({
            id: 'summer.program.title',
            defaultMessage: 'Summer program',
          })}
        />
      )}
    </div>
  );
};

export default Header;
