import { GenericCallbackType } from 'utils/types';

export enum CardTypes {
  MADA = 'MADA',
  VISA = 'VISA',
  MASTER = 'MASTER',
  STC_PAY = 'STC_PAY',
  APPLEPAY = 'APPLEPAY',
  AMEX = 'AMEX',
}

export type CardBrandType = {
  id: number;
  label: CardTypes;
};

export enum SubscriptionServiceEnum {
  RECORDED_CONTENT = 'recorded_content',
  WEEKLY_REVIEW_SESSIONS = 'weekly_review_sessions',
  EXERCISES_AND_EXAMS = 'exercises_and_exams',
  QIYAS_SIMULATION = 'qiyas_simulation',
}

type ServiceType = {
  id: number;
  titleEn: string;
  titleAr: string;
  type: string;
};

export type SubscriptionServiceType = {
  id: string;
  subjectName: string;
  services: ServiceType[];
};

type Service = ServiceType & {
  name: string;
  checked?: boolean;
  isEnrolled: boolean;
  isDrafted?: boolean;
  isDisabled?: boolean;
  descriptionEn?: string;
  descriptionAr?: string;
  video?: string;
  price?: boolean;
};

export type Subjects = {
  id: string;
  services: Service[];
  name: string;
  isQiyasSubject?: boolean;
  isDisabled?: boolean;
};

export type SubscriberService = {
  service_id: number;
};

export type SubscriberSubject = {
  subject_id: string;
  services: SubscriberService[];
};

export type Subscribers = {
  user_id: number;
  subjects: SubscriberSubject[];
};

export type EnrollStudentPayloadType = {
  plan?: number;
  payment_brand_id?: number;
  total_amount: number;
  coupon_code?: string;
  voucher_code?: string;
  subscribers: Subscribers[];
  callback?: GenericCallbackType<string>;
};

export type PaymentPlans = {
  id: number;
  type: string;
  titleAr: string;
  titleEn: string;
  detailEn: string;
  detailAr: string;
  configuration: {
    liveSessionCount: number;
    subjectCount: number;
    lessonCount: number;
    examsCount: number;
    recordingCount: number;
    servicesCount: number;
    servicePrice: number[];
    maxPayableAmount: number;
  };
  paymentBrands: CardBrandType[];
  isLastSelection: boolean;
  valueAddedTax?: string;
};

export type DraftedServices = {
  isEnrolled: boolean;
  isDrafted: boolean;
  id: number;
  titleEn: string;
  titleAr: string;
  type: string;
  price?: boolean;
};

export type LoadSubjectsPayloadType = {
  planId: number;
  gradeId: number;
  userId: number;
  draftedSubjects?: Subjects[];
  draftPlan?: number;
  callback: GenericCallbackType<Subjects[]>;
};

export type SubscriptionDraftPayload = {
  shouldPaidOnly: boolean;
  shouldUpgradePlan: boolean;
  userId: number;
  isStudent: boolean;
};

export type DraftedSubscription = {
  grade: string;
  subjectsServices: [
    {
      id: string;
      subjectName: string;
      isQiyasSubject?: boolean;
      services: DraftedServices[];
    },
  ];
  plan: PaymentPlans;
  lastSelectedPlan: PaymentPlans;
  id: number;
  name: string;
  amountPaid: number;
  isVoucherActive: boolean;
  isAllowedFreePlan?: boolean;
};
