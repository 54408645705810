import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import {
  fetchStudentDashboardDataAction,
  fetchTodayScheduleAction,
  fetchOverdueLessonsAction,
  fetchUnitsBySubjectAction,
  fetchAdditionalLessonsAction,
  updateAdditionalLessonAction,
} from './actions';
import {
  AdditionalLessonsResponseType,
  OverdueLessonsResponseType,
  StudentDashboardDataResponse,
  TodayScheduleType,
  UnitsType,
  AdditionalLessonsType,
} from './types';

type StudentDashboardV2ActionType = ActionType<typeof actions>;

const dashboardData = combineReducers({
  data: createReducer<StudentDashboardDataResponse, StudentDashboardV2ActionType>(
    {} as StudentDashboardDataResponse,
  ).handleAction(fetchStudentDashboardDataAction.success, (_, { payload }) => payload),
  fetching: createReducer<boolean, StudentDashboardV2ActionType>(true)
    .handleAction(fetchStudentDashboardDataAction.request, () => true)
    .handleAction(
      [fetchStudentDashboardDataAction.success, fetchStudentDashboardDataAction.failure],
      () => false,
    ),
  errors: createReducer<string, StudentDashboardV2ActionType>('')
    .handleAction(fetchStudentDashboardDataAction.failure, (_, { payload }) => payload)
    .handleAction(
      [fetchStudentDashboardDataAction.request, fetchStudentDashboardDataAction.success],
      () => '',
    ),
});

const todaySchedule = combineReducers({
  data: createReducer<TodayScheduleType[], StudentDashboardV2ActionType>(
    [] as TodayScheduleType[],
  ).handleAction(fetchTodayScheduleAction.success, (_, { payload }) => payload),
  fetching: createReducer<boolean, StudentDashboardV2ActionType>(true)
    .handleAction(fetchTodayScheduleAction.request, () => true)
    .handleAction(
      [fetchTodayScheduleAction.success, fetchTodayScheduleAction.failure],
      () => false,
    ),
  errors: createReducer<string, StudentDashboardV2ActionType>('')
    .handleAction(fetchTodayScheduleAction.failure, (_, { payload }) => payload)
    .handleAction([fetchTodayScheduleAction.request, fetchTodayScheduleAction.success], () => ''),
});

const overdueLessons = combineReducers({
  data: createReducer<OverdueLessonsResponseType, StudentDashboardV2ActionType>(
    {} as OverdueLessonsResponseType,
  ).handleAction(fetchOverdueLessonsAction.success, (_, { payload }) => payload),
  fetching: createReducer<boolean, StudentDashboardV2ActionType>(true)
    .handleAction(fetchOverdueLessonsAction.request, () => true)
    .handleAction(
      [fetchOverdueLessonsAction.success, fetchOverdueLessonsAction.failure],
      () => false,
    ),
  errors: createReducer<string, StudentDashboardV2ActionType>('')
    .handleAction(fetchOverdueLessonsAction.failure, (_, { payload }) => payload)
    .handleAction([fetchOverdueLessonsAction.request, fetchOverdueLessonsAction.success], () => ''),
});

const unitsBySubject = combineReducers({
  data: createReducer<UnitsType[], StudentDashboardV2ActionType>([]).handleAction(
    fetchUnitsBySubjectAction.success,
    (_, { payload }) => payload,
  ),
  fetching: createReducer<boolean, StudentDashboardV2ActionType>(true)
    .handleAction(fetchUnitsBySubjectAction.request, () => true)
    .handleAction(
      [fetchUnitsBySubjectAction.success, fetchUnitsBySubjectAction.failure],
      () => false,
    ),
  errors: createReducer<string, StudentDashboardV2ActionType>('')
    .handleAction(fetchUnitsBySubjectAction.failure, (_, { payload }) => payload)
    .handleAction([fetchUnitsBySubjectAction.request, fetchUnitsBySubjectAction.success], () => ''),
});

const additionalLessons = combineReducers({
  data: createReducer<AdditionalLessonsResponseType, StudentDashboardV2ActionType>({
    additionalContentInfo: [],
    liveClassRecordingsInfo: [],
    fileLessonsInfo: [],
  })
    .handleAction(fetchAdditionalLessonsAction.success, (_, { payload }) => payload)
    .handleAction(updateAdditionalLessonAction, (state, { payload: { id, lessonType } }) => {
      // Lesson will be removed as API will re-fetch data
      let { fileLessonsInfo, liveClassRecordingsInfo } = state;

      if (lessonType === AdditionalLessonsType.FileLesson) {
        fileLessonsInfo = fileLessonsInfo.map((file) =>
          file.id === id ? { ...file, isCompleted: true } : file,
        );
      } else if (lessonType === AdditionalLessonsType.LiveClass) {
        liveClassRecordingsInfo = liveClassRecordingsInfo.map((recording) =>
          recording.id === id ? { ...recording, isCompleted: true } : recording,
        );
      }

      return { ...state, fileLessonsInfo, liveClassRecordingsInfo };
    }),
  fetching: createReducer<boolean, StudentDashboardV2ActionType>(true)
    .handleAction(fetchAdditionalLessonsAction.request, () => true)
    .handleAction(
      [fetchAdditionalLessonsAction.success, fetchAdditionalLessonsAction.failure],
      () => false,
    ),
  errors: createReducer<string, StudentDashboardV2ActionType>('')
    .handleAction(fetchAdditionalLessonsAction.failure, (_, { payload }) => payload)
    .handleAction(
      [fetchAdditionalLessonsAction.request, fetchAdditionalLessonsAction.success],
      () => '',
    ),
});

const studentDashboardV2Reducer = combineReducers({
  dashboardData,
  todaySchedule,
  overdueLessons,
  unitsBySubject,
  additionalLessons,
});

export type studentDashboardV2StateType = StateType<typeof studentDashboardV2Reducer>;

export default studentDashboardV2Reducer;
