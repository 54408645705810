import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  loadStudentMaterialAction,
  downloadStudentMaterialAction,
  uploadPreparationMaterialAction,
  updatePreparationMaterialAction,
  loadTeacherMaterialAction,
  deletePreparationMaterialAction,
} from './actions';
import {
  getStudentMaterialApi,
  getTeacherMaterialApi,
  downloadStudentMaterialApi,
  updatePreparationMaterialApi,
  uploadPreparationMaterialApi,
  deletePreparationMaterialApi,
} from './api';

function* loadStudentMaterialRequest(): SagaIterator<void> {
  try {
    const response = yield call(getStudentMaterialApi);
    yield put(loadStudentMaterialAction.success(response));
  } catch (error) {
    yield put(loadStudentMaterialAction.failure(error));
  }
}

function* loadTeacherMaterialRequest(): SagaIterator<void> {
  try {
    const response = yield call(getTeacherMaterialApi);
    yield put(loadTeacherMaterialAction.success(response));
  } catch (error) {
    yield put(loadTeacherMaterialAction.failure(error));
  }
}

function* downloadStudentMaterialRequest({
  payload,
}: ReturnType<typeof downloadStudentMaterialAction.request>): SagaIterator<void> {
  try {
    const response = yield call(downloadStudentMaterialApi, payload.materialId);
    yield put(downloadStudentMaterialAction.success(response));
  } catch (error) {
    yield put(downloadStudentMaterialAction.failure(error));
  }
}

function* uploadPreparationMaterialRequest({
  payload,
}: ReturnType<typeof uploadPreparationMaterialAction.request>): SagaIterator<void> {
  try {
    const {
      formData: { title, type, subType, totalQuestions, file },
      teacherId,
    } = payload;
    const data = new FormData();
    data.append('title', title);
    data.append('type', type);
    data.append('sub_type', subType);
    data.append('total_questions', totalQuestions);
    file && data.append('file', file);
    data.append('teacher_id', teacherId);

    const response = yield call(uploadPreparationMaterialApi, data);
    yield put(uploadPreparationMaterialAction.success(response));
    payload.callBack();
  } catch (error) {
    payload.callBack(error);
    yield put(uploadPreparationMaterialAction.failure(error));
  }
}

function* updatePreparationMaterialRequest({
  payload,
}: ReturnType<typeof updatePreparationMaterialAction.request>): SagaIterator<void> {
  try {
    const {
      formData: { title, type, subType, totalQuestions, file },
      teacherId,
      materialId,
    } = payload;

    const data = new FormData();
    data.append('title', title);
    data.append('type', type);
    data.append('sub_type', subType);
    data.append('total_questions', totalQuestions);
    file && data.append('file', file);
    data.append('teacher_id', teacherId);

    const response = yield call(updatePreparationMaterialApi, {
      data,
      materialId,
    });
    yield put(updatePreparationMaterialAction.success(response));
    payload.callBack();
  } catch (error) {
    payload.callBack(error);
    yield put(updatePreparationMaterialAction.failure(error));
  }
}

function* deleteMaterialRequest({
  payload,
}: ReturnType<typeof deletePreparationMaterialAction.request>): SagaIterator<void> {
  try {
    const response = yield call(deletePreparationMaterialApi, payload);
    yield put(deletePreparationMaterialAction.success(response));
    payload.callBack();
  } catch (error) {
    payload.callBack(error);
    yield put(deletePreparationMaterialAction.failure(error));
  }
}

function* preparationMaterialSaga(): SagaIterator {
  yield all([takeEvery(loadStudentMaterialAction.request, loadStudentMaterialRequest)]);
  yield all([takeEvery(downloadStudentMaterialAction.request, downloadStudentMaterialRequest)]);
  yield all([takeEvery(uploadPreparationMaterialAction.request, uploadPreparationMaterialRequest)]);
  yield all([takeEvery(updatePreparationMaterialAction.request, updatePreparationMaterialRequest)]);
  yield all([takeEvery(loadTeacherMaterialAction.request, loadTeacherMaterialRequest)]);
  yield all([takeEvery(deletePreparationMaterialAction.request, deleteMaterialRequest)]);
}

export default preparationMaterialSaga;
