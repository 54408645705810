import { createAction, createAsyncAction } from 'typesafe-actions';
import { LessonWeekType, ExercisesListType, ExamsListType } from './types';

export const loadLessonsWeekScheduleAction = createAsyncAction(
  '@@lessonsWeekSchedule/LOAD_REQUEST',
  '@@lessonsWeekSchedule/LOAD_SUCCESS',
  '@@lessonsWeekSchedule/LOAD_FAILURE',
)<undefined | string, undefined, string>();

export const loadExercisesAction = createAsyncAction(
  '@@loadExercises/LOAD_REQUEST',
  '@@loadExercises/LOAD_SUCCESS',
  '@@loadExercises/LOAD_FAILURE',
)<undefined, ExercisesListType[], string>();

export const loadExamsAction = createAsyncAction(
  '@@loadExams/LOAD_REQUEST',
  '@@loadExams/LOAD_SUCCESS',
  '@@loadExams/LOAD_FAILURE',
)<undefined, ExamsListType, string>();

export const loadAdditionalContentLSuccessAction = createAction(
  '@@lessonsWeekSchedule/LOAD_ADDITIONAL_CONTENT_SUCCESS',
)<LessonWeekType[]>();

export const updateAdditionalContentAction = createAction(
  '@@lessonsWeekSchedule/UPDATE_ADDITIONAL_CONTENT_SUCCESS',
)<string | number>();

export const resetLessonsWeekScheduleAction = createAction('@@lessonsWeekSchedule/RESET')();
