import { createAsyncAction, createAction } from 'typesafe-actions';
import { ProgressCourseType, CourseTilesType } from './types';

export const loadCourseTilesProgressAction = createAsyncAction(
  '@@courseTilesProgress/LOAD_REQUEST',
  '@@courseTilesProgress/LOAD_SUCCESS',
  '@@courseTilesProgress/LOAD_FAILURE',
)<string, Partial<CourseTilesType>, string>();

export const loadCourseProgressAction = createAsyncAction(
  '@@courseProgress/LOAD_REQUEST',
  '@@courseProgress/LOAD_SUCCESS',
  '@@courseProgress/LOAD_FAILURE',
)<string, ProgressCourseType, string>();

export const loadCompletionRateBySubjectAction = createAsyncAction(
  '@@completionRateBySubject/LOAD_REQUEST',
  '@@completionRateBySubject/LOAD_SUCCESS',
  '@@completionRateBySubject/LOAD_FAILURE',
)<string, Partial<CourseTilesType>, string>();

export const resetProgressAction = createAction('@@progress/RESET')();
