import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  TeacherExams,
  ExamsListParamsType,
  ExamDetailType,
  createExamQuestionPayload,
  StudentExamListType,
  StudentExamListParamType,
  fetchLessonOptionPayload,
  createExamPayloadType,
  ClassesList,
  ExamsSubjects,
  PublishExamPayload,
  PublishExamResponse,
  LearningObjectivesPayload,
  LearningObjectivesResponse,
  ExamResultResponse,
  ExamResultPayload,
  StudentExamPayloadType,
  StudentExamType,
  SubmitExamParamType,
  ExamResultType,
  StudentProblemListType,
  ProblemType,
  StudentProblemType,
} from './types';

export const fetchTeacherExamsAction = createAsyncAction(
  '@@exams/FETCH_TEACHER_EXAMS_REQUEST',
  '@@exams/FETCH_TEACHER_EXAMS_SUCCESS',
  '@@exams/FETCH_TEACHER_EXAMS_FAILURE',
)<undefined | ExamsListParamsType, TeacherExams, string>();

export const fetchTeacherExamDetailAction = createAsyncAction(
  '@@exams/FETCH_TEACHER_EXAM_DETAIL_REQUEST',
  '@@exams/FETCH_TEACHER_EXAM_DETAIL_SUCCESS',
  '@@exams/FETCH_TEACHER_EXAM_DETAIL_FAILURE',
)<number, ExamDetailType, string>();

export const createExamQuestionAction = createAsyncAction(
  '@@exams/CREATE_NEW_QUESTION_REQUEST',
  '@@exams/CREATE_NEW_QUESTION_SUCCESS',
  '@@exams/CREATE_NEW_QUESTION_FAILURE',
)<createExamQuestionPayload, any, string>();

export const updateExamQuestionAction = createAsyncAction(
  '@@exams/UPDATE_NEW_QUESTION_REQUEST',
  '@@exams/UPDATE_NEW_QUESTION_SUCCESS',
  '@@exams/UPDATE_NEW_QUESTION_FAILURE',
)<createExamQuestionPayload, undefined, string>();

export const createNewExamAction = createAsyncAction(
  '@@exams/CREATE_NEW_EXAM_REQUEST',
  '@@exams/CREATE_NEW_EXAM_SUCCESS',
  '@@exams/CREATE_NEW_EXAM_FAILURE',
)<createExamPayloadType, any, string>();

export const fetchLessonOptionsAction = createAsyncAction(
  '@@exams/FETCH_LESSONS_REQUEST',
  '@@exams/FETCH_LESSONS_SUCCESS',
  '@@exams/FETCH_LESSONS_FAILURE',
)<fetchLessonOptionPayload, any, string>();

export const fetchStudentExamsListAction = createAsyncAction(
  '@@exams/FETCH_STUDENT_EXAM_LIST_REQUEST',
  '@@exams/FETCH_STUDENT_EXAM_LIST_SUCCESS',
  '@@exams/FETCH_STUDENT_EXAM_LIST_FAILURE',
)<StudentExamListParamType, StudentExamListType, string>();

export const fetchExamsClassesListAction = createAsyncAction(
  '@@exams/FETCH_CLASSES_LIST_REQUEST',
  '@@exams/FETCH_CLASSES_LIST_SUCCESS',
  '@@exams/FETCH_CLASSES_LIST_FAILURE',
)<undefined, Array<ClassesList>, string>();

export const fetchExamsSubjects = createAsyncAction(
  '@@exams/FETCH_EXAMS_REQUEST',
  '@@exams/FETCH_EXAMS_SUCCESS',
  '@@exams/FETCH_EXAMS_FAILURE',
)<string, Array<ExamsSubjects>, string>();

export const publishExamAction = createAsyncAction(
  '@@exams/PUBLISH_EXAMS_REQUEST',
  '@@exams/PUBLISH_EXAMS_SUCCESS',
  '@@exams/PUBLISH_EXAMS_FAILURE',
)<PublishExamPayload, PublishExamResponse, string>();

export const examsLearningObjectivesAction = createAsyncAction(
  '@@exams/EXAMS_LEARNING_OBJECTIVES_REQUEST',
  '@@exams/EXAMS_LEARNING_OBJECTIVES_SUCCESS',
  '@@exams/EXAMS_LEARNING_OBJECTIVES_FAILURE',
)<LearningObjectivesPayload, Array<LearningObjectivesResponse>, string>();

export const fetchStudentResultsAction = createAsyncAction(
  '@@exams/FETCH_STUDENT_RESULTS_REQUEST',
  '@@exams/FETCH_STUDENT_RESULTS_SUCCESS',
  '@@exams/FETCH_STUDENT_RESULTS_FAILURE',
)<ExamResultPayload, ExamResultResponse, string>();

export const resetExamQuestionAction = createAction('@@exams/RESET_CREATE_NEW_QUESTION_REQUEST')();

export const resetExamDetailsDataAction = createAction('@@exams/RESET_EXAMS_DETAIL_REQUEST')();

export const ExitStudentExamAction = createAsyncAction(
  '@@exams/FETCH_STUDENT_EXAM_EXIT_REQUEST',
  '@@exams/FETCH_STUDENT_EXAM_EXIT_SUCCESS',
  '@@exams/FETCH_STUDENT_EXAM_EXIT_FAILURE',
)<SubmitExamParamType, ExamResultType, string>();

export const fetchStudentCurrentExamAction = createAsyncAction(
  '@@exams/FETCH_STUDENT_CURRENT_EXAM_REQUEST',
  '@@exams/FETCH_STUDENT_CURRENT__EXAM_SUCCESS',
  '@@exams/FETCH_STUDENT_CURRENT_EXAM_FAILURE',
)<ExamResultPayload, StudentExamType, string>();

export const fetchStudentExamAction = createAsyncAction(
  '@@exams/FETCH_STUDENT_EXAM_REQUEST',
  '@@exams/FETCH_STUDENT_EXAM_SUCCESS',
  '@@exams/FETCH_STUDENT_EXAM_FAILURE',
)<StudentExamPayloadType, StudentProblemListType, string>();

export const resetStudentExamAction = createAction('@@exams/RESET_STUDENT_EXAM')();

export const fetchTeacherExamProblemsAction = createAsyncAction(
  '@@exams/FETCH_TEACHER_EXAM_PROBLEMS_REQUEST',
  '@@exams/FETCH_TEACHER_EXAM_PROBLEMS_SUCCESS',
  '@@exams/FETCH_TEACHER_EXAM_PROBLEMS_FAILURE',
)<ExamResultPayload, Array<string>, string>();

export const fetchExamProblemAction = createAsyncAction(
  '@@exams/FETCH_EXAM_PROBLEM_REQUEST',
  '@@exams/FETCH_EXAM_PROBLEM_SUCCESS',
  '@@exams/FETCH_EXAM_PROBLEM_FAILURE',
)<string, StudentProblemType, string>();

export const removeExamProblemAction = createAsyncAction(
  '@@exams/REMOVE_EXAM_PROBLEM_REQUEST',
  '@@exams/REMOVE_EXAM_PROBLEM_SUCCESS',
  '@@exams/REMOVE_EXAM_PROBLEM_FAILURE',
)<ProblemType, ProblemType, string>();

export const completeExamStatusAction = createAction(
  '@@exams/CHANGE_STATUS_TO_EXAM_COMPLETED',
)<number>();
export const teacherAddNewQuestionAction = createAction('@@exams/ADD_NEW_QUESTION_EXAMS')<string>();
export const resetTeacherExamsAction = createAction('@@exams/RESET_TEACHER_EXAMS')();
