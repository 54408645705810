import React, { ReactNode } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import cn from 'classnames';

import { useTitle } from 'hooks/useTitle';
import AccountLang from 'components/LayoutBase/components/Header/AccountLang';
import styles from './LayoutForm.module.scss';
import Body from './components/Body';
import Header from './components/Header';
import Footer from './components/Footer';
import PortalCard from './components/PortalCard';
import RegistrationHelp from './components/RegistrationHelp';

export type PropsType = {
  title: string;
  children: ReactNode;
  isLoading?: boolean;
  isContainerBig?: boolean;
  switcherPosition?: 'right' | 'left';
  shouldRedirectToDashboard?: boolean;
};

const LayoutForm = ({
  title = '',
  children,
  isLoading = false,
  isContainerBig = false,
  switcherPosition = 'right',
  shouldRedirectToDashboard = false,
}: PropsType) => {
  const match = useRouteMatch(ROUTES.registration);
  const style = isContainerBig ? styles.containerBig : styles.container;
  useTitle(title, isLoading);

  return (
    <Body>
      <Header shouldRedirectToDashboard={shouldRedirectToDashboard} />
      <div className={style}>
        <PortalCard isLoading={isLoading}>{children}</PortalCard>
        {match?.isExact && <RegistrationHelp />}
      </div>
      <Footer />
      <div className={cn(styles.floatingLang, styles[switcherPosition])}>
        <AccountLang type="dark" useShortName />
      </div>
    </Body>
  );
};

export default LayoutForm;
