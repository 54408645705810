import { gradeTypeEnum } from 'components/TourManagement/constants';
import { AdminRole, Role, SchoolRole } from '../store/account/types';

export const getUserType = (role: Role, schoolRole: SchoolRole, adminRole?: AdminRole): string => {
  let userType;
  if (typeof role === 'number' && !schoolRole) {
    const roleData = Object.entries(Role).find(([, value]) => value === role);
    userType = roleData?.[1] === Role.alwTeacher ? 'alw_teacher' : roleData?.[0];
  }
  if (typeof schoolRole === 'number' && !role) {
    const users = {
      [SchoolRole.schoolStudent]: 'student',
      [SchoolRole.schoolTeacher]: 'teacher',
      [SchoolRole.schoolBranchManager]: 'branch_manager',
      [SchoolRole.schoolAdmin]: 'school_admin',
    };
    userType = users[schoolRole];
  }
  if (adminRole != null) {
    userType = 'alwAdmin';
  }

  return userType || '';
};

export const getGradeType = (gradeValue: number): string =>
  gradeValue > 3 ? gradeTypeEnum.UPPER_GRADES : gradeTypeEnum.lOWER_GRADES;
