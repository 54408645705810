import React, { ReactElement, useState, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { SchoolBrancheType } from 'store/shoolBranches/types';
import { loadSchoolBranches, selectBranch } from 'store/shoolBranches/actions';
import { selectSchoolBranches } from 'store/shoolBranches/selectors';

import { useFormat } from 'hooks/useFormat';
import { useBranch } from 'hooks/useBranch';
import { useCustomScreenClass } from 'hooks/useCustomScreenClass';

import { isSubscriptionExpired } from 'components/SchoolBranches/util';
import { ROUTES } from 'constants/routes';
import { Direction, Lang, LOCALES } from 'lang';
import { Popover } from 'lib';
import { NavigationProps } from './types';
import styles from './Sidebar.module.scss';

export const Navigation = ({ isHamburger, onBranchSwitch }: NavigationProps) => {
  const { locale } = useIntl();
  const history = useHistory();
  const branchId = useBranch();

  const dispatch = useDispatch();
  const screen = useCustomScreenClass();

  const [visiblePopover, setVisibilityPopover] = useState(false);

  const schoolInfo = useSelector(selectSchoolBranches);
  const {
    selectedBranch,
    data: { branches },
  } = schoolInfo;
  const isGradeReportPage = useRouteMatch(ROUTES.gradeReport);
  const isGradeMappingPage = useRouteMatch(ROUTES.gradeMapping);

  const isdesktop = ['sm', 'md', 'lg', 'xl'].includes(screen);
  const isRtlMode = LOCALES[locale as Lang].direction === Direction.rtl;
  const isArabicMode: boolean = locale === Lang.ar;

  const onBranchChange = (branch: SchoolBrancheType): void => {
    if (!branch.allowB2BGrading && (isGradeMappingPage || isGradeReportPage)) {
      history.push(ROUTES.root);
    }

    const { branchId } = branch;
    dispatch(selectBranch(branch));
    localStorage.setItem('schoolBranchId', branchId.toString());
    onBranchSwitch && onBranchSwitch();
  };

  const popoverPosition = (): any => {
    if (isHamburger) return 'auto';
    if (!isdesktop && isRtlMode) return 'left-start';
    if (!isdesktop && !isRtlMode) return 'right-start';
    return 'bottom';
  };

  if (!branches.length && !schoolInfo.isLoading) {
    history.push(ROUTES.selectBranch);
  }

  useEffect(() => {
    if (!branches.length) {
      dispatch(loadSchoolBranches.request());
    } else if (!Object.keys(selectedBranch).length) {
      const branch = branches.find((branch) => +branch.branchId === branchId);

      dispatch(selectBranch(branch as SchoolBrancheType));
    }
  }, [dispatch, branches, selectedBranch, branchId]);

  return (
    <div
      className={cn(styles.navigation, styles.b2b_navigation, {
        [styles.hamburgerOpen]: isHamburger,
      })}
    >
      <Popover
        placement={popoverPosition()}
        type="modal"
        className={styles.submenu__popover}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 12],
            },
          },
        ]}
        isVisible={visiblePopover}
        setVisibility={setVisibilityPopover}
        popover={(): ReactElement => (
          <div className={styles.submenu__detail}>
            <div className={styles.submenu__branches}>
              {branches.map((branch) => {
                const {
                  subscription: { endDate },
                } = branch;
                const isExpired = isSubscriptionExpired(endDate);
                return (
                  <div
                    onClick={(): any => !isExpired && onBranchChange(branch)}
                    key={branch.branchId}
                    className={cn(styles.submenu__address, {
                      [styles.active]: branchId === +branch.branchId,
                      [styles.disabled]: isExpired,
                    })}
                  >
                    <div className={styles.submenu__schoolname}>
                      {isArabicMode ? branch?.nameAr : branch?.nameEn}
                    </div>
                    <div className={styles.submenu__schooladdress}>
                      {isArabicMode ? branch?.addressAr : branch?.addressEn}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      >
        <div className={cn(styles.navigation__item, { [styles.submenu]: true })}>
          <i className={cn(styles['icon-branch'], styles.navigation__icon)} />
          <div className={styles.navigation__title}>
            {useFormat('school.menu.switch', 'Switch Branch')}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default Navigation;
