import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  addNewAssignments,
  teacherAssignmentGroupsApi,
  fetchStudentAssignments,
  fetchStudentSubjects,
  fetchTeacherAssignmentDetail,
  fetchTeacherAssignments,
  submitStudentAssignmentApi,
  updateStudentAssignmentScoreApi,
  teacherAssignmentResubmissionApi,
} from 'utils/apiEndpoints';
import { formatDate } from 'utils/date-time';
import {
  assignmentDetailRequestType,
  assignmentDetailsType,
  AssignmentFormType,
  assignmentGroupsType,
  assignmentGroupType,
  assignmentResubmissionRequestPayload,
  ClassAssignmentsType,
  createGroupsRequestPayload,
  loadAssignmentsRequestType,
  deleteGroupRequestPayload,
  groupsRequestType,
  renameGroupRequestPayload,
  studentAssignmentType,
  studentSubjectType,
  submitAssignmentRequestType,
  updateDeadlineRequestPayload,
  updateStudentAssignmentScoreRequestType,
  updateStudentGroupRequestPayload,
  CreateAssignmentResponseType,
  loadStudentSubjectPayload,
} from './types';

export async function getTeacherAssignments(teacherId: number): Promise<ClassAssignmentsType> {
  const response = await API.get(fetchTeacherAssignments(teacherId));

  return Object.entries(response.data || {}).reduce(
    (acc, [key, value]: [string, any]) => ({ ...acc, [key]: camelcaseKeys(value, { deep: true }) }),
    {},
  );
}

export async function getTeacherAssignmentDetail({
  teacherId,
  assignmentId,
}: assignmentDetailRequestType): Promise<assignmentDetailsType> {
  const response = await API.get(fetchTeacherAssignmentDetail(teacherId, assignmentId));

  return camelcaseKeys(response.data, { deep: true });
}

export async function createAssignment({
  messageTitle = '',
  messageDetails = '',
  classroom,
  subject,
  isScorable,
  totalScore,
  deadline,
  file,
  groups,
}: AssignmentFormType): Promise<CreateAssignmentResponseType> {
  const formData = new FormData();
  formData.append('role', 'teacher');
  formData.append('message_title', messageTitle);
  formData.append('message_details', messageDetails);
  formData.append('classroom', classroom?.id.toString() || '');
  formData.append('subject', subject?.id.toString() || '');
  formData.append('is_scorable', isScorable ? 'True' : 'False');
  formData.append('is_attachable', 'True');
  formData.append('deadline', formatDate(deadline, "yyyy-MM-dd'T'HH:mm:ss"));
  isScorable && totalScore && formData.append('total_score', totalScore.toString());
  file && formData.append('file', file);
  groups &&
    groups.forEach((groupId) => {
      formData.append('groups', groupId);
    });
  const response = await API.post(addNewAssignments(), formData);

  return camelcaseKeys(response.data, { deep: true });
}

export async function updateStudentAssignmentScore({
  teacherId,
  studentAssignmentId,
  score,
}: updateStudentAssignmentScoreRequestType): Promise<studentAssignmentType> {
  const formData = new FormData();
  formData.append('score', score.toString());
  const response = await API.patch(
    updateStudentAssignmentScoreApi(teacherId, studentAssignmentId),
    formData,
  );

  return camelcaseKeys(response.data, { deep: true });
}

export async function getStudentSubjects({
  childUsername,
}: loadStudentSubjectPayload): Promise<Array<studentSubjectType>> {
  const response = await API.get(fetchStudentSubjects(), { params: { username: childUsername } });

  return camelcaseKeys(response.data, { deep: true });
}

export async function getStudentAssignments({
  subjectId,
  isGroupAssignments,
  pageSize,
  page,
  completionStatus,
}: loadAssignmentsRequestType): Promise<Array<any>> {
  const params: {
    page: number;
    page_size: number;
    is_group_assignments?: boolean;
    status?: string;
  } = {
    page,
    page_size: pageSize,
    status: completionStatus !== '' ? completionStatus : undefined,
  };
  if (isGroupAssignments) params.is_group_assignments = isGroupAssignments;

  const response = await API.get(fetchStudentAssignments(subjectId), {
    params,
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function submitStudentAssignment({
  studentAssignmentId,
  file,
}: submitAssignmentRequestType): Promise<studentAssignmentType> {
  const formData = new FormData();
  file && formData.append('file', file);
  const response = await API.put(submitStudentAssignmentApi(studentAssignmentId), formData);

  return camelcaseKeys(response.data, { deep: true });
}

export async function getAssignmentGroups({
  classId,
  subjectId,
}: groupsRequestType): Promise<assignmentGroupsType> {
  const response = await API.get(teacherAssignmentGroupsApi(classId, subjectId));

  return camelcaseKeys(response.data, { deep: true });
}

export async function createAssignmentGroups({
  classId,
  subjectId,
  bodyPayload,
}: Omit<createGroupsRequestPayload, 'unassignedStudents'>): Promise<
  Omit<assignmentGroupsType, 'unassignedStudents'>
> {
  const response = await API.post(
    teacherAssignmentGroupsApi(classId, subjectId),
    snakeCaseKeys(bodyPayload, { deep: true }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateStudentAssignmentGroup({
  classId,
  subjectId,
  bodyPayload,
}: Omit<updateStudentGroupRequestPayload, 'unassignedStudents' | 'groups'>): Promise<{
  msg: string;
}> {
  const response = await API.patch(
    teacherAssignmentGroupsApi(classId, subjectId),
    snakeCaseKeys(bodyPayload, { deep: true }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function renameAssignmentGroup({
  classId,
  subjectId,
  bodyPayload,
}: renameGroupRequestPayload): Promise<assignmentGroupType> {
  const response = await API.patch(
    teacherAssignmentGroupsApi(classId, subjectId),
    snakeCaseKeys(bodyPayload, { deep: true }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function deleteAssignmentGroup({
  classId,
  subjectId,
  bodyPayload,
}: deleteGroupRequestPayload): Promise<{
  msg: string;
}> {
  const response = await API.delete(teacherAssignmentGroupsApi(classId, subjectId), {
    data: snakeCaseKeys(bodyPayload, { deep: true }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateAssignmentDeadline({
  teacherId,
  assignmentId,
  deadline,
}: updateDeadlineRequestPayload): Promise<{
  deadline: string;
}> {
  const response = await API.patch(fetchTeacherAssignmentDetail(teacherId, assignmentId), {
    deadline: formatDate(deadline, "yyyy-MM-dd'T'HH:mm:ss"),
  });
  return response.data;
}

export async function assignmentResubmission({
  groupId,
  assignmentId,
}: assignmentResubmissionRequestPayload): Promise<unknown> {
  const response = await API.post(teacherAssignmentResubmissionApi(assignmentId, groupId));
  return response.data;
}
