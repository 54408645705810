import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  createExamQuestionAPI,
  createNewExamAPI,
  getTeacherExamDetailAPI,
  getTeacherExamsAPI,
  getStudentExamsListAPI,
  getExamsLessonsAPI,
  getExamsClassesAPI,
  getExamsSubjectsAPI,
  publishExamAPI,
  fetchLearningObjectivesAPI,
  fetchStudentResultsAPI,
  getStudentExamApi,
  exitStudentExamAPI,
  studentCurrentExamAPI,
  teacherExamProblemsAPI,
  examProblemAPI,
} from 'utils/apiEndpoints';

import {
  ExamDetailType,
  StudentExamListType,
  TeacherExams,
  createExamQuestionPayload,
  createExamPayloadType,
  ExamType,
  fetchLessonOptionPayload,
  ClassesList,
  ExamsSubjects,
  PublishExamPayload,
  LearningObjectivesPayload,
  ExamResultResponse,
  ExamResultPayload,
  LearningObjectivesResponse,
  SubmitExamParamType,
  StudentExamType,
  ExamResultType,
  StudentProblemListType,
} from './types';

export async function getTeacherExams(
  pageSize?: string,
  pageIndex?: number,
): Promise<TeacherExams> {
  const response = await API.get(getTeacherExamsAPI(), {
    params: {
      page: pageIndex,
      page_size: pageSize,
    },
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeacherExamDetail(examId: number): Promise<ExamDetailType> {
  const response = await API.get(getTeacherExamDetailAPI(examId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getExamLessons(payload: fetchLessonOptionPayload): Promise<ExamDetailType> {
  const response = await API.post(getExamsLessonsAPI(payload?.subjectKey), {
    units: payload?.units,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function createExamQuestion({
  examId,
  ...payload
}: createExamQuestionPayload): Promise<ExamDetailType> {
  const response = await API.post(createExamQuestionAPI(examId), payload);
  return camelcaseKeys(response.data, { deep: true });
}

export async function createNewExam({ ...payload }: createExamPayloadType): Promise<ExamType> {
  const response = await API.post(
    createNewExamAPI(),
    snakeCaseKeys(payload.createExamData, { deep: true }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function getStudentExamList(isCompleted: boolean): Promise<StudentExamListType> {
  const response = await API.get(getStudentExamsListAPI(), {
    params: {
      is_completed: isCompleted,
    },
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function getExamsClasses(): Promise<Array<ClassesList>> {
  const response = await API.get(getExamsClassesAPI());
  return camelcaseKeys(response.data, { deep: true });
}

export async function getExamsSubjects(gradeId: string): Promise<Array<ExamsSubjects>> {
  const response = await API.get(getExamsSubjectsAPI(gradeId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function publishExam({
  examId,
  status,
}: PublishExamPayload): Promise<Array<ExamsSubjects>> {
  const response = await API.put(publishExamAPI(examId), { status });
  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchLearningObjectives(
  payload: LearningObjectivesPayload,
): Promise<LearningObjectivesResponse> {
  const response = await API.post(fetchLearningObjectivesAPI(), payload);
  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchStudentResults({
  examId,
  pageIndex,
  pageSize,
}: ExamResultPayload): Promise<ExamResultResponse> {
  const response = await API.get(fetchStudentResultsAPI(examId), {
    params: {
      page: pageIndex,
      page_size: pageSize,
    },
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getStudentExam(examId: number): Promise<StudentProblemListType> {
  const response = await API.get(getStudentExamApi(examId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function ExitStudentExam({
  examId,
  totalAttemptTime,
}: SubmitExamParamType): Promise<ExamResultType> {
  const response = await API.post(
    exitStudentExamAPI(),
    snakeCaseKeys({ exam: examId, totalAttemptTime }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function getCurrentStudentExam({
  examId,
}: ExamResultPayload): Promise<StudentExamType> {
  const response = await API.get(studentCurrentExamAPI(examId));

  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeacherExamProblems({
  examId,
}: ExamResultPayload): Promise<StudentExamType> {
  const response = await API.get(teacherExamProblemsAPI(examId));

  return camelcaseKeys(response.data, { deep: true });
}

export async function removeTeacherExamProblem(problemKey: string): Promise<StudentExamType> {
  const response = await API.delete(examProblemAPI(problemKey));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getExamProblem(problemKey: string): Promise<StudentExamType> {
  const response = await API.get(examProblemAPI(problemKey));
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateTeacherExamQuestion(
  body: createExamQuestionPayload,
): Promise<StudentExamType> {
  const response = await API.put(examProblemAPI(body?.problemKey || ''), { ...body });
  return camelcaseKeys(response.data, { deep: true });
}
