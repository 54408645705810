import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';

import * as actions from './actions';
import {
  loadTeacherAvailability,
  loadTimeTableData,
  setActiveTab,
  setSelectedEvent,
  setCloseSlotModal,
  setSelectedSlot,
  setEditSlot,
  currentSemesterWeeks,
  createTimeTableSlot,
  updateTimeTableSlot,
  resetTeacherDetail,
  resetTimetableData,
  resetCurrentSemesterWeeks,
  enableResetButton,
  loadPublishStatus,
} from './actions';
import {
  TimeTableDataType,
  GradeWiseScheduleStateType,
  WeeksList,
  ClassroomTeacher,
} from './types';
import { updateSlotStatusAdapter } from './adapter';

export type TimetableType = ActionType<typeof actions>;

export const initialGradeWiseScheduleState: GradeWiseScheduleStateType = {
  activeTab: 0,
  selectedEvent: null,
  showSlotModal: false,
  selectedSlot: null,
  editEvent: null,
};

const timeTableInitialState = {
  classroomSubjects: [],
  classroomTeachers: [],
  publishStatus: false,
  teacher: {},
  classroomId: -1,
} as TimeTableDataType;

export const GradeWiseScheduleStateReducer = createReducer<
  GradeWiseScheduleStateType,
  TimetableType
>(initialGradeWiseScheduleState)
  .handleAction(setActiveTab, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(setSelectedEvent, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(setCloseSlotModal, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(setSelectedSlot, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(setEditSlot, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

const teacherLoading = createReducer<boolean, TimetableType>(false)
  .handleAction([loadTeacherAvailability.request], () => true)
  .handleAction([loadTeacherAvailability.success, loadTeacherAvailability.failure], () => false);

const timeTableData = createReducer<TimeTableDataType, TimetableType>(timeTableInitialState)
  .handleAction(loadTimeTableData.success, (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  })
  .handleAction(enableResetButton, (state) => {
    return {
      ...state,
      publishStatus: true,
    };
  })
  .handleAction(
    [loadTeacherAvailability.success, loadTeacherAvailability.failure],
    (state, { payload }) => {
      return {
        ...state,
        teacher: { ...payload },
      };
    },
  )
  .handleAction(loadPublishStatus.success, (state, { payload }) => {
    return {
      ...state,
      publishStatus: payload,
    };
  })
  .handleAction(loadTeacherAvailability.request, (state) => {
    return {
      ...state,
      teacher: {},
    };
  })
  .handleAction(resetTeacherDetail, (state) => {
    return {
      ...state,
      teacher: {},
    };
  })
  .handleAction(resetTimetableData, () => {
    return timeTableInitialState;
  })
  .handleAction(updateTimeTableSlot.success, (state, { payload }) => {
    return {
      ...state,
      classroomTeachers: updateSlotStatusAdapter(state.classroomTeachers, payload),
    };
  })
  .handleAction(actions.deleteSlot, (state, { payload }) => {
    const newTeachers = [...state.classroomTeachers];
    const currentTeacher =
      newTeachers.find((t) => t.teacherId === payload.teacherId) || ({} as ClassroomTeacher);
    const updatedSlots = currentTeacher?.teacherSchedules?.filter(
      (s) => +s.slotId !== +payload.slotId,
    );
    currentTeacher.teacherSchedules = updatedSlots;
    return {
      ...state,
      classroomTeachers: newTeachers,
    };
  });

const slotCreationOrUpdationLoading = createReducer<boolean, TimetableType>(false)
  .handleAction([createTimeTableSlot.request, updateTimeTableSlot.request], () => true)
  .handleAction(
    [
      createTimeTableSlot.success,
      createTimeTableSlot.failure,
      updateTimeTableSlot.success,
      updateTimeTableSlot.failure,
    ],
    () => false,
  );

const weeksData = createReducer<WeeksList, TimetableType>({
  weekDetails: null,
} as unknown as WeeksList)
  .handleAction(currentSemesterWeeks.success, (state, action) => action.payload)
  .handleAction(resetCurrentSemesterWeeks, (state) => {
    return {
      ...state,
      weekDetails: null,
    };
  });

const fetchingWeeks = createReducer<boolean, TimetableType>(true)
  .handleAction(currentSemesterWeeks.request, () => true)
  .handleAction([currentSemesterWeeks.success, currentSemesterWeeks.failure], () => false);

const timeTableLoading = createReducer<boolean, TimetableType>(false)
  .handleAction(loadTimeTableData.request, () => true)
  .handleAction([loadTimeTableData.success, loadTimeTableData.failure], () => false);

const timetableReducer = combineReducers({
  teacherLoading,
  timeTableData,
  timeTableLoading,
  weeksData,
  fetchingWeeks,
  slotCreationOrUpdationLoading,
});

export type TimetableStateType = StateType<typeof timetableReducer>;

export default timetableReducer;
