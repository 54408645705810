import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { APIError } from 'utils/api';
import { loadSchoolBranches, loadSchoolBranchStats } from './actions';
import { getSchoolBranches, getSchoolBranchStats } from './api';
import { branchStats, schoolDetail } from './types';
import { schoolBranchesInitialState } from './reducer';

function* loadSchoolBranchesRequest(): SagaIterator<void> {
  try {
    const branches: schoolDetail = yield call(getSchoolBranches);
    yield put(loadSchoolBranches.success(branches));
  } catch (error) {
    if (error === APIError.notFound)
      yield put(loadSchoolBranches.success(schoolBranchesInitialState));
    else yield put(loadSchoolBranches.failure(error));
  }
}

function* loadSchoolBranchStasRequest({
  payload,
}: ReturnType<typeof loadSchoolBranchStats.request>): SagaIterator<void> {
  try {
    const branches: branchStats = yield call(getSchoolBranchStats, payload);
    yield put(loadSchoolBranchStats.success(branches));
  } catch (error) {
    yield put(loadSchoolBranchStats.failure(error));
  }
}

function* schoolBranchesSaga(): SagaIterator {
  yield all([
    takeEvery(loadSchoolBranches.request, loadSchoolBranchesRequest),
    takeEvery(loadSchoolBranchStats.request, loadSchoolBranchStasRequest),
  ]);
}

export default schoolBranchesSaga;
