import React, { FunctionComponent, useState, useCallback } from 'react';
import { uniqueId } from 'lodash';
import { Row, Col, useScreenClass } from 'react-grid-system';
import { RateItem } from './RateItem';
import styles from './Rate.module.scss';

export type RateProps = {
  value?: number;
  count?: number;
  isError?: boolean;
  onChange?: (index: number) => void;
};

const DEFAULT_HOVER_INDEX = -1;

export const Rate: FunctionComponent<RateProps> = ({
  value = 0,
  count = 5,
  isError,
  onChange = (): void => {},
}) => {
  const screen = useScreenClass();

  const isMobile = ['xs', 'sm'].includes(screen);

  const [hoverIndex, setHoverIndex] = useState<number>(DEFAULT_HOVER_INDEX);

  const getIsSelected = (index: number): boolean => {
    if (hoverIndex !== DEFAULT_HOVER_INDEX) return index + 1 <= hoverIndex;
    return index + 1 <= value;
  };
  const resetHoverIndex = useCallback(() => setHoverIndex(DEFAULT_HOVER_INDEX), []);

  return (
    <div className={styles.container} onMouseLeave={resetHoverIndex}>
      <Row gutterWidth={18}>
        {[...new Array(count)].map((item, index) => (
          <Col xs="content" key={uniqueId()}>
            <RateItem
              isSelected={getIsSelected(index)}
              isError={isError}
              index={index}
              setHoverIndex={isMobile ? null : setHoverIndex}
              onChange={onChange}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};
