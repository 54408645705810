import React from 'react';
import { Chart as ChartJS, PieController, ArcElement } from 'chart.js';
import { Pie, ChartProps } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { DEFAULT_OPTIONS_PIE } from './Chart.constants';

ChartJS.register(PieController, ArcElement);

export const PieChart = ({ options, ...restProps }: Omit<ChartProps<'pie'>, 'type'>) => (
  <Pie {...restProps} options={{ ...DEFAULT_OPTIONS_PIE, ...options }} />
);
