import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { loadGradesAction, loadGradeStudentsAction } from './actions';
import { getGrades, getGradeStudents } from './api';
import { GradeType } from './types';

function* loadGradesRequest({
  payload,
}: ReturnType<typeof loadGradesAction.request>): SagaIterator<void> {
  try {
    const grades: GradeType[] = yield call(getGrades, payload);
    yield put(loadGradesAction.success(grades));
  } catch (error) {
    yield put(loadGradesAction.failure(error));
  }
}

function* loadGradeStudentsRequest({
  payload,
}: ReturnType<typeof loadGradeStudentsAction.request>): SagaIterator<void> {
  try {
    const grades: GradeType[] = yield call(getGradeStudents, payload);
    yield put(loadGradeStudentsAction.success(grades));
  } catch (error) {
    yield put(loadGradeStudentsAction.failure(error));
  }
}

function* lessonsSaga(): SagaIterator {
  yield all([takeEvery(loadGradesAction.request, loadGradesRequest)]);
  yield all([takeEvery(loadGradeStudentsAction.request, loadGradeStudentsRequest)]);
}

export default lessonsSaga;
