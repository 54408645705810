import { useCallback, useEffect, useState } from 'react';

export const useCustomScreenClass = (): string => {
  const findScreenClass = useCallback((): string => {
    const screenConfigurations = [
      {
        width: 576,
        screenClass: 'sm',
      },
      {
        width: 768,
        screenClass: 'md',
      },
      {
        width: 992,
        screenClass: 'lg',
      },
      {
        width: 1200,
        screenClass: 'xl',
      },
      {
        width: 1600,
        screenClass: 'xxl',
      },
    ];
    let screen = 'xxl';
    const width = window.innerWidth;
    for (let index = 0; index < screenConfigurations.length; index += 1) {
      const { width: configWidth, screenClass } = screenConfigurations[index];
      if (width <= configWidth) {
        screen = screenClass;
        break;
      }
    }
    return screen;
  }, []);

  const [screenClass, setScreenClass] = useState<string>(findScreenClass());

  useEffect(() => {
    const setScreen = (): void => setScreenClass(findScreenClass());
    window.addEventListener('resize', setScreen);
    return (): void => window.removeEventListener('resize', setScreen);
  }, [findScreenClass]);

  return screenClass;
};
