import camelcaseKeys from 'camelcase-keys';
import API from '../../utils/api';
import { updateFormDataType } from './types';

export async function getStudentMaterialApi(): Promise<number> {
  const response = await API.get('/api/entry_test_material/student_items/');
  return camelcaseKeys(response.data, { deep: true });
}

export async function downloadStudentMaterialApi(materialId: number): Promise<number> {
  const response = await API.post('/api/entry_test_material/download/', {
    material_id: materialId,
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function uploadPreparationMaterialApi(payload: FormData): Promise<number> {
  const response = await API.post('/api/entry_test_material/material/', payload);
  return camelcaseKeys(response.data, { deep: true });
}

export async function updatePreparationMaterialApi(payload: updateFormDataType): Promise<number> {
  const response = await API.patch(
    `/api/entry_test_material/operations/${payload.materialId}`,
    payload.data,
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function deletePreparationMaterialApi(payload: updateFormDataType): Promise<number> {
  const response = await API.delete(`/api/entry_test_material/operations/${payload.materialId}`);
  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeacherMaterialApi(): Promise<number> {
  const response = await API.get('/api/entry_test_material/material/');
  return camelcaseKeys(response.data, { deep: true });
}
