import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import { getUserEnrollmentAPI, getUserSemesterCoursesAPI } from 'utils/apiEndpoints';

import {
  SubjectType,
  SubjectsRequestType,
  SemesterSubjectResponseType,
  SemesterSubjectRequestType,
} from './types';

export async function getSubjects({
  isAssigned = true,
  isUserGraded = true,
  isGradeSubject = false,
}: SubjectsRequestType): Promise<SubjectType[]> {
  const response = await API.get('/api/mentora/subjects/', {
    params: {
      not_assigned: isAssigned,
      user_graded: isUserGraded,
      grade_subject: isGradeSubject,
    },
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function getSemesterSubjects({
  gradeId,
}: SemesterSubjectRequestType): Promise<SemesterSubjectResponseType> {
  const response = await API.get(getUserSemesterCoursesAPI(), {
    params: {
      grade_id: gradeId,
    },
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function assignSubjects(payload: any) {
  const response = await API.post(getUserEnrollmentAPI(), payload);
  return camelcaseKeys(response.data, { deep: true });
}
