import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  loadEventsAction,
  loadNextEventAction,
  loadQuestionPoolsDetailsAction,
  loadQuestionPoolsGroupsAction,
  setCompleteAllBlocksAction,
  startQuestionPoolsAttemptAction,
  studentAttemptSubmissionAction,
  submitQuestionGroupsAnswersAction,
} from './actions';
import {
  completeAllBlocks,
  getEvents,
  getNextEvent,
  getQuestionPoolsDetails,
  getQuestionPoolsGroups,
  startQuestionPoolsAttempt,
  studentAttemptApi,
  submitQuestionGroupsAnswers,
} from './api';
import { CompleteEventType, EventType, NextEventType, StudentAttemptResult } from './types';

function* loadEventsRequest(
  action: ReturnType<typeof loadEventsAction.request>,
): SagaIterator<void> {
  try {
    const events: EventType[] = yield call(getEvents, action.payload);
    yield put(loadEventsAction.success(events));
  } catch (error) {
    yield put(loadEventsAction.failure(error));
  }
}

function* loadNextEventRequest(
  action: ReturnType<typeof loadNextEventAction.request>,
): SagaIterator<void> {
  try {
    const next: NextEventType = yield call(getNextEvent, action.payload);
    yield put(loadNextEventAction.success(next));
  } catch (error) {
    yield put(loadNextEventAction.failure(error));
  }
}

function* setCompleteAllBlocksRequest(
  action: ReturnType<typeof setCompleteAllBlocksAction.request>,
): SagaIterator<void> {
  try {
    const success: CompleteEventType = yield call(completeAllBlocks, action.payload);
    yield put(setCompleteAllBlocksAction.success(success));
  } catch (error) {
    yield put(setCompleteAllBlocksAction.failure(error));
  }
}

function* loadQuestionPoolsDetails(
  action: ReturnType<typeof loadQuestionPoolsDetailsAction.request>,
): SagaIterator<void> {
  try {
    const data = yield call(getQuestionPoolsDetails, action.payload);
    yield put(loadQuestionPoolsDetailsAction.success(data));
  } catch (error) {
    yield put(loadQuestionPoolsDetailsAction.failure(error));
  }
}

function* startQuestionPoolsAttemptRequest({
  payload: { eventId, callback },
}: ReturnType<typeof startQuestionPoolsAttemptAction.request>): SagaIterator<void> {
  try {
    const data = yield call(startQuestionPoolsAttempt, eventId);
    yield put(startQuestionPoolsAttemptAction.success(data));
    callback?.({ type: 'success', data });
  } catch (error) {
    yield put(startQuestionPoolsAttemptAction.failure(error));
    callback?.({ type: 'error', error });
  }
}

function* loadQuestionPoolsGroups(
  action: ReturnType<typeof loadQuestionPoolsGroupsAction.request>,
): SagaIterator<void> {
  try {
    const data = yield call(getQuestionPoolsGroups, action.payload);
    yield put(loadQuestionPoolsGroupsAction.success(data));
  } catch (error) {
    yield put(loadQuestionPoolsGroupsAction.failure(error));
  }
}

function* fetchPerformanceResultRequest({
  payload,
}: ReturnType<typeof studentAttemptSubmissionAction.request>): SagaIterator<void> {
  try {
    const lessonEvents: StudentAttemptResult = yield call(studentAttemptApi, payload);
    yield put(studentAttemptSubmissionAction.success(lessonEvents));
  } catch (error) {
    yield put(studentAttemptSubmissionAction.failure(error));
  } finally {
    payload.callback?.();
  }
}

function* submitQuestionGroupsAnswersRequest({
  payload: { callback, ...payload },
}: ReturnType<typeof submitQuestionGroupsAnswersAction.request>): SagaIterator<void> {
  try {
    const data = yield call(submitQuestionGroupsAnswers, payload);
    yield put(submitQuestionGroupsAnswersAction.success(data));
    callback?.({ type: 'success', data });
  } catch (error) {
    yield put(submitQuestionGroupsAnswersAction.failure(error));
    callback?.({ type: 'error', error });
  }
}

function* eventsSaga(): SagaIterator {
  yield all([
    takeEvery(loadEventsAction.request, loadEventsRequest),
    takeEvery(loadNextEventAction.request, loadNextEventRequest),
    takeEvery(setCompleteAllBlocksAction.request, setCompleteAllBlocksRequest),
    takeEvery(loadQuestionPoolsDetailsAction.request, loadQuestionPoolsDetails),
    takeEvery(studentAttemptSubmissionAction.request, fetchPerformanceResultRequest),
    takeEvery(startQuestionPoolsAttemptAction.request, startQuestionPoolsAttemptRequest),
    takeEvery(loadQuestionPoolsGroupsAction.request, loadQuestionPoolsGroups),
    takeEvery(submitQuestionGroupsAnswersAction.request, submitQuestionGroupsAnswersRequest),
  ]);
}

export default eventsSaga;
