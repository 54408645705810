import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  SubjectType,
  SubjectsRequestType,
  SemesterSubjectRequestType,
  SemesterSubjectResponseType,
} from './types';

export const loadSubjectsAction = createAsyncAction(
  '@@subjects/LOAD_REQUEST',
  '@@subjects/LOAD_SUCCESS',
  '@@subjects/LOAD_FAILURE',
)<SubjectsRequestType, SubjectType[], string>();

export const loadSemesterSubjects = createAsyncAction(
  '@@subjects/LOAD_SEMESTER_SUBJECTS_REQUEST',
  '@@subjects/LOAD_SEMESTER_SUBJECTS_SUCCESS',
  '@@subjects/LOAD_SEMESTER_SUBJECTS_FAILURE',
)<SemesterSubjectRequestType, SemesterSubjectResponseType, string>();

export const assignSubjectsAction = createAsyncAction(
  '@@subjects/ASSIGN_SEMESTER_SUBJECTS_REQUEST',
  '@@subjects/ASSIGN_SEMESTER_SUBJECTS_SUCCESS',
  '@@subjects/ASSIGN_SEMESTER_SUBJECTS_FAILURE',
)<any, any, string>();

export const resetSubjectsAction = createAction('@@subjects/RESET')();
