import React, { useEffect } from 'react';
import cn from 'classnames';
import { useToggle } from 'react-use';
import { useLocation } from 'react-router-dom';

import { Icon, IconCommon } from 'lib';

import { MenusType } from 'components/LayoutBase/components/Sidebar/types';

import styles from './Sidebar.module.scss';
import { Menu } from './Menu';

export function SubMenus({ image, label, icon, subMenus, setIsOpen, setElement }: MenusType) {
  const [isOpen, setOpen] = useToggle(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const hasActiveMenu = subMenus?.some(({ path }) => pathname.includes(path));
    setOpen(hasActiveMenu);
  }, [pathname, setOpen, subMenus]);

  return (
    <div className={cn(styles.subMenu, isOpen && styles.open)}>
      <div className={cn(styles.navigation__item)} onClick={setOpen}>
        {image ? (
          <img src={image} alt="icon" className={styles.navigation__icon} />
        ) : (
          <Icon
            className={styles.navigation__icon}
            title={label}
            type={icon ?? 'info'}
            size="s24"
          />
        )}
        <div className={styles.navigation__title}>{label}</div>
        <span className={styles.subMenu__dropArrow}>
          <IconCommon className="keyboard_arrow_down" isMaterialIcon />
        </span>
      </div>
      <div className={styles.subMenu__menu}>
        {subMenus?.map(({ id, ...props }) => (
          <Menu key={id} {...props} setIsOpen={setIsOpen} setElement={setElement} />
        ))}
      </div>
    </div>
  );
}
