import styled, { css, keyframes } from 'styled-components';

import { getPngURL } from 'lib';
import {
  disableButton,
  spin,
  sizeMobile,
  sizeTablet,
  sizeDesktop,
} from 'modules/GradeOneToThree/styled-mixin';

import { ButtonPropsType } from './elementTypes';

export const Row = styled.div`
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: auto;
  margin: 0 -15px;
`;

export const Container = styled.div`
  display: block;
  width: 100%;
  max-width: calc(100% - 40px);
  margin: 0 auto;

  ${sizeDesktop(css`
    max-width: 1170px;
  `)};
`;

const shakeHorizontal = keyframes`
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  80% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  90% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
`;

export const Button = styled.button<ButtonPropsType>`
  position: relative;
  font-family: ${(p) => p.theme.font.family.children};
  font-size: ${(p) => p.theme.font.size.primary};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: -0.48px;
  text-align: center;
  border: 0;
  background: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  padding: 10px 35px;

  &:disabled,
  &[disabled] {
    ${disableButton}
  }

  &:hover{
    -webkit-animation: ${shakeHorizontal} 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: ${shakeHorizontal} 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  }

  ${({ size }) =>
    size === 'sm' &&
    css`
      padding: 13px 35px;
    `};

  ${({ size }) =>
    size === 'md' &&
    css`
      padding: 18px 35px;

      ${sizeMobile(css`
        min-width: 130px;
      `)};
    `};

  ${({ size }) =>
    size === 'lg' &&
    css`
      padding: 20px 45px 25px;
    `};

  ${({ color }) =>
    color === 'orange' &&
    css`
      color: rgba(0, 0, 0, 0.8);
      background-image: url(${getPngURL('btn-orange', true)});
    `};

  ${({ color }) =>
    color === 'red' &&
    css`
      color: ${(p) => p.theme.colors.white};
      background-image: url(${getPngURL('btn-red', true)});
    `};
  
  ${({ variant, color, theme }) =>
    variant === 'outline' &&
    css`
      border: 1px solid ${theme.colors.selectiveYellow};
      color: ${theme.colors.selectiveYellow};
      font-size: ${theme.font.size.secondary};
      font-weight: 600;
      border-radius: 4px;
      padding: 10px 20px;
      cursor: pointer;
      line-height: unset;

      &:hover,
      &:focus,
      &:active {
        background-color: ${theme.colors.selectiveYellow};
        color: ${theme.colors.white};
        animation: none;
      }

      ${(color === 'red' || color === 'green') &&
      css`
        color: ${theme.colors[color === 'red' ? 'wellRead' : 'mountainMeadow']};
        background-color: transparent;
        border: 1px solid ${theme.colors[color === 'red' ? 'wellRead' : 'mountainMeadow']};
        background-image: none;

        &:hover,
        &:focus,
        &:active {
          color: ${theme.colors.white};
          background-color: ${theme.colors[color === 'red' ? 'wellRead' : 'mountainMeadow']};
          border-color: ${theme.colors[color === 'red' ? 'wellRead' : 'mountainMeadow']};
        }
      `};

      &:disabled,
      &[disabled] {
        background: var(--disable-bg);
        border-color: var(--border-color);
        pointer-events: none;
        color: var(--disable-color);
        cursor: not-allowed;
      }

      ${sizeMobile(css`
        font-size: 1.8rem;
      `)};
    `};
  
  ${({ variant, color, theme }) =>
    variant === 'filled' &&
    css`
      background-color: ${theme.colors.selectiveYellow};
      border: 1px solid ${theme.colors.selectiveYellow};
      color: ${theme.colors.white};
      font-size: ${theme.font.size.secondary};
      font-weight: 600;
      border-radius: 4px;
      padding: 10px 20px;
      cursor: pointer;
      line-height: unset;

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        color: ${theme.colors.selectiveYellow};
        animation: none;
      }

      ${(color === 'red' || color === 'green') &&
      css`
        color: ${theme.colors.white};
        background-color: ${theme.colors[color === 'red' ? 'wellRead' : 'mountainMeadow']};
        border: 1px solid ${theme.colors[color === 'red' ? 'wellRead' : 'mountainMeadow']};
        background-image: none;

        &:hover,
        &:focus,
        &:active {
          color: ${theme.colors[color === 'red' ? 'wellRead' : 'mountainMeadow']};
          background-color: transparent;
          border: 1px solid ${theme.colors[color === 'red' ? 'wellRead' : 'mountainMeadow']};
        }
      `};

      &:disabled,
      &[disabled] {
        background: var(--disable-bg);
        border-color: var(--border-color);
        pointer-events: none;
        color: var(--disable-color);
        cursor: not-allowed;
      }

      ${sizeMobile(css`
        font-size: 1.8rem;
      `)};
    `};
}

${(p) =>
  p.theme.dir === 'rtl' &&
  css`
    font-family: ${(p) => p.theme.font.family.arabic};
    line-height: 1;
  `};
`;

Button.defaultProps = {
  type: 'submit',
};

const TextStyle = css`
  font-weight: bold;
  font-family: ${(p) => p.theme.font.family.children};
  text-shadow: 1.5px 2.6px 2px rgba(0, 0, 0, 0.2);

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      font-family: ${(p) => p.theme.font.family.arabic};
    `};
`;

export const Title = styled.h4`
  ${TextStyle}
  font-size: 2.2rem;
  margin-bottom: 10px;
`;

export const SubTitle = styled.p`
  ${TextStyle}
  font-size: 1.2rem;
`;

type CardPropsType = {
  isOverdueLesson?: boolean;
};

export const EmptyState = styled.div<CardPropsType>`
  position: relative;
  padding: 20px;
  background-color: #002a64;
  background: linear-gradient(to bottom, #00264f, #002a64);
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
  border-radius: 0 0 8px 8px;

  ${({ isOverdueLesson }) =>
    isOverdueLesson &&
    css`
      background: linear-gradient(to bottom, #a73030, #300a0a);
    `};

  ${sizeTablet(css`
    min-height: 320px;
  `)};
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-family: ${(p) => p.theme.font.family.children};

  ${sizeMobile(css`
    margin-bottom: 30px;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl'
      ? css`
          font-family: ${(p) => p.theme.font.family.arabic};
        `
      : ''};
`;

export const HeaderTitle = styled.div`
  color: ${(p) => p.theme.colors.primary.headingColor};
  font-size: 2rem;
  font-weight: bold;
  padding-right: 5px;

  ${(p) =>
    p.theme.dir === 'rtl'
      ? css`
          padding-right: 0;
          padding-left: 5px;
        `
      : ''};

  ${sizeMobile(css`
    font-size: 2.6rem;
  `)};
`;

export const IconWrapper = styled.span`
  cursor: pointer;
  width: 15px;
  object-fit: contain;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  -webkit-animation: ${spin} 6s infinite linear;
  animation: ${spin} 6s infinite linear;

  ${sizeMobile(css`
    width: 19px;
    top: 30px;
    right: 30px;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      right: unset;
      left: 15px;

      ${sizeMobile(css`
        right: unset;
        left: 30;
      `)};
    `};
`;
