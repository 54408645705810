import { getPngURL, getSvgURL } from 'lib/SVG/SVG';
import { PaymentStatus, SITEPAL_ANS_LENGTH, StateSubscription } from 'constants/entities';
import { isEmptyObject } from 'utils/helpers';

import {
  AccountAvatarType,
  AccountType,
  Gender,
  ParentInvitationType,
  ProfileType,
  Role,
  summerRole,
  SchoolRole,
  subjectsGradeType,
  AccountSemesterType,
  StudentSubject,
  PaymentMethodType,
  CardTypeId,
  CardType,
  RecurringSubscriptionType,
  AdminRole,
} from './types';
import { SubscriptionServiceType } from '../subscriptions/types';

export type ResponseAccountProfileImageType = {
  imageUrlFull: string;
  imageUrlLarge: string;
  imageUrlMedium: string;
  imageUrlSmall: string;
  hasImage: boolean;
};

export type ResponseProfileType = {
  firstName: string;
  lastName: string;
  username: string;
  profileImage: ResponseAccountProfileImageType;
  email: string;
  gender: Gender | null;
  grade: string;
  mentor?: ResponseProfileType | null;
};

export type SubscriptionSate = {
  expirationDate: string;
  isActive: boolean;
  state: StateSubscription;
  status: PaymentStatus;
};

export type ResponseAccountType = {
  id: number;
  username: string;
  name: string;
  firstName: string;
  plainPassword?: string;
  lastName: string;
  isActive: boolean;
  showSubscriptionBanner: boolean | null;
  sitepalMaxAnswerLength: Number;
  profileImage: ResponseAccountProfileImageType;
  gender: Gender | null;
  email: string;
  role: Role;
  digitalTutor: boolean;
  schoolRole: SchoolRole;
  referralLink: string;
  subjects: StudentSubject[];
  mentor: ResponseProfileType | null;
  rating: number | null;
  teacherRating: number | null;
  students: ResponseProfileType[] | null;
  children: ResponseProfileType[] | null;
  parent: ResponseProfileType | null;
  allMentors?: Array<ResponseProfileType> | null;
  parentInvitation: ParentInvitationType;
  state: SubscriptionSate | null;
  phoneNumber: string | null;
  summerSchoolSubscription?: Array<any>;
  summerSchoolRole: summerRole | any;
  subjectsEnrollmentCount: number;
  grade: subjectsGradeType;
  semester: AccountSemesterType;
  isSubscriptionAutoRenewal?: boolean;
  lastSubscriptionType?: PaymentMethodType;
  lastPaymentMethod?: CardTypeId;
  showCurrentSemesterSubscriptionBanner: boolean;
  isVoucherActive: boolean;
  cardInformation: CardType;
  recurringSubscription: RecurringSubscriptionType;
  adminRole: AdminRole;
  userLoginCount: number;
  subscriptionServices: SubscriptionServiceType[];
  school: {
    logo: string;
  };
  hasQiyasAccess: boolean;
} & Pick<
  AccountType,
  | 'birthPlace'
  | 'nationalId'
  | 'nationality'
  | 'passportNo'
  | 'enableSecureExam'
  | 'secureExamSeconds'
>;

const OmitAccountAttributes = [
  'accomplishmentsShared',
  'accountPrivacy',
  'bio',
  'country',
  'dateJoined',
  'extendedProfile',
  'goals',
  'languageProficiencies',
  'mailingAddress',
  'requiresParentalConsent',
  'secondaryEmail',
  'socialLinks',
  'yearOfBirth',
  'isActive',
];

export const getImage = (
  profileImage: ResponseAccountProfileImageType,
  role: Role,
  username?: string,
  gender?: Gender | null,
): AccountAvatarType => {
  if (profileImage.hasImage) {
    return {
      small: profileImage.imageUrlLarge,
      large: profileImage.imageUrlFull,
    };
  }

  if (username && gender && (role === Role.student || role === Role.mentor)) {
    const isBoy = gender === Gender.male;

    return {
      small: getPngURL(isBoy ? 'boy-small' : 'girl-small'),
      large: getPngURL(isBoy ? 'boy-large' : 'girl-large'),
    };
  }

  return {
    small: getPngURL('unknown-small'),
    large: getPngURL('unknown-large'),
  };
};

export const dataAccountProfileAdapter = (
  profile: ResponseProfileType,
  role: Role,
): ProfileType => ({
  firstName: profile.firstName,
  lastName: profile.lastName,
  username: profile.username,
  grade: profile.grade,
  email: profile.email,
  gender: profile.gender,
  avatar: getImage(profile.profileImage, role, profile.username, profile.gender),
  mentor: profile.mentor && dataAccountProfileAdapter(profile.mentor, Role.mentor),
});

export const cardInformationAdapter = (cardInformation: CardType) => {
  const cardName = cardInformation.paymentBrand;
  return !isEmptyObject(cardInformation)
    ? {
        ...cardInformation,
        cardBrandLogoUrl: cardName && getSvgURL(cardName.toLowerCase()),
        cardBrandName: cardName,
      }
    : ({} as CardType);
};

export const dataAccountAdapter = (account: ResponseAccountType): AccountType => {
  const INDEX_PHONE_CODE = 3;

  const response = {
    ...account,
    sitepalMaxAnswerLength: account.sitepalMaxAnswerLength || SITEPAL_ANS_LENGTH,
    avatar: getImage(account.profileImage, account.role, account.username, account.gender),
    mentor: account.mentor && dataAccountProfileAdapter(account.mentor, Role.mentor),
    phone: {
      code:
        account.phoneNumber && account.phoneNumber.length > INDEX_PHONE_CODE
          ? account.phoneNumber.substring(0, INDEX_PHONE_CODE)
          : null,
      number:
        account.phoneNumber && account.phoneNumber.length > INDEX_PHONE_CODE
          ? account.phoneNumber.substring(INDEX_PHONE_CODE)
          : null,
    },
    students:
      account.students &&
      account.students.map((student) => dataAccountProfileAdapter(student, Role.student)),
    children:
      account.children &&
      account.children.map((child) => dataAccountProfileAdapter(child, Role.student)),
    allMentors:
      account.allMentors &&
      account.allMentors.map((mentor) => dataAccountProfileAdapter(mentor, Role.mentor)),
    parent: account.parent && dataAccountProfileAdapter(account.parent, Role.parent),
    cardInformation: cardInformationAdapter(account.cardInformation),
    userLoginCount: account.userLoginCount,
    subscribedServices: account.subscriptionServices,
    school: account.school,
  };

  OmitAccountAttributes.forEach((value) => delete response[value as keyof ResponseAccountType]);
  return response;
};

export const dataUpdateAccountAdapter = (
  account: Partial<AccountType>,
): Partial<ResponseAccountType> => {
  const {
    email,
    gender,
    firstName,
    lastName,
    phone,
    birthPlace,
    nationalId,
    nationality,
    passportNo,
  } = account || {};

  return {
    email,
    gender,
    firstName,
    lastName,
    phoneNumber: phone?.code ? `${phone.code}${phone.number}` : undefined,
    birthPlace,
    nationalId,
    nationality,
    passportNo,
  };
};
