import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';

import * as actions from './actions';
import {
  fetchGradeSubjectsAction,
  fetchQuestionsAction,
  fetchLessonEventsAction,
  fetchLearningObjectivesAction,
  uploadContentAction,
  fetchPreviewQuestionsAction,
} from './actions';
import { GradeSubjects, LessonQuestionsResponse, LessonEvent, LearningObjective } from './types';

export type NativeEventsActions = ActionType<typeof actions>;

const initialState = {
  data: {
    gradeSubjects: {} as GradeSubjects,
    questions: {} as LessonQuestionsResponse,
    previewQuestions: {} as LessonQuestionsResponse,
    lessonEvents: [] as LessonEvent[],
    learningObjectives: [] as LearningObjective[],
    uploadedContentUrl: '',
  },
  fetching: {
    gradeSubjects: false,
    questions: false,
    previewQuestions: false,
    lessonEvents: false,
    learningObjectives: false,
  },
  errors: {
    gradeSubjects: '',
    questions: '',
    previewQuestions: '',
    lessonEvents: '',
    learningObjectives: '',
  },
};

const data = combineReducers({
  gradeSubjects: createReducer<GradeSubjects, NativeEventsActions>(
    initialState.data.gradeSubjects,
  ).handleAction([fetchGradeSubjectsAction.success], (_, { payload }) => ({
    ...payload,
  })),
  questions: createReducer<LessonQuestionsResponse, NativeEventsActions>(
    initialState.data.questions,
  ).handleAction([fetchQuestionsAction.success], (_, { payload }) => ({
    ...payload,
  })),
  previewQuestions: createReducer<LessonQuestionsResponse, NativeEventsActions>(
    initialState.data.previewQuestions,
  )
    .handleAction([fetchPreviewQuestionsAction.success], (_, { payload }) => ({
      ...payload,
    }))
    .handleAction([actions.resetPreviewQuestionsAction], () => initialState.data.previewQuestions),
  lessonEvents: createReducer<LessonEvent[], NativeEventsActions>(initialState.data.lessonEvents)
    .handleAction([fetchLessonEventsAction.success], (_, { payload }) => [...payload])
    .handleAction([actions.resetLessonEventsAction], () => initialState.data.lessonEvents),
  learningObjectives: createReducer<LearningObjective[], NativeEventsActions>(
    initialState.data.learningObjectives,
  ).handleAction([fetchLearningObjectivesAction.success], (_, { payload }) => [...payload]),
  uploadedContentUrl: createReducer<string, NativeEventsActions>(
    initialState.data.uploadedContentUrl,
  )
    .handleAction([uploadContentAction.success], (_, { payload }) => payload.url)
    .handleAction([actions.resetUploadedContentAction], () => initialState.data.uploadedContentUrl),
});

const fetching = combineReducers({
  gradeSubjects: createReducer<boolean, NativeEventsActions>(true)
    .handleAction([fetchGradeSubjectsAction.request, fetchQuestionsAction.request], () => true)
    .handleAction(
      [fetchGradeSubjectsAction.success, fetchGradeSubjectsAction.failure],
      () => false,
    ),

  questions: createReducer<boolean, NativeEventsActions>(true)
    .handleAction([fetchQuestionsAction.request], () => true)
    .handleAction([fetchQuestionsAction.success, fetchQuestionsAction.failure], () => false),
  previewQuestions: createReducer<boolean, NativeEventsActions>(true)
    .handleAction([fetchQuestionsAction.request], () => true)
    .handleAction(
      [fetchPreviewQuestionsAction.success, fetchPreviewQuestionsAction.failure],
      () => false,
    ),
  lessonEvents: createReducer<boolean, NativeEventsActions>(true)
    .handleAction([fetchLessonEventsAction.request], () => true)
    .handleAction([fetchLessonEventsAction.success, fetchLessonEventsAction.failure], () => false),
  learningObjectives: createReducer<boolean, NativeEventsActions>(true)
    .handleAction([fetchLearningObjectivesAction.request], () => true)
    .handleAction(
      [fetchLearningObjectivesAction.success, fetchLearningObjectivesAction.failure],
      () => false,
    ),
  uploadedImg: createReducer<boolean, NativeEventsActions>(true)
    .handleAction([uploadContentAction.request], () => true)
    .handleAction([uploadContentAction.success, uploadContentAction.failure], () => false),
});

const errors = combineReducers({
  gradeSubjects: createReducer<string, NativeEventsActions>('')
    .handleAction(fetchGradeSubjectsAction.failure, (_, { payload }) => payload)
    .handleAction(fetchGradeSubjectsAction.success, () => ''),
  questions: createReducer<string, NativeEventsActions>('')
    .handleAction(fetchQuestionsAction.failure, (_, { payload }) => payload)
    .handleAction(fetchQuestionsAction.success, () => ''),
  previewQuestions: createReducer<string, NativeEventsActions>('')
    .handleAction(fetchQuestionsAction.failure, (_, { payload }) => payload)
    .handleAction(fetchPreviewQuestionsAction.success, () => ''),
  lessonEvents: createReducer<string, NativeEventsActions>('')
    .handleAction(fetchLessonEventsAction.failure, (_, { payload }) => payload)
    .handleAction(fetchLessonEventsAction.success, () => ''),
  learningObjectives: createReducer<string, NativeEventsActions>('')
    .handleAction(fetchLearningObjectivesAction.failure, (_, { payload }) => payload)
    .handleAction(fetchLearningObjectivesAction.success, () => ''),
  uploadedImg: createReducer<string, NativeEventsActions>('')
    .handleAction(uploadContentAction.failure, (_, { payload }) => payload)
    .handleAction(uploadContentAction.success, () => ''),
});

const nativeEventsReducer = combineReducers({
  fetching,
  data,
  errors,
});

export type NativeEventsStateType = StateType<typeof nativeEventsReducer>;

export default nativeEventsReducer;
