import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { loadSemestersAction } from 'store/semesters/actions';
import { loadAccountAction } from 'store/account/actions';
import { loadSubjectsAction, loadSemesterSubjects, assignSubjectsAction } from './actions';
import { getSubjects, getSemesterSubjects, assignSubjects } from './api';

import { SemesterSubjectResponseType, SubjectType } from './types';

function* loadSubjectsRequest({
  payload,
}: ReturnType<typeof loadSubjectsAction.request>): SagaIterator<void> {
  try {
    const subjects: SubjectType[] = yield call(getSubjects, payload);
    yield put(loadSubjectsAction.success(subjects));
  } catch (error) {
    yield put(loadSubjectsAction.failure(error));
  }
}

function* loadSemesterSubjectsRequest({
  payload,
}: ReturnType<typeof loadSemesterSubjects.request>): SagaIterator<void> {
  try {
    const semesterSubjects: SemesterSubjectResponseType = yield call(getSemesterSubjects, payload);
    yield put(loadSemesterSubjects.success(semesterSubjects));
  } catch (error) {
    yield put(loadSemesterSubjects.failure(error));
  }
}

function* assignSemesterSubjectsRequest({
  payload: { courses, callBack },
}: ReturnType<typeof assignSubjectsAction.request>): SagaIterator<void> {
  try {
    const assignSemesterSubjects: SemesterSubjectResponseType = yield call(assignSubjects, {
      courses,
    });
    yield put(assignSubjectsAction.success(assignSemesterSubjects));
    yield put(loadSemestersAction.request());
    yield put(loadAccountAction.request());
    callBack('success');
  } catch (error) {
    callBack('error');
    yield put(assignSubjectsAction.failure(error));
  }
}

function* subjectsSaga(): SagaIterator {
  yield all([takeEvery(loadSubjectsAction.request, loadSubjectsRequest)]);
  yield all([takeEvery(loadSemesterSubjects.request, loadSemesterSubjectsRequest)]);
  yield all([takeEvery(assignSubjectsAction.request, assignSemesterSubjectsRequest)]);
}

export default subjectsSaga;
