import { selectorLocale } from 'store/preferences/selectors';
import { useSelector } from 'react-redux';

import { dayTheme, nightTheme } from 'styledTheme';
import { ThemeTypes } from 'contexts/ThemeContext';

export const useStyledTheme = () => {
  const {
    data: { theme },
  } = useSelector(selectorLocale);
  return theme === ThemeTypes.day ? dayTheme : nightTheme;
};
