import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import {
  getWeeklyPlanAction,
  getWeekLessonsToDoAction,
  getWeekLiveSessionsAction,
  getChildTeachersAction,
  getParentsQuestionsAction,
  getLessonDetailsAction,
  resetError,
} from './actions';
import { DashboardData } from './types';

export type DashboardActionType = ActionType<typeof actions>;

const initialState = {
  fetching: {
    weeklyPlans: false,
    weekLessonsToDo: false,
    weekLiveSessions: false,
    childTeachers: false,
    parentQuestions: false,
    lessons: false,
    lessonDetails: false,
  },
  errors: {
    weeklyPlans: '',
    weekLessonsToDo: '',
    weekLiveSessions: '',
    childTeachers: '',
    lessons: '',
  },

  data: {} as DashboardData,
};

const data = createReducer<DashboardData, DashboardActionType>(initialState.data)
  .handleAction(getWeeklyPlanAction.success, (state, { payload }) => ({
    ...state,
    weeklyPlans: payload,
  }))
  .handleAction(getWeekLessonsToDoAction.success, (state, { payload }) => ({
    ...state,
    weekLessonsToDo: payload,
  }))
  .handleAction(getWeekLiveSessionsAction.success, (state, { payload }) => ({
    ...state,
    weekLiveSessions: payload,
  }))
  .handleAction(getChildTeachersAction.success, (state, { payload }) => ({
    ...state,
    childTeachers: payload,
  }))
  .handleAction(getParentsQuestionsAction.success, (state, { payload }) => ({
    ...state,
    parentQuestions: payload,
  }))
  .handleAction(getLessonDetailsAction.success, (state, { payload }) => ({
    ...state,
    lessonDetails: payload,
  }));

const fetching = combineReducers({
  fetchingWeeklyPlans: createReducer<boolean, DashboardActionType>(
    initialState.fetching.weeklyPlans,
  )
    .handleAction(getWeeklyPlanAction.request, () => true)
    .handleAction([getWeeklyPlanAction.success, getWeeklyPlanAction.failure], () => false),

  fetchingLessonsToDo: createReducer<boolean, DashboardActionType>(
    initialState.fetching.weekLessonsToDo,
  )
    .handleAction(getWeekLessonsToDoAction.request, () => true)
    .handleAction(
      [getWeekLessonsToDoAction.success, getWeekLessonsToDoAction.failure],
      () => false,
    ),

  fetchingLiveSessions: createReducer<boolean, DashboardActionType>(
    initialState.fetching.weekLiveSessions,
  )
    .handleAction(getWeekLiveSessionsAction.request, () => true)
    .handleAction(
      [getWeekLiveSessionsAction.success, getWeekLiveSessionsAction.failure],
      () => false,
    ),
  fetchingTeachers: createReducer<boolean, DashboardActionType>(initialState.fetching.childTeachers)
    .handleAction(getChildTeachersAction.request, () => true)
    .handleAction([getChildTeachersAction.success, getChildTeachersAction.failure], () => false),
  fetchingParentQuestions: createReducer<boolean, DashboardActionType>(
    initialState.fetching.parentQuestions,
  )
    .handleAction(getParentsQuestionsAction.request, () => true)
    .handleAction(
      [getParentsQuestionsAction.success, getParentsQuestionsAction.failure],
      () => false,
    ),
  fetchingLessonDetails: createReducer<boolean, DashboardActionType>(
    initialState.fetching.lessonDetails,
  )
    .handleAction(getLessonDetailsAction.request, () => true)
    .handleAction([getLessonDetailsAction.success, getLessonDetailsAction.failure], () => false),
});

const errors = combineReducers({
  errorWeeklyPlans: createReducer<string, DashboardActionType>(initialState.errors.weeklyPlans)
    .handleAction(
      [
        getWeeklyPlanAction.failure,
        getWeekLiveSessionsAction.failure,
        getWeekLessonsToDoAction.failure,
        getParentsQuestionsAction.failure,
      ],
      (state, { payload }) => payload,
    )
    .handleAction(getWeeklyPlanAction.success, () => initialState.errors.weeklyPlans)
    .handleAction(resetError, () => initialState.errors.weeklyPlans),
});

const dashboardReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type DashboardStateType = StateType<typeof dashboardReducer>;

export default dashboardReducer;
