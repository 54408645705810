import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import { generateChatWarningApi, getBlockStatusAPI, getSpamWordsListApi } from 'utils/apiEndpoints';

import {
  GroupDetails,
  GroupType,
  TokenType,
  ParticipantsPayloadType,
  SpamWords,
  AddChatTopicPayloadType,
  Participants,
} from './types';

export async function getGroupList(): Promise<GroupType[]> {
  const response = await API.get('/api/alw_chat/groups/');

  return camelcaseKeys(response.data, { deep: true });
}

export async function getGroupDetails(groupId: number): Promise<GroupDetails> {
  const response = await API.get(`/api/alw_chat/groups/${groupId}/`);

  return camelcaseKeys(response.data, { deep: true });
}
export async function getPeerAPI(): Promise<Array<Participants>> {
  const response = await API.get(`/api/alw_chat/one_to_one_participants/`);

  return camelcaseKeys(response.data, { deep: true });
}

export async function getUserAccessToken(): Promise<TokenType> {
  const response = await API.get('/api/alw_chat/user_access_token/?app_id=127.0.0.1');

  return camelcaseKeys(response.data, { deep: true });
}
export async function getUserBlockStatus(groupId: number): Promise<TokenType> {
  const response = await API.get(getBlockStatusAPI(groupId));

  return camelcaseKeys(response.data, { deep: true });
}

export async function blockParticipants(data: ParticipantsPayloadType): Promise<GroupDetails> {
  const response = await API.post(
    '/api/alw_chat/groups/block_participants/',
    snakeCaseKeys(data, { deep: true }),
  );

  return camelcaseKeys(response.data, { deep: true });
}

export async function addChatGroupPinMessage(data: AddChatTopicPayloadType): Promise<void> {
  await API.patch(`/api/alw_chat/groups/${data.groupId}/`, {
    pinned_msg: data.msg,
  });
}

export async function filteredWords(): Promise<SpamWords> {
  const response = await API.get(getSpamWordsListApi());

  return camelcaseKeys(response.data, { deep: true });
}

export async function createChatWarning(): Promise<void> {
  const response = await API.patch(generateChatWarningApi());

  return camelcaseKeys(response.data, { deep: true });
}

export async function unblockParticipants(data: ParticipantsPayloadType): Promise<GroupDetails> {
  const response = await API.post(
    '/api/alw_chat/groups/unblock_participants/',
    snakeCaseKeys(data, { deep: true }),
  );

  return camelcaseKeys(response.data, { deep: true });
}
