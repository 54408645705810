import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { loadUnitsAction } from './actions';
import { getUnits } from './api';
import { UnitType } from './types';

function* loadUnitsRequest(action: ReturnType<typeof loadUnitsAction.request>): SagaIterator<void> {
  try {
    const units: UnitType[] = yield call(getUnits, action.payload);
    yield put(loadUnitsAction.success(units));
  } catch (error) {
    yield put(loadUnitsAction.failure(error));
  }
}

function* unitsSaga(): SagaIterator {
  yield all([takeEvery(loadUnitsAction.request, loadUnitsRequest)]);
}

export default unitsSaga;
