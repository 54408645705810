/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import { ReactourStep } from 'reactour';
import { Lang } from '../../lang';
import { TourType } from '../../store/tourManagement/types';
import styles from './TourManagement.module.scss';

const isImgLink = (url: string): boolean => {
  return url.match(/^http[^?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) != null;
};

const createHtmlContent = (title: string, text: string, filePath?: string): React.ReactNode => (
  <div className={styles.tour__body}>
    <h3 className={styles.tour__title}>{title}</h3>
    <p className={styles.tour__text}>{text}</p>
    {filePath && (
      <div className={styles.tour__media}>
        {isImgLink(filePath) ? (
          <img height="200px" src={filePath} alt="img" />
        ) : (
          <video height="200px" width="100%" controls>
            <track kind="captions" />
            <source src={filePath} type="video/mp4" />
          </video>
        )}
      </div>
    )}
  </div>
);

export const getCurrentPageSteps = (
  tourData: TourType | any,
  locale: string,
): { id: string; steps: ReactourStep[] } | any => {
  const steps = tourData?.steps
    ?.sort((a: any, b: any) => a.order - b.order)
    ?.map((step: any): object => ({
      content:
        locale === Lang.ar
          ? createHtmlContent(step.headingAr, step.detailAr, step.fileAr)
          : createHtmlContent(step.headingEn, step.detailEn, step.fileEn),
      selector: `#${step.componentId}`,
      componentId: step.componentId,
    }));
  return {
    id: tourData?.id,
    isWatched: tourData?.isWatched,
    steps: steps?.length > 0 && [
      {
        content:
          locale === Lang.ar
            ? createHtmlContent(tourData.titleAr, tourData.detailAr)
            : createHtmlContent(tourData.titleEn, tourData.detailEn),
        placement: 'center',
      },
      ...steps,
    ],
  };
};
