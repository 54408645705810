import { createSelector } from 'reselect';
import { find } from 'lodash';

import { AccountStateType } from './reducer';
import { ProfileType, AccountType, Role, SchoolRole, AdminRole } from './types';
import { RootStateType } from '../types';
import { SubscriptionServiceEnum } from '../subscriptions/types';
import { adminRoles, alwRoles, schoolRoles, summerRoles } from '../../utils/roles';

export const selectorAccount = (state: RootStateType): AccountStateType => state.account;

export const accountDataSelector = createSelector(selectorAccount, ({ data }: any) => data);

export const accountRoleSelector = (state: RootStateType): Role => state.account?.data?.role;

export const selectorAccountData = (state: RootStateType): AccountType => state.account.data;
export const isAlwRoleSelector = (state: RootStateType): boolean => {
  const { role } = state.account.data;
  return alwRoles.includes(role);
};
export const isSchoolRoleSelector = (state: RootStateType): boolean => {
  const { schoolRole } = state.account.data;
  return schoolRoles.includes(schoolRole);
};

export const isSchoolTeacherSelector = (state: RootStateType): boolean => {
  const { schoolRole: currentRole } = state.account.data;
  return SchoolRole.schoolTeacher === currentRole;
};

export const isAlwTeacherRoleSelector = (state: RootStateType): boolean => {
  const { role } = state.account.data;
  return role === Role.alwTeacher;
};

export const isTeacherRoleSelector = (state: RootStateType): boolean => {
  const { role, schoolRole } = state.account.data;
  return role === Role.alwTeacher || schoolRole === SchoolRole.schoolTeacher;
};

export const isAlwTeacherMentorRoleSelector = (state: RootStateType): boolean => {
  const { isAlwTeacherMentor } = state.account.data;
  return !!isAlwTeacherMentor;
};

export const isStudentRoleSelector = (state: RootStateType): boolean => {
  const { schoolRole, role } = state.account.data;
  return schoolRole === SchoolRole.schoolStudent || Role.student === role;
};

export const isB2bStudentRoleSelector = (state: RootStateType): boolean => {
  const { schoolRole } = state.account.data;
  return schoolRole === SchoolRole.schoolStudent;
};

export const isALWStudentRoleSelector = (state: RootStateType): boolean => {
  const { role } = state.account.data;
  return role === Role.student;
};

export const isParentRoleSelector = (state: RootStateType): boolean => {
  const { role } = state.account.data;
  return Role.parent === role;
};

export const isAdminRoleSelector = (state: RootStateType): boolean => {
  const { schoolRole: currentRole } = state.account.data;
  return SchoolRole.schoolAdmin === currentRole;
};

export const isAutorizedRoleSelector = (state: RootStateType): boolean => {
  const { schoolRole, role, summerSchoolRole, adminRole } = state.account.data;
  return (
    schoolRoles.includes(schoolRole) ||
    summerRoles.includes(summerSchoolRole) ||
    alwRoles.includes(role) ||
    adminRoles.includes(adminRole)
  );
};

export const isUserLoggedIn = (state: RootStateType) => {
  const { username, email } = state.account.data;
  return !!username || !!email;
};

export const selectorStudent =
  (state: RootStateType) =>
  (username: string): ProfileType | undefined => {
    const { students } = state.account.data;
    return students?.find(({ username: studentUsername }) => studentUsername === username);
  };

export const selectorSubscriptionExpirationDate = createSelector(accountDataSelector, () => null);

export const selectorParentChild =
  (state: RootStateType) =>
  (username: string): ProfileType | undefined =>
    find(state.account.data.children, { username });

export const selectorAccountGender = createSelector(accountDataSelector, (data) => data?.gender);

export const studentGradeSelector = createSelector(
  accountDataSelector,
  ({ grade }) => grade?.gradeValue,
);

export const isAdminOrBranchManagerRoleSelector = (state: RootStateType): boolean => {
  const { schoolRole } = state.account.data;
  return SchoolRole.schoolAdmin === schoolRole || SchoolRole.schoolBranchManager === schoolRole;
};

export const isSchoolAdminRoleSelector = (state: RootStateType) => {
  const { schoolRole } = state.account.data;
  return SchoolRole.schoolAdmin === schoolRole;
};

export const teacherRatingSelector = createSelector(
  accountDataSelector,
  ({ teacherRating }) => teacherRating,
);

export const isBranchManagerRoleSelector = (state: RootStateType): boolean => {
  const { schoolRole } = state.account.data;
  return SchoolRole.schoolBranchManager === schoolRole;
};

export const otpDataSelector = createSelector(
  selectorAccount,
  ({ updateAccountOTP }: AccountStateType) => updateAccountOTP.otpData,
);

export const isOTPVerifiedSelector = createSelector(
  selectorAccount,
  ({ updateAccountOTP }: AccountStateType) => updateAccountOTP.isOtpVerified,
);

export const otpErrorSelector = createSelector(
  selectorAccount,
  ({ errors }: AccountStateType) => errors.otpError,
);

export const isAuthorOrAlwAdminSelector = (state: RootStateType): boolean => {
  const { adminRole, role } = state.account.data;
  return Role.contentAuthor === role || AdminRole.alwAdmin === adminRole;
};

export const disabledServicesSelector = createSelector(
  selectorAccountData,
  ({ subscribedServices }) => {
    const disabledServices = {
      [SubscriptionServiceEnum.RECORDED_CONTENT]: true,
      [SubscriptionServiceEnum.WEEKLY_REVIEW_SESSIONS]: true,
      [SubscriptionServiceEnum.EXERCISES_AND_EXAMS]: true,
      [SubscriptionServiceEnum.QIYAS_SIMULATION]: true,
    };

    subscribedServices
      ?.map(({ services }) => services)
      .flat()
      .forEach((service) => {
        disabledServices[service.type] = false;
      });

    return disabledServices;
  },
);

export const isUpdatingAccountSelector = createSelector(
  selectorAccount,
  ({ fetching: { pendingEmailRequest } }) => pendingEmailRequest,
);

export const digitalTutorFlagSelector = createSelector(
  accountDataSelector,
  ({ digitalTutor }) => digitalTutor,
);

export const accountFetchingSelector = createSelector(selectorAccount, ({ fetching }) => fetching);

export const loginRequestStatusSelector = createSelector(
  accountFetchingSelector,
  ({ login }) => login,
);

export const schoolLogoSelector = createSelector(selectorAccountData, ({ school }) => school?.logo);

export const selectSecureExamConfig = createSelector(
  selectorAccountData,
  ({ secureExamSeconds, enableSecureExam }) => ({
    secureExamSeconds,
    enableSecureExam,
  }),
);
