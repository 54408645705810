import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import ErrorView from '../ErrorView';

const chunkFailedMessage = /Loading chunk [\d]+ failed/;

const ErrorFallback: FunctionComponent = ({ error }: any) => {
  const { formatMessage } = useIntl();
  const isChunkLoadError = useMemo(
    () => error?.message && chunkFailedMessage.test(error.message),
    [error],
  );
  useEffect(() => {
    if (isChunkLoadError) {
      window.location.reload();
    }
  }, [isChunkLoadError]);

  return (
    <>
      {!isChunkLoadError && (
        <ErrorView
          heading="oops"
          subheading={formatMessage({
            id: 'error.page.went-wrong',
            defaultMessage: 'Something went wrong!',
          })}
        />
      )}
    </>
  );
};

export default ErrorFallback;
