import { ROUTES } from 'constants/routes';
import { getIntl } from 'utils/intl';
import { MenuItemType } from '../components/Sidebar/types';

export const mentorMenu = (): MenuItemType[] => {
  const { formatMessage } = getIntl();
  return [
    {
      id: 'sidebar__dashboard',
      path: ROUTES.root,
      label: formatMessage({ id: 'dashboard.title', defaultMessage: 'Dashboard' }),
      icon: 'dashboard',
    },
    {
      id: 'sidebar__schedule',
      path: ROUTES.schedule,
      label: formatMessage({ id: 'schedule.title', defaultMessage: 'Schedule' }),
      icon: 'schedule',
    },
    {
      id: 'sidebar__chatgroup',
      path: ROUTES.groupChat,
      label: formatMessage({ id: 'group-chat.title', defaultMessage: 'Chat Group' }),
      icon: 'group-chat',
    },
    {
      id: 'sidebar__content_library',
      path: ROUTES.contentLibrary,
      label: formatMessage({ id: 'content-library.title', defaultMessage: 'Content Library' }),
      icon: 'question-bank',
    },
  ];
};
