import React, { FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from 'lib';
import { useScreenInclude } from 'hooks/useScreenInclude';
import { isStudentRoleSelector } from 'store/account/selectors';
import { loadRewardStatsAction } from 'store/Rewards/actions';
import { rewardStatsSelector } from 'store/Rewards/selectors';

import styles from './Header.module.scss';

const RewardPoints: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isMobile = useScreenInclude(['xs', 'sm']);

  const stats = useSelector(rewardStatsSelector);
  const isStudent = useSelector(isStudentRoleSelector);

  useEffect(() => {
    if (isStudent) dispatch(loadRewardStatsAction.request());
  }, [dispatch, isStudent]);

  return (
    <>
      {isStudent && (
        <div className={styles.topheader__rewardPoint}>
          <span className={styles.topheader__rewardPoint__icon}>
            <Icon type="reward" size={isMobile ? 's20' : 's24'} />
          </span>
          <div className={styles.topheader__rewardPoint__info}>
            <div className={styles.topheader__rewardPoint__label}>
              {formatMessage({
                id: 'rewards.top.nav.total.points',
                defaultMessage: 'Total Points',
              })}
            </div>
            <div className={styles.topheader__rewardPoint__points}>{stats.totalPoints ?? 0}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default RewardPoints;
