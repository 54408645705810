import { createSelector } from 'reselect';

import { RootStateType } from '../types';
import { SystemConfigStateType } from './reducer';
import { SIGNUP_VERSIONS } from '../../constants/common';

const selectorSystemConfig = (state: RootStateType): SystemConfigStateType => state.systemConfig;

export const signupVersionSelector = createSelector(
  selectorSystemConfig,
  (systemConfig) => systemConfig.signupVersion,
);

export const selectIsV1 = createSelector(
  signupVersionSelector,
  ({ value }) => value === SIGNUP_VERSIONS.v1,
);
