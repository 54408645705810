import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchStudentDashboardDataAction,
  fetchTodayScheduleAction,
  fetchOverdueLessonsAction,
  fetchUnitsBySubjectAction,
  fetchAdditionalLessonsAction,
} from './actions';
import {
  fetchAdditionalLessonsAPI,
  fetchOverdueLessonsAPI,
  fetchStudentDashboardDataAPI,
  fetchTodayScheduleAPI,
  fetchUnitsBySubjectAPI,
} from './api';

function* fetchStudentDashboardDataSaga({
  payload,
}: ReturnType<typeof fetchStudentDashboardDataAction.request>): SagaIterator<void> {
  try {
    const response = yield call(fetchStudentDashboardDataAPI, payload);
    yield put(fetchStudentDashboardDataAction.success(response));
  } catch (error) {
    yield put(fetchStudentDashboardDataAction.failure(error));
  }
}

function* fetchTodayScheduleSaga({
  payload,
}: ReturnType<typeof fetchTodayScheduleAction.request>): SagaIterator<void> {
  try {
    const response = yield call(fetchTodayScheduleAPI, payload);
    yield put(fetchTodayScheduleAction.success(response));
  } catch (error) {
    yield put(fetchTodayScheduleAction.failure(error));
  }
}

function* fetchOverdueLessonsSaga({
  payload,
}: ReturnType<typeof fetchOverdueLessonsAction.request>): SagaIterator<void> {
  try {
    const response = yield call(fetchOverdueLessonsAPI, payload);
    yield put(fetchOverdueLessonsAction.success(response));
  } catch (error) {
    yield put(fetchOverdueLessonsAction.failure(error));
  }
}

function* fetchUnitsBySubjectSaga({
  payload,
}: ReturnType<typeof fetchUnitsBySubjectAction.request>): SagaIterator<void> {
  try {
    const response = yield call(fetchUnitsBySubjectAPI, payload);
    yield put(fetchUnitsBySubjectAction.success(response));
  } catch (error) {
    yield put(fetchUnitsBySubjectAction.failure(error));
  }
}

function* fetchAdditionalLessonsSaga({
  payload,
}: ReturnType<typeof fetchAdditionalLessonsAction.request>): SagaIterator<void> {
  try {
    const response = yield call(fetchAdditionalLessonsAPI, payload);
    yield put(fetchAdditionalLessonsAction.success(response));
  } catch (error) {
    yield put(fetchAdditionalLessonsAction.failure(error));
  }
}

function* studentDashboardV2Saga(): SagaIterator {
  yield all([
    takeLatest(fetchStudentDashboardDataAction.request, fetchStudentDashboardDataSaga),
    takeLatest(fetchTodayScheduleAction.request, fetchTodayScheduleSaga),
    takeLatest(fetchOverdueLessonsAction.request, fetchOverdueLessonsSaga),
    takeLatest(fetchUnitsBySubjectAction.request, fetchUnitsBySubjectSaga),
    takeLatest(fetchAdditionalLessonsAction.request, fetchAdditionalLessonsSaga),
  ]);
}

export default studentDashboardV2Saga;
