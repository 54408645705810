import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ButtonTypes, getSvgURL, IconCommon, Loader, NewButton } from 'lib';
import { isArabicMode } from 'utils/helpers';
import { OrientationContext } from 'components/Orientation/OrientationContext';
import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import {
  orientationVideosLoadingSelector,
  orientationVideosSelector,
} from 'store/orientation/selectors';
import { HelpMenuProps, OrientationResponseType } from 'store/orientation/types';

import styles from './Help.module.scss';

const Tutorials = ({
  setOpenMenu,
  closeHelpTour,
}: Pick<HelpMenuProps, 'closeHelpTour' | 'setOpenMenu'>) => {
  const { formatMessage: fm, locale } = useIntl();
  const isArabic = isArabicMode(locale);

  const orientationVideos = useSelector(orientationVideosSelector);
  const isLoading = useSelector(orientationVideosLoadingSelector);

  const isGradeOneToThree = useIsGradeOneToThree();
  const { handleOrientationOpen, triggerOrientationVideo, handleOrientationClose } =
    useContext(OrientationContext);

  const startTutorialHandler = (orientationData: OrientationResponseType, currentIndex: number) => {
    const nextEle = orientationVideos[currentIndex + 1];
    const prevEle = orientationVideos[currentIndex - 1];
    const nextTitle = isArabic ? nextEle?.titleAr : nextEle?.titleEn;
    const preTitle = isArabic ? prevEle?.titleAr : prevEle?.titleEn;

    const actions = [
      {
        ele: prevEle,
        index: currentIndex - 1,
        style: isGradeOneToThree
          ? [ButtonTypes.btnGrade, ButtonTypes.btnGradeSmall, ButtonTypes.btnGradeRed]
          : [ButtonTypes.buttonSecondary],
        defaultTitle: fm({ id: 'tour.skipButton.label', defaultMessage: 'Skip' }),
        title: preTitle,
      },
      {
        ele: nextEle,
        index: currentIndex + 1,
        style: isGradeOneToThree
          ? [ButtonTypes.btnGrade, ButtonTypes.btnGradeSmall, ButtonTypes.btnGradeOrange]
          : [ButtonTypes.buttonPrimary],
        defaultTitle: fm({ id: 'quiz.dialog.incorrect.response', defaultMessage: 'Got it' }),
        title: nextTitle,
      },
    ];

    handleOrientationOpen();
    triggerOrientationVideo({
      data: orientationData,
      renderActionButtons: () =>
        actions.map(({ ele, index, title, style, defaultTitle }) => (
          <NewButton
            key={defaultTitle}
            style={style}
            onClick={() => {
              if (title) startTutorialHandler(ele, index);
              else handleOrientationClose();
            }}
          >
            {title || defaultTitle}
          </NewButton>
        )),
    });
  };

  return (
    <div className={styles.menu__wrap}>
      {isLoading ? (
        <Loader type="static" size="s40" />
      ) : (
        <>
          {orientationVideos?.length
            ? orientationVideos.map((orientationData, index) => {
                const { titleEn, titleAr, featureIcon } = orientationData;
                const title = isArabic ? titleAr : titleEn;
                return (
                  <div className={styles.menu__items} key={titleEn}>
                    <div
                      className={styles.menu__item}
                      onClick={() => {
                        startTutorialHandler(orientationData, index);
                        closeHelpTour?.();
                        setOpenMenu?.();
                      }}
                    >
                      <div className={styles.menu__itemText}>
                        <img
                          src={getSvgURL(featureIcon)}
                          className={styles.menu__icon}
                          alt={title}
                        />
                        {title}
                      </div>
                      <div className={styles.menu__actionLinks}>
                        {fm({
                          id: 'orientation.video.menu.start.tutorial',
                          defaultMessage: 'Start tutorial',
                        })}
                        <IconCommon
                          className={isArabic ? 'icon-chevron-medium' : 'icon-chevron-medium-right'}
                          space="sl10"
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : fm({
                id: 'orientation.video.no.video.available',
                defaultMessage: 'No video available.',
              })}
        </>
      )}
    </div>
  );
};

export default Tutorials;
