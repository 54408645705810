import { createSelector } from 'reselect';

import { RootStateType } from '../types';
import { SupportStateType } from './reducer';

export const selectSupport = (state: RootStateType): SupportStateType => state.support;

export const selectorSupportFetching = createSelector(
  selectSupport,
  ({ fetching }: SupportStateType) => fetching.send,
);
