import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { loadBadgesAction } from './actions';
import { getBadges } from './api';

function* getBadgesRequest(): SagaIterator {
  try {
    const data = yield call(getBadges);
    yield put(loadBadgesAction.success(data));
  } catch (error) {
    yield put(loadBadgesAction.failure(error.message || error));
  }
}

export default function* badgesSaga(): SagaIterator {
  yield takeEvery(loadBadgesAction.request, getBadgesRequest);
}
