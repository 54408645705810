import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isSchoolTeacherSelector, selectorAccountData } from 'store/account/selectors';
import { loadB2BTeacherClassRoomOptions } from 'store/b2bTeacher/actions';
import { selectStudentClassRoomOptions } from 'store/b2bTeacher/selectors';

export const useTeacherTeachesGrade = (gradeValue: number): Boolean => {
  const dispatch = useDispatch();

  const { id } = useSelector(selectorAccountData);
  const isB2BTeacher = useSelector(isSchoolTeacherSelector);
  const grades = useSelector(selectStudentClassRoomOptions);

  const isTeachesGrade = useMemo(() => {
    if (!grades && isB2BTeacher) {
      return true;
    }
    if (grades) {
      const validGradeSections = grades?.filter(({ grade }) => grade.gradeValue === gradeValue);
      return !!validGradeSections?.length;
    }
    return false;
  }, [gradeValue, grades, isB2BTeacher]);

  useEffect(() => {
    if (isB2BTeacher && !grades)
      dispatch(loadB2BTeacherClassRoomOptions.request({ teacherID: id }));
  }, [dispatch, grades, id, isB2BTeacher]);

  return isTeachesGrade;
};
