import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  loadNotificationsAction,
  markNotificationsAction,
  getNotificationsUnreadCountAction,
} from './actions';
import { getNotificationsUnreadCount, getNotifications, markNotifications } from './api';
import { NotificationsReturnType } from './types';

function* loadNotificationsRequest({
  payload,
}: ReturnType<typeof loadNotificationsAction.request>): SagaIterator<void> {
  try {
    const notifications: NotificationsReturnType = yield call(getNotifications, payload?.page);
    payload?.callback?.('success');
    yield put(loadNotificationsAction.success(notifications));
  } catch (error) {
    payload?.callback?.('error');
    yield put(loadNotificationsAction.failure(error));
  }
}

function* markNotificationsRequest({
  payload: { notifications },
}: ReturnType<typeof markNotificationsAction.request>): SagaIterator<void> {
  try {
    yield call(markNotifications, notifications);
    yield put(markNotificationsAction.success());
  } catch (error) {
    yield put(markNotificationsAction.failure(error));
  }
}

function* getNotificationsUnread(): SagaIterator<void> {
  try {
    const response = yield call(getNotificationsUnreadCount);
    yield put(getNotificationsUnreadCountAction.success(response));
  } catch (error) {
    yield put(getNotificationsUnreadCountAction.failure(error));
  }
}

function* localeSaga(): SagaIterator {
  yield all([
    takeEvery(loadNotificationsAction.request, loadNotificationsRequest),
    takeEvery(markNotificationsAction.request, markNotificationsRequest),
    takeEvery(getNotificationsUnreadCountAction.request, getNotificationsUnread),
  ]);
}

export default localeSaga;
