import React, { useState, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { IconCommon } from 'lib/IconCommon/IconCommon';
import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import styles from './UserPassword.module.scss';

type UserPassword = {
  password: string;
};

export const UserPassword: FunctionComponent<UserPassword> = ({ password }) => {
  const { formatMessage } = useIntl();
  const isGradeOneToThree = useIsGradeOneToThree();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={cn(styles.showPassword, { [styles.grade]: isGradeOneToThree })}>
      <span>
        {showPassword
          ? password
          : formatMessage({ id: 'user.view.password', defaultMessage: 'View Password' })}
      </span>
      <IconCommon clickHandler={() => setShowPassword(!showPassword)} className="icon-view" />
    </div>
  );
};

export default UserPassword;
