import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import {
  draftedSubscriptionAPI,
  enrollStudentAPI,
  subscriptionPlansAPI,
  subscriptionsSubjectsServicesAPI,
} from 'utils/apiEndpoints';

import { EnrollStudentPayloadType, Subjects } from './types';
import { transformDraftedSubscription } from './adaptor';

export const fetchSubjects = async ({
  planId,
  gradeId,
  userId,
}: {
  planId: number;
  gradeId: number;
  userId: number;
}) => {
  const response = await API.get(subscriptionsSubjectsServicesAPI(planId, gradeId, userId));
  const subjects: Subjects[] = Object.values(camelcaseKeys(response.data, { deep: true }));
  return subjects.sort((a: Subjects, b: Subjects) => {
    const x = a.isQiyasSubject ? 0 : 1;
    const y = b.isQiyasSubject ? 0 : 1;
    return x - y;
  });
};

export const fetchPlans = async () => {
  const response = await API.get(subscriptionPlansAPI());
  return camelcaseKeys(response.data, { deep: true }).map((dt: any) => ({
    ...dt,
    prices: [90, 30, 30, 40, 40, 40, 35, 35, 35, 30, 30, 30, 25, 25, 25, 25],
    totalLimit: 540,
  }));
};

export const loadCheckoutIdRequest = async (data: EnrollStudentPayloadType) => {
  const response = await API.post(enrollStudentAPI(data.plan || 0), {
    ...data,
    plan: undefined,
    callback: undefined,
  });
  return camelcaseKeys(response.data, { deep: true });
};

export const loadDraftedSubscriptionRequest = async (
  shouldPaidOnly?: boolean,
  shouldUpgradePlan?: boolean,
  userId?: number,
  isStudent?: boolean,
) => {
  const response = await API.get(draftedSubscriptionAPI());
  return transformDraftedSubscription(
    camelcaseKeys(response.data, { deep: true }),
    shouldPaidOnly,
    shouldUpgradePlan,
    userId,
    isStudent,
  );
};
