import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  loadGradesSubjectsAction,
  loadTeachersDetailsAction,
  loadSubjectsTeachersAction,
  assignGradesToTeacherAction,
} from './actions';
import {
  getGradesClassRooms,
  getSubjectsTeachers,
  assignGradeToTeacher,
  getTeachersDetails,
} from './api';

function* loadGradesSubjects({
  payload,
}: ReturnType<typeof loadGradesSubjectsAction.request>): SagaIterator<void> {
  try {
    const gradesSubjects: any = yield call(getGradesClassRooms, payload);
    yield put(loadGradesSubjectsAction.success(gradesSubjects));
  } catch (error) {
    yield put(loadGradesSubjectsAction.failure(error));
  }
}

function* loadSubjectsTeachers({
  payload,
}: ReturnType<typeof loadSubjectsTeachersAction.request>): SagaIterator<void> {
  try {
    const gradesClassRooms: any = yield call(getSubjectsTeachers, payload);
    yield put(loadSubjectsTeachersAction.success(gradesClassRooms));
  } catch (error) {
    yield put(loadSubjectsTeachersAction.failure(error));
  }
}

function* assignGradesToTeacher({
  payload,
}: ReturnType<typeof assignGradesToTeacherAction.request>): SagaIterator<void> {
  try {
    const assignGradesTeacher: any = yield call(assignGradeToTeacher, payload);
    yield put(assignGradesToTeacherAction.success(assignGradesTeacher));
    payload.callback?.(false, assignGradesTeacher?.message);
  } catch (error) {
    payload.callback?.(true, error?.error);
    yield put(assignGradesToTeacherAction.failure(error));
  }
}

function* assignGetTeachersDetails({
  payload,
}: ReturnType<typeof loadTeachersDetailsAction.request>): SagaIterator<void> {
  try {
    const assignGradesTeacher: any = yield call(getTeachersDetails, payload);
    yield put(loadTeachersDetailsAction.success(assignGradesTeacher));
  } catch (error) {
    yield put(loadTeachersDetailsAction.failure(error));
  }
}

function* assignTeachersGradesSaga(): SagaIterator {
  yield all([takeEvery(loadGradesSubjectsAction.request, loadGradesSubjects)]);
  yield all([takeEvery(loadSubjectsTeachersAction.request, loadSubjectsTeachers)]);
  yield all([takeEvery(assignGradesToTeacherAction.request, assignGradesToTeacher)]);
  yield all([takeEvery(loadTeachersDetailsAction.request, assignGetTeachersDetails)]);
}

export default assignTeachersGradesSaga;
