import camelcaseKeys from 'camelcase-keys';
import API from '../../utils/api';
import { teacherOptionsAPI, addTeacherAPI } from '../../utils/apiEndpoints';
import { AddNewTeacherType, UpdateTeacherType } from './types';

export async function addTeacher(data: AddNewTeacherType): Promise<AddNewTeacherType> {
  const {
    payload: {
      profileImage,
      lastName,
      phoneNumber,
      email,
      firstName,
      dateOfBirth,
      confirmPassword,
      gender,
      address,
      password,
      subjects,
      title,
      grades,
    },
    branch,
  } = data;

  const formData = new FormData();
  if (profileImage) formData.append('profile_image', profileImage);
  formData.append('last_name', lastName);
  formData.append('phone_number', phoneNumber);
  formData.append('email', email);
  formData.append('first_name', firstName);
  formData.append('date_of_birth', dateOfBirth || '');
  formData.append('confirm_password', confirmPassword);
  formData.append('gender', gender);
  formData.append('address', address);
  formData.append('password', password);
  formData.append('subjects', JSON.stringify(subjects));
  formData.append('title', title);
  formData.append('grades', JSON.stringify(grades));
  const response = await API.post(addTeacherAPI(branch), formData);

  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeacherOptions(branchID: any): Promise<UpdateTeacherType> {
  const response = await API.get(teacherOptionsAPI(branchID));

  return camelcaseKeys(response.data, { deep: true });
}
