export const colors = [
  '34,91,119',
  '123,87,177',
  '17,136,45',
  '162,40,21',
  '160,41,179',
  '7,100,52',
  '59,120,181',
  '138,105,155',
  '185,92,34',
  '146,111,7',
  '133,59,131',
  '85,13,119',
  '122,118,0',
  '54,127,129',
  '118,90,76',
  '14, 165, 233',
  '109, 40, 217',
  '60, 42, 85',
  '15, 76, 81',
  '106, 36, 54',
  '47, 135, 57',
  '71, 86, 72',
  '10, 102, 140',
  '61, 0, 63',
  '89, 61, 39',
  '255, 124, 20',
  '20, 18, 107',
  '107, 135, 71',
  '68, 95, 103',
  '22, 111, 93',
];

export const defaultSlotColor = 'silver';
export const unPublishTextColor = '#344055';
export const publishTextColor = '#fff';
