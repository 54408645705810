import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { AxiosResponse } from 'axios';

import {
  fetchNativeEventGradeSubjectsAPI,
  fetchNativeEventQuestions,
  fetchLessonEventsAPI,
  deleteMainQuestionAPI,
  deleteSubQuestionAPI,
  fetchLearningObjectiveAPI,
  uploadContentAPI,
  deleteContentAPI,
  discardChangesAPI,
  createQuestionsAPI,
  editSubQuestionAPI,
} from 'utils/apiEndpoints';
import API from 'utils/api';

import {
  GradeSubjects,
  LessonQuestionsResponse,
  LessonEvent,
  FetchQuestionsPayload,
  DeleteMainQuestionPayload,
  DeleteSubQuestionPayload,
  UploadContentPayload,
  DeleteContentPayload,
  DiscardChangesPayload,
  CreationQuestionsResponse,
  CreateQuestionsPayload,
  EditMainQuestionPayload,
} from './types';

export async function fetchGradeSubjects(gradeId: number): Promise<GradeSubjects> {
  const response = await API.get(fetchNativeEventGradeSubjectsAPI, {
    params: snakecaseKeys({ gradeId }),
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchQuestions({
  lessonId,
  pageNo,
  pageSize,
}: Omit<FetchQuestionsPayload, 'isPreview'>): Promise<LessonQuestionsResponse> {
  const response = await API.get(fetchNativeEventQuestions(lessonId), {
    params: {
      page: pageNo,
      page_size: pageSize,
    },
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchLessonEvents(lessonUsageKey: string): Promise<LessonEvent> {
  const response = await API.get(fetchLessonEventsAPI(lessonUsageKey));

  return camelcaseKeys(response.data, { deep: true });
}

export async function deleteMainQuestion({
  eventId,
  questionGroupId,
}: DeleteMainQuestionPayload): Promise<LessonEvent> {
  const response = await API.delete(deleteMainQuestionAPI(eventId, questionGroupId));

  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchLearningObjectives(lessonKey: string) {
  const response = await API.get(fetchLearningObjectiveAPI(), {
    params: snakecaseKeys({ lessonKey }),
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function createEditQuestions(
  payload: CreateQuestionsPayload,
): Promise<CreationQuestionsResponse> {
  let response = {} as AxiosResponse<CreationQuestionsResponse>;
  const { questionGroupId, eventKey, ...restPayload } = payload;
  if (questionGroupId) {
    response = await API.patch(
      editSubQuestionAPI(eventKey, questionGroupId),
      snakecaseKeys(restPayload),
    );
  } else {
    response = await API.post(createQuestionsAPI(eventKey), snakecaseKeys(restPayload));
  }

  return camelcaseKeys(response.data, { deep: true });
}

export async function deleteSubQuestion({
  questionId,
}: DeleteSubQuestionPayload): Promise<LessonEvent> {
  const response = await API.delete(deleteSubQuestionAPI(questionId));

  return camelcaseKeys(response.data, { deep: true });
}

export async function uploadContent({ content }: UploadContentPayload) {
  const formData = new FormData();
  formData.append('content', content);

  const response = await API.post(uploadContentAPI(), formData);

  return camelcaseKeys(response.data, { deep: true });
}

export async function deleteContent({ contentUrl }: DeleteContentPayload) {
  const formData = new FormData();
  formData.append('content_url', contentUrl);

  const response = await API.delete(deleteContentAPI(), { data: formData });

  return camelcaseKeys(response.data, { deep: true });
}

export async function discardChanges({
  action,
  durationInMinutes,
  eventId,
}: DiscardChangesPayload) {
  const response = await API.post(
    discardChangesAPI(eventId),
    snakecaseKeys({ action, durationInMinutes }),
  );

  return camelcaseKeys(response.data, { deep: true });
}

export async function editMainQuestion({
  eventKey,
  questionGroupId,
  ...payload
}: EditMainQuestionPayload): Promise<CreationQuestionsResponse> {
  const response = await API.patch(
    deleteMainQuestionAPI(eventKey, questionGroupId),
    snakecaseKeys(payload),
  );

  return camelcaseKeys(response.data, { deep: true });
}
