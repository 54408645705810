import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { AccountStateType } from '../account/reducer';
import { selectorAccount } from '../account/selectors';
import { loadSSOAccountsAction, unlinkSSOAccountAction } from './actions';
import { getSSOAccounts, unlinkSSOAccount } from './api';
import { SSOAccountType } from './types';

function* loadSSOAccountsRequest(): SagaIterator<void> {
  try {
    const {
      data: { username },
    }: AccountStateType = yield select(selectorAccount);
    const ssoAccounts: SSOAccountType[] = yield call(getSSOAccounts, username);
    yield put(loadSSOAccountsAction.success(ssoAccounts));
  } catch (error) {
    yield put(loadSSOAccountsAction.failure(error));
  }
}

function* unlinkSSOAccountRequest(
  action: ReturnType<typeof unlinkSSOAccountAction.request>,
): SagaIterator<void> {
  try {
    const {
      data: { username },
    }: AccountStateType = yield select(selectorAccount);
    yield call(unlinkSSOAccount, action.payload);
    const ssoAccounts: SSOAccountType[] = yield call(getSSOAccounts, username);
    yield put(loadSSOAccountsAction.success(ssoAccounts));
    yield put(unlinkSSOAccountAction.success());
  } catch (error) {
    yield put(unlinkSSOAccountAction.failure(error));
  }
}

function* ssoSaga(): SagaIterator {
  yield all([
    takeEvery(loadSSOAccountsAction.request, loadSSOAccountsRequest),
    takeEvery(unlinkSSOAccountAction.request, unlinkSSOAccountRequest),
  ]);
}

export default ssoSaga;
