import { createReducer, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { TourErrorsType, TourType } from './types';
import { loadTourAction, resetTourAction } from './actions';
import * as actions from './actions';

const initialState = {
  id: 0,
  titleEn: '',
  titleAr: '',
  detailEn: '',
  detailAr: '',
  page: '',
  userType: '',
  isWatched: false,
  isWatchedInAllowedHours: false,
  isActive: false,
  steps: [],
};

export type TourActionType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, TourActionType>(true)
  .handleAction([loadTourAction.request, resetTourAction], () => true)
  .handleAction([loadTourAction.success, loadTourAction.failure], () => false);

const data = createReducer<TourType, TourActionType>(initialState)
  .handleAction(loadTourAction.success, (state, action) => action.payload)
  .handleAction(loadTourAction.failure, () => initialState)
  .handleAction(resetTourAction, () => initialState);

const errors = createReducer<TourErrorsType, TourActionType>({})
  .handleAction(loadTourAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(loadTourAction.success, (state) => ({
    ...state,
    error: '',
  }))
  .handleAction(resetTourAction, () => ({}));

const semesterReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export default semesterReducer;
