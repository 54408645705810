import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import { getGradesAPI, getGradeStudentsAPI } from 'utils/apiEndpoints';

import { GradeType } from './types';

export async function getGrades(queryString?: string): Promise<Array<GradeType>> {
  const response = await API.get(getGradesAPI(queryString));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getGradeStudents(gradeId: number): Promise<Array<any>> {
  const response = await API.get(getGradeStudentsAPI(gradeId));
  return camelcaseKeys(response.data, { deep: true });
}
