import { createAsyncAction, createAction } from 'typesafe-actions';
import { AssignedSubjectType, SemesterSubjectType } from './types';

export const loadAssignedSubjectsAction = createAsyncAction(
  '@@assignedSubjects/LOAD_REQUEST',
  '@@assignedSubjects/LOAD_SUCCESS',
  '@@assignedSubjects/LOAD_FAILURE',
)<string, SemesterSubjectType[], string>();

export const addAssignedSubjectsAction = createAsyncAction(
  '@@assignedSubjects/ADD_REQUEST',
  '@@assignedSubjects/ADD_SUCCESS',
  '@@assignedSubjects/ADD_FAILURE',
)<Partial<AssignedSubjectType>[], AssignedSubjectType[], string>();

export const resetAssignedSubjectsAction = createAction('@@assignedSubjects/RESET')();
