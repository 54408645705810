import { createAction, createAsyncAction } from 'typesafe-actions';

import { TFetchQuestionsPayload, TQiyasQuestion, TQiyasExam } from './types';

export const fetchQiyasExamDetailAction = createAsyncAction(
  '@@qiyas/FETCH_EXAM_DETAIL_REQUEST',
  '@@qiyas/FETCH_EXAM_DETAIL_SUCCESS',
  '@@qiyas/FETCH_EXAM_DETAIL_FAILURE',
)<number, TQiyasExam, string>();

export const updateQiyasExamDetailAction = createAction('@@qiyas/UPDATE_EXAM_DETAIL')<
  Pick<TQiyasExam, 'title'>
>();

export const fetchQuestionsByExamAction = createAsyncAction(
  '@@qiyas/FETCH_QUESTIONS_REQUEST',
  '@@qiyas/FETCH_QUESTIONS_SUCCESS',
  '@@qiyas/FETCH_QUESTIONS_FAILURE',
)<TFetchQuestionsPayload, TQiyasQuestion[], string>();

export const updateQuestionsAction = createAction('@@qiyas/UPDATE_QUESTIONS')<
  TQiyasQuestion & { mode: 'create' | 'update' | 'delete' }
>();

export const handleTemporaryQuestionAction = createAction('@@qiyas/HANDLE_TEMPORARY_QUESTION')<
  TQiyasQuestion & { mode: 'create' | 'update' | 'delete' }
>();

export const clearQiyasExamAction = createAction('@@qiyas/CLEAR_EXAM_DETAILS')();

export const setMaxQuestionsLimitAction = createAction('@@qiyas/SET_MAX_QUESTIONS_LIMIT')<number>();
