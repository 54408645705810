import { createContext } from 'react';

import { DashboardTypes } from 'constants/entities';
import { ENABLE_SMART_SWITCHER } from 'constants/settings';

type DashboardContextType = {
  smartSwitcherEnabled: boolean;
  dashboardTab: DashboardTypes;
  setDashboardTab: (val: DashboardTypes) => void;
};

export const DashboardContext = createContext<DashboardContextType>({
  smartSwitcherEnabled: ENABLE_SMART_SWITCHER,
  dashboardTab: DashboardTypes.SmartView,
  setDashboardTab: () => {},
});
