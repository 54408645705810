export enum TextTypes {
  title = 'title',
  heading1 = 'heading1',
  heading1Center = 'heading1Center',
  heading2 = 'heading2',
  heading3 = 'heading3',
  heading4 = 'heading4',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
  overline = 'overline',
  caption1 = 'caption1',
  caption2 = 'caption2',
  body1 = 'body1',
  body2 = 'body2',
}

export enum TextAlignments {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum TextColors {
  primary = 'primary',
  secondary = 'secondary',
  dark = 'dark',
  light = 'light',
  info = 'info',
  muted = 'muted',
  error = 'error',
  pending = 'pending',
  success = 'success',
  active = 'active',
  black = 'black',
  white = 'white',
}
