import {
  MIN_DURATION_1_TO_1,
  MIN_DURATION_1_TO_MANY,
  MIN_DURATION_LIVE_CLASS,
  PersistedStateKeys,
  REDIRECT_LOGIN_URL,
} from 'constants/entities';
import { APP_API_URL, LANDING_URL_AR, LANDING_URL_EN } from 'constants/settings';
import { classes, CreateSearchParamsType, ScheduleType } from 'store/schedule/types';

import { GroupType, mesiboSessionUserGroupType, Participants } from 'store/groupChat/types';
import { teacherGradesData } from 'store/reports/types';
import { SemesterType } from 'store/semesters/types';
import { StudentQuestionType } from 'store/qiyas/types';

import { ROUTES } from 'constants/routes';

import {
  ScheduleDuration,
  ScheduleTimeType,
} from 'components/Schedule/ScheduleMeeting/ScheduleMeeting.types';

import { getIntl } from './intl';
import { Direction, Lang, LOCALES } from '../lang';
import { diff, formatDate as formatDateUtil, subtractTime } from './date-time';

export type Duration = {
  start_date: string;
  end_date: string;
};

export type MinMax = {
  min: number;
  max: number;
};

export type FormatLocalDateType = {
  date: Date | string;
  formatDate: Function;
  yearType?: 'numeric' | '2-digit';
};

export type OptionType = {
  value: number;
  label: string;
};

export const VIDEO_FILE_TYPE = 'video/mp4';
export const ACCEPTED_FILE_TYPES = [
  'video/mp4',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const phoneMask = '...............';

export const getISOStringWithoutMilliSeconds = (date: Date) =>
  `${date.toISOString().split('.')[0]}Z`;

export const getLocalizedData = (
  locale: string,
  attr: { label: { en: string; ar: string } } | undefined,
): string => {
  if (!attr?.label) return '';

  const { label } = attr;
  return locale.includes('ar') ? label.ar : label.en;
};

export const containsLatex = (str: string) => {
  // Regular expression patterns to match LaTeX syntax
  const inlineMathPattern = /\$[^$]*\$/; // matches $...$
  const displayMathPattern = /\\\[[^\]]*\\\]/; // matches \[...\]
  const blockMathPattern = /\$\$[^$]*\$\$/; // matches $$...$$
  const latexCommandPattern = /\\[a-zA-Z]+/; // matches \command
  const mathEnvPattern = /\\begin{.*?}.*?\\end{.*?}/s;

  const superscriptPattern = /[a-zA-Z0-9]\^[^{][^ ]*/; // matches a^b
  const subscriptPattern = /[a-zA-Z0-9]_[^{][^ ]*/; // matches a_b

  // Check if any pattern matches the input string
  return (
    inlineMathPattern.test(str) ||
    displayMathPattern.test(str) ||
    blockMathPattern.test(str) ||
    latexCommandPattern.test(str) ||
    mathEnvPattern.test(str) ||
    superscriptPattern.test(str) ||
    subscriptPattern.test(str)
  );
};

export const getPlainPhoneNumber = (phone: string) =>
  phone[0] === '+' ? phone.substring(1) : phone;

export const getEmailOrPlainPhoneNumber = (field: string) =>
  /^[+]?\d+$/.test(field) ? getPlainPhoneNumber(field) : field;

export const addTimeToDate = ({ hour, minute }: ScheduleTimeType, startDate: Date) =>
  new Date(startDate.setHours(hour, minute));

export const isValidEmailFormat = (email: string) => /@/.test(email) && !/.+@.+\..+/i.test(email);

export const apiErrorMessage = (
  response: any,
  errorMessageArray: Array<string> = [],
): Array<any> => {
  if (response && typeof response === 'string') {
    if (response === 'server' || response === 'notFound' || response === 'network') {
      errorMessageArray.push('defaultError');

      return errorMessageArray;
    }

    errorMessageArray.push(response);
    return errorMessageArray;
  }

  const keys = Object.keys(response);
  if (keys) {
    keys.forEach((key) => apiErrorMessage(response[key], errorMessageArray));
    return errorMessageArray;
  }

  return errorMessageArray;
};

export const formatServerErrors = (errors?: string | string[]) => {
  const { formatMessage } = getIntl();

  const errorsMessages = apiErrorMessage(errors);
  return errorsMessages.map((errorMessage: string) => {
    if (errorMessage === 'defaultError')
      return formatMessage({
        id: 'assignments.form.submit.error',
        defaultMessage: 'Something went wrong!',
      });
    return errorMessage;
  });
};

export const isSemesterActive = (semesters: SemesterType[]) => {
  return !!semesters.filter((semester: SemesterType) => {
    const currentDate = new Date();
    const startDate = new Date(semester.startDate);
    const endDate = new Date(semester.endDate);

    return currentDate >= startDate && currentDate <= endDate;
  });
};

export const groupedQuestionsSubjectWise = (
  questions: StudentQuestionType[],
): Record<string, StudentQuestionType[]> =>
  questions.reduce((result, item, index) => {
    const { subjectTitle } = item;
    if (!result[subjectTitle]) {
      // eslint-disable-next-line no-param-reassign
      result[subjectTitle] = [];
    }
    result[subjectTitle].push({ ...item, index: index + 1 });
    return result;
  }, {} as Record<string, StudentQuestionType[]>);

export const getNextWeekStartDate = () => {
  const today = new Date();
  const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000); // Add 7 days

  // Calculate the start date of the next week
  nextWeek.setDate(nextWeek.getDate() - nextWeek.getDay() + 1); // Set to Monday

  return new Date(nextWeek.getFullYear(), nextWeek.getMonth(), nextWeek.getDate());
};

export const getNextSemesterStartDate = (semesters: SemesterType[]) => {
  const dates = semesters.map((semester) => new Date(semester.startDate));

  const futureDates = dates.filter((date) => date > new Date());

  let smallestFutureDate = futureDates[0];
  futureDates.forEach((date) => {
    if (date < smallestFutureDate) {
      smallestFutureDate = date;
    }
  });
  return smallestFutureDate || getNextWeekStartDate();
};

export const scrolltoTopOfPage = () => window.scrollTo(0, 0);

export const countryCodeList = (countryList: any): any => {
  return countryList?.map(({ isoCode }: any) => isoCode.toLowerCase());
};

export const countryCodeLength = (countryCode: string, countryList: any): any => {
  const validLengths = countryList?.reduce((acc: any, country: any) => {
    return {
      ...acc,
      [country.code]:
        country.minLimit === country.maxLimit
          ? [country.minLimit]
          : [country.minLimit, country.maxLimit],
    };
  }, {});

  return validLengths?.[countryCode] || [];
};

export const getCountryIsoCode = (countriesList: any, code: any): string => {
  const [filteredCountry = {}] = countriesList.filter((country: any): any => {
    return country.code === parseInt(code, 10);
  });
  return filteredCountry?.isoCode;
};

export const isValidPhoneNumber = (
  phoneNumber: string,
  countryCode: string,
  countryList: any,
): boolean =>
  phoneNumber &&
  countryCodeLength(countryCode, countryList).includes((countryCode + phoneNumber).length);

export const validNumberMinMaxRange = (countryCode = '966', countryList: any): MinMax => {
  const validLengths: Array<number> = countryCodeLength(countryCode, countryList);

  return {
    min: validLengths[0],
    max: validLengths[validLengths.length - 1] + 2,
  };
};

export const convertToPercentage = (score: number, totalScore: number) => {
  return +((score / totalScore) * 100).toFixed();
};

export const getValidPhoneLength = (countryCode: string, lan: string, countryList: any): string => {
  const separator = lan.includes('en') ? ' or ' : ' أو ';

  return countryCodeLength(countryCode, countryList)
    .map((len: number) => len)
    .join(separator);
};

export const getRedirectionUrl = (): string => {
  const redirectPage = localStorage.getItem('redirectLoginURL');
  return redirectPage?.includes('management') ? ROUTES.managementLogin : ROUTES.login;
};

export const arabicSpeechToTextCorrection = (value: string | undefined): any => {
  if (value) {
    const words = value.split(' ').filter(Boolean);
    return words
      .map((word) => {
        const lastLetter = word.slice(-1) ?? '';
        if (lastLetter === 'ه') {
          return `${word.slice(0, -1)}ة`;
        }
        return word;
      })
      .join(' ');
  }
  return '';
};

export const setRedirectionUrl = (page: string): void => {
  localStorage.setItem(REDIRECT_LOGIN_URL, page);
};

export const dateDifference = (date: Date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  return Math.round(Math.abs((+date - +new Date()) / oneDay));
};

const subtractDate = (
  duration = 0,
  durationType: 'years' | 'months' | 'weeks' | 'days',
  dateFormat?: string,
) => formatDateUtil(subtractTime(new Date(), { [durationType]: duration }), dateFormat);

const calculateDuration = (semesterStartDate: string | undefined, days: number): Duration => {
  let startDate: string = subtractDate(days, 'days');
  if (semesterStartDate && startDate < semesterStartDate) {
    startDate = semesterStartDate;
  }

  return {
    start_date: startDate,
    end_date: formatDateUtil(new Date()),
  };
};

export const getCurrentSemester = (semesterStartDate: string | undefined): Duration => {
  const startDate: string = semesterStartDate || subtractDate(14, 'weeks');

  return {
    start_date: startDate,
    end_date: formatDateUtil(new Date()),
  };
};

export const getSelectedDuration = (
  statusType: string,
  semesterStartDate: string | undefined,
): Duration => {
  if (statusType === 'w') {
    return calculateDuration(semesterStartDate, 7);
  }
  if (statusType === 'm') {
    return calculateDuration(semesterStartDate, 30);
  }
  return getCurrentSemester(semesterStartDate);
};

export const getAbbreviation = (input: string): string | undefined =>
  input.match(/\b([A-Z]|[a-z])/g)?.join('');

export const getSearchParams = (
  searchquery: string,
  properties: Array<{ property: string; value?: string | null }>,
): string => {
  const searchParams = new URLSearchParams(searchquery);
  properties.forEach(({ property, value }) => {
    value ? searchParams.set(property, value) : searchParams.delete(property);
  });
  return searchParams.toString();
};

export const getScheduleDurations = (isSchoolRole: boolean) => {
  let minutes: Array<ScheduleDuration> = [
    { value: 30, type: 'minute' },
    { value: 45, type: 'minute' },
    { value: 60, type: 'minute' },
  ];
  if (isSchoolRole) {
    minutes = minutes.concat([
      { value: 2, type: 'hour' },
      { value: 3, type: 'hour' },
    ]);
  }
  return minutes;
};

export const isTimePassed = (endTime: Date) => Number(Date.now()) > Number(new Date(endTime));

export const getValidTimeSlots = (
  duration: ScheduleDuration,
  slots: Array<ScheduleTimeType>,
): Array<ScheduleTimeType> => {
  if (duration?.value === 2) {
    // For 2hr, All slots till 19(7'O Clock)
    // slot.hour < 19 ====> give slots less than 7:00
    // (slot.hour === 19 && slot.minute === 0) will add 7:00 to remaining slots
    return slots.filter((slot) => slot.hour < 19 || (slot.hour === 19 && slot.minute === 0));
  }
  if (duration?.value === 3) {
    // For 3hr, All slots till 18(6'O Clock)
    // slot.hour < 18 ====> give slots less than 6:00
    // (slot.hour === 18 && slot.minute === 0) will add 6:00 to remaining slots
    return slots.filter((slot) => slot.hour < 18 || (slot.hour === 18 && slot.minute === 0));
  }
  if (duration?.value === 60) {
    // For 1hr, All slots till 20(8'O Clock)
    // slot.hour < 20 ====> give slots less than 8:00
    // (slot.hour === 20 && slot.minute === 0) will add 8:00 to remaining slots
    return slots.filter((slot) => slot.hour < 20 || (slot.hour === 20 && slot.minute === 0));
  }
  if (duration?.value === 45) {
    // For 45 Mins, All slots till 20(8:15' Clock)
    // slot.hour < 20 ====> give slots less than 8:00
    // (slot.hour === 20 && slot.minute === 0) will add 8:00 to 8:15 slots
    return slots.filter((slot) => slot.hour <= 20 || (slot.hour === 20 && slot.minute <= 15));
  }
  if (duration?.value === 30) {
    // For 30 Mins, All slots till 20:20(8:20'O Clock)
    // slot.hour < 20 ====> give slots less than 8:00
    // (slot.hour === 20 && slot.minute <= 30) will add 8:00 to 8:30 slots
    return slots.filter((slot) => slot.hour < 20 || (slot.hour === 20 && slot.minute <= 30));
  }
  return slots;
};

export const getMinimumDuration = (meetingType: ScheduleType): number => {
  switch (meetingType) {
    case ScheduleType.OneOnOne:
      return MIN_DURATION_1_TO_1;
    case ScheduleType.OneToMany:
      return MIN_DURATION_1_TO_MANY;
    case ScheduleType.LiveClass:
      return MIN_DURATION_LIVE_CLASS;
    default:
      break;
  }
  return MIN_DURATION_LIVE_CLASS;
};

export const currentDayTime = (hours: number, mins: number, secs: number): Date => {
  const currentDay = new Date();
  currentDay.setHours(hours);
  currentDay.setMinutes(mins);
  currentDay.setSeconds(secs);
  return currentDay;
};

export const getTimeSlots = (
  meetingType: ScheduleType,
  date: string,
  duration?: number,
  finishHours = 22,
) => {
  const selectedDate = new Date(date);
  const today = new Date();
  let startHours = 7;
  const modifiedDate = new Date(date);
  modifiedDate.setHours(startHours);

  if (date && diff(modifiedDate, today, 'ms') < 0) {
    startHours = new Date().getHours();
  }

  const stepInMinutes: number = duration || getMinimumDuration(meetingType);
  const countMinutesInHour = 60;
  const countInHour = countMinutesInHour / stepInMinutes;
  const years = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDay();
  const times: Array<ScheduleTimeType> = [];
  const slotsLen = Math.floor(finishHours * countInHour - startHours * countInHour);
  if (slotsLen < 0) return [];

  Array(slotsLen)
    .fill(0)
    .forEach((_, i) => {
      const hour = Math.floor(startHours + i / countInHour);
      const minute = Math.round(countMinutesInHour * ((i / countInHour) % 1));

      selectedDate.setHours(hour);
      selectedDate.setMinutes(minute);
      const isFuture = diff(selectedDate, today, 'mins') >= stepInMinutes;

      if (isFuture) {
        times.push({
          value: i + 1,
          labelValue: new Date(years, month, day, hour, minute),
          hour,
          minute,
        });
      }
    });

  return times;
};

export const isDateInFuture = (dateString: string) => {
  const date = new Date(dateString);
  const currentDate = new Date();
  return date > currentDate;
};

export const downloadFileWithLink = (href: string) => {
  const link = document.createElement('a');
  const name = decodeURI(href?.split('/').pop() || '');
  link.setAttribute('download', name);
  link.href = href;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const isArabicMode = (locale: string) =>
  LOCALES[locale as Lang]?.direction === Direction.rtl;

export const getKeyUrl = (isTeacher: boolean, branchID: number): string => {
  const role = isTeacher ? 'teacher' : 'student';
  return `${APP_API_URL}/api/school_system/branches/${branchID}/bulk-upload-helping-keys/${role}/download/`;
};

export const isSafariBrowser = (): boolean => {
  return (
    /apple/i.test(navigator.vendor) &&
    !/crios/i.test(navigator.userAgent) &&
    !/fxios/i.test(navigator.userAgent) &&
    !/Opera|OPT\//.test(navigator.userAgent)
  );
};

export const isIpad = (): boolean => {
  return (
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
    navigator.platform === 'iPad'
  );
};

export const convertHoursToTime = (num: number): { hours: number; minutes: number } => {
  const numInHours = num * 60;
  const hours = Math.floor(numInHours / 60);
  const minutes = Math.floor(numInHours % 60);

  return { hours, minutes };
};

const getLabel = (type: string, value: number) => {
  const { formatMessage } = getIntl();

  const labels: Record<string, string> = {
    hours: formatMessage({ id: 'reports.live.sessions.hours', defaultMessage: 'hours' }),
    mins: formatMessage({ id: 'reports.live.sessions.minutes', defaultMessage: 'minutes' }),
    secs: formatMessage({ id: 'reports.live.sessions.seconds', defaultMessage: 'seconds' }),
  };
  const labelSingular: Record<string, string> = {
    hours: formatMessage({ id: 'reports.live.sessions.hour', defaultMessage: 'hour' }),
    mins: formatMessage({ id: 'reports.live.sessions.minute', defaultMessage: 'minute' }),
    secs: formatMessage({ id: 'reports.live.sessions.second', defaultMessage: 'second' }),
  };
  return value === 1 ? labelSingular[type] : labels[type];
};

export const convertSecondsToDuration = (seconds: number) => {
  const { formatMessage } = getIntl();

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60) % 60;
  const secs = seconds % 60;

  const hoursMins = [
    { value: hours, type: 'hours' },
    { value: minutes, type: 'mins' },
    { value: secs, type: 'secs' },
  ]
    .filter((v) => v.value)
    .map((filtered) => {
      return `${filtered.value} ${getLabel(filtered.type, filtered.value)}`;
    });

  return hoursMins.length > 0
    ? hoursMins.join(' ')
    : `0 ${formatMessage({ id: 'reports.live.sessions.minutes', defaultMessage: 'minutes' })}`;
};

export const getSessionType = (type: string): number | undefined => {
  if (type.includes('Live')) return ScheduleType.LiveClass;
  if (type.includes('oneToOne')) return ScheduleType.OneOnOne;
  if (type.includes('oneToMany')) return ScheduleType.OneToMany;
  return undefined;
};

export const sessionDurationInMins = (startDate: string, endDate: string) =>
  `${diff(endDate, startDate, 'mins')}`;

export const formatDurationTime = (mins: number): string => {
  const { formatMessage } = getIntl();

  const time = convertHoursToTime(mins / 60);
  if (time.hours && time.minutes) {
    return formatMessage(
      {
        id: 'live.session.hours.min',
        defaultMessage: '{hour} hr(s) {mins} min(s)',
      },
      {
        hour: time.hours,
        mins: time.minutes,
      },
    );
  }
  if (time.hours) {
    return formatMessage(
      {
        id: 'live.session.hours',
        defaultMessage: '{hour} hr(s)',
      },
      {
        hour: time.hours,
      },
    );
  }
  return formatMessage(
    {
      id: 'live.session.min',
      defaultMessage: '{mins} min(s)',
    },
    {
      mins: time.minutes,
    },
  );
};

export const isSubjectsInLimit = (
  selectedSubjects: number,
  maximumAllowedSubjects: number,
  minimumAllowedSubjects: number,
) => {
  return selectedSubjects <= maximumAllowedSubjects && selectedSubjects >= minimumAllowedSubjects;
};

export const iSubjectsLessThanMinimumLimit = (
  selectedSubjects: number,
  minimumAllowedSubjects: number,
) => {
  return selectedSubjects < minimumAllowedSubjects;
};

export const classLabels = (classes: classes[]) => {
  return classes
    .filter(Boolean)
    .map((cls) => cls.name)
    .join(',');
};

export const getRedirectUrl = (paramsString: string) => {
  const searchParams = new URLSearchParams(paramsString);

  const redirectUrl = searchParams.get('redirect_url') || '/';
  searchParams.delete('redirect_url');

  const allParams = Object.fromEntries(searchParams.entries());

  const params = new URLSearchParams(allParams);

  return {
    pathname: redirectUrl,
    search: decodeURIComponent(params.toString()),
  };
};

export const addMesiboUserInPeerDetail = (
  peer?: Participants[],
  users?: mesiboSessionUserGroupType[],
  groups?: GroupType[],
): any => {
  const chatWindows: any = peer ?? groups;
  return chatWindows
    ?.map((item: any) => {
      return {
        ...item,
        ...users?.find((user: any) => user.user === item.userId || user.user === item.groupId),
      };
    })
    .sort((a: any, b: any) => (a.unreadCount < b.unreadCount ? 1 : -1));
};

export const formatLocalDate = (props: FormatLocalDateType) => {
  const { formatDate, date, yearType = 'numeric' } = props;
  return formatDate(date, {
    year: yearType,
    month: 'short',
    day: 'numeric',
  });
};

export const translateSystemError = (error: string): string => {
  const { formatMessage } = getIntl();

  if (error === 'network') {
    return formatMessage({ id: 'api.error.network', defaultMessage: 'network' });
  }
  if (error === 'server') {
    return formatMessage({ id: 'api.error.server', defaultMessage: 'server' });
  }
  return error;
};

export const createSearchParams = (paramObject: CreateSearchParamsType): string => {
  const searchQuery = new URLSearchParams();
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(paramObject)) {
    searchQuery.set(key, value || '');
  }
  return searchQuery.toString();
};

export const refreshMesiboListeners = () => {
  const old_element: any = document.getElementById('send-message');
  const new_element: any = old_element?.cloneNode(true);
  old_element?.parentNode?.replaceChild(new_element, old_element);
  const old_attachment: any = document.getElementById('send-attachment');
  const new_attachment: any = old_attachment?.cloneNode(true);
  old_attachment?.parentNode?.replaceChild(new_attachment, old_attachment);
};

export const persistState = (stateKey: keyof typeof PersistedStateKeys, state: any): void => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem(stateKey, serializedState);
};

export const loadPersistedState = (stateKey: keyof typeof PersistedStateKeys): any => {
  try {
    const serializedState = localStorage.getItem(stateKey);

    if (serializedState) {
      return JSON.parse(serializedState);
    }

    return {};
  } catch {
    return {};
  }
};

export const removePresistState = (stateKey: keyof typeof PersistedStateKeys): void => {
  localStorage.removeItem(stateKey);
};

export const sortGrades = (grades: teacherGradesData[]): teacherGradesData[] => {
  return grades.sort((firstGrade, secondGrade) => {
    if (firstGrade.gradeValue && secondGrade.gradeValue)
      return firstGrade.gradeValue - secondGrade.gradeValue;
    return -1;
  });
};

export const isEmptyObject = (obj: object | undefined): boolean =>
  obj ? !Object.keys(obj).length : false;

export const isObjectEmpty = (obj: object | undefined): boolean =>
  obj && typeof obj === 'object' ? !Object.keys(obj).length : true;

export const setDocumentLang = (locale: Lang) => {
  document.documentElement.lang = locale;
  document.documentElement.dir = LOCALES[locale].direction;
};

export const getOptions = <T extends { id: number; title: string }>(arg: T[]): OptionType[] => {
  return arg.map((x) => ({ value: x.id, label: x.title }));
};

export const getTodayDateInfo = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const date = today.getDate();

  return { year, month, date };
};

export const getUnitLessonStr = (count: number, type: 'unit' | 'lesson') => {
  const { formatMessage } = getIntl();

  let unit = formatMessage({ id: 'content-library.units.label', defaultMessage: 'Units' });
  let lesson = formatMessage({
    id: 'schedule.event.detail.lessons',
    defaultMessage: 'Lessons',
  });

  if (count === 1) {
    unit = formatMessage({ id: 'subject.unit.title', defaultMessage: 'Unit' });
    lesson = formatMessage({
      id: 'content-library.lesson.title',
      defaultMessage: 'Lesson',
    });
  }

  return `${count} ${type === 'lesson' ? lesson : unit}`;
};

export const getUnitsAndLessonsStr = (units: number, lessons: number) => {
  const { formatMessage } = getIntl();

  return `${getUnitLessonStr(units, 'unit')} ${formatMessage({
    id: 'and.label',
    defaultMessage: 'and',
  })} ${getUnitLessonStr(lessons, 'lesson')}`;
};

export const getLandingUrl = (locale: string) => {
  return isArabicMode(locale) ? LANDING_URL_AR : LANDING_URL_EN;
};

export const replaceSpaceInString = (str: string | null, replacementString: string) => {
  return str?.replace(/ /g, replacementString);
};

export const bankersRounding = (num: number, decimalPlaces = 0) => {
  const m = 10 ** decimalPlaces;
  const n = +(decimalPlaces ? num * m : num).toFixed(8);
  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8;
  // eslint-disable-next-line no-nested-ternary
  const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
  return decimalPlaces ? r / m : r;
};
