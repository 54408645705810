import { createAsyncAction, createAction } from 'typesafe-actions';
import { TeacherInfoType, TeacherInfoPayload, DeletePayload, UploadPayloadType } from './types';

export const loadTeacherProfile = createAsyncAction(
  '@@teacherProfile/LOAD_REQUEST',
  '@@teacherProfile/LOAD_SUCCESS',
  '@@teacherProfile/LOAD_FAILURE',
)<TeacherInfoPayload, TeacherInfoType, string>();

export const updateTeacherAction = createAsyncAction(
  '@@teacher/UPDATE_REQUEST',
  '@@teacher/UPDATE_SUCCESS',
  '@@teacher/UPDATE_FAILURE',
)<any, any, any>();

export const updateTeacherPasswordAction = createAsyncAction(
  '@@teacherPassword/UPDATE_REQUEST',
  '@@teacherPassword/UPDATE_SUCCESS',
  '@@teacherPassword/UPDATE_FAILURE',
)<UploadPayloadType, undefined, string>();

export const resetTeacherAction = createAction('@@teacherProfile/RESET')();

export const deleteTeacherAction = createAsyncAction(
  '@@deleteTeacher/LOAD_REQUEST',
  '@@deleteTeacher/LOAD_SUCCESS',
  '@@deleteTeacher/LOAD_FAILURE',
)<DeletePayload, any, any>();
