import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  loadSchoolStudents,
  loadSchoolTeachers,
  loadSchoolTeachersProgress,
  regeneratePassword,
} from './actions';
import {
  getSchoolStudentsList,
  getSchoolTeachersList,
  getTeachersProgressList,
  regenerateUserPassword,
} from './api';
import { SchoolUserListResponse } from './types';

function* loadSchoolTeacherRequest({
  payload,
}: ReturnType<typeof loadSchoolTeachers.request>): SagaIterator<void> {
  try {
    const list: SchoolUserListResponse = yield call(getSchoolTeachersList, payload);
    yield put(loadSchoolTeachers.success(list));
  } catch (error) {
    yield put(loadSchoolTeachers.failure(error));
  }
}

function* loadSchoolStudentsRequest({
  payload,
}: ReturnType<typeof loadSchoolStudents.request>): SagaIterator<void> {
  try {
    const list: SchoolUserListResponse = yield call(getSchoolStudentsList, payload);
    yield put(loadSchoolStudents.success(list));
  } catch (error) {
    yield put(loadSchoolStudents.failure(error));
  }
}

function* loadSchoolTeacherProgressRequest({
  payload,
}: ReturnType<typeof loadSchoolTeachersProgress.request>): SagaIterator<void> {
  try {
    const progress: SchoolUserListResponse = yield call(getTeachersProgressList, payload);
    yield put(loadSchoolTeachersProgress.success(progress));
  } catch (error) {
    yield put(loadSchoolTeachersProgress.failure(error));
  }
}

function* regeneratePasswordRequest({
  payload,
}: ReturnType<typeof regeneratePassword.request>): SagaIterator<void> {
  try {
    yield call(regenerateUserPassword, payload);
    payload.callback('success');
  } catch (error) {
    payload.callback('error');
  }
}

function* schoolListsSaga(): SagaIterator {
  yield all([
    takeEvery(loadSchoolTeachers.request, loadSchoolTeacherRequest),
    takeEvery(loadSchoolTeachersProgress.request, loadSchoolTeacherProgressRequest),
    takeEvery(loadSchoolStudents.request, loadSchoolStudentsRequest),
    takeEvery(regeneratePassword.request, regeneratePasswordRequest),
  ]);
}

export default schoolListsSaga;
