import { combineReducers } from 'redux';
import accountReducer from './account/reducer';
import assignedSubjectsReducer from './assignedSubjects/reducer';
import badgesReducer from './badges/reducer';
import breadcrumbsReducer from './breadcrumbs/reducer';
import eventsReducer from './events/reducer';
import feedbackReducer from './feedback/reducer';
import gradesReducer, { gradeStudentsReducer } from './grades/reducer';
import inviteParentsReducer from './inviteParents/reducer';
import learningProfilesReducer from './learningProfiles/reducer';
import lessonPreviewReducer from './lessonPreview/reducer';
import lessonsReducer from './lessons/reducer';
import lessonsWeekScheduleReducer from './lessonsWeekSchedule/reducer';
import preferencesReducer from './preferences/reducer';
import notificationsReducer from './notifications/reducer';
import progressReducer from './progress/reducer';
import preparationMaterialReducer from './preparationMaterial/reducer';
import registrationReducer from './registration/reducer';
import scheduleReducer, {
  joinMeetingReducer,
  eventDetailReducer,
  teacherEventAttachmentsReducer,
  scheduleBMReducer,
  teacherGeneralQAReducer,
} from './schedule/reducer';
import schoolTeacherReducer from './schoolTeacher/reducer';
import schoolStudentReducer from './schoolStudent/reducer';
import scheduleForTodayReducer from './scheduleForToday/reducer';
import ssoReducer from './sso/reducer';
import childrenSubscriptionsReducer from './childrenSubscriptions/reducer';
import subjectReducer from './subjects/reducer';
import subscriptionsReducer from './subscriptions/reducer';
import supportReducer from './support/reducer';
import unitsReducer from './units/reducer';
import semestersReducer from './semesters/reducer';
import contentLibraryReducer from './contentLibrary/reducer';
import paymentHistoryReducer from './paymentHistory/reducer';
import modalReducer from './modals/reducer';
import schoolBranchesReducer from './shoolBranches/reducer';
import teacherProfileReducer from './schoolTeacherProfile/reducer';
import studentProfileReducer from './studentProfile/reducer';
import schoolTeachersReducer from './shoolLists/reducer';
import schoolTeachersProgressReducer from './shoolLists/progressReducer';
import schoolStudentsReducer from './shoolLists/studentReducer';
import b2bBulkImportReducer from './b2bBulkImport/reducer';
import tourReducer from './tourManagement/reducer';
import groupChatReducer, { spamWordsReducer } from './groupChat/reducer';
import schoolBranchStatReducer from './shoolBranches/statsReducer';
import b2bTeacherReducer from './b2bTeacher/reducer';
import assignTeacherGradesReducer from './assignTeacherGrades/reducer';
import questionBankReducer from './questionBank/reducer';
import reportsReducer from './reports/reducer';
import RewardsReducer from './Rewards/reducer';
import AvatarReducer from './DigitalTutor/reducer';
import semesterScheduleReducer from './SemesterSchedule/reducer';
import assigmentsReducer from './assignments/reducer';
import examsReducer from './exams/reducer';
import flexibleScheduleReducer from './flexibleSchedule/reducer';
import timetableReducer from './Timetable/reducer';
import promotionReducer from './promotion/reducer';
import systemConfigReducer from './systemConfig/reducer';
import dashboardReducer from './dashboard/reducer';
import childTeacherDetailQuestionReducer from './childTeacherDetailQuestion/reducer';
import recommendationSystemReducer from './recommendationSystem/reducer';
import nativeEventsReducer from './native-events/reducer';
import orientationReducer from './orientation/reducer';
import studentDashboardV2Reducer from './student-dashboard/reducer';
import qiyasReducer from './qiyas/reducer';
import gradingSystemReducer from './grading-system/reducer';

const rootReducer = combineReducers({
  account: accountReducer,
  assignedSubjects: assignedSubjectsReducer,
  badges: badgesReducer,
  breadcrumbs: breadcrumbsReducer,
  childrenSubscriptions: childrenSubscriptionsReducer,
  events: eventsReducer,
  feedback: feedbackReducer,
  grades: gradesReducer,
  gradeStudents: gradeStudentsReducer,
  inviteParents: inviteParentsReducer,
  learningProfiles: learningProfilesReducer,
  lessonPreview: lessonPreviewReducer,
  lessons: lessonsReducer,
  lessonsWeekSchedule: lessonsWeekScheduleReducer,
  preferences: preferencesReducer,
  notifications: notificationsReducer,
  progress: progressReducer,
  preparation: preparationMaterialReducer,
  registration: registrationReducer,
  rewards: RewardsReducer,
  schedule: scheduleReducer,
  scheduleBM: scheduleBMReducer,
  joinMeeting: joinMeetingReducer,
  eventDetail: eventDetailReducer,
  schoolTeacher: schoolTeacherReducer,
  schoolStudent: schoolStudentReducer,
  scheduleForToday: scheduleForTodayReducer,
  sso: ssoReducer,
  schoolBranches: schoolBranchesReducer,
  schoolBranchStats: schoolBranchStatReducer,
  subjects: subjectReducer,
  teacherProfile: teacherProfileReducer,
  subscriptions: subscriptionsReducer,
  support: supportReducer,
  units: unitsReducer,
  semesterSchedule: semesterScheduleReducer,
  timetable: timetableReducer,
  semesters: semestersReducer,
  contentLibrary: contentLibraryReducer,
  paymentHistory: paymentHistoryReducer,
  modal: modalReducer,
  spamWords: spamWordsReducer,
  studentProfile: studentProfileReducer,
  schoolTeachers: schoolTeachersReducer,
  schoolTeachersProgress: schoolTeachersProgressReducer,
  schoolStudents: schoolStudentsReducer,
  b2bBulkImport: b2bBulkImportReducer,
  tour: tourReducer,
  avatars: AvatarReducer,
  groupChat: groupChatReducer,
  b2bTeacher: b2bTeacherReducer,
  assignTeacherGrades: assignTeacherGradesReducer,
  questionBank: questionBankReducer,
  teacherEventAttachments: teacherEventAttachmentsReducer,
  reports: reportsReducer,
  assignments: assigmentsReducer,
  exams: examsReducer,
  flexibleSchedule: flexibleScheduleReducer,
  promotion: promotionReducer,
  systemConfig: systemConfigReducer,
  dashboard: dashboardReducer,
  childTeacherDetailQuestion: childTeacherDetailQuestionReducer,
  recommendationSystem: recommendationSystemReducer,
  nativeEvents: nativeEventsReducer,
  orientation: orientationReducer,
  dashboardV2: studentDashboardV2Reducer,
  qiyas: qiyasReducer,
  gradingSystem: gradingSystemReducer,
  teacherGeneralQA: teacherGeneralQAReducer,
});

export default rootReducer;
