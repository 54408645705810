import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { some } from 'lodash/fp';
import { VideoPopup } from '../../lib';
import { selectorModal } from '../../store/modals/selector';
import { closeVideoPopup } from '../../store/modals/actions';
import { selectorLessonsWeekSchedule } from '../../store/lessonsWeekSchedule/selectors';
import { completeContentDataAction } from '../../store/contentLibrary/actions';
import { loadLessonsWeekScheduleAction } from '../../store/lessonsWeekSchedule/actions';
import { Role } from '../../store/account/types';
import { selectorAccount } from '../../store/account/selectors';
import { selectorContentLibrary } from '../../store/contentLibrary/selectors';

const EventPopup: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { videoPopup } = useSelector(selectorModal);
  const {
    data: { role },
  } = useSelector(selectorAccount);

  const isStudent = role === Role.student;

  const {
    data: { additional },
  } = useSelector(selectorLessonsWeekSchedule);

  const {
    data: { completeLesson },
  } = useSelector(selectorContentLibrary);

  const isCompleted = useMemo(
    () => completeLesson?.contentId === videoPopup.eventId && completeLesson?.isCompleted,
    [completeLesson, videoPopup.eventId],
  );

  const isAssigned = useMemo(() => {
    return some({ lessonId: Number(videoPopup.eventId) })(additional);
  }, [additional, videoPopup.eventId]);

  const onCompleteContent = useCallback(() => {
    dispatch(
      completeContentDataAction.request({
        lessonId: String(videoPopup.eventId),
      }),
    );
  }, [dispatch, videoPopup.eventId]);

  const setIsShowModal = (): void => {
    if (videoPopup.onClose) {
      videoPopup.onClose();
    }
    dispatch(closeVideoPopup());
  };

  useEffect(() => {
    if (videoPopup.isOpen) {
      dispatch(loadLessonsWeekScheduleAction.request(undefined));
    }
  }, [dispatch, additional.length, videoPopup.isOpen]);

  return (
    <VideoPopup
      title={videoPopup.title}
      date={videoPopup.date}
      videoUrl={videoPopup.videoUrl}
      isOpen={Boolean(videoPopup.isOpen)}
      setIsShowModal={setIsShowModal}
      isCompleted={isCompleted && isStudent}
      onCompleteContent={onCompleteContent}
      isAssigned={isAssigned}
    />
  );
};

export default EventPopup;
