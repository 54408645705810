import { isEmpty } from 'lodash/fp';
import React, { FunctionComponent, useCallback, useEffect, useRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useClickAway, useInterval, useKey, useToggle, useEvent } from 'react-use';

import { IconCommon, Icon as NewIcon } from 'lib';
import {
  getNotificationsUnreadCountAction,
  loadNotificationsAction,
  markNotificationsAction,
  resetNotificationsAction,
} from 'store/notifications/actions';
import {
  notificationFetchingSelector,
  selectorBulkImport,
  selectorNotificationsGroupByDate,
  selectorNotificationsUnread,
  selectorNotificationsUnreadCount,
} from 'store/notifications/selectors';
import { DEFAULT_PAGE_OPTIONS } from 'constants/entities';
import { IS_DEV_ENVIRONMENT } from 'constants/settings';

import { NotificationList } from './NotificationList/NotificationList';
import { messages } from './NotificationMenu.messages';
import style from './NotificationMenu.module.scss';

type NotificationProps = {
  isGradeOneToThree?: boolean;
};

const NotificationMenu: FunctionComponent<NotificationProps> = ({ isGradeOneToThree }) => {
  const dispatch = useDispatch();

  const notifications = useSelector(selectorNotificationsGroupByDate);
  const fetchingNotifications = useSelector(notificationFetchingSelector);
  const unreadCount = useSelector(selectorNotificationsUnreadCount);
  const unread = useSelector(selectorNotificationsUnread);
  const isBulkImportInProgress = useSelector(selectorBulkImport);
  const { formatMessage } = useIntl();

  const [isIntervalRunning, setIsIntervalRunning] = useToggle(true);
  const [isOpen, setIsOpen] = useToggle(false);
  const currentEl = useRef(null);

  const updateInterval = 60000;
  const updateIntervalDuringBulkImport = 10000;

  const getNotifications = useCallback(() => {
    dispatch(loadNotificationsAction.request(DEFAULT_PAGE_OPTIONS));
  }, [dispatch]);

  const toggleHandler = useCallback((): void => {
    setIsIntervalRunning();

    if (!isOpen) {
      getNotifications();
    } else {
      dispatch(resetNotificationsAction());
    }

    if (!isEmpty(unread) && isOpen) {
      dispatch(markNotificationsAction.request({ notifications: unread }));
      dispatch(getNotificationsUnreadCountAction.request());
    }
    setIsOpen();
  }, [dispatch, getNotifications, isOpen, setIsIntervalRunning, setIsOpen, unread]);

  const handleClose = useCallback((): void => {
    setIsIntervalRunning(true);
    if (!isEmpty(unread) && isOpen) {
      dispatch(
        markNotificationsAction.request({
          notifications: unread,
        }),
      );
    }

    setIsOpen(false);
  }, [dispatch, isOpen, setIsIntervalRunning, setIsOpen, unread]);

  useClickAway(currentEl, handleClose);
  useKey('Escape', handleClose);
  useEvent('blur', handleClose);

  const interval = useMemo(() => {
    if (isBulkImportInProgress) return updateIntervalDuringBulkImport;
    return updateInterval;
  }, [isBulkImportInProgress]);

  useInterval(
    () => {
      if (!IS_DEV_ENVIRONMENT) {
        dispatch(getNotificationsUnreadCountAction.request());
      }
    },
    isIntervalRunning ? interval : null,
  );

  useEffect(() => {
    dispatch(getNotificationsUnreadCountAction.request());
  }, [dispatch]);

  useEffect(() => {
    if (!isOpen) dispatch(resetNotificationsAction());
  }, [dispatch, isOpen]);

  return (
    <div
      id="topbar__notification"
      ref={currentEl}
      className={cn(style.topheader__operation, { [style.grade]: isGradeOneToThree })}
    >
      <div className={style.component}>
        <div role="button" className={style.button} onClick={toggleHandler}>
          <NewIcon type="notification-bell" size="s24" />
          {!!unreadCount && <span className={style.counter}>{unreadCount}</span>}
        </div>
        {isOpen && (
          <div className={style.notificationMenu}>
            <div className={style.notificationMenu__title}>
              {!isEmpty(notifications) || fetchingNotifications ? (
                <h3>{formatMessage(messages.title)}</h3>
              ) : (
                <h3>{formatMessage(messages.noNotification)}</h3>
              )}
              <span role="button" className={style.notificationMenu__close} onClick={toggleHandler}>
                <IconCommon className="icon-cancel" size="s16" weight="bold" />
              </span>
            </div>
            {(!isEmpty(notifications) || fetchingNotifications) && (
              <NotificationList
                items={notifications}
                onClose={handleClose}
                isGradeOneToThree={isGradeOneToThree}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationMenu;
