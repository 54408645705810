import { createAsyncAction } from 'typesafe-actions';
import { RewardStatResponse, RewardUpdateResponseType, RewardUpdateType } from './types';

export const loadRewardStatsAction = createAsyncAction(
  '@@rewardStats/LOAD_REQUEST',
  '@@rewardStats/LOAD_SUCCESS',
  '@@rewardStats/LOAD_FAILURE',
)<undefined, RewardStatResponse, string>();

export const updateRewardAction = createAsyncAction(
  '@@updateReward/LOAD_REQUEST',
  '@@updateReward/LOAD_SUCCESS',
  '@@updateReward/LOAD_FAILURE',
)<RewardUpdateType, RewardUpdateResponseType, string>();
