import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { loadRewardStatsAction, updateRewardAction } from './actions';
import { getRewardStats, updateReward } from './api';
import { RewardStatResponse, RewardUpdateResponseType } from './types';

function* loadRewardStatsRequest(): SagaIterator<void> {
  try {
    const rewardStats: RewardStatResponse = yield call(getRewardStats);
    yield put(loadRewardStatsAction.success(rewardStats));
  } catch (error) {
    yield put(loadRewardStatsAction.failure(error));
  }
}
function* rewardUpdateRequest({
  payload,
}: ReturnType<typeof updateRewardAction.request>): SagaIterator<void> {
  try {
    const rewardResponse: RewardUpdateResponseType = yield call(updateReward, payload);
    yield put(updateRewardAction.success(rewardResponse));
    if (rewardResponse.success) {
      payload.callback(rewardResponse.rewardPoints);
    }
  } catch (error) {
    yield put(updateRewardAction.failure(error));
  }
}

function* rewardsSaga(): SagaIterator {
  yield all([takeEvery(loadRewardStatsAction.request, loadRewardStatsRequest)]);
  yield all([takeEvery(updateRewardAction.request, rewardUpdateRequest)]);
}

export default rewardsSaga;
