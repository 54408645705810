import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import API from 'utils/api';
import {
  getTeacherGradesAPI,
  getGradeDetailAPI,
  getStudentReportsAPI,
  getStatsScalesAPI,
  getSelfStudentReportAPI,
  getChildReportAPI,
  getB2BTeacherGradesAPI,
  getChildrenReportsAPI,
  updateChildDetailsAPI,
  getBranchClassroomsAPI,
  getBranchClassroomDetailsAPI,
  getPerformanceStatsAPI,
  getPerformanceStatsStudentsAPI,
  sendTeacherEmailAPI,
  getClassroomRecommendationsAPI,
  getLiveClassRecommendationsListAPI,
  getStatsScalesByGradeAPI,
} from 'utils/apiEndpoints';
import {
  classroomDetailsPayload,
  classroomsListReturnType,
  fetchStudentReportPayload,
  getClassroomsListPayload,
  reportsGradeListPayload,
  classroomsDetailsReturnType,
  getStudentProgressClassesPayload,
  getPerformanceStatsPayload,
  studentProgressClassesReturnType,
  performanceStats,
  getPerformanceStatsStudentsPayload,
  sendTeacherEmailPayload,
  RecommendationsClassesPayload,
  SendEmailResponseType,
  LiveClassRecommendationType,
  reportsStatsScalePayload,
  statsScaleType,
} from './types';

import { gradeDetailAdapter, childrenReportsAdapter } from './adapter';

export async function getTeacherGrades(payload: reportsGradeListPayload | undefined): Promise<any> {
  const response = await API.get(
    payload?.isALWTeacher
      ? getTeacherGradesAPI(payload?.search)
      : getB2BTeacherGradesAPI(payload?.search, payload?.id),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function getGradeDetail(
  gradeId: number,
  pageIndex?: number,
  searchName?: string,
  pageSize?: string,
  isALWTeacher?: boolean,
  classId?: number,
  teacherId?: number,
): Promise<any> {
  const response = await API.get(getGradeDetailAPI(gradeId, isALWTeacher, teacherId), {
    params: {
      name: searchName,
      page: pageIndex,
      page_size: pageSize,
      class: classId,
    },
  });
  return gradeDetailAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function getStudentReports({
  id,
  startDate,
  endDate,
  isALWParent,
  isALWStudent,
  isALWTeacher,
  isSchoolTeacher,
  isAdmin,
  semester,
}: fetchStudentReportPayload): Promise<any> {
  let response: any;
  if (isALWTeacher || isSchoolTeacher || isAdmin)
    response = await API.get(getStudentReportsAPI(id, startDate, endDate, semester));
  else if (isALWStudent)
    response = await API.get(getSelfStudentReportAPI(startDate, endDate, semester));
  else if (isALWParent)
    response = await API.get(getChildReportAPI(id, startDate, endDate, semester));
  return gradeDetailAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function updateChildDetails({ payload }: any): Promise<any> {
  const childData = payload.formData;
  const response: any = await API.patch(
    updateChildDetailsAPI(payload.studentID),
    snakeCaseKeys(childData, { deep: true }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function getStatsScales(): Promise<any> {
  const response = await API.get(getStatsScalesAPI());
  return camelcaseKeys(response.data, { deep: true });
}

export async function getChildrenReports(): Promise<any> {
  const response = await API.get(getChildrenReportsAPI());
  return childrenReportsAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function getBranchClassrooms({
  branchId,
  pageSize,
}: getClassroomsListPayload): Promise<classroomsListReturnType> {
  const response = await API.get(getBranchClassroomsAPI(branchId, pageSize));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getBranchClassroomDetails({
  classroomId,
  startDate,
  endDate,
  page,
  pageSize = '10',
  semester,
}: classroomDetailsPayload): Promise<classroomsDetailsReturnType> {
  const response = await API.get(getBranchClassroomDetailsAPI(classroomId), {
    params: snakeCaseKeys({ startDate, endDate, page, pageSize, semester }),
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function getStudentProgressClasses({
  branchId,
  startDate,
  endDate,
  pageSize,
  semester,
}: getStudentProgressClassesPayload): Promise<studentProgressClassesReturnType> {
  const response = await API.get(getBranchClassroomsAPI(branchId, pageSize), {
    params: snakeCaseKeys({
      startDate,
      endDate,
      semester,
      performanceScale: 'weak',
    }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getPerformanceStats({
  classId,
  startDate,
  endDate,
  semester,
}: getPerformanceStatsPayload): Promise<Array<performanceStats>> {
  const response = await API.get(getPerformanceStatsAPI(classId), {
    params: snakeCaseKeys({
      startDate,
      endDate,
      semester,
    }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getPerformanceStatsStudents({
  classId,
  subjectId,
  startDate,
  endDate,
  page,
  pageSize = '10',
  semester,
}: getPerformanceStatsStudentsPayload): Promise<classroomsDetailsReturnType> {
  const response = await API.get(getPerformanceStatsStudentsAPI(classId, subjectId), {
    params: snakeCaseKeys({
      startDate,
      endDate,
      performanceScale: 'weak',
      page,
      pageSize,
      semester,
    }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function sendTeacherEmailRequest(
  props: sendTeacherEmailPayload,
): Promise<SendEmailResponseType> {
  const response = await API.post(
    sendTeacherEmailAPI(),
    snakeCaseKeys({
      ...props,
      performanceScale: 'weak',
    }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function getRecommendationsRequest({
  branchId,
  duration,
  pageSize,
}: RecommendationsClassesPayload): Promise<classroomsListReturnType> {
  const response = await API.get(getBranchClassroomsAPI(branchId, pageSize), {
    params: snakeCaseKeys({
      withRecommendations: true,
      duration,
    }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getClassroomRecommendations({
  classId = 1,
  callback,
  ...rest
}: RecommendationsClassesPayload): Promise<classroomsListReturnType> {
  const response = await API.get(getClassroomRecommendationsAPI(classId), {
    params: snakeCaseKeys({
      ...rest,
    }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getLiveClassRecommendationsList({
  classId = 1,
  duration,
}: RecommendationsClassesPayload): Promise<LiveClassRecommendationType> {
  const response = await API.get(getLiveClassRecommendationsListAPI(classId), {
    params: snakeCaseKeys({ duration }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getStatsScalesByGrade({
  gradeId,
}: reportsStatsScalePayload): Promise<statsScaleType> {
  const response = await API.get(getStatsScalesByGradeAPI(gradeId));
  return camelcaseKeys(response.data, { deep: true });
}
