export enum ButtonTypes {
  buttonBig = 'btn-big',
  buttonSmall = 'btn-small',
  button300 = 'btn-300',
  buttonPrimary = 'btn-primary',
  buttonPrimaryMD = 'btn-primary-md',
  buttonPrimarySm = 'btn-primary-sm',
  buttonSecondary = 'btn-secondary',
  buttonSecondaryMD = 'btn-secondary-md',
  buttonSecondarySm = 'btn-secondary-sm',
  buttonCard = 'btn-card',
  buttonCardPackage = 'btn-card-package',
  buttonRed = 'btn-red',
  buttonRedSm = 'btn-red-sm',
  buttonRedSmOutline = 'btn-red-outline-sm',
  buttonRedMD = 'btn-red-md',
  buttonGreen = 'btn-green',
  buttonGreenSM = 'btn-green-sm',
  buttonGreenPlainSM = 'btn-green-plain-sm',
  btnGrade = 'btnGrade',
  btnGradeSmall = 'btnGrade-small',
  btnGradeRed = 'btnGrade-red',
  btnGradeOrange = 'btnGrade-orange',
  btnLink = 'btn-link',
  btnFilledSm = 'btn-filled-sm',
  btnOutlineWhite = 'btn-outline-white',
  btnYellow = 'btn-yellow',
  anchor = 'anchor',
}
