import { createAsyncAction } from 'typesafe-actions';
import {
  TeachersListPayload,
  TeachersProgressListPayload,
  RegeneratePasswordPayload,
  SchoolUserListResponse,
} from './types';

export const loadSchoolTeachers = createAsyncAction(
  '@@schoolTeachers/LOAD_REQUEST',
  '@@schoolTeachers/LOAD_SUCCESS',
  '@@schoolTeachers/LOAD_FAILURE',
)<TeachersListPayload, SchoolUserListResponse, string>();

export const loadSchoolTeachersProgress = createAsyncAction(
  '@@schoolTeachersProgress/LOAD_REQUEST',
  '@@schoolTeachersProgress/LOAD_SUCCESS',
  '@@schoolTeachersProgress/LOAD_FAILURE',
)<TeachersProgressListPayload, SchoolUserListResponse, string>();

export const loadSchoolStudents = createAsyncAction(
  '@@schoolStudents/LOAD_REQUEST',
  '@@schoolStudents/LOAD_SUCCESS',
  '@@schoolStudents/LOAD_FAILURE',
)<TeachersListPayload, SchoolUserListResponse, string>();

export const regeneratePassword = createAsyncAction(
  '@@schoolRegeneratePassword/LOAD_REQUEST',
  '@@schoolRegeneratePassword/LOAD_SUCCESS',
  '@@schoolRegeneratePassword/LOAD_FAILURE',
)<RegeneratePasswordPayload, string, string>();
