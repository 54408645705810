import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import { getPromotionVideosCardsAPI, getPromotionNewsUpdatesAPI } from 'utils/apiEndpoints';

import {
  NewsAndUpdatesPayloadType,
  NewsAndUpdatesResponseType,
  VideosCardsResponseType,
} from './types';

export async function getVideosCardsRequest(): Promise<VideosCardsResponseType> {
  const response = await API.get(getPromotionVideosCardsAPI());
  return camelcaseKeys(response.data, { deep: true });
}

export async function getNewsUpdatesRequest(
  payload: NewsAndUpdatesPayloadType,
): Promise<NewsAndUpdatesResponseType> {
  const response = await API.get(getPromotionNewsUpdatesAPI(), {
    params: payload,
  });
  return camelcaseKeys(response.data, { deep: true });
}
