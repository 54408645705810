/* eslint-disable @typescript-eslint/ban-ts-comment  */
import Recorder from './recorder';

let microphoneStream: any; // stream from getUserMedia()
let rec: any = Recorder; // Recorder.js object
let input; // MediaStreamAudioSourceNode we'll be recording

/**
 *
 * @param {{
 * audioContext: AudioContext
 * errHandler?: () => void
 * onStreamLoad?: () => void
 * }}
 * @returns {Promise<MediaStream>}
 */
export const startRecording = async ({
  audioContext,
  errHandler,
  onStreamLoad,
}: any): Promise<any> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    if (onStreamLoad) {
      onStreamLoad();
    }

    /*  assign stream for later use  */
    microphoneStream = stream;

    /* use the stream */
    input = audioContext.createMediaStreamSource(stream);

    // @ts-ignore
    rec = new Recorder(input);

    // start the recording process
    rec.record();

    return stream;
  } catch (err) {
    if (errHandler) {
      return errHandler();
    }
    return console.log(err);
  }
};

/**
 *
 * @param {{
 * exportWAV: boolean
 * wavCallback?: (blob: Blob) => void
 * }}
 */
export const stopRecording = ({ exportWAV, wavCallback }: any) => {
  // stop recorder.js recording
  rec.stop();

  // stop microphone access
  microphoneStream.getAudioTracks()[0].stop();

  // create the wav blob
  if (exportWAV && wavCallback) {
    rec.exportWAV((blob: any) => wavCallback(blob));
  }

  rec.clear();
};
