import { createAction, createAsyncAction } from 'typesafe-actions';
import { branchStats, branchStatType, SchoolBrancheType, schoolDetail } from './types';

export const loadSchoolBranches = createAsyncAction(
  '@@schoolBranches/LOAD_REQUEST',
  '@@schoolBranches/LOAD_SUCCESS',
  '@@schoolBranches/LOAD_FAILURE',
)<undefined, schoolDetail, string>();

export const loadSchoolBranchStats = createAsyncAction(
  '@@schoolBranchStats/LOAD_REQUEST',
  '@@schoolBranchStats/LOAD_SUCCESS',
  '@@schoolBranchStats/LOAD_FAILURE',
)<branchStatType, branchStats, string>();

export const selectBranch = createAction('@@schoolSelectedBranch/SET')<SchoolBrancheType>();
