import { createAsyncAction, createAction } from 'typesafe-actions';
import { SupportMessageRequestType } from './types';

export const sendSupportMessageAction = createAsyncAction(
  '@@support/SEND_MESSAGE_REQUEST',
  '@@support/SEND_MESSAGE_SUCCESS',
  '@@support/SEND_MESSAGE_FAILURE',
)<SupportMessageRequestType, SupportMessageRequestType, string>();

export const resetSupportAction = createAction('@@support/RESET')();
