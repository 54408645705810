import { createAsyncAction, createAction } from 'typesafe-actions';

import {
  GradeSubjects,
  FetchGradeSubjectsPayload,
  LessonQuestionsResponse,
  LessonEvent,
  FetchQuestionsPayload,
  FetchLessonEventsPayload,
  DeleteMainQuestionPayload,
  DeleteSubQuestionPayload,
  FetchLearningObjectivesPayload,
  LearningObjective,
  DeleteContentPayload,
  UploadContentPayload,
  UploadContentResponse,
  DiscardChangesPayload,
  CreateQuestionsPayload,
  CreationQuestionsResponse,
  EditMainQuestionPayload,
} from './types';

export const fetchGradeSubjectsAction = createAsyncAction(
  '@@native-events/LOAD_REQUEST',
  '@@native-events/LOAD_SUCCESS',
  '@@native-events/LOAD_FAILURE',
)<FetchGradeSubjectsPayload, GradeSubjects, string>();

export const fetchQuestionsAction = createAsyncAction(
  '@@native-events/LOAD_LESSON_QUESTIONS_REQUEST',
  '@@native-events/LOAD_LESSON_QUESTIONS_SUCCESS',
  '@@native-events/LOAD_LESSON_QUESTIONS_FAILURE',
)<FetchQuestionsPayload, LessonQuestionsResponse, string>();

export const fetchPreviewQuestionsAction = createAsyncAction(
  '@@native-events/LOAD_LESSON_PREVIEW_QUESTIONS_REQUEST',
  '@@native-events/LOAD_LESSON_PREVIEW_QUESTIONS_SUCCESS',
  '@@native-events/LOAD_LESSON_PREVIEW_QUESTIONS_FAILURE',
)<FetchQuestionsPayload, LessonQuestionsResponse, string>();

export const fetchLessonEventsAction = createAsyncAction(
  '@@native-events/LOAD_LESSON_EVENTS_REQUEST',
  '@@native-events/LOAD_LESSON_EVENTS_SUCCESS',
  '@@native-events/LOAD_LESSON_EVENTS_FAILURE',
)<FetchLessonEventsPayload, LessonEvent[], string>();

export const resetLessonEventsAction = createAction('@@native-events/RESET_LESSON_EVENTS')();

export const deleteMainQuestionAction = createAsyncAction(
  '@@native-events/DELETE_MAIN_QUESTION_REQUEST',
  '@@native-events/DELETE_MAIN_QUESTION_SUCCESS',
  '@@native-events/DELETE_MAIN_QUESTION_FAILURE',
)<DeleteMainQuestionPayload, undefined, string>();

export const deleteSubQuestionAction = createAsyncAction(
  '@@native-events/DELETE_SUB_QUESTION_REQUEST',
  '@@native-events/DELETE_SUB_QUESTION_SUCCESS',
  '@@native-events/DELETE_SUB_QUESTION_FAILURE',
)<DeleteSubQuestionPayload, undefined, string>();

export const fetchLearningObjectivesAction = createAsyncAction(
  '@@native-events/LOAD_LO_REQUEST',
  '@@native-events/LOAD_LO_SUCCESS',
  '@@native-events/LOAD_LO_FAILURE',
)<FetchLearningObjectivesPayload, LearningObjective[], string>();

export const createEditQuestionsAction = createAsyncAction(
  '@@native-events/CREATE_QUESTIONS_REQUEST',
  '@@native-events/CREATE_QUESTIONS_SUCCESS',
  '@@native-events/CREATE_QUESTIONS_FAILURE',
)<CreateQuestionsPayload, CreationQuestionsResponse, string>();

export const uploadContentAction = createAsyncAction(
  '@@native-events/UPLOAD_CONTENT_REQUEST',
  '@@native-events/UPLOAD_CONTENT_SUCCESS',
  '@@native-events/UPLOAD_CONTENT_FAILURE',
)<UploadContentPayload, UploadContentResponse, string>();

export const deleteContentAction = createAsyncAction(
  '@@native-events/DELETE_CONTENT_REQUEST',
  '@@native-events/DELETE_CONTENT_SUCCESS',
  '@@native-events/DELETE_CONTENT_FAILURE',
)<DeleteContentPayload, LearningObjective, string>();

export const discardChangesAction = createAsyncAction(
  '@@native-events/DISCARD_CHANGES_REQUEST',
  '@@native-events/DISCARD_CHANGES_SUCCESS',
  '@@native-events/DISCARD_CHANGES_FAILURE',
)<DiscardChangesPayload, string, string>();

export const resetUploadedContentAction = createAction(
  '@@native-events/RESET_UPLOADED_CONTENT_REQUEST',
)();

export const resetPreviewQuestionsAction = createAction(
  '@@native-events/RESET_PREVIEW_QUESTIONS',
)();

export const editMainQuestionAction = createAsyncAction(
  '@@native-events/EDIT_MAIN_QUESTION_REQUEST',
  '@@native-events/EDIT_MAIN_QUESTION_SUCCESS',
  '@@native-events/EDIT_MAIN_QUESTION_FAILURE',
)<EditMainQuestionPayload, CreationQuestionsResponse, string>();
