import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  cancelChildrenSubscriptionAction,
  loadChildrenSubscriptions,
  updateSubscription,
} from './actions';
import { cancelChildrenSubscription, getChildrenSubscriptions } from './api';
import { Role } from '../account/types';
import { updateAccountSubscription } from '../account/actions';

function* loadChildrenSubscription(): SagaIterator<void> {
  try {
    const data = yield call(getChildrenSubscriptions);
    yield put(loadChildrenSubscriptions.success(data));
  } catch (error) {
    yield put(loadChildrenSubscriptions.failure(error.message));
  }
}

function* cancelStudentSubscriptionSaga({
  payload,
}: ReturnType<typeof cancelChildrenSubscriptionAction.request>): SagaIterator<void> {
  try {
    const subscription = yield call(cancelChildrenSubscription, payload.userId);
    if (payload.role === Role.parent) {
      yield put(
        updateSubscription.success({
          subscription,
          userId: payload.userId,
        }),
      );
    } else {
      yield put(updateAccountSubscription.success(subscription));
    }
  } catch (error) {
    yield put(
      cancelChildrenSubscriptionAction.failure({
        message: error.non_field_errors || error || 'Fail',
        userId: payload.userId,
      }),
    );
  }
}

function* childrenSubscriptionsSaga(): SagaIterator {
  yield all([
    takeEvery(loadChildrenSubscriptions.request, loadChildrenSubscription),
    takeEvery(cancelChildrenSubscriptionAction.request, cancelStudentSubscriptionSaga),
  ]);
}

export default childrenSubscriptionsSaga;
