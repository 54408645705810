import { createAsyncAction } from 'typesafe-actions';
import { SSOAccountType, SSOProvider } from './types';

export const loadSSOAccountsAction = createAsyncAction(
  '@@sso/LOAD_REQUEST',
  '@@sso/LOAD_SUCCESS',
  '@@sso/LOAD_FAILURE',
)<undefined, SSOAccountType[], string>();

export const unlinkSSOAccountAction = createAsyncAction(
  '@@sso/UNLINK_REQUEST',
  '@@sso/UNLINK_SUCCESS',
  '@@sso/UNLINK_FAILURE',
)<SSOProvider, undefined, string>();
