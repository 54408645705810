import React from 'react';
import { useIntl } from 'react-intl';
import CreatableSelect from 'react-select/creatable';
import cn from 'classnames';

import { SelectProps } from './types';
import { Select, Option, DropdownIndicator } from '../Select/Select';

const onMenuOpen = () => {
  setTimeout(() => {
    const selectedEl = document.getElementsByClassName('form-select__option--is-selected')[0];

    if (selectedEl) {
      selectedEl.scrollIntoView({ block: 'center', inline: 'start' });
    }
  });
};

export const SelectV2 = (props: SelectProps) => {
  const {
    className,
    placeholder = '',
    isSearchable = false,
    isDisabled = false,
    maxMenuHeight = 240,
    isReadOnly = false,
    isClearable = false,
    isCreatable = false,
  } = props;

  const { formatMessage } = useIntl();

  const noOptions = formatMessage({
    id: 'dropdown.empty-options',
    defaultMessage: 'No options',
  });

  if (!isCreatable) return <Select {...props} />;

  return (
    <CreatableSelect
      {...props}
      maxMenuHeight={maxMenuHeight}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isDisabled={isDisabled || isReadOnly}
      className={cn(className, 'form-select')}
      classNamePrefix="form-select"
      noOptionsMessage={() => noOptions}
      components={{ Option, DropdownIndicator }}
      isClearable={isClearable}
      onMenuOpen={onMenuOpen}
    />
  );
};
