import styled, { css, keyframes } from 'styled-components';

import { sizeMobile } from './utils';

export const typeDashboardBlock = css`
  background-color: var(--card-bg);
  min-height: 250px;
  box-shadow: var(--card-shadow);
  border-radius: 6px;
`;

export const customScroll = css`
  //Scrollbar
  /* width */

  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-button {
    width: 10px;
    height: 10px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.colors.white};
    border-radius: 8px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.colors.selectiveYellow};
    border-radius: 8px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.colors.selectiveYellow};
  }
`;

export const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const bounceInTop = keyframes`
  0% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
`;

export const ring = keyframes`
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
`;

export const disableButton = css`
  filter: grayscale(100%);
  pointer-events: none;
`;

export const rotateCenter = keyframes`
0% {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
`;

export const heartbeat = keyframes` 
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }`;

/* Move the background and make it smaller. */
/* Animation shown when entering the page and after the hover animation. */
export const rainbowTextRev = keyframes` 
  0% {
    background-size: 650%;
  }
  40% {
    background-size: 650%;
  }
  100% {
    background-size: 100%;
  }`;

/* Move the background and make it larger. */
/* Animation shown when hovering over the text. */
export const rainbowText = keyframes` 
    0% {
      background-size: 100%;
    }
    80% {
      background-size: 650%;
    }
    100% {
      background-size: 650%;
    }
  `;

export const gradientText = css`
  animation: ${rainbowTextRev} 0.75s ease forwards;
  -webkit-background-clip: text;
  background-color: #ca4246;
  background-image: linear-gradient(
    45deg,
    #ca4246 16.666%,
    #e16541 16.666%,
    #e16541 33.333%,
    #3591a7 33.333%,
    #3591a7 50%,
    #3f6aa3 50%,
    #3f6aa3 66.666%,
    #476098 66.666%,
    #476098 83.333%,
    #a7489b 83.333%
  );
  background-repeat: repeat;
  background-size: 100%;
  -webkit-text-fill-color: transparent;

  &:hover {
    animation: ${rainbowText} 0.5s ease-in forwards;
  }
`;

export const dashboardBlock = css`
  background-color: var(--card-bg);
  min-height: 250px;
  box-shadow: var(--card-shadow);
  border-radius: 6px;
`;

export const BackDropImage = styled.img`
  display: none;
  position: absolute;
  top: -80px;
  right: 0;
  width: 100%;
  max-width: 80px;
  animation: ${bounceInTop} 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  ${sizeMobile(css`
    display: block;
  `)};

  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      right: unset;
      left: 0;
    `};
`;

export const AnimatedCharacter = styled(BackDropImage)`
  max-width: 100px;
  animation: ${ring} 6s 0.7s ease-in-out infinite;
`;
