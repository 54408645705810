import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getPaymentHistory } from './api';
import { paymentHistoryAction } from './actions';

function* getPaymentHistorySaga(): SagaIterator {
  try {
    const data = yield call(getPaymentHistory);
    yield put(paymentHistoryAction.success(data));
  } catch (error) {
    yield put(paymentHistoryAction.failure(error.message || error));
  }
}

export default function* paymentHistorySaga(): SagaIterator {
  yield takeEvery(paymentHistoryAction.request, getPaymentHistorySaga);
}
