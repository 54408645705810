import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  getGradesClassRoomsAPI,
  getSubjectsTeachersAPI,
  assignGradeToTeacherAPI,
  getTeachersDetailsAPI,
} from 'utils/apiEndpoints';

import { AssignedTeacherPayload } from './types';

export async function getGradesClassRooms(branchID: any): Promise<any> {
  const response = await API.get(getGradesClassRoomsAPI(branchID));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getSubjectsTeachers({
  classId,
}: Pick<AssignedTeacherPayload, 'classId'>): Promise<number> {
  const response = await API.get(getSubjectsTeachersAPI(classId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function assignGradeToTeacher(payload: any): Promise<number> {
  const response = await API.post(
    assignGradeToTeacherAPI(payload?.branchId),
    snakeCaseKeys(payload?.teacherData, { deep: true }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeachersDetails(payload: any): Promise<number> {
  const queryParams: any = {
    gender: payload.gender,
    name: payload.name,
    class: payload.class,
    subject: encodeURIComponent(payload.subject),
    page: payload.page,
    exclude_teacher_id: payload.teacherId,
    page_size: 4,
  };

  const response = await API.get(getTeachersDetailsAPI(payload.branchID), {
    params: queryParams,
  });
  return camelcaseKeys(response.data, { deep: true });
}
