import React, {
  FunctionComponent,
  ReactChild,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { usePopper } from 'react-popper';
import { useEvent, useLockBodyScroll, useToggle } from 'react-use';
import { ESCAPE_KEY } from '../../constants/entities';
import { Placements, Types, Strategy } from './Popover.types';
import style from './Popover.module.scss';
import { useScreenInclude } from '../../hooks/useScreenInclude';

export type PopoverProps = {
  popover: (setVisibility: any, update?: any) => ReactChild;
  type?: keyof typeof Types;
  placement?: keyof typeof Placements;
  strategy?: keyof typeof Strategy;
  modifiers?: any[];
  isUnwrapped?: boolean;
  isVisible?: boolean;
  isChangeVisibleWithBlurEvent?: boolean;
  setVisibility: (value: boolean) => void;
  popoverStyles?: object;
  arrow?: boolean;
  isVisibleConditionExternal?: boolean;
  children: ReactElement;
  className?: string;
};

const applyArrowHide = {
  name: 'applyArrowHide',
  enabled: true,
  phase: 'write',
  fn({ state }: any): void {
    const { arrow } = state.elements;
    if (arrow) {
      if (state.modifiersData.arrow.centerOffset !== 0) {
        arrow.setAttribute('data-hide', '');
      } else {
        arrow.removeAttribute('data-hide');
      }
    }
  },
};

export const Popover: FunctionComponent<PopoverProps> = ({
  children,
  popover,
  type = Types.modal,
  placement = Placements.auto,
  modifiers = [],
  isUnwrapped = false,
  isVisible = false,
  setVisibility,
  arrow = false,
  isChangeVisibleWithBlurEvent = false,
  isVisibleConditionExternal = false,
  popoverStyles,
  className,
  strategy,
}) => {
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const isMobile = useScreenInclude(['xs', 'sm']);
  const [locked, toggleLocked] = useToggle(false);
  useLockBodyScroll(locked);

  const { styles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, {
    placement,
    strategy,
    modifiers: [
      ...modifiers,
      applyArrowHide,
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
  });

  useEffect(() => {
    toggleLocked(isVisible && isMobile && type === Types.modal);
  }, [isMobile, isVisible, toggleLocked, type]);

  useEffect(() => {
    if (forceUpdate) {
      forceUpdate();
    }
  }, [forceUpdate]);

  const onEscDown = useCallback(
    ({ keyCode }) => {
      if (keyCode === ESCAPE_KEY) setVisibility(false);
    },
    [setVisibility],
  );

  const onDocumentClick = useCallback(
    (event) => {
      if (referenceElement?.contains && referenceElement.contains(event.target)) return;

      if (popperElement?.contains && !popperElement.contains(event.target)) {
        setVisibility(false);
      }
    },
    [popperElement, referenceElement, setVisibility],
  );

  useEvent('keydown', onEscDown);
  useEvent('blur', () => {
    if (isChangeVisibleWithBlurEvent) setVisibility(false);
  });
  useEvent('mousedown', onDocumentClick);
  useEvent('touchend', onDocumentClick);

  const onClick = useCallback((): void => {
    if (!isVisibleConditionExternal) setVisibility(!isVisible);
  }, [setVisibility, isVisible, isVisibleConditionExternal]);

  const onMouseEnter = useCallback((): void => {
    if (!isVisibleConditionExternal) setVisibility(true);
  }, [setVisibility, isVisibleConditionExternal]);

  const onMouseLeave = useCallback((): void => {
    if (!isVisibleConditionExternal) setVisibility(false);
  }, [setVisibility, isVisibleConditionExternal]);

  const eventHandlers = type === Types.tooltip ? { onMouseEnter, onMouseLeave } : { onClick };

  return (
    <>
      {React.Children.map(children, (child: ReactElement) => {
        return React.cloneElement(child, {
          ref: setReferenceElement,
          ...eventHandlers,
        });
      })}
      {isVisible && isUnwrapped && (
        <div
          className={className && className}
          style={{ position: 'absolute', zIndex: 5 }}
          ref={setPopperElement}
        >
          {popover(setVisibility)}
        </div>
      )}
      {isVisible && !isUnwrapped && (
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, ...popoverStyles }}
          className={cn({
            [style.popup]: type === Types.modal,
            [style.tooltip]: type === Types.tooltip,
          })}
          {...attributes.popper}
        >
          {popover(setVisibility, forceUpdate)}
          {arrow && <div ref={setArrowElement} style={styles.arrow} className={style.arrow} />}
        </div>
      )}
    </>
  );
};
