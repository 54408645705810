import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { getPngURL } from 'lib';
import { ROUTES } from 'constants/routes';

import { unreadRecommendationsSelector } from 'store/notifications/selectors';

import styles from './Header.module.scss';
import { messages } from './Header.messages';

type RecommendationMenuProps = {
  isGradeOneToThree?: boolean;
};

const RecommendationMenu = ({ isGradeOneToThree }: RecommendationMenuProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const hasRecommendations = useSelector(unreadRecommendationsSelector);

  return (
    <div
      className={cn(styles.topheader__operation, {
        [styles.recommendationMenu]: isGradeOneToThree,
      })}
      onClick={() => history.push(ROUTES.recommendation)}
      title={formatMessage(messages.recommendations)}
    >
      <img src={getPngURL('recommendation-icon')} alt={formatMessage(messages.recommendations)} />
      {hasRecommendations && <span className={styles.topheader__operation__notification} />}
    </div>
  );
};

export default RecommendationMenu;
