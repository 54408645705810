import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dashboard: {
    id: 'dashboard.title',
    defaultMessage: 'Dashboard',
  },
  smartView: {
    id: 'dashboard.smartView',
    defaultMessage: 'Smart View',
  },
  recommendations: {
    id: 'reports.recommendations',
    defaultMessage: 'recommendations',
  },
});
