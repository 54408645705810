import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  flexibleScheduleClassesAPI,
  teachersScheduleAPI,
  getTeacherWeeklyScheduleAPI,
  getAdminWeeklyScheduleAPI,
  flexibleScheduleBMClassesAPI,
} from 'utils/apiEndpoints';

import {
  ClassroomSubjectsResponseType,
  TeacherSchedulesResponseType,
  TeacherSchedulesRequestType,
  WeeklyScheduleRequestPayload,
  WeeklyScheduleResponseType,
  GetClassesPayload,
} from './types';

export async function getClasses({
  teacherId,
  branchId,
}: GetClassesPayload): Promise<ClassroomSubjectsResponseType> {
  const response = await API.get(
    branchId
      ? flexibleScheduleBMClassesAPI(branchId, teacherId)
      : flexibleScheduleClassesAPI(teacherId),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeachersSchedule({
  branchId,
  ...props
}: TeacherSchedulesRequestType): Promise<TeacherSchedulesResponseType> {
  const response = await API.get(teachersScheduleAPI(branchId), {
    params: snakeCaseKeys(props),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getWeeklyScheduleRequest(
  payload: WeeklyScheduleRequestPayload,
): Promise<WeeklyScheduleResponseType> {
  const response = await API.get(
    payload?.isAdmin ? getAdminWeeklyScheduleAPI(payload) : getTeacherWeeklyScheduleAPI(payload),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function saveWeeklyScheduleRequest(
  payload: WeeklyScheduleRequestPayload,
  requestData: WeeklyScheduleResponseType,
): Promise<WeeklyScheduleResponseType> {
  const response = await API.post(
    payload?.isAdmin ? getAdminWeeklyScheduleAPI(payload) : getTeacherWeeklyScheduleAPI(payload),
    snakeCaseKeys(requestData, { deep: true }),
  );
  return camelcaseKeys(response.data, { deep: true });
}
