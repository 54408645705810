import { compose, map } from 'lodash/fp';
import { ChildrenSubscriptionsType } from './types';

export const dataChildrenSubscriptions = (subscriptions: ChildrenSubscriptionsType) =>
  compose(
    map(({ subscriptionServices, ...rest }) => {
      return {
        subscribedServices: subscriptionServices,
        ...rest,
      };
    }),
  )(subscriptions);
