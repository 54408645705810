import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';
import * as actions from './actions';
import { loadScheduleForTodayAction, resetScheduleForTodayAction } from './actions';

export type ScheduleForTodayType = ActionType<typeof actions>;

const data = createReducer<any, ScheduleForTodayType>([])
  .handleAction(loadScheduleForTodayAction.success, (state, { payload }) => [...payload])
  .handleAction(resetScheduleForTodayAction, () => []);

const fetching = combineReducers({
  load: createReducer<boolean, ScheduleForTodayType>(true)
    .handleAction([loadScheduleForTodayAction.request, resetScheduleForTodayAction], () => true)
    .handleAction(
      [loadScheduleForTodayAction.success, loadScheduleForTodayAction.failure],
      () => false,
    ),
});

const errors = combineReducers({
  load: createReducer<string, ScheduleForTodayType>('').handleAction(
    loadScheduleForTodayAction.failure,
    (state, { payload }) => payload,
  ),
});

const scheduleForTodayReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type ScheduleForTodayStateType = StateType<typeof scheduleForTodayReducer>;

export default scheduleForTodayReducer;
