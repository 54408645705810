import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  subjectsType,
  learningObjectPayload,
  learningObjectivesResponse,
  unitsObjectPayload,
  lessonResults,
  sampleQuestionsType,
  fetchLessonsPayload,
  fetchUnitsPayload,
  loadSubjectRequestType,
  sampleQuestionsResult,
  subjectsListType,
  teacherQuestionLocatorsPayload,
  teacherUnitsAndLessonsType,
  answerDetailOptions,
  classesSubjectsType,
  CreateQuestionPayload,
  questionListPayloadType,
  questionListResponse,
  archiveQuestionPayload,
  uploadQuestionImagePayload,
  fetchClassesSearchPayload,
  TeacherGradesType,
  CreateQuestionResponse,
  TeacherSubjectParam,
  LearningObjectiveSmartScoreResponse,
  FetchLearningObjectiveSmartScorePayload,
  UpdateExpectedAnswersPayload,
  CreateUpdateLearningObjectPayload,
  CreateUpdateLearningObjectResponse,
  fetchSampleQuestionsPayload,
} from './types';

export const loadSubjectsAction = createAsyncAction(
  '@@questionBank/LOAD_REQUEST',
  '@@questionBank/LOAD_SUCCESS',
  '@@questionBank/LOAD_FAILURE',
)<undefined, Array<subjectsType>, string>();

export const fetchLearningObjectivesAction = createAsyncAction(
  '@@questionBank/FETCH_LEARNING_OBJECTIVES_REQUEST',
  '@@questionBank/FETCH_LEARNING_OBJECTIVES_SUCCESS',
  '@@questionBank/FETCH_LEARNING_OBJECTIVES_FAILURE',
)<learningObjectPayload, learningObjectivesResponse, string>();

export const loadQuestionList = createAsyncAction(
  '@@questionBankList/LOAD_REQUEST',
  '@@questionBankList/LOAD_SUCCESS',
  '@@questionBankList/LOAD_FAILURE',
)<questionListPayloadType, questionListResponse, string>();

export const fetchUnitsActions = createAsyncAction(
  '@@questionBank/FETCH_UNITS_REQUEST',
  '@@questionBank/FETCH_UNITS_SUCCESS',
  '@@questionBank/FETCH_UNITS_FAILURE',
)<fetchUnitsPayload, subjectsType, string>();

export const fetchLessonResultsActions = createAsyncAction(
  '@@questionBank/FETCH_LESSON_RESULTS_REQUEST',
  '@@questionBank/FETCH_LESSON_RESULTS_SUCCESS',
  '@@questionBank/FETCH_LESSON_RESULTS_FAILURE',
)<unitsObjectPayload, lessonResults, string>();

export const fetchSampleQuestionsActions = createAsyncAction(
  '@@questionBank/FETCH_SAMPLE_QUESTIONS_REQUEST',
  '@@questionBank/FETCH_SAMPLE_QUESTIONS_SUCCESS',
  '@@questionBank/FETCH_SAMPLE_QUESTIONS_FAILURE',
)<fetchSampleQuestionsPayload, questionListResponse, string>();

export const subjectSearchAction = createAsyncAction(
  '@@questionBank/SEARCH_SUBJECT_REQUEST',
  '@@questionBank/SEARCH_SUBJECT_SUCCESS',
  '@@questionBank/SEARCH_SUBJECT_FAILURE',
)<loadSubjectRequestType, Array<subjectsType>, string>();

export const unitsSearchActions = createAsyncAction(
  '@@questionBank/UNITS_SEARCH_REQUEST',
  '@@questionBank/UNITS_SEARCH_SUCCESS',
  '@@questionBank/UNITS_SEARCH_FAILURE',
)<fetchUnitsPayload, subjectsType, string>();

export const learningObjectiveQuizAction = createAsyncAction(
  '@@questionBank/QUIZ_REQUEST',
  '@@questionBank/QUIZ_SUCCESS',
  '@@questionBank/QUIZ_FAILURE',
)<fetchLessonsPayload, sampleQuestionsType, string>();

export const updateLearningObjectiveQuizAction = createAsyncAction(
  '@@questionBank/QUIZ_UPDATE_REQUEST',
  '@@questionBank/QUIZ_UPDATE_SUCCESS',
  '@@questionBank/QUIZ_UPDATE_FAILURE',
)<any, sampleQuestionsType, string>();

export const fetchTeacherClassesAction = createAsyncAction(
  '@@questionBank/FETCH_TEACHER_CLASSES_SUBJECTS_REQUEST',
  '@@questionBank/FETCH_TEACHER_CLASSES_SUBJECTS_SUCCESS',
  '@@questionBank/FETCH_TEACHER_CLASSES_SUBJECTS_FAILURE',
)<fetchClassesSearchPayload, Array<classesSubjectsType>, string>();

export const archiveQuestionAction = createAsyncAction(
  '@@questionBank/ARCHIVE_QUESTION_REQUEST',
  '@@questionBank/ARCHIVE_QUESTION_SUCCESS',
  '@@questionBank/ARCHIVE_QUESTION_FAILURE',
)<archiveQuestionPayload, undefined, string>();

export const fetchSubjectsGradesAction = createAsyncAction(
  '@@questionBank/FETCH_SUBJECTS_GRADES_REQUEST',
  '@@questionBank/FETCH_SUBJECTS_GRADES_SUCCESS',
  '@@questionBank/FETCH_SUBJECTS_GRADES_FAILURE',
)<undefined, Array<TeacherGradesType>, string>();

export const fetchSubjectsAction = createAsyncAction(
  '@@questionBank/FETCH_SUBJECTS_REQUEST',
  '@@questionBank/FETCH_SUBJECTS_SUCCESS',
  '@@questionBank/FETCH_SUBJECTS_FAILURE',
)<TeacherSubjectParam, Array<subjectsListType>, string>();

export const fetchSubjectRelatedUnitsAction = createAsyncAction(
  '@@questionBank/FETCH_SUBJECT_RELATED_UNITS_REQUEST',
  '@@questionBank/FETCH_SUBJECT_RELATED_UNITS_SUCCESS',
  '@@questionBank/FETCH_SUBJECT_RELATED_UNITS_FAILURE',
)<teacherQuestionLocatorsPayload, Array<teacherUnitsAndLessonsType>, string>();

export const fetchUnitRelatedLessonsAction = createAsyncAction(
  '@@questionBank/FETCH_UNIT_RELATED_LESSONS_REQUEST',
  '@@questionBank/FETCH_UNIT_RELATED_LESSONS_SUCCESS',
  '@@questionBank/FETCH_UNIT_RELATED_LESSONS_FAILURE',
)<teacherQuestionLocatorsPayload, Array<teacherUnitsAndLessonsType>, string>();

export const fetchLessonRelatedLearningObjectivesAction = createAsyncAction(
  '@@questionBank/FETCH_LESSONS_RELATED_LEARNING_OBJECTIVES_REQUEST',
  '@@questionBank/FETCH_LESSONS_RELATED_LEARNING_OBJECTIVES_SUCCESS',
  '@@questionBank/FETCH_LESSONS_RELATED_LEARNING_OBJECTIVES_FAILURE',
)<learningObjectPayload, learningObjectivesResponse, string>();

export const fetchAnswerDetailOptionsAction = createAsyncAction(
  '@@questionBank/FETCH_DIFFICULTY_LEVELS_REQUEST',
  '@@questionBank/FETCH_DIFFICULTY_LEVELS_SUCCESS',
  '@@questionBank/FETCH_DIFFICULTY_LEVELS_FAILURE',
)<undefined, Array<answerDetailOptions>, string>();

export const uploadQuestionImageAction = createAsyncAction(
  '@@questionBank/UPLOAD_QUESTION_IMAGE_REQUEST',
  '@@questionBank/UPLOAD_QUESTION_IMAGE_SUCCESS',
  '@@questionBank/UPLOAD_QUESTION_IMAGE_FAILURE',
)<uploadQuestionImagePayload, any, string>();

export const createNewQuestionAction = createAsyncAction(
  '@@questionBank/CREATE_NEW_QUESTION_REQUEST',
  '@@questionBank/CREATE_NEW_QUESTION_SUCCESS',
  '@@questionBank/CREATE_NEW_QUESTION_FAILURE',
)<CreateQuestionPayload, CreateQuestionResponse[], string>();

export const fetchLearningObjectiveSmartScoreActions = createAsyncAction(
  '@@questionBank/FETCH_LEARNING_OBJECTIVE_SMART_SCORE_REQUEST',
  '@@questionBank/FETCH_LEARNING_OBJECTIVE_SMART_SCORE_SUCCESS',
  '@@questionBank/FETCH_LEARNING_OBJECTIVE_SMART_SCORE_FAILURE',
)<FetchLearningObjectiveSmartScorePayload, LearningObjectiveSmartScoreResponse, string>();

export const updateExpectedAnswersAction = createAsyncAction(
  '@@questionBank/UPDATE_EXPECTED_ANSWERS_REQUEST',
  '@@questionBank/UPDATE_EXPECTED_ANSWERS_SUCCESS',
  '@@questionBank/UPDATE_EXPECTED_ANSWERS_FAILURE',
)<UpdateExpectedAnswersPayload, undefined, string>();

export const updateLearningObjectiveSmartScoreActions = createAction(
  '@@questionBank/UPDATE_LEARNING_OBJECTIVE_SMART_SCORE',
)<number>();

export const submitQuestionCounter = createAction(
  '@@questionBank/QUESTION_SUBMIT_COUNTER',
)<number>();

export const questionStatusUpdateAction = createAction(
  '@@questionBank/UPDATE_QUESTION_STATUS',
)<boolean>();

export const lastAttemptedProblemAction = createAction(
  '@@questionBank/UPDATE_LAST_ATTEMPTED_PROBLEM',
)<sampleQuestionsResult>();

export const nextProblemAction = createAction(
  '@@questionBank/UPDATE_Next_PROBLEM',
)<sampleQuestionsResult>();

export const createUpdateLearningObjectiveAction = createAsyncAction(
  '@@questionBank/CREATE_UPDATE_LEARNING_OBJECTIVE_REQUEST',
  '@@questionBank/CREATE_UPDATE_LEARNING_OBJECTIVE_SUCCESS',
  '@@questionBank/CREATE_UPDATE_LEARNING_OBJECTIVE_FAILURE',
)<CreateUpdateLearningObjectPayload, CreateUpdateLearningObjectResponse, string>();

export const resetSampleQuestions = createAction('@@questionBank/RESET_SAMPLE_QUESTIONS')();

export const resetSubjectAction = createAction('@@questionBank/RESET')();
