import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import {
  getForgetPasswordV2API,
  getGenerateOTPAPI,
  getResendPasswordV2API,
  getVerifyOTPAPI,
  verifyOTPUrl,
  generateOTPUrl,
} from 'utils/apiEndpoints';
import API from 'utils/api';

import { dataAccountAdapter, dataUpdateAccountAdapter } from './adapter';
import {
  MeType,
  AccountType,
  SetPasswordResponseType,
  OtpVerifyResponseType,
  ForgetPasswordPayloadType,
  GenerateOtpResponseType,
  OtpPayloadType,
} from './types';
import { OtpSendRequestType } from '../registration/types';

export async function getMe(): Promise<MeType> {
  const response = await API.get('/api/user/v1/me');

  return camelcaseKeys(response.data, { deep: true });
}

export async function getAccount(username: string): Promise<AccountType> {
  const response = await API.get(`/api/user/v1/accounts/${username}`);
  return dataAccountAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function updateAccount(
  username: string,
  account: Partial<AccountType>,
): Promise<AccountType> {
  const response = await API.patch(
    `/api/user/v1/accounts/${username}`,
    snakeCaseKeys(dataUpdateAccountAdapter(account), { deep: true }),
    {
      headers: { 'Content-Type': 'application/merge-patch+json' },
    },
  );

  return dataAccountAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function uploadAvatar(username: string, file: File): Promise<void> {
  const data = new FormData();
  data.append('file', file);

  await API.post(`/api/profile_images/v1/${username}/upload`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function resetPassword(email: string): Promise<void> {
  const data = new FormData();
  data.append('email', email);

  await API.post('/password_reset/', data, { responseType: 'text' });
}

export async function resetPasswordV2(payload: ForgetPasswordPayloadType): Promise<void> {
  const data = new FormData();
  data.append('new_password1', payload.new_password1);
  data.append('new_password2', payload.new_password2);

  await API.post(getResendPasswordV2API(), data, { responseType: 'text' });
}

export async function forgetPasswordV2(email: string): Promise<void> {
  const data = new FormData();
  data.append('email', email);

  const response = await API.post(getForgetPasswordV2API(), data, {
    responseType: 'text',
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function inviteParent(parentEmail: string): Promise<void> {
  const data = new FormData();
  data.append('parent_email', parentEmail);

  const response = await API.post('/api/alw/invitation/parent/', data, {
    responseType: 'text',
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function setPassword(
  password: string,
  token: string,
  email: string,
): Promise<SetPasswordResponseType> {
  const data = new FormData();
  data.append('new_password1', password);
  data.append('new_password2', password);

  const response = await API.post(`/set-password/${token}/${email}/`, data, {
    responseType: 'text',
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function login(email: string, password: string, loginPage: string): Promise<any> {
  const params = new URLSearchParams();
  params.append('email', email);
  params.append('password', password);
  params.append('login-page', loginPage);
  params.append('remember', 'true');

  const response = await API.post('/api/mentora/auth/alw-login/', params, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    responseType: 'text',
  });
  return response.data;
}

export async function verifyOtpLogin(
  email: string,
  signature: string,
  loginPage: string,
  otp: string,
): Promise<any> {
  const data = new FormData();
  data.append('email', email);
  data.append('signature', signature);
  data.append('otp', otp);
  data.append('login-page', loginPage);
  data.append('remember', 'true');

  const response = await API.post('/api/mentora/auth/verify-opt-login/', data, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    responseType: 'text',
  });
  return response.data;
}

export async function logout(): Promise<void> {
  await API.get('/logout');
}

export async function getCSRF(): Promise<string> {
  const response = await API.get('/api/mentora/auth/csrftoken');

  return response.data.csrfToken;
}

export async function activateAccount(token: string): Promise<string> {
  const response = await API.get(`/api/mentora/auth/activate/${token}`);

  return response.data.success;
}

export async function confirmEmail(token: string): Promise<void> {
  await API.get(`/email-confirm/${token}`);
}

export async function generateLoginOtp(phone: string): Promise<GenerateOtpResponseType> {
  const data = new FormData();
  data.append('phone', phone);

  const response = await API.post(getGenerateOTPAPI(), data);
  return camelcaseKeys(response.data);
}

export async function verifyPhoneOtpLogin(
  phone: string,
  signature: string,
  otp: string,
): Promise<OtpVerifyResponseType> {
  const data = new FormData();
  data.append('phone', phone);
  data.append('signature', signature);
  data.append('otp', otp);

  const response = await API.post(getVerifyOTPAPI(), data);
  return camelcaseKeys(response.data, { deep: true });
}

export async function generateOTP(payload: Partial<OtpSendRequestType>) {
  const response = await API.post(generateOTPUrl(), payload);
  return camelcaseKeys(response.data);
}

export async function verifyOTP(payload: OtpPayloadType) {
  const response = await API.post(verifyOTPUrl(), snakeCaseKeys(payload));
  return camelcaseKeys(response.data);
}
