import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  archiveStudentAPI,
  b2bStudentUpdatePassword,
  studentDetailAPI,
  updateStudentAPI,
} from 'utils/apiEndpoints';
import {
  StudentInfoPayload,
  StudentInfoType,
  DeletePayloadData,
  updatePasswordPayload,
} from './types';

import { UpdateStudentType } from '../schoolStudent/types';

export async function getSubjects(payload: StudentInfoPayload): Promise<StudentInfoType> {
  const response = await API.get(studentDetailAPI(payload.branch, payload.id));
  return camelcaseKeys(response.data, { deep: true });
}

export async function deleteStudent(payload: DeletePayloadData): Promise<number> {
  const response = await API.patch(archiveStudentAPI(payload.branch, payload.id));
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateStudentPassword(
  branchId: number,
  studentId: number,
  body: updatePasswordPayload,
): Promise<number> {
  const response = await API.patch(
    b2bStudentUpdatePassword(branchId, studentId),
    snakeCaseKeys(body),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateStudent(data: UpdateStudentType): Promise<UpdateStudentType> {
  const {
    payload: {
      profileImage,
      lastName,
      phoneNumber,
      email,
      firstName,
      dateOfBirth,
      confirmPassword,
      gender,
      address,
      classroom,
      password,
    },
    branch,
    studentId,
  } = data;

  const formData = new FormData();

  formData.append('profile_image', profileImage || '');
  formData.append('last_name', lastName);
  formData.append('phone_number', phoneNumber);
  formData.append('email', email);
  formData.append('first_name', firstName);
  formData.append('date_of_birth', dateOfBirth || '');
  formData.append('confirm_password', confirmPassword || '');
  formData.append('gender', gender);
  formData.append('address', address);
  formData.append('classroom', classroom);
  formData.append('password', password || '');

  const response = await API.put(updateStudentAPI(branch, studentId), formData);

  return camelcaseKeys(response.data, { deep: true });
}
