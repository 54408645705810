import { createSelector } from 'reselect';
import { RootStateType } from '../types';
import { lessonsWeekScheduleType } from './reducer';

export const selectorLessonsWeekSchedule = (state: RootStateType): lessonsWeekScheduleType => {
  return {
    ...state.lessonsWeekSchedule,
  };
};

export const lessonsWeekListDataSelector = createSelector(
  selectorLessonsWeekSchedule,
  ({ data }) => data,
);

export const selectorLessonsWeekList = createSelector(
  selectorLessonsWeekSchedule,
  ({ data }) => data.lessons,
);

export const selectorOverdueLessons = createSelector(
  selectorLessonsWeekList,
  (lessons) => lessons.overdue,
);
