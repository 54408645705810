export const DEFAULT_OPTIONS_PIE = {
  plugins: {
    legend: { display: false },
    tooltip: { enabled: false },
  },
  maintainAspectRatio: false,
};

export const DEFAULT_OPTIONS_DOUGHNUT = {
  ...DEFAULT_OPTIONS_PIE,
  cutout: '85%',
  animation: { duration: 2000 },
};
