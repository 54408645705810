import { groupBy, compose, mapValues } from 'lodash/fp';
import {
  ContentLibraryResponsType,
  ContentLibraryType,
  ContentTypeSubjectType,
  ContentTypeLessonType,
  ContentLibraryUnitResponseType,
  ContentTypeUnitType,
  LiveEventType,
  ContentLibrarySubjectResponseType,
  LiveEventGroupByGradeType,
} from './types';

const LIVE_CLASS_UNIT = 'LIVE_CLASS_UNIT_KEY';

export const dataLessonsAdapter = (lessons?: any): Array<any> => {
  if (!lessons) return [];

  return Object.keys(lessons)
    .map((lesson) => ({
      lessonId: lesson,
      id: lessons[lesson].id,
      lessonName: lessons[lesson].lesson_name || lessons[lesson].name,
      recordingLink: lessons[lesson].recording_link,
    }))
    .sort((a: ContentTypeLessonType, b: ContentTypeLessonType) =>
      lessons[a.lessonId].index > lessons[b.lessonId].index ? 1 : -1,
    );
};

export const dataUnitsAdapter = (
  units?: ContentLibraryUnitResponseType,
): Array<ContentTypeUnitType> => {
  if (!units) return [];
  return Object.keys(units)
    .map((unit) => {
      const objUnit = units[unit];

      return {
        unitId: unit,
        unitName: objUnit?.unit_name || objUnit?.eventName,
        weekNumber: objUnit?.weekNumber,
        lessonId: objUnit?.eventId,
        startDate: objUnit?.startDate,
        recordings: objUnit?.recordings,
        eventId: objUnit?.eventId,
        isOverflowStudent: objUnit?.isOverflowStudent,
        lessons: dataLessonsAdapter(objUnit?.lessons),
        isLiveEventUnit: unit === LIVE_CLASS_UNIT || objUnit.unit === LIVE_CLASS_UNIT,
      };
    })
    .sort((a: ContentTypeUnitType, b: ContentTypeUnitType) =>
      units[a.unitId].index > units[b.unitId].index ? 1 : -1,
    );
};

export const dataContentLibraryAdapter = (
  content: ContentLibraryResponsType,
): Array<ContentLibraryType> =>
  Object.keys(content).map((grade) => {
    const objGrade = content[grade];
    return {
      grade,
      subjects: Object.keys(objGrade)
        .map((subject) => {
          const objSubject = objGrade[subject];
          return {
            subjectId: subject,
            subjectName: objSubject.subject_name,
            units: dataUnitsAdapter(objSubject.units),
          };
        })
        .sort((a: ContentTypeSubjectType, b: ContentTypeSubjectType) =>
          a.subjectName > b.subjectName ? 1 : -1,
        ),
    };
  });

export const dataLiveClassContentAdapter = (
  liveEvents: Array<LiveEventType>,
): LiveEventGroupByGradeType => {
  return compose(
    mapValues((grade: any) => groupBy('subjectId', grade)),
    groupBy('grade'),
  )(liveEvents);
};

export const convertLiveClassesToUnits = (
  liveEvents: Array<LiveEventType>,
): Array<ContentLibraryUnitResponseType> | any =>
  liveEvents.map((liveEvent) => ({ ...liveEvent, unit: LIVE_CLASS_UNIT }));

export const convertLiveClassesToSubjects = (
  liveEventsGroupBySubject: Array<LiveEventType>,
): ContentLibrarySubjectResponseType => {
  const [firstEvent] = liveEventsGroupBySubject;

  return {
    subject_name: firstEvent.subjectName,
    units: convertLiveClassesToUnits(liveEventsGroupBySubject),
  };
};

export const mapperLessonWithLiveClassLessons = (
  lessons: ContentLibraryResponsType,
  liveEvents: Array<LiveEventType>,
): Array<ContentLibraryType> => {
  const lessonsWithEvents = { ...lessons };
  const liveEventsGroupByGrade = dataLiveClassContentAdapter(liveEvents);

  Object.keys(liveEventsGroupByGrade).forEach((grade: string): void => {
    if (grade in lessonsWithEvents) {
      const currentGrade = liveEventsGroupByGrade[Number(grade)];
      Object.keys(currentGrade).forEach((subject) => {
        if (subject in lessonsWithEvents[Number(grade)]) {
          lessonsWithEvents[Number(grade)][subject].units = {
            ...lessonsWithEvents[Number(grade)][subject].units,
            ...convertLiveClassesToUnits(currentGrade[subject]),
          };
        } else {
          lessonsWithEvents[Number(grade)][subject] = convertLiveClassesToSubjects(
            currentGrade[subject],
          );
        }
      });
    } else {
      lessonsWithEvents[Number(grade)] = {};
      Object.keys(liveEventsGroupByGrade[Number(grade)]).forEach((subject) => {
        lessonsWithEvents[Number(grade)][subject] = convertLiveClassesToSubjects(
          liveEventsGroupByGrade[Number(grade)][subject],
        );
      });
    }
  });

  return dataContentLibraryAdapter(lessonsWithEvents);
};
