export const isTrue = (val?: string): boolean => val?.toLowerCase() === 'true';

export const ENV = import.meta.env;
export const APP_API_URL = ENV.VITE_API_URL;
export const CSRF_TOKEN_KEY = ENV.VITE_CSRF_TOKEN_KEY;
export const RASA_TOKEN = ENV.VITE_RASA_TOKEN;
export const RASA_BASE_URL = ENV.VITE_RASA_BASE_URL;
export const HYPERPAY_URL = ENV.VITE_HYPERPAY_URL;
export const PAYMENT_URL = ENV.VITE_PAYMENT_URL;
export const EMAIL_URL = ENV.VITE_EMAIL_URL;
export const FLIPBOOK_CDN = ENV.VITE_FLIPBOOK_CDN;
export const SUMMER_SEMESTER_START_DATE = ENV.VITE_SUMMER_SEMESTER_START_DATE;
export const SUMMER_SEMESTER_END_DATE = ENV.VITE_SUMMER_SEMESTER_END_DATE;
export const GOOGLE_KEY = ENV.VITE_GOOGLE_KEY || '';
export const GOOGLE_CLIENT_ID = ENV.VITE_GOOGLE_CLIENT_ID;
export const FAKE_EMAIL = ENV.VITE_FAKE_EMAIL_DOMAIN || '';
export const CAPTCHA_KEY = ENV.VITE_CAPTCHA_KEY || '';
export const CHAT_GROUP_MESSAGE_COUNT = ENV.VITE_CHAT_GROUP_MESSAGES_COUNT || 100;
export const FACEBOOK_KEY = ENV.VITE_FACEBOOK_KEY || '';
export const TEACHER_SAMPLE_URL = ENV.VITE_URL_TEACHER_SAMPLE;
export const STUDENT_SAMPLE_URL = ENV.VITE_URL_STUDENT_SAMPLE;
export const SITEPAL_FEMALE_CHARACTER_ID = ENV.VITE_SITEPAL_FEMALE_CHARACTER_ID;
export const MAX_FILE_SIZE = Number(ENV.VITE_MAX_FILE_UPLOAD_SIZE) || 15728640;
export const SPEECH_TO_TEXT_API_KEY = ENV.VITE_GOOGLE_SPEECH_TO_TEXT_API_KEY;
export const PRIVACY_POLICY_EN = ENV.VITE_PRIVACY_POLICY_EN;
export const PRIVACY_POLICY_AR = ENV.VITE_PRIVACY_POLICY_AR;
export const HIDE_GRADE_ONE_TO_THREE = ENV.VITE_FEATURE_HIDE_GRADE_ONE_TO_THREE;
export const ASSETS_URL = ENV.VITE_CDN_ASSETS;
export const ENABLE_SMART_SWITCHER = ENV.VITE_ENABLE_SMART_SWITCHER === 'true';
export const TRACKING_PAGES = ENV.VITE_TRACKING_PAGES;
export const LANDING_URL_EN = ENV.VITE_LANDING_URL_EN;
export const LANDING_URL_AR = ENV.VITE_LANDING_URL_AR;
export const ORIENTATION_VIDEO_ENABLED_ROLES = ENV.VITE_ORIENTATION_VIDEO_ENABLED_ROLES;
export const SENTRY_DSN = ENV.VITE_SENTRY_DSN;
export const DISABLE_EXAMS_USER_EMAIL = ENV.VITE_DISABLE_EXAMS_USER_EMAIL;

export const DISABLE_B2C_GROUP_CHAT = isTrue(ENV.VITE_HIDE_B2C_CHAT_GROUP);
export const DISABLE_B2C_REWARDS = isTrue(ENV.VITE_HIDE_B2C_REWARDS);
export const DISABLE_B2B_GRADING = isTrue(ENV.VITE_DISABLE_B2B_GRADING);
export const DISABLE_B2C_RECOMMENDATIONS = isTrue(ENV.VITE_HIDE_B2C_RECOMMENDATIONS);
export const ENABLE_TOURS = isTrue(ENV.VITE_ENABLE_TOURS);
export const ENABLE_GOOGLE_ANALYTICS = isTrue(ENV.VITE_ENABLE_B2C_CAMPIGN_GOOGLE_ANALYTICS);
export const DISABLE_ARS = isTrue(ENV.VITE_DISABLE_ARS);
export const ENABLE_DASHBOARD_WARNINGS = isTrue(ENV.VITE_ENABLE_DASHBOARD_WARNINGS);
export const IS_FACEBOOK_SIGNIN_ENABLED = isTrue(ENV.VITE_ENABLE_FACEBOOK_SIGNIN);
export const IS_HIGHER_GRADE_DISABLED = isTrue(ENV.VITE_DISABLE_HIGHER_GRADE);
export const HIDE_EXAMS_FEATURE = isTrue(ENV.VITE_FEATURE_HIDE_EXAMS);
export const DISABLE_CAPTCHA = isTrue(ENV.VITE_DISABLE_CAPTCHA);
export const IS_DEV_ENVIRONMENT = ENV.DEV;
