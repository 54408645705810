import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  getAccessTokenAction,
  groupDetailAction,
  listGroupAction,
  blockParticipantAction,
  unblockParticipantAction,
  getSpamWords,
  addChatGroupTitle,
  getBlockStatus,
  getPeer,
} from './actions';
import {
  getUserAccessToken,
  getGroupList,
  getGroupDetails,
  blockParticipants,
  unblockParticipants,
  filteredWords,
  getUserBlockStatus,
  addChatGroupPinMessage,
  getPeerAPI,
} from './api';

function* getAccessToken(): SagaIterator<void> {
  try {
    const accessToken = yield call(getUserAccessToken);
    yield put(getAccessTokenAction.success(accessToken));
  } catch (error) {
    yield put(getAccessTokenAction.failure(error));
  }
}

function* getBlockGroupStatus({
  payload,
}: ReturnType<typeof getBlockStatus.request>): SagaIterator<void> {
  try {
    const status = yield call(getUserBlockStatus, payload);
    yield put(getBlockStatus.success(status));
  } catch (error) {
    yield put(getBlockStatus.failure(error));
  }
}

function* addGroupTopic({
  payload,
}: ReturnType<typeof addChatGroupTitle.request>): SagaIterator<void> {
  try {
    const accessToken = yield call(addChatGroupPinMessage, payload);
    yield put(addChatGroupTitle.success(accessToken));
    payload.callback();
  } catch (error) {
    yield put(addChatGroupTitle.failure(error));
  }
}

function* getSpamWordsList(): SagaIterator<void> {
  try {
    const words = yield call(filteredWords);
    yield put(getSpamWords.success(words));
  } catch (error) {
    yield put(getSpamWords.failure(error));
  }
}

function* getGroups(): SagaIterator<void> {
  try {
    const groups = yield call(getGroupList);
    yield put(listGroupAction.success(groups));
  } catch (error) {
    yield put(listGroupAction.failure(error));
  }
}

function* getGroupDetail({
  payload,
}: ReturnType<typeof groupDetailAction.request>): SagaIterator<void> {
  try {
    const groupDetails = yield call(getGroupDetails, payload);
    yield put(groupDetailAction.success(groupDetails));
  } catch (error) {
    yield put(groupDetailAction.failure(error));
  }
}

function* getPeers(): SagaIterator<void> {
  try {
    const groupDetails = yield call(getPeerAPI);
    yield put(getPeer.success(groupDetails));
  } catch (error) {
    yield put(getPeer.failure(error));
  }
}

function* blockGroupUsers({
  payload,
}: ReturnType<typeof blockParticipantAction.request>): SagaIterator<void> {
  try {
    const groupDetails = yield call(blockParticipants, payload);
    yield put(blockParticipantAction.success(groupDetails));
  } catch (error) {
    yield put(blockParticipantAction.failure(error));
  }
}

function* unblockGroupUsers({
  payload,
}: ReturnType<typeof unblockParticipantAction.request>): SagaIterator<void> {
  try {
    const groupDetails = yield call(unblockParticipants, payload);
    yield put(unblockParticipantAction.success(groupDetails));
  } catch (error) {
    yield put(unblockParticipantAction.failure(error));
  }
}

function* groupChat(): SagaIterator {
  yield all([
    takeEvery(getAccessTokenAction.request, getAccessToken),
    takeEvery(getBlockStatus.request, getBlockGroupStatus),
    takeEvery(getPeer.request, getPeers),
    takeEvery(getSpamWords.request, getSpamWordsList),
    takeEvery(addChatGroupTitle.request, addGroupTopic),
    takeEvery(listGroupAction.request, getGroups),
    takeEvery(groupDetailAction.request, getGroupDetail),
    takeEvery(blockParticipantAction.request, blockGroupUsers),
    takeEvery(unblockParticipantAction.request, unblockGroupUsers),
  ]);
}

export default groupChat;
