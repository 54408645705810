import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  loadGradeStudentsAction,
  loadGradesAction,
  loadSubjectsAction,
  loadFiltersDataAction,
  resetContentLibraryAction,
  loadContentDataAction,
  resetSubjectsContentLibraryAction,
  loadStudentDataAction,
  assignContentDataAction,
  resetAssignContentLibraryAction,
  completeContentDataAction,
  uploadAndAssignContentAction,
  selfAssignContentAction,
  loadClassroomStudentsAction,
} from './actions';
import {
  FiltersDataType,
  ContentLibraryType,
  ContentLibraryStudentsByGradeType,
  AssignContentRequestType,
  AssignContentResponseType,
  ContentLibrarySubjectsType,
  ContentLibraryGradesType,
  UploadAndAssignContentRequestType,
  LiveEventsType,
} from './types';

export type ContentLibraryActionType = ActionType<typeof actions>;

const data = combineReducers({
  content: createReducer<ContentLibraryType[], ContentLibraryActionType>([] as ContentLibraryType[])
    .handleAction(loadContentDataAction.success, (state, action) => action.payload)
    .handleAction(resetContentLibraryAction, () => [])
    .handleAction(resetSubjectsContentLibraryAction, () => [])
    .handleAction(loadContentDataAction.failure, () => []),
  filter: createReducer<FiltersDataType, ContentLibraryActionType>({} as FiltersDataType)
    .handleAction(loadFiltersDataAction.success, (state, action) => action.payload)
    .handleAction(resetContentLibraryAction, () => ({})),
  students: createReducer<ContentLibraryStudentsByGradeType[], ContentLibraryActionType>(
    [] as ContentLibraryStudentsByGradeType[],
  )
    .handleAction(loadStudentDataAction.success, (state, action) => action.payload)
    .handleAction(resetContentLibraryAction, () => []),
  subjects: createReducer<ContentLibrarySubjectsType, ContentLibraryActionType>([])
    .handleAction(loadSubjectsAction.success, (state, action) => action.payload)
    .handleAction(resetContentLibraryAction, () => []),
  liveEvents: createReducer<Array<LiveEventsType>, ContentLibraryActionType>([]).handleAction(
    actions.liveEventsAction,
    (state, action) => action.payload,
  ),
  gradeStudents: createReducer<any, ContentLibraryActionType>([])
    .handleAction(loadGradeStudentsAction.success, (state, action) => action.payload)
    .handleAction(resetContentLibraryAction, () => []),
  classroomStudents: createReducer<any, ContentLibraryActionType>([])
    .handleAction(loadClassroomStudentsAction.success, (state, action) => action.payload)
    .handleAction(resetContentLibraryAction, () => []),
  grades: createReducer<ContentLibraryGradesType, ContentLibraryActionType>([])
    .handleAction(loadGradesAction.success, (state, action) => action.payload)
    .handleAction(resetContentLibraryAction, () => []),
  assign: createReducer<Array<Partial<AssignContentRequestType>>, ContentLibraryActionType>(
    [] as AssignContentRequestType[],
  )
    .handleAction(assignContentDataAction.success, (state, action) => [...state, action.payload])
    .handleAction(resetContentLibraryAction, () => [])
    .handleAction(resetAssignContentLibraryAction, () => []),
  uploadAndAssign: createReducer<any, ContentLibraryActionType>(
    [] as UploadAndAssignContentRequestType[],
  )
    .handleAction(uploadAndAssignContentAction.success, (state, action) => [
      ...state,
      action.payload,
    ])
    .handleAction(resetContentLibraryAction, () => [])
    .handleAction(resetAssignContentLibraryAction, () => []),
  selfAssign: createReducer<any, ContentLibraryActionType>([])
    .handleAction(selfAssignContentAction.success, (state, action) => action.payload)
    .handleAction(resetContentLibraryAction, () => []),
  completeLesson: createReducer<Partial<AssignContentResponseType>, ContentLibraryActionType>(
    {} as AssignContentResponseType,
  )
    .handleAction(completeContentDataAction.success, (state, action) => action.payload)
    .handleAction(resetContentLibraryAction, () => ({}))
    .handleAction(resetAssignContentLibraryAction, () => ({})),
});

const fetching = combineReducers({
  content: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(loadContentDataAction.request, () => true)
    .handleAction([loadContentDataAction.success, loadContentDataAction.failure], () => false),
  filter: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(loadFiltersDataAction.request, () => true)
    .handleAction([loadFiltersDataAction.success, loadFiltersDataAction.failure], () => false),
  students: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(loadStudentDataAction.request, () => true)
    .handleAction([loadStudentDataAction.success, loadStudentDataAction.failure], () => false),
  gradeStudents: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(loadGradeStudentsAction.request, () => true)
    .handleAction([loadGradeStudentsAction.success, loadGradeStudentsAction.failure], () => false),
  classroomStudents: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(loadClassroomStudentsAction.request, () => true)
    .handleAction(
      [loadClassroomStudentsAction.success, loadClassroomStudentsAction.failure],
      () => false,
    ),
  subjects: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(loadSubjectsAction.request, () => true)
    .handleAction([loadSubjectsAction.success, loadSubjectsAction.failure], () => false),
  assign: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(assignContentDataAction.request, () => true)
    .handleAction([assignContentDataAction.success, assignContentDataAction.failure], () => false),
  uploadAndAssign: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(uploadAndAssignContentAction.request, () => true)
    .handleAction(
      [uploadAndAssignContentAction.success, uploadAndAssignContentAction.failure],
      () => false,
    ),
  selfAssign: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(selfAssignContentAction.request, () => true)
    .handleAction([selfAssignContentAction.success, selfAssignContentAction.failure], () => false),
  completeLesson: createReducer<boolean, ContentLibraryActionType>(false)
    .handleAction(completeContentDataAction.request, () => true)
    .handleAction(
      [completeContentDataAction.success, completeContentDataAction.failure],
      () => false,
    ),
});

const errors = combineReducers({
  content: createReducer<string, ContentLibraryActionType>('')
    .handleAction(loadContentDataAction.failure, (state, { payload }) => payload)
    .handleAction(
      [loadContentDataAction.success, resetContentLibraryAction, resetSubjectsContentLibraryAction],
      () => '',
    ),
  filter: createReducer<string, ContentLibraryActionType>('')
    .handleAction(loadFiltersDataAction.failure, (state, { payload }) => payload)
    .handleAction([loadFiltersDataAction.success, resetContentLibraryAction], () => ''),
  students: createReducer<string, ContentLibraryActionType>('')
    .handleAction(loadStudentDataAction.failure, (state, { payload }) => payload)
    .handleAction([loadStudentDataAction.success, resetContentLibraryAction], () => ''),
  gradeStudents: createReducer<string, ContentLibraryActionType>('')
    .handleAction(loadGradeStudentsAction.failure, (state, { payload }) => payload)
    .handleAction([loadGradeStudentsAction.success, resetContentLibraryAction], () => ''),
  classroomStudents: createReducer<string, ContentLibraryActionType>('')
    .handleAction(loadClassroomStudentsAction.failure, (state, { payload }) => payload)
    .handleAction([loadClassroomStudentsAction.success, resetContentLibraryAction], () => ''),
  subjects: createReducer<string, ContentLibraryActionType>('')
    .handleAction(loadSubjectsAction.failure, (state, { payload }) => payload)
    .handleAction([loadSubjectsAction.success, resetContentLibraryAction], () => ''),
  assign: createReducer<string, ContentLibraryActionType>('')
    .handleAction(assignContentDataAction.failure, (state, { payload }) => payload)
    .handleAction(
      [assignContentDataAction.success, resetContentLibraryAction, resetAssignContentLibraryAction],
      () => '',
    ),
  selfAssign: createReducer<string, ContentLibraryActionType>('')
    .handleAction(selfAssignContentAction.failure, (state, { payload }) => payload)
    .handleAction(
      [selfAssignContentAction.success, resetContentLibraryAction, resetAssignContentLibraryAction],
      () => '',
    ),
  uploadAndAssign: createReducer<string, ContentLibraryActionType>('')
    .handleAction(uploadAndAssignContentAction.failure, (state, { payload }) => payload)
    .handleAction(
      [
        uploadAndAssignContentAction.success,
        resetContentLibraryAction,
        resetAssignContentLibraryAction,
      ],
      () => '',
    ),
  completeLesson: createReducer<string, ContentLibraryActionType>('')
    .handleAction(completeContentDataAction.failure, (state, { payload }) => payload)
    .handleAction(
      [
        completeContentDataAction.success,
        resetContentLibraryAction,
        resetAssignContentLibraryAction,
      ],
      () => '',
    ),
});

const contentLibraryReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type ContentLibraryStateType = StateType<typeof contentLibraryReducer>;

export default contentLibraryReducer;
