import React, { ReactElement, useContext, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useToggle, useClickAway, useKey, useEvent } from 'react-use';

import { Avatar, IconCommon } from 'lib';

import { ROUTES } from 'constants/routes';
import { SELECTED_ROLE } from 'constants/entities';
import { useScreenInclude } from 'hooks/useScreenInclude';
import { getRedirectionUrl } from 'utils/helpers';

import UserPassword from 'lib/UserPassword/UserPassword';
import { TourContext } from 'components/TourManagement/TourManagement';
import { ProfileDetailType } from 'components/LayoutBase/v2/types';

import { Role } from 'store/account/types';
import { updateAccountRoleAction } from 'store/account/actions';
import {
  isAlwTeacherMentorRoleSelector,
  isB2bStudentRoleSelector,
  isStudentRoleSelector,
  studentGradeSelector,
} from 'store/account/selectors';

import HelpDialog from 'modules/GradeOneToThree/components/BaseLayout/TopHeader/HelpDialog';

import AccountLang from './components/AccountLang';
import styles from './LogoutMenu.module.scss';
import { messages } from './messages';

export const LogoutMenu = ({
  firstName,
  src,
  srcLarge,
  isGradeOneToThree,
  email,
  password,
}: ProfileDetailType): ReactElement => {
  const { formatMessage: fm } = useIntl();
  const [isOpenLogoutTooltip, setLogoutTooltip] = useToggle(false);
  const { isExist, handleTourOpen } = useContext(TourContext);
  const currentEl = useRef(null);
  const dispatch = useDispatch();
  const isMobile = useScreenInclude(['xs', 'sm']);
  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isAlwTeacherMentor = useSelector(isAlwTeacherMentorRoleSelector);
  const isStudent = useSelector(isStudentRoleSelector);
  const grade = useSelector(studentGradeSelector);
  const isB2BStudentRole = useSelector(isB2bStudentRoleSelector);

  useClickAway(currentEl, () => {
    setLogoutTooltip(false);
  });

  useKey('Escape', () => {
    setLogoutTooltip(false);
  });

  useEvent('blur', () => setLogoutTooltip(false));

  const handleTour = () => {
    setLogoutTooltip(false);
    handleTourOpen();
  };

  const onRoleChange = (role: number) => {
    dispatch(updateAccountRoleAction(role));
    localStorage.setItem(SELECTED_ROLE, role.toString());
    history.push(ROUTES.roleSelection);
  };

  const handleOpenHelpDialog = () => {
    if (!isGradeOneToThree) {
      history.push(ROUTES.help);
      return;
    }

    setLogoutTooltip(false);
    setIsDialogOpen(true);
  };

  return (
    <div
      id="topbar__logout"
      ref={currentEl}
      className={cn(styles.logutMenu, { [styles.grade]: isGradeOneToThree })}
    >
      <div
        role="button"
        className={cn(styles.topheader__operation, styles.iconText)}
        onClick={setLogoutTooltip}
      >
        <Avatar size={24} src={src} srcLarge={srcLarge} />
        {!isMobile ? firstName : ''}
      </div>
      {isOpenLogoutTooltip && (
        <div className={styles.menu}>
          <div className={styles.menu__user}>
            {email && (
              <Link to={ROUTES.account} className={styles.menu__name} title={email}>
                {email}
              </Link>
            )}
            <div className={styles.menu__role}>
              {password && <UserPassword password={password} />}
              {isStudent && grade && (
                <span className={styles.menu__gradeLabel}>
                  {fm(isB2BStudentRole ? messages.classRoom : messages.gradeValue, { grade })}
                </span>
              )}
              {isAlwTeacherMentor && (
                <span onClick={() => onRoleChange(Role.teacherAndMentor)} role="button">
                  {fm(messages.switchRole)}
                </span>
              )}
            </div>
          </div>
          <div className={styles.menu__items}>
            <div className={styles.menu__item} onClick={handleOpenHelpDialog}>
              <IconCommon className="icon-help" classes={styles.menu__icon} />
              {fm(messages.help)}
            </div>

            <Link to={ROUTES.account} className={styles.menu__item}>
              <IconCommon className="icon-setting" classes={styles.menu__icon} />
              {fm(messages.settings)}
            </Link>

            {isExist && (
              <span role="button" onClick={handleTour} className={styles.menu__item}>
                <IconCommon className="icon-tour" classes={styles.menu__icon} />
                {fm(messages.startTour)}
              </span>
            )}

            <div className={styles.menu__item}>
              <IconCommon className="icon-global-01" classes={styles.menu__icon} />
              <AccountLang type={isGradeOneToThree ? 'yellow' : 'primary'} />
            </div>

            <div className={styles.menu__item}>
              <IconCommon className="icon-signout" classes={styles.menu__icon} />

              <button
                className={styles['logout-button']}
                onClick={() => history.push(getRedirectionUrl())}
              >
                <span className={styles['logout-button__title']}>{fm(messages.signOut)}</span>
              </button>
            </div>
          </div>
        </div>
      )}

      <HelpDialog isDialogOpen={isDialogOpen} closeDialog={() => setIsDialogOpen(false)} />
    </div>
  );
};
