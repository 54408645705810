import React, { FunctionComponent, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Icon } from 'lib';
import { ROUTES } from 'constants/routes';

export type HelpDisclaimerType = {
  actionHandler?: () => void;
};

const HelpDisclaimer: FunctionComponent<HelpDisclaimerType> = ({ actionHandler }): ReactElement => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <>
      <span className="inlineMsg__icon">
        <Icon type="alert-icon" size="s20" />
      </span>
      <p className="inlineMsg__detailWrap">
        {formatMessage({
          id: 'help.add.email.message',
          defaultMessage: 'To access this feature, please confirm your email from the ',
        })}
        <span
          className="anchor"
          onClick={() => (actionHandler ? actionHandler() : history.push(ROUTES.account))}
        >
          {`${formatMessage({
            id: 'settings.title',
            defaultMessage: 'Settings',
          })}.`}
        </span>
      </p>
    </>
  );
};

export default HelpDisclaimer;
