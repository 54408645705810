import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  loadLessonsWeekScheduleAction,
  loadAdditionalContentLSuccessAction,
  loadExercisesAction,
  loadExamsAction,
} from './actions';
import { getELearningAdditionalLessons, getExercisesList, getExamsList } from './api';
import { ExamsListType } from './types';

function* loadLessonsWeekSchedule({
  payload,
}: ReturnType<typeof loadLessonsWeekScheduleAction.request>): SagaIterator<void> {
  try {
    const additional = yield call(getELearningAdditionalLessons, payload);
    if (additional) yield put(loadAdditionalContentLSuccessAction(additional));
    yield put(loadLessonsWeekScheduleAction.success());
  } catch (error) {
    yield put(loadLessonsWeekScheduleAction.failure(error));
  }
}

function* loadExercisesSchedule(): SagaIterator<void> {
  try {
    const exercisesList = yield call(getExercisesList);
    yield put(loadExercisesAction.success(exercisesList));
  } catch (error) {
    yield put(loadExercisesAction.failure(error));
  }
}

function* loadExamsSchedule(): SagaIterator<void> {
  try {
    const examsList: ExamsListType = yield call(getExamsList);
    yield put(loadExamsAction.success(examsList));
  } catch (error) {
    yield put(loadExamsAction.failure(error));
  }
}

export default function* lessonsWeekScheduleSaga(): SagaIterator<void> {
  yield all([
    takeEvery(loadLessonsWeekScheduleAction.request, loadLessonsWeekSchedule),
    takeEvery(loadExercisesAction.request, loadExercisesSchedule),
    takeEvery(loadExamsAction.request, loadExamsSchedule),
  ]);
}
