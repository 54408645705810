import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Lang } from 'lang';
import { ToastContainer, toast, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IconCommon } from 'lib';

import styles from './ToastContext.module.scss';

type Props = {
  children: React.ReactNode;
};

const iconNames = {
  success: 'icon-thumbsup',
  error: 'icon-error',
  warning: 'icon-warning',
};

export const ToastTypes = toast.TYPE;

type SnackbarContextType = {
  addToast: (message?: string | string[], type?: TypeOptions, timeOut?: number) => void;
};

const ToastContext = React.createContext<SnackbarContextType>({
  addToast: () => {},
});

const TOAST_TIMEOUT = 3000;

export const ToastProvider = ({ children }: Props) => {
  const { locale } = useIntl();

  const addToast = useCallback(
    (
      message?: string | string[],
      bannerType: TypeOptions = ToastTypes.INFO,
      timeOut = TOAST_TIMEOUT,
    ) => {
      if (message)
        toast(
          <>
            <span className={styles.toaster__icon}>
              <IconCommon className={iconNames[bannerType] || 'icon-info'} />
            </span>
            <p>
              {Array.isArray(message)
                ? message?.map((error: string) => <li key={error}>{error}</li>)
                : message}
            </p>
          </>,
          {
            type: bannerType,
            autoClose: timeOut,
          },
        );
    },
    [],
  );

  const toastCtxValues = useMemo(() => ({ addToast }), [addToast]);

  return (
    <ToastContext.Provider value={toastCtxValues}>
      {children}
      <ToastContainer
        toastClassName={({ defaultClassName, type }: any) =>
          cn(defaultClassName, styles.toaster, type && styles[`toaster-${type}`])
        }
        position={locale === Lang.ar ? 'top-left' : 'top-right'}
        rtl={locale === Lang.ar}
        autoClose={TOAST_TIMEOUT}
        closeOnClick={false}
        hideProgressBar
        newestOnTop
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => React.useContext(ToastContext);

export type { TypeOptions };
