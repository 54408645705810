import { createSelector } from 'reselect';

import { RootStateType } from '../types';
import { PreferencesStateType } from './reducer';

export const selectorLocale = (state: RootStateType): PreferencesStateType => state.preferences;

export const selectorAccountNotifications = createSelector(
  selectorLocale,
  ({ data }: PreferencesStateType) => data?.notifications,
);

export const selectorAccountAvatar = createSelector(
  selectorLocale,
  ({ data }: PreferencesStateType) => data?.avatar,
);

export const selectorAccountAvatarGender = createSelector(
  selectorLocale,
  ({ data }: PreferencesStateType) => data?.avatarGender,
);

export const selectorAccountAvatarLoader = createSelector(
  selectorLocale,
  ({ fetching }: PreferencesStateType) => fetching?.avatar,
);
