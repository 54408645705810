import camelcaseKeys from 'camelcase-keys';
import API from 'utils/api';
import { RewardStatResponse, RewardUpdateType } from './types';

export async function getRewardStats(): Promise<RewardStatResponse> {
  const response = await API.get('/api/alw_rewards/retrieve/');
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateReward(payload: RewardUpdateType): Promise<any> {
  const response = await API.post('/api/alw_rewards/add/', {
    assessment_type: payload.assessment_type,
    lesson: payload.lesson,
    course_id: payload.course_id,
    grade: payload.grade,
    learning_objective_id: payload.learningObjectiveId || undefined,
  });
  return camelcaseKeys(response.data, { deep: true });
}
