import React, { ChangeEvent, useCallback, useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';

import { Icon, Loader, getPngURL, getSvgURL, IconCommon } from 'lib';
import { useTheme } from 'contexts/ThemeContext';
import { ROUTES } from 'constants/routes';
import { ToastTypes, useToast } from 'contexts/ToastContext';
import { isSummerSemester } from 'utils/summerSemesterUtils';
import UserPassword from 'lib/UserPassword/UserPassword';
import { MAX_FILE_SIZE } from 'constants/settings';
import { SIZE_ONE_MB } from 'constants/entities';

import { messages as accountMsg } from 'components/Account/Messages';

import { uploadAvatarAction } from 'store/account/actions';
import {
  isStudentRoleSelector,
  accountDataSelector,
  isAdminOrBranchManagerRoleSelector,
} from 'store/account/selectors';
import { teacherQuestionsStatusSelector } from 'store/childTeacherDetailQuestion/selectors';
import { getTeacherQuestionStatusAction } from 'store/childTeacherDetailQuestion/actions';
import { UploadAvatarResponseErrorType } from 'store/account/types';

import { SidebarProps, SidebarTypes } from './types';
import styles from './Sidebar.module.scss';
import { Navigation } from './Navigation';
import { messages } from './messages';

export const Sidebar = ({
  sidebarType,
  menuItems,
  profileDetails,
  isOpen,
  setIsOpen,
  showHamburger,
  isSummerProgram,
}: SidebarProps) => {
  const { formatMessage } = useIntl();
  const { addToast } = useToast();
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const elRef = useRef<{ [key: string]: HTMLAnchorElement | null }>({});

  const [isAvatarUploading, setIsAvatarUploading] = useState(false);

  const isStudent = useSelector(isStudentRoleSelector);
  const { grade } = useSelector(accountDataSelector);
  const isAdminOrBM = useSelector(isAdminOrBranchManagerRoleSelector);
  const { questionsStatus } = useSelector(teacherQuestionsStatusSelector);

  const isALW = sidebarType === SidebarTypes.ALW;
  const summerProgram = isSummerSemester && isSummerProgram;
  const inputFile = useRef<HTMLInputElement>(null);

  const uploadCallbackHandler = useCallback(
    (responseType: string, error?: UploadAvatarResponseErrorType) => {
      if (responseType === 'success') {
        addToast(formatMessage(accountMsg.accountAvatarUpdateSucess), ToastTypes.SUCCESS);
      } else {
        addToast(error?.user_message, ToastTypes.ERROR);
      }
      setIsAvatarUploading(false);
    },
    [addToast, formatMessage],
  );

  const handleInputClick = useCallback(() => {
    inputFile?.current?.click();
  }, [inputFile]);

  const handleInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const fileList: FileList = (target.files as FileList) || [];
      const [file] = fileList;
      if (file?.type === 'image/png' || file?.type === 'image/jpeg') {
        if (file?.size < MAX_FILE_SIZE) {
          dispatch(
            uploadAvatarAction.request({
              file,
              callback: uploadCallbackHandler,
            }),
          );
        } else {
          addToast(
            formatMessage(messages.maxFileSize, {
              filesize: `${Math.round(MAX_FILE_SIZE / SIZE_ONE_MB)}MB`,
            }),
            ToastTypes.ERROR,
          );
        }
        setIsAvatarUploading(true);
      } else {
        addToast(formatMessage(messages.invalidFileType), ToastTypes.ERROR);
      }
    },
    [addToast, dispatch, formatMessage, uploadCallbackHandler],
  );

  const handleClearInput = () => {
    if (inputFile?.current) inputFile.current.value = '';
  };

  const navSrc: Record<string, string> = {
    light: summerProgram ? getPngURL('nav-no-shadow') : getPngURL('left-nav-eng'),
    dark: getPngURL('left-nav-dark'),
    blue: summerProgram ? getPngURL('nav-blue-no-shadow') : getPngURL('left-nav-blue'),
    pink: summerProgram ? getPngURL('nav-pink-no-shadow') : getPngURL('left-nav-pink'),
  };

  useEffect(() => {
    const activeElementRef = elRef.current[pathname];
    activeElementRef?.scrollIntoView({ block: 'nearest' });
  }, [pathname]);

  useEffect(() => {
    dispatch(getTeacherQuestionStatusAction.request());
  }, [dispatch, pathname]);

  return (
    <div
      className={cn({
        [styles.open]: isOpen,
        [styles.summerProgram]: summerProgram,
      })}
    >
      <aside
        className={cn(styles.sidenav, {
          [styles.b2bSidebar]: !isALW,
          [styles.hamburger]: showHamburger,
        })}
      >
        <i className={cn(styles.crossicon, styles['icon-cancel'])} onClick={setIsOpen} />
        <NavLink to={ROUTES.root} className={styles.sidenav__logo} onClick={setIsOpen}>
          <img src={getSvgURL(theme === 'dark' ? 'dark-logo' : 'logo')} alt="ALW" />
        </NavLink>
        <img
          src={navSrc[theme] || getPngURL('left-nav-eng')}
          alt="Left bar"
          className={cn(styles.sidenav__overlay, isALW && styles['d-none-xl'])}
        />
        <div className={styles.profile}>
          <div className={styles.profile__image}>
            {isAvatarUploading && <Loader type="absolute" size="s40" />}
            <span className={styles.profile__image__background}>
              {!isAvatarUploading && (
                <>
                  <img
                    src={profileDetails.srcLarge}
                    alt={formatMessage(accountMsg.accountProfile)}
                  />
                  <span className={styles.profile__edit}>
                    <IconCommon clickHandler={handleInputClick} className="icon-edit" />
                  </span>
                </>
              )}
              <input
                ref={inputFile}
                type="file"
                accept="image/png,image/gif,image/jpeg"
                onChange={handleInputChange}
                onClick={handleClearInput}
                className={cn(styles.input, styles.hidden)}
              />
            </span>
          </div>
          <div className={styles.profile__detail}>
            <div className={styles.profile__name}>{profileDetails.name}</div>
            <div className={styles.profile__role}>
              {profileDetails.role}
              {isStudent &&
                grade?.label &&
                ` (
                  ${formatMessage(messages.assignedGrade, { grade: grade.label })}
                  )`}
            </div>
            {profileDetails.password && (
              <>
                <div className={styles.profile__email}>
                  {profileDetails.email || profileDetails.username}
                </div>
                <div className={styles.profile__userPassword}>
                  <UserPassword password={profileDetails.password} />
                </div>
              </>
            )}
          </div>
        </div>
        {!isALW && isAdminOrBM && <Navigation isHamburger={showHamburger} />}
        <nav
          className={cn(styles.navigation, {
            [styles.shrinkSpace]: profileDetails.password,
          })}
        >
          {menuItems.map(({ id, path, label, icon, image }) => (
            <NavLink
              to={path}
              key={id || label}
              id={id}
              activeClassName={styles.active}
              exact={path === ROUTES.root}
              className={cn(styles.navigation__item)}
              ref={(el) => {
                elRef.current[path] = el;
              }}
              onClick={setIsOpen}
            >
              {image ? (
                <img src={image} alt={label} className={styles.navigation__icon} />
              ) : (
                <Icon
                  className={styles.navigation__icon}
                  title={label}
                  type={icon ?? 'dashboard'}
                  size="s24"
                />
              )}
              <div
                className={cn(styles.navigation__title, {
                  [styles.title__highlighted]:
                    path === `/parent-questions` && questionsStatus?.hasUnansweredQuestions,
                })}
              >
                {label}
              </div>
            </NavLink>
          ))}
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
