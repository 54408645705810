import { ChangeEvent, FocusEvent, ClipboardEvent, KeyboardEvent } from 'react';

export enum InputTypes {
  date = 'date',
  text = 'text',
  email = 'email',
  hidden = 'hidden',
  password = 'password',
  number = 'number',
  tel = 'tel',
  search = 'search',
}

export interface RestrictedInputType {
  onCopy?: ClipBoardEventType;
  onPaste?: ClipBoardEventType;
  onCut?: ClipBoardEventType;
}

export type InputEventType = (e: ChangeEvent<HTMLInputElement>) => void;
export type FocusEventType = (e: FocusEvent<HTMLInputElement>) => void;
export type KeyDownEventType = (e: KeyboardEvent<HTMLInputElement>) => void;
export type ClipBoardEventType = (e: ClipboardEvent) => void;

export type CommonInputPropsType = RestrictedInputType & {
  type?: string;
  name?: string;
  id?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  value?: string;
  onBlur?: FocusEventType;
  onFocus?: FocusEventType;
  onChange?: InputEventType;
  onKeyDown?: KeyDownEventType;
  autoComplete?: 'on' | 'off';
  maxLength?: number;
  fullWidth?: boolean;
  icon?: string;
};

export type InputBaseProps = CommonInputPropsType & {
  readOnly?: boolean;
  isError?: string | boolean;
  isInline?: boolean;
};
