import { EventDetailType, MeetingType, ScheduleSource, ScheduleType } from 'store/schedule/types';
import { SubjectType } from 'store/subjects/types';
import {
  ClassroomTeacher,
  ClassroomSubject,
  TimeTableScheduleEventType,
  SubjectDetailType,
  TeacherAvailabilityType,
  SlotUpdationSuccessType,
} from 'store/Timetable/types';

import { colors, defaultSlotColor } from './constants';

export const teachersDetailAdapter = (classroomTeachers: ClassroomTeacher[], classroom: number) =>
  classroomTeachers.map((teacher) => {
    return {
      isActive: true,
      teacherId: teacher.teacherId,
      teacherName: teacher.teacherName,
      classroomId: classroom,
      teacherSubjects: teacher.teacherSubjects,
    };
  });

export const subjectDetailAdapter = (classroomSubjects: ClassroomSubject[]) =>
  classroomSubjects.map((subject) => {
    return {
      ...subject,
      isLiveClass: false,
      eventCount: 0,
      backgroundColor: '',
    };
  });

export const teacherDetailAdopter = (teacherAvailability: TeacherAvailabilityType) => {
  if (teacherAvailability.teacherDetail) {
    const { classes: eventClasses, teacher } = teacherAvailability.teacherDetail;
    const slotClasses = eventClasses.map((cls) => `${cls.classroom.name}`);
    const uniqueClasses = [...new Set(slotClasses)].join(' , ');
    return {
      teacherDetail: {
        name: teacher.name || '',
        description: uniqueClasses,
        profileImage: teacher.profileImage,
      },
    };
  }
  return {
    errorMessage: teacherAvailability.errorMessage,
  };
};

export const subjectFilterAdopter = (classroomSubjects: ClassroomSubject[]) =>
  classroomSubjects.map(({ subject }) => subject);

export const timeTableScheduleAdapter = (classroomTeachers: ClassroomTeacher[]) => {
  const schedules: TimeTableScheduleEventType[] = [];
  classroomTeachers.forEach((teacher) => {
    teacher.teacherSchedules.forEach((schedule) => {
      const newSchedule: TimeTableScheduleEventType = {
        teacherId: teacher.teacherId,
        id: schedule.id,
        slotId: schedule.slotId,
        published: schedule.published,
        classroom: schedule.classroom,
        day: schedule.day,
        duration: schedule.duration,
        teacherName: teacher.teacherName,
        subject: schedule.subject,
        isInPersonClass: schedule.isInPersonClass,
        start: new Date(schedule.startTime),
        end: new Date(schedule.endTime),
        profileImage: teacher.teacherImage,
        description: schedule.description,
        weeks: schedule.weeks,
      };
      schedules.push(newSchedule);
    });
  });

  return schedules;
};

export const eventDetailAdopter = (teacherDetail: TimeTableScheduleEventType): EventDetailType => {
  const { classroom, start, end, teacherName, teacherId, isInPersonClass } = teacherDetail;
  return {
    id: teacherDetail.slotId,
    grade: classroom.name,
    classrooms: [classroom],
    trainer: {
      name: teacherName || '',
      id: teacherId,
      profileImage: {
        ...teacherDetail.profileImage,
        imageUrlSmall: teacherDetail.profileImage?.small,
      },
    },
    eventType: ScheduleType.LiveClass,
    description: teacherDetail.description,
    isInPersonClass,
    eventName: teacherDetail.subject.displayName,
    subject: teacherDetail.subject as unknown as SubjectType,
    meetingType: MeetingType.ZoomLinkInRequest,
    trainerName: teacherDetail.teacherName,
    weekNumber: '0',
    eventSource: ScheduleSource.b2b,
    startDate: new Date(start || ''),
    endDate: new Date(end || ''),
    weeks: teacherDetail.weeks?.map((week) => ({ id: week.id, value: week.studyingWeek })),
  } as EventDetailType;
};

export const subjectDetailWithColorAndCountAdapter = (
  subjectDetails: SubjectDetailType[],
  timeTableScheduleEvents: TimeTableScheduleEventType[],
  classroomId: Number,
): SubjectDetailType[] => {
  return subjectDetails.map((subjectDetail, index) => {
    const eventCount = timeTableScheduleEvents.filter(
      (event) =>
        event.subject.id === subjectDetail.subject.id && event.classroom.id === classroomId,
    ).length;

    return {
      ...subjectDetail,
      isLiveClass: eventCount !== 0,
      eventCount,
      backgroundColor: colors[index],
    };
  });
};

export const timeTableScheduleWithColorAdapter = (
  subjectDetails: SubjectDetailType[],
  timeTableScheduleEvents: TimeTableScheduleEventType[],
  classroomId: number,
): TimeTableScheduleEventType[] => {
  return timeTableScheduleEvents.map((event) => {
    const subjectFound = subjectDetails.find(
      (subjectDetail) =>
        subjectDetail.subject.id === event.subject.id && event.classroom.id === classroomId,
    );

    return {
      ...event,
      backgroundColor: subjectFound ? subjectFound.backgroundColor : defaultSlotColor,
    };
  });
};

export const updateSlotStatusAdapter = (
  classroomTeachers: ClassroomTeacher[],
  { prevSlotId, newSlotId, published, start, end }: SlotUpdationSuccessType,
) => {
  return classroomTeachers.map((teacher) => ({
    ...teacher,
    teacherSchedules: teacher.teacherSchedules.map((schedule) => {
      return +schedule.slotId === +prevSlotId
        ? {
            ...schedule,
            published,
            start,
            end,
            slotId: newSlotId,
            startTime: start,
            endTime: end,
          }
        : schedule;
    }),
  }));
};
