import { getMessageDescriptor } from '../../utils/getMessageDescriptor';

export const messages = {
  account: getMessageDescriptor('account.menu.account', 'Account'),
  sso: getMessageDescriptor('account.menu.sso', 'Social accounts'),
  gradeSuccess: getMessageDescriptor('gender.update.success', 'Gender added successfully,'),
  chatSuccess: getMessageDescriptor('use.group.chat', ', now you can use group chat'),
  lang: getMessageDescriptor('account.menu.language', 'System Language'),
  accountDetails: getMessageDescriptor('account.account.details', 'Account Details'),
  accountUsername: getMessageDescriptor('account.account.username-title', 'Username'),
  accountFirstName: getMessageDescriptor('teacher.form.basicInfo.firstName', 'First Name'),
  accountLastName: getMessageDescriptor('teacher.form.basicInfo.lastName', 'Last Name'),
  accountGender: getMessageDescriptor('account.student.gender.type', 'Gender'),
  accountPhoneNumber: getMessageDescriptor('account.account.phone-title', 'Phone number'),
  accountParentEmail: getMessageDescriptor('account.account.parent-email-title', 'Parent Email'),
  accountEmail: getMessageDescriptor('account.account.email-title', 'Email'),
  enterField: getMessageDescriptor('profile.enter.field', 'Enter {field}'),
  confirmField: getMessageDescriptor('profile.confirm.field', 'Confirm {field}'),
  accountPasswordError: getMessageDescriptor(
    'b2b.password.validate.message',
    'Password dont match',
  ),
  accountEmailChange: getMessageDescriptor(
    'account.account.confirmation-email-text',
    'We’ve sent a confirmation to {email}. Click the link to update your email.',
  ),
  accountPassword: getMessageDescriptor('account.account.password-title', 'Password'),
  accountChangeButton: getMessageDescriptor('account.account.change-button', 'Change'),
  accountInviteButton: getMessageDescriptor('account.account.invite-button', 'Invite'),
  upgradePlan: getMessageDescriptor('account.subscription.upgrade-plan', 'Upgrade Plan'),
  accountLang: getMessageDescriptor('account.lang.select', 'Select Application Language'),
  accountThemeSelect: getMessageDescriptor('account.app.theme.select', 'Select Application Color'),
  accountTheme: getMessageDescriptor('account.theme.select', 'Color Theme'),
  accountThemeDefault: getMessageDescriptor('account.theme.select.default', 'Default'),
  accountThemePink: getMessageDescriptor('account.theme.select.pink', 'Pink'),
  accountThemeGold: getMessageDescriptor('account.theme.select.gold', 'Gold'),
  accountThemeDark: getMessageDescriptor('account.theme.select.dark', 'Dark'),
  accountThemeLight: getMessageDescriptor('account.theme.select.light', 'Light'),
  accountThemeBlue: getMessageDescriptor('account.theme.select.blue', 'Blue'),
  addServices: getMessageDescriptor('account.subscription.add-services', 'Add Services'),
  notAvailable: getMessageDescriptor('reports.progress.not.attempted', 'N/A'),
  cancel: getMessageDescriptor('account.account.cancel-button', 'Cancel'),
  passwordLink: getMessageDescriptor(
    'password-link-email-message',
    'Password link sent successfully to the email',
  ),
  changePasswordSuccess: getMessageDescriptor(
    'password.change.success.message',
    'Password changed successfully. Please login again',
  ),
  accountGenderMale: getMessageDescriptor('student.form.basicInfo.male', 'Male'),
  accountGenderFemale: getMessageDescriptor('student.form.basicInfo.female', 'Female'),
  accountSettingSave: getMessageDescriptor('account.account.save-button', 'Save'),
  accountConfirmPassword: getMessageDescriptor(
    'student.form.basicInfo.confirmPassword',
    'Confirm Password',
  ),
  accountResetPassword: getMessageDescriptor(
    'account.account.reset-password-button',
    'Reset Password',
  ),
  accountFacebookSSO: getMessageDescriptor(
    'account.sso.connect-with-facebook',
    'Connect with your Facebook account',
  ),
  accountFacebook: getMessageDescriptor('account.sso.facebook', 'Facebook'),
  accountGoogle: getMessageDescriptor('account.sso.google', 'Google'),
  accountGoogleSSO: getMessageDescriptor(
    'account.sso.connect-with-google',
    'Connect with your Google account',
  ),
  socialAccount: getMessageDescriptor('account.menu.sso', 'Social accounts'),
  firstNameRequired: getMessageDescriptor(
    'registration.form.add-children.first-name.error',
    'First Name is required field, please fill it out.',
  ),
  genderRequired: getMessageDescriptor(
    'student.profile.gender.error',
    'Gender is required field, please fill it out.',
  ),
  lastNameRequired: getMessageDescriptor(
    'registration.form.add-children.last-name.error',
    'Last Name is required field, please fill it out.',
  ),
  defaultPhoneText: getMessageDescriptor(
    'account.account.phone-empty',
    'You can add your phone number here',
  ),
  pendingConfirmation: getMessageDescriptor('account.account.pending', 'Pending confirmation'),
  accountFullName: getMessageDescriptor('account.form.basicInfo.fullName', 'FIRST & LAST NAME'),
  accountReset: getMessageDescriptor('reports.filter.reset', 'Reset'),
  subscription: getMessageDescriptor('account.menu.subscription', 'Subscriptions'),
  childSubscriptions: getMessageDescriptor(
    'account.menu.parentSubscription',
    'Children’s subscriptions',
  ),
  accountAvatarUpdateStarted: getMessageDescriptor(
    'account.avatar.update.started',
    'Account Avatar Update Started',
  ),
  accountAvatarUpdateSucess: getMessageDescriptor(
    'account.avatar.update.success',
    'Account Avatar Updated Successfully',
  ),
  accountProfile: getMessageDescriptor('account.avatar.title', 'User Profile'),
  editIcon: getMessageDescriptor('account.avatar.edit.icon.title', 'Edit Icon'),
  allNotifications: getMessageDescriptor(
    'accounts.notifications.all.notifications',
    'All Notifications',
  ),
  changeProfilePicture: {
    id: 'account.settings.change-profile-picture',
    defaultMessage: 'Change Profile Picture',
  },
  selectOrDnDHere: {
    id: 'account.settings.select-file-or-dnd-here',
    defaultMessage: 'Select a file or drag and drop here',
  },
  browse: {
    id: 'account.settings.browse',
    defaultMessage: 'Browse',
  },
  invalidFileType: {
    id: 'invalid-file-type',
    defaultMessage: 'Invalid file type',
  },
  maxFileSize: {
    id: 'max-file-size',
    defaultMessage: 'Max allowed file size is {filesize}',
  },
  profilePictureAlt: {
    id: 'alt.profile.picture',
    defaultMessage: 'ProfilePicture',
  },
  save: {
    id: 'account.account.save-button',
    defaultMessage: 'Save',
  },
  requiredField: {
    id: 'form.field.is.required',
    defaultMessage: '{field} is required field, please fill it out.',
  },
  nationality: { id: 'account-settings.nationality', defaultMessage: 'Nationality' },
  nationalIDNumber: { id: 'account-settings.national-id-number', defaultMessage: 'ID No.' },
  passportNumber: { id: 'account-settings.passport-number', defaultMessage: 'Passport No.' },
  placeOfBirth: { id: 'account-settings.place-of-birth', defaultMessage: 'Place Of Birth' },
};
