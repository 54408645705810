import { ROUTES } from 'constants/routes';
import { isObjectEmpty } from 'utils/helpers';

import { SubscriptionServiceEnum } from 'store/subscriptions/types';

export const getDisabledRoutes = (
  disabledServices: Record<SubscriptionServiceEnum, boolean>,
  isB2CStudent: boolean,
): string[] => {
  if (!isB2CStudent || isObjectEmpty(disabledServices)) return [];

  const routes = {
    [SubscriptionServiceEnum.RECORDED_CONTENT]: [
      ROUTES.learningPath,
      ROUTES.contentLibrary,
      ROUTES.exercises,
      ROUTES.quizExam,
    ],
    [SubscriptionServiceEnum.WEEKLY_REVIEW_SESSIONS]: [
      ROUTES.schedule,
      ROUTES.studentLiveQuestions,
    ],
    [SubscriptionServiceEnum.EXERCISES_AND_EXAMS]: [
      ROUTES.questionBankSubjects,
      ROUTES.studentExams,
    ],
  };

  return Object.entries(disabledServices)
    .map(([key, value]) => (value ? routes[key] : []))
    .flat();
};
