import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';
import * as actions from './actions';
import { loadLessonPreviewAction, resetLessonPreviewAction } from './actions';
import { LessonPreviewType } from './types';

export type LessonPreviewActionType = ActionType<typeof actions>;

const data = createReducer<LessonPreviewType, LessonPreviewActionType>({} as LessonPreviewType)
  .handleAction(loadLessonPreviewAction.success, (state, action) => action.payload)
  .handleAction(resetLessonPreviewAction, () => ({} as LessonPreviewType));

const fetching = combineReducers({
  load: createReducer<boolean, LessonPreviewActionType>(true)
    .handleAction([loadLessonPreviewAction.request, resetLessonPreviewAction], () => true)
    .handleAction([loadLessonPreviewAction.success, loadLessonPreviewAction.failure], () => false),
});

const errors = combineReducers({
  load: createReducer<string, LessonPreviewActionType>('')
    .handleAction(loadLessonPreviewAction.failure, (state, { payload }) => payload)
    .handleAction([loadLessonPreviewAction.success, resetLessonPreviewAction], () => ''),
});

const lessonPreviewReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type LessonPreviewStateType = StateType<typeof lessonPreviewReducer>;

export default lessonPreviewReducer;
