import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import {
  loadEventsAction,
  loadNextEventAction,
  loadQuestionPoolsDetailsAction,
  loadQuestionPoolsGroupsAction,
  resetEventsAction,
  resetQuestionPools,
  setEventCompleteAction,
  studentAttemptSubmissionAction,
} from './actions';
import {
  EventErrorsType,
  EventType,
  NextEventType,
  QuestionPoolsDetailsType,
  QuestionPoolsGroupsResponseType,
  StudentAttemptResult,
  StudentAttemptScore,
} from './types';

export type EventsActionType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, EventsActionType>(true)
  .handleAction([loadEventsAction.request, resetEventsAction], () => true)
  .handleAction([loadEventsAction.success, loadEventsAction.failure], () => false);

const data = createReducer<EventType[], EventsActionType>([])
  .handleAction(loadEventsAction.success, (state, action) => action.payload)
  .handleAction(setEventCompleteAction, (state, action) =>
    [...state].map((event) => (event.id !== action.payload ? event : { ...event, complete: true })),
  )
  .handleAction(resetEventsAction, () => []);

const isNextLoading = createReducer<boolean, EventsActionType>(true)
  .handleAction(loadNextEventAction.request, () => true)
  .handleAction([loadNextEventAction.success, loadNextEventAction.failure], () => false);

const next = createReducer<NextEventType, EventsActionType>({} as NextEventType)
  .handleAction(loadNextEventAction.success, (state, action) => action.payload)
  .handleAction(resetEventsAction, () => ({}));

const errors = createReducer<EventErrorsType, EventsActionType>({})
  .handleAction(loadEventsAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadEventsAction.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(loadNextEventAction.failure, (state, action) => ({
    ...state,
    next: action.payload,
  }))
  .handleAction(loadNextEventAction.success, (state) => ({
    ...state,
    next: '',
  }))
  .handleAction(resetEventsAction, () => ({}));

const questionPoolsDetails = combineReducers({
  isLoading: createReducer<boolean, EventsActionType>(false)
    .handleAction(loadQuestionPoolsDetailsAction.request, () => true)
    .handleAction(
      [loadQuestionPoolsDetailsAction.success, loadQuestionPoolsDetailsAction.failure],
      () => false,
    ),
  data: createReducer<QuestionPoolsDetailsType, EventsActionType>(
    {} as QuestionPoolsDetailsType,
  ).handleAction(loadQuestionPoolsDetailsAction.success, (state, action) => action.payload),
  error: createReducer<string, EventsActionType>('').handleAction(
    loadQuestionPoolsDetailsAction.failure,
    (state, action) => action.payload,
  ),
});

const questionGroups = combineReducers({
  isLoading: createReducer<boolean, EventsActionType>(true)
    .handleAction(loadQuestionPoolsGroupsAction.request, () => true)
    .handleAction(
      [loadQuestionPoolsGroupsAction.success, loadQuestionPoolsGroupsAction.failure],
      () => false,
    ),
  data: createReducer<QuestionPoolsGroupsResponseType[], EventsActionType>(
    [] as QuestionPoolsGroupsResponseType[],
  )
    .handleAction(loadQuestionPoolsGroupsAction.success, (state, action) => [
      ...state,
      action.payload,
    ])
    .handleAction(resetQuestionPools, () => []),
  error: createReducer<string, EventsActionType>('').handleAction(
    loadQuestionPoolsGroupsAction.failure,
    (state, action) => action.payload,
  ),
});

const performanceResult = combineReducers({
  isLoading: createReducer<boolean, EventsActionType>(true).handleAction(
    [studentAttemptSubmissionAction.request, studentAttemptSubmissionAction.failure],
    () => true,
  ),
  data: createReducer<StudentAttemptResult, EventsActionType>({
    attemptDetails: [] as StudentAttemptScore[],
  } as StudentAttemptResult).handleAction(
    [studentAttemptSubmissionAction.success],
    (_, { payload }) => ({
      ...payload,
    }),
  ),
});

const eventsReducer = combineReducers({
  isLoading,
  isNextLoading,
  data,
  next,
  errors,
  questionPoolsDetails,
  performanceResult,
  questionGroups,
});

export type EventsStateType = StateType<typeof eventsReducer>;

export default eventsReducer;
