import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import styles from '../InputField.module.scss';

type FieldLabelProps = {
  id?: string;
  title?: string;
  htmlFor?: string;
  required?: boolean;
};

export const FieldLabel: FunctionComponent<FieldLabelProps> = ({
  id,
  title,
  htmlFor,
  required,
}) => {
  if (!title) return null;

  return (
    <label className={cn(styles.formlabel, 'formlabel')} id={id} htmlFor={htmlFor}>
      {title}
      {required && <sup className={styles.required}>*</sup>}
    </label>
  );
};
