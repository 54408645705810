import { compose, map } from 'lodash/fp';
import { Lang } from '../../lang';

export const adapterLessons = (events: any): any => {
  return compose(
    map(({ ar, en, ...rest }) => ({
      [Lang.en]: en,
      [Lang.ar]: ar,
      ...rest,
    })),
  )(events);
};
