import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { loadLearningProfilesAction } from './action';
import * as actions from './action';
import { LearningProfilesType } from './types';
import { Lang } from '../../lang';

type LearningProfilesTypes = ActionType<typeof actions>;

export const learningProfiles = createReducer<LearningProfilesType, LearningProfilesTypes>({
  [Lang.en]: [],
  [Lang.ar]: [],
}).handleAction(loadLearningProfilesAction.success, (state, { payload }) => ({ ...payload }));

export const fetching = combineReducers({
  load: createReducer(true)
    .handleAction(loadLearningProfilesAction.request, () => true)
    .handleAction(
      [loadLearningProfilesAction.success, loadLearningProfilesAction.failure],
      () => false,
    ),
});

export const errors = combineReducers({
  load: createReducer<string, LearningProfilesTypes>('').handleAction(
    loadLearningProfilesAction.failure,
    (state, { payload }) => payload,
  ),
});

const learningProfilesReducer = combineReducers({
  learningProfiles,
  fetching,
  errors,
});

export type LearningProfilesStateType = StateType<typeof learningProfilesReducer>;

export default learningProfilesReducer;
