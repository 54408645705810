import { createAction, createAsyncAction } from 'typesafe-actions';
import { sendParentInviteType } from './api';

export const inviteParentsActions = createAsyncAction(
  '@@inviteParents/REQUEST',
  '@@inviteParents/SUCCESS',
  '@@inviteParents/FAILURE',
)<sendParentInviteType, string, string>();

export const resetInviteAction = createAction('@@inviteParents/RESET_INVITE')();
