/// <reference types="google.accounts" />

import React, { useState, useEffect, memo } from 'react';
import axios from 'axios';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import camelcaseKeys from 'camelcase-keys';

import { getSvgURL } from 'lib/SVG/SVG';
import { GOOGLE_CLIENT_ID } from 'constants/settings';
import useScript from 'hooks/useScript';

import styles from './GoogleAuthButton.module.scss';

const GOOGLE_IDENTITY_SERVICE_URL = 'https://accounts.google.com/gsi/client';
const AUTH_SCOPE_BASE = 'https://www.googleapis.com/auth/';
const GOOGLE_OAUTH_USERINFO_URL = 'https://www.googleapis.com/oauth2/v2/userinfo';

type UserInfo = {
  email: string;
  lastName: string;
  firstName: string;
  accessToken: string;
};

type UserInfoResponse = {
  email: string;
  familyName: string;
  givenName: string;
};

type GoogleAuthButtonProps = {
  onSuccess: (response: UserInfo) => void;
};

export const GoogleAuthButton = memo(({ onSuccess }: GoogleAuthButtonProps) => {
  const { formatMessage } = useIntl();

  const scriptLoadingStatus = useScript(GOOGLE_IDENTITY_SERVICE_URL, { removeOnUnmount: true });

  const [tokenClient, setTokenClient] = useState<google.accounts.oauth2.TokenClient>(
    {} as google.accounts.oauth2.TokenClient,
  );

  useEffect(() => {
    if (scriptLoadingStatus === 'ready' && GOOGLE_CLIENT_ID)
      setTokenClient(
        google.accounts.oauth2.initTokenClient({
          client_id: GOOGLE_CLIENT_ID,
          scope: `${AUTH_SCOPE_BASE}userinfo.profile ${AUTH_SCOPE_BASE}userinfo.email`,
          callback: async (response) => {
            const { data: userInfo } = await axios.get(
              `${GOOGLE_OAUTH_USERINFO_URL}?access_token=${response.access_token}`,
            );

            const { familyName, givenName, email }: UserInfoResponse = camelcaseKeys(userInfo);

            onSuccess({
              email,
              firstName: givenName,
              lastName: familyName,
              accessToken: response.access_token,
            });
          },
        }),
      );
  }, [onSuccess, scriptLoadingStatus]);

  return (
    <span
      className={cn(styles.socialLogin__option, styles.googleLogin)}
      onClick={() => {
        tokenClient.requestAccessToken();
      }}
    >
      <img src={getSvgURL('google')} alt="Google" className={styles.socialLogin__icon} />

      {formatMessage({
        id: 'signup.button.google',
        defaultMessage: 'Continue sign up with Google',
      })}
    </span>
  );
});
