import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { fetchQiyasExamDetailAction, fetchQuestionsByExamAction } from './actions';
import { fetchQiyasExamDetailAPI, fetchQuestionsByExamAPI } from './api';

function* fetchQiyasExamDetailSaga({
  payload,
}: ReturnType<typeof fetchQiyasExamDetailAction.request>): SagaIterator<void> {
  try {
    const result = yield call(fetchQiyasExamDetailAPI, payload);
    yield put(fetchQiyasExamDetailAction.success(result));
  } catch (error) {
    yield put(fetchQiyasExamDetailAction.failure(error));
  }
}

function* fetchQuestionsByExamSaga({
  payload,
}: ReturnType<typeof fetchQuestionsByExamAction.request>): SagaIterator<void> {
  try {
    const result = yield call(fetchQuestionsByExamAPI, payload);
    yield put(fetchQuestionsByExamAction.success(result));
  } catch (error) {
    yield put(fetchQuestionsByExamAction.failure(error));
  }
}

function* qiyasSaga(): SagaIterator {
  yield all([
    takeLatest(fetchQiyasExamDetailAction.request, fetchQiyasExamDetailSaga),
    takeLatest(fetchQuestionsByExamAction.request, fetchQuestionsByExamSaga),
  ]);
}

export default qiyasSaga;
