import camelcaseKeys from 'camelcase-keys';

import { getSemesterWeeksUrl } from 'utils/apiEndpoints';
import API from 'utils/api';

import { SemesterType, SemesterWeeksPayload } from './types';

export async function getSemestersDetail(): Promise<Array<SemesterType>> {
  const response = await API.get('/api/semester/details/');

  return camelcaseKeys(response.data, { deep: true });
}

export async function getNextYearSemestersDetail(): Promise<Array<SemesterType>> {
  const response = await API.get('/api/semester/details-next-year/');

  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchSemesterWeeksAPI({ semesterId, pastWeeks }: SemesterWeeksPayload) {
  const response = await API.get(getSemesterWeeksUrl(semesterId), {
    params: {
      ...(pastWeeks && { past_weeks: 'True' }),
    },
  });
  return camelcaseKeys(response.data, { deep: true });
}
