import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';

import * as actions from './actions';
import {
  loadPreferencesAction,
  notificationPreferencesAction,
  updateNotificationPreferencesAction,
  updatePreferencesAction,
  updateAvatarLoader,
  resetPreferencesAction,
} from './actions';
import { PreferencesType, ResultType } from './types';
import { getNotifications, getUpdateNotifications } from './adapter';

export type PreferencesActionType = ActionType<typeof actions>;

const data = createReducer<PreferencesType, PreferencesActionType>({} as PreferencesType)
  .handleAction([loadPreferencesAction.success], (_, { payload }) => ({
    ...payload,
    notifications: [],
  }))
  .handleAction([updatePreferencesAction.success], (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(notificationPreferencesAction, (state, { payload }) => ({
    ...state,
    notifications: getNotifications(state as ResultType, payload),
  }))
  .handleAction(
    [updateNotificationPreferencesAction.success],
    (state, { payload: { grade, ...payload } }) => ({
      ...state,
      notifications: getUpdateNotifications(payload, state.notifications, grade),
    }),
  )
  .handleAction(resetPreferencesAction, () => ({}));

const fetching = combineReducers({
  load: createReducer<boolean, PreferencesActionType>(true)
    .handleAction(loadPreferencesAction.request, () => true)
    .handleAction([loadPreferencesAction.success, loadPreferencesAction.failure], () => false),
  update: createReducer<boolean, PreferencesActionType>(false)
    .handleAction(updatePreferencesAction.request, () => true)
    .handleAction([updatePreferencesAction.success, updatePreferencesAction.failure], () => false),
  avatar: createReducer<boolean, PreferencesActionType>(false).handleAction(
    updateAvatarLoader,
    (state, { payload }) => payload,
  ),
});

const errors = combineReducers({
  load: createReducer<string, PreferencesActionType>('')
    .handleAction(loadPreferencesAction.failure, (state, { payload }) => payload)
    .handleAction(loadPreferencesAction.success, () => ''),
  update: createReducer<string, PreferencesActionType>('')
    .handleAction(updatePreferencesAction.failure, (state, { payload }) => payload)
    .handleAction(updatePreferencesAction.success, () => ''),
});

const preferencesReducer = combineReducers({
  fetching,
  data,
  errors,
});

export type PreferencesStateType = StateType<typeof preferencesReducer>;

export default preferencesReducer;
