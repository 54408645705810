import { compose, map } from 'lodash/fp';
import { Lang } from '../../lang';

export const adapterLessonPreview = (data: any): any => {
  return {
    lessonName: data.lessonName,
    events: compose(
      map(({ ar, en, ...rest }) => ({
        [Lang.en]: en,
        [Lang.ar]: ar,
        ...rest,
      })),
    )(data.events),
  };
};
