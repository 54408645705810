import { createContext } from 'react';

import { OrientationVideosType } from 'store/orientation/types';

type OrientationContextType = {
  userType: string;
  isOrientationOpen: boolean;
  orientationData: OrientationVideosType | null;
  handleOrientationOpen: () => void;
  handleOrientationClose: () => void;
  triggerOrientationVideo: (data: OrientationVideosType) => void;
  changeStateToOrientationVideo: () => void;
};

export const OrientationContext = createContext<OrientationContextType>({
  userType: '',
  isOrientationOpen: false,
  orientationData: {} as OrientationVideosType,
  handleOrientationOpen: () => {},
  handleOrientationClose: () => {},
  triggerOrientationVideo: () => {},
  changeStateToOrientationVideo: () => {},
});
