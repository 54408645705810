import { ROUTES } from 'constants/routes';

import { getIntl } from 'utils/intl';

import { MenuItemType } from '../components/Sidebar/types';

export const parentMenu = (parentChildren: any): MenuItemType[] => {
  const { formatMessage } = getIntl();
  const menu: MenuItemType[] = [
    {
      id: 'sidebar__dashboard',
      path: ROUTES.root,
      label: formatMessage({ id: 'dashboard.title', defaultMessage: 'Dashboard' }),
      icon: 'dashboard',
    },
  ];
  parentChildren?.forEach((child: any) => {
    menu.push({
      id: `sidebar__reports_${child.id}`,
      path: `/child-reports/${child.id}`,
      label: `${child.firstName} ${child.lastName}`,
      icon: child.profileImage.small,
      image: child.profileImage.small,
    });
  });
  return menu;
};
