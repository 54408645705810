import {
  format as fmd,
  parse,
  isValid,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  isSunday,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInMilliseconds,
  add,
  sub,
  getDay,
  startOfWeek,
  endOfWeek,
  endOfISOWeek,
  Locale,
  minutesToMilliseconds,
} from 'date-fns';
import { enAU, ar } from 'date-fns/locale';

type TimeUnits = 'ms' | 'secs' | 'mins' | 'hrs' | 'days';

export const datefnsLocales: Record<string, Locale> = {
  en: enAU,
  ar,
};

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const toDate = (date: string | Date) => (typeof date === 'string' ? new Date(date) : date);

export const diff = (
  laterDate: string | Date,
  earlierDate: string | Date,
  unit: TimeUnits = 'mins',
) => {
  const timeDiffFunc = {
    days: differenceInDays,
    hrs: differenceInHours,
    mins: differenceInMinutes,
    secs: differenceInSeconds,
    ms: differenceInMilliseconds,
  };

  return timeDiffFunc[unit]?.(toDate(laterDate), toDate(earlierDate)) || 0;
};

export const currentTimeDiff = (dateStr: string | Date, unit: TimeUnits = 'mins') =>
  diff(dateStr, new Date(), unit);

export const isInFuture = (dateStr: string) => isAfter(new Date(dateStr), new Date());

export const isDateValid = (date: Date | string) => isValid(toDate(date));

export const formatDate = (
  date: string | Date,
  format = DEFAULT_DATE_FORMAT,
  options: object = {},
) => {
  if (!isDateValid(date)) return '';

  try {
    return fmd(toDate(date), format, options);
  } catch (RangeError) {
    return fmd(toDate(date), DEFAULT_DATE_FORMAT, options);
  }
};

export const isSameOrBefore = (dateStr?: string) => {
  if (!dateStr) {
    return false;
  }
  const expirationDate = new Date(dateStr);
  return isBefore(expirationDate, new Date()) || expirationDate.getTime() === new Date().getTime();
};

export const createDate = (month?: number, year?: number) => {
  const date = new Date();
  if (month && month >= 0) date.setMonth(month);
  if (year) date.setFullYear(year);
  return date;
};

export const getFullDate = (): [number, number, number] => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const date = today.getDate();

  return [date, year, month];
};

export const getDateInt = (date: string) => +new Date(new Date(date).toDateString());

export const formatDateWithLocale = (
  date: string | Date,
  locale: string,
  format = DEFAULT_DATE_FORMAT,
) => {
  if (!isDateValid(date)) return '';

  const options = {
    locale: datefnsLocales[locale.startsWith('ar') ? 'ar' : 'en'],
  };
  try {
    return fmd(toDate(date), format, options);
  } catch (RangeError) {
    return fmd(toDate(date), DEFAULT_DATE_FORMAT, options);
  }
};

export const parseDate = (date: string, format = DEFAULT_DATE_FORMAT, referenceDate = new Date()) =>
  parse(date, format, referenceDate);

export const getFullMonthNames = (locale: string) =>
  Array(12)
    .fill(0)
    .map((_, i) => formatDateWithLocale(new Date(0, i), locale, 'LLLL'));

export const compareDate = (
  date1: string | Date,
  date2: string | Date,
  method: 'min' | 'max' = 'min',
) => {
  const compareMethod = {
    min: Math.min,
    max: Math.max,
  };

  const comparedValue = compareMethod[method](toDate(date1).getTime(), toDate(date2).getTime());
  return new Date(comparedValue);
};

export const formatSeconds = (
  seconds: number,
): {
  hours: number;
  minutes: number;
  seconds: string;
} => {
  const hours: number = Math.floor(seconds / 3600);
  const minutes: number = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = `${seconds % 60 < 10 ? '0' : ''}${seconds % 60}`;

  return {
    hours,
    minutes,
    seconds: remainingSeconds,
  };
};

export const formatTimeInMinutesToHHMMSS = (timeInMinutes: number) => {
  const hours = Math.floor(timeInMinutes / 60);
  const mins = Math.floor(timeInMinutes % 60);

  const formattedTime = `${hours.toString().padStart(2, '0')}:${mins
    .toString()
    .padStart(2, '0')}:00`;

  return formattedTime;
};

export {
  isSunday,
  isBefore,
  getDay,
  add as addTime,
  sub as subtractTime,
  endOfISOWeek,
  startOfWeek,
  endOfWeek,
  isToday,
  isSameDay,
  minutesToMilliseconds,
};
