import { createAsyncAction, createAction } from 'typesafe-actions';
import { LessonPreviewType } from './types';

export const loadLessonPreviewAction = createAsyncAction(
  '@@lessonPreview/LOAD_REQUEST',
  '@@lessonPreview/LOAD_SUCCESS',
  '@@lessonPreview/LOAD_FAILURE',
)<string, LessonPreviewType, string>();

export const resetLessonPreviewAction = createAction('@@lessonPreview/RESET')();
