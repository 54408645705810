import camelcaseKeys from 'camelcase-keys';
import API, { API_URL } from '../../utils/api';
import { SSOProvider } from './types';

export async function getSSOAccounts(username: string): Promise<any> {
  const response = await API.get(`/api/third_party_auth/v0/users/?username=${username}`);

  return camelcaseKeys(response.data.active);
}

export function getProviderId(provider: SSOProvider): string {
  switch (provider) {
    case SSOProvider.facebook:
      return 'facebook';
    case SSOProvider.google:
      return 'google-oauth2';
    default:
      return '';
  }
}

export function loginSSOAccount(provider: SSOProvider): void {
  document.location.href = `${API_URL}/auth/login/${getProviderId(
    provider,
  )}/?auth_entry=login&next=${document.location.origin}/sso/${provider}/login`;
}

export function linkSSOAccount(provider: SSOProvider): void {
  document.location.href = `${API_URL}/auth/login/${getProviderId(
    provider,
  )}/?auth_entry=login&next=${document.location.origin}/sso/${provider}/account`;
}

export async function unlinkSSOAccount(provider: SSOProvider): Promise<void> {
  await API.post(`/auth/disconnect/${getProviderId(provider)}/`);
}
