import { createAsyncAction, createAction } from 'typesafe-actions';
import { BreadcrumbsType } from './types';

export const loadBreadcrumbsAction = createAsyncAction(
  '@@breadcrumbs/LOAD_REQUEST',
  '@@breadcrumbs/LOAD_SUCCESS',
  '@@breadcrumbs/LOAD_FAILURE',
)<string, BreadcrumbsType, string>();

export const resetBreadcrumbsAction = createAction('@@breadcrumbs/RESET')();
