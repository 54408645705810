import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react';
import classNames from 'classnames';
import styles from './styles/ButtonBase.module.scss';

export type ButtonProps = {
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  children: ReactNode;
  className?: string;
  tabIndex?: number;
  isSubmit?: boolean;
};

const ButtonBase: FunctionComponent<ButtonProps> = ({
  children,
  disabled = false,
  className,
  isSubmit = false,
  tabIndex,
  onClick = (): void => {},
}) => (
  <button
    type={isSubmit ? 'submit' : 'button'}
    disabled={disabled}
    tabIndex={tabIndex}
    className={classNames(className, styles.base, {
      [styles.disable]: disabled,
    })}
    onClick={onClick}
  >
    {children}
  </button>
);

export default ButtonBase;
