import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import API from '../../utils/api';
import {
  archiveTeacherAPI,
  teacherDetailAPI,
  updateTeacherAPI,
  b2bTeacherUpdatePassword,
} from '../../utils/apiEndpoints';
import {
  TeacherInfoType,
  TeacherInfoPayload,
  DeletePayloadData,
  UpdatePasswordPayload,
} from './types';
import { UpdateTeacherType } from '../schoolTeacher/types';

export async function getTeacher(payload: TeacherInfoPayload): Promise<TeacherInfoType> {
  const response = await API.get(teacherDetailAPI(payload.branch, payload.id));
  return camelcaseKeys(response.data, { deep: true });
}

export async function deleteTeacher(payload: DeletePayloadData): Promise<number> {
  const response = await API.patch(archiveTeacherAPI(payload.branch, payload.id), {
    replace_with_teacher: payload.teacherId,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateTeacher(data: UpdateTeacherType): Promise<UpdateTeacherType> {
  const {
    payload: {
      profileImage,
      lastName,
      phoneNumber,
      email,
      firstName,
      dateOfBirth,
      confirmPassword,
      gender,
      address,
      password,
      subjects,
      title,
      grades,
    },
    branch,
    teacherId,
  } = data;

  const formData = new FormData();
  formData.append('profile_image', profileImage || '');
  formData.append('last_name', lastName);
  formData.append('phone_number', phoneNumber);
  formData.append('email', email);
  formData.append('first_name', firstName);
  formData.append('date_of_birth', dateOfBirth || '');
  formData.append('confirm_password', confirmPassword || '');
  formData.append('gender', gender);
  formData.append('address', address);
  formData.append('password', password || '');
  formData.append('subjects', JSON.stringify(subjects));
  formData.append('title', title);
  formData.append('grades', JSON.stringify(grades));
  const response = await API.put(updateTeacherAPI(branch, teacherId), formData);

  return camelcaseKeys(response.data, { deep: true });
}

export async function updateTeacherPassword(
  branchId: number,
  teacherId: number,
  body: UpdatePasswordPayload,
): Promise<number> {
  const response = await API.patch(
    b2bTeacherUpdatePassword(branchId, teacherId),
    snakeCaseKeys(body),
  );
  return camelcaseKeys(response.data, { deep: true });
}
