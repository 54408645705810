import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import { getAssignedSubjectsAPI } from 'utils/apiEndpoints';

import API from '../../utils/api';
import { AssignedSubjectType, SemesterSubjectType } from './types';

export async function getAssignedSubjects(semesterId: string): Promise<SemesterSubjectType> {
  const response = await API.get(getAssignedSubjectsAPI(), {
    params: semesterId ? snakeCaseKeys({ semesterId }) : undefined,
  });

  return camelcaseKeys({ semesterId, subjects: response.data }, { deep: true });
}

export async function addAssignedSubjects(
  subjects: Partial<AssignedSubjectType>[],
): Promise<AssignedSubjectType[]> {
  const response = await API.post(
    getAssignedSubjectsAPI(),
    snakeCaseKeys(subjects, { deep: true }),
  );

  return camelcaseKeys(response.data, { deep: true });
}
