import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import {
  getTeacherQuestionsAction,
  deleteTeacherQuestionAction,
  getChildTeacherDetailAction,
  getChildDetailAction,
  postUpdateAnswerAction,
  getTeacherQuestionStatusAction,
} from './actions';
import { TeacherDetailQuestionData } from './types';

export type ChildTeacherDetailQuestionAction = ActionType<typeof actions>;

const initialState = {
  fetching: {
    teacherQuestions: false,
    childTeacherDetail: false,
    childDetail: false,
    postUpdateAnswer: false,
    questionsStatus: false,
  },
  errors: {
    teacherQuestions: '',
    childTeacherDetail: '',
    childDetail: '',
    postUpdateAnswer: '',
  },

  data: {} as TeacherDetailQuestionData,
};

const data = createReducer<TeacherDetailQuestionData, ChildTeacherDetailQuestionAction>(
  initialState.data,
)
  .handleAction(getTeacherQuestionsAction.success, (state, { payload: teacherQuestions }) => ({
    ...state,
    teacherQuestions,
  }))
  .handleAction(getTeacherQuestionStatusAction.success, (state, { payload }) => ({
    ...state,
    questionsStatus: payload,
  }))
  .handleAction(getChildTeacherDetailAction.success, (state, { payload: childTeacherDetail }) => ({
    ...state,
    childTeacherDetail,
  }))
  .handleAction(getChildDetailAction.success, (state, { payload: childDetail }) => {
    return {
      ...state,
      childDetail,
    };
  })
  .handleAction(postUpdateAnswerAction.success, (state, { payload: postedAnswer }) => {
    return {
      ...state,
      teacherQuestions: {
        ...state.teacherQuestions,
        results: state.teacherQuestions.results.map((question) => {
          if (question.id === +postedAnswer.parentQuestion) {
            return { ...question, answer: postedAnswer };
          }

          return question;
        }),
      },
    };
  });

const fetching = combineReducers({
  teacherQuestions: createReducer<boolean, ChildTeacherDetailQuestionAction>(
    initialState.fetching.teacherQuestions,
  )
    .handleAction(
      [getTeacherQuestionsAction.request, deleteTeacherQuestionAction.request],
      () => true,
    )
    .handleAction(
      [getTeacherQuestionsAction.success, getTeacherQuestionsAction.failure],
      () => false,
    ),
  childTeacherDetail: createReducer<boolean, ChildTeacherDetailQuestionAction>(
    initialState.fetching.childTeacherDetail,
  )
    .handleAction(getChildTeacherDetailAction.request, () => true)
    .handleAction(
      [getChildTeacherDetailAction.success, getChildTeacherDetailAction.failure],
      () => false,
    ),
  childDetail: createReducer<boolean, ChildTeacherDetailQuestionAction>(
    initialState.fetching.childDetail,
  )
    .handleAction(getChildDetailAction.request, () => true)
    .handleAction([getChildDetailAction.success, getChildDetailAction.failure], () => false),

  postUpdateAnswer: createReducer<boolean, ChildTeacherDetailQuestionAction>(
    initialState.fetching.postUpdateAnswer,
  )
    .handleAction(postUpdateAnswerAction.request, () => true)
    .handleAction([postUpdateAnswerAction.success, postUpdateAnswerAction.failure], () => false),
});

const errors = combineReducers({
  teacherQuestions: createReducer<string, ChildTeacherDetailQuestionAction>(
    initialState.errors.teacherQuestions,
  )
    .handleAction(getTeacherQuestionsAction.failure, (state, { payload }) => payload)
    .handleAction(getTeacherQuestionsAction.success, () => initialState.errors.teacherQuestions),
});

const childTeacherDetailQuestionReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type ChildTeacherDetailQuestionStateType = StateType<
  typeof childTeacherDetailQuestionReducer
>;

export default childTeacherDetailQuestionReducer;
