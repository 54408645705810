import React, { Fragment, memo } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { getFormattedLabel } from 'utils/formattedLocals';
import { isArabicMode } from 'utils/helpers';

import styles from './Breadcrumbs.module.scss';

export type BreadcrumbType = {
  label: string;
  url?: string;
};

type BreadcrumbsProps = {
  breadcrumbs?: BreadcrumbType[];
};

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const { locale } = useIntl();

  return (
    <div>
      {!!breadcrumbs?.length && (
        <div className={styles.breadcrumbs}>
          {breadcrumbs.map(({ label, url }, index) => (
            <Fragment key={label}>
              {index < breadcrumbs?.length - 1 ? (
                <NavLink to={url || '#'} className={styles.breadcrumbs__path} key={label}>
                  {label}
                </NavLink>
              ) : (
                <span className={cn(styles.breadcrumbs__path, styles.active)} key={label}>
                  {getFormattedLabel(label, isArabicMode(locale))}
                </span>
              )}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(Breadcrumbs);
