import React, { FunctionComponent } from 'react';
import cn from 'classnames';

import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import { Types, Size } from './Loader.types';
import { Icon, IconType } from '../Icon/Icon';
import styles from './Loader.module.scss';

export type LoaderProps = {
  type?: keyof typeof Types;
  size?: keyof typeof Size;
  iconStyle?: IconType;
};

export const Loader: FunctionComponent<LoaderProps> = ({
  type = 'page',
  size = 's60',
  iconStyle = 'loader',
}) => {
  const isGradeOneToThree = useIsGradeOneToThree();

  return (
    <div className={cn(styles[type])}>
      <div className={cn(styles.alwloader, size && styles[size])}>
        <Icon type={isGradeOneToThree ? 'pacman' : iconStyle} size="auto" />
      </div>
    </div>
  );
};
