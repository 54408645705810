import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  fetchGradeMappingsAction,
  fetchStudentsScoreAction,
  fetchStudentSubjectsScoreAction,
} from './actions';
import {
  fetchGradeMappingsAPI,
  fetchStudentsScoreListAPI,
  fetchStudentsSubjectScoreListAPI,
} from './api';

function* fetchGradeMappingsSaga({
  payload,
}: ReturnType<typeof fetchGradeMappingsAction.request>): SagaIterator<void> {
  try {
    const grades = yield call(fetchGradeMappingsAPI, payload);
    yield put(fetchGradeMappingsAction.success(grades));
  } catch (error) {
    yield put(fetchGradeMappingsAction.failure(error));
  }
}

function* fetchStudentsScoreSaga({
  payload,
}: ReturnType<typeof fetchStudentsScoreAction.request>): SagaIterator<void> {
  try {
    const grades = yield call(fetchStudentsScoreListAPI, payload);
    yield put(fetchStudentsScoreAction.success(grades));
  } catch (error) {
    yield put(fetchStudentsScoreAction.failure(error));
  }
}

function* fetchStudentSubjectsScoreSaga({
  payload,
}: ReturnType<typeof fetchStudentSubjectsScoreAction.request>): SagaIterator<void> {
  try {
    const grades = yield call(fetchStudentsSubjectScoreListAPI, payload);
    yield put(fetchStudentSubjectsScoreAction.success(grades));
  } catch (error) {
    yield put(fetchStudentSubjectsScoreAction.failure(error));
  }
}

function* gradingSystemSaga(): SagaIterator {
  yield all([takeLatest(fetchGradeMappingsAction.request, fetchGradeMappingsSaga)]);
  yield all([takeLatest(fetchStudentsScoreAction.request, fetchStudentsScoreSaga)]);
  yield all([takeLatest(fetchStudentSubjectsScoreAction.request, fetchStudentSubjectsScoreSaga)]);
}

export default gradingSystemSaga;
