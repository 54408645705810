import React, { FunctionComponent, memo } from 'react';

import { HeaderTitleWrapper, HeaderTitle } from 'modules/GradeOneToThree/elements';

type HeaderProps = {
  title: string;
  subHeading?: string;
};

const Header: FunctionComponent<HeaderProps> = ({ title, subHeading }) => {
  return (
    <HeaderTitleWrapper>
      <HeaderTitle>{title}</HeaderTitle>
      {subHeading && <p>{subHeading}</p>}
    </HeaderTitleWrapper>
  );
};

export default memo(Header);
