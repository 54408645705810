import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';

import {
  submitStudentAttemptsAPI,
  startQuestionPoolsAttemptUrl,
  submitQuestionGroupsAnswersUrl,
  getQuestionPoolsDetailsUrl,
  fetchNativeEventQuestions,
} from 'utils/apiEndpoints';

import { adapterLessons } from './adapter';
import {
  CompleteEventType,
  EventType,
  NextEventType,
  QuestionPoolsGroupsRequestType,
  StudentAttemptApi,
  StudentAttemptResult,
  SubmitQuestionsAnswerRequestType,
} from './types';

export async function getEvents(id: string): Promise<EventType[]> {
  const response = await API.get(`/api/mentora/lessons/${id}/events`);
  return adapterLessons(camelcaseKeys(response.data, { deep: true }));
}

export async function getNextEvent(id: string): Promise<NextEventType> {
  const response = await API.get(`/api/mentora/lessons/${id}/next`);

  return camelcaseKeys(response.data, { deep: true });
}

export async function completeAllBlocks(id: string): Promise<CompleteEventType> {
  const response = await API.post(`/api/mentora/lessons/complete-event/?event=${id}`);
  return camelcaseKeys(response.data, { deep: true });
}

export async function getQuestionPoolsDetails(eventId: string) {
  const response = await API.get(getQuestionPoolsDetailsUrl(eventId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function startQuestionPoolsAttempt(eventId: string) {
  const response = await API.post(startQuestionPoolsAttemptUrl(eventId), {
    start_datetime: new Date().toISOString(),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function studentAttemptApi({
  eventId,
  studentAttemptId,
}: StudentAttemptApi): Promise<StudentAttemptResult> {
  const response = await API.post(submitStudentAttemptsAPI(eventId, studentAttemptId), {
    end_datetime: new Date(),
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function getQuestionPoolsGroups({
  eventId,
  queryString,
}: QuestionPoolsGroupsRequestType) {
  const response = await API.get(
    `${fetchNativeEventQuestions(eventId)}?${queryString || 'page=1&page_size=1'}`,
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function submitQuestionGroupsAnswers({
  eventId,
  questionGroupId,
  ...payload
}: SubmitQuestionsAnswerRequestType) {
  const response = await API.post(
    submitQuestionGroupsAnswersUrl(eventId, questionGroupId),
    payload,
  );
  return camelcaseKeys(response.data, { deep: true });
}
