import { orderBy } from 'lodash/fp';
import { createSelector } from 'reselect';

import { getDateInt, isBefore } from 'utils/date-time';

import { RootStateType } from '../types';
import { SemesterErrorsType, SemesterType } from './types';
import { selectorSubscriptionExpirationDate } from '../account/selectors';

export const getSemestersDetails = (state: RootStateType): SemesterType[] =>
  orderBy(['isCurrent'], ['desc'])(state.semesters.data.semesterDetails) as SemesterType[];

export const getNextYearSemestersDetails = (state: RootStateType): SemesterType[] =>
  state.semesters.data.nextYearSemesterDetails;

export const getSubscriptionSemestersDetails = (state: RootStateType): SemesterType[] =>
  state.semesters.data.semesterDetails;

export const getCurrentSemesterDetails = createSelector(
  getSemestersDetails,
  (semesters: SemesterType[]): SemesterType => semesters[0],
);

export const getLastSemesterDetails = createSelector(
  getSemestersDetails,
  (semesters: SemesterType[]): SemesterType => {
    return semesters.reduce((acc, semester) => {
      return !acc?.endDate || isBefore(new Date(acc.endDate), new Date(semester.endDate))
        ? semester
        : acc;
    }, {} as SemesterType);
  },
);

export const getPaidSemestersDetails = createSelector(
  getSubscriptionSemestersDetails,
  getCurrentSemesterDetails,
  selectorSubscriptionExpirationDate,
  (
    semesters: SemesterType[],
    currentSemester: SemesterType,
    subscriptionExpirationDate?: string | null,
  ): SemesterType[] => {
    const currentSemesterStartDate = getDateInt(currentSemester?.startDate);

    return semesters.filter(
      ({ enrollmentsCount, startDate, endDate, isCurrentAcademicYear }: SemesterType) => {
        return (
          subscriptionExpirationDate &&
          isCurrentAcademicYear &&
          (enrollmentsCount > 0 ||
            (currentSemesterStartDate < getDateInt(startDate) &&
              getDateInt(subscriptionExpirationDate) >= getDateInt(endDate)))
        );
      },
    );
  },
);

export const semesterSelector = createSelector(
  getSemestersDetails,
  (_: unknown, semesterId: string) => semesterId,
  (semesters, semesterId) => {
    const semester = semesters.find((semester) => semester.id === +semesterId);
    return semester;
  },
);

export const getSemestersIsLoading = (state: RootStateType): boolean => state.semesters.isLoading;
export const getSemestersErrors = (state: RootStateType): SemesterErrorsType =>
  state.semesters.errors;

export const semesterWeeksSelector = (state: RootStateType) => state.semesters.semesterWeeks;
