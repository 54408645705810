import { createAsyncAction } from 'typesafe-actions';

import {
  TeacherDetailQuestionsPayload,
  QuestionDeletePayload,
  AskQuestionPayload,
  ChildDetailPayload,
  ChildDetail,
  TeacherAnswer,
  PostAnswerPayload,
  ChildTeacherDetail,
  PaginatedTeacherQuestion,
  TeacherQuestionsStatusDetail,
} from './types';

export const getTeacherQuestionsAction = createAsyncAction(
  '@@childTeacherDetailQuestion/LOAD_TEACHER_QUESTIONS_REQUEST',
  '@@childTeacherDetailQuestion/LOAD_TEACHER_QUESTIONS_SUCCESS',
  '@@childTeacherDetailQuestion/LOAD_TEACHER_QUESTIONS_FAILURE',
)<TeacherDetailQuestionsPayload, PaginatedTeacherQuestion, string>();

export const getTeacherQuestionStatusAction = createAsyncAction(
  '@@childTeacherDetailQuestion/LOAD_TEACHER_QUESTIONS_STATUS_REQUEST',
  '@@childTeacherDetailQuestion/LOAD_TEACHER_QUESTIONS_STATUS_SUCCESS',
  '@@childTeacherDetailQuestion/LOAD_TEACHER_QUESTIONS_STATUS_FAILURE',
)<void, TeacherQuestionsStatusDetail, string>();

export const deleteTeacherQuestionAction = createAsyncAction(
  '@@childTeacherDetailQuestion/DELETE_TEACHER_QUESTION_REQUEST',
  '@@childTeacherDetailQuestion/DELETE_TEACHER_QUESTION_SUCCESS',
  '@@childTeacherDetailQuestion/DELETE_TEACHER_QUESTION_FAILURE',
)<QuestionDeletePayload, void, string>();

export const getChildTeacherDetailAction = createAsyncAction(
  '@@childTeacherDetailQuestion/LOAD_CHILD_TEACHER_DETAIL_REQUEST',
  '@@childTeacherDetailQuestion/LOAD_CHILD_TEACHER_DETAIL_SUCCESS',
  '@@childTeacherDetailQuestion/LOAD_CHILD_TEACHER_DETAIL_FAILURE',
)<Omit<TeacherDetailQuestionsPayload, 'pageNo'>, ChildTeacherDetail, string>();

export const getChildDetailAction = createAsyncAction(
  '@@childTeacherDetailQuestion/LOAD_CHILD_DETAIL_REQUEST',
  '@@childTeacherDetailQuestion/LOAD_CHILD_DETAIL_SUCCESS',
  '@@childTeacherDetailQuestion/LOAD_CHILD_DETAIL_FAILURE',
)<ChildDetailPayload, ChildDetail, string>();

export const askQuestionAction = createAsyncAction(
  '@@childTeacherDetailQuestion/ASK_QUESTION_REQUEST',
  '@@childTeacherDetailQuestion/ASK_QUESTION_SUCCESS',
  '@@childTeacherDetailQuestion/ASK_QUESTION_FAILURE',
)<AskQuestionPayload, void, string>();

export const postUpdateAnswerAction = createAsyncAction(
  '@@childTeacherDetailQuestion/POST_UPDATE_ANSWER_REQUEST',
  '@@childTeacherDetailQuestion/POST_UPDATE_ANSWER_SUCCESS',
  '@@childTeacherDetailQuestion/POST_UPDATE_ANSWER_FAILURE',
)<PostAnswerPayload, TeacherAnswer, string>();
