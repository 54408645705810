import { createAsyncAction, createAction } from 'typesafe-actions';

import {
  WeeklyPlanItemType,
  WeekLessonsToDoPayloadType,
  WeekLessonsToDoResponse,
  WeekLiveSessionsResponse,
  DashboardTeacher,
  TeachersListPayload,
  PaginationPayloadType,
  ParentQuestion,
  LessonDetailResponseType,
  LessonDetailPayload,
} from './types';

export const getWeeklyPlanAction = createAsyncAction(
  '@@dashboard/LOAD_WEEKLY_PLAN_REQUEST',
  '@@dashboard/LOAD_WEEKLY_PLAN_SUCCESS',
  '@@dashboard/LOAD_WEEKLY_PLAN_FAILURE',
)<undefined, WeeklyPlanItemType[], string>();

export const getWeekLessonsToDoAction = createAsyncAction(
  '@@dashboard/LOAD_WEEK_LESSONS_TO_DO_REQUEST',
  '@@dashboard/LOAD_WEEK_LESSONS_TO_DO_SUCCESS',
  '@@dashboard/LOAD_WEEK_LESSONS_TO_DO_FAILURE',
)<WeekLessonsToDoPayloadType, WeekLessonsToDoResponse, string>();

export const getWeekLiveSessionsAction = createAsyncAction(
  '@@dashboard/LOAD_WEEK_LIVE_SESSIONS_REQUEST',
  '@@dashboard/LOAD_WEEK_LIVE_SESSIONS_SUCCESS',
  '@@dashboard/LOAD_WEEK_LIVE_SESSIONS_FAILURE',
)<WeekLessonsToDoPayloadType, WeekLiveSessionsResponse, string>();

export const getChildTeachersAction = createAsyncAction(
  '@@dashboard/GET_CHILD_TEACHERS_REQUEST',
  '@@dashboard/GET_CHILD_TEACHERS_SUCCESS',
  '@@dashboard/GET_CHILD_TEACHERS_FAILURE',
)<TeachersListPayload, DashboardTeacher[], string>();

export const getParentsQuestionsAction = createAsyncAction(
  '@@dashboard/GET_PARENTS_QUESTIONS_REQUEST',
  '@@dashboard/GET_PARENTS_QUESTIONS_SUCCESS',
  '@@dashboard/GET_PARENTS_QUESTIONS_FAILURE',
)<PaginationPayloadType, ParentQuestion[], string>();

export const getLessonDetailsAction = createAsyncAction(
  '@@dashboard/GET_LESSON_DETAILS_REQUEST',
  '@@dashboard/GET_LESSON_DETAILS_SUCCESS',
  '@@dashboard/GET_LESSON_DETAILS_FAILURE',
)<LessonDetailPayload, LessonDetailResponseType, string>();

export const resetError = createAction('@@dashboard/RESET_ERROR')();
