import { toNumber, toString, map } from 'lodash/fp';
import {
  RegistrationType,
  PostStudentRegistrationType,
  PostParentRegistrationType,
  ChildrenType,
  PostChildrenType,
  RegisterParentType,
  PostRegisterParentType,
} from './types';

const phoneNumberAdapter = (phoneCode: string, phone: string): string =>
  (phoneCode + phone).replace(/[^0-9+]+/g, '');

export const dataStudentAdapter = ({
  agree,
  lastName,
  firstName,
  grade,
  username,
  password,
  gender,
  email,
  refid,
  provider,
  access_token,
  lang,
  role,
  phoneCode,
  phone,
}: RegistrationType): PostStudentRegistrationType => ({
  grade: toNumber(grade),
  terms_of_service: toString(agree),
  firstName,
  lastName,
  username,
  password,
  gender,
  email,
  refid,
  lang: lang.substring(0, 2),
  role,
  provider,
  access_token,
  phoneNumber: phoneNumberAdapter(phoneCode, phone),
});

export const dataParentAdapter = ({
  agree,
  lastName,
  firstName,
  grade,
  username,
  password,
  gender,
  provider,
  access_token,
  email,
  refid,
  lang,
  role,
  children,
  phoneCode,
  phone,
}: RegistrationType): PostParentRegistrationType => ({
  grade: toNumber(grade),
  terms_of_service: toString(agree),
  firstName,
  lastName,
  username,
  password,
  provider,
  access_token,
  gender,
  email,
  refid,
  lang: lang.substring(0, 2),
  role,
  phoneNumber: phoneNumberAdapter(phoneCode, phone),
  children: map(
    ({
      firstName: firstNameChild,
      lastName: lastNameChild,
      username: usernameChild,
      email: emailChild,
      gender: genderChild,
      grade: gradeChild,
    }: any) => ({
      terms_of_service: toString(agree),
      firstName: firstNameChild,
      lastName: lastNameChild,
      username: usernameChild,
      email: emailChild,
      gender: genderChild,
      grade: toNumber(gradeChild),
    }),
    children,
  ),
});

export const childrenAdapter = (children: Array<ChildrenType>): Array<PostChildrenType> =>
  children.map(
    ({
      email,
      firstName,
      gender,
      grade,
      lastName,
      username,
      phoneNumber,
    }: ChildrenType): PostChildrenType => ({
      email,
      firstName,
      gender,
      grade,
      lastName,
      username,
      phoneNumber,
    }),
  );

export const dataInviteParentAdapter = ({
  email,
  username,
  password,
  firstName,
  lastName,
  role,
  parentInvitationCode,
  agree,
  phoneCode,
  phone,
  provider,
  access_token,
}: RegisterParentType): PostRegisterParentType => ({
  email,
  username,
  password,
  firstName,
  lastName,
  role,
  parentInvitationCode,
  terms_of_service: toString(agree),
  phoneNumber: phoneNumberAdapter(phoneCode, phone),
  provider,
  access_token,
});
