import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import { resetSupportAction, sendSupportMessageAction } from './actions';
import { SupportMessageType } from './types';

export type SupportActionType = ActionType<typeof actions>;

const data = createReducer<SupportMessageType, SupportActionType>({} as SupportMessageType)
  .handleAction(sendSupportMessageAction.success, (state, action) => action.payload)
  .handleAction(resetSupportAction, () => ({} as SupportMessageType));

const fetching = combineReducers({
  send: createReducer<boolean, SupportActionType>(false)
    .handleAction(sendSupportMessageAction.request, () => true)
    .handleAction(
      [sendSupportMessageAction.success, sendSupportMessageAction.failure],
      () => false,
    ),
});

const errors = combineReducers({
  load: createReducer<string, SupportActionType>('')
    .handleAction(sendSupportMessageAction.failure, (state, { payload }) => payload)
    .handleAction(sendSupportMessageAction.success, () => ''),
});

const supportReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type SupportStateType = StateType<typeof supportReducer>;

export default supportReducer;
