import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Loader } from 'lib';

import { PRIVACY_POLICY_AR, PRIVACY_POLICY_EN } from 'constants/settings';
import { isArabicMode } from 'utils/helpers';

import { isUserLoggedIn } from 'store/account/selectors';

import styles from './Footer.module.scss';

export type FooterProps = {
  isLoading?: boolean;
};

const Footer = ({ isLoading = false }: FooterProps) => {
  const { formatMessage, locale } = useIntl();

  const isLoggedIn = useSelector(isUserLoggedIn);

  const privacyPolicy = isArabicMode(locale) ? PRIVACY_POLICY_AR : PRIVACY_POLICY_EN;

  if (isLoading) return <Loader />;

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        {!isLoggedIn && (
          <p>
            {formatMessage(
              {
                id: 'login.form.privacy-policy-text',
                defaultMessage:
                  'By logging in to this app, you acknowledge <privacy>Privacy Policy</privacy>',
              },
              {
                privacy: (str) => (
                  <a
                    className={styles.anchor}
                    href={privacyPolicy}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {str}
                  </a>
                ),
              },
            )}
          </p>
        )}
      </div>
    </footer>
  );
};

export default Footer;
