import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { SchoolUserListResponse, TeacherType } from './types';
import { loadSchoolTeachers } from './actions';
import * as actions from './actions';

export type SchoolProgressType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, SchoolProgressType>(true)
  .handleAction(loadSchoolTeachers.request, () => true)
  .handleAction([loadSchoolTeachers.success, loadSchoolTeachers.failure], () => false);

const data = createReducer<SchoolUserListResponse, SchoolProgressType>({
  results: [] as TeacherType[],
} as SchoolUserListResponse).handleAction(
  loadSchoolTeachers.success,
  (state, action) => action.payload,
);

const errors = createReducer<any, SchoolProgressType>({})
  .handleAction(loadSchoolTeachers.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(loadSchoolTeachers.success, (state) => ({
    ...state,
    error: '',
  }));

const schoolTeachersReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export type SchoolTeachersStateType = StateType<typeof schoolTeachersReducer>;

export default schoolTeachersReducer;
