import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { fetchNewsAndUpdatesAction, fetchVideosCardsAction } from './actions';
import { getNewsUpdatesRequest, getVideosCardsRequest } from './api';

function* fetchVideosCardsSaga(): SagaIterator<void> {
  try {
    const result = yield call(getVideosCardsRequest);
    yield put(fetchVideosCardsAction.success(result));
  } catch (error) {
    yield put(fetchVideosCardsAction.failure(error));
  }
}

function* fetchNewsUpdatesSaga({
  payload,
}: ReturnType<typeof fetchNewsAndUpdatesAction.request>): SagaIterator<void> {
  try {
    const result = yield call(getNewsUpdatesRequest, payload);
    yield put(fetchNewsAndUpdatesAction.success(result));
  } catch (error) {
    yield put(fetchNewsAndUpdatesAction.failure(error));
  }
}

function* reportsSaga(): SagaIterator {
  yield all([
    takeEvery(fetchNewsAndUpdatesAction.request, fetchNewsUpdatesSaga),
    takeEvery(fetchVideosCardsAction.request, fetchVideosCardsSaga),
  ]);
}

export default reportsSaga;
