import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { GradeErrorsType, GradeStudentType, GradeType } from './types';
import { loadGradesAction, loadGradeStudentsAction, resetGradeStudents } from './actions';
import * as actions from './actions';

export type GradeActionType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, GradeActionType>(true)
  .handleAction(loadGradesAction.request, () => true)
  .handleAction([loadGradesAction.success, loadGradesAction.failure], () => false);

const data = createReducer<GradeType[], GradeActionType>([]).handleAction(
  loadGradesAction.success,
  (state, action) => action.payload,
);

const errors = createReducer<GradeErrorsType, GradeActionType>({})
  .handleAction(loadGradesAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadGradesAction.success, (state) => ({
    ...state,
    loading: '',
  }));

const gradesReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export const gradeStudentsReducer = combineReducers({
  loading: createReducer<boolean, GradeActionType>(false)
    .handleAction(loadGradeStudentsAction.request, () => true)
    .handleAction([loadGradeStudentsAction.success, loadGradeStudentsAction.failure], () => false),
  data: createReducer<GradeStudentType[], GradeActionType>([])
    .handleAction(loadGradeStudentsAction.success, (state, action) => action.payload)
    .handleAction(resetGradeStudents, () => []),
  errors: createReducer<string, GradeActionType>('')
    .handleAction(loadGradeStudentsAction.failure, (state, { payload }) => payload)
    .handleAction(resetGradeStudents, () => ''),
});

export type GradesStateType = StateType<typeof gradesReducer>;
export type GradeStudentsStateType = StateType<typeof gradeStudentsReducer>;

export default gradesReducer;
