import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { addTeacherAction, loadTeacherOptionsAction } from './actions';
import { addTeacher, getTeacherOptions } from './api';

function* createTeacherRequest({
  payload: { callback, ...payload },
}: ReturnType<typeof addTeacherAction.request>): SagaIterator<void> {
  try {
    yield call(addTeacher, payload);
    yield put(addTeacherAction.success());
    callback && callback('success');
  } catch (error) {
    yield put(addTeacherAction.failure(error));
    callback && callback('fail');
  }
}

function* loadTeacherOptionsRequest({ payload }: any): SagaIterator<void> {
  try {
    const teacherOptions = yield call(getTeacherOptions, payload);
    yield put(loadTeacherOptionsAction.success(teacherOptions));
  } catch (error) {
    yield put(loadTeacherOptionsAction.failure(error));
  }
}

function* schoolTeacherSaga(): SagaIterator {
  yield all([takeEvery(addTeacherAction.request, createTeacherRequest)]);
  yield all([takeEvery(loadTeacherOptionsAction.request, loadTeacherOptionsRequest)]);
}

export default schoolTeacherSaga;
