import { createAsyncAction } from 'typesafe-actions';
import { PaymentHistoryType } from './types';

// TODO rename these actions according naming convention and add a reset action

export const paymentHistoryAction = createAsyncAction(
  '@@paymentHistory/REQUEST',
  '@@paymentHistory/SUCCESS',
  '@@paymentHistory/FAILURE',
)<undefined, PaymentHistoryType[], string>();
