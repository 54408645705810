import { createAsyncAction } from 'typesafe-actions';
import { AddNewTeacherType } from './types';

export const addTeacherAction = createAsyncAction(
  '@@teacher/CREATE_REQUEST',
  '@@teacher/CREATE_SUCCESS',
  '@@teacher/CREATE_FAILURE',
)<AddNewTeacherType, undefined, any>();

export const loadTeacherOptionsAction = createAsyncAction(
  '@@teacher/LOAD_REQUEST',
  '@@teacher/LOAD_SUCCESS',
  '@@teacher/LOAD_FAILURE',
)<any, any, any>();
