import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { SchoolUserListResponse, TeacherProgressType } from './types';
import { loadSchoolTeachersProgress } from './actions';
import * as actions from './actions';

export type SchoolProgressType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, SchoolProgressType>(true)
  .handleAction(loadSchoolTeachersProgress.request, () => true)
  .handleAction(
    [loadSchoolTeachersProgress.success, loadSchoolTeachersProgress.failure],
    () => false,
  );

const data = createReducer<SchoolUserListResponse, SchoolProgressType>({
  results: [] as TeacherProgressType[],
} as SchoolUserListResponse).handleAction(
  loadSchoolTeachersProgress.success,
  (state, action) => action.payload,
);
const errors = createReducer<any, SchoolProgressType>({})
  .handleAction(loadSchoolTeachersProgress.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(loadSchoolTeachersProgress.success, (state) => ({
    ...state,
    error: '',
  }));

const schoolTeachersProgressReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export type SchoolTeachersProgressStateType = StateType<typeof schoolTeachersProgressReducer>;

export default schoolTeachersProgressReducer;
