import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  ParticipantsPayloadType,
  GroupType,
  GroupDetails,
  TokenType,
  SpamWords,
  AddChatTopicPayloadType,
} from './types';

export const listGroupAction = createAsyncAction(
  '@@chatGroup/LIST_GROUP_REQUEST',
  '@@chatGroup/LIST_GROUP_SUCCESS',
  '@@chatGroup/LIST_GROUP_FAILURE',
)<undefined, GroupType[], string>();

export const groupDetailAction = createAsyncAction(
  '@@chatGroup/GROUP_Detail_REQUEST',
  '@@chatGroup/GROUP_Detail_SUCCESS',
  '@@chatGroup/GROUP_Detail_FAILURE',
)<number, GroupDetails, string>();

export const unblockParticipantAction = createAsyncAction(
  '@@chatGroup/UNBLOCK_PARTICIPANT_REQUEST',
  '@@chatGroup/UNBLOCK_PARTICIPANT_SUCCESS',
  '@@chatGroup/UNBLOCK_PARTICIPANT_FAILURE',
)<ParticipantsPayloadType, {}, string>();

export const addChatGroupTitle = createAsyncAction(
  '@@chatGroup/GROUP_TITLE_REQUEST',
  '@@chatGroup/GROUP_TITLE_SUCCESS',
  '@@chatGroup/GROUP_TITLE_FAILURE',
)<AddChatTopicPayloadType, {}, string>();

export const blockParticipantAction = createAsyncAction(
  '@@chatGroup/BLOCK_PARTICIPANT_REQUEST',
  '@@chatGroup/BLOCK_PARTICIPANT_SUCCESS',
  '@@chatGroup/BLOCK_PARTICIPANT_FAILURE',
)<ParticipantsPayloadType, {}, string>();

export const getAccessTokenAction = createAsyncAction(
  '@@chatGroup/GET_ACCESS_TOKEN_REQUEST',
  '@@chatGroup/GET_ACCESS_TOKEN_SUCCESS',
  '@@chatGroup/GET_ACCESS_TOKEN_FAILURE',
)<undefined, TokenType, string>();

export const getPeer = createAsyncAction(
  '@@chat/GET_PEER_REQUEST',
  '@@chat/GET_PEER_SUCCESS',
  '@@chat/GET_PEER_FAILURE',
)<undefined, any, string>();

export const getSpamWords = createAsyncAction(
  '@@chatGroup/GET_FILTERED_WORD_REQUEST',
  '@@chatGroup/GET_FILTERED_WORD_SUCCESS',
  '@@chatGroup/GET_FILTERED_WORD_FAILURE',
)<undefined, SpamWords, string>();

export const getBlockStatus = createAsyncAction(
  '@@chatGroup/GET_BLOCK_STATUS_REQUEST',
  '@@chatGroup/GET_BLOCK_STATUS_SUCCESS',
  '@@chatGroup/GET_BLOCK_STATUS_FAILURE',
)<number, { isBlocked: boolean }, string>();

export const resetChatAction = createAction('@@chatgroup/RESET')();
