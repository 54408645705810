import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  gradeValue: {
    id: 'assign.teachers.assigned.grade',
    defaultMessage: 'Grade { grade }',
  },
  classRoom: {
    id: 'classroom.label',
    defaultMessage: 'Classroom {grade}',
  },
  switchRole: {
    id: 'role.switch-button.label',
    defaultMessage: 'Switch Role',
  },
  help: {
    id: 'help.title',
    defaultMessage: 'Help',
  },
  settings: {
    id: 'settings.title',
    defaultMessage: 'Settings',
  },
  changeSubjects: {
    id: 'settings.change.subject',
    defaultMessage: 'Change Subjects',
  },
  startTour: {
    id: 'tour.startButton.label',
    defaultMessage: 'Start Tour',
  },
  signOut: {
    id: 'account.account.logout-button',
    defaultMessage: 'Sign out',
  },
});
