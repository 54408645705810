import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  fetchGradeSubjectsAction,
  fetchQuestionsAction,
  fetchLessonEventsAction,
  deleteMainQuestionAction,
  deleteSubQuestionAction,
  fetchLearningObjectivesAction,
  uploadContentAction,
  deleteContentAction,
  discardChangesAction,
  createEditQuestionsAction,
  editMainQuestionAction,
  fetchPreviewQuestionsAction,
} from './actions';
import {
  fetchGradeSubjects,
  fetchQuestions,
  fetchLessonEvents,
  deleteMainQuestion,
  deleteSubQuestion,
  fetchLearningObjectives,
  uploadContent,
  deleteContent,
  discardChanges,
  createEditQuestions,
  editMainQuestion,
} from './api';
import { GradeSubjects, LessonQuestionsResponse, LessonEvent } from './types';

function* fetchGradeSubjectsRequest({
  payload,
}: ReturnType<typeof fetchGradeSubjectsAction.request>): SagaIterator<void> {
  try {
    const gradeSubjects: GradeSubjects = yield call(fetchGradeSubjects, payload.gradeId);
    yield put(fetchGradeSubjectsAction.success(gradeSubjects));
  } catch (error) {
    yield put(fetchGradeSubjectsAction.failure(error));
  }
}

function* fetchQuestionsRequest({
  payload: { isPreview, ...restPayload },
}: ReturnType<typeof fetchQuestionsAction.request>): SagaIterator<void> {
  try {
    const lessonsQuestions: LessonQuestionsResponse = yield call(fetchQuestions, restPayload);
    if (isPreview) yield put(fetchPreviewQuestionsAction.success(lessonsQuestions));
    else yield put(fetchQuestionsAction.success(lessonsQuestions));
  } catch (error) {
    yield put(fetchQuestionsAction.failure(error));
  }
}

function* fetchLessonEventsRequest({
  payload,
}: ReturnType<typeof fetchLessonEventsAction.request>): SagaIterator<void> {
  try {
    const lessonEvents: LessonEvent[] = yield call(fetchLessonEvents, payload.lessonUsageKey);
    yield put(fetchLessonEventsAction.success(lessonEvents));
  } catch (error) {
    yield put(fetchLessonEventsAction.failure(error));
  }
}

function* deleteMainQuestionRequest({
  payload,
}: ReturnType<typeof deleteMainQuestionAction.request>): SagaIterator<void> {
  try {
    yield call(deleteMainQuestion, payload);
    yield put(deleteMainQuestionAction.success());
    payload?.callback?.('success');
  } catch (error) {
    yield put(deleteMainQuestionAction.failure(error));
  }
}

function* deleteSubQuestionRequest({
  payload,
}: ReturnType<typeof deleteSubQuestionAction.request>): SagaIterator<void> {
  try {
    yield call(deleteSubQuestion, payload);
    yield put(deleteSubQuestionAction.success());
    payload?.callback?.('success');
  } catch (error) {
    yield put(deleteSubQuestionAction.failure(error));
  }
}

function* fetchLearningObjectivesRequest({
  payload,
}: ReturnType<typeof fetchLearningObjectivesAction.request>): SagaIterator<void> {
  try {
    const learningObjectives = yield call(fetchLearningObjectives, payload.lessonKey);
    yield put(fetchLearningObjectivesAction.success(learningObjectives));
  } catch (error) {
    yield put(fetchLearningObjectivesAction.failure(error));
  }
}

function* createEditQuestionsRequest({
  payload,
}: ReturnType<typeof createEditQuestionsAction.request>): SagaIterator<void> {
  try {
    const createdQuestions = yield call(createEditQuestions, payload);
    yield put(createEditQuestionsAction.success(createdQuestions));
    payload?.callback?.({ type: 'success' });
  } catch (error) {
    yield put(createEditQuestionsAction.failure(error));
    payload?.callback?.({ type: 'error', error: Object.values(error) });
  }
}

function* uploadContentRequest({
  payload,
}: ReturnType<typeof uploadContentAction.request>): SagaIterator<void> {
  try {
    const response = yield call(uploadContent, payload);
    yield put(uploadContentAction.success(response));
    payload?.callback?.({ type: 'success', uploadedContentUrl: response.url });
  } catch (error) {
    let firstError = '';
    if (error?.content && Array.isArray(error?.content)) {
      [firstError] = error.content;
    }
    payload?.callback?.({ type: 'error', message: firstError });
    yield put(uploadContentAction.failure(error));
  }
}

function* deleteContentRequest({
  payload,
}: ReturnType<typeof deleteContentAction.request>): SagaIterator<void> {
  try {
    const learningObjectives = yield call(deleteContent, payload);
    yield put(deleteContentAction.success(learningObjectives));
  } catch (error) {
    yield put(deleteContentAction.failure(error));
  }
}

function* discardChangesRequest({
  payload,
}: ReturnType<typeof discardChangesAction.request>): SagaIterator<void> {
  try {
    const response = yield call(discardChanges, payload);
    yield put(discardChangesAction.success(response));
    payload?.callback?.('success');
  } catch (error) {
    yield put(discardChangesAction.failure(error));
    payload?.callback?.('error', error.duration_in_minutes[0]);
  }
}

function* editQuestionRequest({
  payload,
}: ReturnType<typeof editMainQuestionAction.request>): SagaIterator<void> {
  try {
    const createdQuestions = yield call(editMainQuestion, payload);
    yield put(editMainQuestionAction.success(createdQuestions));
    payload?.callback?.('success');
  } catch (error) {
    yield put(editMainQuestionAction.failure(error));
  }
}

function* nativeEventsSaga(): SagaIterator {
  yield all([
    takeEvery(fetchGradeSubjectsAction.request, fetchGradeSubjectsRequest),
    takeEvery(fetchQuestionsAction.request, fetchQuestionsRequest),
    takeEvery(fetchLessonEventsAction.request, fetchLessonEventsRequest),
    takeEvery(deleteMainQuestionAction.request, deleteMainQuestionRequest),
    takeEvery(deleteSubQuestionAction.request, deleteSubQuestionRequest),
    takeEvery(fetchLearningObjectivesAction.request, fetchLearningObjectivesRequest),
    takeEvery(uploadContentAction.request, uploadContentRequest),
    takeEvery(deleteContentAction.request, deleteContentRequest),
    takeEvery(discardChangesAction.request, discardChangesRequest),
    takeEvery(createEditQuestionsAction.request, createEditQuestionsRequest),
    takeEvery(editMainQuestionAction.request, editQuestionRequest),
    // takeEvery(editSubQuestionAction.request, editSubQuestionRequest),
  ]);
}

export default nativeEventsSaga;
