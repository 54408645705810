import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';

import * as actions from './actions';
import {
  orientationDemoAction,
  orientationVideoAction,
  resetOrientationDataAction,
  setUserGuideMenuStateAction,
} from './actions';
import { OrientationInitialData } from './types';

export type OrientationActionsType = ActionType<typeof actions>;

const initialState = {
  data: {} as OrientationInitialData,
  fetching: {
    demo: false,
    featureVideos: false,
  },
  errors: {
    demo: '',
    featureVideos: '',
  },
};

const data = createReducer<OrientationInitialData, OrientationActionsType>(initialState.data)
  .handleAction([orientationDemoAction.success], (state, { payload }) => ({
    ...state,
    ...{ demo: payload },
  }))
  .handleAction([actions.orientationVideoAction.success], (state, { payload }) => ({
    ...state,
    ...{ featureVideos: payload },
  }))
  .handleAction([resetOrientationDataAction], () => initialState.data);

const fetching = combineReducers({
  demo: createReducer<boolean, OrientationActionsType>(true)
    .handleAction(orientationDemoAction.request, () => true)
    .handleAction([orientationDemoAction.success, orientationDemoAction.failure], () => false)
    .handleAction([resetOrientationDataAction], () => initialState.fetching.demo),
  featureVideos: createReducer<boolean, OrientationActionsType>(false)
    .handleAction(orientationVideoAction.request, () => true)
    .handleAction([orientationVideoAction.success, orientationVideoAction.failure], () => false)
    .handleAction([resetOrientationDataAction], () => initialState.fetching.featureVideos),
});

const errors = combineReducers({
  demo: createReducer<string, OrientationActionsType>('')
    .handleAction(orientationDemoAction.failure, (state, { payload }) => payload)
    .handleAction(orientationDemoAction.success, () => '')
    .handleAction([resetOrientationDataAction], () => initialState.errors.demo),
  featureVideos: createReducer<string, OrientationActionsType>('')
    .handleAction(orientationVideoAction.failure, (state, { payload }) => payload)
    .handleAction(orientationVideoAction.success, () => '')
    .handleAction([resetOrientationDataAction], () => initialState.errors.featureVideos),
});

const userGuideMenuState = createReducer<boolean, OrientationActionsType>(false).handleAction(
  setUserGuideMenuStateAction,
  (_, { payload }) => payload,
);

const orientationsReducer = combineReducers({
  fetching,
  data,
  errors,
  userGuideMenuState,
});

export type OrientationsStateType = StateType<typeof orientationsReducer>;

export default orientationsReducer;
