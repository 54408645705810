import React, { FunctionComponent, useRef, useState, ReactChild } from 'react';
import { useIntl } from 'react-intl';
import { MenuPlacement } from 'react-select';
import { noop, uniqueId } from 'lodash/fp';
import cn from 'classnames';

import { ErrorMessage } from './ErrorMessage/ErrorMessage';
import { FieldLabel } from './FieldLabel/FieldLabel';
import styles from './InputField.module.scss';
import { SelectV2 } from './SelectV2/SelectV2';
import { IconCommon } from '../IconCommon/IconCommon';
import { Popover } from '../Popover/Popover';

type SelectFieldProps = {
  id?: string;
  name?: string;
  disabled?: boolean;
  searchable?: boolean;
  readonly?: boolean;
  clearable?: boolean;
  placeholder?: string;
  value?: any;
  defaultValue?: object;
  title?: string;
  error?: string | boolean;
  onChange?: any;
  onBlur?: any;
  disableMirror?: boolean;
  maxMenuHeight?: number;
  options: Array<object>;
  getOptionLabel?: (value: any) => string;
  getOptionValue?: (value: any) => string;
  menuPlacement?: MenuPlacement;
  isLoading?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  blurInputOnSelect?: boolean;
  className?: string;
  fullWidth?: boolean;
  hideSelectedOptions?: boolean;
  isCreatable?: boolean;
  onCreateOption?: (val: string) => void;
  onCreateUpdateOption?: (val: string, updateId: string | number) => void;
  formatCreateLabel?: (val: string) => string;
  isCreateAndEdit?: boolean;
  createMenuTitle?: string;
  updateMenuTitle?: string;
  isRequired?: boolean;
};

export const SelectField: FunctionComponent<SelectFieldProps> = ({
  id = uniqueId('input_'),
  name = '',
  disabled = false,
  searchable,
  readonly,
  clearable,
  defaultValue,
  placeholder = '',
  value,
  title = '',
  error = '',
  onChange = noop,
  onBlur = noop,
  disableMirror,
  maxMenuHeight,
  options,
  getOptionLabel,
  getOptionValue,
  menuPlacement,
  isLoading,
  closeMenuOnSelect = true,
  isMulti = false,
  blurInputOnSelect,
  className,
  fullWidth,
  hideSelectedOptions,
  isCreatable = false,
  onCreateOption,
  onCreateUpdateOption,
  formatCreateLabel,
  isCreateAndEdit = false,
  isRequired,
}) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [showInputField, setShowInput] = useState(false);
  const isError = !!error;

  const selectProps = {
    options,
    defaultValue,
    placeholder,
    isSearchable: searchable,
    isDisabled: disabled,
    isReadOnly: readonly,
    isClearable: clearable,
    maxMenuHeight,
    isError,
    value,
    onChange,
    onBlur,
    getOptionLabel,
    getOptionValue,
    menuPlacement,
    isLoading,
    closeMenuOnSelect,
    isMulti,
    name,
    blurInputOnSelect,
    className,
    fullWidth,
    hideSelectedOptions,
    isCreatable,
    onCreateOption,
    formatCreateLabel,
    isRequired,
  };

  return (
    <>
      <div
        className={cn(styles['form-group'], className, {
          'error-group': isError,
          [styles.full]: fullWidth,
        })}
      >
        <FieldLabel title={title} htmlFor={id} required={isRequired} />
        <div dir={disableMirror ? 'ltr' : undefined}>
          <SelectV2 {...selectProps} isDisabled={disabled || showInputField} />
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        {isCreateAndEdit && !disabled && !showInputField && (
          <div className={styles.editFieldValue}>
            <IconCommon
              className={value ? 'icon-edit' : 'icon-plus-round'}
              clickHandler={() => {
                setShowInput(true);
              }}
            />
          </div>
        )}
        {showInputField && (
          <Popover
            isVisible={showInputField}
            isUnwrapped
            setVisibility={() => {
              setShowInput(false);
            }}
            className={styles.editPopover}
            popover={(): ReactChild => (
              <div className={cn('skill-tooltip__detail', styles.updateLine)}>
                <div className="flex-grow">
                  <FieldLabel
                    title={
                      value
                        ? formatMessage({
                            id: 'select.update.option.title',
                            defaultMessage: 'Update Option',
                          })
                        : formatMessage({
                            id: 'select.create.option.title',
                            defaultMessage: 'Create Option',
                          })
                    }
                  />
                  <input
                    className="form-control"
                    defaultValue={value?.label || ''}
                    ref={inputRef}
                  />
                </div>
                <IconCommon
                  className="icon-success"
                  clickHandler={() => {
                    onCreateUpdateOption?.(inputRef.current?.value || '', value?.value);
                    setShowInput(false);
                  }}
                />
                <IconCommon className="icon-cross-round" clickHandler={() => setShowInput(false)} />
              </div>
            )}
          >
            <div className={styles.editFieldValue}>
              <IconCommon
                className="icon-cross-round"
                clickHandler={() => {
                  setShowInput(false);
                }}
              />
            </div>
          </Popover>
        )}
      </div>
    </>
  );
};
