import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import styles from './IconCommon.module.scss';
import { Sizes, Spaces, Weight, Colors, MaterialType } from './IconCommon.constants';

type Props = {
  className: string;
  disabled?: boolean;
  clickHandler?: any;
  space?: keyof typeof Spaces;
  size?: keyof typeof Sizes;
  color?: keyof typeof Colors;
  classes?: string;
  weight?: keyof typeof Weight;
  isMaterialIcon?: boolean;
  materialIconType?: keyof typeof MaterialType;
};

export const IconCommon: FunctionComponent<Props> = ({
  clickHandler,
  className,
  space,
  size,
  color,
  classes = '',
  weight = 'normal',
  isMaterialIcon,
  materialIconType = 'filled',
  disabled = false,
}) => {
  const materialType = materialIconType === 'filled' ? '' : `-${materialIconType}`;
  return (
    <i
      onClick={clickHandler}
      className={cn(
        !isMaterialIcon && styles.icon,
        isMaterialIcon && `material-icons${materialType}`,
        space && styles[space],
        weight && styles[weight],
        color && [styles[color]],
        size && styles[size],
        styles[className],
        classes,
        disabled && styles.disabled,
      )}
    >
      {isMaterialIcon && className}
    </i>
  );
};
