import { combineReducers } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { loadLessonsAction, resetLessonsAction } from './actions';
import { LessonErrorsType, LessonType } from './types';

export type LessonActionType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, LessonActionType>(true)
  .handleAction([loadLessonsAction.request, resetLessonsAction], () => true)
  .handleAction([loadLessonsAction.success, loadLessonsAction.failure], () => false);

const data = createReducer<LessonType[], LessonActionType>([])
  .handleAction(loadLessonsAction.success, (state, action) => action.payload)
  .handleAction(resetLessonsAction, () => []);

const errors = createReducer<LessonErrorsType, LessonActionType>({})
  .handleAction(loadLessonsAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadLessonsAction.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(resetLessonsAction, () => ({}));

const lessonsReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export default lessonsReducer;
