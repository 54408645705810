import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import { setWatchedTourAPI, tourManagementAPI } from 'utils/apiEndpoints';

import { SetWatchedRequestType, TourResponseType, TourType } from './types';

const tourAdapter = (tour: TourResponseType): TourType => {
  return {
    id: tour.id,
    titleEn: tour.titleEn,
    titleAr: tour.titleAr,
    detailEn: tour.detailEn,
    detailAr: tour.detailAr,
    page: tour.page,
    userType: tour.userType,
    isWatched: tour.isWatched,
    isWatchedInAllowedHours: tour.isWatchedInAllowedHours,
    isActive: tour.isActive,
    steps: tour?.steps?.map((step) => ({
      fileEn: step.fileEn,
      fileAr: step.fileAr,
      headingEn: step.headingEn,
      headingAr: step.headingAr,
      detailEn: step.detailEn,
      detailAr: step.detailAr,
      order: Number(step.order),
      componentId: step.componentId,
    })),
  };
};

export async function getTourDetail({ page, userType, gradeType }: any): Promise<TourType> {
  const response = await API.get(
    `${tourManagementAPI(page)}/?user_type=${userType}${
      gradeType ? `&grade_type=${gradeType}` : ''
    }`,
  );
  return tourAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function setWatchedTour(data: SetWatchedRequestType): Promise<any> {
  const response = await API.post(setWatchedTourAPI(), data);

  return response.data;
}
