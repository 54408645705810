import camelcaseKeys from 'camelcase-keys';

import { NotificationsType, NotificationsReturnType } from './types';
import API from '../../utils/api';

export async function getNotificationsOld(): Promise<NotificationsType> {
  const response = await API.get('/api/notifications/my/', {
    params: {
      current_week: true,
    },
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function getNotifications(page: number): Promise<NotificationsReturnType> {
  const response = await API.get('/api/notifications/v2/my/', {
    params: {
      page,
    },
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getNotificationsUnreadCount(): Promise<any> {
  const response = await API.get('/api/notifications/my/unread-count/');
  return camelcaseKeys(response.data, { deep: true });
}

export async function markNotifications(notifications: number[]): Promise<void> {
  await API.post('/api/notifications/my/mark', {
    notifications,
  });
}
