import { getSvgURL } from 'lib';

import { DISABLE_B2C_REWARDS } from 'constants/settings';
import { ROUTES } from 'constants/routes';
import { getIntl } from 'utils/intl';
import { isSummerSemester } from 'utils/summerSemesterUtils';

import { SubscriptionServiceEnum } from 'store/subscriptions/types';

export type MenuItemType = {
  path?: string;
  label?: string;
  image: string;
  id: string;
  hide?: boolean;
  disabled?: boolean;
};

export const getStudentMenu = (
  isSliderMenu?: boolean,
  isB2BStudent?: boolean,
  disabledServices = {} as Record<SubscriptionServiceEnum, boolean>,
): MenuItemType[] => {
  const { formatMessage } = getIntl();
  const menu: MenuItemType[] = [
    {
      id: 'menu__learning_path',
      path: ROUTES.learningPath,
      label: formatMessage({ id: 'learning-path.title', defaultMessage: 'Learning Path' }),
      image: getSvgURL('learning-path'),
      disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.RECORDED_CONTENT],
    },
    {
      id: 'menu__schedule',
      path: ROUTES.schedule,
      label: formatMessage({ id: 'schedule.title', defaultMessage: 'Schedule' }),
      image: getSvgURL('schedule'),
      hide: isSummerSemester,
      disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.WEEKLY_REVIEW_SESSIONS],
    },
    {
      id: 'menu__games',
      path: ROUTES.games,
      label: formatMessage({ id: 'navbar.menu.games.title', defaultMessage: 'Games' }),
      image: getSvgURL('game-icon', true),
      hide: isSummerSemester,
    },
    {
      id: 'menu__lab',
      path: ROUTES.lab,
      label: formatMessage({ id: 'navbar.menu.lab.title', defaultMessage: 'Lab' }),
      image: getSvgURL('lab-icon', true),
      hide: isSummerSemester,
    },
    {
      id: 'menu__exams',
      label: formatMessage({ id: 'navbar.menu.exams.title', defaultMessage: 'Exams' }),
      path: ROUTES.studentExams,
      image: getSvgURL('exam'),
      hide: isSummerSemester,
      disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.EXERCISES_AND_EXAMS],
    },
    {
      id: 'menu__content_library',
      path: ROUTES.contentLibrary,
      label: formatMessage({ id: 'content-library.title', defaultMessage: 'Content Library' }),
      image: getSvgURL('content-library'),
      hide: isSummerSemester,
      disabled: !isB2BStudent && disabledServices[SubscriptionServiceEnum.RECORDED_CONTENT],
    },
    {
      id: 'menu__reports',
      label: formatMessage({ id: 'b2bTeacher.reports.title', defaultMessage: 'Reports' }),
      path: ROUTES.selfReport,
      image: getSvgURL('report'),
      hide: isSummerSemester,
    },
  ];

  if (!DISABLE_B2C_REWARDS && isSliderMenu) {
    menu.push({
      id: 'menu__rewards',
      label: formatMessage({ id: 'navbar.menu.rewards.title', defaultMessage: 'My Rewards' }),
      path: ROUTES.rewards,
      image: getSvgURL('reward-icon', true),
      hide: isSummerSemester,
    });
  }

  if (isSliderMenu && isB2BStudent) {
    menu.push({
      id: 'menu__activities',
      path: ROUTES.assignments,
      label: formatMessage({ id: 'assignments.title', defaultMessage: 'Assignments' }),
      image: getSvgURL('assignment'),
      hide: isSummerSemester,
    });

    if (DISABLE_B2C_REWARDS) {
      menu.push({
        id: 'menu__rewards',
        label: formatMessage({ id: 'navbar.menu.rewards.title', defaultMessage: 'My Rewards' }),
        path: ROUTES.rewards,
        image: getSvgURL('reward-icon', true),
        hide: isSummerSemester,
      });
    }
  }

  if (!isSliderMenu && isB2BStudent) {
    menu.push(
      {
        id: 'menu__chat',
        path: ROUTES.Chat,
        label: formatMessage({ id: 'chat.teacher.title', defaultMessage: 'Teacher Communication' }),
        image: '',
        hide: isSummerSemester,
      },
      {
        id: 'menu__activities',
        path: ROUTES.assignments,
        label: formatMessage({ id: 'assignments.title', defaultMessage: 'Assignments' }),
        image: '',
        hide: isSummerSemester,
      },
    );
  }

  return menu.filter((m) => !m.hide);
};
