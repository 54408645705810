import React from 'react';
import { Chart as ChartJS, DoughnutController, ArcElement } from 'chart.js';
import { Doughnut, ChartProps } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import { DEFAULT_OPTIONS_DOUGHNUT } from './Chart.constants';

ChartJS.register(DoughnutController, ArcElement);

export const DoughnutChart = ({ options, ...restProps }: Omit<ChartProps<'doughnut'>, 'type'>) => (
  <Doughnut {...restProps} options={{ ...DEFAULT_OPTIONS_DOUGHNUT, ...options }} />
);
