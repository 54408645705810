import { createSelector } from 'reselect';

import { ChildTeacherDetailQuestionStateType } from './reducer';
import { RootStateType } from '../types';

export const childTeacherDetailQuestionSelector = (
  state: RootStateType,
): ChildTeacherDetailQuestionStateType => state.childTeacherDetailQuestion;

export const teacherQuestionsSelector = createSelector(
  childTeacherDetailQuestionSelector,
  ({ data, fetching, errors }) => ({
    teacherQuestions: data.teacherQuestions,
    fetchingTeacherQuestions: fetching.teacherQuestions,
    errorTeacherQuestions: errors.teacherQuestions,
  }),
);

export const teacherQuestionsStatusSelector = createSelector(
  childTeacherDetailQuestionSelector,
  ({ data }) => ({
    questionsStatus: data.questionsStatus,
  }),
);

export const childTeacherDetailSelector = createSelector(
  childTeacherDetailQuestionSelector,
  ({ data, fetching }) => ({
    childTeacherDetail: data.childTeacherDetail,
    fetchingChildTeacherDetail: fetching.childTeacherDetail,
  }),
);

export const childDetailSelector = createSelector(
  childTeacherDetailQuestionSelector,
  ({ data, fetching }) => ({
    childDetail: data.childDetail,
    fetchingChildDetail: fetching.childDetail,
  }),
);

export const postUpdateAnswerInProgressSelector = createSelector(
  childTeacherDetailQuestionSelector,
  ({ fetching }) => ({
    postUpdateAnswerInProgress: fetching.postUpdateAnswer,
  }),
);
