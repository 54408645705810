import { FormatNumberOptions } from 'react-intl';

export const currencyFormat: FormatNumberOptions = {
  style: 'currency',
  currency: 'sar',
};

export enum SubscriptionTypes {
  trial = 'trial',
  certificate = 'Certificate',
}

export const SUMMER_SEMESTER_TYPE = 2;

export enum SubscriptionPlanTypes {
  empty = 'empty',
  trial = 'trial',
  coupon = 'coupon',
  monthly = 'monthly',
  semester = 'semester',
  yearly = 'yearly',
}

export enum ChildSubscriptionTypes {
  trial = 'Trial',
  standard = 'Standard',
  coupon = 'Coupon',
  withOutSubscription = 'withOutSubscription',
}

export enum SubscriptionPaymentTypes {
  TRIAL = 0,
  SEMESTER = 1,
  YEARLY = 2,
  MONTHLY = 3,
  MONTHLY_RECURRING = 4,
}

export enum SubscriptionPaymentMethods {
  BASIC = 'basic',
  STANDARD = 'standard',
}

export const FREE_PLAN = 'free';
export const PAID_PLAN = 'standard';

export enum PaymentStatus {
  SUCCESS,
  FAIL,
  IN_PROGRESS,
  INITIATED,
}

export enum StateSubscription {
  INITIAL,
  PROLONGATION,
  CANCEL,
  TERMINATION,
}

export enum Period {
  Trial = 14,
  Semester = 140,
  Monthly = 30,
}

export enum LearningProfile {
  VSL = 1,
  ASL = 2,
  LR = 3,
}

export const LearningProfiles = [
  {
    abbreviation: 'VSL',
    definition: 'Virtual School Learner',
    description:
      'Provides the full learning experience – for studen…or those wanting a deeper treatment of a subject.',
    id: 1,
  },
  {
    abbreviation: 'ASL',
    definition: 'After School Learner',
    description:
      'Provides a summary of lessons and an opportunity t…eal as a supplement for students attending school',
    id: 2,
  },
  {
    abbreviation: 'LR',
    definition: 'Light Reviewer',
    description:
      'For students interested in the core learning mater… but not needing to summary material or exercises',
    id: 3,
  },
];

export const SIZE_ONE_MB = 1048576;

export const MAX_ATTACHMENT_FILE_SIZE_MP4 = 15 * SIZE_ONE_MB;

export const MAX_ATTACHMENT_FILE_SIZE = 15 * SIZE_ONE_MB;

export const ESCAPE_KEY = 27;

export const BACKEND_DATE_FORMAT = 'yyyy-MM-dd';

export const REDIRECT_LOGIN_URL = 'redirectLoginURL';

export const IN_APP_RENDERING_VIEW = 'inapp';

export const MAX_ATTACHMENT_SIZE = 15 * SIZE_ONE_MB;

export const MAX_DISCUSSION_TOPIC_LENGTH = 50;

export const MESIBO_SCRIPT_URL = 'https://api.mesibo.com/mesibo-v2.js';

export const BLOCK_API_DELAY = 20000;

export const MAX_COMMENT_LENGTH = 20000;

export const SITEPAL_ANS_LENGTH = 512;

export const READ_DB_SESSION_DELAY = 20000;

export const RESTRICTED_GRADES = [1, 2, 3];

export const RESTRICTED_MENU_ITEMS = [
  'sidebar__chatgroup',
  'sidebar__question_bank',
  'sidebar__exercises',
];

export const EXPORT_PDF_LENGTH = 1000;

export enum PersistedStateKeys {
  registrationFields = 'registrationFields',
}
export const MAX_NAME_LENGTH = 30;

export const MAX_EXAM_TITLE_LENGTH = 25;

export const MAX_SESSION_ATTACHMENTS_LEN = 5;

export const REACT_TABLE_PAGE_SIZES = [10, 25, 50, 100];

export const TABLE_DEFAULT_PAGE_SIZE = 10;

export const MAX_SERVICE_LIMIT = 50;

export const CALENDAR_FIELD_LIMIT = 100;

export const MAX_FIELD_LIMIT = 255;

export const ENGLISH_NODES = {
  en: '2. English',
  ar: '١. إنجليزي',
};

export const ARABIC_NODES = {
  en: '1. Arabic',
  ar: '٢. عربي',
};

export const VALID_LANG_NODES = [
  ENGLISH_NODES.en,
  ENGLISH_NODES.ar,
  ARABIC_NODES.en,
  ARABIC_NODES.ar,
];

export const SESSION_TIME_FORMAT = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const INACTIVITY_STATUS = 'inactivity_staus';

export const SESSION_DATE_FORMAT = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

export const SESSION_JOIN_DELAY = 900000;

export const defaultUserNameRgx = {
  firstName: /^[Ss]tudent+$/,
  lastName: /^[Nn]ame ([0-9]+)$/,
  parent: /^[Pp]arent+$/,
};

export const YEARLY = 'Yearly';
export const SEMESTERLY = 'Semesterly';

export const PAGING_PARAMS_SUBJECTS_TEACHERS = {
  pageIndex: 1,
  pageSize: 13,
};

export const STUDENT_ASSIGNMENTS_PAGE_SIZE = 9;

export const PREPARATION_MATERIAL_GRADE = 12;

export enum SitePalDimension {
  mobile = '100,120',
  web = '300,400',
}

export const GENDER_TYPE = {
  female: 'f',
  male: 'm',
};

export const OTP_LENGTH = 6;
export const ENGLISH_LANGUAGE_REGEX = /[a-zA-Z]/;
export const PHONE_REGEX = /^[+]?\d+$/;
export const INTRODUCTION_KEY = 'introductionStatus';

export const MIN_DURATION_1_TO_1 = 5;
export const MIN_DURATION_1_TO_MANY = 15;
export const MIN_DURATION_LIVE_CLASS = 30;
export const TOTAL_TEACHER_RATING = 5;

export const ONE_TO_THREE_GRADES = [1, 2, 3];

export const DEFAULT_PAGE_OPTIONS = { page: 1 };

export enum DashboardTypes {
  SmartView = 'Smart',
  NormalView = 'Dashboard',
}

export const SELECTED_ROLE = 'selectedRole';

export const SHOW_LESSONS_SECTION = 'showLessonsSection';
