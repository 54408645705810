import { createAsyncAction } from 'typesafe-actions';
import { AddNewStudentType } from './types';

export const addStudentAction = createAsyncAction(
  '@@student/CREATE_REQUEST',
  '@@student/CREATE_SUCCESS',
  '@@student/CREATE_FAILURE',
)<AddNewStudentType, undefined, any>();

export const loadStudentOptionsAction = createAsyncAction(
  '@@student/LOAD_REQUEST',
  '@@student/LOAD_SUCCESS',
  '@@student/LOAD_FAILURE',
)<any, any, any>();
