import camelcaseKeys from 'camelcase-keys';
import { getReinforcementEventsListAPI } from 'utils/apiEndpoints';
import API from 'utils/api';

import { LessonWeekType, ExercisesListType, ExamsListType } from './types';
import {
  dataAdditionalLessonsAdapter,
  dataExercisesListAdapter,
  dataExamsListAdapter,
} from './adapter';

export const getExercisesList = async (): Promise<ExercisesListType> => {
  const {
    data: { lessons_week_schedule },
  } = await API.get('/api/alw/exercises/exercises-schedule/');

  return dataExercisesListAdapter(camelcaseKeys(lessons_week_schedule));
};

export const getELearningAdditionalLessons = async (
  childUsername?: string,
): Promise<LessonWeekType[]> => {
  const {
    data: { additional_content_info, live_class_recordings_info, file_lessons_info },
  } = await API.get('/api/alw/content_library/assigned-content/', {
    params: {
      child_username: childUsername,
    },
  });
  return dataAdditionalLessonsAdapter(
    camelcaseKeys(additional_content_info, { deep: true }),
    camelcaseKeys(live_class_recordings_info, { deep: true }),
    camelcaseKeys(file_lessons_info, { deep: true }),
  );
};

export const getExamsList = async (): Promise<ExamsListType> => {
  const {
    data: { reinforcement_events_list },
  } = await API.get(getReinforcementEventsListAPI());

  return dataExamsListAdapter(camelcaseKeys(reinforcement_events_list, { deep: true }));
};
