import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import { getClassesAction, getTeachersScheduleAction, getWeeklyScheduleAction } from './actions';
import { FlexibleScheduleData } from './types';

export type FlexibleScheduleActionType = ActionType<typeof actions>;

const initialState = {
  fetching: {
    classes: false,
    teacherSchedules: false,
    weeklySchedule: false,
  },
  errors: {
    classes: '',
    teacherSchedules: '',
    weeklySchedule: '',
  },

  data: {} as FlexibleScheduleData,
};

const data = createReducer<FlexibleScheduleData, FlexibleScheduleActionType>(initialState.data)
  .handleAction(getClassesAction.success, (state, { payload }) => ({
    ...state,
    classes: payload.classroomWiseSubjects,
    semester: payload.semester,
  }))
  .handleAction(getTeachersScheduleAction.success, (state, { payload }) => ({
    ...state,
    teacherSchedules: payload,
  }))
  .handleAction([getWeeklyScheduleAction.success], (state, { payload }) => ({
    ...state,
    weeklySchedules: payload,
  }));

const fetching = combineReducers({
  fetchingClasses: createReducer<boolean, FlexibleScheduleActionType>(initialState.fetching.classes)
    .handleAction(getClassesAction.request, () => true)
    .handleAction([getClassesAction.success, getClassesAction.failure], () => false),

  fetchingTeacherSchedules: createReducer<boolean, FlexibleScheduleActionType>(
    initialState.fetching.teacherSchedules,
  )
    .handleAction(getTeachersScheduleAction.request, () => true)
    .handleAction([getTeachersScheduleAction.success, getClassesAction.failure], () => false),
  fetchingWeeklySchedule: createReducer<boolean, FlexibleScheduleActionType>(
    initialState.fetching.weeklySchedule,
  )
    .handleAction([getWeeklyScheduleAction.request], () => true)
    .handleAction([getWeeklyScheduleAction.success, getWeeklyScheduleAction.failure], () => false),
});

const errors = combineReducers({
  errorClasses: createReducer<string, FlexibleScheduleActionType>(initialState.errors.classes)
    .handleAction(getClassesAction.failure, (state, { payload }) => payload)
    .handleAction(getClassesAction.success, () => initialState.errors.classes),

  errorTeacherSchedules: createReducer<string, FlexibleScheduleActionType>(
    initialState.errors.teacherSchedules,
  )
    .handleAction(getTeachersScheduleAction.failure, (state, { payload }) => payload)
    .handleAction(getTeachersScheduleAction.success, () => initialState.errors.teacherSchedules),
  errorWeeklySchedule: createReducer<string, FlexibleScheduleActionType>(
    initialState.errors.weeklySchedule,
  )
    .handleAction([getWeeklyScheduleAction.failure], (state, { payload }) => payload)
    .handleAction([getWeeklyScheduleAction.success], () => initialState.errors.weeklySchedule),
});

const flexibleScheduleReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type FlexibleScheduleStateType = StateType<typeof flexibleScheduleReducer>;

export default flexibleScheduleReducer;
