import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { Icon, IconType, IconCommon } from 'lib';

import { isArabicMode } from 'utils/helpers';
import { isSummerSemester } from 'utils/summerSemesterUtils';
import { useScreenInclude } from 'hooks/useScreenInclude';
import { useInAppView } from 'hooks/useInAppView';

import { useHeaderContext } from 'contexts/HeaderContext';
import OrientationDialog from 'components/Orientation';
import { getOrientationEnabledRoles } from 'components/Orientation/utils';
import { ProfileDetailType } from 'components/LayoutBase/v2/types';

import { isAuthorOrAlwAdminSelector } from 'store/account/selectors';

import NotificationMenu from './NotificationMenu/NotificationMenu';
import styles from './Header.module.scss';
import { LogoutMenu } from './LogoutMenu/LogoutMenu';

type HeaderProps = {
  title: string;
  profileDetails: ProfileDetailType;
  userType: string;
  subtitle?: string;
  icon?: IconType;
  showHamburger?: boolean;
  isSummerProgram?: boolean;
  isChild?: boolean;
  handleOpenSidebar?: () => void;
};

const Header = ({
  title,
  subtitle,
  icon,
  profileDetails,
  showHamburger,
  isSummerProgram,
  isChild,
  handleOpenSidebar,
  userType,
}: HeaderProps) => {
  const { locale } = useIntl();
  const isMobile = useScreenInclude(['xs', 'sm']);
  const { isInAppView } = useInAppView();
  const history = useHistory();

  const isAuthorOrAlwAdmin = useSelector(isAuthorOrAlwAdminSelector);
  const { isHeaderDisabled } = useHeaderContext();

  const orientationEnabledRoles = getOrientationEnabledRoles();
  const shouldOrientationVisible = orientationEnabledRoles?.some((role) => role === userType);

  const isArabic = isArabicMode(locale);

  const PageTitle = () => {
    return (
      <>
        <h1 className={styles.topheader__title}>
          {icon ? (
            <Icon type={icon} size="s32" />
          ) : (
            isChild && (
              <span onClick={history.goBack} className={styles.headerBack}>
                <IconCommon className={isArabic ? 'arrow_forward' : 'arrow_back'} isMaterialIcon />
              </span>
            )
          )}
          {title}
        </h1>
        {subtitle && <div className={styles.breadcrumbs}>{subtitle}</div>}
      </>
    );
  };

  return (
    <div
      className={cn(styles.row, styles.headerRow, {
        [styles.summerProgramHeader]: isSummerSemester && isSummerProgram,
      })}
    >
      <div className={cn(styles['col-3'], styles['col-md-6'])}>
        <div
          className={cn(styles.hamburger, {
            [styles.hide]: isInAppView,
            [styles.showDesktop]: showHamburger,
          })}
          role="button"
          onClick={handleOpenSidebar}
        >
          <Icon type="menu-outline" size={isMobile ? 's24' : 's32'} />
        </div>
        {!isMobile && <PageTitle />}
      </div>
      <div
        className={cn(styles['col-9'], styles['col-md-6'], {
          [styles.hide]: isInAppView,
        })}
      >
        <div
          className={cn(styles.topheader, styles.navigation__item, {
            [styles.disabled]: isHeaderDisabled,
          })}
        >
          {shouldOrientationVisible && <OrientationDialog userType={userType} />}
          {!isAuthorOrAlwAdmin && <NotificationMenu />}
          <LogoutMenu {...profileDetails} />
        </div>
      </div>
      {isMobile && (
        <div className={cn('mt-20', styles['col-12'])}>
          <PageTitle />
        </div>
      )}
    </div>
  );
};

export default Header;
