import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  getWeeklyPlanAPI,
  getWeekLessonsToDoAPI,
  getWeekLiveSessionsAPI,
  getChildTeachersAPI,
  getParentQuestionsAPI,
  getLessonDetailsAPI,
} from 'utils/apiEndpoints';

import {
  WeeklyPlanItemType,
  WeekLessonsToDoResponse,
  WeekLiveSessionsResponse,
  WeekLessonsToDoPayloadType,
  TeachersListPayload,
  PaginationPayloadType,
  ParentQuestion,
  DashboardTeacher,
  LessonDetailResponseType,
  LessonDetailPayload,
} from './types';

export async function getWeeklyPlan(): Promise<WeeklyPlanItemType[]> {
  const response = await API.get(getWeeklyPlanAPI());
  return camelCaseKeys(response.data, { deep: true });
}

export async function getWeekLessonsToDo({
  userId,
  weekId,
  pageIndex,
  pageSize,
}: WeekLessonsToDoPayloadType): Promise<WeekLessonsToDoResponse> {
  const response = await API.get(getWeekLessonsToDoAPI(userId, weekId), {
    params: snakeCaseKeys({ page: pageIndex, pageSize }),
  });
  return camelCaseKeys(response.data, { deep: true });
}

export async function getWeekLiveSessions({
  userId,
  weekId,
  pageIndex,
  pageSize,
}: WeekLessonsToDoPayloadType): Promise<WeekLiveSessionsResponse> {
  const response = await API.get(getWeekLiveSessionsAPI(userId, weekId), {
    params: snakeCaseKeys({ page: pageIndex, pageSize }),
  });
  return camelCaseKeys(response.data, { deep: true });
}

export async function getChildTeachers({
  childId,
  pageIndex,
  pageSize,
}: TeachersListPayload): Promise<DashboardTeacher[]> {
  const response = await API.get(getChildTeachersAPI(childId), {
    params: snakeCaseKeys({ page: pageIndex, pageSize }),
  });
  return camelCaseKeys(response.data, { deep: true });
}

export async function getParentQuestions({
  pageIndex,
  pageSize,
}: PaginationPayloadType): Promise<ParentQuestion[]> {
  const response = await API.get(getParentQuestionsAPI(), {
    params: snakeCaseKeys({ page: pageIndex, pageSize }),
  });
  return camelCaseKeys(response.data, { deep: true });
}

export async function getLessonDetails({
  subjectId,
  childUsername,
}: LessonDetailPayload): Promise<LessonDetailResponseType> {
  const response = await API.post(
    getLessonDetailsAPI(),
    snakeCaseKeys({
      subjectId,
    }),
    { params: { username: childUsername } },
  );
  return camelCaseKeys(response.data, { deep: true });
}
