import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { SchoolRole } from 'store/account/types';
import { selectorAccountData } from 'store/account/selectors';
import { selectorChildrenSubscriptions } from 'store/childrenSubscriptions/selector';
import { selectCurrentBranch } from 'store/shoolBranches/selectors';

import LayoutContext from 'contexts/LayoutContext';
import { useInAppView } from 'hooks/useInAppView';
import { useTitle } from 'hooks/useTitle';
import { useTeacherTeachesGrade } from 'hooks/useTeacherTeachesGrade';
import { PREPARATION_MATERIAL_GRADE } from 'constants/entities';
import { getUserType, getGradeType } from 'utils/getUserType';
import { getUserTitle } from 'utils/getUserTitle';
import { isSummerSemester } from 'utils/summerSemesterUtils';
import Tour from 'components/TourManagement/TourManagement';

import { Sidebar } from './components/Sidebar/Sidebar';
import { ProfileDetailsType, SidebarTypes } from './components/Sidebar/types';
import Header from './components/Header';
import { BreadcrumbType } from './components/Header/Header';
import { getMenuItems } from './menus';
import { LayoutBaseTypes } from './type';
import styles from './LayoutBase.module.scss';

export type LayoutProps = {
  title: string;
  subtitle?: string;
  layoutType?: LayoutBaseTypes.ALW | LayoutBaseTypes.B2B;
  breadcrumbs?: BreadcrumbType[];
  children?: ReactNode;
  isLoading?: boolean;
  showHamburger?: boolean;
  hideSideNav?: boolean;
  isSummerProgram?: boolean;
  isChild?: boolean;
};

export const LayoutBase = ({ children }: { children?: ReactNode }) => {
  const isValidGradeForPreparation = useTeacherTeachesGrade(PREPARATION_MATERIAL_GRADE);

  const [isOpen, setOpen] = useState(false);
  const [layoutProps, setLayoutProps] = useState({} as LayoutProps);

  const {
    layoutType = LayoutBaseTypes.ALW,
    title,
    subtitle,
    breadcrumbs,
    showHamburger,
    isSummerProgram,
    isChild,
    hideSideNav,
  } = layoutProps;

  const {
    firstName,
    lastName,
    role,
    schoolRole,
    adminRole,
    grade: { gradeValue },
    gender,
    plainPassword = '',
    username,
    email,
    profileImage: { imageUrlLarge: imageUrl },
  } = useSelector(selectorAccountData);
  const parentDashboardData = useSelector(selectorChildrenSubscriptions);
  const { allowB2BGrading } = useSelector(selectCurrentBranch);

  const isALW = layoutType === LayoutBaseTypes.ALW;
  const userType = getUserType(role, schoolRole, adminRole) || '';
  const gradeType = getGradeType(gradeValue);

  const profileDetails: ProfileDetailsType = {
    srcLarge: imageUrl,
    src: imageUrl,
    name: `${firstName} ${lastName}`,
    role: getUserTitle(userType, gender),
    password: plainPassword,
    username,
    email,
  };

  const { isInAppView } = useInAppView();
  const menuItems = getMenuItems(
    userType,
    gender,
    gradeValue,
    parentDashboardData?.data,
    schoolRole === SchoolRole.schoolStudent,
    isValidGradeForPreparation,
    allowB2BGrading,
  );

  useTitle(title, isALW);

  const handleSetLayoutProps = useCallback((props: LayoutProps) => {
    setLayoutProps(props);
  }, []);

  useEffect(() => {
    document.body.classList[isOpen ? 'add' : 'remove']('sidebar-open');
  }, [isOpen]);

  return (
    <LayoutContext.Provider value={handleSetLayoutProps}>
      <Tour userType={userType} gradeType={gradeType}>
        {!isInAppView && !hideSideNav && (
          <Sidebar
            sidebarType={isALW ? SidebarTypes.ALW : SidebarTypes.B2B}
            menuItems={menuItems}
            profileDetails={profileDetails}
            isOpen={isOpen}
            setIsOpen={() => {
              setOpen(false);
            }}
            showHamburger={!!showHamburger}
            isSummerProgram={isSummerSemester && isSummerProgram}
          />
        )}
        <main>
          <div
            className={cn(styles.dashboard, {
              [styles.desktopHamburger]: showHamburger,
              [styles.summerProgram]: isSummerSemester && isSummerProgram,
            })}
          >
            <div className={styles.dashboard__container}>
              <Header
                title={title}
                subtitle={subtitle}
                breadcrumbs={breadcrumbs}
                profileDetails={profileDetails}
                handleOpenSidebar={() => {
                  setOpen(true);
                }}
                showHamburger={!!showHamburger}
                isSummerProgram={!!isSummerProgram}
                isChild={isChild}
                userType={userType}
              />

              <div className={cn(styles.row, styles['layout-body'], 'layout-body')}>{children}</div>
            </div>
          </div>
        </main>
      </Tour>
    </LayoutContext.Provider>
  );
};

export default LayoutBase;
