import camelcaseKeys from 'camelcase-keys';
import { BulkImportType } from './types';
import API from '../../utils/api';
import { b2bTeachersBulkCreate, b2bStudentsBulkCreate } from '../../utils/apiEndpoints';

export async function bulkImportTeachersAPI(payload: any): Promise<BulkImportType> {
  const data = new FormData();
  data.append('file', payload.file);
  const response = await API.post(b2bTeachersBulkCreate(payload.branchID), data);

  return camelcaseKeys(response.data, { deep: true });
}

export async function bulkImportStudentsAPI(payload: any): Promise<BulkImportType> {
  const data = new FormData();
  data.append('file', payload.file);
  const response = await API.post(b2bStudentsBulkCreate(payload.branchID), data);

  return camelcaseKeys(response.data, { deep: true });
}
