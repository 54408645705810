import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  loadSubjectsAction,
  fetchLearningObjectivesAction,
  fetchUnitsActions,
  fetchLessonResultsActions,
  fetchSampleQuestionsActions,
  subjectSearchAction,
  unitsSearchActions,
  learningObjectiveQuizAction,
  fetchTeacherClassesAction,
  fetchSubjectsAction,
  fetchUnitRelatedLessonsAction,
  fetchLessonRelatedLearningObjectivesAction,
  fetchSubjectRelatedUnitsAction,
  fetchAnswerDetailOptionsAction,
  createNewQuestionAction,
  loadQuestionList,
  archiveQuestionAction,
  uploadQuestionImageAction,
  fetchSubjectsGradesAction,
  updateLearningObjectiveQuizAction,
  fetchLearningObjectiveSmartScoreActions,
  updateExpectedAnswersAction,
  createUpdateLearningObjectiveAction,
} from './actions';
import {
  loadSubjects,
  getLessonLearningObjects,
  getLessonUnits,
  getLessonResults,
  getSampleQuestion,
  getLearningObjectiveProblems,
  getTeacherClassesData,
  getTeacherSubjects,
  getTeacherUnits,
  getTeacherLessons,
  getAnswerDetailOptions,
  createEditQuestion,
  getQuestionList,
  archiveQuestion,
  uploadQuestionImage,
  getSubjectGrades,
  getLeaningObjectiveSmartScore,
  updateExpectedAnswers,
  createUpdateLearningObjective,
} from './api';
import { questionListResponse, ActionTypes, learningObjectPayload } from './types';

function* loadQuestionBankSubjects(): SagaIterator<void> {
  try {
    const subjects = yield call(loadSubjects);
    yield put(loadSubjectsAction.success(subjects));
  } catch (error) {
    yield put(loadSubjectsAction.failure(error || error.detail));
  }
}

function* loadLearningObjectives({
  payload,
}: {
  payload: learningObjectPayload;
}): SagaIterator<void> {
  try {
    const learningObjects = yield call(getLessonLearningObjects, payload);
    yield put(fetchLearningObjectivesAction.success(learningObjects));
  } catch (error) {
    yield put(fetchLearningObjectivesAction.failure(error || error.detail));
  }
}

function* loadLessonUnits({ payload }: ReturnType<any>): SagaIterator<void> {
  try {
    const lessonUnits = yield call(getLessonUnits, payload);
    yield put(fetchUnitsActions.success(lessonUnits));
  } catch (error) {
    yield put(fetchUnitsActions.failure(error || error.detail));
  }
}

function* questionListRequest({
  payload,
}: ReturnType<typeof loadQuestionList.request>): SagaIterator<void> {
  try {
    const questionsResponse: questionListResponse = yield call(getQuestionList, payload);
    yield put(loadQuestionList.success(questionsResponse));
  } catch (error) {
    yield put(loadQuestionList.failure(error));
  }
}

function* loadLessonResults({ payload }: ReturnType<any>): SagaIterator<void> {
  try {
    const lessonUnits = yield call(getLessonResults, payload);
    yield put(fetchLessonResultsActions.success(lessonUnits));
  } catch (error) {
    yield put(fetchLessonResultsActions.failure(error || error.detail));
  }
}

function* fetchSampleQuestions({ payload }: ReturnType<any>): SagaIterator<void> {
  try {
    const sampleQuestions = yield call(getSampleQuestion, payload);
    yield put(fetchSampleQuestionsActions.success(sampleQuestions));
  } catch (error) {
    yield put(fetchSampleQuestionsActions.failure(error || error.detail));
  }
}

function* searchLessonUnits({ payload }: ReturnType<any>): SagaIterator<void> {
  try {
    const lessonUnits = yield call(getLessonUnits, payload);
    yield put(unitsSearchActions.success(lessonUnits));
  } catch (error) {
    yield put(unitsSearchActions.failure(error || error.detail));
  }
}

function* searchQuestionBankSubject({ payload }: ReturnType<any>): SagaIterator<void> {
  try {
    const subjects = yield call(loadSubjects, payload.searchQuery);
    yield put(subjectSearchAction.success(subjects));
  } catch (error) {
    yield put(subjectSearchAction.failure(error || error.detail));
  }
}

function* fetchLearningObjectiveProblems({ payload }: ReturnType<any>): SagaIterator<void> {
  try {
    const subjects = yield call(getLearningObjectiveProblems, payload);
    yield put(learningObjectiveQuizAction.success(subjects));
  } catch (error) {
    yield put(learningObjectiveQuizAction.failure(error || error.detail));
  }
}

function* updateLearningObjectiveProblems({ payload }: ReturnType<any>): SagaIterator<void> {
  try {
    yield put(updateLearningObjectiveQuizAction.success(payload));
  } catch (error) {
    yield put(updateLearningObjectiveQuizAction.failure(error || error.detail));
  }
}

function* archiveQuestionRequest({
  payload,
}: ReturnType<typeof archiveQuestionAction.request>): SagaIterator<void> {
  try {
    const subjects = yield call(archiveQuestion, payload.id);
    yield put(learningObjectiveQuizAction.success(subjects));
    payload.callback(true);
  } catch (error) {
    payload.callback(false);
    yield put(learningObjectiveQuizAction.failure(error || error.detail));
  }
}

function* fetchTeacherClassesData({
  payload,
}: ReturnType<typeof fetchTeacherClassesAction.request>): SagaIterator<void> {
  try {
    const classesData = yield call(getTeacherClassesData, payload);
    yield put(fetchTeacherClassesAction.success(classesData));
  } catch (error) {
    yield put(fetchTeacherClassesAction.failure(error || error.detail));
  }
}

function* fetchTeacherSubjects({
  payload,
}: ReturnType<typeof fetchSubjectsAction.request>): SagaIterator<void> {
  try {
    const classesData = yield call(getTeacherSubjects, payload);
    yield put(fetchSubjectsAction.success(classesData));
  } catch (error) {
    yield put(fetchSubjectsAction.failure(error || error.detail));
  }
}

function* fetchTeacherUnits({ payload }: any): SagaIterator<void> {
  try {
    const classesData = yield call(getTeacherUnits, payload);
    yield put(fetchSubjectRelatedUnitsAction.success(classesData));
  } catch (error) {
    yield put(fetchSubjectRelatedUnitsAction.failure(error || error.detail));
  }
}

function* fetchTeacherLessons({ payload }: any): SagaIterator<void> {
  try {
    const classesData = yield call(getTeacherLessons, payload);
    yield put(fetchUnitRelatedLessonsAction.success(classesData));
  } catch (error) {
    yield put(fetchUnitRelatedLessonsAction.failure(error || error.detail));
  }
}

function* fetchTeacherLearningObjective({ payload }: ReturnType<any>): SagaIterator<void> {
  try {
    const learningObjects = yield call(getLessonLearningObjects, payload);
    yield put(fetchLessonRelatedLearningObjectivesAction.success(learningObjects));
  } catch (error) {
    yield put(fetchLessonRelatedLearningObjectivesAction.failure(error || error.detail));
  }
}

function* fetchAnswerDetailOptions(): SagaIterator<void> {
  try {
    const AnswerDetailOptions = yield call(getAnswerDetailOptions);
    yield put(fetchAnswerDetailOptionsAction.success(AnswerDetailOptions));
  } catch (error) {
    yield put(fetchAnswerDetailOptionsAction.failure(error || error.detail));
  }
}

function* uploadQuestionImageSaga({
  payload: { callback, ...payload },
}: ReturnType<typeof uploadQuestionImageAction.request>): SagaIterator<void> {
  try {
    const uploadQuestionImageResponse = yield call(uploadQuestionImage, payload);
    callback && callback('success', uploadQuestionImageResponse);
    yield put(uploadQuestionImageAction.success(uploadQuestionImageResponse));
  } catch (error) {
    callback && callback('failure', error || error.detail);
    yield put(uploadQuestionImageAction.failure(error || error.detail));
  }
}

function* createNewQuestionSaga({
  payload: { callback, ...restPayload },
}: ReturnType<typeof createNewQuestionAction.request>): SagaIterator<void> {
  try {
    const questionCreatedResponse = yield call(createEditQuestion, restPayload);
    callback && callback('success', ActionTypes.Publish);
    yield put(createNewQuestionAction.success(questionCreatedResponse));
  } catch (error) {
    callback && callback('failure', undefined, error);
    yield put(createNewQuestionAction.failure(error || error.detail));
  }
}

function* fetchSubjectsGrades(): SagaIterator<void> {
  try {
    const grades = yield call(getSubjectGrades);
    yield put(fetchSubjectsGradesAction.success(grades));
  } catch (error) {
    yield put(fetchSubjectsGradesAction.failure(error || error.detail));
  }
}

function* fetchLearningObjectiveSmartScore({ payload }: ReturnType<any>): SagaIterator<void> {
  try {
    const learningObjectiveSmartScore = yield call(getLeaningObjectiveSmartScore, payload);
    yield put(fetchLearningObjectiveSmartScoreActions.success(learningObjectiveSmartScore));
  } catch (error) {
    yield put(fetchLearningObjectiveSmartScoreActions.failure(error || error.detail));
  }
}

function* updateExpectedAnswersUpdate({
  payload: { callback, ...payload },
}: ReturnType<any>): SagaIterator<void> {
  try {
    yield call(updateExpectedAnswers, payload);
    callback(true);
  } catch (error) {
    callback(false);
    yield put(updateExpectedAnswersAction.failure(error || error.detail));
  }
}

function* createUpdateLearningObjectiveSaga({
  payload,
}: ReturnType<typeof createUpdateLearningObjectiveAction.request>): SagaIterator<void> {
  try {
    const learningObjective = yield call(createUpdateLearningObjective, payload);
    payload?.callback?.('success', learningObjective);
    yield put(createUpdateLearningObjectiveAction.success(learningObjective));
  } catch (err) {
    const error = err.error || err;
    payload?.callback?.('error', undefined, error);
    yield put(createUpdateLearningObjectiveAction.failure(error));
  }
}

function* questionBankSaga(): SagaIterator {
  yield all([
    takeEvery(loadSubjectsAction.request, loadQuestionBankSubjects),
    takeEvery(fetchLearningObjectivesAction.request, loadLearningObjectives),
    takeEvery(loadQuestionList.request, questionListRequest),
    takeEvery(archiveQuestionAction.request, archiveQuestionRequest),
    takeEvery(fetchUnitsActions.request, loadLessonUnits),
    takeEvery(fetchLessonResultsActions.request, loadLessonResults),
    takeEvery(fetchSampleQuestionsActions.request, fetchSampleQuestions),
    takeEvery(subjectSearchAction.request, searchQuestionBankSubject),
    takeEvery(unitsSearchActions.request, searchLessonUnits),
    takeEvery(learningObjectiveQuizAction.request, fetchLearningObjectiveProblems),
    takeEvery(fetchTeacherClassesAction.request, fetchTeacherClassesData),
    takeEvery(fetchSubjectsAction.request, fetchTeacherSubjects),
    takeEvery(fetchSubjectRelatedUnitsAction.request, fetchTeacherUnits),
    takeEvery(fetchUnitRelatedLessonsAction.request, fetchTeacherLessons),
    takeEvery(fetchLessonRelatedLearningObjectivesAction.request, fetchTeacherLearningObjective),
    takeEvery(fetchAnswerDetailOptionsAction.request, fetchAnswerDetailOptions),
    takeEvery(uploadQuestionImageAction.request, uploadQuestionImageSaga),
    takeEvery(createNewQuestionAction.request, createNewQuestionSaga),
    takeEvery(fetchSubjectsGradesAction.request, fetchSubjectsGrades),
    takeEvery(updateLearningObjectiveQuizAction.request, updateLearningObjectiveProblems),
    takeEvery(fetchLearningObjectiveSmartScoreActions.request, fetchLearningObjectiveSmartScore),
    takeEvery(updateExpectedAnswersAction.request, updateExpectedAnswersUpdate),
    takeEvery(createUpdateLearningObjectiveAction.request, createUpdateLearningObjectiveSaga),
  ]);
}

export default questionBankSaga;
