import { createAction, createAsyncAction } from 'typesafe-actions';

import { AssignedTeacherPayload, AssignedTeacherResponse } from './types';

export const loadGradesSubjectsAction = createAsyncAction(
  '@@assignTeacherGrades/LOAD_GRADES_REQUEST',
  '@@assignTeacherGrades/LOAD_GRADES_SUCCESS',
  '@@assignTeacherGrades/LOAD_GRADES_FAILURE',
)<any, any, string>();

export const loadSubjectsTeachersAction = createAsyncAction(
  '@@assignTeacherGrades/LOAD_SUBJECTS_REQUEST',
  '@@assignTeacherGrades/LOAD_SUBJECTS_SUCCESS',
  '@@assignTeacherGrades/LOAD_SUBJECTS_FAILURE',
)<AssignedTeacherPayload, AssignedTeacherResponse, string>();

export const loadTeachersDetailsAction = createAsyncAction(
  '@@assignTeacherGrades/LOAD_SPECIFIC_REQUEST',
  '@@assignTeacherGrades/LOAD_SPECIFIC_SUCCESS',
  '@@assignTeacherGrades/LOAD_SPECIFIC_FAILURE',
)<any, any, string>();

export const assignGradesToTeacherAction = createAsyncAction(
  '@@assignTeacherGrades/ASSIGN_SUBJECT_REQUEST',
  '@@assignTeacherGrades/ASSIGN_SUBJECT_SUCCESS',
  '@@assignTeacherGrades/ASSIGN_SUBJECT_FAILURE',
)<any, any, string>();

export const resetSubjectTeacherAction = createAction('@@resetSubjectTeacherAction/RESET')();
