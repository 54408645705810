import React, { ReactElement, FunctionComponent, ReactChild, useState, useCallback } from 'react';
import cn from 'classnames';

import { Popover, Icon } from 'lib';
import { useScreenInclude } from 'hooks/useScreenInclude';

import FeedbackForm from './FeedbackForm';
import style from './Feedback.module.scss';

type FeedbackProps = {
  isGradeOneToThree?: boolean;
};

const Feedback: FunctionComponent<FeedbackProps> = ({ isGradeOneToThree }): ReactElement => {
  const isMobile = useScreenInclude(['xs', 'sm']);
  const [visiblePopover, setVisibilityPopover] = useState(false);

  const handleCloseModal = useCallback(() => setVisibilityPopover(false), [setVisibilityPopover]);

  return (
    <Popover
      placement="bottom-end"
      type="modal"
      isUnwrapped={isMobile}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'document',
          },
        },
        {
          name: 'offset',
          options: {
            offset: [10, 10],
          },
        },
      ]}
      isVisible={visiblePopover}
      setVisibility={setVisibilityPopover}
      popover={(): ReactChild => (
        <FeedbackForm onClose={handleCloseModal} isGradeOneToThree={isGradeOneToThree} />
      )}
    >
      <div
        id="topbar__review"
        className={cn(style.topheader__operation, { [style.grade]: isGradeOneToThree })}
      >
        <div role="button" className={style.button} id="header.feedback">
          <Icon type="feedback" size={isGradeOneToThree ? 's32' : 's24'} />
        </div>
      </div>
    </Popover>
  );
};

export default Feedback;
