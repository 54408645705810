import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { REDIRECT_LOGIN_URL, PersistedStateKeys } from 'constants/entities';
import { removePresistState } from 'utils/helpers';

import {
  registrationAction,
  registrationChildrenAction,
  registrationParentAction,
  invitationAccountAction,
  otpVerificationAction,
  otpSendAction,
  otpClearAction,
  countryListAction,
  trackSignupAction,
} from './actions';
import {
  sendRegistration,
  sendRegistrationChildren,
  registrationParent,
  invitationAccount,
  otpVerificationRequest,
  otpSendingRequest,
  getCountriesListRequest,
  trackSignup,
} from './api';
import { ChildrenType } from './types';

function* sendRegistrationRequest({
  payload: { values, callback },
}: ReturnType<typeof registrationAction.request>): SagaIterator<void> {
  try {
    const isSuccess = yield call(sendRegistration, values);
    yield put(registrationAction.success(isSuccess));
    yield call(callback);
    removePresistState(PersistedStateKeys.registrationFields);
  } catch (error) {
    yield call(callback, error);
    yield put(registrationAction.failure(error));
  }
}

function* sendRegistrationParentRequest({
  payload: { callback, ...payload },
}: ReturnType<typeof registrationParentAction.request>): SagaIterator<void> {
  try {
    yield call(registrationParent, payload);
    localStorage.setItem(REDIRECT_LOGIN_URL, 'main');
    callback?.({ type: 'success' });
    yield put(registrationParentAction.success());
  } catch (error) {
    yield put(registrationParentAction.failure(error));
  }
}

function* sendRegistrationChildrenRequest({
  payload: { children, callback },
}: ReturnType<typeof registrationChildrenAction.request>): SagaIterator<void> {
  try {
    const data: Array<ChildrenType> = yield call(sendRegistrationChildren, children);

    yield put(registrationChildrenAction.success());
    yield call(callback, data?.[0], true, '');
  } catch (error) {
    yield call(callback, undefined, false, error);
    yield put(registrationChildrenAction.failure(error));
  }
}

function* invitationAccountRequest({
  payload: { token, callback },
}: ReturnType<typeof invitationAccountAction.request>): SagaIterator<void> {
  try {
    yield call(invitationAccount, token);
    callback?.({ type: 'success' });
    yield put(invitationAccountAction.success());
  } catch (error) {
    yield put(invitationAccountAction.failure(error));
  }
}

function* otpVerification({
  payload: { source, role, ...payload },
}: ReturnType<typeof otpVerificationAction.request>): SagaIterator<void> {
  try {
    const isSuccess = yield call(otpVerificationRequest, payload);
    yield put(otpVerificationAction.success(isSuccess));
    yield put(trackSignupAction.request({ phone_number: payload.phone, source, role }));
  } catch (error) {
    yield put(otpVerificationAction.failure(error));
  }
}

function* otpSending({ payload }: ReturnType<typeof otpSendAction.request>): SagaIterator<void> {
  try {
    const isSuccess = yield call(otpSendingRequest, payload);
    yield put(otpSendAction.success(isSuccess));
  } catch (error) {
    yield put(otpSendAction.failure(error));
  }
}

function* otpClear({ payload }: ReturnType<typeof otpClearAction.request>): SagaIterator<void> {
  try {
    yield put(otpClearAction.success(payload));
  } catch (error) {
    yield put(otpClearAction.failure(error));
  }
}

function* countriesList(): SagaIterator<void> {
  const response = yield call(getCountriesListRequest);

  try {
    yield put(countryListAction.success(response));
  } catch (error) {
    yield put(countryListAction.failure(error));
  }
}

function* trackSignupSaga({
  payload,
}: ReturnType<typeof trackSignupAction.request>): SagaIterator<void> {
  try {
    yield call(trackSignup, payload);
    yield put(trackSignupAction.success());
  } catch (error) {
    yield put(trackSignupAction.failure(error));
  }
}

function* registrationSaga(): SagaIterator {
  yield all([
    takeEvery(registrationAction.request, sendRegistrationRequest),
    takeEvery(registrationChildrenAction.request, sendRegistrationChildrenRequest),
    takeEvery(registrationParentAction.request, sendRegistrationParentRequest),
    takeEvery(invitationAccountAction.request, invitationAccountRequest),
    takeEvery(otpVerificationAction.request, otpVerification),
    takeEvery(otpSendAction.request, otpSending),
    takeEvery(otpClearAction.request, otpClear),
    takeEvery(countryListAction.request, countriesList),
    takeEvery(trackSignupAction.request, trackSignupSaga),
  ]);
}

export default registrationSaga;
