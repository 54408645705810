import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  NotificationsReturnType,
  UnreadCountType,
  LoadNotificationRequestType,
  MarkNotificationsRequestType,
} from './types';

export const loadNotificationsAction = createAsyncAction(
  '@@notifications/LOAD_REQUEST',
  '@@notifications/LOAD_SUCCESS',
  '@@notifications/LOAD_FAILURE',
)<LoadNotificationRequestType, NotificationsReturnType, string>();

export const markNotificationsAction = createAsyncAction(
  '@@notifications/UPDATE_REQUEST',
  '@@notifications/UPDATE_SUCCESS',
  '@@notifications/UPDATE_FAILURE',
)<MarkNotificationsRequestType, undefined, string>();

export const getNotificationsUnreadCountAction = createAsyncAction(
  '@@notifications/GET_NOTIFICATIONS_UNREAD_COUNT_REQUEST',
  '@@notifications/GET_NOTIFICATIONS_UNREAD_COUNT_SUCCESS',
  '@@notifications/GET_NOTIFICATIONS_UNREAD_COUNT_FAILURE',
)<undefined, UnreadCountType, string>();

export const resetNotificationsAction = createAction('@@notifications/RESET__NOTIFICATIONS_DATA')();

export const bulkImportDataAction = createAction('@@bulkImportData/STATUS')<boolean>();
