import { getPngURL, getSvgURL } from 'lib';

import { ROUTES } from 'constants/routes';
import { getIntl } from 'utils/intl';

import { DISABLE_B2B_GRADING } from 'constants/settings';

import { MenuItemTypes } from '../components/Sidebar/types';

export const b2bTeacherMenu = (isValidPreparationGrade?: Boolean): MenuItemTypes[] => {
  const { formatMessage } = getIntl();
  const menu: MenuItemTypes[] = [
    {
      id: 'sidebar__dashboard',
      path: ROUTES.root,
      label: formatMessage({ id: 'dashboard.title', defaultMessage: 'Dashboard' }),
      icon: 'dashboard',
      orderIndex: 1,
    },
    ...(!DISABLE_B2B_GRADING
      ? [
          {
            path: '',
            label: formatMessage({ id: 'grade-system', defaultMessage: 'Grading System' }),
            image: getSvgURL('grade-system'),
            orderIndex: 13,

            subMenus: [
              {
                path: ROUTES.gradeMapping,
                label: formatMessage({
                  id: 'grade-system.title',
                  defaultMessage: 'Grade Mapping',
                }),
                image: getSvgURL('grade-mapping'),
              },
              {
                path: ROUTES.gradeReport,
                label: formatMessage({
                  id: 'grade-reports.title',
                  defaultMessage: 'Grade Report',
                }),
                image: getSvgURL('grade-reports'),
              },
            ],
          },
        ]
      : []),
    {
      id: 'sidebar__schedule',
      path: ROUTES.schedule,
      label: formatMessage({ id: 'schedule.title', defaultMessage: 'Schedule' }),
      icon: 'schedule',
      orderIndex: 2,
    },
    {
      id: 'sidebar__exams',
      path: ROUTES.teacherExams,
      label: formatMessage({ id: 'exams.examinations.title', defaultMessage: 'Examinations' }),
      icon: 'exam',
      orderIndex: 5,
    },
    {
      id: 'sidebar__flexible__schedule',
      path: ROUTES.flexibleSchedule,
      label: formatMessage({ id: 'flexibleSchedule.title', defaultMessage: 'Lesson’s Schedule' }),
      icon: 'schedule',
      orderIndex: 4,
    },
    {
      id: 'sidebar__question_bank',
      path: ROUTES.questionBankSubjects,
      label: formatMessage({ id: 'question-bank.title', defaultMessage: 'Question Bank' }),
      image: getPngURL('question-bank'),
      orderIndex: 7,
    },
    {
      id: 'sidebar__content_library',
      path: ROUTES.contentLibrary,
      label: formatMessage({ id: 'content-library.title', defaultMessage: 'Content Library' }),
      icon: 'question-bank',
      orderIndex: 8,
    },
    {
      id: 'sidebar__assignments',
      path: ROUTES.assignments,
      label: formatMessage({ id: 'assignments.title', defaultMessage: 'Assignments' }),
      image: getSvgURL('assignment'),
      orderIndex: 6,
    },
    {
      id: 'sidebar__chat',
      path: ROUTES.Chat,
      label: formatMessage({
        id: 'chat.student.title',
        defaultMessage: 'Student Communication',
      }),
      icon: 'group-chat',
      orderIndex: 9,
    },
    {
      id: 'sidebar__reports',
      path: ROUTES.reports,
      label: formatMessage({ id: 'b2bTeacher.reports.title', defaultMessage: 'Reports' }),
      image: getSvgURL('report'),
      orderIndex: 12,
    },
    {
      id: 'sidebar__sessionQA',
      path: ROUTES.teacherLiveQuestions,
      label: formatMessage({
        id: 'b2bTeacher.sessions.title',
        defaultMessage: 'Sessions Q/A',
      }),
      icon: 'students',
      orderIndex: 11,
    },
    {
      id: 'sidebar__semester_schedule',
      path: ROUTES.semesterSchedule,
      label: formatMessage({
        id: 'semester.schedule.title',
        defaultMessage: 'Semester Schedule',
      }),
      icon: 'schedule',
      orderIndex: 3,
    },
    {
      id: 'sidebar__parents_questions',
      path: ROUTES.parentQuestions,
      label: formatMessage({ id: 'parentQuestions.title', defaultMessage: 'Parents Question' }),
      icon: 'question-icon',
      orderIndex: 10,
    },
  ];

  if (isValidPreparationGrade)
    menu.push({
      id: 'sidebar__preparation',
      path: ROUTES.preparation,
      label: formatMessage({
        id: 'preparation.material.nav.university.admission.test',
        defaultMessage: 'University Admission Tests',
      }),
      icon: 'admission-test',
      orderIndex: 14,
    });
  return menu.sort((a: MenuItemTypes, b: MenuItemTypes) => {
    const order1 = a.orderIndex || 0;
    const order2 = b.orderIndex || 0;

    return order1 - order2;
  });
};
