import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { getSignupVersion } from './api';
import { loadSignupVersionAction } from './actions';

function* loadSignupVersion(): SagaIterator<void> {
  try {
    const response = yield call(getSignupVersion);

    yield put(loadSignupVersionAction.success(response.value));
  } catch (error) {
    yield put(loadSignupVersionAction.failure(error));
  }
}

function* systemConfigSaga(): SagaIterator {
  yield all([takeEvery(loadSignupVersionAction.request, loadSignupVersion)]);
}

export default systemConfigSaga;
