import { createSelector } from 'reselect';

import { RootStateType } from '../types';
import { BreadcrumbsStateType } from './reducer';

export const selectBreadcrumbs = (state: RootStateType): BreadcrumbsStateType => state.breadcrumbs;

export const selectorBreadcrumbs = createSelector(
  selectBreadcrumbs,
  ({ data }: BreadcrumbsStateType) => data,
);

export const selectorBreadcrumbsIsLoading = createSelector(
  selectBreadcrumbs,
  ({ fetching }: BreadcrumbsStateType) => fetching?.load,
);
