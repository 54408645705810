import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import API from 'utils/api';
import {
  checkConflictsAPI,
  createSlotAPI,
  updateSlotAPI,
  currentSemesterWeekAPI,
  getSlotsAPI,
  publishEventsAPI,
  getPublishStatusAPI,
} from 'utils/apiEndpoints';
import {
  GetSlotDataRequestPayload,
  SlotCreationPayloadType,
  SlotUpdationPayloadType,
  SlotUpdationSuccessType,
  TeacherAvailabilityPayloadType,
  TeacherAvailabilityRequestBodyType,
  TimeTableDataType,
  WeeksList,
} from './types';

export async function getTeacherAvailability(
  payload: TeacherAvailabilityPayloadType,
): Promise<WeeksList> {
  const { classroom, startTime, endTime, branchId, subjectId, weeks, id } = payload;

  const requestBody: TeacherAvailabilityRequestBodyType = {
    weeks,
    id: id || undefined,
    start_date: startTime,
    end_date: endTime,
    subject: subjectId,
  };

  const response = await API.post(checkConflictsAPI(classroom, branchId), requestBody);
  return camelCaseKeys(response.data, { deep: true });
}

export async function getWeeksData(): Promise<WeeksList> {
  const response = await API.get(currentSemesterWeekAPI());
  return camelCaseKeys(response.data, { deep: true });
}

export async function createSlot(payload: SlotCreationPayloadType): Promise<void> {
  const { branchId, classroomId } = payload;
  await API.post(createSlotAPI(classroomId, branchId), {
    ...snakeCaseKeys(payload.slot, { deep: true }),
  });
}

export async function updateSlot(
  payload: SlotUpdationPayloadType,
): Promise<SlotUpdationSuccessType> {
  const { branchId, classroomId, slotId } = payload;
  const response = await API.put(updateSlotAPI(classroomId, branchId, slotId), {
    ...snakeCaseKeys(payload.slot, { deep: true }),
  });

  return camelCaseKeys(response.data, { deep: true });
}

export async function getTimeTableData(
  payload: GetSlotDataRequestPayload,
): Promise<TimeTableDataType> {
  const { branchId, classroomId } = payload;
  const response = await API.get(getSlotsAPI(classroomId, branchId));
  return camelCaseKeys({ ...response.data, classroomId }, { deep: true });
}
export async function getPublishStatus(
  payload: GetSlotDataRequestPayload,
): Promise<{ publishStatus: boolean }> {
  const { branchId, classroomId } = payload;
  const response = await API.get(getPublishStatusAPI(classroomId, branchId));
  return camelCaseKeys({ ...response.data }, { deep: true });
}

export async function publishEvents(branchId: number, classroomId: number) {
  return API.post(publishEventsAPI(branchId, classroomId));
}
