import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { LiveClassRecommendationItem } from 'store/reports/types';
import {
  createScheduleAction,
  loadScheduleAction,
  deleteScheduleAction,
  createRecordingLinkAction,
  deleteRecordingLinkAction,
  loadTableListScheduleAction,
  loadJoinMeetingUrl,
  loadScheduleDetailAction,
  addEventAttachmentsAction,
  loadTeacherEventAttachmentsAction,
  declineEventRequestAction,
  refreshJoinMeetingUrl,
  updateLiveEventRatingAction,
  liveEventRatingAction,
  getStudentLiveQuestions,
  postStudentLiveQuestions,
  getTeacherLiveQuestions,
  deleteLiveEventQuestion,
  postLiveEventAnswer,
  updateLiveEventAnswer,
  loadScheduleFormOptions,
  loadBranchCalenderScheduleOptions,
  loadBranchTableSchedules,
  addCommentAction,
  loadCommentAction,
  getLiveScheduleData,
  deleteAttachmentAction,
  getStudentGeneralQuestions,
  postGeneralAnswer,
  updateGeneralAnswer,
} from './actions';
import {
  getSchedule,
  setSchedule,
  deleteScheduleEvent,
  setRecordingLink,
  getMeetingLink,
  getEventDetail,
  addEventAttachments,
  getTeacherEventAttachments,
  sendDeclineEventRequest,
  refreshMeetingLinkAPI,
  sendRateLiveEventAPI,
  updateRateLiveEventAPI,
  getStudentQuestionsAPI,
  postStudentQuestionsAPI,
  getTeacherQuestionsAPI,
  postEventAnswerAPI,
  updateEventAnswerAPI,
  deleteEventQuestionAPI,
  getScheduleFilters,
  getScheduleCardViewData,
  getScheduleTableViewData,
  addCommentAPIRequest,
  loadCommentAPIRequest,
  getLiveScheduleDataAPI,
  deleteAttachmentAPI,
  getStudentGeneralQA,
  postGeneralAnswerAPI,
  updateGeneralAnswerAPI,
} from './api';

import {
  BranchScheduleTableViewResponseType,
  Comment,
  EventDetailType,
  ScheduleCalendarType,
  ScheduleEventType,
  ScheduleFormOptionsType,
  TableViewResponseType,
  StudentLiveQuestionsType,
  TeacherSessionQAEventType,
  GeneralQuestionAnswerType,
} from './types';

function* loadScheduleRequest(
  action: ReturnType<typeof loadScheduleAction.request>,
): SagaIterator<void> {
  try {
    const scheduleEvents: ScheduleEventType[] | TableViewResponseType = yield call(
      getSchedule,
      action.payload.startDate,
      action.payload.endDate,
      action.payload.listView,
      action.payload.pageIndex,
      action.payload.pageSize,
    );

    if ('results' in scheduleEvents) {
      yield put(loadTableListScheduleAction.success(scheduleEvents));
      yield put(loadScheduleAction.success([]));
    } else yield put(loadScheduleAction.success(scheduleEvents));
  } catch (error) {
    yield put(loadScheduleAction.failure(error));
  }
}

function* loadJoinMeetingRequest({
  payload: { id, callback },
}: ReturnType<typeof loadJoinMeetingUrl.request>): SagaIterator<void> {
  try {
    const response: any = yield call(getMeetingLink, id);
    yield put(loadJoinMeetingUrl.success(response));
    callback && callback(response);
  } catch (error) {
    yield put(loadJoinMeetingUrl.failure(error));
  }
}
function* refreshJoinMeetingRequest({
  payload: { id, callback },
}: ReturnType<typeof refreshJoinMeetingUrl.request>): SagaIterator<void> {
  try {
    const response: any = yield call(refreshMeetingLinkAPI, id);
    yield put(refreshJoinMeetingUrl.success(response));
    callback && callback(response);
  } catch (error) {
    yield put(refreshJoinMeetingUrl.failure(error));
  }
}

function* loadEventDetailRequest({
  payload,
}: ReturnType<typeof loadScheduleDetailAction.request>): SagaIterator<void> {
  try {
    const response: EventDetailType = yield call(getEventDetail, payload);
    yield put(loadScheduleDetailAction.success(response));
  } catch (error) {
    yield put(loadScheduleDetailAction.failure(error));
  }
}

function* loadScheduleFiltersRequest({
  payload,
}: ReturnType<typeof loadScheduleFormOptions.request>): SagaIterator<void> {
  try {
    const response: ScheduleFormOptionsType = yield call(getScheduleFilters, payload);
    yield put(loadScheduleFormOptions.success(response));
  } catch (error) {
    yield put(loadScheduleFormOptions.failure(error));
  }
}

function* loadScheduleCardViewDataRequest({
  payload,
}: ReturnType<typeof loadBranchCalenderScheduleOptions.request>): SagaIterator<void> {
  try {
    const response: ScheduleCalendarType[] = yield call(getScheduleCardViewData, payload);
    yield put(loadBranchCalenderScheduleOptions.success(response));
  } catch (error) {
    yield put(loadBranchCalenderScheduleOptions.failure(error));
  }
}

function* addCommentRequest({
  payload,
}: ReturnType<typeof addCommentAction.request>): SagaIterator<void> {
  const { callback } = payload;
  try {
    yield call(addCommentAPIRequest, payload);
    callback && callback();
    yield put(addCommentAction.success());
  } catch (error) {
    yield put(addCommentAction.failure(error));
  }
}

function* loadCommentRequest({
  payload,
}: ReturnType<typeof loadCommentAction.request>): SagaIterator<void> {
  try {
    const response: Comment[] = yield call(loadCommentAPIRequest, payload);
    yield put(loadCommentAction.success(response));
  } catch (error) {
    yield put(loadCommentAction.failure(error));
  }
}

function* loadScheduleTableViewDataRequest({
  payload,
}: ReturnType<typeof loadBranchTableSchedules.request>): SagaIterator<void> {
  try {
    const response: BranchScheduleTableViewResponseType = yield call(
      getScheduleTableViewData,
      payload,
    );
    yield put(loadBranchTableSchedules.success(response));
  } catch (error) {
    yield put(loadBranchTableSchedules.failure(error));
  }
}

function* deleteScheduleRequest({
  payload: { eventId, callback },
}: ReturnType<typeof deleteScheduleAction.request>): SagaIterator<void> {
  try {
    yield call(deleteScheduleEvent, eventId);
    yield put(deleteScheduleAction.success(eventId));
    callback();
  } catch (error) {
    yield put(deleteScheduleAction.failure(error));
  }
}

function* deleteAttachmentRequest({
  payload,
}: ReturnType<typeof deleteAttachmentAction.request>): SagaIterator<void> {
  try {
    yield call(deleteAttachmentAPI, payload);
    yield put(deleteAttachmentAction.success(payload.eventId));
    payload.callback('success');
  } catch (error) {
    yield put(deleteAttachmentAction.failure(error));
    payload.callback('error', error);
  }
}

function* createScheduleRequest({
  payload,
}: ReturnType<typeof createScheduleAction.request>): SagaIterator<void> {
  const { schedule, callback } = payload;
  try {
    yield call(setSchedule, schedule);
    yield put(createScheduleAction.success());
    yield call(callback);
  } catch (error) {
    callback?.('error', error);
    yield put(createScheduleAction.failure(error));
  }
}

function* createRecordingLink({
  payload,
}: ReturnType<typeof createRecordingLinkAction.request>): SagaIterator<void> {
  try {
    yield call(setRecordingLink, payload);
    yield put(createRecordingLinkAction.success(payload));
  } catch (error) {
    yield put(createRecordingLinkAction.failure(error.message || error));
  }
}

function* deleteAttachRecordingLink({
  payload,
}: ReturnType<typeof deleteRecordingLinkAction.request>): SagaIterator<void> {
  try {
    yield call(setRecordingLink, payload);
    yield put(deleteRecordingLinkAction.success(payload.id));
  } catch (error) {
    yield put(deleteRecordingLinkAction.failure(error.message || error));
  }
}

function* addEventAttachmentsRequest({
  payload,
}: ReturnType<typeof addEventAttachmentsAction.request>): SagaIterator<void> {
  try {
    const response: any = yield call(addEventAttachments, payload);
    yield put(addEventAttachmentsAction.success(response));
  } catch (error) {
    yield put(addEventAttachmentsAction.failure(error.message || error));
  }
}

function* loadTeacherEventAttachmentsRequest({
  payload,
}: ReturnType<typeof loadTeacherEventAttachmentsAction.request>): SagaIterator<void> {
  try {
    const response: any = yield call(getTeacherEventAttachments, payload);
    yield put(loadTeacherEventAttachmentsAction.success(response));
  } catch (error) {
    yield put(loadTeacherEventAttachmentsAction.failure(error));
  }
}

function* sendDeclineEventRequestAction({
  payload: { callback, ...restPayload },
}: ReturnType<typeof declineEventRequestAction.request>): SagaIterator<void> {
  try {
    const response: EventDetailType = yield call(sendDeclineEventRequest, restPayload);
    yield put(declineEventRequestAction.success(response));
    callback();
  } catch (error) {
    yield put(declineEventRequestAction.failure(error));
  }
}

function* sendRateLiveEventRequestAction({
  payload,
}: ReturnType<typeof liveEventRatingAction.request>): SagaIterator<void> {
  try {
    const response: EventDetailType = yield call(sendRateLiveEventAPI, payload);
    yield put(liveEventRatingAction.success(response));
    payload.callBack?.();
  } catch (error) {
    yield put(liveEventRatingAction.failure(error));
  }
}

function* updateRateLiveEventRequestAction({
  payload,
}: ReturnType<typeof updateLiveEventRatingAction.request>): SagaIterator<void> {
  try {
    const response: EventDetailType = yield call(updateRateLiveEventAPI, payload);
    yield put(updateLiveEventRatingAction.success(response));
    payload.callBack?.();
  } catch (error) {
    yield put(updateLiveEventRatingAction.failure(error));
  }
}

function* getStudentQuestionsRequestAction({
  payload,
}: ReturnType<typeof getStudentLiveQuestions.request>): SagaIterator<void> {
  try {
    const response: StudentLiveQuestionsType[] = yield call(getStudentQuestionsAPI, payload);
    yield put(getStudentLiveQuestions.success(response));
  } catch (error) {
    yield put(getStudentLiveQuestions.failure(error));
  }
}

function* postStudentQuestionsRequestAction({
  payload: { callback, ...payload },
}: ReturnType<typeof postStudentLiveQuestions.request>): SagaIterator<void> {
  try {
    const response: StudentLiveQuestionsType = yield call(postStudentQuestionsAPI, payload);
    yield put(postStudentLiveQuestions.success(response));
    callback?.(true);
  } catch (error) {
    yield put(postStudentLiveQuestions.failure(error));
  }
}

function* postEventAnswerAPIRequestAction({
  payload: { callback, ...payload },
}: ReturnType<typeof postLiveEventAnswer.request>): SagaIterator<void> {
  try {
    const response = yield call(postEventAnswerAPI, payload);
    yield put(postLiveEventAnswer.success(response));
    callback?.(true);
  } catch (error) {
    yield put(postLiveEventAnswer.failure(error));
  }
}

function* updateEventAnswerAPIRequestAction({
  payload: { callback, ...payload },
}: ReturnType<typeof updateLiveEventAnswer.request>): SagaIterator<void> {
  try {
    const response = yield call(updateEventAnswerAPI, payload);
    yield put(updateLiveEventAnswer.success(response));
    callback?.(true);
  } catch (error) {
    yield put(updateLiveEventAnswer.failure(error));
  }
}

function* getTeacherQuestionsRequestAction({
  payload,
}: ReturnType<typeof getTeacherLiveQuestions.request>): SagaIterator<void> {
  try {
    const response: TeacherSessionQAEventType[] = yield call(getTeacherQuestionsAPI, payload);
    yield put(getTeacherLiveQuestions.success(response));
  } catch (error) {
    yield put(getTeacherLiveQuestions.failure(error));
  }
}

function* deleteStudentQuestionsRequestAction({
  payload,
}: ReturnType<typeof deleteLiveEventQuestion.request>): SagaIterator<void> {
  try {
    const response: EventDetailType = yield call(deleteEventQuestionAPI, payload);
    yield put(deleteLiveEventQuestion.success(response));
    payload.callBack();
  } catch (error) {
    yield put(deleteLiveEventQuestion.failure(error));
  }
}

function* getLiveScheduleDataAction({
  payload,
}: ReturnType<typeof getLiveScheduleData.request>): SagaIterator<void> {
  try {
    const response: LiveClassRecommendationItem = yield call(getLiveScheduleDataAPI, payload);
    yield put(getLiveScheduleData.success(response));
  } catch (error) {
    if (payload.callback) payload.callback('error', error);
    yield put(getLiveScheduleData.failure(error));
  }
}

function* postGeneralAnswerAPIRequestAction({
  payload: { callback, ...payload },
}: ReturnType<typeof postGeneralAnswer.request>): SagaIterator<void> {
  try {
    const response = yield call(postGeneralAnswerAPI, payload);
    yield put(postGeneralAnswer.success(response));
    callback?.(true);
  } catch (error) {
    yield put(postGeneralAnswer.failure(error));
  }
}

function* updateGeneralAnswerAPIRequestAction({
  payload: { callback, ...payload },
}: ReturnType<typeof updateGeneralAnswer.request>): SagaIterator<void> {
  try {
    const response = yield call(updateGeneralAnswerAPI, payload);
    yield put(updateGeneralAnswer.success(response));
    callback?.(true);
  } catch (error) {
    yield put(updateGeneralAnswer.failure(error));
  }
}

function* loadGenearalStudentQuestionsRequestAction({
  payload,
}: ReturnType<typeof getStudentGeneralQuestions.request>): SagaIterator<void> {
  try {
    const response: GeneralQuestionAnswerType = yield call(getStudentGeneralQA, payload.subjectId);
    yield put(getStudentGeneralQuestions.success(response));
  } catch (error) {
    yield put(getStudentGeneralQuestions.failure(error));
  }
}

function* scheduleSaga(): SagaIterator {
  yield all([takeEvery(loadScheduleAction.request, loadScheduleRequest)]);
  yield all([takeEvery(loadJoinMeetingUrl.request, loadJoinMeetingRequest)]);
  yield all([takeEvery(addCommentAction.request, addCommentRequest)]);
  yield all([takeEvery(loadCommentAction.request, loadCommentRequest)]);
  yield all([takeEvery(refreshJoinMeetingUrl.request, refreshJoinMeetingRequest)]);
  yield all([
    takeEvery(loadBranchCalenderScheduleOptions.request, loadScheduleCardViewDataRequest),
  ]);
  yield all([takeEvery(loadBranchTableSchedules.request, loadScheduleTableViewDataRequest)]);
  yield all([takeEvery(loadScheduleDetailAction.request, loadEventDetailRequest)]);
  yield all([takeEvery(createScheduleAction.request, createScheduleRequest)]);
  yield all([takeEvery(loadScheduleFormOptions.request, loadScheduleFiltersRequest)]);
  yield all([takeEvery(deleteScheduleAction.request, deleteScheduleRequest)]);
  yield all([takeEvery(deleteAttachmentAction.request, deleteAttachmentRequest)]);
  yield all([takeEvery(createRecordingLinkAction.request, createRecordingLink)]);
  yield all([takeEvery(deleteRecordingLinkAction.request, deleteAttachRecordingLink)]);
  yield all([takeEvery(declineEventRequestAction.request, sendDeclineEventRequestAction)]);
  yield all([takeEvery(liveEventRatingAction.request, sendRateLiveEventRequestAction)]);
  yield all([takeEvery(updateLiveEventRatingAction.request, updateRateLiveEventRequestAction)]);
  yield all([takeEvery(getStudentLiveQuestions.request, getStudentQuestionsRequestAction)]);
  yield all([takeEvery(postStudentLiveQuestions.request, postStudentQuestionsRequestAction)]);
  yield all([takeEvery(postLiveEventAnswer.request, postEventAnswerAPIRequestAction)]);
  yield all([takeEvery(deleteLiveEventQuestion.request, deleteStudentQuestionsRequestAction)]);
  yield all([takeEvery(updateLiveEventAnswer.request, updateEventAnswerAPIRequestAction)]);
  yield all([takeEvery(getTeacherLiveQuestions.request, getTeacherQuestionsRequestAction)]);
  yield all([takeEvery(addEventAttachmentsAction.request, addEventAttachmentsRequest)]);
  yield all([
    takeEvery(loadTeacherEventAttachmentsAction.request, loadTeacherEventAttachmentsRequest),
  ]);
  yield all([takeEvery(getLiveScheduleData.request, getLiveScheduleDataAction)]);
  yield all([
    takeEvery(getStudentGeneralQuestions.request, loadGenearalStudentQuestionsRequestAction),
  ]);
  yield all([takeEvery(postGeneralAnswer.request, postGeneralAnswerAPIRequestAction)]);
  yield all([takeEvery(updateGeneralAnswer.request, updateGeneralAnswerAPIRequestAction)]);
}

export default scheduleSaga;
