import { RESTRICTED_GRADES, RESTRICTED_MENU_ITEMS } from 'constants/entities';
import { HIDE_EXAMS_FEATURE } from 'constants/settings';
import { Gender } from 'store/account/types';

import { parentMenu } from './parentMenu';
import { studentMenu } from './studentMenu';
import { mentorMenu } from './mentorMenu';
import { alwAdminMenu, contentAuthorMenu } from './menus';
import { b2bTeacherMenu } from './b2bTeacherMenu';
import { schoolBranchManagerMenu } from './schoolBranchManagerMenu';
import { MenuItemType } from '../components/Sidebar/types';
import { alwTecherMenu } from './alwTeacherMenu';

export const getMenuItems = (
  userType?: string,
  gender?: Gender | null,
  grade?: number,
  parentChildren?: any,
  isB2BStudent?: boolean,
  isValidPreparationGrade?: Boolean,
  allowB2BGrading = true,
): MenuItemType[] => {
  const menu: Record<string, Function> = {
    parent: () => parentMenu(parentChildren),
    mentor: () => mentorMenu(),
    alw_teacher: () => alwTecherMenu(grade),
    teacher: () => b2bTeacherMenu(isValidPreparationGrade),
    student: () => studentMenu(isB2BStudent, grade, gender),
    branch_manager: () => schoolBranchManagerMenu(true, allowB2BGrading),
    school_admin: () => schoolBranchManagerMenu(false, allowB2BGrading),
    teacherAndMentor: () => mentorMenu(),
    alwAdmin: () => alwAdminMenu(),
    contentAuthor: () => contentAuthorMenu(),
  };

  let sideMenu: MenuItemType[] = userType
    ? menu[userType]()
    : studentMenu(isB2BStudent, grade, gender);

  if (HIDE_EXAMS_FEATURE) {
    const examId = 'sidebar__exams';
    sideMenu = sideMenu.filter((menu) => menu.id !== examId);
  }

  if (grade && RESTRICTED_GRADES.includes(+grade)) {
    return sideMenu.filter((menu) => !RESTRICTED_MENU_ITEMS.includes(`${menu.id}`));
  }

  return sideMenu;
};
