import { combineReducers } from 'redux';
import { remove, map } from 'lodash/fp';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  loadScheduleAction,
  loadTableListScheduleAction,
  resetScheduleAction,
  createScheduleAction,
  deleteScheduleAction,
  deleteRecordingLinkAction,
  createRecordingLinkAction,
  loadJoinMeetingUrl,
  resetJoinUrl,
  loadScheduleDetailAction,
  resetScheduleDetailAction,
  addEventAttachmentsAction,
  loadTeacherEventAttachmentsAction,
  declineEventRequestAction,
  refreshJoinMeetingUrl,
  liveEventRatingAction,
  getStudentLiveQuestions,
  postStudentLiveQuestions,
  getTeacherLiveQuestions,
  deleteLiveEventQuestion,
  deleteAttachmentAction,
  getLiveEventQuestions,
  postLiveEventAnswer,
  updateLiveEventAnswer,
  updateLiveEventRatingAction,
  loadScheduleFormOptions,
  loadBranchCalenderScheduleOptions,
  loadBranchTableSchedules,
  loadCommentAction,
  resetComments,
  getLiveScheduleData,
  scheduleStartDateAction,
  scheduleEndDateAction,
  scheduleStartDateCompAction,
  scheduleEndDateCompAction,
  scheduleActiveStartDateCompAction,
  scheduleStartDateForApiAction,
  getStudentGeneralQuestions,
} from './actions';

import {
  EventDetailType,
  ScheduleEventType,
  TableViewResponseType,
  BranchManagerScheduleType,
  BranchScheduleTableViewResponseType,
  ScheduleCalendarType,
  ScheduleFormOptionsType,
  Comment,
  LiveScheduleDataType,
  ScheduleErrorType,
  scheduleTypes,
  LiveQuestionsAnswersType,
  GeneralQuestionAnswerType,
} from './types';

export type ScheduleActionType = ActionType<typeof actions>;

export const initialScheduleStates = {
  startDate: undefined,
  endDate: undefined,
  startDateComp: undefined,
  endDateComp: undefined,
  activeStartDateComp: undefined,
  startDateForApi: undefined,
};

export const scheduleStateReducer = createReducer<scheduleTypes, ScheduleActionType>(
  initialScheduleStates,
)
  .handleAction([scheduleStartDateAction], (state, action) => ({
    ...state,
    ...{ startDate: action.payload },
  }))
  .handleAction([scheduleEndDateAction], (state, action) => ({
    ...state,
    ...{ endDate: action.payload },
  }))
  .handleAction([scheduleStartDateCompAction], (state, action) => ({
    ...state,
    ...{ startDateComp: action.payload },
  }))
  .handleAction([scheduleEndDateCompAction], (state, action) => ({
    ...state,
    ...{ endDateComp: action.payload },
  }))
  .handleAction([scheduleActiveStartDateCompAction], (state, action) => ({
    ...state,
    ...{ activeStartDateComp: action.payload },
  }))
  .handleAction([scheduleStartDateForApiAction], (state, action) => ({
    ...state,
    ...{ startDateForApi: action.payload },
  }));

const data = createReducer<ScheduleEventType[], ScheduleActionType>([] as ScheduleEventType[])
  .handleAction(loadScheduleAction.success, (state, action) => action.payload)
  .handleAction(createScheduleAction.success, (state) => [...state])
  .handleAction(createRecordingLinkAction.success, (state, { payload }) =>
    map((event: ScheduleEventType) => (event.id === payload.id ? { ...event, ...payload } : event))(
      state,
    ),
  )
  .handleAction(deleteRecordingLinkAction.success, (state, { payload }) =>
    map((event: ScheduleEventType) =>
      event.id === payload ? { ...event, recordingLink: '' } : event,
    )(state),
  )
  .handleAction(deleteScheduleAction.success, (state, { payload }) =>
    remove(({ id }: ScheduleEventType) => id === payload)(state),
  )
  .handleAction(resetScheduleAction, () => [])
  .handleAction(liveEventRatingAction.success, (state) => state);

const tableData = createReducer<TableViewResponseType, ScheduleActionType>({
  count: 0,
  currentPage: 0,
  next: '',
  numPages: 0,
  previous: '',
  results: [],
  start: 0,
}).handleAction(loadTableListScheduleAction.success, (state, action) => action.payload);

const liveQAData = createReducer<LiveQuestionsAnswersType, ScheduleActionType>({
  teacherSessionQAEvents: [],
})
  .handleAction(getTeacherLiveQuestions.success, (state, action) => ({
    ...state,
    teacherSessionQAEvents: action.payload,
  }))
  .handleAction(getStudentLiveQuestions.success, (state, action) => ({
    ...state,
    studentLiveQuestions: action.payload,
  }))
  .handleAction(postStudentLiveQuestions.success, (state, action) => ({
    ...state,
    postStudentLiveQuestions: action.payload,
  }))
  .handleAction(getLiveEventQuestions.success, (state, action) => ({
    ...state,
    liveEventQuestions: action.payload,
  }))
  .handleAction(postLiveEventAnswer.success, (state, action) => ({
    ...state,
    liveEventAnswers: action.payload,
  }))
  .handleAction(updateLiveEventAnswer.success, (state, action) => ({
    ...state,
    updateLiveEventAnswer: action.payload,
  }))
  .handleAction(deleteLiveEventQuestion.success, (state, action) => ({
    ...state,
    deleteLiveQuestion: action.payload,
  }))
  .handleAction(deleteAttachmentAction.success, (state, action) => ({
    ...state,
    deleteAttachment: action.payload,
  }))
  .handleAction(updateLiveEventRatingAction.success, (state, action) => ({
    ...state,
    updateRateLiveQuestion: action.payload,
  }));

const fetching = combineReducers({
  load: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(loadScheduleAction.request, () => true)
    .handleAction([loadScheduleAction.success, loadScheduleAction.failure], () => false),
  create: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(createScheduleAction.request, () => true)
    .handleAction([createScheduleAction.success, createScheduleAction.failure], () => false),
  delete: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(deleteScheduleAction.request, () => true)
    .handleAction([deleteScheduleAction.success, deleteScheduleAction.failure], () => false),
  createRecordingLink: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(createRecordingLinkAction.request, () => true)
    .handleAction(
      [createRecordingLinkAction.success, createRecordingLinkAction.failure],
      () => false,
    ),
  deleteRecordingLink: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(deleteRecordingLinkAction.request, () => true)
    .handleAction(
      [deleteRecordingLinkAction.success, deleteRecordingLinkAction.failure],
      () => false,
    ),
  addEventAttachments: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(addEventAttachmentsAction.request, () => true)
    .handleAction(
      [addEventAttachmentsAction.success, addEventAttachmentsAction.failure],
      () => false,
    ),
  declineEventRequest: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(declineEventRequestAction.request, () => true)
    .handleAction(
      [declineEventRequestAction.success, declineEventRequestAction.failure],
      () => false,
    ),
  rateLiveEvent: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(liveEventRatingAction.request, () => true)
    .handleAction([liveEventRatingAction.success, liveEventRatingAction.failure], () => false),
  updateRateLiveEvent: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(updateLiveEventRatingAction.request, () => true)
    .handleAction(
      [updateLiveEventRatingAction.success, updateLiveEventRatingAction.failure],
      () => false,
    ),
  studentLiveQuestions: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(getStudentLiveQuestions.request, () => true)
    .handleAction([getStudentLiveQuestions.success, getStudentLiveQuestions.failure], () => false),
  postStudentLiveQuestions: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(postStudentLiveQuestions.request, () => true)
    .handleAction([postStudentLiveQuestions.success, postStudentLiveQuestions.failure], () => false)
    .handleAction([getTeacherLiveQuestions.success, getTeacherLiveQuestions.failure], () => false),
  teacherLiveQuestions: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(getTeacherLiveQuestions.request, () => true)
    .handleAction([getTeacherLiveQuestions.success, getTeacherLiveQuestions.failure], () => false),
  liveEventQuestions: createReducer<boolean, ScheduleActionType>(false).handleAction(
    getLiveEventQuestions.request,
    () => true,
  ),
  liveEventAnswer: createReducer<boolean, ScheduleActionType>(false).handleAction(
    postLiveEventAnswer.request,
    () => true,
  ),
  updateLiveEventAnswer: createReducer<boolean, ScheduleActionType>(false).handleAction(
    updateLiveEventAnswer.request,
    () => true,
  ),
  deleteLiveEventQuestion: createReducer<boolean, ScheduleActionType>(false).handleAction(
    deleteLiveEventQuestion.request,
    () => true,
  ),
  deleteAttachment: createReducer<boolean, ScheduleActionType>(false).handleAction(
    deleteAttachmentAction.request,
    () => true,
  ),
  getLiveScheduleData: createReducer<boolean, ScheduleActionType>(false).handleAction(
    getLiveScheduleData.request,
    () => true,
  ),
});

const errors = combineReducers({
  load: createReducer<string, ScheduleActionType>('')
    .handleAction(loadScheduleAction.failure, (state, { payload }) => payload)
    .handleAction([loadScheduleAction.success, resetScheduleAction], () => ''),
  create: createReducer<string | any, ScheduleActionType>('')
    .handleAction(createScheduleAction.failure, (state, { payload }) => payload)
    .handleAction([createScheduleAction.success, resetScheduleAction], () => ''),
  delete: createReducer<string, ScheduleActionType>('')
    .handleAction(deleteScheduleAction.failure, (state, { payload }) => payload)
    .handleAction([deleteScheduleAction.success, resetScheduleDetailAction], () => ''),
  createRecordingLink: createReducer<string, ScheduleActionType>('')
    .handleAction(createRecordingLinkAction.failure, (state, { payload }) => payload)
    .handleAction([createRecordingLinkAction.success, resetScheduleAction], () => ''),
  deleteRecordingLink: createReducer<string, ScheduleActionType>('')
    .handleAction(deleteRecordingLinkAction.failure, (state, { payload }) => payload)
    .handleAction([deleteRecordingLinkAction.success, resetScheduleAction], () => ''),
  addEventAttachments: createReducer<string, ScheduleActionType>('')
    .handleAction(addEventAttachmentsAction.failure, (state, { payload }) => payload)
    .handleAction([addEventAttachmentsAction.success, resetScheduleDetailAction], () => ''),
  declineEventRequest: createReducer<string, ScheduleActionType>('')
    .handleAction(declineEventRequestAction.failure, (state, { payload }) => payload)
    .handleAction([declineEventRequestAction.success, resetScheduleDetailAction], () => ''),
  rateLiveEvent: createReducer<string, ScheduleActionType>('')
    .handleAction(liveEventRatingAction.failure, (state, { payload }) => payload)
    .handleAction([liveEventRatingAction.success], () => ''),
  UpdateRateLiveEvent: createReducer<string, ScheduleActionType>('')
    .handleAction(updateLiveEventRatingAction.failure, (state, { payload }) => payload)
    .handleAction([updateLiveEventRatingAction.success], () => ''),
  studentLiveQuestions: createReducer<string, ScheduleActionType>('')
    .handleAction(getStudentLiveQuestions.failure, (state, { payload }) => payload)
    .handleAction([getStudentLiveQuestions.success], () => ''),
  postStudentLiveQuestions: createReducer<string, ScheduleActionType>('')
    .handleAction(postStudentLiveQuestions.failure, (state, { payload }) => payload)
    .handleAction([postStudentLiveQuestions.success], () => ''),
  teacherLiveQuestions: createReducer<string, ScheduleActionType>('')
    .handleAction(getTeacherLiveQuestions.failure, (state, { payload }) => payload)
    .handleAction([getTeacherLiveQuestions.success], () => ''),
  liveEventQuestions: createReducer<string, ScheduleActionType>('')
    .handleAction(getLiveEventQuestions.failure, (state, { payload }) => payload)
    .handleAction([getLiveEventQuestions.success], () => ''),
  liveEventAnswer: createReducer<string, ScheduleActionType>('')
    .handleAction(postLiveEventAnswer.failure, (state, { payload }) => payload)
    .handleAction([postLiveEventAnswer.success], () => ''),
  liveEventAnswerUpdate: createReducer<string, ScheduleActionType>('')
    .handleAction(updateLiveEventAnswer.failure, (state, { payload }) => payload)
    .handleAction([updateLiveEventAnswer.success], () => ''),
  deleteLiveQuestion: createReducer<string, ScheduleActionType>('')
    .handleAction(deleteLiveEventQuestion.failure, (state, { payload }) => payload)
    .handleAction([deleteLiveEventQuestion.success], () => ''),
  deleteAttachment: createReducer<string, ScheduleActionType>('')
    .handleAction(deleteAttachmentAction.failure, (state, { payload }) => payload)
    .handleAction([deleteAttachmentAction.success], () => ''),
  getLiveScheduleData: createReducer<string | ScheduleErrorType, ScheduleActionType>('')
    .handleAction(getLiveScheduleData.failure, (state, { payload }) => payload)
    .handleAction([getLiveScheduleData.success], () => ''),
});

const joinMeeting = createReducer<any, ScheduleActionType>({})
  .handleAction(loadJoinMeetingUrl.success, (state, action) => action.payload)
  .handleAction(refreshJoinMeetingUrl.success, (state, action) => action.payload)
  .handleAction(resetJoinUrl, () => []);

const liveSchedule = createReducer<LiveScheduleDataType, ScheduleActionType>(
  {} as LiveScheduleDataType,
).handleAction([getLiveScheduleData.success], (state, action) => ({
  ...state,
  ...{ data: action.payload },
}));

const fetchingUrl = createReducer<boolean, ScheduleActionType>(false)
  .handleAction(loadJoinMeetingUrl.request, () => true)
  .handleAction([loadJoinMeetingUrl.success, loadJoinMeetingUrl.failure], () => false);

const fetchingRefreshedURL = createReducer<boolean, ScheduleActionType>(false)
  .handleAction(refreshJoinMeetingUrl.request, () => true)
  .handleAction([refreshJoinMeetingUrl.success, refreshJoinMeetingUrl.failure], () => false);

const eventDetail = createReducer<EventDetailType, ScheduleActionType>({} as EventDetailType)
  .handleAction(loadScheduleDetailAction.success, (state, action) => action.payload)
  .handleAction(addEventAttachmentsAction.success, (state, action) => ({
    ...state,
    attachmentsInfo: !state?.attachmentsInfo
      ? { book: null, endPage: null, startPage: null, attachments: action.payload, lessons: [] }
      : { ...state.attachmentsInfo, attachments: action.payload },
  }))
  .handleAction(declineEventRequestAction.success, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .handleAction(resetScheduleDetailAction, () => {
    return {} as EventDetailType;
  });

const fetchingEvent = createReducer<boolean, ScheduleActionType>(false)
  .handleAction(loadScheduleDetailAction.request, () => true)
  .handleAction([loadScheduleDetailAction.success, loadScheduleDetailAction.failure], () => false);

const eventDetailErrors = createReducer<string, ScheduleActionType>('')
  .handleAction(loadScheduleDetailAction.failure, (state, action) => action.payload)
  .handleAction([loadScheduleDetailAction.success, resetScheduleDetailAction], () => '');

const teacherEventAttachmentsData = createReducer<[], ScheduleActionType>([])
  .handleAction(loadTeacherEventAttachmentsAction.success, (state, action) => action.payload)
  .handleAction(resetScheduleDetailAction, () => []);

const fetchingTeacherEventAttachments = createReducer<boolean, ScheduleActionType>(false)
  .handleAction(loadTeacherEventAttachmentsAction.request, () => true)
  .handleAction(
    [loadTeacherEventAttachmentsAction.success, loadTeacherEventAttachmentsAction.failure],
    () => false,
  );

const branchManagerData = createReducer<BranchManagerScheduleType, ScheduleActionType>({
  filters: {} as ScheduleFormOptionsType,
  cardData: [] as ScheduleCalendarType[],
  comments: [] as Comment[],
  tableData: {
    count: 0,
    currentPage: 0,
    next: '',
    numPages: 0,
    previous: '',
    results: [],
    start: 0,
  } as BranchScheduleTableViewResponseType,
} as BranchManagerScheduleType)
  .handleAction(loadScheduleFormOptions.success, (state, action) => {
    return { ...state, filters: action.payload };
  })
  .handleAction(loadBranchCalenderScheduleOptions.success, (state, action) => {
    return {
      ...state,
      cardData: action.payload,
    };
  })
  .handleAction(loadBranchTableSchedules.success, (state, action) => {
    return {
      ...state,
      tableData: action.payload,
    };
  })
  .handleAction(loadCommentAction.success, (state, action) => {
    return {
      ...state,
      comments: action.payload,
    };
  })
  .handleAction(resetComments, (state) => {
    return {
      ...state,
      comments: [],
    };
  });

const loadingBMData = combineReducers({
  filters: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(loadScheduleFormOptions.request, () => true)
    .handleAction([loadScheduleFormOptions.success, loadScheduleFormOptions.failure], () => false),
  data: createReducer<boolean, ScheduleActionType>(false)
    .handleAction(
      [loadBranchCalenderScheduleOptions.request, loadBranchTableSchedules.request],
      () => true,
    )
    .handleAction(
      [
        loadBranchCalenderScheduleOptions.success,
        loadBranchCalenderScheduleOptions.failure,
        loadBranchTableSchedules.success,
        loadBranchTableSchedules.failure,
      ],
      () => false,
    ),
  comments: createReducer<boolean, ScheduleActionType>(false)
    .handleAction([loadCommentAction.request], () => true)
    .handleAction([loadCommentAction.success, loadCommentAction.failure], () => false),
});

const teacherGeneralQAData = createReducer<GeneralQuestionAnswerType, ScheduleActionType>({
  count: 0,
  currentPage: 0,
  next: '',
  numPages: 0,
  previous: '',
  results: [],
  start: 0,
}).handleAction(getStudentGeneralQuestions.success, (_, action) => action.payload);

const fetchingTeacherGeneralQA = createReducer<boolean, ScheduleActionType>(false)
  .handleAction([getStudentGeneralQuestions.request], () => true)
  .handleAction(
    [getStudentGeneralQuestions.success, getStudentGeneralQuestions.failure],
    () => false,
  );

const scheduleReducer = combineReducers({
  data,
  tableData,
  liveQAData,
  liveSchedule,
  fetching,
  errors,
});

export const scheduleBMReducer = combineReducers({
  data: branchManagerData,
  fetching: loadingBMData,
});

export const joinMeetingReducer = combineReducers({
  joinMeeting,
  fetchingUrl,
  fetchingRefreshedURL,
});

export const eventDetailReducer = combineReducers({
  fetching: fetchingEvent,
  data: eventDetail,
  errors: eventDetailErrors,
});

export const teacherEventAttachmentsReducer = combineReducers({
  data: teacherEventAttachmentsData,
  fetching: fetchingTeacherEventAttachments,
});

export const teacherGeneralQAReducer = combineReducers({
  data: teacherGeneralQAData,
  fetching: fetchingTeacherGeneralQA,
});

export type ScheduleStateType = StateType<typeof scheduleReducer>;
export type JoinMeetingStateType = StateType<typeof joinMeetingReducer>;
export type BMScheduleStateType = StateType<typeof scheduleBMReducer>;
export type EventDetailStateType = StateType<typeof eventDetailReducer>;
export type TeacherEventAttachmentsStateType = StateType<typeof teacherEventAttachmentsReducer>;
export type TeacherGeneralQAStateType = StateType<typeof teacherGeneralQAReducer>;

export default scheduleReducer;
