import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { getSvgURL } from 'lib';
import { OrientationVideosType } from 'store/orientation/types';
import { isArabicMode } from 'utils/helpers';

import styles from './Orientation.module.scss';
import { msgs } from './messages';
import { OrientationContext } from './OrientationContext';

const OrientationVideos = () => {
  const { formatMessage: fm, locale } = useIntl();
  const { orientationData } = useContext(OrientationContext);
  const { data, renderActionButtons } = orientationData || ({} as OrientationVideosType);

  const isArabic = isArabicMode(locale);
  const { titleEn, titleAr, videoAr, videoEn, featureIcon = '' } = data || {};
  const title = isArabic ? titleAr : titleEn;
  const url = isArabic ? videoAr : videoEn;

  return (
    <div className={styles.orientationVideo__detail}>
      <h1 className={styles.orientationVideo__heading}>
        <img
          className={styles.orientationVideo__heading__img}
          src={getSvgURL(featureIcon)}
          alt={title}
        />
        {title}
      </h1>
      <p className={styles.orientationVideo__desc}>{fm(msgs.demoDescription, { title })}</p>
      <div className={styles.orientationVideo__videoWrapper}>
        <video width="100%" height="400px" controls key={url}>
          <track kind="captions" src="sampleCaptions.vtt" srcLang={isArabic ? 'ar' : 'en'} />
          <source src={url} type="video/mp4" />
        </video>
      </div>
      <div className={styles.orientationVideo__actions}>{renderActionButtons()}</div>
    </div>
  );
};

export default OrientationVideos;
