import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { APIError } from 'utils/api';
import style from './ErrorMessage.module.scss';

export const ErrorMessage: FunctionComponent<any> = ({ children }) => {
  const { formatMessage } = useIntl();
  if (!children) return null;

  const errors: any = {
    [APIError.network]: formatMessage({
      id: 'api.error.network',
      defaultMessage: 'Network connection error, please try again later.',
    }),
    [APIError.server]: formatMessage({
      id: 'api.error.server',
      defaultMessage: 'Server error, please try again later.',
    }),
    [APIError.notFound]: formatMessage({
      id: 'api.error.404',
      defaultMessage: '404. Not found',
    }),
  };
  return (
    <span className={style['form-error']} title={errors[children] || children}>
      {errors[children] || children}
    </span>
  );
};
