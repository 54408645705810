import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Loader, getSvgURL } from 'lib';
import { useTheme } from 'contexts/ThemeContext';
import { ROUTES } from 'constants/routes';
import { getLandingUrl } from 'utils/helpers';

import styles from './Header.module.scss';

export type HeaderProps = {
  isLoading?: boolean;
  shouldRedirectToDashboard?: boolean;
};

const LogoLink = ({
  shouldRedirectToDashboard,
  children,
}: {
  shouldRedirectToDashboard: boolean;
  children: JSX.Element;
}) => {
  const { locale } = useIntl();

  const landingUrl = getLandingUrl(locale);
  return (
    <>
      {!shouldRedirectToDashboard && landingUrl ? (
        <a target="_blank" rel="noreferrer" href={landingUrl} className={styles.logo}>
          {children}
        </a>
      ) : (
        <Link className={styles.logo} to={ROUTES.root} rel="noreferrer" target="_self">
          {children}
        </Link>
      )}
    </>
  );
};

const Header = ({ isLoading = false, shouldRedirectToDashboard = false }: HeaderProps) => {
  const { formatMessage } = useIntl();
  const { theme } = useTheme();

  if (isLoading) return <Loader />;

  return (
    <header className={styles.header}>
      <LogoLink shouldRedirectToDashboard={shouldRedirectToDashboard}>
        <img
          src={getSvgURL(theme === 'dark' ? 'dark-logo-text' : 'logo-text')}
          alt={formatMessage({
            id: 'login.title',
            defaultMessage: 'Advanced Learning World',
          })}
        />
      </LogoLink>
    </header>
  );
};

export default Header;
