import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  assignmentDetailRequestType,
  assignmentDetailsType,
  assignmentGroupsType,
  assignmentResubmissionRequestPayload,
  ClassAssignmentsType,
  CreateAssignmentRequestType,
  createGroupsRequestPayload,
  deleteGroupRequestPayload,
  groupsRequestType,
  paginatedStudentAssignmentDetailType,
  renameGroupRequestPayload,
  loadAssignmentsRequestType,
  studentAssignmentType,
  studentSubjectType,
  loadStudentSubjectPayload,
  submitAssignmentRequestType,
  updateDeadlineRequestPayload,
  updateStudentAssignmentScoreRequestType,
  updateStudentGroupRequestPayload,
  CreateAssignmentResponseType,
} from './types';

export const loadTeacherAssignmentsAction = createAsyncAction(
  '@@contentAssignments/LOAD_TEACHER_ASSIGNMENTS_REQUEST',
  '@@contentAssignments/LOAD_TEACHER_ASSIGNMENTS_SUCCESS',
  '@@contentAssignments/LOAD_TEACHER_ASSIGNMENTS_FAILURE',
)<number, ClassAssignmentsType, string>();

export const loadTeacherAssignmentDetailAction = createAsyncAction(
  '@@contentAssignments/LOAD_TEACHER_ASSIGNMENT_DETAIL_REQUEST',
  '@@contentAssignments/LOAD_TEACHER_ASSIGNMENT_DETAIL_SUCCESS',
  '@@contentAssignments/LOAD_TEACHER_ASSIGNMENT_DETAIL_FAILURE',
)<assignmentDetailRequestType, assignmentDetailsType, string>();

export const createAssignmentAction = createAsyncAction(
  '@@contentAssignments/CREATE_ASSIGNMENTS_REQUEST',
  '@@contentAssignments/CREATE_ASSIGNMENTS_SUCCESS',
  '@@contentAssignments/CREATE_ASSIGNMENTS_FAILURE',
)<CreateAssignmentRequestType, CreateAssignmentResponseType, string>();

export const updateStudentAssignmentScoreAction = createAsyncAction(
  '@@contentAssignments/UPDATE_STUDENT_ASSIGNMENTS_SCORE_REQUEST',
  '@@contentAssignments/UPDATE_STUDENT_ASSIGNMENTS_SCORE_SUCCESS',
  '@@contentAssignments/UPDATE_STUDENT_ASSIGNMENTS_SCORE_FAILURE',
)<updateStudentAssignmentScoreRequestType, studentAssignmentType, string>();

export const loadStudentSubjectsAction = createAsyncAction(
  '@@contentAssignments/LOAD_STUDENT_SUBJECTS_REQUEST',
  '@@contentAssignments/LOAD_STUDENT_SUBJECTS_SUCCESS',
  '@@contentAssignments/LOAD_STUDENT_SUBJECTS_FAILURE',
)<loadStudentSubjectPayload, studentSubjectType[], string>();

export const loadStudentAssignmentsAction = createAsyncAction(
  '@@contentAssignments/LOAD_STUDENT_ASSIGNMENTS_REQUEST',
  '@@contentAssignments/LOAD_STUDENT_ASSIGNMENTS_SUCCESS',
  '@@contentAssignments/LOAD_STUDENT_ASSIGNMENTS_FAILURE',
)<loadAssignmentsRequestType, paginatedStudentAssignmentDetailType, string>();

export const submitStudentAssignmentAction = createAsyncAction(
  '@@contentAssignments/SUBMIT_STUDENT_ASSIGNMENTS_REQUEST',
  '@@contentAssignments/SUBMIT_STUDENT_ASSIGNMENTS_REQUEST_SUCCESS',
  '@@contentAssignments/SUBMIT_STUDENT_ASSIGNMENTS_REQUEST_FAILURE',
)<submitAssignmentRequestType, studentAssignmentType, string>();

export const loadAssignmentGroupsAction = createAsyncAction(
  '@@contentAssignments/LOAD_ASSIGNMENT_GROUPS_REQUEST',
  '@@contentAssignments/LOAD_ASSIGNMENT_GROUPS_SUCCESS',
  '@@contentAssignments/LOAD_ASSIGNMENT_GROUPS_FAILURE',
)<groupsRequestType, assignmentGroupsType, string>();

export const updateStudentAssignmentGroupAction = createAsyncAction(
  '@@contentAssignments/UPDATE_STUDENT_GROUP_REQUEST',
  '@@contentAssignments/UPDATE_STUDENT_GROUP_SUCCESS',
  '@@contentAssignments/UPDATE_STUDENT_GROUP_FAILURE',
)<updateStudentGroupRequestPayload, assignmentGroupsType, string>();

export const renameAssignmentGroupAction = createAsyncAction(
  '@@contentAssignments/RENAME_GROUP_REQUEST',
  '@@contentAssignments/RENAME_GROUP_SUCCESS',
  '@@contentAssignments/RENAME_GROUP_FAILURE',
)<renameGroupRequestPayload, assignmentGroupsType, string>();

export const deleteAssignmentGroupAction = createAsyncAction(
  '@@contentAssignments/DELETE_GROUP_REQUEST',
  '@@contentAssignments/DELETE_GROUP_SUCCESS',
  '@@contentAssignments/DELETE_GROUP_FAILURE',
)<deleteGroupRequestPayload, assignmentGroupsType, string>();

export const createAssignmentGroupsAction = createAsyncAction(
  '@@contentAssignments/CREATE_GROUPS_REQUEST',
  '@@contentAssignments/CREATE_GROUPS_SUCCESS',
  '@@contentAssignments/CREATE_GROUPS_FAILURE',
)<createGroupsRequestPayload, assignmentGroupsType, string>();

export const updateAssignmentDeadlineAction = createAsyncAction(
  '@@contentAssignments/UPDATE_DEADLINE_REQUEST',
  '@@contentAssignments/UPDATE_DEADLINE_SUCCESS',
  '@@contentAssignments/UPDATE_DEADLINE_FAILURE',
)<updateDeadlineRequestPayload, { deadline: string }, string>();

export const assignmentResubmissionAction = createAsyncAction(
  '@@contentAssignments/ASSIGNMENT_RESUBMISSION_REQUEST',
  '@@contentAssignments/ASSIGNMENT_RESUBMISSION_SUCCESS',
  '@@contentAssignments/ASSIGNMENT_RESUBMISSION_FAILURE',
)<assignmentResubmissionRequestPayload, unknown, string>();

export const resetTeacherAssignmentsAction = createAction(
  '@@contentAssignments/RESET_TEACHER_ASSIGNMENTS',
)();

export const resetStudentAssignmentsAction = createAction(
  '@@contentAssignments/RESET_STUDENT_ASSIGNMENTS',
)();

export const resetAssignmentGroupsAction = createAction(
  '@@contentAssignments/RESET_ASSIGNMENT_GROUPS',
)();
