import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  createAssignmentGroupsAction,
  loadAssignmentGroupsAction,
  updateStudentAssignmentGroupAction,
  loadStudentAssignmentsAction,
  loadStudentSubjectsAction,
  loadTeacherAssignmentDetailAction,
  loadTeacherAssignmentsAction,
  renameAssignmentGroupAction,
  resetAssignmentGroupsAction,
  resetStudentAssignmentsAction,
  resetTeacherAssignmentsAction,
  submitStudentAssignmentAction,
  updateStudentAssignmentScoreAction,
  deleteAssignmentGroupAction,
  updateAssignmentDeadlineAction,
  assignmentResubmissionAction,
} from './actions';
import {
  assignmentDetailsType,
  assignmentGroupsType,
  ClassAssignmentsType,
  paginatedStudentAssignmentDetailType,
  studentSubjectType,
} from './types';

export type assignmentsActionType = ActionType<typeof actions>;

const initialStates = {
  assignmentDetails: {
    deadline: '',
    isAttachable: false,
    isScorable: false,
    messageDetails: '',
    messageTitle: '',
    responses: undefined,
    subjectName: '',
    totalScore: '',
    weekEndDate: '',
    weekStartDate: '',
    weekNumber: 0,
    attachments: undefined,
    classroom: 0,
    subject: '',
  },
  assignmentGroups: {} as assignmentGroupsType,
};

const data = combineReducers({
  teacherAssignments: createReducer<ClassAssignmentsType, assignmentsActionType>({})
    .handleAction(loadTeacherAssignmentsAction.success, (state, action) => action.payload)
    .handleAction(resetTeacherAssignmentsAction, () => ({})),
  assignmentDetails: createReducer<assignmentDetailsType, assignmentsActionType>(
    initialStates.assignmentDetails,
  )
    .handleAction(loadTeacherAssignmentDetailAction.success, (state, action) => action.payload)
    .handleAction(updateStudentAssignmentScoreAction.success, (state, action) => ({
      ...state,
      [action.payload.groupId ? 'groups' : 'responses']: action.payload.groupId
        ? state?.groups?.map((row) =>
            row?.id === action.payload.groupId
              ? {
                  ...row,
                  groupMembers: row.groupMembers?.map((student) => ({
                    ...student,
                    obtainedMarks: action.payload.obtainedMarks,
                  })),
                }
              : row,
          )
        : state?.responses?.map((row) => (row?.id === action.payload.id ? action.payload : row)),
    }))
    .handleAction(assignmentResubmissionAction.success, (state, action) => ({
      ...state,
      groups: state?.groups?.map((row) =>
        row?.id === action.payload.groupId
          ? {
              ...row,
              groupMembers: row.groupMembers?.map((student) => ({
                ...student,
                isCompleted: false,
              })),
            }
          : row,
      ),
    }))
    .handleAction(updateAssignmentDeadlineAction.success, (state, action) => ({
      ...state,
      ...action.payload,
    })),
  studentSubjects: createReducer<studentSubjectType[], assignmentsActionType>([]).handleAction(
    loadStudentSubjectsAction.success,
    (state, action) => action.payload,
  ),
  studentAssignments: createReducer<paginatedStudentAssignmentDetailType, assignmentsActionType>(
    {} as paginatedStudentAssignmentDetailType,
  )
    .handleAction(loadStudentAssignmentsAction.success, (state, action) => action.payload)
    .handleAction(submitStudentAssignmentAction.success, (state, action) => {
      const updatedStudentAssignments = state.results.map((row) =>
        row.contentAssignment === action.payload.contentAssignment
          ? {
              ...row,
              fileUrl: action.payload?.fileUrl,
              isCompleted: action.payload?.isCompleted,
            }
          : row,
      );

      return { ...state, results: updatedStudentAssignments };
    })
    .handleAction(resetStudentAssignmentsAction, () => {
      return {} as paginatedStudentAssignmentDetailType;
    }),
  assignmentGroups: createReducer<assignmentGroupsType, assignmentsActionType>(
    initialStates.assignmentGroups,
  )
    .handleAction(
      [
        loadAssignmentGroupsAction.success,
        createAssignmentGroupsAction.success,
        updateStudentAssignmentGroupAction.success,
        deleteAssignmentGroupAction.success,
        renameAssignmentGroupAction.success,
      ],
      (state, action) => action.payload,
    )
    .handleAction(loadAssignmentGroupsAction.failure, () => initialStates.assignmentGroups)
    .handleAction(resetAssignmentGroupsAction, () => initialStates.assignmentGroups),
});
const fetching = combineReducers({
  teacherAssignments: createReducer<boolean, assignmentsActionType>(false)
    .handleAction(loadTeacherAssignmentsAction.request, () => true)
    .handleAction(
      [loadTeacherAssignmentsAction.success, loadTeacherAssignmentsAction.failure],
      () => false,
    ),
  assignmentDetails: createReducer<boolean, assignmentsActionType>(false)
    .handleAction(loadTeacherAssignmentDetailAction.request, () => true)
    .handleAction(
      [loadTeacherAssignmentDetailAction.success, loadTeacherAssignmentDetailAction.failure],
      () => false,
    ),
  studentSubjects: createReducer<boolean, assignmentsActionType>(false)
    .handleAction(loadStudentSubjectsAction.request, () => true)
    .handleAction(
      [loadStudentSubjectsAction.success, loadStudentSubjectsAction.failure],
      () => false,
    ),
  studentAssignments: createReducer<boolean, assignmentsActionType>(false)
    .handleAction(loadStudentAssignmentsAction.request, () => true)
    .handleAction(
      [loadStudentAssignmentsAction.success, loadStudentAssignmentsAction.failure],
      () => false,
    ),
  assignmentGroups: createReducer<boolean, assignmentsActionType>(false)
    .handleAction(loadAssignmentGroupsAction.request, () => true)
    .handleAction(
      [loadAssignmentGroupsAction.success, loadAssignmentGroupsAction.failure],
      () => false,
    ),
});
const errors = combineReducers({
  teacherAssignments: createReducer<string, assignmentsActionType>('')
    .handleAction(loadTeacherAssignmentsAction.failure, (state, action) => action.payload)
    .handleAction(loadTeacherAssignmentsAction.success, () => ''),
  assignmentDetails: createReducer<string, assignmentsActionType>('')
    .handleAction(loadTeacherAssignmentDetailAction.failure, (state, action) => action.payload)
    .handleAction(loadTeacherAssignmentDetailAction.success, () => ''),
  studentSubjects: createReducer<string, assignmentsActionType>('')
    .handleAction(loadStudentSubjectsAction.failure, (state, action) => action.payload)
    .handleAction(loadStudentSubjectsAction.success, () => ''),
  studentAssignments: createReducer<string, assignmentsActionType>('')
    .handleAction(loadStudentAssignmentsAction.failure, (state, action) => action.payload)
    .handleAction(loadStudentAssignmentsAction.success, () => ''),
  assignmentGroups: createReducer<string, assignmentsActionType>('')
    .handleAction(loadAssignmentGroupsAction.failure, (state, action) => action.payload)
    .handleAction(loadAssignmentGroupsAction.success, () => ''),
});

const assignmentsReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type AssignmentsStateType = StateType<typeof assignmentsReducer>;

export default assignmentsReducer;
