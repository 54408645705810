/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FunctionComponent, useEffect, useMemo, useState, createContext } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { getTourIsLoading, getTourData } from 'store/tourManagement/selectors';
import {
  loadTourAction,
  resetTourAction,
  setWatchedTourAction,
} from 'store/tourManagement/actions';
import { IconCommon } from 'lib';

import { ROUTES } from 'constants/routes';
import { ENABLE_TOURS } from 'constants/settings';

import { userTypeEnum, gradeTypeEnum } from './constants';
import styles from './TourManagement.module.scss';
import { getCurrentPageSteps } from './utils';

export const TourContext = createContext({
  isExist: false,
  isTourOpen: false,
  handleTourOpen: (): void => {},
});

type TourManagementType = {
  userType?: string;
  gradeType?: string;
  children?: any;
};

const TourManagement: FunctionComponent<TourManagementType> = ({
  userType,
  gradeType,
  children,
}) => {
  const [isTourOpen, handleTourModel] = useState(false);
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const tourData = useSelector(getTourData);
  const isLoading = useSelector(getTourIsLoading);
  const [stepIndex, setStepIndex] = useState(0);
  const { pathname } = useLocation();

  const route = Object.entries(ROUTES).find(([, value]) => {
    const match = matchPath(pathname, {
      path: value,
      exact: true,
    });
    return !!match?.isExact;
  });
  const pageSlug = route && route[0];

  const { id, isWatched, steps } = useMemo(
    () => !isLoading && getCurrentPageSteps(tourData, locale),
    [tourData, locale, isLoading],
  );

  useEffect(() => {
    if (ENABLE_TOURS) {
      dispatch(
        loadTourAction.request({
          page: pageSlug,
          userType,
          ...(gradeType && { gradeType }),
          callback: ({ isWatchedInAllowedHours }) => handleTourModel(isWatchedInAllowedHours),
        }),
      );
    }
    return () => {
      dispatch(resetTourAction());
    };
  }, [dispatch, pageSlug, userType, gradeType]);

  useEffect(() => {
    if (id && !isLoading && !isWatched) {
      handleTourModel(true);
      dispatch(setWatchedTourAction.request({ tour: id }));
    }
  }, [dispatch, id, isLoading, isWatched]);

  useEffect(() => {
    if (isTourOpen) {
      document.documentElement.style.scrollBehavior = 'inherit';
    } else {
      document.documentElement.style.scrollBehavior = 'smooth';
    }
  }, [isTourOpen]);

  const isElementVisible = (componentId: string): boolean => {
    const elm = document.getElementById(componentId);
    const style = elm && window.getComputedStyle(elm);
    const isVisible = style?.visibility !== 'hidden' || style?.display === 'none';
    if (isVisible) elm?.scrollIntoView();
    return isVisible;
  };

  const handleTourClose = (): void => {
    handleTourModel(false);
  };

  const handleStep = (step: number): void => {
    let index = step;
    let currentStep = steps[index];
    while (!isElementVisible(currentStep?.componentId) && index < steps?.length && index > 0) {
      index = step < stepIndex ? index - 1 : index + 1;
      currentStep = steps[index];
    }
    if (index < steps?.length) {
      setStepIndex(index);
    }
  };

  const getTourClassName = (userType: string, gradeType: string) => {
    if (
      userType !== userTypeEnum.STUDENT ||
      (userType === userTypeEnum.STUDENT && gradeType === gradeTypeEnum.UPPER_GRADES)
    ) {
      return styles.tour;
    }
    return styles.tourDark;
  };

  const disableScroll = (target: HTMLElement | Element): void => disableBodyScroll(target);
  const enableScroll = (target: HTMLElement | Element): void => enableBodyScroll(target);

  const tourContextValue = useMemo(
    () => ({
      isExist: !isLoading && steps?.length > 0,
      isTourOpen,
      handleTourOpen: () => handleTourModel(true),
    }),
    [isLoading, steps, isTourOpen, handleTourModel],
  );

  return (
    <TourContext.Provider value={tourContextValue}>
      {!isLoading && steps?.length > 0 && (
        <Tour
          className={getTourClassName(userType as string, gradeType as string)}
          maskClassName={styles.tour__backdrop}
          prevButton={<IconCommon className="icon-chevron-medium" />}
          nextButton={<IconCommon className="icon-chevron-medium-right" />}
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={(): void => handleTourClose()}
          showNumber={false}
          goToStep={stepIndex}
          getCurrentStep={handleStep}
          onAfterOpen={disableScroll}
          onBeforeClose={enableScroll}
          disableInteraction
        />
      )}
      {children}
    </TourContext.Provider>
  );
};

export default TourManagement;
