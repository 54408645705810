import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  loadTeacherProfile,
  resetTeacherAction,
  deleteTeacherAction,
  updateTeacherAction,
} from './actions';
import { addTeacherAction } from '../schoolTeacher/actions';
import { SchoolTeacherActionType } from '../schoolTeacher/reducer';
import { TeacherInfoType, TeacherProfileErrorsType } from './types';

export type ProfileActionType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, ProfileActionType>(true)
  .handleAction(
    [loadTeacherProfile.request, resetTeacherAction, deleteTeacherAction.request],
    () => true,
  )
  .handleAction([loadTeacherProfile.success, loadTeacherProfile.failure], () => false)
  .handleAction([deleteTeacherAction.success, deleteTeacherAction.failure], () => false);

const isTeacherUpdateLoading = createReducer<boolean, ProfileActionType | SchoolTeacherActionType>(
  false,
)
  .handleAction([updateTeacherAction.request, addTeacherAction.request], () => true)
  .handleAction(
    [
      updateTeacherAction.success,
      updateTeacherAction.failure,
      addTeacherAction.success,
      addTeacherAction.failure,
    ],
    () => false,
  );

const data = createReducer<TeacherInfoType, ProfileActionType>({} as TeacherInfoType)
  .handleAction(
    [updateTeacherAction.success, loadTeacherProfile.success],
    (state, action) => action.payload,
  )
  .handleAction(resetTeacherAction, () => {
    return {} as TeacherInfoType;
  });

const errors = createReducer<TeacherProfileErrorsType, ProfileActionType>({})
  .handleAction(loadTeacherProfile.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(deleteTeacherAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(deleteTeacherAction.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(loadTeacherProfile.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(resetTeacherAction, () => ({}));

const teacherProfileReduce = combineReducers({
  isLoading,
  data,
  errors,
  isTeacherUpdateLoading,
});
export type teacherProfileStateType = StateType<typeof teacherProfileReduce>;
export default teacherProfileReduce;
