import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { API_RESPONSE_TYPES } from 'constants/common';
import {
  createExamQuestionAction,
  fetchTeacherExamDetailAction,
  fetchTeacherExamsAction,
  fetchStudentExamsListAction,
  fetchLessonOptionsAction,
  createNewExamAction,
  fetchExamsClassesListAction,
  fetchExamsSubjects,
  publishExamAction,
  examsLearningObjectivesAction,
  fetchStudentResultsAction,
  fetchStudentExamAction,
  ExitStudentExamAction,
  fetchStudentCurrentExamAction,
  fetchTeacherExamProblemsAction,
  removeExamProblemAction,
  fetchExamProblemAction,
  updateExamQuestionAction,
} from './actions';
import {
  getTeacherExams,
  getStudentExamList,
  getTeacherExamDetail,
  createExamQuestion,
  getExamLessons,
  createNewExam,
  getExamsClasses,
  getExamsSubjects,
  publishExam,
  fetchLearningObjectives,
  fetchStudentResults,
  getStudentExam,
  ExitStudentExam,
  getCurrentStudentExam,
  getTeacherExamProblems,
  removeTeacherExamProblem,
  getExamProblem,
  updateTeacherExamQuestion,
} from './api';

function* fetchTeacherGrades({
  payload,
}: ReturnType<typeof fetchTeacherExamsAction.request>): SagaIterator<void> {
  try {
    const examsList = yield call(getTeacherExams, payload?.pageSize, payload?.pageIndex);
    yield put(fetchTeacherExamsAction.success(examsList));
  } catch (error) {
    yield put(fetchTeacherExamsAction.failure(error));
  }
}

function* getTeacherExamDetailSaga({
  payload: examId,
}: ReturnType<typeof fetchTeacherExamDetailAction.request>): SagaIterator<void> {
  try {
    const examDetail = yield call(getTeacherExamDetail, examId);
    yield put(fetchTeacherExamDetailAction.success(examDetail));
  } catch (error) {
    yield put(fetchTeacherExamDetailAction.failure(error));
  }
}

function* createExamQuestionSaga({
  payload,
}: ReturnType<typeof createExamQuestionAction.request>): SagaIterator<void> {
  try {
    const response = yield call(createExamQuestion, payload);
    payload.callback('success');
    yield put(createExamQuestionAction.success(response));
  } catch (error) {
    payload.callback('failure', error);
    yield put(createExamQuestionAction.failure(error));
  }
}

function* fetchExamLessonSaga({
  payload,
}: ReturnType<typeof fetchLessonOptionsAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getExamLessons, payload);
    yield put(fetchLessonOptionsAction.success(response));
  } catch (error) {
    yield put(fetchLessonOptionsAction.failure(error));
  }
}

function* fetchStudentExamList({
  payload,
}: ReturnType<typeof fetchStudentExamsListAction.request>): SagaIterator<void> {
  try {
    const examsList = yield call(getStudentExamList, payload?.isCompleted);
    yield put(fetchStudentExamsListAction.success(examsList));
  } catch (error) {
    yield put(fetchStudentExamsListAction.failure(error));
  }
}

function* createNewExamSaga({
  payload,
}: ReturnType<typeof createNewExamAction.request>): SagaIterator<void> {
  try {
    const examsList = yield call(createNewExam, payload);
    yield put(createNewExamAction.success(examsList));
    payload?.callback('success');
  } catch (error) {
    const [errorMessage] = error?.start_datetime ? error.start_datetime : [error];
    payload?.callback('error', errorMessage);
    yield put(createNewExamAction.failure(errorMessage));
    payload?.setSubmitting?.(false);
  }
}

function* fetchExamsClasses(): SagaIterator<void> {
  try {
    const classes = yield call(getExamsClasses);
    yield put(fetchExamsClassesListAction.success(classes));
  } catch (error) {
    yield put(fetchExamsClassesListAction.failure(error));
  }
}

function* fetchExamsSubjectsSaga({
  payload,
}: ReturnType<typeof fetchExamsSubjects.request>): SagaIterator<void> {
  try {
    const subjects = yield call(getExamsSubjects, payload);
    yield put(fetchExamsSubjects.success(subjects));
  } catch (error) {
    yield put(fetchExamsSubjects.failure(error));
  }
}

function* publishExamSaga({
  payload,
}: ReturnType<typeof publishExamAction.request>): SagaIterator<void> {
  try {
    const publish = yield call(publishExam, payload);
    yield put(publishExamAction.success(publish));
  } catch (error) {
    yield put(publishExamAction.failure(error));
  }
}

function* fetchLearningObjectivesSaga({
  payload,
}: ReturnType<typeof examsLearningObjectivesAction.request>): SagaIterator<void> {
  try {
    const learningObjectives = yield call(fetchLearningObjectives, payload);
    yield put(examsLearningObjectivesAction.success(learningObjectives));
  } catch (error) {
    yield put(examsLearningObjectivesAction.failure(error));
  }
}

function* fetchStudentResultsSaga({
  payload,
}: ReturnType<typeof fetchStudentResultsAction.request>): SagaIterator<void> {
  try {
    const studentResults = yield call(fetchStudentResults, payload);
    yield put(fetchStudentResultsAction.success(studentResults));
  } catch (error) {
    yield put(fetchStudentResultsAction.failure(error));
  }
}

function* studentExamSaga({
  payload,
}: ReturnType<typeof fetchStudentExamAction.request>): SagaIterator<void> {
  try {
    const studentExam = yield call(getStudentExam, payload.examId);
    yield put(fetchStudentExamAction.success(studentExam));
    payload.callback(API_RESPONSE_TYPES.success);
  } catch (error) {
    payload.callback(API_RESPONSE_TYPES.error, error);

    yield put(fetchStudentExamAction.failure(error));
  }
}

function* studentCurrentExamSaga({
  payload,
}: ReturnType<typeof fetchStudentCurrentExamAction.request>): SagaIterator<void> {
  try {
    const studentResults = yield call(getCurrentStudentExam, payload);
    yield put(fetchStudentCurrentExamAction.success(studentResults));
  } catch (error) {
    yield put(fetchStudentCurrentExamAction.failure(error));
  }
}

function* submitStudentExamSaga({
  payload,
}: ReturnType<typeof ExitStudentExamAction.request>): SagaIterator<void> {
  try {
    const studentResults = yield call(ExitStudentExam, payload);
    yield put(ExitStudentExamAction.success(studentResults));
    payload.callback(API_RESPONSE_TYPES.success);
  } catch (error) {
    payload.callback(API_RESPONSE_TYPES.error, error);

    yield put(ExitStudentExamAction.failure(error));
  }
}

function* fetchTeacherExamProblems({
  payload,
}: ReturnType<typeof fetchTeacherExamProblemsAction.request>): SagaIterator<void> {
  try {
    const studentResults = yield call(getTeacherExamProblems, payload);
    yield put(fetchTeacherExamProblemsAction.success(studentResults));
  } catch (error) {
    yield put(fetchTeacherExamProblemsAction.failure(error));
  }
}

function* removeExamProblem({
  payload,
}: ReturnType<typeof removeExamProblemAction.request>): SagaIterator<void> {
  try {
    const removed = yield call(removeTeacherExamProblem, payload?.problemKey);
    payload.callback?.('success');
    yield put(removeExamProblemAction.success(removed));
  } catch (error) {
    payload.callback?.('failure', error?.error);
  }
}

function* fetchExamProblem({
  payload,
}: ReturnType<typeof fetchExamProblemAction.request>): SagaIterator<void> {
  try {
    const problem = yield call(getExamProblem, payload);
    yield put(fetchExamProblemAction.success(problem));
  } catch (error) {
    yield put(fetchExamProblemAction.failure(error));
  }
}

function* updateExamQuestion({
  payload,
}: ReturnType<typeof updateExamQuestionAction.request>): SagaIterator<void> {
  try {
    yield call(updateTeacherExamQuestion, payload);
    payload.callback('success');
  } catch (error) {
    payload.callback('failure', error);
  }
}

function* reportsSaga(): SagaIterator {
  yield all([
    takeEvery(fetchTeacherExamsAction.request, fetchTeacherGrades),
    takeEvery(fetchTeacherExamDetailAction.request, getTeacherExamDetailSaga),
    takeEvery(createExamQuestionAction.request, createExamQuestionSaga),
    takeEvery(fetchStudentExamsListAction.request, fetchStudentExamList),
    takeEvery(fetchLessonOptionsAction.request, fetchExamLessonSaga),
    takeEvery(createNewExamAction.request, createNewExamSaga),
    takeEvery(fetchExamsClassesListAction.request, fetchExamsClasses),
    takeEvery(fetchExamsSubjects.request, fetchExamsSubjectsSaga),
    takeEvery(publishExamAction.request, publishExamSaga),
    takeEvery(examsLearningObjectivesAction.request, fetchLearningObjectivesSaga),
    takeEvery(fetchStudentResultsAction.request, fetchStudentResultsSaga),
    takeEvery(fetchStudentExamAction.request, studentExamSaga),
    takeEvery(fetchStudentCurrentExamAction.request, studentCurrentExamSaga),
    takeEvery(ExitStudentExamAction.request, submitStudentExamSaga),
    takeEvery(fetchTeacherExamProblemsAction.request, fetchTeacherExamProblems),
    takeEvery(removeExamProblemAction.request, removeExamProblem),
    takeEvery(fetchExamProblemAction.request, fetchExamProblem),
    takeEvery(updateExamQuestionAction.request, updateExamQuestion),
  ]);
}

export default reportsSaga;
