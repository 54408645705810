import { CourseChartData } from 'components/Dashboard/CoursesProgress/type';
import { messages } from 'components/Reports/Reports.messages';

import { getIntl } from './intl';

export const testArabicRegex = /[\u0600-\u06FF]|[\u0750-\u077F]|[\uFB50-\uFDFF]|[\uFE70-\uFEFF]/;

export const alphaNumericRegex = /^[A-Za-z0-9]+$/;

export const numericRegex = /^[0-9]+$/;

const englishRegex = /[a-zA-Z]/;

type LabelLocalType = {
  ar: string;
  en: string;
};

export const getGradeLabel = (value: number) => `reports_grade_${value}`;

const getLocals = (splitLabels: LabelLocalType, isLanguageArabic: boolean): string => {
  return isLanguageArabic ? splitLabels.ar : splitLabels.en;
};

const splitFormattedLabels = (label: string): LabelLocalType => {
  let labelLocals: LabelLocalType = { ar: '', en: '' };

  if (label?.includes('|')) {
    const [eng, arabic] = label.split('|').map((val) => val.trim());
    const arabicUpdated = testArabicRegex.test(arabic) ? arabic : eng;
    const engUpdated = englishRegex.test(eng) ? eng : arabic;
    labelLocals = { ar: arabicUpdated, en: engUpdated };
  } else {
    labelLocals = { ar: label, en: label };
  }

  return labelLocals;
};

export const isDualLingualLabel = (label: string): boolean =>
  label.includes('|') && testArabicRegex.test(label) && englishRegex.test(label);

export const reverseArabicText = (label: string, isArabic: boolean): string => {
  return !isArabic ? label : label.split(' ').reverse().join(' ');
};

export const getFormattedLabel = (
  label: string | undefined = '',
  isLanguageArabic: boolean,
): string => {
  const formattedLabels = splitFormattedLabels(label);
  return getLocals(formattedLabels, isLanguageArabic);
};

export const getFormattedChartsData = (
  data: CourseChartData,
  isLanguageArabic: boolean,
): CourseChartData => {
  const { labels, datasets } = data;

  const formattedLabels = labels.map((label: string) => getFormattedLabel(label, isLanguageArabic));
  return {
    datasets,
    labels: formattedLabels || [],
  };
};

export const getClassSpanDir = (section: string, isArabic: boolean, grade?: string): string => {
  let dir = 'ltr';
  if (alphaNumericRegex.test(section) && isArabic) dir = 'rtl';
  if (grade && numericRegex.test(grade) && isArabic) dir = 'rtl';

  return dir;
};

export const getFormattedGradeLabel = (grade: string | number = ''): string => {
  const { formatMessage } = getIntl();
  let classroomLabel = messages[getGradeLabel(+grade)];
  classroomLabel = classroomLabel ? `${formatMessage(classroomLabel)}` : `${grade}`;

  return classroomLabel;
};

export const getClassroomWithSectionLabel = (grade: string, section: string, isArabic: boolean) => {
  const classroomLabel = getFormattedGradeLabel(grade);
  const dir = getClassSpanDir(section, isArabic);
  return dir === 'ltr' ? `${classroomLabel} ${section}` : `${section} ${classroomLabel}`;
};
