export const ROUTES = {
  managementLogin: '/management-login',
  selectBranch: '/select-branch',
  login: '/login',
  loginOtp: '/login/otp',
  forgotPassword: '/forgot-password',
  forgotB2BPassword: '/forgot-b2b-password',
  setPassword: '/set-password/:token/:email',
  confirmEmail: '/email-confirm/:token',
  registration: '/registration',
  activate: '/activate/:token',
  SSO: '/sso/:provider/:type',
  successPayment: '/subscriptions/success-payment',
  successPaymentUsername: '/subscriptions/:username/success-payment',
  subscriptions: '/subscriptions',
  recommendation: '/recommendation',
  subscriptionsUsername: '/subscriptions/:username',
  account: '/account',
  branchDetail: '/branch-detail',
  teachers: '/teachers',
  calendar: '/calendar',
  gradeMapping: '/grade-mapping',
  gradeReport: '/grade-report',
  students: '/students',
  qiyasExam: '/qiyas/exam/:id',
  qiyasSummary: '/qiyas/summary/',
  addingSubject: '/learning-path/adding',
  lesson: '/learning-path/:subjectId/:eventId',
  units: '/learning-path/semester/:semesterId/subject/:subjectId',
  rewards: '/rewards',
  semesterSchedule: '/semester-schedule',
  semesterScheduleDetail: '/semester-schedule-detail/:classId/:weekNumber',
  assignments: '/assignments',
  createAssignments: '/assignments/create',
  assignmentGroupSettings: '/assignments/create/group-settings',
  assignmentDetail: '/assignments/:assignmentId',
  adminDashBoard: '/school-admin-dashboard',
  learningPath: '/learning-path',
  studentLiveQuestions: '/student-questions/:eventId/student/:studentId/event/:eventName',
  teacherLiveQuestions: '/teacher-questions',
  teacherSessionQAs: '/teacher-questions/details',
  teacherQAs: '/teacher-questions/:subjectId/questions-answers',
  questionBankSubjects: '/question-banks',
  questionBankCreateNewQuestion: '/question-banks/questions/create',
  questionBankQuestionsDetail:
    '/question-banks/classes/:classId/courses/:courseId/units/:unitId/lessons/:lessonId',
  questionBankLessons: '/question-banks/:subjectId/lessons/:lessonKey',
  questionBankUnits: '/question-banks/units/:unitKey',
  questionBankQuiz:
    '/question-banks/:subjectId/:lessonKey/:learningObjectTitle/:learningObjectId/quiz',
  contentLibrary: '/content-library',
  lessonPreview: '/content-library/:lessonId',
  schedule: '/schedule',
  eventDetail: '/schedule/:eventId',
  help: '/help',
  exercises: '/exercises',
  root: '/',
  childDashboard: '/child-dashboard/:childUsername',
  inviteChild: '?register-flow=addChild',
  semesterSubjects: '/semester-subjects',
  groupChat: '/group-chat',
  Chat: '/chat',
  roleSelection: '/role-selection',
  paymentDetails: '/payment-details',
  reports: '/reports',
  preparation: '/preparation-material',
  selfReport: '/self-reports',
  reportsGradeDetail: '/reports/grades/:grade/:gradeId',
  teacherStudentReport: '/reports/students/:studentId',
  adminStudentReport: '/admin-reports/students/:studentId',
  childrenReports: '/child-reports/:childId',
  b2bTeacherClassDetail:
    '/reports/grades/:grade/section/:section/gradeId/:gradeId/classId/:classId/',
  liveSessionsSchedule: '/live-sessions-schedule',
  adminReports: '/admin-reports',
  classroomDetails: '/admin-reports/classroom-details',
  studentClassroomDetails: '/admin-reports/student-progress-details',
  flexibleSchedule: '/flexible-schedule',
  teacherScheduleList: '/teacher-schedule',
  teacherScheduleWeekly: '/teacher-schedule/weekly',
  teacherScheduleSubjects: '/teacher-schedule/:teacherId/subjects',
  flexibleScheduleWeekly: '/flexible-schedule/week',
  teacherExams: '/exams/list',
  timetable: '/timetable',
  gradeSchedule: '/grade-wise-timetable',
  classTimetable: '/grade-wise-timetable/:gradeId/:branch',
  studentExams: '/exams',
  studentExamAttempt: '/exams/attempt/:examId',
  createNewExam: '/exams/create',
  examDetail: '/exams/:examId',
  teacherExamsResults: '/exams/:examId/results',
  examResult: '/exams/result/:examId',
  quizExam: '/quiz',
  alertLogDetails: '/admin-reports/alert-logs-details',
  games: '/games',
  activities: '/activities',
  lab: '/lab',
  promotion: '/promotion',
  weeklyPlan: '/weekly-plan',
  childTeachers: '/child-teachers/:childUsername/:childId',
  childTeacherDetail: '/child-teachers/:childUsername/:childId/teachers/:teacherId/detail',
  parentQuestions: '/parent-questions',
  childDetail: '/parent-questions/children/:childId',
  nativeEvents: '/native-events',
  nativeEventsGradeSubjects: '/native-events/grade/:gradeId',
  nativeEventsQuestionsList: '/native-events/questions/:gradeId/:lessonUsageKey',
  overdueLessons: '/lessons/overdue-lessons',
  additionalLessons: '/lessons/additional-lessons',
  currentWeekLessons: '/lessons/current-week-lessons',
  notFound: '/not-found',
  qiyas: '/qiyas',
  qiyasCreateExam: '/qiyas/create',
  qiyasExams: '/qiyas/exams/:examID',
  learningObjectives: '/learning-objectives',
};
