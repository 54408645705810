import { createAsyncAction } from 'typesafe-actions';
import { BulkImportType } from './types';

export const bulkImportTeachers = createAsyncAction(
  '@@bulkImport/CREATE_IMPORT_REQUEST',
  '@@bulkImport/CREATE_IMPORT_SUCCESS',
  '@@bulkImport/CREATE_IMPORT_FAILURE',
)<any, BulkImportType, string>();

export const bulkImportStudents = createAsyncAction(
  '@@bulkImport/CREATE_IMPORT_STUDENTS_REQUEST',
  '@@bulkImport/CREATE_IMPORT_STUDENTS_SUCCESS',
  '@@bulkImport/CREATE_IMPORT_STUDENTS_FAILURE',
)<any, BulkImportType, string>();
