import { TypeOptions } from 'react-toastify';

import { GenericCallbackType } from 'utils/types';

import { OtpSendRequestType } from '../registration/types';
import { SubscriptionServiceType } from '../subscriptions/types';

export enum PaymentMethodType {
  TRIAL = 0,
  SEMESTER = 1,
  YEARLY = 2,
  MONTHLY = 3,
}

export enum Role {
  student = 0,
  mentor = 1,
  parent = 2,
  alwTeacher = 3,
  teacherAndMentor = 4,
  contentAuthor = 5,
}

export enum SchoolRole {
  schoolAdmin = 0,
  schoolBranchManager = 1,
  schoolStudent = 2,
  schoolTeacher = 3,
}

export enum summerRole {
  Student = 0,
  Teacher = 1,
}

export enum AdminRole {
  alwAdmin = 0,
}

export enum Gender {
  male = 'm',
  female = 'f',
  other = 'o',
  maleValue = 'Male',
}

export enum semesterSelectionGrades {
  one = 1,
  two = 2,
  three = 3,
  ten = 10,
  eleven = 11,
  twelve = 12,
}

export type formatMessagesType = {
  id: string;
  defaultMessage: string;
};

export type MeType = {
  username: string;
  isArchived: boolean;
};

export type LoginType = {
  email: string;
  password: string;
  loginPage?: string | any;
  callback?: (isSuccess: boolean) => void;
};

export type LoginOtpType = {
  email: string;
  signature: string;
  otp: string;
  loginPage: string;
  callback?: GenericCallbackType;
};

export type ResetPasswordType = {
  email: string;
};

export type SetPasswordType = {
  password1: any;
  password2: any;
  token: string;
  email: string;
  redirectURL?: any;
  callback?: any;
};

export type AccountAvatarType = {
  large: string;
  small: string;
};

export type ProfileType = {
  firstName: string;
  lastName: string;
  username: string;
  avatar: AccountAvatarType;
  mentor?: ProfileType | null;
  label?: string;
  email: string;
  grade: string;
  gender: Gender | null;
};

export enum InvitationStatus {
  initial = 'initial',
  sent = 'sent',
  registered = 'registered',
}

export type ParentInvitationType = {
  status: InvitationStatus;
  email: string;
};

export type AccountSemesterType = {
  academicYearId: number;
  academicYearTitle: string;
  endDate: string;
  enrollmentsCount: number;
  id: number;
  isCurrent: boolean;
  name: string;
  startDate: string;
};

export type StudentSubject = {
  subjectId: string;
  subjectDisplayName: string;
};

export type callbackType = (
  val: TypeOptions,
  isCardUpdated?: boolean,
  errorMessage?: string,
) => void;

export type CommonCardTypes = {
  expirationMonth: string;
  expirationYear: string;
  cardHolderName: string;
};

export type CardType = CommonCardTypes & {
  paymentBrand: string;
  cardLastFourDigits: string;
  cardBrandLogoUrl?: string;
  cardBrandName?: string;
};

export type RecurringSubscriptionType = {
  basePrice: number;
  monthlyRecurringSubscriptionAmount: number;
  vat: number;
};

export type AccountType = {
  id: number;
  username: string;
  plainPassword?: string;
  name: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  avatar: AccountAvatarType;
  digitalTutor: boolean;
  gender: Gender | null;
  email: string;
  sitepalMaxAnswerLength: Number;
  profileImage?: any;
  role: Role;
  isAlwTeacherMentor?: boolean;
  schoolRole: SchoolRole;
  referralLink?: string;
  mentor: ProfileType | null;
  rating: number | null;
  teacherRating: number | null;
  showSubscriptionBanner: boolean | null;
  students: ProfileType[] | null;
  children: ProfileType[] | null;
  parent: ProfileType | null;
  subjects: StudentSubject[];
  phone: {
    code: string | null;
    number: string | null;
  };
  semester: AccountSemesterType;
  allMentors?: Array<ProfileType> | null;
  parentInvitation: ParentInvitationType | null;
  summerSchoolSubscription?: Array<any>;
  summerSchoolRole: number;
  subjectsEnrollmentCount: number;
  grade: subjectsGradeType;
  isArchived?: boolean;
  callback?: Function;
  isSubscriptionAutoRenewal?: boolean;
  lastSubscriptionType?: PaymentMethodType;
  lastPaymentMethod?: CardTypeId;
  showCurrentSemesterSubscriptionBanner?: boolean;
  isVoucherActive: boolean;
  cardInformation: CardType;
  recurringSubscription: RecurringSubscriptionType;
  adminRole: AdminRole;
  userLoginCount: number;
  subscribedServices: SubscriptionServiceType[];
  school: { logo: string };
  birthPlace: string;
  nationalId: string;
  nationality: string;
  passportNo: string;
  phoneNumber: string | null;
  secureExamSeconds: number;
  enableSecureExam: boolean;
  hasQiyasAccess: boolean;
};

export type subjectsGradeType = {
  id: number;
  label: string;
  maximumAllowedSubjects: number;
  minimumAllowedSubjects: number;
  gradeValue: number;
};

export type SetPasswordResponseType = {
  value: string;
  success: boolean;
};

export type ResetPasswordResponseType = {
  redirectLoginUrl: string;
};

export type ResetPasswordPayloadType = {
  email: string;
  callback: Function;
};

export type ForgetPasswordPayloadType = {
  new_password1: string;
  new_password2: string;
  callback: Function;
};

export type AccountFieldTypes = {
  updatedAccountEmail?: string;
  isFormEdited?: boolean;
  isFormUpdated?: boolean;
};

export type CountryListType = {
  code: number;
  example_number: string;
  isDefault: boolean;
  isoCode: string;
  maxLimit: number;
  minLimit: number;
  nameAR: string;
  nameEN: string;
};

export type UpdateAccountType = { callback?: Function } | AccountType;

export type UploadAvatarResponseErrorType = {
  developer_message: string;
  user_message: string;
};

export type UploadAvatarPayloadType = {
  file: File;
  callback: (responseType: string, error?: UploadAvatarResponseErrorType) => void;
};

export type StepsType = {
  message: formatMessagesType;
  completed: boolean;
  active: boolean;
  hidden?: boolean;
};

type CallbackType<T> = {
  isSuccess: boolean;
  data?: T;
  error?: string;
};

export type GenerateOtpResponseType = {
  signature: string;
  resendOtpTime: number;
};

export type GenerateOtpRequestType = {
  phone: string;
  callback: (response: CallbackType<GenerateOtpResponseType>) => void;
};

export type UserObjectType = {
  username: string;
  firstName: string;
  lastName: string;
  role: number;
  plainPassword: string;
  email: string;
  profileImage: {
    imageUrlSmall: string;
    imageUrlMedium: string;
    imageUrlLarge: string;
  };
};

export type OtpVerifyResponseType = {
  isOtpVerified: boolean;
  usersInformation: UserObjectType[];
};

type OtpCommonValueTypes = {
  signature: string;
  otp: string;
};

export type OtpPayloadType = Partial<OtpSendRequestType> & OtpCommonValueTypes;

export type OtpGenerateRequestType = Partial<Pick<AccountType, 'phone' | 'email'>>;

export type OtpVerifyPayloadType = OtpGenerateRequestType &
  OtpCommonValueTypes & {
    callback: (isSuccess: boolean) => void;
  };

export type OTPDataType = GenerateOtpResponseType & OtpPayloadType;

export type OtpVerifyRequestType = Required<Omit<OTPDataType, 'resendOtpTime' | 'email'>> & {
  callback: (response: CallbackType<OtpVerifyResponseType>) => void;
};

export type AccountOTPType = {
  otpData: OTPDataType;
  isOtpVerified: boolean;
};

export enum CardTypeId {
  VISA,
  MASTER,
  MADA,
  STC_PAY,
}
