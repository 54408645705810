import { RecordingsType } from 'store/contentLibrary/types';
import { LiveClassRecommendationItem } from 'store/reports/types';
import { SubjectType } from '../subjects/types';

export enum ScheduleType {
  OneOnOne = 0,
  LiveClass = 1,
  OneToMany = 5,
  LiveSession = 6,
}

export enum ScheduleSource {
  b2b = 1,
  alw = 0,
}

export enum MeetingType {
  ZoomLinkInRequest = 0,
  ZoomSimpleMeeting = 1,
  ZoomWebinarMeeting = 2,
}

export type CellResultsRow = {
  original: ScheduleTableType;
};

export type CellResultsType = {
  row: CellResultsRow;
  value: number;
};

export type ScheduleTableType = {
  subject?: string;
  sessionTime?: string;
  startDate?: string | Date;
  endDate?: string | Date;
  trainerName?: string;
  id?: number;
  weekNumber?: string;
  description?: string;
  sessionLink?: string;
  sessionType?: number | string;
};

export type CreateSearchParamsType = {
  [key: string]: string | undefined;
};

export type TeacherFormOptionType = {
  firstName: string;
  lastName: string;
  id: string;
  email: string;
};

export type SubjectFormOptionType = {
  value: string;
  label: string;
};

export type ClassFormOptionType = {
  value: string;
  label: string;
};

export type ScheduleFormOptionsType = {
  classrooms: ClassFormOptionType[];
  subjects: SubjectFormOptionType[];
  teachers: TeacherFormOptionType[];
  branch: number;
};

export type classes = {
  id: number;
  name: string;
};

export type BMSchedulePayload = {
  branchId: number;
  listView: boolean;
  subject?: string;
  classroom?: string;
  type?: number;
  teacher?: string;
};

export type BMTableSchedulePayload = {
  branchId: number;
  listView: boolean;
  page: number;
  pageSize: number;
  subject?: string;
  classroom?: string;
  type?: number;
  teacher?: string;
};

export type AddCommentPayload = {
  text?: string;
  branchId: number;
  eventId: number;
  callback?: Function;
};

export type Comment = {
  text: string;
  id: number;
  authorName: string;
};

export type ScheduleCalendarType = {
  id: string;
  eventName: string;
  startDate: string;
  endDate: string;
  trainerName: string;
  weekNumber: string;
  eventType: number;
  description: string;
  eventSource: number;
  medium: number;
  classrooms: classes[];
  eventClassrooms: string[];
  subject: SubjectType;
  recordings: RecordingsType[];
};

export type BranchManagerScheduleType = {
  filters: ScheduleFormOptionsType;
  cardData: ScheduleCalendarType[];
  tableData: BranchScheduleTableViewResponseType;
  comments: Comment[];
};

export type ScheduleRequestType = {
  startDate?: Date;
  endDate?: Date;
  listView?: boolean;
  pageIndex?: number;
  pageSize?: number;
};

export type BranchScheduleTableViewResponseType = {
  count: number;
  currentPage: number;
  next: string;
  numPages: number;
  previous: string | null;
  results: Array<ScheduleCalendarType>;
  start: number;
};

type EventCommonType = {
  eventType: ScheduleType;
  eventName?: string;
  description?: string;
  zoomLink: string;
  subject?: null | SubjectType;
  trainer?: string;
  meetingType?: MeetingType;
  trainerName?: string;
  studentLiveQuestions?: any;
};

export type ScheduleEventType = EventCommonType & {
  id: number;
  eventSource: ScheduleSource;
  students: string[];
  recordingLink: string;
  weekNumber: string;
  startDate: Date;
  endDate: Date;
  recordings?: RecordingsType[] | null;
  isOverflowStudent?: boolean;
  eventType?: number;
  trainerName?: string;
  difference?: any;
  studentLiveQuestions?: any;
};
export type DeleteAttachmentType = {
  eventId: number;
  attachmentId?: number;
  callback: (responseType: string, error?: string) => void;
};

export type ScheduleEventResponseType = EventCommonType & {
  id: number;
  eventSource: ScheduleSource;
  students: string[];
  recordingLink: string;
  weekNumber: string;
  startDate: string;
  endDate: string;
};

export type SessionQAEventWithNameType = {
  eventName: string;
  events: TeacherSessionQAEventType[];
};

export type CreateMeetingType = EventCommonType & {
  startDate: Date;
  endDate: Date;
  eventSource?: number;
  grade?: number;
  students?: string[];
  classrooms?: number[];
  checkScheduleClash: boolean;
  recommendation?: string | null;
  isInPersonClass?: boolean;
};

export type CreateEventRequestType = {
  schedule: CreateMeetingType;
  callback: (type?: string, error?: string) => void;
};

export type TableViewResponseType = {
  count: number;
  currentPage: number;
  next: string;
  numPages: number;
  previous: string | null;
  results: Array<ScheduleEventType>;
  start: number;
};

export type CalendarEventsDataType = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  backgroundColor: string;
  eventSource: string;
  eventName?: string;
  classNames: Array<string>;
  isHasLinkRecording: boolean;
  isLiveClass: boolean;
  isMentor: boolean;
};

type classroomType = { name: string; id: number };

export type LessonType = { id: string; name: string };

type AttachmentType = {
  id: number;
  attachment: string; // unused - exist in API
  fileName: string;
  fileUrl: string;
};

export type AttachmentsInfo = {
  book: {
    id: number;
    book: string; // unused - exist in API
    bookUrl: string;
    name: string;
  } | null;
  startPage: number | null;
  endPage: number | null;
  attachments: AttachmentType[];
  lessons: LessonType[];
};

type EventDetailCommonType = Omit<EventCommonType, 'trainer'> & {
  id: number;
  grade: string;
  classrooms: Array<classroomType>;
  zoomMeetingId: string;
  zoomMeetingType: number;
  zoomLimit: number;
  zoomLink: string;
  rating?: number;
  recordings?: Array<RecordingsType> | undefined;
  attachmentsInfo?: AttachmentsInfo | null;
  lastWeekLessons?: LessonType[] | null;
  isOverflowStudent?: boolean;
  isSessionConducted?: boolean;
  isInPersonClass: boolean;
  student: string | null;
  students: string[] | null;
  trainer: {
    name: string;
    id: number;
    profileImage: {
      imageUrlSmall: string;
      imageUrlMedium: string;
      imageUrlLarge: string;
      imageUrlFull: string;
      hasImage: boolean;
    };
  } | null;
  weekNumber: string;
  eventSource: ScheduleSource;
  noOfInvites?: number;
  liveSessionSlot: number | null;
  rejectionReason?: string;
};

export type EventDetailResponseType = EventDetailCommonType & {
  startDate: string;
  endDate: string;
};

export type EventDetailType = EventDetailCommonType & {
  startDate: Date;
  endDate: Date;
  isOverflowStudent?: boolean;
  weeks?: Array<{ id: number; value: string }>;
};

export type TQuestionFormValues = {
  question: string;
  attachmentQuestion?: File;
};

export type TAnswerFormValues = {
  answer: string;
  attachmentAnswer?: File;
};

export type PostStudentQuestionPayload = TQuestionFormValues & {
  eventId: number;
  studentId: number;
};

export type PostStudentQuestionRequest = PostStudentQuestionPayload & {
  callback?: (isSuccess: boolean) => void;
};

export type UpdateAnswerPayload = TAnswerFormValues & {
  eventId: number;
  questionId: number;
};

export type UpdateAnswerRequest = UpdateAnswerPayload & {
  callback?: (isSuccess: boolean) => void;
};

export type LiveEventType = {
  eventId: number;
  accountId?: number;
  rating?: number;
  studentId?: number | undefined;
  question?: string;
  questionId?: number;
  answer?: string;
  callBack?: () => void;
};

export type TeacherSessionQARequestType = {
  value: string;
};

export type LiveQuestionsAnswersType = {
  studentLiveQuestions?: StudentLiveQuestionsType[];
  teacherSessionQAEvents: TeacherSessionQAEventType[];
};

export type GetStudentLiveQuestionsType = {
  eventId: number | null;
};

export type LiveAnswerType = {
  answer: string;
  attachmentAnswer?: string;
  answeredBy: number;
  id: number;
  modified: string;
  sessionQuestion: number;
};

export type StudentLiveQuestionsType = {
  answer: LiveAnswerType | null;
  askedBy: number;
  askedByName: string;
  created: string;
  id: number;
  liveSession: number;
  modified: string;
  question: string;
  attachmentQuestion?: string;
};

export type TeacherSessionQAEventType = {
  id: number;
  eventName: string;
  startDate: string;
  subjectName: string;
  subjectId: string;
  classrooms: string[];
  unansweredQuestions: number;
  week: string;
  grade?: number;
};

export type LiveScheduleDataType = {
  data?: LiveScheduleReturnType;
};

export type LiveScheduleReturnType = LiveClassRecommendationItem;

export type GetLiveScheduleRequestType = {
  id: number;
  forScheduling?: boolean;
  callback?: (type?: string, response?: ScheduleErrorType) => void;
};

export type ScheduleErrorType = {
  message: string;
};

type JoinMeetingCallbackType = (args: { message: string; joinUrl: string }) => void;

export type JoinMeetingUrlType = { id: number; callback: JoinMeetingCallbackType };

export type scheduleTypes = {
  startDate?: Date;
  endDate?: Date;
  startDateComp?: Date;
  endDateComp?: Date;
  activeStartDateComp?: Date;
  startDateForApi?: Date;
};

type Question = {
  id: number;
  askedBy: number;
  askedByName: string;
  teacher: number;
  subject: Subject;
  answer: any;
  question: string;
  attachment: any;
  created: string;
};

type Subject = {
  course_key: string;
  display_name: string;
};

export type GeneralQuestionAnswerType = {
  count: number;
  currentPage: number;
  next: string;
  numPages: number;
  previous: string | null;
  results: Question[];
  start: number;
};

export type GetStudentGeneralQuestionsType = {
  subjectId: string;
};

export type TGeneralAnswerFormValues = {
  answer: string;
  attachment?: File;
};

export type GeneralAnswerType = {
  id: number;
  answeredBy: number;
  created: string;
  modified: string;
  answer: string;
  attachment?: string | null;
  generalQuestion: number;
};

export type UpdateGeneralAnswerRequest = UpdateGeneralAnswerPayload & {
  callback?: (isSuccess: boolean) => void;
};

export type PostGeneralAnswerRequest = PostGeneralAnswerPayload & {
  callback?: (isSuccess: boolean) => void;
};

export type PostGeneralAnswerPayload = TGeneralAnswerFormValues & {
  questionId: number;
};

export type UpdateGeneralAnswerPayload = TGeneralAnswerFormValues & {
  questionId: number;
  answerId: number;
};
