import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { loadSSOAccountsAction, unlinkSSOAccountAction } from './actions';
import * as actions from './actions';
import { SSOAccountType } from './types';

export type SSOActionType = ActionType<typeof actions>;

const data = createReducer<SSOAccountType[], SSOActionType>([]).handleAction(
  [loadSSOAccountsAction.success],
  (state, action) => action.payload,
);

const fetching = combineReducers({
  load: createReducer<boolean, SSOActionType>(true)
    .handleAction(loadSSOAccountsAction.request, () => true)
    .handleAction([loadSSOAccountsAction.success, loadSSOAccountsAction.failure], () => false),
  unlink: createReducer<boolean, SSOActionType>(false)
    .handleAction(unlinkSSOAccountAction.request, () => true)
    .handleAction([unlinkSSOAccountAction.success, unlinkSSOAccountAction.failure], () => false),
});

const errors = combineReducers({
  load: createReducer<string, SSOActionType>('')
    .handleAction(loadSSOAccountsAction.failure, (state, { payload }) => payload)
    .handleAction(loadSSOAccountsAction.success, () => ''),
  unlink: createReducer<string, SSOActionType>('')
    .handleAction(unlinkSSOAccountAction.failure, (state, { payload }) => payload)
    .handleAction(unlinkSSOAccountAction.success, () => ''),
});

const ssoReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type SSOStateType = StateType<typeof ssoReducer>;

export default ssoReducer;
