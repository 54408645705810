import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import {
  ExamDetailType,
  TeacherExams,
  StudentExamListType,
  examLessonsType,
  ClassesList,
  ExamsSubjects,
  ExamQuestion,
  LearningObjectivesResponse,
  ExamResultResponse,
  PublishExamResponse,
  StudentExamType,
  ExamResultType,
  StudentProblemListType,
  ProblemType,
  StudentProblemType,
} from './types';
import { resetStudentExamAction } from './actions';

type ExamsActionType = ActionType<typeof actions>;
const {
  createExamQuestionAction,
  fetchStudentResultsAction,
  fetchTeacherExamsAction,
  fetchStudentExamsListAction,
  resetTeacherExamsAction,
  fetchLessonOptionsAction,
  fetchExamsClassesListAction,
  fetchExamsSubjects,
  resetExamQuestionAction,
  examsLearningObjectivesAction,
  fetchTeacherExamDetailAction,
  publishExamAction,
  fetchStudentExamAction,
  fetchStudentCurrentExamAction,
  ExitStudentExamAction,
  fetchTeacherExamProblemsAction,
  teacherAddNewQuestionAction,
  resetExamDetailsDataAction,
  removeExamProblemAction,
  fetchExamProblemAction,
  completeExamStatusAction,
} = actions;

const initialState = {
  data: {
    teacherExams: {} as TeacherExams,
    teacherExamDetail: {} as ExamDetailType,
    studentExamsList: [] as StudentExamListType,
    examLessons: [] as examLessonsType,
    teacherClasses: [] as Array<ClassesList>,
    examsSubjects: [] as Array<ExamsSubjects>,
    examsQuestion: {} as ExamQuestion,
    examsLO: [] as Array<LearningObjectivesResponse>,
    studentResults: {} as ExamResultResponse,
    examStatus: {} as PublishExamResponse,
    studentExamData: [] as StudentProblemListType,
    studentCurrentExam: {} as StudentExamType,
    studentExamResult: {} as ExamResultType,
    teacherExamProblems: [] as Array<string>,
    examProblem: {} as StudentProblemType,
  },
  fetching: {
    fetchTeacherExams: false,
    teacherExamDetail: false,
    fetchStudentExamsList: false,
    examLessons: false,
    studentExamData: false,
    studentCurrentExam: false,
    studentExamResult: false,
  },
  errors: {
    errorTeacherExams: '',
    teacherExamDetail: '',
    errorStudentExamsList: '',
    examLessons: '',
    studentExamData: '',
    studentCurrentExam: '',
    studentExamResult: '',
  },
};

const removeProblem = (problems: Array<string>, removedProblem: ProblemType) =>
  problems.filter((problem: string) => problem !== removedProblem?.problemKey);

const changeExamStatusToComplete = (teacherExamsData: TeacherExams, id: number) => {
  const results = teacherExamsData.results.map((result) => {
    if (result.id === id) return { ...result, isEnded: true };
    return result;
  });
  return { ...teacherExamsData, results };
};

const data = combineReducers({
  teacherExams: createReducer<TeacherExams, ExamsActionType>(initialState.data.teacherExams)
    .handleAction([fetchTeacherExamsAction.success], (state, action) => action.payload)
    .handleAction(completeExamStatusAction, (state, action) =>
      changeExamStatusToComplete(state, action.payload),
    )
    .handleAction(resetTeacherExamsAction, () => initialState.data.teacherExams),
  teacherExamDetail: createReducer<ExamDetailType, ExamsActionType>(
    initialState.data.teacherExamDetail,
  )
    .handleAction(fetchTeacherExamDetailAction.success, (state, action) => action.payload)
    .handleAction([resetExamDetailsDataAction], () => initialState.data.teacherExamDetail),
  studentExamsList: createReducer<StudentExamListType, ExamsActionType>(
    initialState.data.studentExamsList,
  ).handleAction([fetchStudentExamsListAction.success], (state, action) => action.payload),
  ExamsLessons: createReducer<examLessonsType, ExamsActionType>(
    initialState.data.examLessons,
  ).handleAction([fetchLessonOptionsAction.success], (state, action) => action.payload),
  teacherClasses: createReducer<Array<ClassesList>, ExamsActionType>(
    initialState.data.teacherClasses,
  ).handleAction([fetchExamsClassesListAction.success], (state, action) => action.payload),
  examsSubjects: createReducer<Array<ExamsSubjects>, ExamsActionType>(
    initialState.data.examsSubjects,
  ).handleAction([fetchExamsSubjects.success], (state, action) => action.payload),
  examsQuestion: createReducer<ExamQuestion, ExamsActionType>(initialState.data.examsQuestion)
    .handleAction([createExamQuestionAction.success], (state, action) => action.payload)
    .handleAction(resetExamQuestionAction, () => initialState.data.examsQuestion),
  examsLO: createReducer<Array<LearningObjectivesResponse>, ExamsActionType>(
    initialState.data.examsLO,
  ).handleAction([examsLearningObjectivesAction.success], (state, action) => action.payload),
  studentResults: createReducer<ExamResultResponse, ExamsActionType>(
    initialState.data.studentResults,
  ).handleAction([fetchStudentResultsAction.success], (state, action) => action.payload),
  examStatus: createReducer<PublishExamResponse, ExamsActionType>(initialState.data.examStatus)
    .handleAction([publishExamAction.success], (state, action) => action.payload)
    .handleAction([resetExamDetailsDataAction], () => initialState.data.examStatus),
  studentExamData: createReducer<StudentProblemListType, ExamsActionType>(
    initialState.data.studentExamData,
  ).handleAction([fetchStudentExamAction.success], (state, action) => action.payload),
  studentCurrentExam: createReducer<StudentExamType, ExamsActionType>(
    initialState.data.studentCurrentExam,
  )
    .handleAction([fetchStudentCurrentExamAction.success], (state, action) => action.payload)
    .handleAction(resetStudentExamAction, () => initialState.data.studentCurrentExam),
  studentExamResult: createReducer<ExamResultType, ExamsActionType>(
    initialState.data.studentExamResult,
  ).handleAction([ExitStudentExamAction.success], (state, action) => action.payload),
  examProblem: createReducer<StudentProblemType, ExamsActionType>(initialState.data.examProblem)
    .handleAction([fetchExamProblemAction.success], (state, action) => action.payload)
    .handleAction(resetExamQuestionAction, () => initialState.data.examProblem),
  teacherExamProblems: createReducer<Array<string>, ExamsActionType>(
    initialState.data.teacherExamProblems,
  )
    .handleAction([fetchTeacherExamProblemsAction.success], (state, action) => action.payload)
    .handleAction([teacherAddNewQuestionAction], (state, action) => [...state, action.payload])
    .handleAction([removeExamProblemAction.success], (state, action) =>
      removeProblem(state, action.payload),
    ),
});

const fetching = combineReducers({
  fetchTeacherExams: createReducer<boolean, ExamsActionType>(
    initialState.fetching.fetchTeacherExams,
  )
    .handleAction(fetchTeacherExamsAction.request, () => true)
    .handleAction([fetchTeacherExamsAction.success, fetchTeacherExamsAction.failure], () => false)
    .handleAction([resetTeacherExamsAction], () => initialState.fetching.fetchTeacherExams),
  teacherExamDetail: createReducer<boolean, ExamsActionType>(
    initialState.fetching.teacherExamDetail,
  )
    .handleAction(fetchTeacherExamDetailAction.request, () => true)
    .handleAction(
      [fetchTeacherExamDetailAction.success, fetchTeacherExamDetailAction.failure],
      () => false,
    ),
  studentExamResult: createReducer<boolean, ExamsActionType>(
    initialState.fetching.studentExamResult,
  )
    .handleAction(ExitStudentExamAction.request, () => true)
    .handleAction([ExitStudentExamAction.success, ExitStudentExamAction.failure], () => false),
  examLessons: createReducer<boolean, ExamsActionType>(initialState.fetching.teacherExamDetail)
    .handleAction(fetchTeacherExamDetailAction.request, () => true)
    .handleAction(
      [fetchTeacherExamDetailAction.success, fetchTeacherExamDetailAction.failure],
      () => false,
    ),
  studentExamData: createReducer<boolean, ExamsActionType>(initialState.fetching.studentExamData)
    .handleAction(fetchStudentExamAction.request, () => true)
    .handleAction([fetchStudentExamAction.success, fetchStudentExamAction.failure], () => false),
  studentCurrentExam: createReducer<boolean, ExamsActionType>(
    initialState.fetching.studentCurrentExam,
  )
    .handleAction(fetchStudentCurrentExamAction.request, () => true)
    .handleAction(
      [fetchStudentCurrentExamAction.success, fetchStudentCurrentExamAction.failure],
      () => false,
    ),
  fetchStudentExamsList: createReducer<boolean, ExamsActionType>(
    initialState.fetching.studentExamData,
  )
    .handleAction(
      [fetchLessonOptionsAction.request, fetchStudentExamsListAction.request],
      () => true,
    )
    .handleAction(
      [
        fetchLessonOptionsAction.success,
        fetchLessonOptionsAction.failure,
        fetchStudentExamsListAction.success,
      ],
      () => false,
    )
    .handleAction([resetTeacherExamsAction], () => initialState.fetching.examLessons),
});

const errors = combineReducers({
  errorTeacherExams: createReducer<string, ExamsActionType>(
    initialState.errors.errorTeacherExams,
  ).handleAction(fetchTeacherExamsAction.failure, (state, { payload }) => payload),
  teacherExamDetail: createReducer<string, ExamsActionType>(
    initialState.errors.teacherExamDetail,
  ).handleAction(fetchTeacherExamDetailAction.failure, (state, { payload }) => payload),
  errorStudentExamsList: createReducer<string, ExamsActionType>(
    initialState.errors.errorStudentExamsList,
  ).handleAction(fetchStudentExamsListAction.failure, (state, { payload }) => payload),
  examLessons: createReducer<string, ExamsActionType>(initialState.errors.examLessons).handleAction(
    fetchLessonOptionsAction.failure,
    (state, { payload }) => payload,
  ),
  studentExamData: createReducer<string, ExamsActionType>(
    initialState.errors.studentExamData,
  ).handleAction(fetchStudentExamAction.failure, (state, { payload }) => payload),
  studentExamResult: createReducer<string, ExamsActionType>(
    initialState.errors.studentExamResult,
  ).handleAction(ExitStudentExamAction.failure, (state, { payload }) => payload),
  studentCurrentExam: createReducer<string, ExamsActionType>(
    initialState.errors.studentCurrentExam,
  ).handleAction(fetchStudentCurrentExamAction.failure, (state, { payload }) => payload),
});

const examReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type ExamType = StateType<typeof examReducer>;

export default examReducer;
