import { createSelector } from 'reselect';
import { groupBy, compose, map, filter } from 'lodash/fp';

import { formatDate } from 'utils/date-time';

import { RootStateType } from '../types';
import { NotificationsType } from './types';
import { NotificationsStateType } from './reducer';

export const selectorNotifications = (state: RootStateType): NotificationsStateType =>
  state.notifications;

export const notificationsV2Selector = createSelector(
  selectorNotifications,
  ({ data }) => data?.notifications,
);

export const selectorNotificationsGroupByDate = createSelector(selectorNotifications, ({ data }) =>
  groupBy(({ notifyAt }: NotificationsType) => formatDate(notifyAt))(data?.notifications?.results),
);

export const selectorNotificationsUnread = createSelector(selectorNotifications, ({ data }) =>
  compose(map('id'), filter({ isRead: false }))(data?.notifications?.results),
);

export const selectorBulkImport = createSelector(
  selectorNotifications,
  (state) => state.bulkImportStatus,
);

export const selectorNotificationsUnreadCount = createSelector(
  selectorNotifications,
  ({ data }) => data?.count,
);

export const notificationFetchingSelector = createSelector(
  selectorNotifications,
  ({ fetching }) => fetching?.load,
);

export const unreadRecommendationsSelector = createSelector(
  selectorNotifications,
  ({ data }) => data?.hasRecommendations,
);
