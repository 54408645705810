import 'external-svg-loader';
import React, { FunctionComponent, createElement, useMemo } from 'react';
import cn from 'classnames';

import { ASSETS_URL_SVG } from 'lib/SVG/contants';

import { ICONS, Sizes, Colors, internalIcons } from './Icon.constants';
import styles from './Icon.module.scss';
import { SvgIcon } from './SvgIcon';

export type IconType = keyof typeof ICONS;

export type IconProps = {
  type: IconType;
  title?: string;
  size?: keyof typeof Sizes;
  ariaLabel?: string;
  ariaHidden?: boolean;
  color?: keyof typeof Colors;
  disabled?: boolean;
  disableMirror?: boolean;
  className?: string;
};

export const Icon: FunctionComponent<IconProps> = ({
  type,
  title,
  size = Sizes.s20,
  ariaLabel,
  ariaHidden,
  color = Colors.default,
  disabled = false,
  disableMirror = false,
  className,
}: IconProps) => {
  const icon = useMemo(() => {
    const [data] = internalIcons.filter((icon) => icon.name === type) || [];
    return data?.component;
  }, [type]);

  return (
    <SvgIcon
      title={title}
      disabled={disabled}
      disableMirror={disableMirror}
      ariaHidden={ariaHidden}
      ariaLabel={ariaLabel}
      className={cn(className, {
        [styles[size]]: size,
        [styles[color]]: color,
      })}
    >
      {icon ? createElement(icon) : <svg data-src={`${ASSETS_URL_SVG}${type}.svg`} />}
    </SvgIcon>
  );
};
