/**
 * Theme for applying consistent styling to the app
 */

export const dayTheme = {
  /**
   * Font
   */
  font: {
    family: {
      primary: "'Nunito Sans', sans-serif",
      arabic: `'29LT Azer'`,
      children: "'Inter', sans-serif",
    },
    weight: {},
    size: {
      primary: '1.6rem',
      secondary: '1.4rem',
    },
  },

  /**
   * Colors
   */
  colors: {
    black: '#000',
    white: '#fff',
    haiti: '#191434',
    poreGore: '#2f2651',
    sunsetOrange: '#fd4949',
    selectiveYellow: '#ffb200',
    congressBlue: '#004794',
    prussianBlue: '#00264f',
    flushMahogany: '#cb3a3a',
    wellRead: '#a73030',
    midnightBlue: '#002a64',
    resolutionBlue: '#00377d',
    butterflyBush: '#6a55a5',
    blueCharcoal: '#00060e',
    codGray: '#141414',
    emperor: '#535353',
    aubergine: '#300a0a',
    blueMarguerite: '#697dc5',
    midnight: '#001128',
    valentino: '#2b1049',
    scarletGum: '#38155e',
    blackRussian: '#0b0027',
    martinique: '#3c314f',
    smoky: '#5d566f',
    steelGray: '#2f2741',
    amaranth: '#ea1c6d',
    flamingo: '#f15d19',
    jellyBean: '#2285a2',
    blackRussian2: '#09002f',
    violentViolet: '#280c67',
    portage: '#a580ef',
    jaguar: '#030116',
    violet: '#1c0d3d',
    violet2: '#0D0626',
    cerulean: '#009ede',
    santasGrey: '#999faa',
    robinEggBlue: '#00dec5',
    goldenTainoi: '#ffc454',
    statusGreen: '#009174',
    alto: '#d9d9d9',
    turquoise: '#26d9b5',
    porcelain2: '#f3f6f7',
    haiti2: '#0B0518',
    persianGreen: '#00a99d',
    ceriseRed: '#e63e62',
    mountainMeadow: '#1cac8f',
    poreGore2: '#302550',
    sanMarino2: '#4C5DA2',
    polar: '#e7f7fa',
    botticelli2: '#d2e4eb',
    foam: '#e3f7fc',
    whiteIce2: '#e7f8fb',
    mintTulip: '#bcecf3',
    fountainBlue: '#53c9c3',
    spray: '#70d7ef',
    pearlLusta: '#fcf2cf',
    jonquil: '#f4ff95',

    navigation: {
      border: '#d2e4eb',
      background: '#e7f8fb',
      color: '#344055',
      activeColor: '#fff',
    },

    input: {
      color: '#344055',
      border: '#d2e4eb',
      background: '#fff',
      labelBg: '#001128',
      labelColorLight: '#fff',
      labelColorDark: '#344055',
      focusBorderLight: '#ffb200',
      focusBorderDark: '#344055',
    },

    primary: {
      textColor: '#344055',
      headingColor: '#344055',
      blockBackground: '#e7f8fb',
      blockBackgroundDark: '#e7f8fb',
      blockHeaderBackground: '#1A4661',
      blockBorder: '#d2e4eb',
      blockShadow: '#e7f8fb',
      anchor: '#1a4661',
    },

    secondary: {
      seperator: 'linear-gradient(var(--navbar-top-border) 25%, rgba(0, 0, 0, 0) 85%)',
      active: '#344055',
      dashboardMenuBG: 'rgba(255, 255, 255, 0.7)',
      cardDarkBG: '#fff',
      dashboardMenuBGLight: 'rgba(255, 255, 255, 0.4)',
      settingNavBG: '#1a4661',
      dashboardMenuHover: '#e7f8fb',
      lessonTitleBg: '#1A4661',
      lessonTitleColor: '#fff',
      textColor: '#fff',
      textLight: '#1A4661',
    },

    card: {
      color: '#344055',
      border: '#d2e4eb',
      background: '#fff',
      secondaryBackground: '#fff',
      footerBackground: '#bcecf3',
      footerColor: '#344055',
      shadow: '#e7f8fb',
      gradient: '#fff',
      lessonHeaderBG: '#e7f8fb',
      lessonFooterBG: '#bcecf3',
    },

    modal: {
      background: '#F2F7FA',
      border: '#d2e4eb',
      color: '#344055',
      shadow: '5px 8.7px 25px 0 rgba(244, 255, 248, 0.6)',
    },

    error: {
      // main: '#fff',
    },
  },
  breakpoint: {
    tablet: '992px',
    desktop: '1200px',
    mobile: '768px',
    extralarge: '1600px',
  },
};

export const nightTheme = {
  /**
   * Font
   */
  font: {
    family: {
      primary: "'Nunito Sans', sans-serif",
      arabic: `'29LT Azer'`,
      children: "'Inter', sans-serif",
    },
    weight: {},
    size: {
      primary: '1.6rem',
      secondary: '1.4rem',
    },
  },

  /**
   * Colors
   */
  colors: {
    black: '#000',
    white: '#fff',
    haiti: '#191434',
    poreGore: '#2f2651',
    sunsetOrange: '#fd4949',
    selectiveYellow: '#ffb200',
    congressBlue: '#004794',
    prussianBlue: '#00264f',
    flushMahogany: '#cb3a3a',
    wellRead: '#a73030',
    midnightBlue: '#002a64',
    resolutionBlue: '#00377d',
    butterflyBush: '#6a55a5',
    blueCharcoal: '#00060e',
    codGray: '#141414',
    emperor: '#535353',
    aubergine: '#300a0a',
    blueMarguerite: '#697dc5',
    midnight: '#001128',
    valentino: '#2b1049',
    scarletGum: '#38155e',
    blackRussian: '#0b0027',
    martinique: '#3c314f',
    smoky: '#5d566f',
    steelGray: '#2f2741',
    amaranth: '#ea1c6d',
    flamingo: '#f15d19',
    jellyBean: '#2285a2',
    blackRussian2: '#09002f',
    violentViolet: '#280c67',
    portage: '#a580ef',
    jaguar: '#030116',
    violet: '#1c0d3d',
    violet2: '#0D0626',
    cerulean: '#009ede',
    santasGrey: '#999faa',
    robinEggBlue: '#00dec5',
    goldenTainoi: '#ffc454',
    statusGreen: '#009174',
    alto: '#d9d9d9',
    turquoise: '#26d9b5',
    porcelain2: '#f3f6f7',
    haiti2: '#0B0518',
    persianGreen: '#00a99d',
    ceriseRed: '#e63e62',
    mountainMeadow: '#1cac8f',
    sanMarino2: '#4C5DA2',

    navigation: {
      border: '#2f2651',
      background: '#030116',
      color: '#999faa',
      activeColor: '#fff',
    },

    input: {
      color: '#d9d9d9',
      border: '#2f2651',
      background: '#0d0626',
      labelBg: '#6a55a5',
      labelColorLight: '#d9d9d9',
      labelColorDark: '#344055',
      focusBorderLight: '#ffb200',
      focusBorderDark: '#344055',
    },

    primary: {
      textColor: '#fff',
      headingColor: '#ffb200',
      blockBackground: '#1c0d3d',
      blockBackgroundDark: '#0D0626',
      blockHeaderBackground: '#0B0518',
      blockBorder: '#2f2651',
      blockShadow: '#0B0518',
      anchor: '#ffb200',
    },

    secondary: {
      seperator: 'linear-gradient(rgba(37, 33, 68, 1) 25%, rgba(0, 0, 0, 0) 85%)',
      active: '#ffb200',
      dashboardMenuBG: 'rgba(10, 0, 38, 0.7)',
      cardDarkBG: '#0D0626',
      dashboardMenuBGLight: 'rgba(10, 0, 38, 0.4)',
      settingNavBG: '#09002f',
      dashboardMenuHover: '#2f2651',
      lessonTitleBg: '#fff',
      lessonTitleColor: '#191434',
      textColor: '#fff',
      textLight: '#d9d9d9',
    },

    card: {
      color: '#d9d9d9',
      border: '#2f2651',
      background: '#0B0518',
      secondaryBackground: '#2f2651',
      footerBackground: '#030116',
      footerColor: '#ffb200',
      shadow: 'transparent',
      gradient:
        'radial-gradient(54.18% 57.17% at 49.19% 49.75%, rgba(249, 249, 249, 0.03) 0%, #0B0518 100%)',
      lessonHeaderBG:
        'radial-gradient(54.18% 57.17% at 49.19% 49.75%, rgba(249, 249, 249, 0.03) 0%, #0d0626 100%)',
      lessonFooterBG:
        'radial-gradient( 54.18% 57.17% at 49.19% 49.75%, rgb(65, 123, 229, 0.75) 0%, #321b72 100%)',
    },

    modal: {
      background: '#15053a',
      border: '#2f2651',
      color: '#fff',
      shadow: 'none',
    },

    error: {
      // main: '#fff',
    },
  },

  /**
   * Breakpoints
   */
  breakpoint: {
    tablet: '992px',
    desktop: '1200px',
    mobile: '768px',
    extralarge: '1600px',
  },
};
