import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';
import { ButtonTypes, NewButton, SVG } from 'lib';

import styles from './Orientation.module.scss';
import { msgs } from './messages';
import { OrientationContext } from './OrientationContext';

const WelcomeContent = () => {
  const { formatMessage: fm } = useIntl();

  const { changeStateToOrientationVideo } = useContext(OrientationContext);
  const isGradeOneToThree = useIsGradeOneToThree();

  return (
    <>
      <div className={styles.orientationVideo__ribbonWrap}>
        <SVG name="ribbon" className={`ribbonSVG ${isGradeOneToThree && 'ribbonGradeSVG'}`} />
        <h6 className={styles.orientationVideo__ribbonWrap__heading}>{fm(msgs.welcomeTitle)}</h6>
      </div>
      <p className={styles.orientationVideo__desc}>{fm(msgs.welcomeDescription)}</p>
      <h1 className={styles.orientationVideo__heading}>{fm(msgs.advanceLearningPlatformTitle)}</h1>
      <p className={styles.orientationVideo__smallDesc}>{fm(msgs.orientationStepsDescription)}</p>
      <NewButton
        style={
          isGradeOneToThree
            ? [ButtonTypes.btnGrade, ButtonTypes.btnGradeSmall, ButtonTypes.btnGradeOrange]
            : [ButtonTypes.buttonSmall, ButtonTypes.buttonPrimary]
        }
        onClick={changeStateToOrientationVideo}
      >
        {fm(msgs.nextButtonTitle)}
      </NewButton>
    </>
  );
};

export default WelcomeContent;
