import axios, { AxiosError } from 'axios';

import { getRedirectionUrl } from './helpers';
import { ROUTES } from '../constants/routes';
import { APP_API_URL, CSRF_TOKEN_KEY } from '../constants/settings';

export enum APIError {
  network = 'network',
  server = 'server',
  notFound = 'notFound',
  paymentError = 'paymentError',
}

export const processApiError = (error: AxiosError): any => {
  if (!error.response) {
    return APIError.network;
  }

  if (error.response?.status && [500, 501, 502, 503, 504].includes(error.response?.status)) {
    return APIError.server;
  }

  if (error.response?.status === 404) {
    const errorMessage = error.response?.data?.message || error.response?.data?.error;
    if (errorMessage) return errorMessage;
    return APIError.notFound;
  }

  if (error.response?.status === 413) {
    return error.response?.statusText;
  }

  if (error.response?.status === 402) {
    return APIError.paymentError;
  }

  if (error.response?.data?.field_errors) {
    return Object.entries(error.response.data.field_errors).map(([name, value]) => ({
      // eslint-disable-next-line
      // @ts-ignore
      userMessage: value.user_message,
      fieldName: name,
    }));
  }

  if (error.response?.data?.errors !== undefined) {
    return error.response.data.errors.map((err: { user_message: string }) => ({
      userMessage: err.user_message ? err.user_message : null,
    }));
  }

  if (error.response?.data?.success === false && error.response?.data?.value) {
    return error.response?.data?.value;
  }

  if (error.response?.data?.detail) {
    return error.response?.data?.detail;
  }
  if (!error.response?.data) {
    return 'An unknown error occurred.';
  }

  return error.response?.data;
};

export const API_URL = APP_API_URL;
export const CSRF_COOKIE_KEY = CSRF_TOKEN_KEY;

const API = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  xsrfCookieName: CSRF_COOKIE_KEY || 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  responseType: 'json',
});

API.interceptors.request.use((request) => {
  const { lang } = document.getElementsByTagName('html')[0];
  request.headers['HTTP-Accept-Language'] = lang.substring(0, 2);
  request.headers['X-ALW-Application'] = 'true';

  return request;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      const path = getRedirectionUrl();
      const { search, pathname: currentPath } = window.location;
      const params = new URLSearchParams(search);

      const allParams = Object.fromEntries(params.entries());

      const searchParams = new URLSearchParams(allParams);

      if (!(currentPath === ROUTES.login || currentPath === ROUTES.managementLogin) && search) {
        window.location.href = `${path}?${searchParams}`;
      } else if (!search.includes('redirect_url')) {
        window.location.href = path;
      }
    }
    return Promise.reject(processApiError(error));
  },
);

export default API;
