import { createSelector } from 'reselect';
import { RootStateType } from '../types';
import { ContentLibraryStateType } from './reducer';

export const selectorContentLibrary = (state: RootStateType): ContentLibraryStateType =>
  state.contentLibrary;

export const contentLibraryDataSelector = createSelector(
  selectorContentLibrary,
  ({ data }) => data,
);

export const liveEventsSelector = createSelector(
  selectorContentLibrary,
  ({ data: { liveEvents } }) => liveEvents,
);

export const contentLibraryFetchingSelector = createSelector(
  selectorContentLibrary,
  ({ fetching }) => fetching,
);

export const contentLibraryErrorsSelector = createSelector(
  selectorContentLibrary,
  ({ errors }: any) => errors,
);

export const subjectsSelector = createSelector(
  selectorContentLibrary,
  ({ data: { subjects } }) => subjects,
);

export const subjectsFetchingSelector = createSelector(
  selectorContentLibrary,
  ({ fetching }) => fetching.subjects,
);
