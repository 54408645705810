import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  loadTeacherProfile,
  deleteTeacherAction,
  updateTeacherAction,
  updateTeacherPasswordAction,
} from './actions';
import { getTeacher, deleteTeacher, updateTeacher, updateTeacherPassword } from './api';
import { TeacherInfoType } from './types';

function* loadTeacherList({
  payload,
}: ReturnType<typeof loadTeacherProfile.request>): SagaIterator<void> {
  try {
    const subjects: TeacherInfoType = yield call(getTeacher, payload);
    yield put(loadTeacherProfile.success(subjects));
  } catch (error) {
    yield put(loadTeacherProfile.failure(error));
  }
}

function* deleteTeacherList({
  payload,
}: ReturnType<typeof deleteTeacherAction.request>): SagaIterator<void> {
  try {
    yield call(deleteTeacher, payload.payload);
    payload.callback();
  } catch (error) {
    payload.callback(error?.error);
    yield put(loadTeacherProfile.failure(error));
  }
}

function* updateTeacherRequest({
  payload,
}: ReturnType<typeof updateTeacherAction.request>): SagaIterator<void> {
  try {
    const data = yield call(updateTeacher, payload);
    yield put(updateTeacherAction.success(data));
  } catch (error) {
    yield put(updateTeacherAction.failure(error));
  } finally {
    if (payload.callback) {
      payload.callback();
    }
  }
}

function* updateTeacherPasswordRequest({
  payload: { branchId, teacherId, formValues, callbackSuccess, callbackFail },
}: ReturnType<typeof updateTeacherPasswordAction.request>): SagaIterator<void> {
  try {
    yield call(updateTeacherPassword, branchId, teacherId, formValues);
    callbackSuccess();
    yield put(updateTeacherPasswordAction.success());
  } catch (error) {
    callbackFail && callbackFail(error);
    yield put(updateTeacherPasswordAction.failure(error));
  }
}

function* teacherProfileSaga(): SagaIterator {
  yield all([
    takeEvery(loadTeacherProfile.request, loadTeacherList),
    takeEvery(deleteTeacherAction.request, deleteTeacherList),
    takeEvery(updateTeacherAction.request, updateTeacherRequest),
    takeEvery(updateTeacherPasswordAction.request, updateTeacherPasswordRequest),
  ]);
}

export default teacherProfileSaga;
