import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  getEventDetailAPI,
  addLiveSessionAttachments,
  getTeacherEventAttachmentsAPI,
  declineEventRequestAPI,
  rateLiveEvent,
  liveEventStudentQuestions,
  liveEventTeacherQuestions,
  liveEventAnswers,
  deleteLiveQuestion,
  deleteAttachment,
  commentAPI,
  scheduleFilterAPI,
  getBranchEventsAPI,
  getLiveSessionData,
  getGeneralQAs,
  generalAnswers,
  updateGeneralAnswers,
} from 'utils/apiEndpoints';
import { getISOStringWithoutMilliSeconds } from 'utils/helpers';
import {
  dataSchedulesAdapter,
  dataScheduleAdapter,
  tableViewSchedulesAdapter,
  eventDetailAdapter,
} from './adapter';
import {
  ScheduleEventType,
  CreateMeetingType,
  TableViewResponseType,
  EventDetailType,
  LiveEventType,
  BMSchedulePayload,
  ScheduleFormOptionsType,
  ScheduleCalendarType,
  BMTableSchedulePayload,
  BranchScheduleTableViewResponseType,
  AddCommentPayload,
  Comment,
  LiveScheduleReturnType,
  DeleteAttachmentType,
  UpdateAnswerPayload,
  LiveAnswerType,
  PostStudentQuestionPayload,
  StudentLiveQuestionsType,
  GeneralAnswerType,
  UpdateGeneralAnswerPayload,
  PostGeneralAnswerPayload,
} from './types';

export async function getSchedule(
  startDate?: Date,
  endDate?: Date,
  listView?: boolean,
  pageIndex?: number,
  pageSize?: number,
): Promise<ScheduleEventType[] | TableViewResponseType> {
  const response = await API.get('/api/mentora/calendar/events/', {
    params: {
      start_date: startDate?.toISOString(),
      end_date: endDate?.toISOString(),
      list_view: listView,
      page: pageIndex,
      page_size: pageSize,
    },
  });
  if (!listView) return dataSchedulesAdapter(camelcaseKeys(response.data, { deep: true }));
  return tableViewSchedulesAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function deleteScheduleEvent(id: number): Promise<void> {
  await API.delete(`/api/mentora/calendar/event/${id}/`);
}

export function getEventsByPageSize(count: number): Promise<any> {
  return API.get('/api/mentora/calendar/events/', {
    params: {
      list_view: true,
      page: 1,
      page_size: count,
    },
  });
}

export async function getScheduleFilters(branchId: number): Promise<ScheduleFormOptionsType> {
  const response = await API.get(scheduleFilterAPI(branchId));
  return camelcaseKeys({ ...response.data, branch: branchId }, { deep: true });
}

export async function getScheduleCardViewData({
  branchId,
  listView,
  type,
  classroom,
  subject,
  teacher,
}: BMSchedulePayload): Promise<ScheduleCalendarType[]> {
  const response = await API.get(getBranchEventsAPI(), {
    params: {
      list_view: listView,
      branch_id: branchId,
      event_type: type,
      trainer_id: teacher,
      classroom_id: classroom,
      subject,
    },
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function addCommentAPIRequest({
  text,
  branchId,
  eventId,
}: AddCommentPayload): Promise<void> {
  const response = await API.post(commentAPI(branchId, eventId), {
    text,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function loadCommentAPIRequest({
  branchId,
  eventId,
}: AddCommentPayload): Promise<Comment[]> {
  const response = await API.get(commentAPI(branchId, eventId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getScheduleTableViewData({
  branchId,
  listView,
  pageSize,
  page,
  type,
  classroom,
  subject,
  teacher,
}: BMTableSchedulePayload): Promise<BranchScheduleTableViewResponseType> {
  const response = await API.get(getBranchEventsAPI(), {
    params: snakeCaseKeys({
      listView,
      page,
      pageSize,
      branchId,
      subject,
      eventType: type,
      trainerId: teacher,
      classroomId: classroom,
    }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getMeetingLink(id: number): Promise<void> {
  const response = await API.get(`/api/mentora/calendar/events/${id}/join-link/`);
  return camelcaseKeys(response.data, { deep: true });
}

export async function refreshMeetingLinkAPI(id: number): Promise<void> {
  const response = await API.get(`/api/mentora/calendar/events/${id}/update-meeting-link/`);
  return camelcaseKeys(response.data, { deep: true });
}

export async function getEventDetail(id: number): Promise<EventDetailType> {
  const response = await API.get(getEventDetailAPI(id));
  return eventDetailAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function setSchedule({
  eventName,
  eventType,
  startDate,
  endDate,
  zoomLink,
  trainer,
  grade,
  students,
  eventSource,
  subject,
  description,
  meetingType,
  checkScheduleClash,
  classrooms,
  recommendation,
  isInPersonClass,
}: CreateMeetingType): Promise<ScheduleEventType> {
  const response = await API.post(
    '/api/mentora/calendar/events/',
    snakeCaseKeys(
      {
        eventName,
        eventType,
        startDate: getISOStringWithoutMilliSeconds(startDate),
        endDate: getISOStringWithoutMilliSeconds(endDate),
        zoomLink,
        trainer,
        students,
        grade,
        subject,
        eventSource,
        description,
        meetingType,
        checkScheduleClash,
        classrooms,
        recommendation,
        isInPersonClass,
      },
      { deep: true },
    ),
  );

  return dataScheduleAdapter(camelcaseKeys(response.data, { deep: true }));
}

export async function setRecordingLink({
  id,
  ...event
}: ScheduleEventType): Promise<ScheduleEventType> {
  const { data } = await API.put(
    `/api/mentora/calendar/event/${id}/`,
    snakeCaseKeys(
      {
        ...event,
        subject: event.subject?.id,
        startDate: event.startDate?.toISOString(),
        endDate: event.endDate?.toISOString(),
      },
      { deep: true },
    ),
  );
  return data;
}

export async function addEventAttachments({
  eventId,
  formData,
  onUploadProgress,
}: any): Promise<any> {
  const { data } = await API.post(addLiveSessionAttachments(eventId), formData, {
    onUploadProgress,
  });
  return camelcaseKeys(data, { deep: true });
}

export async function getTeacherEventAttachments(queryString?: string): Promise<void> {
  const response = await API.get(getTeacherEventAttachmentsAPI(queryString));
  return camelcaseKeys(response.data, { deep: true });
}

export async function sendDeclineEventRequest({ eventId, rejectionReason }: any): Promise<any> {
  await API.post(declineEventRequestAPI(eventId), snakeCaseKeys({ rejectionReason }));
  return { rejectionReason };
}

export async function sendRateLiveEventAPI({
  eventId,
  accountId,
  rating,
}: LiveEventType): Promise<EventDetailType> {
  const response = await API.post(
    rateLiveEvent(eventId),
    snakeCaseKeys({ liveSession: eventId, rated_by: accountId, rating }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateRateLiveEventAPI({
  eventId,
  accountId,
  rating,
}: LiveEventType): Promise<EventDetailType> {
  const response = await API.patch(
    rateLiveEvent(eventId),
    snakeCaseKeys({ liveSession: eventId, rated_by: accountId, rating }),
  );
  return camelcaseKeys(response.data, { deep: true });
}

export async function getStudentQuestionsAPI({ eventId }: any): Promise<any> {
  const response = await API.get(liveEventStudentQuestions(eventId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function postStudentQuestionsAPI({
  eventId,
  question,
  attachmentQuestion,
  studentId,
}: PostStudentQuestionPayload): Promise<StudentLiveQuestionsType> {
  const data = new FormData();
  data.append('question', question);
  data.append('asked_by', studentId.toString());
  attachmentQuestion && data.append('attachment_question', attachmentQuestion);

  const response = await API.post(liveEventStudentQuestions(eventId), data);
  return camelcaseKeys(response.data, { deep: true });
}

export async function getTeacherQuestionsAPI({ value }: any): Promise<any> {
  const response = await API.get(liveEventTeacherQuestions(value));
  return camelcaseKeys(response.data, { deep: true });
}

export async function postEventAnswerAPI({
  eventId,
  questionId,
  answer,
  attachmentAnswer,
}: UpdateAnswerPayload): Promise<LiveAnswerType> {
  const data = new FormData();
  data.append('answer', answer);
  attachmentAnswer && data.append('attachment_answer', attachmentAnswer);

  const response = await API.post(liveEventAnswers(eventId, questionId), data);
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateEventAnswerAPI({
  eventId,
  questionId,
  answer,
  attachmentAnswer,
}: UpdateAnswerPayload): Promise<LiveAnswerType> {
  const data = new FormData();
  data.append('answer', answer);
  attachmentAnswer && data.append('attachment_answer', attachmentAnswer);

  const response = await API.patch(liveEventAnswers(eventId, questionId), data);
  return camelcaseKeys(response.data, { deep: true });
}

export async function deleteEventQuestionAPI({ eventId, questionId }: LiveEventType): Promise<any> {
  const response = await API.delete(deleteLiveQuestion(eventId, questionId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function deleteAttachmentAPI({
  eventId,
  attachmentId,
}: DeleteAttachmentType): Promise<void> {
  const response = await API.delete(deleteAttachment(eventId, attachmentId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getLiveScheduleDataAPI({
  id,
  forScheduling = false,
}: {
  id: number;
  forScheduling?: boolean;
}): Promise<LiveScheduleReturnType> {
  const response = await API.get(getLiveSessionData(id), {
    params: snakeCaseKeys({ forScheduling }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getStudentGeneralQA(subjectId: string): Promise<any> {
  const response = await API.get(getGeneralQAs(subjectId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function postGeneralAnswerAPI({
  questionId,
  answer,
  attachment,
}: PostGeneralAnswerPayload): Promise<GeneralAnswerType> {
  const data = new FormData();
  data.append('answer', answer);
  attachment && data.append('attachment', attachment);

  const response = await API.post(generalAnswers(questionId), data);
  return camelcaseKeys(response.data, { deep: true });
}

export async function updateGeneralAnswerAPI({
  questionId,
  answerId,
  answer,
  attachment,
}: UpdateGeneralAnswerPayload): Promise<GeneralAnswerType> {
  const data = new FormData();
  data.append('answer', answer);
  attachment && data.append('attachment', attachment);

  const response = await API.patch(updateGeneralAnswers(questionId, answerId), data);
  return camelcaseKeys(response.data, { deep: true });
}
