import { Role, SchoolRole } from '../account/types';
import { PreferencesRequestType, AccountNotificationsType, ResultType } from './types';

type NotificationType = {
  [key in Role]?: Array<AccountNotificationsType>;
};

export const teacherDefaultNotifications = [
  {
    key: 'live_session_reminder_email',
    title: { id: 'accounts.notifications.events.title', defaultMessage: 'Events' },
    description: {
      id: 'accounts.notifications.events.description',
      defaultMessage: 'One-to-one, one to many and Live sessions reminder emails',
    },
    checked: false,
  },
];

const studentDefaultNotifications = [
  ...teacherDefaultNotifications,
  {
    key: 'student_stuff_to_do_email',
    title: { id: 'accounts.notifications.stuff.email', defaultMessage: 'Stuff to do email' },
    description: {
      id: 'accounts.notifications.lessons.for.next.week',
      defaultMessage: 'Lessons and live session for next week',
    },
    checked: false,
  },
  {
    key: 'student_12_new_material_email',
    title: {
      id: 'preparation.material.title',
      defaultMessage: 'Al-Qudrat & Tahseeli',
    },
    description: {
      id: 'accounts.notifications.al-qudrat.tahseeli.description',
      defaultMessage: 'New material email',
    },
    grade: 12,
    checked: false,
  },
  {
    key: 'student_exam_email',
    title: { id: 'exams.title', defaultMessage: 'Exams' },
    description: { id: 'accounts.notifications.exams.description', defaultMessage: 'Exams emails' },
    checked: false,
  },
  {
    key: 'performance_reminder_email',
    title: { id: 'accounts.notifications.reminder-engine.title', defaultMessage: 'Reminders' },
    description: {
      id: 'accounts.notifications.reminder-engine.description',
      defaultMessage:
        'Receive notifications for performance reminders, such as overdue lessons, assignments, missing live sessions and lessons to do in a week',
    },
    checked: true,
  },
];

export const alwNotifications: NotificationType = {
  [Role.student]: studentDefaultNotifications,
  [Role.parent]: [
    {
      key: 'parent_student_week_reports_email',
      title: {
        id: 'accounts.notifications.weekly.reports.title',
        defaultMessage: 'Weekly reports',
      },
      description: {
        id: 'accounts.notifications.weekly.reports.description',
        defaultMessage: 'Children weekly reports',
      },
      checked: false,
    },
  ],
  [Role.alwTeacher]: teacherDefaultNotifications,
};

export const b2bNotifications = {
  [SchoolRole.schoolStudent]: [
    ...studentDefaultNotifications,
    {
      key: 'b2b_student_assignment_email',
      title: {
        id: 'accounts.notifications.assignments.title',
        defaultMessage: 'Assignments - Individual / Group',
      },
      description: {
        id: 'accounts.notifications.assignments.description',
        defaultMessage: 'New assignments emails',
      },
      checked: false,
    },
    {
      key: 'b2b_student_resubmit_assignment_email',
      title: {
        id: 'accounts.notifications.assignments.resubmission.title',
        defaultMessage: 'Assignments - Resubmission',
      },
      description: {
        id: 'accounts.notifications.assignments.resubmission.description',
        defaultMessage: 'resubmit assignment email',
      },
      checked: false,
    },
  ],
  [SchoolRole.schoolTeacher]: [
    ...teacherDefaultNotifications,
    {
      key: 'b2b_teacher_recommendation_from_school_manager_email',
      title: { id: 'reports.recommendations', defaultMessage: 'Recommendations' },
      description: {
        id: 'accounts.notifications.recommendations',
        defaultMessage: 'Recommendation to teachers emails',
      },
      checked: false,
    },
  ],
  [SchoolRole.schoolAdmin]: [
    {
      key: 'school_admin_subscription_expiry_email',
      title: { id: 'account.menu.subscription', defaultMessage: 'Subscription' },
      description: {
        id: 'accounts.notifications.school.expiry.email',
        defaultMessage: 'School subscription expiry emails',
      },
      checked: false,
    },
    {
      key: 'school_admin_bulk_import_email',
      title: { id: 'teachers.tabs.bulk', defaultMessage: 'Bulk Import' },
      description: {
        id: 'accounts.notifications.bulk.emails',
        defaultMessage: 'Bulk import emails',
      },
      checked: false,
    },
  ],
  [SchoolRole.schoolBranchManager]: [
    {
      key: 'branch_manager_subscription_expiry_email',
      title: { id: 'account.menu.subscription', defaultMessage: 'Subscription' },
      description: {
        id: 'accounts.notifications.school.expiry.email',
        defaultMessage: 'School subscription expiry emails',
      },
      checked: false,
    },
    {
      key: 'branch_manager_bulk_import_email',
      title: { id: 'teachers.tabs.bulk', defaultMessage: 'Bulk Import' },
      description: {
        id: 'accounts.notifications.bulk.emails',
        defaultMessage: 'Bulk import emails',
      },
      checked: false,
    },
    {
      key: 'branch_manager_biweekly_teacher_report_email',
      title: { id: 'accounts.notifications.teacher.reports', defaultMessage: 'Teacher’s Report' },
      description: {
        id: 'accounts.notifications.teacher.biweekly.reports.emails',
        defaultMessage: 'Teacher biweekly reports emails',
      },
      checked: false,
    },
  ],
};

export const getUpdateNotifications = (
  result: ResultType,
  notifications: Array<AccountNotificationsType> = [],
  grade?: number,
) => {
  return notifications.flatMap((notificationData: AccountNotificationsType) => {
    if ('grade' in notificationData && notificationData.grade !== grade) return [];
    const isKeyExists = Object.prototype.hasOwnProperty.call(result, notificationData.key);
    return isKeyExists
      ? { ...notificationData, checked: result[notificationData.key] }
      : notificationData;
  });
};

export const getNotifications = (
  result: ResultType,
  { isAlwRole, role, schoolRole, grade }: PreferencesRequestType,
) => {
  const notifications = isAlwRole ? alwNotifications[role] : b2bNotifications[schoolRole];
  return getUpdateNotifications(result, notifications, grade);
};
