import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import * as actions from './actions';
import { PaymentHistoryType } from './types';
import { paymentHistoryAction } from './actions';

type ActionsType = ActionType<typeof actions>;

const data = createReducer<PaymentHistoryType[], ActionsType>([]).handleAction(
  paymentHistoryAction.success,
  (state, { payload }) => payload,
);

const load = createReducer<boolean, ActionsType>(false)
  .handleAction([paymentHistoryAction.success, paymentHistoryAction.failure], () => false)
  .handleAction(paymentHistoryAction.request, () => true);

const error = createReducer<string, ActionsType>('')
  .handleAction(paymentHistoryAction.failure, (state, { payload }) => payload)
  .handleAction([paymentHistoryAction.success, paymentHistoryAction.request], () => '');

const paymentHistoryReducer = combineReducers({
  data,
  load,
  error,
});

export type PaymentHistoryReducerType = StateType<typeof paymentHistoryReducer>;
export default paymentHistoryReducer;
