import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getRecommendationSubjectsAction, getSubjectRecommendationByIdAction } from './actions';
import { getRecommendationSubjects, getSubjectRecommendationById } from './api';

function* getRecommendationSubjectsSaga({
  payload,
}: ReturnType<typeof getRecommendationSubjectsAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getRecommendationSubjects, payload);
    yield put(getRecommendationSubjectsAction.success(response));
  } catch (error) {
    yield put(getRecommendationSubjectsAction.failure(error));
  }
}

function* getSubjectRecommendationByIdSaga({
  payload,
}: ReturnType<typeof getSubjectRecommendationByIdAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getSubjectRecommendationById, payload);
    yield put(getSubjectRecommendationByIdAction.success(response));
  } catch (error) {
    yield put(getSubjectRecommendationByIdAction.failure(error));
  }
}

function* recommendationsSaga(): SagaIterator {
  yield all([
    takeLatest(getRecommendationSubjectsAction.request, getRecommendationSubjectsSaga),
    takeLatest(getSubjectRecommendationByIdAction.request, getSubjectRecommendationByIdSaga),
  ]);
}

export default recommendationsSaga;
