import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { loadTourAction, setWatchedTourAction } from './actions';
import { getTourDetail, setWatchedTour } from './api';
import { TourType } from './types';

function* loadTourRequest({ payload: { callback, ...payload } }: any): SagaIterator<void> {
  try {
    const tour: TourType = yield call(getTourDetail, payload);
    yield put(loadTourAction.success(tour));
    callback(tour);
  } catch (error) {
    yield put(loadTourAction.failure(error));
  }
}

function* setWatchedTourRequest({ payload }: any): SagaIterator<void> {
  try {
    yield call(setWatchedTour, payload);
    yield put(setWatchedTourAction.success());
  } catch (error) {
    yield put(setWatchedTourAction.failure(error));
  }
}

function* tourSaga(): SagaIterator {
  yield all([
    takeEvery(loadTourAction.request, loadTourRequest),
    takeEvery(setWatchedTourAction.request, setWatchedTourRequest),
  ]);
}

export default tourSaga;
