import styled, { css, keyframes } from 'styled-components';

import {
  spin,
  sizeMobile,
  sizeTablet,
  sizeDesktop,
  sizeDesktopLg,
} from 'modules/GradeOneToThree/styled-mixin';
import { getPngURL } from 'lib';

type SectionProps = {
  layoutForm?: boolean;
};

export type ThemePropsType = SectionProps & {
  color: 'day' | 'night';
};

type StickyPropsType = {
  hide?: boolean;
};

export const Root = styled.div<ThemePropsType>`
  position: relative;
  background-size: contain;
  background-image: url(${getPngURL('base-bg', true)}),
    linear-gradient(
      to top,
      ${(p) => p.theme.colors.portage},
      ${(p) => p.theme.colors.violentViolet},
      ${(p) => p.theme.colors.blackRussian2}
    );
  background-repeat: no-repeat;
  background-position: left bottom;
  color: ${(p) => p.theme.colors.primary.textColor};
  min-height: 100vh;
  padding-bottom: 15px;

  [class*='col-'] {
    padding: 0 15px;
  }

  ${sizeMobile(css`
    flex-wrap: nowrap;
  `)};

  ${sizeTablet(css`
    padding-bottom: 60px;
  `)};

  ${({ layoutForm }) =>
    layoutForm &&
    css`
      padding: 0;
      ${sizeTablet(css`
        padding: 0;
      `)};
    `};
  ${({ color }) =>
    color === 'day' &&
    css`
      background: ${(p) => p.theme.colors.spray};

      ${sizeTablet(css`
        background-image: linear-gradient(
          to bottom,
          ${(p) => p.theme.colors.spray} 65%,
          ${(p) => p.theme.colors.pearlLusta},
          ${(p) => p.theme.colors.jonquil}
        );
      `)};
    `};
`;

export const MoonImage = styled.img`
  z-index: 0;
  -webkit-animation: ${spin} 6s infinite linear;
  animation: ${spin} 6s infinite linear;
  position: absolute;
  width: 100%;
  max-width: 355px;
  top: -180px;
  display: none;

  ${sizeDesktop(css`
    left: -120px;
    display: block;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      ${sizeDesktop(css`
        left: unset;
        right: -112px;
        max-width: 335px;
      `)};

      ${sizeDesktopLg(css`
        left: unset;
        right: -120px;
        max-width: 365px;
      `)};
    `};
`;

export const SunImage = styled(MoonImage)`
  position: absolute;
  width: 100%;
  max-width: 290px;
  top: -165px;
  display: none;

  ${sizeDesktop(css`
    left: -70px;
    display: block;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      ${sizeDesktop(css`
        left: unset;
        right: -70px;
        top: -170px;
        max-width: 280px;
      `)};

      ${sizeDesktopLg(css`
        max-width: 290px;
        top: -165px;
      `)};
    `};
`;

const flight = keyframes`
  0% {
    top: 500px;
    right: 0px;
    opacity: 0%;
  }
  2% {
    opacity: 0%;
  }
  20% {
    top: 220px;
    right: 230px;
    opacity: 100%;
  }
  25% {
    top: 221px;
    right: 233px;
  }
  35% {
    top: 218px;
  }
  45% {
    top: 222px;
    right: 232px;
  }
  55% {
    top: 219px;
    right: 233px;
  }
  65% {
    top: 221px;
    right: 233px;
  }
  75% {
    top: 218px;
  }
  80% {
    top: -150px;
    right: 233px;
    opacity: 100%;
    -webkit-transform: scale(0.4) rotate(50deg);
    transform: scale(0.4) rotate(50deg);
  }
  90% {
    opacity: 0%;
  }
  100% {
    top: -150px;
    right: 500px;
    opacity: 0%;
    -webkit-transform: scale(0.3) rotate(50deg);
    transform: scale(0.3) rotate(50deg);
  }
`;

const flightRtl = keyframes`
  0% {
    top: 500px;
    left: 0px;
    opacity: 0%;
  }
  2% {
    opacity: 0%;
  }
  20% {
    top: 220px;
    left: 230px;
    opacity: 100%;
  }
  25% {
    top: 221px;
    left: 233px;
  }
  35% {
    top: 218px;
  }
  45% {
    top: 222px;
    left: 232px;
  }
  55% {
    top: 219px;
    left: 233px;
  }
  65% {
    top: 221px;
    left: 233px;
  }
  75% {
    top: 218px;
  }
  80% {
    top: -150px;
    left: 233px;
    opacity: 100%;
    -webkit-transform: scale(0.4) rotate(50deg);
    transform: scale(0.4) rotate(50deg);
  }
  90% {
    opacity: 0%;
  }
  100% {
    top: -150px;
    left: 500px;
    opacity: 0%;
    -webkit-transform: scale(0.3) rotate(50deg);
    transform: scale(0.3) rotate(50deg);
  }`;

const moveX = keyframes`
  0% {
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
  }
  5% {
    -webkit-transform: translateX(-7%);
    transform: translateX(-7%);
  }
  10% {
    -webkit-transform: translateX(-5%);
    transform: translateX(-5%);
  }
  15% {
    -webkit-transform: translateX(-3%);
    transform: translateX(-3%);
  }
  20% {
    -webkit-transform: translateX(-1%);
    transform: translateX(-1%);
  }
  30% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(1%);
    transform: translateX(1%);
  }
  45% {
    -webkit-transform: translateX(3%);
    transform: translateX(3%);
  }
  50% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  55% {
    -webkit-transform: translateX(7%);
    transform: translateX(7%);
  }
  60% {
    -webkit-transform: translateX(10%);
    transform: translateX(10%);
  }
  65% {
    -webkit-transform: translateX(7%);
    transform: translateX(7%);
  }
  70% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  75% {
    -webkit-transform: translateX(3%);
    transform: translateX(3%);
  }
  80% {
    -webkit-transform: translateX(1%);
    transform: translateX(1%);
  }
  85% {
    -webkit-transform: translateX(-1%);
    transform: translateX(-1%);
  }
  90% {
    -webkit-transform: translateX(-4%);
    transform: translateX(-4%);
  }
  95% {
    -webkit-transform: translateX(-7%);
    transform: translateX(-7%);
  }
  100% {
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
  }
`;

export const RocketImage = styled.img`
  -webkit-animation: ${flight} 4s infinite;
  animation: ${flight} 4s infinite;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      left: 0;
      right: unset;
      -webkit-animation: ${flightRtl} 4s infinite;
      animation: ${flightRtl} 4s infinite;
    `};
`;

export const CloudImage = styled.img`
  -webkit-animation: ${moveX} 20s infinite linear;
  animation: ${moveX} 20s infinite linear;
  position: absolute;
  top: 200px;
  z-index: 0;
  max-width: 550px;
  left: -260px;
  width: 100%;
  height: 130px;
  display: none;

  ${sizeMobile(css`
    display: block;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      left: unset;
      right: 0;
    `};
`;

export const Header = styled.header`
  position: relative;
  z-index: 2;
`;

export const HeaderWrapper = styled.nav`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${sizeTablet(css`
    flex-wrap: nowrap;
  `)};
`;

export const HeaderLogo = styled.span`
  max-width: 150px;
  width: 100%;
  margin: 25px auto 0;

  a {
    display: flex;
  }

  ${sizeMobile(css`
    max-width: 180px;
    padding-right: 30px;
    flex-wrap: nowrap;
    margin: 25px 0 0;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      ${sizeMobile(css`
        padding-right: 0;
        padding-left: 30px;
      `)};
    `};
`;

export const RightHeaderWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  max-width: 100%;

  ${sizeMobile(css`
    margin-right: unset;
    margin-top: 0;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      ${sizeMobile(css`
        margin-left: unset;
        margin-right: auto;
      `)};
    `};
`;

export const MainSection = styled.main<SectionProps>`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  ${sizeTablet(css`
    padding-top: 40px;
    padding-bottom: 40px;
  `)};

  ${({ layoutForm }) =>
    layoutForm &&
    css`
      padding: 0;
      ${sizeTablet(css`
        padding: 0;
      `)};
    `};
`;

export const Stars = styled.div`
  position: fixed;
  top: 0;
  z-index: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  background-image: url(${getPngURL('star-bg', true)});
`;

export const StickyBottom = styled.div<StickyPropsType>`
  position: fixed;
  width: 100%;
  z-index: 2;
  bottom: 0;

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `};
`;

export const Grass = styled.div`
  width: 100%;
  height: 100px;
  background: transparent url(${getPngURL('grass-base', true)}) no-repeat center bottom;
`;

export const FootBall = styled.img`
  -webkit-animation: roll-in-right 5s linear;
  animation: roll-in-right 5s linear;
  position: absolute;
  right: 235px;
  bottom: 0;
  width: 50px;
  max-width: 50px;
`;
