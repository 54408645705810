import { diff } from 'utils/date-time';

import { SchoolRole } from 'store/account/types';
import { SchoolBrancheType } from 'store/shoolBranches/types';

export const getRoleName = (roleId: number, language: string): string => {
  if (language.includes('en')) {
    return SchoolRole.schoolAdmin === roleId ? 'School Admin' : 'School Branch Manager';
  }

  return SchoolRole.schoolBranchManager === roleId ? 'مدير فرع المدرسة' : 'مدير المدرسة';
};

export const getSubscriptionDuration = (start: string, end: string) => {
  const duration = diff(start, end, 'days');
  return duration > 0 ? 0 : Math.abs(duration);
};

export const getSubscriptionPassed = (start: string) => {
  const duration = Math.abs(diff(new Date(), start, 'days'));
  return duration > 0 ? duration : 0;
};

export const getSubscriptionDurationString = (
  total: number,
  passed: number,
  startDay: string,
): any => {
  let duration: any = '';
  if (diff(new Date(), startDay, 'ms') < 0) {
    return { value: '', type: 'duration.type.notStarted' };
  }
  if (passed > total) {
    return { value: '', type: 'duration.type.expired' };
  }
  const days = total - passed;
  duration = { value: days, type: 'duration.type.month' };
  if (days >= 30) {
    duration.value = Math.round(days / 30);
  } else {
    duration.type = 'duration.type.day';
  }
  return duration;
};

export const getSubscriptionGraphRange = (
  total: number,
  passed: number,
  startDate: string,
): any => {
  if (diff(new Date(), startDate, 'ms') < 0) {
    return [0, 1];
  }
  return passed > total ? [1, 0] : [passed, total - passed];
};

export const isSubscriptionLastMonth = (endDate: string) => diff(endDate, new Date(), 'days') < 30;

export const isSubscriptionExpired = (endDate: string) => diff(endDate, new Date(), 'days') < 0;

export const isSubscriptionNotStarted = (startDate: string) =>
  diff(startDate, new Date(), 'days') > 0;

export const isAllBranchesExpired = (branches: Array<SchoolBrancheType>): boolean => {
  if (branches?.length) {
    return branches
      .map((branch: SchoolBrancheType): boolean =>
        isSubscriptionExpired(branch?.subscription?.endDate),
      )
      .every(Boolean);
  }
  return false;
};
