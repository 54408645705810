import { RecordingsType } from 'store/contentLibrary/types';

export enum LessonWeekTypes {
  current = 'current',
  overdue = 'overdue',
  additional = 'additional',
}

export enum AdditionalLessonWeekTypes {
  Lesson = 0,
  LiveClass = 1,
  FileLesson = 2,
}

export type AdditionalLessonWeekType = {
  id?: string;
  isOverflowStudent?: boolean;
  file?: string;
  eventId?: number;
  isCompleted?: boolean;
};

export type LessonWeekType = AdditionalLessonWeekType & {
  subjectId: string;
  subjectName: string;
  lessonId: string;
  lessonName: string;
  lastEventId: string;
  progress?: number;
  start?: Date;
  end: Date;
  week?: Date;
  lessonType?: AdditionalLessonWeekTypes;
  link?: string;
  recordings?: Array<RecordingsType>;
  subjectIllustration?: string;
  isCompleted?: boolean;
};

export type LessonsWeekListType = {
  current?: LessonWeekType[];
  overdue?: LessonWeekType[];
  weekNo?: number;
};

export type exercisesLessonsArrayType = {
  lessonName: string;
  lessonStatus: string;
  subjectName: string;
  unitName: string;
};

export type ExercisesListType = {
  accomplishedExercises: string;
  exercisesLessonsArray: exercisesLessonsArrayType[];
  totalAvailableExercises: string;
};

export type LiveEventResponseType = {
  id: string;
  subjectId: string;
  subjectName: string;
  eventName: string;
  endDate: string;
  recordingLink: string;
  created: string;
  recordings?: RecordingsType[];
  eventId?: number;
  isOverflowStudent?: boolean;
  subjectIllustration?: string;
  isCompleted?: boolean;
};

export type examsLessonsArrayType = {
  lessonName: string;
  subjectName: string;
  unitName: string;
  lessonStatus: string;
  subjectId: string;
  eventId: string;
  lessonStatusEn?: string;
};

export type exerciseLessonsArrayType = {
  lessonName: string;
  subjectName: string;
  unitName: string;
  lessonStatus: string;
  subjectId: string;
  eventId: string;
  lessonStatusEn?: string;
};

export type ExamsListType = {
  accomplishedExams: string;
  examsLessonsArray: examsLessonsArrayType[];
  totalAvailableExams: string;
};
