import { createAsyncAction } from 'typesafe-actions';

import { studentPreparationMaterialType } from './types';

export const loadStudentMaterialAction = createAsyncAction(
  '@@preparationMaterial/LOAD_MATERIAL_REQUEST',
  '@@preparationMaterial/LOAD_MATERIAL_SUCCESS',
  '@@preparationMaterial/LOAD_MATERIAL_FAILURE',
)<undefined, studentPreparationMaterialType, string>();

export const loadTeacherMaterialAction = createAsyncAction(
  '@@preparationMaterial/TEACHER_MATERIAL_REQUEST',
  '@@preparationMaterial/TEACHER_MATERIAL_SUCCESS',
  '@@preparationMaterial/TEACHER_MATERIAL_FAILURE',
)<undefined, studentPreparationMaterialType, string>();

export const downloadStudentMaterialAction = createAsyncAction(
  '@@preparationMaterial/DOWNLOAD_MATERIAL_REQUEST',
  '@@preparationMaterial/DOWNLOAD_MATERIAL_SUCCESS',
  '@@preparationMaterial/DOWNLOAD_MATERIAL_FAILURE',
)<any, File, string>();

export const uploadPreparationMaterialAction = createAsyncAction(
  '@@preparationMaterial/UPLOAD_MATERIAL_REQUEST',
  '@@preparationMaterial/UPLOAD_MATERIAL_SUCCESS',
  '@@preparationMaterial/UPLOAD_MATERIAL_FAILURE',
)<any, studentPreparationMaterialType, any>();

export const updatePreparationMaterialAction = createAsyncAction(
  '@@preparationMaterial/UPDATE_MATERIAL_REQUEST',
  '@@preparationMaterial/UPDATE_MATERIAL_SUCCESS',
  '@@preparationMaterial/UPDATE_MATERIAL_FAILURE',
)<any, studentPreparationMaterialType, any>();

export const deletePreparationMaterialAction = createAsyncAction(
  '@@preparationMaterial/DELETE_MATERIAL_REQUEST',
  '@@preparationMaterial/DELETE_MATERIAL_SUCCESS',
  '@@preparationMaterial/DELETE_MATERIAL_FAILURE',
)<any, any, any>();
