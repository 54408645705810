import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { loadLessonsAction } from './actions';
import { getLessons } from './api';
import { LessonType } from './types';

function* loadLessonsRequest(
  action: ReturnType<typeof loadLessonsAction.request>,
): SagaIterator<void> {
  try {
    const units: LessonType[] = yield call(getLessons, action.payload);
    yield put(loadLessonsAction.success(units));
  } catch (error) {
    yield put(loadLessonsAction.failure(error));
  }
}

function* lessonsSaga(): SagaIterator {
  yield all([takeEvery(loadLessonsAction.request, loadLessonsRequest)]);
}

export default lessonsSaga;
