import { getPngURL, getSvgURL } from 'lib';

import { ROUTES } from 'constants/routes';
import { DISABLE_B2C_GROUP_CHAT, DISABLE_B2C_REWARDS } from 'constants/settings';
import { isSummerSemester } from 'utils/summerSemesterUtils';
import { getIntl } from 'utils/intl';
import { Gender } from 'store/account/types';

import { MenuItemType } from '../components/Sidebar/types';

export const studentMenu = (
  isB2BStudent?: boolean,
  grade?: number | undefined,
  gender?: Gender | null,
): MenuItemType[] => {
  const { formatMessage } = getIntl();
  const menu: MenuItemType[] = [
    {
      id: 'sidebar__dashboard',
      path: ROUTES.root,
      label: formatMessage({ id: 'dashboard.title', defaultMessage: 'Dashboard' }),
      icon: 'dashboard',
      orderIndex: 1,
    },
    {
      id: 'sidebar__learning_path',
      path: ROUTES.learningPath,
      label: formatMessage({ id: 'learning-path.title', defaultMessage: 'Learning Path' }),
      icon: 'learning-path',
      orderIndex: 2,
    },
    {
      id: 'sidebar__schedule',
      path: ROUTES.schedule,
      label: formatMessage({ id: 'schedule.title', defaultMessage: 'Schedule' }),
      icon: 'schedule',
      hide: isSummerSemester,
      orderIndex: 3,
    },
    {
      id: 'sidebar__exercises',
      path: ROUTES.exercises,
      label: formatMessage({ id: 'exercises.title', defaultMessage: 'Exercises' }),
      icon: 'exercise',
      hide: isSummerSemester,
      orderIndex: 4,
    },
    {
      id: 'sidebar__reinforcements',
      path: ROUTES.quizExam,
      label: formatMessage({ id: 'reinforcements.title', defaultMessage: 'Reinforcements' }),
      image: getSvgURL('reinforcements'),
      hide: isSummerSemester,
      orderIndex: 5,
    },
    {
      id: 'sidebar__exams',
      path: ROUTES.studentExams,
      label: formatMessage({ id: 'exams.examinations.title', defaultMessage: 'Examinations' }),
      icon: 'exam',
      hide: isSummerSemester,
      orderIndex: 6,
    },
    {
      id: 'sidebar__question_bank',
      path: ROUTES.questionBankSubjects,
      label: formatMessage({ id: 'question-bank.title', defaultMessage: 'Question Bank' }),
      image: getPngURL('question-bank'),
      hide: isSummerSemester,
      orderIndex: 8,
    },
    {
      id: 'sidebar__content_library',
      path: ROUTES.contentLibrary,
      label: formatMessage({ id: 'content-library.title', defaultMessage: 'Content Library' }),
      icon: 'question-bank',
      hide: isSummerSemester,
      orderIndex: 9,
    },
    {
      id: 'sidebar__reports',
      path: ROUTES.selfReport,
      label: formatMessage({ id: 'b2bTeacher.reports.title', defaultMessage: 'Reports' }),
      image: getSvgURL('report'),
      hide: isSummerSemester,
      orderIndex: 10,
    },
  ];

  if (!DISABLE_B2C_GROUP_CHAT && gender) {
    menu.push({
      id: 'sidebar__chatgroup',
      path: ROUTES.groupChat,
      label: formatMessage({ id: 'group-chat.title', defaultMessage: 'Chat Group' }),
      icon: 'group-chat',
      orderIndex: 11,
    });
  }

  if (!DISABLE_B2C_REWARDS) {
    menu.push({
      id: 'sidebar__rewards',
      path: ROUTES.rewards,
      label: formatMessage({ id: 'side.menu.rewards.title', defaultMessage: 'Rewards' }),
      icon: 'reward',
      hide: isSummerSemester,
      orderIndex: 13,
    });
  }

  if (grade === 12) {
    menu.push({
      id: 'sidebar__preparation',
      path: ROUTES.preparation,
      label: formatMessage({
        id: 'preparation.material.nav.university.admission.test',
        defaultMessage: 'University Admission Tests',
      }),
      icon: 'admission-test',
      hide: isSummerSemester,
      orderIndex: 14,
    });
  }

  if (isB2BStudent) {
    menu.push(
      {
        id: 'sidebar__assignments',
        path: ROUTES.assignments,
        label: formatMessage({ id: 'assignments.title', defaultMessage: 'Assignments' }),
        image: getSvgURL('assignment'),
        hide: isSummerSemester,
        orderIndex: 7,
      },
      {
        id: 'sidebar__chat',
        path: ROUTES.Chat,
        label: formatMessage({
          id: 'chat.teacher.title',
          defaultMessage: 'Teacher Communication',
        }),
        hide: isSummerSemester,
        icon: 'group-chat',
        orderIndex: 12,
      },
    );

    if (!DISABLE_B2C_GROUP_CHAT && gender) {
      menu.push({
        id: 'sidebar__chatgroup',
        path: ROUTES.groupChat,
        label: formatMessage({ id: 'group-chat.title', defaultMessage: 'Chat Group' }),
        icon: 'group-chat',
        orderIndex: 11,
      });
    }

    if (DISABLE_B2C_REWARDS) {
      menu.push({
        id: 'sidebar__rewards',
        path: ROUTES.rewards,
        label: formatMessage({ id: 'side.menu.rewards.title', defaultMessage: 'Rewards' }),
        icon: 'reward',
        hide: isSummerSemester,
        orderIndex: 13,
      });
    }
  }

  return menu
    .sort((a: MenuItemType, b: MenuItemType) => {
      const order1 = a.orderIndex || 0;
      const order2 = b.orderIndex || 0;

      return order1 - order2;
    })
    .filter((m) => !m.hide);
};
