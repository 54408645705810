import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import cn from 'classnames';
import Modal from 'react-modal';
import { map } from 'lodash/fp';
import { uniqueId } from 'lodash';
import { Col, Row } from 'react-grid-system';
import { useLockBodyScroll, useToggle } from 'react-use';

import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { SVG } from '../SVG/SVG';
import { Types } from './Popup.constants';
import style from './Popup.module.scss';
import { useScreenInclude } from '../../hooks/useScreenInclude';

Modal.setAppElement('body');

export type PopupButtonProps = {
  type?: string;
  text?: string;
  isClose?: boolean;
  onClick?: Function;
  disabled?: boolean;
};

export type PopupProps = {
  type?: keyof typeof Types;
  title?: string;
  isOpen?: boolean;
  children?: ReactNode;
  buttons?: PopupButtonProps[];
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

export const PopupContent: FunctionComponent<PopupProps> = ({
  title,
  children,
  buttons,
  type = Types.warning,
  onClose = (): void => {},
}) => {
  const isMobile = useScreenInclude(['xs', 'sm']);
  return (
    <div className={style.modalContent}>
      {title && (
        <header className={cn(style.header)}>
          <figure className={style.illustration}>
            <SVG name="popup-illustration" className={cn(style[`header-${type}`])} />
          </figure>
          <div className={style.title}>
            <Text type="heading3">{title}</Text>
          </div>
        </header>
      )}
      <main className={style.content}>{children}</main>

      {buttons && (
        <footer className={style.footer}>
          <Row justify="end" gutterWidth={isMobile ? 16 : undefined}>
            {map(
              ({ text, isClose, onClick, ...rest }: any) => (
                <Col xs="content" key={uniqueId('popup-button-')}>
                  <Button {...rest} onClick={(isClose && onClose) || onClick}>
                    {text}
                  </Button>
                </Col>
              ),
              buttons,
            )}
          </Row>
        </footer>
      )}
    </div>
  );
};

export const Popup: FunctionComponent<PopupProps> = ({
  title,
  children,
  buttons,
  isOpen = false,
  type,
  onClose = (): void => {},
}) => {
  const [locked, toggleLocked] = useToggle(false);
  useLockBodyScroll(locked);

  useEffect(() => {
    toggleLocked(isOpen);
  }, [isOpen, toggleLocked]);

  return (
    <Modal
      isOpen={isOpen}
      className={style.modal}
      overlayClassName={style.overlay}
      onRequestClose={onClose}
    >
      <PopupContent title={title} type={type} buttons={buttons} onClose={onClose}>
        {children}
      </PopupContent>
    </Modal>
  );
};
