import { IN_APP_RENDERING_VIEW } from 'constants/entities';
import { useLocation } from 'react-use';

export type useInAppViewType = {
  isInAppView: boolean;
};

export const useInAppView = (): useInAppViewType => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const viewType = searchParams.get('view') || '';

  return {
    isInAppView: viewType === IN_APP_RENDERING_VIEW,
  };
};
