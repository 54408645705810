import { createAsyncAction, createAction } from 'typesafe-actions';
import { StudentInfoPayload, StudentInfoType, DeletePayload } from './types';

export const loadStudentProfile = createAsyncAction(
  '@@studentProfile/LOAD_REQUEST',
  '@@studentProfile/LOAD_SUCCESS',
  '@@studentProfile/LOAD_FAILURE',
)<StudentInfoPayload, StudentInfoType, string>();

export const resetStudentAction = createAction('@@studentProfile/RESET')();
export const deleteStudentAction = createAsyncAction(
  '@@deleteStudent/LOAD_REQUEST',
  '@@deleteStudent/LOAD_SUCCESS',
  '@@deleteStudent/LOAD_FAILURE',
)<DeletePayload, any, any>();

export const updateStudentPasswordAction = createAsyncAction(
  '@@studentPassword/UPDATE_REQUEST',
  '@@studentPassword/UPDATE_SUCCESS',
  '@@studentPassword/UPDATE_FAILURE',
)<any, undefined, any>();

export const updateStudentAction = createAsyncAction(
  '@@student/UPDATE_REQUEST',
  '@@student/UPDATE_SUCCESS',
  '@@student/UPDATE_FAILURE',
)<any, any, any>();
