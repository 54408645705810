import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import { reportsDataType } from './types';

type reportsActionType = ActionType<typeof actions>;
const {
  fetchGradesAction,
  resetReportsAction,
  fetchGradeDetailAction,
  studentReportsAction,
  reportsStatsScaleAction,
  resetReportsSubjectsAction,
  parentDashboardReportsAction,
  updateReportsChildAction,
  fetchClassroomsAction,
  fetchClassroomDetailsAction,
  fetchStudentProgressClassesAction,
  fetchPerformanceStatsAction,
  fetchStatsStudentsListAction,
  sendTeacherEmailAction,
  fetchRecommendationClasses,
  fetchClassroomRecommendations,
  fetchLiveClassRecommendations,
  reportsStatsScaleByGradeAction,
} = actions;

const initialState = {
  data: {} as reportsDataType,
  fetching: {
    fetchGrades: false,
    fetchGradeDetail: false,
    fetchReports: false,
    updateChild: false,
    fetchClassrooms: false,
    fetchClassroomDetails: false,
    fetchStudentProgressClasses: false,
    fetchPerformanceStatsAction: false,
    fetchStatsStudentsList: false,
    sendingTeacherEmail: false,
    fetchingRecommendationClasses: false,
    fetchingClassroomRecommendations: false,
    fetchingLiveClassRecommendations: false,
  },
  errors: {
    errorGrades: '',
    errorGradeDetail: '',
    errorReports: '',
    errorParentDashboardReports: '',
    updateChild: '',
    errorClassrooms: '',
    errorClassroomDetails: '',
    errorStudentProgressClasses: '',
    errorPerformanceStats: '',
    errorStatsStudentsList: '',
    errorSendingTeacherEmail: '',
    errorRecommendationClasses: '',
    errorClassroomRecommendations: '',
    errorLiveClassRecommendations: '',
  },
};

const data = createReducer<reportsDataType, reportsActionType>(initialState.data)
  .handleAction([fetchGradesAction.success], (state, action) => ({
    ...state,
    ...{ grades: action.payload },
  }))
  .handleAction([fetchGradeDetailAction.success], (state, action) => ({
    ...state,
    ...{ gradeDetail: action.payload },
  }))
  .handleAction([studentReportsAction.success], (state, action) => ({
    ...state,
    ...{ studentReports: action.payload },
  }))
  .handleAction(
    [reportsStatsScaleAction.success, reportsStatsScaleByGradeAction.success],
    (state, action) => ({
      ...state,
      ...{ statsScales: action.payload },
    }),
  )
  .handleAction([parentDashboardReportsAction.success], (state, action) => ({
    ...state,
    ...{ parentsDashboardReports: action.payload },
  }))
  .handleAction([updateReportsChildAction.success], (state, action) => {
    let reportsData = state.studentReports;
    if (reportsData?.student) {
      reportsData = { ...reportsData, student: { ...reportsData.student, ...action.payload } };
    }

    return {
      ...state,
      studentReports: reportsData,
    };
  })
  .handleAction([fetchClassroomsAction.success], (state, action) => ({
    ...state,
    ...{ classrooms: action.payload },
  }))
  .handleAction([fetchClassroomDetailsAction.success], (state, action) => ({
    ...state,
    ...{ classroomDetails: action.payload },
  }))
  .handleAction([fetchStudentProgressClassesAction.success], (state, action) => ({
    ...state,
    ...{ studentProgressClasses: action.payload },
  }))
  .handleAction([fetchPerformanceStatsAction.success], (state, action) => ({
    ...state,
    ...{ performanceStats: action.payload },
  }))
  .handleAction([fetchStatsStudentsListAction.success], (state, action) => ({
    ...state,
    ...{ statsStudentsList: action.payload },
  }))
  .handleAction([fetchRecommendationClasses.success], (state, action) => ({
    ...state,
    ...{ recommendationClasses: action.payload },
  }))
  .handleAction([fetchClassroomRecommendations.success], (state, action) => ({
    ...state,
    ...{ classroomRecommendations: action.payload },
  }))
  .handleAction([fetchLiveClassRecommendations.success], (state, action) => ({
    ...state,
    ...{ liveClassRecommendations: action.payload },
  }))
  .handleAction(resetReportsAction, () => initialState.data)
  .handleAction(resetReportsSubjectsAction, (state) => ({ ...state, studentReports: undefined }));

const fetching = combineReducers({
  fetchGrades: createReducer<boolean, reportsActionType>(initialState.fetching.fetchGrades)
    .handleAction(fetchGradesAction.request, () => true)
    .handleAction([fetchGradesAction.success, fetchGradesAction.failure], () => false)
    .handleAction([resetReportsAction], () => initialState.fetching.fetchGrades),
  fetchGradeDetail: createReducer<boolean, reportsActionType>(
    initialState.fetching.fetchGradeDetail,
  )
    .handleAction(fetchGradeDetailAction.request, () => true)
    .handleAction([fetchGradeDetailAction.success, fetchGradeDetailAction.failure], () => false)
    .handleAction([resetReportsAction], () => initialState.fetching.fetchGradeDetail),
  fetchReports: createReducer<boolean, reportsActionType>(initialState.fetching.fetchReports)
    .handleAction([studentReportsAction.request, parentDashboardReportsAction.request], () => true)
    .handleAction(
      [
        studentReportsAction.success,
        studentReportsAction.failure,
        parentDashboardReportsAction.success,
        parentDashboardReportsAction.failure,
      ],
      () => false,
    )
    .handleAction(
      [resetReportsAction, resetReportsSubjectsAction],
      () => initialState.fetching.fetchReports,
    ),
  updateChild: createReducer<boolean, reportsActionType>(initialState.fetching.updateChild)
    .handleAction(updateReportsChildAction.request, () => true)
    .handleAction(
      [updateReportsChildAction.success, updateReportsChildAction.failure],
      () => false,
    ),
  fetchClassroooms: createReducer<boolean, reportsActionType>(initialState.fetching.fetchClassrooms)
    .handleAction(fetchClassroomsAction.request, () => true)
    .handleAction([fetchClassroomsAction.success, fetchClassroomsAction.failure], () => false),
  fetchClassrooomDetails: createReducer<boolean, reportsActionType>(
    initialState.fetching.fetchGradeDetail,
  )
    .handleAction(fetchClassroomDetailsAction.request, () => true)
    .handleAction(
      [fetchClassroomDetailsAction.success, fetchClassroomDetailsAction.failure],
      () => false,
    ),
  fetchStudentProgressClasses: createReducer<boolean, reportsActionType>(
    initialState.fetching.fetchStudentProgressClasses,
  )
    .handleAction(fetchStudentProgressClassesAction.request, () => true)
    .handleAction(
      [fetchStudentProgressClassesAction.success, fetchStudentProgressClassesAction.failure],
      () => false,
    ),

  fetchPerformanceStatsAction: createReducer<boolean, reportsActionType>(
    initialState.fetching.fetchPerformanceStatsAction,
  )
    .handleAction(fetchPerformanceStatsAction.request, () => true)
    .handleAction(
      [fetchPerformanceStatsAction.success, fetchPerformanceStatsAction.failure],
      () => false,
    ),
  fetchStatsStudentsList: createReducer<boolean, reportsActionType>(
    initialState.fetching.fetchStatsStudentsList,
  )
    .handleAction(fetchStatsStudentsListAction.request, () => true)
    .handleAction(
      [fetchStatsStudentsListAction.success, fetchStatsStudentsListAction.failure],
      () => false,
    ),
  sendingTeacherEmail: createReducer<boolean, reportsActionType>(
    initialState.fetching.sendingTeacherEmail,
  )
    .handleAction(sendTeacherEmailAction.request, () => true)
    .handleAction([sendTeacherEmailAction.success, sendTeacherEmailAction.failure], () => false),

  fetchRecommendationClasses: createReducer<boolean, reportsActionType>(
    initialState.fetching.fetchingRecommendationClasses,
  )
    .handleAction(fetchRecommendationClasses.request, () => true)
    .handleAction(
      [fetchRecommendationClasses.success, fetchRecommendationClasses.failure],
      () => false,
    ),
  fetchClassroomRecommendations: createReducer<boolean, reportsActionType>(
    initialState.fetching.fetchingRecommendationClasses,
  )
    .handleAction(fetchClassroomRecommendations.request, () => true)
    .handleAction(
      [fetchClassroomRecommendations.success, fetchClassroomRecommendations.failure],
      () => false,
    ),

  fetchLiveClassRecommendations: createReducer<boolean, reportsActionType>(
    initialState.fetching.fetchingLiveClassRecommendations,
  )
    .handleAction(fetchLiveClassRecommendations.request, () => true)
    .handleAction(
      [fetchLiveClassRecommendations.success, fetchLiveClassRecommendations.failure],
      () => false,
    ),
});

const errors = combineReducers({
  errorGrades: createReducer<string, reportsActionType>(initialState.errors.errorGrades)
    .handleAction(fetchGradesAction.failure, (state, { payload }) => payload)
    .handleAction(
      [fetchGradesAction.success, resetReportsAction],
      () => initialState.errors.errorGrades,
    ),
  errorGradeDetail: createReducer<string, reportsActionType>(initialState.errors.errorGradeDetail)
    .handleAction(fetchGradeDetailAction.failure, (state, { payload }) => payload)
    .handleAction(
      [fetchGradeDetailAction.success, resetReportsAction],
      () => initialState.errors.errorGradeDetail,
    ),
  errorReports: createReducer<string, reportsActionType>(initialState.errors.errorReports)
    .handleAction([studentReportsAction.failure], (state, { payload }) =>
      typeof payload === 'string' ? payload : payload.message,
    )
    .handleAction(
      [studentReportsAction.success, resetReportsAction, resetReportsSubjectsAction],
      () => initialState.errors.errorReports,
    ),
  errorParentDashboardReports: createReducer<string, reportsActionType>(
    initialState.errors.errorParentDashboardReports,
  )
    .handleAction([studentReportsAction.failure], (state, { payload }) =>
      typeof payload === 'string' ? payload : payload.message,
    )
    .handleAction(
      [parentDashboardReportsAction.success, resetReportsAction, resetReportsSubjectsAction],
      () => initialState.errors.errorReports,
    ),
  updateChild: createReducer<string, reportsActionType>(initialState.errors.updateChild)
    .handleAction(updateReportsChildAction.failure, (state, { payload }) => payload)
    .handleAction([updateReportsChildAction.success], () => initialState.errors.updateChild),

  errorClassrooms: createReducer<string, reportsActionType>(initialState.errors.errorClassrooms)
    .handleAction(fetchClassroomsAction.failure, (state, { payload }) => payload)
    .handleAction(
      [fetchClassroomsAction.success, resetReportsAction],
      () => initialState.errors.errorClassrooms,
    ),
  errorClassroomDetails: createReducer<string, reportsActionType>(
    initialState.errors.errorClassroomDetails,
  )
    .handleAction(fetchClassroomDetailsAction.failure, (state, { payload }) => payload)
    .handleAction(
      [fetchClassroomDetailsAction.success, resetReportsAction],
      () => initialState.errors.errorClassroomDetails,
    ),

  errorStudentProgressClasses: createReducer<string, reportsActionType>(
    initialState.errors.errorStudentProgressClasses,
  )
    .handleAction(fetchStudentProgressClassesAction.failure, (state, { payload }) => payload)
    .handleAction(
      [fetchStudentProgressClassesAction.success, resetReportsAction],
      () => initialState.errors.errorStudentProgressClasses,
    ),
  errorPerformanceStats: createReducer<string, reportsActionType>(
    initialState.errors.errorPerformanceStats,
  )
    .handleAction(fetchPerformanceStatsAction.failure, (state, { payload }) => payload)
    .handleAction(
      [fetchPerformanceStatsAction.success, resetReportsAction],
      () => initialState.errors.errorPerformanceStats,
    ),

  errorStatsStudentsList: createReducer<string, reportsActionType>(
    initialState.errors.errorStatsStudentsList,
  )
    .handleAction(fetchStatsStudentsListAction.failure, (state, { payload }) => payload)
    .handleAction(
      [fetchStatsStudentsListAction.success, resetReportsAction],
      () => initialState.errors.errorStatsStudentsList,
    ),
  errorSendingTeacherEmail: createReducer<string, reportsActionType>(
    initialState.errors.errorSendingTeacherEmail,
  )
    .handleAction(sendTeacherEmailAction.failure, (state, { payload }) => payload)
    .handleAction(
      [sendTeacherEmailAction.success, resetReportsAction],
      () => initialState.errors.errorSendingTeacherEmail,
    ),
  errorRecommendationClasses: createReducer<string, reportsActionType>(
    initialState.errors.errorRecommendationClasses,
  )
    .handleAction(fetchRecommendationClasses.failure, (state, { payload }) =>
      typeof payload === 'string' ? payload : payload.message,
    )
    .handleAction(
      [fetchRecommendationClasses.success, resetReportsAction],
      () => initialState.errors.errorClassrooms,
    ),
  errorClassroomRecommendations: createReducer<string, reportsActionType>(
    initialState.errors.errorRecommendationClasses,
  )
    .handleAction(fetchClassroomRecommendations.failure, (state, { payload }) =>
      typeof payload === 'string' ? payload : payload.message,
    )
    .handleAction(
      [fetchClassroomRecommendations.success, resetReportsAction],
      () => initialState.errors.errorClassrooms,
    ),

  errorLiveClassRecommendations: createReducer<string, reportsActionType>(
    initialState.errors.errorLiveClassRecommendations,
  )
    .handleAction(fetchLiveClassRecommendations.failure, (state, { payload }) =>
      typeof payload === 'string' ? payload : payload.message,
    )
    .handleAction(
      [fetchLiveClassRecommendations.success, resetReportsAction],
      () => initialState.errors.errorClassrooms,
    ),
});

const reportsReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type reportsType = StateType<typeof reportsReducer>;

export default reportsReducer;
