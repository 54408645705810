import { compose, map } from 'lodash/fp';
import camelcaseKeys from 'camelcase-keys';
import {
  LessonWeekType,
  LessonsWeekListType,
  LiveEventResponseType,
  AdditionalLessonWeekTypes,
  ExercisesListType,
  ExamsListType,
} from './types';

export type LessonsWeekTypeResponse = {
  subjectId: string;
  subjectName: string;
  lessonId: string;
  lastEventId?: string;
  lessonName: string;
  progress: number;
  start: string;
  end: string;
  week: string;
  created: string;
};

export type AttachmentsFileLessons = {
  id: number;
  created: string;
  file_type: string;
  file: string;
  modified: string;
  name: string;
  subject: {
    grade: string;
    id: string;
    subject: string;
  };
};
export type FileLessonsInfoResponse = {
  id: number;
  created: string;
  attachments: AttachmentsFileLessons;
  start?: string;
  end?: string;
  week?: string;
  isCompleted?: boolean;
};

export type LessonsWeekListTypeResponse = {
  currentWeekLessons: LessonsWeekTypeResponse[];
  overdueLessons: LessonsWeekTypeResponse[];
  currentWeekNo: string;
};

const dataLessonsWeekAdapter = (data: LessonsWeekTypeResponse[]): LessonWeekType[] => {
  return compose(
    map((lesson: LessonsWeekTypeResponse) => {
      return {
        ...lesson,
        start: lesson.start ? new Date(lesson.start) : undefined,
        end: new Date(lesson.end),
        week: lesson.week ? new Date(lesson.week) : undefined,
        created: new Date(lesson.created),
      };
    }),
  )(data);
};

const dataFileLessonsAdapter = (data: FileLessonsInfoResponse[]): LessonWeekType[] => {
  return compose(
    map((lesson: FileLessonsInfoResponse) => {
      return {
        start: lesson.start ? new Date(lesson.start) : undefined,
        end: lesson.end ? new Date(lesson.end) : undefined,
        week: lesson.week ? new Date(lesson.week) : undefined,
        created: new Date(lesson.created),
        subjectId: lesson.attachments.id,
        subjectName: lesson.attachments.subject.subject,
        lessonId: lesson.id,
        lessonName: lesson.attachments.name,
        file: lesson.attachments.file,
        isCompleted: lesson.isCompleted,
      };
    }),
  )(data);
};

export const dataLessonsWeekListAdapter = (
  data: LessonsWeekListTypeResponse,
): LessonsWeekListType => {
  return {
    current: dataLessonsWeekAdapter(camelcaseKeys(data.currentWeekLessons)),
    overdue: dataLessonsWeekAdapter(camelcaseKeys(data.overdueLessons)),
    weekNo: +data.currentWeekNo,
  };
};

export const dataExercisesListAdapter = (data: any): ExercisesListType => {
  return {
    accomplishedExercises: data.accomplishedExercises,
    exercisesLessonsArray: camelcaseKeys(data.exercisesLessonsArray),
    totalAvailableExercises: data.totalAvailableExercises,
  };
};

export const dataExamsListAdapter = (data: any): ExamsListType => {
  return {
    accomplishedExams: data.accomplishedReinforcements,
    examsLessonsArray: data.reinforcementEventsList,
    totalAvailableExams: data.totalAvailableReinforcements,
  };
};

export const dataAdditionalLessonsAdapter = (
  lessons: LessonsWeekTypeResponse[],
  liveEvents: LiveEventResponseType[],
  fileLessons: FileLessonsInfoResponse[],
): Array<LessonWeekType> => {
  const lessonsWeek = dataLessonsWeekAdapter(lessons).map((lesson) => {
    return {
      ...lesson,
      lessonType: AdditionalLessonWeekTypes.Lesson,
    };
  });

  const fileLessonsInfo = dataFileLessonsAdapter(fileLessons).map((lesson) => {
    return {
      ...lesson,
      lessonType: AdditionalLessonWeekTypes.FileLesson,
    };
  });

  const liveEventsWeek = liveEvents.map((event) => ({
    ...event,
    lessonId: event.id,
    lessonName: event.eventName,
    end: new Date(event.endDate),
    created: new Date(event.created),
    link: event.recordingLink,
    lastEventId: '',
    lessonType: AdditionalLessonWeekTypes.LiveClass,
  }));
  return [...lessonsWeek, ...liveEventsWeek, ...fileLessonsInfo];
};
