import { Gender } from 'store/account/types';
import { getIntl } from './intl';

export const getUserTitle = (userType: string, gender: Gender | null): string => {
  const { formatMessage } = getIntl();
  const isMale = gender === 'm';
  const titles: Record<string, string> = {
    mentor: formatMessage({ id: 'user.title.mentor', defaultMessage: 'Mentor' }),
    parent: formatMessage({ id: 'user.title.parent', defaultMessage: 'Parent' }),
    teacher: formatMessage({ id: 'user.title.teacher', defaultMessage: 'Teacher' }),
    alw_teacher: formatMessage({ id: 'user.title.teacher', defaultMessage: 'Teacher' }),
    student: isMale
      ? formatMessage({ id: 'user.title.student', defaultMessage: 'Student' })
      : formatMessage({ id: 'user.title.student.female', defaultMessage: 'Student' }),
    school_admin: formatMessage({ id: 'user.title.school.admin', defaultMessage: 'School Admin' }),
    branch_manager: formatMessage({
      id: 'user.title.school.branch.manager',
      defaultMessage: 'School Branch Manager',
    }),
    alwAdmin: formatMessage({
      id: 'admin.label',
      defaultMessage: 'ALW Admin',
    }),
    contentAuthor: formatMessage({
      id: 'content.author.label',
      defaultMessage: 'Content Author',
    }),
  };
  return titles[userType];
};
