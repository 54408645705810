import React, { SyntheticEvent } from 'react';
import cn from 'classnames';
import { map } from 'lodash/fp';

import style from './SwitchGroup.module.scss';

export type SwitchGroupOptions = {
  caption: string | JSX.Element;
  value: string;
  id?: number;
  itemDisabled?: boolean;
};

type SwitchGroupProps = {
  name: string;
  defaultValue?: string | number;
  disabled?: boolean;
  items?: SwitchGroupOptions[];
  type?: 'primary' | 'secondary' | 'dark' | 'yellow' | 'smart';
  onChange?: (e: SyntheticEvent, learningProfileId: number) => void;
};

export const SwitchGroup = ({
  name,
  defaultValue = '',
  disabled = false,
  items = [],
  onChange = (): void => {},
  type = 'primary',
}: SwitchGroupProps) => (
  <fieldset className={cn(style.fieldset, style[type])}>
    <div
      className={cn(style.container, {
        [style.disabled]: disabled,
      })}
    >
      {map(({ caption, value, id, itemDisabled }: SwitchGroupOptions) => (
        <label
          htmlFor={`radio-${name}-${value}-${id}`}
          key={`radio-${name}-${value}-${id}`}
          className={cn(style.label, style[type])}
        >
          <input
            name={name}
            value={value}
            type="radio"
            disabled={disabled || itemDisabled}
            id={`radio-${name}-${value}-${id}`}
            onChange={(e): void => onChange(e, id as number)}
            checked={defaultValue === value}
            className={cn(style.input, {
              [style.checked]: defaultValue === value,
            })}
          />
          <span className={cn(style.caption)}>{caption}</span>
        </label>
      ))(items)}
    </div>
  </fieldset>
);
