import { defineMessages } from 'react-intl';

export const messages: any = defineMessages({
  title: {
    id: 'header.notification.title',
    defaultMessage: 'Notifications',
  },
  noNotification: {
    id: 'no.resutls.found',
    defaultMessage: 'No Results Found',
  },
});
