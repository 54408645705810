import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  CompleteEventType,
  EventType,
  NextEventType,
  QuestionPoolAttemptRequestType,
  QuestionPoolAttemptResponseType,
  QuestionPoolsDetailsType,
  QuestionPoolsGroupsRequestType,
  QuestionPoolsGroupsResponseType,
  StudentAttemptApi,
  StudentAttemptResult,
  SubmitQuestionsAnswerRequestType,
  SubmitQuestionsAnswerResponseType,
} from './types';

export const loadEventsAction = createAsyncAction(
  '@@events/LOAD_REQUEST',
  '@@events/LOAD_SUCCESS',
  '@@events/LOAD_FAILURE',
)<string, EventType[], string>();

export const loadNextEventAction = createAsyncAction(
  '@@events/LOAD_NEXT_REQUEST',
  '@@events/LOAD_NEXT_SUCCESS',
  '@@events/LOAD_NEXT_FAILURE',
)<string, NextEventType, string>();

export const setEventCompleteAction = createAction('@@events/SET_COMPLETE')<string>();

export const resetEventsAction = createAction('@@events/RESET')();

export const setCompleteAllBlocksAction = createAsyncAction(
  '@@events/SET_COMPLETE_REQUEST',
  '@@events/SET_COMPLETE_SUCCESS',
  '@@events/SET_COMPLETE_FAILURE',
)<string, CompleteEventType, string>();

export const loadQuestionPoolsDetailsAction = createAsyncAction(
  '@@events/LOAD_QUESTION_POOLS_DETAILS_REQUEST',
  '@@events/LOAD_QUESTION_POOLS_DETAILS_SUCCESS',
  '@@events/LOAD_QUESTION_POOLS_DETAILS_FAILURE',
)<string, QuestionPoolsDetailsType, string>();

export const startQuestionPoolsAttemptAction = createAsyncAction(
  '@@events/START_QUESTION_POOLS_ATTEMPT_REQUEST',
  '@@events/START_QUESTION_POOLS_ATTEMPT_SUCCESS',
  '@@events/START_QUESTION_POOLS_ATTEMPT_FAILURE',
)<QuestionPoolAttemptRequestType, QuestionPoolAttemptResponseType, string>();

export const resetQuestionPools = createAction('@@events/RESET_QUESTION_POOL')();

export const loadQuestionPoolsGroupsAction = createAsyncAction(
  '@@events/LOAD_QUESTION_POOLS_GROUPS_REQUEST',
  '@@events/LOAD_QUESTION_POOLS_GROUPS_SUCCESS',
  '@@events/LOAD_QUESTION_POOLS_GROUPS_FAILURE',
)<QuestionPoolsGroupsRequestType, QuestionPoolsGroupsResponseType, string>();

export const studentAttemptSubmissionAction = createAsyncAction(
  '@@events/STUDENT_ATTEMPT_SUBMISSION_REQUEST',
  '@@events/STUDENT_ATTEMPT_SUBMISSION_SUCCESS',
  '@@events/STUDENT_ATTEMPT_SUBMISSION_FAILURE',
)<StudentAttemptApi, StudentAttemptResult, string>();

export const submitQuestionGroupsAnswersAction = createAsyncAction(
  '@@events/SUBMIT_QUESTION_GROUPS_ANSWERS_REQUEST',
  '@@events/SUBMIT_QUESTION_GROUPS_ANSWERS_SUCCESS',
  '@@events/SUBMIT_QUESTION_GROUPS_ANSWERS_FAILURE',
)<SubmitQuestionsAnswerRequestType, SubmitQuestionsAnswerResponseType, string>();
