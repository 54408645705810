import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
  CanceledSubscriptionErrorType,
  cancelChildrenSubscriptionAction,
  loadChildrenSubscriptions,
  resetErrorsSubscriptionsActions,
  updateSubscription,
  updateChildAutoDeductAction,
} from './actions';
import * as actions from './actions';
import { ChildrenSubscriptionsType } from './types';

export type SubscriptionsActionType = ActionType<typeof actions>;
const initialErrors = {
  message: '',
  userId: null,
};

const data = createReducer<ChildrenSubscriptionsType[], SubscriptionsActionType>([])
  .handleAction(loadChildrenSubscriptions.success, (state, { payload }) => payload)
  .handleAction(updateSubscription.success, (state, { payload }) =>
    state.map((children) => {
      if (children.id === payload.userId) {
        return {
          ...children,
        };
      }
      return children;
    }),
  )
  .handleAction(updateChildAutoDeductAction, (state, { payload }) => {
    return state.map((child) => {
      if (child.id === payload.childId) {
        return { ...child, isSubscriptionAutoRenewal: payload.autoDeductionCheck };
      }
      return child;
    });
  });

const fetching = combineReducers({
  load: createReducer<boolean, SubscriptionsActionType>(true)
    .handleAction(loadChildrenSubscriptions.request, () => true)
    .handleAction(
      [loadChildrenSubscriptions.success, loadChildrenSubscriptions.failure],
      () => false,
    ),
  cancel: createReducer<boolean, SubscriptionsActionType>(true)
    .handleAction(cancelChildrenSubscriptionAction.request, () => true)
    .handleAction(
      [cancelChildrenSubscriptionAction.success, cancelChildrenSubscriptionAction.failure],
      () => false,
    ),
});

const errors = combineReducers({
  load: createReducer<string, SubscriptionsActionType>('')
    .handleAction(loadChildrenSubscriptions.failure, (state, { payload }) => payload)
    .handleAction(loadChildrenSubscriptions.success, () => ''),
  cancel: createReducer<CanceledSubscriptionErrorType, SubscriptionsActionType>(initialErrors)
    .handleAction(cancelChildrenSubscriptionAction.failure, (state, { payload }) => payload)
    .handleAction(cancelChildrenSubscriptionAction.success, () => initialErrors)
    .handleAction(resetErrorsSubscriptionsActions, () => initialErrors),
});

const childrenSubscriptionsReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type ChildrenSubscriptionsStateType = StateType<typeof childrenSubscriptionsReducer>;

export default childrenSubscriptionsReducer;
