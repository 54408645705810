import React, { createContext, useContext, useMemo, useState } from 'react';

type HeaderContextType = {
  isHeaderDisabled: boolean;
  setIsHeaderDisabled: (state: boolean) => void;
};

const HeaderContext = createContext<HeaderContextType>({
  isHeaderDisabled: false,
  setIsHeaderDisabled: () => {},
});

type Props = {
  children: React.ReactElement;
};

const HeaderContextProvider = ({ children }: Props) => {
  const [isHeaderDisabled, setIsHeaderDisabled] = useState(false);

  const contextValue = useMemo(
    () => ({ isHeaderDisabled, setIsHeaderDisabled }),
    [isHeaderDisabled, setIsHeaderDisabled],
  );

  return <HeaderContext.Provider value={contextValue}>{children}</HeaderContext.Provider>;
};

export const useHeaderContext = () => useContext(HeaderContext);

export default HeaderContextProvider;
