import * as Sentry from '@sentry/react';
import { ENV, SENTRY_DSN } from 'constants/settings';

export function setUpSentry() {
  if (ENV.PROD && SENTRY_DSN) {
    Sentry.init({
      environment: 'production',
      dsn: SENTRY_DSN,
      integrations: [
        Sentry.replayIntegration(),
        Sentry.replayCanvasIntegration(),
        Sentry.browserTracingIntegration({
          enableInp: true,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      tracePropagationTargets: [/^https?:\/\/(\w+\.)?alw\.sa/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}
