import { createAsyncAction } from 'typesafe-actions';
import {
  ScheduleDetailPayload,
  ScheduleFiltersType,
  SemesterScheduleClassType,
  SemesterScheduleSubjectType,
} from './types';

export const loadSemesterScheduleClassesAction = createAsyncAction(
  '@@semesterScheduleClasses/LOAD_REQUEST',
  '@@semesterScheduleClasses/LOAD_SUCCESS',
  '@@semesterScheduleClasses/LOAD_FAILURE',
)<undefined, SemesterScheduleClassType[], string>();

export const loadSemesterScheduleSubjectsAction = createAsyncAction(
  '@@semesterScheduleSubjects/LOAD_REQUEST',
  '@@semesterScheduleSubjects/LOAD_SUCCESS',
  '@@semesterScheduleSubjects/LOAD_FAILURE',
)<ScheduleDetailPayload, SemesterScheduleSubjectType[], string>();

export const loadSemesterScheduleFiltersAction = createAsyncAction(
  '@@semesterScheduleFilters/LOAD_REQUEST',
  '@@semesterScheduleFilters/LOAD_SUCCESS',
  '@@semesterScheduleFilters/LOAD_FAILURE',
)<string, ScheduleFiltersType, string>();
