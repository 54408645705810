import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';

export const defaultTheme = 'light';

export enum ThemeTypes {
  day = 'day',
  night = 'night',
}

type ThemeContextType = {
  theme: string;
};
const ThemeContext = React.createContext<ThemeContextType>({
  theme: defaultTheme,
});

type Props = {
  theme?: string;
  children: React.ReactNode;
};
export const ThemeProvider: FunctionComponent<Props> = ({ theme = defaultTheme, children }) => {
  const changeThemeHandler = useCallback((theme: string) => {
    const themeClass = document.body.classList;
    if (themeClass.length === 0) {
      themeClass.add(theme);
    } else {
      themeClass?.remove(themeClass?.[0]);
      themeClass.add(theme);
    }
  }, []);
  useEffect(() => {
    changeThemeHandler(theme);
  }, [changeThemeHandler, theme]);

  const themeHandler = useMemo(() => {
    return { theme, changeTheme: changeThemeHandler };
  }, [changeThemeHandler, theme]);

  return <ThemeContext.Provider value={themeHandler}>{children}</ThemeContext.Provider>;
};

export const useTheme = (): any => React.useContext(ThemeContext);
