import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { ButtonTypes as Types } from './NewButton.type';
import styles from './NewButton.module.scss';
import Spinner from '../Spinner/Spinner';

export type NewButtonProps = {
  style: Types[];
  type?: 'submit' | 'button' | 'reset' | undefined;
  onClick?: (e: SyntheticEvent) => void;
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  classes?: Array<string>;
  name?: string;
  title?: string;
};

export const ButtonTypes = Types;

export const NewButton: FunctionComponent<NewButtonProps> = (props) => {
  const {
    style,
    type = 'submit',
    children,
    onClick = (): void => {},
    disabled = false,
    loading = false,
    classes = [],
    title,
    ...rest
  } = props;
  const includedStyles = style.map((item) => styles[item]);
  return (
    <button
      disabled={disabled}
      type={type}
      className={classNames(includedStyles, ...classes)}
      onClick={onClick}
      title={title}
      {...rest}
    >
      {loading ? <Spinner /> : children}
    </button>
  );
};
