import React, { useContext, useEffect } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { IconCommon } from 'lib';

import { OrientationContext } from 'components/Orientation/OrientationContext';

import { orientationVideosSelector } from 'store/orientation/selectors';
import { orientationVideoAction } from 'store/orientation/actions';
import { HelpMenuProps } from 'store/orientation/types';

import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import styles from './Help.module.scss';
import Tutorials from './Tutorials';

const HelpMenus = ({ isOpenMenu, closeHelpTour, setOpenMenu }: HelpMenuProps) => {
  const dispatch = useDispatch();
  const { formatMessage: fm } = useIntl();
  const { userType } = useContext(OrientationContext);

  const orientationVideos = useSelector(orientationVideosSelector);

  const isGradeOneToThree = useIsGradeOneToThree();
  const helpTitle = fm({ id: 'orientation.video.user.guide', defaultMessage: 'User Guide' });
  const orientationLength = orientationVideos?.length || 0;

  useEffect(() => {
    if (isOpenMenu && !orientationLength) dispatch(orientationVideoAction.request({ userType }));
  }, [dispatch, isOpenMenu, orientationLength, userType]);

  return (
    <div
      className={cn(styles.menu, styles.optionsMenu, {
        [styles.hide]: !isOpenMenu,
        [styles.gradeMenu]: isGradeOneToThree,
      })}
    >
      <span className={styles.menu__close} onClick={closeHelpTour}>
        <IconCommon className="icon-cancel" />
      </span>
      <div className={styles.menu__user}>
        <div className={styles.menu__name}>{helpTitle}</div>
        <div className={styles.menu__role}>
          {fm({
            id: 'orientation.video.menu.description',
            defaultMessage: 'Learn to use the platform by clicking “Start tutorial”.',
          })}
        </div>
      </div>
      <Tutorials setOpenMenu={setOpenMenu} closeHelpTour={closeHelpTour} />
    </div>
  );
};

export default HelpMenus;
