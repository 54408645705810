import { createAsyncAction, createAction } from 'typesafe-actions';
import { ScheduleForTodayType, ScheduleForTodayRequestType } from './types';

export const loadScheduleForTodayAction = createAsyncAction(
  '@@scheduleForToday/LOAD_REQUEST',
  '@@scheduleForToday/LOAD_SUCCESS',
  '@@scheduleForToday/LOAD_FAILURE',
)<ScheduleForTodayRequestType, ScheduleForTodayType[], string>();

export const resetScheduleForTodayAction = createAction('@@scheduleForToday/RESET')();
