import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useTitle as useHeadTitle } from 'react-use';

import { TRACKING_PAGES, ENABLE_GOOGLE_ANALYTICS } from 'constants/settings';

export const useTitle = (title: string, isALW = true, isLoading = false): void => {
  const { pathname } = useLocation();

  useHeadTitle(title ? `${title} ${isALW ? '| ALW' : ''}` : 'ALW');

  useEffect(() => {
    const shouldTrackPage = (() => {
      // '*' should track all pages
      if (TRACKING_PAGES === '*') return true;
      // track limited pages
      // tracking pages should be comma separated
      return !!TRACKING_PAGES?.split(',').find((page: string) => `/${page.trim()}` === pathname);
    })();

    if (ENABLE_GOOGLE_ANALYTICS && !isLoading && title && shouldTrackPage) {
      ReactGA.send({ hitType: 'pageview', page: pathname + window.location.search, title });
    }
  }, [isLoading, title, pathname]);
};
