import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  loadStudentProfile,
  resetStudentAction,
  deleteStudentAction,
  updateStudentAction,
} from './actions';
import { addStudentAction } from '../schoolStudent/actions';
import { SchoolStudentActionType } from '../schoolStudent/reducer';
import { StudentInfoType, StudentProfileErrorsType } from './types';

export type ProfileActionType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, ProfileActionType>(true)
  .handleAction(
    [loadStudentProfile.request, resetStudentAction, deleteStudentAction.request],
    () => true,
  )
  .handleAction(
    [
      updateStudentAction.success,
      updateStudentAction.failure,
      loadStudentProfile.success,
      loadStudentProfile.failure,
    ],
    () => false,
  )
  .handleAction([deleteStudentAction.success, deleteStudentAction.failure], () => false);

const data = createReducer<StudentInfoType, ProfileActionType>({} as StudentInfoType)
  .handleAction(
    [updateStudentAction.success, loadStudentProfile.success],
    (state, action) => action.payload,
  )
  .handleAction(deleteStudentAction.success, (state, action) => action.payload)
  .handleAction(resetStudentAction, () => {
    return {} as StudentInfoType;
  });

const errors = createReducer<StudentProfileErrorsType, ProfileActionType>({})
  .handleAction([updateStudentAction.failure, loadStudentProfile.failure], (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction([updateStudentAction.success, loadStudentProfile.success], (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(deleteStudentAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(deleteStudentAction.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(resetStudentAction, () => ({}));

const isUpdateStudentLoading = createReducer<boolean, ProfileActionType | SchoolStudentActionType>(
  false,
)
  .handleAction([updateStudentAction.request, addStudentAction.request], () => true)
  .handleAction(
    [
      updateStudentAction.success,
      updateStudentAction.failure,
      addStudentAction.success,
      addStudentAction.failure,
    ],
    () => false,
  );

const studentProfileReducer = combineReducers({
  isLoading,
  data,
  errors,
  isUpdateStudentLoading,
});
export type StudentProfileStateType = StateType<typeof studentProfileReducer>;
export default studentProfileReducer;
