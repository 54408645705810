import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { loadLessonPreviewAction } from './actions';
import { getLessonPreview } from './api';
import { LessonPreviewType } from './types';

function* loadLessonPreviewRequest(
  action: ReturnType<typeof loadLessonPreviewAction.request>,
): SagaIterator<void> {
  try {
    const data: LessonPreviewType = yield call(getLessonPreview, action.payload);
    yield put(loadLessonPreviewAction.success(data));
  } catch (error) {
    yield put(loadLessonPreviewAction.failure(error));
  }
}

function* lessonPreviewSaga(): SagaIterator {
  yield all([takeEvery(loadLessonPreviewAction.request, loadLessonPreviewRequest)]);
}

export default lessonPreviewSaga;
