import React, { ReactElement } from 'react';
import ReactSelect, { components, OptionProps, IndicatorProps } from 'react-select';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { Text } from '../../Text/Text';
import { Icon } from '../../Icon/Icon';
import { SelectProps } from './types';
import styles from '../InputField.module.scss';

export const Option = (props: OptionProps<object, boolean>): ReactElement => {
  const { data, isSelected, isMulti, innerProps, label } = props;

  return (
    <>
      {isMulti ? (
        <div
          className={cn(styles['form-select__multioption'], {
            [`${styles.checked} checked`]: isSelected,
          })}
        >
          <label className={styles['form-select__multioption-label']}>
            <span className={styles['custom-checkbox']}>
              <input type="checkbox" value={data} checked={isSelected} {...innerProps} readOnly />
            </span>
            {label}
          </label>
        </div>
      ) : (
        <components.Option {...props} />
      )}
    </>
  );
};

export const DropdownIndicator = (props: IndicatorProps<object, boolean>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon type="arrow-down" />
    </components.DropdownIndicator>
  );
};

const onMenuOpen = () => {
  setTimeout(() => {
    const selectedEl = document.getElementsByClassName('form-select__option--is-selected')[0];

    if (selectedEl) {
      selectedEl.scrollIntoView({ block: 'center', inline: 'start' });
    }
  });
};

export const Select = ({
  name,
  options,
  defaultValue,
  placeholder = '',
  isSearchable = false,
  isDisabled = false,
  maxMenuHeight = 240,
  isReadOnly = false,
  isClearable = false,
  value,
  onChange,
  onBlur,
  getOptionLabel,
  getOptionValue,
  className,
  isLoading,
  isMulti,
  menuPlacement,
  closeMenuOnSelect,
  hideSelectedOptions,
  blurInputOnSelect,
}: SelectProps) => {
  const { formatMessage } = useIntl();

  const noOptions = formatMessage({
    id: 'dropdown.empty-options',
    defaultMessage: 'No options',
  });

  return (
    <ReactSelect
      name={name}
      maxMenuHeight={maxMenuHeight}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={value}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isDisabled={isDisabled || isReadOnly}
      options={options}
      className={cn(className, 'form-select')}
      classNamePrefix="form-select"
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      noOptionsMessage={({ inputValue }) => inputValue}
      components={{
        Option,
        DropdownIndicator,
        NoOptionsMessage: (props) => (
          <components.NoOptionsMessage {...props}>
            <Text type="body2">{noOptions}</Text>
          </components.NoOptionsMessage>
        ),
      }}
      isClearable={isClearable}
      menuPlacement={menuPlacement}
      isLoading={isLoading}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      blurInputOnSelect={blurInputOnSelect}
      onMenuOpen={onMenuOpen}
    />
  );
};
