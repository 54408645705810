import React, { ReactNode, lazy, useMemo, createElement, Suspense, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Route, Switch, Redirect, matchPath, useHistory } from 'react-router-dom';

import { Loader } from 'lib';
import { ROUTES } from 'constants/routes';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { PREPARATION_MATERIAL_GRADE } from 'constants/entities';
import { useTeacherTeachesGrade } from 'hooks/useTeacherTeachesGrade';
import {
  isAdminOrBranchManagerRoleSelector,
  isSchoolTeacherSelector,
  isStudentRoleSelector,
  selectorAccount,
} from 'store/account/selectors';

import ErrorView from 'components/ErrorView';
import PrivateRoute from 'components/PrivateRoute';
import ErrorFallback from 'components/ErrorFallback';
import LayoutBase, { BaseLayout } from 'components/LayoutBase';
import GradeOneToThreeLayout from 'modules/GradeOneToThree/components/BaseLayout';
import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';
import SubscriptionErrorModal from 'components/SubscriptionErrorModal';

import AuthRoutes, { AuthPaths } from './AuthRoutes';
import { NonGradeOneToThreeRoutes, LayoutRoutes, LayoutRoutesType } from './Routes';

const DigitalTutor = lazy(() => import('components/Chatbot'));

const Layout = ({
  layoutType,
  children,
}: {
  layoutType: LayoutRoutesType['layoutType'];
  children: ReactNode;
}) => {
  const isStudent = useSelector(isStudentRoleSelector);
  const isAdminOrBranchManagerRole = useSelector(isAdminOrBranchManagerRoleSelector);
  const isSchoolTeacher = useSelector(isSchoolTeacherSelector);

  const isGradeOneToThree = useIsGradeOneToThree();

  switch (layoutType) {
    case 'common':
      return isGradeOneToThree ? (
        <GradeOneToThreeLayout>{children}</GradeOneToThreeLayout>
      ) : (
        <>
          {createElement(
            isStudent || isAdminOrBranchManagerRole || isSchoolTeacher ? BaseLayout : LayoutBase,
            {},
            children,
          )}
        </>
      );
    case 'gradeOneToThree':
      return <GradeOneToThreeLayout>{children}</GradeOneToThreeLayout>;
    default:
      return <>{children}</>;
  }
};

const AppRoutes = () => {
  const { formatMessage } = useIntl();
  const browserHistory = useHistory();
  const isGradeOneToThree = useIsGradeOneToThree();

  useScrollToTop();

  const {
    data: { grade },
  } = useSelector(selectorAccount);

  const RoutesInfo = LayoutRoutes.map(({ routes, layoutType }) => {
    if (layoutType === 'common') return [...routes, ...NonGradeOneToThreeRoutes];
    return routes;
  }).flat();

  const RoutePaths = LayoutRoutes.map(({ routes, layoutType }) => {
    const routesCopy = [...routes];
    if (layoutType === 'common') routesCopy.push(...NonGradeOneToThreeRoutes);

    return routesCopy.map(({ path }) => path).flat();
  }).flat() as readonly string[];

  const isValidGradeTeacher = useTeacherTeachesGrade(PREPARATION_MATERIAL_GRADE);
  const isValidGradeStudent = grade?.gradeValue === PREPARATION_MATERIAL_GRADE;

  const isValidGradeForPreparation = isValidGradeStudent || isValidGradeTeacher;

  const currentStudentRoute = RoutesInfo.find(({ path }) =>
    matchPath(window.location.pathname, { path, exact: true }),
  );

  const LayoutRoutesCopy = useMemo(
    () =>
      LayoutRoutes.map((route) => {
        if (route.layoutType === 'common' && !isGradeOneToThree)
          route.routes.push(...NonGradeOneToThreeRoutes);

        return route;
      }),
    [isGradeOneToThree],
  );

  useEffect(() => {
    browserHistory.listen(() => {
      if (browserHistory.action === 'POP') {
        const path = browserHistory.location.pathname;
        if (path === ROUTES.managementLogin || path === ROUTES.login) {
          browserHistory.push(ROUTES.root);
        }
      }
    });
  }, [browserHistory]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <Suspense fallback={<Loader size={isGradeOneToThree ? 's100' : 's60'} />}>
        <Switch>
          <Route exact path={ROUTES.notFound}>
            <ErrorView
              heading="404"
              subheading={formatMessage({
                id: 'error.page.not-found',
                defaultMessage: 'Page Not Found!',
              })}
            />
          </Route>

          <Route exact path={AuthPaths as readonly string[]}>
            <AuthRoutes />
          </Route>

          <PrivateRoute path={RoutePaths} {...currentStudentRoute?.appRoles}>
            <Switch>
              {LayoutRoutesCopy.map(({ routes, layoutType }, index) => (
                <Route
                  key={index}
                  path={routes.map(({ path }) => path).flat() as readonly string[]}
                >
                  <Layout layoutType={layoutType}>
                    <Switch>
                      {routes.map(({ path, component, exact }, index) => {
                        if (path === ROUTES.preparation && !isValidGradeForPreparation) {
                          return null;
                        }

                        return (
                          <Route key={index} path={path} exact={exact}>
                            {component}
                          </Route>
                        );
                      })}
                      <Redirect to={ROUTES.notFound} />
                    </Switch>
                  </Layout>
                </Route>
              ))}
            </Switch>
          </PrivateRoute>
        </Switch>
        <DigitalTutor />
        <SubscriptionErrorModal />
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};

export default AppRoutes;
