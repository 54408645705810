import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { Dialog, IconCommon, NewButton, ButtonTypes, getSvgURL } from 'lib';
import API, { APIError } from 'utils/api';
import { ROUTES } from 'constants/routes';
import { accountDataSelector } from 'store/account/selectors';
import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';
import { DraftedActions } from 'components/Subscription/types';

import styles from './SubscriptionError.module.scss';
import messages from './messages';

const SubscriptionErrorModal = () => {
  const { formatMessage: fm } = useIntl();
  const isGradeOneToThree = useIsGradeOneToThree();
  const history = useHistory();

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const { id } = useSelector(accountDataSelector);

  const { UPGRADE_PLAN } = DraftedActions;

  API.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error === APIError.paymentError) {
        setErrorModalOpen(true);
      }
      return Promise.reject(error);
    },
  );

  return (
    <Dialog
      isVisible={errorModalOpen}
      overlayClasses={cn(styles.topIndex, styles['modal-backdrop'])}
    >
      <div className={styles.imagesWrtap} role="document">
        {isGradeOneToThree && (
          <img
            src={getSvgURL('smile-face', isGradeOneToThree)}
            className={styles.smile_face}
            alt="smile"
          />
        )}
        <div
          className={cn(styles['error-dialog'], { [styles.lowerGrade]: isGradeOneToThree })}
          role="document"
        >
          <span className={styles.close} onClick={() => setErrorModalOpen(false)}>
            <IconCommon className="icon-cancel" />
          </span>
          <div className="text-center">
            <h1 className={styles.title}>
              <img src={getSvgURL('alert-icon')} alt="Alert" /> {fm(messages.heading)}
            </h1>
            <p>{fm(messages.description)}</p>
          </div>
          <div className={styles.footer}>
            <NewButton
              style={
                isGradeOneToThree
                  ? [ButtonTypes.btnGrade, ButtonTypes.btnGradeSmall, ButtonTypes.btnGradeRed]
                  : [ButtonTypes.buttonSecondary]
              }
              onClick={() => setErrorModalOpen(false)}
            >
              {fm(messages.cancelButton)}
            </NewButton>
            <NewButton
              style={
                isGradeOneToThree
                  ? [ButtonTypes.btnGrade, ButtonTypes.btnGradeSmall, ButtonTypes.btnGradeOrange]
                  : [ButtonTypes.buttonPrimary]
              }
              onClick={() => {
                history.push(`${ROUTES.subscriptions}?action=${UPGRADE_PLAN}&user=${id}`);
                setErrorModalOpen(false);
              }}
            >
              {fm(messages.upgradeButton)}
            </NewButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SubscriptionErrorModal;
