import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectSchoolBranches } from 'store/shoolBranches/selectors';

export const useBranch = (): number => {
  const {
    selectedBranch: { branchId },
  } = useSelector(selectSchoolBranches);

  const branch = useMemo(() => {
    return branchId || +(localStorage.getItem('schoolBranchId') || 0);
  }, [branchId]);

  return branch;
};
