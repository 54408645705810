import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  loadGradesSubjectsAction,
  loadTeachersDetailsAction,
  assignGradesToTeacherAction,
  loadSubjectsTeachersAction,
  resetSubjectTeacherAction,
} from './actions';

export type AssignTeacherGradesType = ActionType<typeof actions>;

const isLoading = combineReducers({
  isLoadingSubjectsDetails: createReducer<boolean, AssignTeacherGradesType>(true)
    .handleAction([loadGradesSubjectsAction.request], () => true)
    .handleAction(
      [loadGradesSubjectsAction.success, loadGradesSubjectsAction.failure],
      () => false,
    ),
  isLoadingGradesDetails: createReducer<boolean, any>(true)
    .handleAction([loadSubjectsTeachersAction.request], () => true)
    .handleAction(
      [loadSubjectsTeachersAction.success, loadSubjectsTeachersAction.failure],
      () => false,
    ),
  isLoadingTeachersDetails: createReducer<boolean, any>(true)
    .handleAction([loadTeachersDetailsAction.request], () => true)
    .handleAction(
      [loadTeachersDetailsAction.success, loadTeachersDetailsAction.failure],
      () => false,
    ),
  isLoadingAssignTeacher: createReducer<boolean, any>(true)
    .handleAction([assignGradesToTeacherAction.request], () => true)
    .handleAction(
      [assignGradesToTeacherAction.success, assignGradesToTeacherAction.failure],
      () => false,
    ),
});

const data = createReducer<any, AssignTeacherGradesType>({})
  .handleAction(loadGradesSubjectsAction.success, (state, action) => ({
    ...state,
    teacherGradesSubjects: action.payload,
  }))
  .handleAction(loadTeachersDetailsAction.success, (state, action) => ({
    ...state,
    teacherDetails: action.payload,
  }))
  .handleAction(loadSubjectsTeachersAction.success, (state, action) => ({
    ...state,
    assignSubjectTeachers: action.payload,
  }))
  .handleAction(resetSubjectTeacherAction, (state) => ({
    ...state,
    assignSubjectTeachers: [],
  }))
  .handleAction(assignGradesToTeacherAction.success, (state, action) => ({
    ...state,
    assignSubjectToTeacher: action.payload,
  }));

const errors = createReducer<any, AssignTeacherGradesType>({})
  .handleAction(loadGradesSubjectsAction.failure, (state, action) => ({
    ...state,
    errors: action.payload,
  }))
  .handleAction(loadGradesSubjectsAction.success, (state) => ({
    ...state,
    errors: '',
  }))
  .handleAction(loadTeachersDetailsAction.success, (state) => ({
    ...state,
    errors: '',
  }))
  .handleAction(loadTeachersDetailsAction.failure, (state, action) => ({
    ...state,
    errors: action.payload,
  }))
  .handleAction(loadSubjectsTeachersAction.success, (state) => ({
    ...state,
    errors: '',
  }))
  .handleAction(loadSubjectsTeachersAction.failure, (state, action) => ({
    ...state,
    errors: action.payload,
  }))
  .handleAction(assignGradesToTeacherAction.success, (state) => ({
    ...state,
    errors: '',
  }))
  .handleAction(assignGradesToTeacherAction.failure, (state, action) => ({
    ...state,
    errors: action.payload,
  }));

const assignTeacherGradesReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export type assignTeacherGradesReducerType = StateType<typeof assignTeacherGradesReducer>;

export default assignTeacherGradesReducer;
