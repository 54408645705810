import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';
import {
  loadCompletionRateBySubjectAction,
  loadCourseProgressAction,
  loadCourseTilesProgressAction,
  resetProgressAction,
} from './actions';
import * as actions from './actions';

import { CourseTilesType } from './types';

export type ProgressActionType = ActionType<typeof actions>;

const data = createReducer<Partial<CourseTilesType>, ProgressActionType>({} as CourseTilesType)
  .handleAction(loadCourseTilesProgressAction.success, (state, { payload }) => payload)
  .handleAction(loadCompletionRateBySubjectAction.success, (state, action) => ({
    ...state,
    completionRateBySubject: action.payload.completionRateBySubject,
  }))
  .handleAction(loadCourseProgressAction.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(resetProgressAction, () => ({}));

const fetching = combineReducers({
  load: createReducer<boolean, ProgressActionType>(true)
    .handleAction([loadCourseTilesProgressAction.request, resetProgressAction], () => true)
    .handleAction(
      [loadCourseTilesProgressAction.success, loadCourseTilesProgressAction.failure],
      () => false,
    ),
  loadCompletionRateBySubject: createReducer<boolean, ProgressActionType>(true)
    .handleAction(loadCompletionRateBySubjectAction.request, () => false)
    .handleAction(
      [loadCompletionRateBySubjectAction.success, loadCompletionRateBySubjectAction.failure],
      () => false,
    ),
  loadCourseProgress: createReducer<boolean, ProgressActionType>(true)
    .handleAction(loadCourseProgressAction.request, () => true)
    .handleAction(
      [loadCourseProgressAction.failure, loadCourseProgressAction.success],
      () => false,
    ),
});

const errors = combineReducers({
  load: createReducer<string, ProgressActionType>('')
    .handleAction(loadCourseTilesProgressAction.failure, (state, action) => action.payload)
    .handleAction([loadCourseTilesProgressAction.success, resetProgressAction], () => ''),
  loadCompletionRateBySubject: createReducer<string, ProgressActionType>('')
    .handleAction(loadCompletionRateBySubjectAction.failure, (state, action) => action.payload)
    .handleAction(loadCompletionRateBySubjectAction.success, () => ''),
  loadCourseProgress: createReducer<string, ProgressActionType>('')
    .handleAction(loadCourseProgressAction.failure, (state, { payload }) => payload)
    .handleAction(loadCourseProgressAction.success, () => ''),
});

const progressReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type ProgressReducerStateType = StateType<typeof progressReducer>;

export default progressReducer;
