import React, { FunctionComponent, memo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from 'components/TopTabs/TopTabs.module.scss';

import { TabType } from './Tabs.types';

type TabsListPropTypes = {
  data: TabType[];
  showPopover: boolean;
};

const TabsWithoutPopover: FunctionComponent<TabsListPropTypes> = ({ data, showPopover }) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          <li className={cn(styles.tab__item, { [styles.active]: item.isActive })} key={item.label}>
            {!showPopover && item.isActive ? (
              <Link
                to={item.url}
                className={styles.tab__title}
                onClick={(event) => event.preventDefault()}
              >
                {showPopover && `${index + 1}.`} {item.label}
              </Link>
            ) : (
              <Link to={item.url} className={styles.tab__title}>
                {showPopover && `${index + 1}.`} {item.label}
              </Link>
            )}
          </li>
        );
      })}
    </>
  );
};

export default memo(TabsWithoutPopover);
