import { IconType } from 'lib';
import { SchoolBrancheType } from 'store/shoolBranches/types';

export enum SidebarTypes {
  ALW,
  B2B,
}

export interface MenuItemType {
  path: string;
  label: string;
  icon?: IconType;
  id?: string;
  image?: string;
  orderIndex?: number;
  hide?: boolean;
  disabled?: boolean;
}

export interface MenuItemTypes extends MenuItemType {
  subMenus?: MenuItemType[];
}

export interface ProfileDetailsType {
  src: string;
  srcLarge: string;
  name: string;
  role: string;
  isGradeOneToThree?: boolean;
  username?: string;
  password?: string;
  email?: string;
}

export interface SideBarNavigationType {
  branches: Array<SchoolBrancheType>;
}

export interface SidebarProps {
  sidebarType: SidebarTypes.ALW | SidebarTypes.B2B;
  menuItems: MenuItemTypes[];
  profileDetails: ProfileDetailsType;
  isOpen: boolean;
  setIsOpen: () => void;
  showHamburger?: boolean;
  isSummerProgram?: boolean;
}

export interface MenusType extends MenuItemTypes {
  setIsOpen: () => void;
  setElement: (el: HTMLAnchorElement | null) => void;
}

export interface NavigationProps {
  isHamburger?: boolean;
  onBranchSwitch?: () => void;
}

export type MessagesType = Record<string, { id: string; defaultMessage: string }>;
