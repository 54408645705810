import styled, { css } from 'styled-components';

import { bounceInTop, sizeMobile, sizeTablet } from 'modules/GradeOneToThree/styled-mixin';
import { getSvgURL, getPngURL } from 'lib';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;

  ${sizeMobile(css`
    justify-content: flex-end;
  `)};
`;

export const LeftHeaderList = styled.ul`
  display: flex;
  align-items: center;
  flex-basis: auto;
  justify-content: flex-start;

  ${(p) =>
    p.theme.dir === 'rtl' &&
    sizeMobile(css`
      margin-left: 0;
      margin-right: auto;
    `)};

  ${sizeTablet(css`
    flex-basis: 350px;
    justify-content: flex-end;
  `)};
`;

export const RewardsLinkListItem = styled.li`
  font-weight: bold;
  font-family: ${(p) => p.theme.font.family.children};
  font-size: 1.2rem;
  text-transform: capitalize;
  display: none;

  a {
    color: ${(p) => p.theme.colors.secondary.active};
  }

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      font-family: ${(p) => p.theme.font.family.arabic};
    `};

  ${sizeTablet(css`
    display: inline-flex;
  `)};
`;

export const DashboardItem = styled.li`
  margin-right: 10px;
  display: flex;
  align-items: center;

  a {
    display: inline-flex;
  }

  img {
    width: 35px;
    min-width: 35px;
    object-fit: contain;
  }

  ${sizeMobile(css`
    margin: 0 10px;

    img {
      width: 40px;
      min-width: 40px;
    }
  `)};

  ${sizeTablet(css`
    margin: 0 20px;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      margin-right: 0;
      margin-left: 10px;
    `};
`;

export const RewardsCoinListItem = styled.li`
  justify-content: center;
  align-items: center;
  display: inline-flex;
  background-repeat: no-repeat;
  margin-right: 10px;
  margin-left: 0;
  position: relative;
  background-size: 100% 100%;
  padding: 10px;
  font-size: 1.2rem;
  font-family: ${(p) => p.theme.font.family.children};
  font-weight: bold;
  color: ${(p) => p.theme.colors.white};
  background-image: url(${getSvgURL('reward-bg', true)});

  &::before {
    content: '';
    height: 32px;
    margin-bottom: 3px;
    width: 30px;
    background-image: url(${getPngURL('coin-icon', true)});
    -webkit-animation: ${bounceInTop} cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: ${bounceInTop} 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  ${sizeMobile(css`
    font-size: ${(p) => p.theme.font.size.primary};
  `)};

  ${sizeTablet(css`
    margin: 0 20px;
    min-width: 70px;
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      margin-right: 0;
      margin-left: 10px;
      font-family: ${(p) => p.theme.font.family.arabic};

      ${sizeTablet(css`
        margin: 0 20px;
      `)};
    `};
`;

export const PlusIconListItem = styled.li`
  z-index: 1;
  margin-right: 10px;
  line-height: 0;

  img {
    width: 30px;
    min-width: 30px;
    object-fit: contain;
  }

  ${sizeMobile(css`
    margin-right: 20px;

    img {
      width: 40px;
      min-width: 40px;
    }
  `)};

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      margin-left: 10px;
      margin-right: 0;

      ${sizeMobile(css`
        margin-left: 20px;
        margin-right: 0;
      `)};
    `};
`;

export const RightHeaderList = styled.ul`
  display: flex;
  align-items: center;

  ${sizeMobile(css`
    margin-top: 0;
  `)};
`;

export const RightHeaderListItem = styled.li`
  padding: 0 5px;
  position: relative;
  min-height: 40px;
  display: flex;
  align-items: center;

  img,
  svg {
    cursor: pointer;
    width: 40px;
  }

  &:before {
    left: 0px;
    width: 1px;
    top: 6px;
    height: 50px;
    background: ${(p) => p.theme.colors.secondary.seperator};
    content: '';
    position: absolute;
  }

  &:last-child {
    &:after {
      right: 0px;
      width: 1px;
      top: 6px;
      height: 50px;
      background: ${(p) => p.theme.colors.secondary.seperator};
      content: '';
      position: absolute;
    }
  }

  ${(p) =>
    p.theme.dir === 'rtl' &&
    css`
      &:before {
        left: unset;
        right: 0;
      }
      &:last-child {
        &:after {
          right: unset;
          left: 0;
        }
      }
    `};

  ${sizeMobile(css`
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;

    &:before {
      top: -20px;
      height: 80px;
    }

    &:last-child {
      &:after {
        top: -20px;
        height: 80px;
      }
    }
  `)};
`;
