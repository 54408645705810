import { ChildSubscriptionInfo, FormType } from 'components/Subscription/types';
import { FREE_PLAN } from 'constants/entities';
import {
  calculateSubscriptionPrice,
  getQiyasSubjects,
  getQiyasSubjectsPrice,
  getServicesDetail,
} from 'components/Subscription/utils';
import { DraftedSubscription, Subjects, CardBrandType } from './types';

type NestedObject = {
  [key: string]: string[] | NestedObject[];
};

export const flattenObject = (obj: NestedObject, parentKey = '') => {
  try {
    const result: Record<string, string> = {};

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in obj) {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      const value: any = obj[key];

      if (Array.isArray(value)) {
        if (value.every((item: string | NestedObject) => typeof item === 'string')) {
          // If the value is an array of strings, join the strings with commas and add to the result
          result[currentKey] = value.join(', ');
        } else if (value.every((item: NestedObject) => typeof item === 'object')) {
          // If the value is an array of objects, recursively flatten each object in the array
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < value.length; i++) {
            const nestedObject = flattenObject(value[i], `${currentKey}[${i}]`);
            Object.assign(result, nestedObject);
          }
        }
      }
    }
    return result as unknown as string[];
  } catch (err) {
    return [];
  }
};

export const transformDraftedSubscription = (
  draftSubscription: DraftedSubscription[],
  shouldPaidOnly?: boolean,
  shouldUpgradePlan?: boolean,
  userId?: number,
  isStudent?: boolean,
): FormType => {
  if (isStudent && draftSubscription?.[0]?.subjectsServices === null)
    return {
      isAllowedFreePlan: draftSubscription?.[0]?.isAllowedFreePlan,
      isVoucherActive: draftSubscription?.[0]?.isVoucherActive,
    } as unknown as FormType;

  const subscription = draftSubscription.map((subs) => ({
    ...subs,
    subjectsServices: subs.subjectsServices || [],
  }));

  const isAnyChildEnrolled = subscription?.some((child) =>
    child.subjectsServices?.some((subj) => {
      return subj.services.some((service) => service.isEnrolled);
    }),
  );

  let subscriptionDetail = shouldPaidOnly
    ? subscription.filter(
        (s) =>
          (s.plan || s.lastSelectedPlan).type !== FREE_PLAN &&
          (s.subjectsServices.some((sub) => sub.services.some((service) => !service.isEnrolled)) ||
            !s.subjectsServices.length),
      )
    : subscription;

  subscriptionDetail =
    shouldUpgradePlan || userId
      ? subscriptionDetail.filter((s) => s.id === userId)
      : subscriptionDetail;

  const lastChild = subscriptionDetail.at(-1);

  let grandTotal = 0;

  const children: ChildSubscriptionInfo[] = subscriptionDetail.map((user) => {
    const subjects = user.subjectsServices.map((subject) => ({
      name: subject.subjectName,
      id: subject.id,
      isAllowedFreePlan: user.isAllowedFreePlan,
      isQiyasSubject: subject.isQiyasSubject,
      services: subject.services.map((service) => ({
        ...service,
        name: service.titleEn,
        checked: service.isEnrolled || service.isDrafted,
      })),
    }));

    const plan = user.plan || user.lastSelectedPlan;
    const { totalEnrolledServices, newServices } = getServicesDetail(subjects);

    const { paidQiyasSubjects, unpaidQiyasSubjects } = getQiyasSubjects(subjects);
    const qiyasPaidPrice = getQiyasSubjectsPrice(paidQiyasSubjects);
    const qiyasPrice = getQiyasSubjectsPrice(unpaidQiyasSubjects);

    const price =
      plan?.type === FREE_PLAN || user.isVoucherActive
        ? 0
        : calculateSubscriptionPrice(
            plan?.configuration?.servicePrice || [],
            totalEnrolledServices,
            newServices,
            (plan?.configuration?.maxPayableAmount || 0) -
              (Number(user.amountPaid || 0) - qiyasPaidPrice),
          ) + qiyasPrice;
    // sum all child total;
    grandTotal += price;

    return {
      plan,
      subjects,
      userId: user.id,
      grade: +user.grade,
      name: user.name,
      amountPaid: Number(user.amountPaid || 0),
      lastSuccessfulPlan: user.plan,
      isAdd: !user.subjectsServices.length,
      totalPrice: price,
      draftable: true,
      isVoucherActive: user.isVoucherActive,
      isAllowedFreePlan: user.isAllowedFreePlan,
    };
  });

  const formData: FormType = {
    firstName: lastChild?.name || '',
    lastName: '',
    grade: lastChild?.grade || '',
    children,
    totalPrice: grandTotal,
    discountedPrice: grandTotal,
    currentUserIndex: subscriptionDetail.length - 1,
    currentStep: 4,
    brand: {} as CardBrandType,
    currentChildGrade: +(lastChild?.grade || 0),
    currentUserId: lastChild?.id || 0,
    isAnyChildEnrolled,
  };
  return formData;
};

export const transformUserSubjects = (
  subjects: Subjects[],
  draftedSubjects: Subjects[],
  planId?: number,
  draftPlan?: number,
) => {
  if (!draftedSubjects.length)
    return (
      subjects.map((sub) => ({
        ...sub,
        price: 0,
        services: sub.services.map((sr) => ({
          ...sr,
          isDrafted: false,
          isEnrolled: false,
          checked: false,
        })),
      })) || []
    );
  return subjects.map((subject) => {
    const previousServices = subject.services;
    const draftedSubject = draftedSubjects.find((sbj) => sbj.id === subject.id);
    const draftedServices = draftedSubject?.services || [];
    const currentServices = draftedServices.length ? draftedServices : previousServices;
    return {
      ...subject,
      services: subject.services.map((service) => {
        const currentService = currentServices.find((sr) => sr.id === service.id);
        const isEnrolled = planId !== draftPlan ? false : !!currentService?.isEnrolled;
        const isDrafted = currentService?.isDrafted;
        return {
          ...service,
          isEnrolled,
          isDrafted,
          checked: !!isDrafted || !!isEnrolled,
        };
      }),
    };
  });
};
