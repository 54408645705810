import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import { PromotionDataType } from './types';

type PromotionActionType = ActionType<typeof actions>;

const { fetchNewsAndUpdatesAction, fetchVideosCardsAction } = actions;

const initialState = {
  data: {} as PromotionDataType,
  fetching: {
    newsAndUpdates: false,
    videoAndCards: false,
  },
  errors: {
    newsAndUpdates: '',
    videoAndCards: '',
  },
};

const data = createReducer<PromotionDataType, PromotionActionType>(initialState.data)
  .handleAction([fetchNewsAndUpdatesAction.success], (state, action) => ({
    ...state,
    ...{ newsAndUpdates: action.payload },
  }))
  .handleAction([fetchVideosCardsAction.success], (state, action) => ({
    ...state,
    ...{ videoAndCards: action.payload },
  }));

const fetching = combineReducers({
  fetchNewsAndUpdates: createReducer<boolean, PromotionActionType>(
    initialState.fetching.newsAndUpdates,
  )
    .handleAction(fetchNewsAndUpdatesAction.request, () => true)
    .handleAction(
      [fetchNewsAndUpdatesAction.success, fetchNewsAndUpdatesAction.failure],
      () => false,
    ),
  fetchVideosAndCards: createReducer<boolean, PromotionActionType>(
    initialState.fetching.videoAndCards,
  )
    .handleAction(fetchVideosCardsAction.request, () => true)
    .handleAction([fetchVideosCardsAction.success, fetchVideosCardsAction.failure], () => false),
});

const errors = combineReducers({
  errorVideosAndCards: createReducer<string, PromotionActionType>(initialState.errors.videoAndCards)
    .handleAction(fetchVideosCardsAction.failure, (state, { payload }) => payload)
    .handleAction([fetchVideosCardsAction.success], () => initialState.errors.videoAndCards),

  errorNewsAndUpdates: createReducer<string, PromotionActionType>(
    initialState.errors.newsAndUpdates,
  )
    .handleAction(fetchNewsAndUpdatesAction.failure, (state, { payload }) => payload)
    .handleAction([fetchNewsAndUpdatesAction.success], () => initialState.errors.newsAndUpdates),
});

const promotionReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type PromotionType = StateType<typeof promotionReducer>;

export default promotionReducer;
