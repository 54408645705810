import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  loadStudentProfile,
  deleteStudentAction,
  updateStudentPasswordAction,
  updateStudentAction,
} from './actions';
import { getSubjects, deleteStudent, updateStudentPassword, updateStudent } from './api';
import { StudentInfoType } from './types';

function* loadStudentList({
  payload,
}: ReturnType<typeof loadStudentProfile.request>): SagaIterator<void> {
  try {
    const subjects: StudentInfoType = yield call(getSubjects, payload);
    yield put(loadStudentProfile.success(subjects));
  } catch (error) {
    yield put(loadStudentProfile.failure(error));
  }
}

function* deleteStudentList({
  payload,
}: ReturnType<typeof deleteStudentAction.request>): SagaIterator<void> {
  try {
    yield call(deleteStudent, payload.payload);
    payload.callback();
  } catch (error) {
    yield put(loadStudentProfile.failure(error));
  }
}

function* updateStudentPasswordRequest({
  payload,
}: ReturnType<typeof updateStudentPasswordAction.request>): SagaIterator<void> {
  try {
    yield call(updateStudentPassword, payload.branchId, payload.studentId, payload.formValues);
    payload.callbackSuccess();
    yield put(updateStudentPasswordAction.success());
  } catch (error) {
    payload.callbackFail(error);
    yield put(updateStudentPasswordAction.failure(error));
  }
}

function* updateStudentRequest({
  payload: { callback, ...payload },
}: ReturnType<typeof updateStudentAction.request>): SagaIterator<void> {
  try {
    const data = yield call(updateStudent, payload);
    yield put(updateStudentAction.success(data));
    callback && callback('success');
  } catch (error) {
    yield put(updateStudentAction.failure(error));
    callback && callback('fail', error);
  }
}

function* studentProfileSaga(): SagaIterator {
  yield all([
    takeEvery(loadStudentProfile.request, loadStudentList),
    takeEvery(deleteStudentAction.request, deleteStudentList),
    takeEvery(updateStudentPasswordAction.request, updateStudentPasswordRequest),
    takeEvery(updateStudentAction.request, updateStudentRequest),
  ]);
}

export default studentProfileSaga;
