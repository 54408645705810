import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import { branchStatsAPI } from 'utils/apiEndpoints';
import { getSelectedDuration } from 'utils/helpers';

import { branchStats, branchStatType, schoolDetail } from './types';

export type param = {
  start_date?: string;
  end_date?: string;
};

export async function getSchoolBranches(): Promise<Array<schoolDetail>> {
  const response = await API.get('/api/school_system/user-branches/');
  return camelcaseKeys(response.data, { deep: true });
}

export async function getSchoolBranchStats(payload: branchStatType): Promise<branchStats> {
  const duration = getSelectedDuration(payload.statusType, payload.semesterStartDate);
  const queryParams: param = {
    ...duration,
  };
  const response = await API.get(branchStatsAPI(payload.branchId), {
    params: queryParams,
  });
  return camelcaseKeys(response.data, { deep: true });
}
