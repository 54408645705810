import { ROUTES } from 'constants/routes';
import { getIntl } from 'utils/intl';
import { getSvgURL } from 'lib';

import { DISABLE_B2B_GRADING } from 'constants/settings';

import { MenuItemTypes } from '../components/Sidebar/types';

export const schoolBranchManagerMenu = (
  isBranchAdmin: boolean,
  allowB2BGrading = true,
): Array<MenuItemTypes> => {
  const { formatMessage } = getIntl();
  const menu: Array<MenuItemTypes> = [
    {
      path: ROUTES.root,
      label: formatMessage({ id: 'school.menu.branch', defaultMessage: 'Branch Detail' }),
      icon: 'branch-detail',
    },
    {
      path: ROUTES.teachers,
      label: formatMessage({ id: 'school.menu.teachers', defaultMessage: 'Teachers' }),
      icon: 'teacher',
    },
    {
      path: ROUTES.calendar,
      label: formatMessage({ id: 'calendar.label', defaultMessage: 'Calendar' }),
      icon: 'schedule',
    },
    {
      path: ROUTES.students,
      label: formatMessage({ id: 'school.menu.students', defaultMessage: 'Students' }),
      icon: 'students',
    },
    {
      id: 'sidebar__reports',
      path: ROUTES.adminReports,
      label: formatMessage({ id: 'b2bTeacher.reports.title', defaultMessage: 'Reports' }),
      icon: 'report',
    },
    {
      path: ROUTES.timetable,
      label: formatMessage({ id: 'schedule.timetable', defaultMessage: 'Timetable' }),
      icon: 'schedule',
    },
    ...(!DISABLE_B2B_GRADING && (allowB2BGrading || !isBranchAdmin)
      ? [
          {
            path: ROUTES.teachers,
            label: formatMessage({ id: 'grade-system', defaultMessage: 'Grading System' }),
            image: getSvgURL('grade-system'),
            subMenus: [
              {
                path: ROUTES.gradeMapping,
                label: formatMessage({
                  id: 'grade-system.title',
                  defaultMessage: 'Grade Mapping',
                }),
                image: getSvgURL('grade-mapping'),
              },
              {
                path: ROUTES.gradeReport,
                label: formatMessage({
                  id: 'grade-reports.title',
                  defaultMessage: 'Grade Report',
                }),
                image: getSvgURL('grade-reports'),
              },
            ],
          },
        ]
      : []),
  ];

  if (isBranchAdmin) {
    const index =
      !DISABLE_B2B_GRADING && (allowB2BGrading || !isBranchAdmin) ? menu.length - 1 : menu.length;
    menu.splice(index, 0, {
      path: ROUTES.liveSessionsSchedule,
      label: formatMessage({
        id: 'live.sessions.schedule',
        defaultMessage: 'Live Sessions Schedule',
      }),
      icon: 'students',
    });
    menu.splice(index, 0, {
      id: 'sidebar__flexible__schedule',
      path: ROUTES.teacherScheduleList,
      label: formatMessage({
        id: 'flexibleSchedule.teachers.list.title',
        defaultMessage: 'Teachers’ Schedule',
      }),
      icon: 'schedule',
    });
  }
  return menu;
};
