import { SUMMER_SEMESTER_END_DATE, SUMMER_SEMESTER_START_DATE } from 'constants/settings';
import { Role, SchoolRole } from 'store/account/types';

import { isTimeBetween } from './date-time';

export const isSummerSemester = (() => {
  // use to enable summer semester from console, currently disable it
  // if (localStorage.getItem('isSummer') === 'True') return true; // temporary only for QA testing
  const semesterStartDate = new Date(SUMMER_SEMESTER_START_DATE || '');
  const semesterEndDate = new Date(SUMMER_SEMESTER_END_DATE || '');
  return isTimeBetween(semesterStartDate, semesterEndDate);
})();

export const getRolesSemesterWise = (roles: Role[]) => {
  if (isSummerSemester) {
    return roles.filter((role) => role !== Role.student);
  }
  return roles;
};

export const getSchoolRolesSemesterWise = (schoolRole: SchoolRole[]) => {
  if (isSummerSemester) {
    return schoolRole.filter((role) => role !== SchoolRole.schoolStudent);
  }
  return schoolRole;
};
