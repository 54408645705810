import React, { FunctionComponent, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LOCALES, Lang, getLocale } from 'lang';
import { updatePreferencesAction } from 'store/preferences/actions';
import { selectorLocale } from 'store/preferences/selectors';
import { SwitchGroup } from './SwitchGroup/SwitchGroup';

type ChangeLangType = {
  value?: Lang;
  useShortName?: boolean;
  type?: 'primary' | 'secondary' | 'dark' | 'yellow';
};

const AccountLang: FunctionComponent<ChangeLangType> = ({ type, useShortName }) => {
  const dispatch = useDispatch();

  const { data } = useSelector(selectorLocale);
  const lang = getLocale(data.prefLang);

  const langs = Object.keys(LOCALES).map((key) => ({
    caption: useShortName ? LOCALES[key as Lang].shortName : LOCALES[key as Lang].name,
    value: key,
  }));

  const onChangeLangHandler = ({ target: { value } }: ChangeEvent<ChangeLangType>): void => {
    dispatch(updatePreferencesAction.request({ prefLang: value }));
  };

  return (
    <SwitchGroup
      name="lang"
      items={langs}
      defaultValue={lang}
      type={type}
      onChange={onChangeLangHandler}
    />
  );
};

export default AccountLang;
