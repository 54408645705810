import { createReducer, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { UnitErrorsType, UnitType } from './types';
import { loadUnitsAction, resetUnitsAction } from './actions';
import * as actions from './actions';

export type UnitActionType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, UnitActionType>(true)
  .handleAction([loadUnitsAction.request, resetUnitsAction], () => true)
  .handleAction([loadUnitsAction.success, loadUnitsAction.failure], () => false);

const data = createReducer<UnitType[], UnitActionType>([])
  .handleAction(loadUnitsAction.success, (state, action) => action.payload)
  .handleAction(resetUnitsAction, () => []);

const errors = createReducer<UnitErrorsType, UnitActionType>({})
  .handleAction(loadUnitsAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadUnitsAction.success, (state) => ({
    ...state,
    loading: '',
  }))
  .handleAction(resetUnitsAction, () => ({}));

const unitReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export default unitReducer;
