import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  reportsTitle: {
    id: 'b2bTeacher.reports.title',
    defaultMessage: 'Reports',
  },
  selectGrades_breadcrumbLabel_teacher: {
    id: 'schedule.event.detail.trainee',
    defaultMessage: 'Teacher',
  },
  selectGrades_breadcrumbLabel_select_grades: {
    id: 'content.library.assign.lesson.select.grade',
    defaultMessage: 'Select Grade',
  },
  selectGrades_reports_subHeading: {
    id: 'reports.subheading',
    defaultMessage: 'Select a grade to see reports of students',
  },
  selectGrades_search_grade: {
    id: 'reports.search.grade',
    defaultMessage: 'Search grade',
  },
  selectGrades_grade_title: {
    id: 'reports.grade.title',
    defaultMessage: 'Grade',
  },
  selectGrades_reports_detail_button_title: {
    id: 'reports.detail',
    defaultMessage: 'Detail',
  },
  selectGrades_student_title: {
    id: 'content-library.student.label',
    defaultMessage: 'Student',
  },
  selectGrades_students_title: {
    id: 'b2bTeacher.dashboard.Students',
    defaultMessage: 'Students',
  },
  gradeDetail_subheading: {
    id: 'reports.grade.detail.subheading',
    defaultMessage:
      'Followings are the students of class {grade}. Select a student to see reports.',
  },
  gradeDetail_search_label: {
    id: 'content-library.search.label',
    defaultMessage: 'Search',
  },
  gradeDetail_grades_name: {
    id: 'reports.grades.detail.name',
    defaultMessage: 'Name',
  },
  gradeDetail_email_title: {
    id: 'account.account.email-title',
    defaultMessage: 'Email',
  },
  gradeDetail_action_label: {
    id: 'b2bTeacher.allStudents.table.column.action',
    defaultMessage: 'Action',
  },
  modal_start_end_week_message: {
    id: 'reports.filter.select.starting.ending.week',
    defaultMessage: 'Select a starting and ending week for reports',
  },
  modal_select_filter: {
    id: 'reports.filter.select.quick.filter',
    defaultMessage: 'Select a quick filter',
  },
  modal_filter_last_one_week: {
    id: 'reports.filter.last.week',
    defaultMessage: 'Last 1 week',
  },
  modal_filter_last_two_week: {
    id: 'reports.filter.last.two.weeks',
    defaultMessage: 'Last 2 weeks',
  },
  modal_filter_current_semester: {
    id: 'reports.filter.current.week',
    defaultMessage: 'Current Semester',
  },
  modal_filter_semester_one: {
    id: 'reports.filter.semester.one',
    defaultMessage: 'Semester 1',
  },
  modal_filter_semester_two: {
    id: 'reports.filter.semester.two',
    defaultMessage: 'Semester 2',
  },
  modal_filter_semester_three: {
    id: 'reports.filter.semester.three',
    defaultMessage: 'Semester 3',
  },
  modal_starting_week: {
    id: 'reports.filter.starting.week',
    defaultMessage: 'Starting Week',
  },
  modal_ending_week: {
    id: 'reports.filter.ending.week',
    defaultMessage: 'Ending Week',
  },
  modal_cancel_btn: {
    id: 'account.parents.invite.btn.cancel',
    defaultMessage: 'Cancel',
  },
  modal_view_report_btn: {
    id: 'reports.filter.review.details',
    defaultMessage: 'View Report Detail',
  },
  modal_last_quarter: {
    id: 'reports.filter.last.quarter',
    defaultMessage: 'Last Quarter',
  },
  reports_filter_apply: {
    id: 'reports.filter.apply',
    defaultMessage: 'Apply Filter',
  },
  reports_filter_reset: {
    id: 'reports.filter.reset',
    defaultMessage: 'Reset',
  },
  student_title: {
    id: 'user.title.student',
    defaultMessage: 'Student',
  },
  parent_title: {
    id: 'user.title.parent',
    defaultMessage: 'Parent',
  },
  teacher_title: {
    id: 'user.title.teacher',
    defaultMessage: 'Teacher',
  },
  select_grade: {
    id: 'content.library.assign.lesson.select.grade',
    defaultMessage: 'Select Grade',
  },
  stats_in_progress: {
    id: 'dashboard.progress.in-progress',
    defaultMessage: 'In Progress',
  },
  stats_done: {
    id: 'reports.progress.done',
    defaultMessage: 'Done',
  },
  not_attempted: {
    id: 'reports.progress.not.attempted',
    defaultMessage: 'N/A',
  },
  reports_view_scale: {
    id: 'reports.view.scale',
    defaultMessage: 'View Scale',
  },
  reports_progress_scale: {
    id: 'reports.progress.scale',
    defaultMessage: 'View Scale',
  },
  view_full_report: {
    id: 'reports.progress.view.full.report',
    defaultMessage: 'View Full Report',
  },
  reports_title_capital: {
    id: 'reports.report.title.capital',
    defaultMessage: 'REPORTS',
  },
  overall_stats: {
    id: 'reports.overall.stats',
    defaultMessage: 'Overall Stats',
  },
  enrolled_title: {
    id: 'reports.enrolled.title',
    defaultMessage: 'Enrolled',
  },
  semester_title: {
    id: 'subscription.selectPackage.semester',
    defaultMessage: 'Semester',
  },
  weekly_reports: {
    id: 'reports.weekly',
    defaultMessage: 'Reports Weekly',
  },
  reports_overall_title: {
    id: 'reports.overall.title',
    defaultMessage: 'Overall',
  },
  reports_lesson: {
    id: 'content-library.lesson.title',
    defaultMessage: 'Lesson',
  },
  reports_warm_up: {
    id: 'reports.progress.warm.up',
    defaultMessage: 'Warm Up',
  },
  reports_live_sessions: {
    id: 'reports.progress.live.sessions',
    defaultMessage: 'Live Sessions',
  },
  reports_engagement: {
    id: 'reports.progress.engagement',
    defaultMessage: 'Engagement',
  },
  reports_summary: {
    id: 'reports.progress.summary',
    defaultMessage: 'Summary',
  },
  reports_exercise: {
    id: 'reports.progress.exercise',
    defaultMessage: 'Exercise',
  },
  reports_reinforcement: {
    id: 'reports.progress.reinforcement',
    defaultMessage: 'Reinforcement',
  },
  reports_lessons_title: {
    id: 'schedule.event.detail.lessons',
    defaultMessage: 'Lessons',
  },
  reports_additional_lessons_title: {
    id: 'reports_additional_lessons_title',
    defaultMessage: 'Additional Lessons',
  },
  reports_sessions_title: {
    id: 'reports_sessions_title',
    defaultMessage: 'Sessions',
  },
  reports_attendance: {
    id: 'reports_attendance',
    defaultMessage: 'Attendance',
  },
  reports_lessons_questions: {
    id: 'reports_lessons_question',
    defaultMessage: 'Lessons Questions',
  },
  reports_question_attempted: {
    id: 'reports_question_attempted',
    defaultMessage: 'attempted',
  },
  reports_question_correct: {
    id: 'reports_question_correct',
    defaultMessage: 'correct',
  },
  reports_quick_filters: {
    id: 'reports_quick_filters',
    defaultMessage: 'Quick Filter',
  },
  reports_subscription_title: {
    id: 'reports.subscription.title',
    defaultMessage: 'Subscription',
  },
  reports_subscription_currency: {
    id: 'subscription.paymentSummary.currency',
    defaultMessage: 'SAR',
  },
  reports_renewal_title: {
    id: 'reports.renewal.title',
    defaultMessage: 'RENEWAL DATE',
  },
  reports_renewal_time: {
    id: 'reports.renewal.time',
    defaultMessage: 'Days Left',
  },
  reports_strength_weakness_scale: {
    id: 'reports_strength_weakness_scale',
    defaultMessage: 'Strength / Weakness Overall Scale',
  },
  reports_teacher_classes: {
    id: 'reports_teacher_classes',
    defaultMessage: 'classes',
  },
  reports_teacher_class: {
    id: 'reports_teacher_class',
    defaultMessage: 'class',
  },
  reports_question_bank_title: {
    id: 'question-bank.title',
    defaultMessage: 'Question Bank',
  },
  reports_learning_objectives_title: {
    id: 'learning-objectives.title',
    defaultMessage: 'Learning Objectives',
  },
  reports_learning_objectives_list: {
    id: 'learning-objectives.list.title',
    defaultMessage: 'LESSONS ( Learning Objectives (LO) )',
  },
  reports_learning_objectives_short_title: {
    id: 'learning-objectives.short.title',
    defaultMessage: 'LO',
  },
  reports_recommendations: {
    id: 'reports.recommendations',
    defaultMessage: 'Recommendations',
  },
  reports_filter_title: {
    id: 'reports.filter.title',
    defaultMessage: 'Filter Reports',
  },
  reports_remarks_excellent: {
    id: 'reports.remarks.excellent',
    defaultMessage: 'Excellent',
  },
  reports_remarks_good: {
    id: 'reports.remarks.good',
    defaultMessage: 'Good',
  },
  reports_remarks_aboveAverage: {
    id: 'reports.remarks.aboveAverage',
    defaultMessage: 'Above Average',
  },
  reports_remarks_belowAverage: {
    id: 'reports.remarks.belowAverage',
    defaultMessage: 'Below Average',
  },
  reports_remarks_average: {
    id: 'reports.remarks.average',
    defaultMessage: 'Average',
  },
  reports_remarks_weak: {
    id: 'reports.remarks.weak',
    defaultMessage: 'Weak',
  },
  reports_questions_title: {
    id: 'reports.questions.title',
    defaultMessage: 'Questions',
  },
  reports_details_view_reports: {
    id: 'reports.details.view.reports',
    defaultMessage: 'View Reports',
  },
  reports_grade_detail_select_student: {
    id: 'content.library.assign.lesson.select.student',
    defaultMessage: 'Select Student',
  },
  reports_class_label: {
    id: 'assign.modal.class.label',
    defaultMessage: 'Class',
  },
  reports_grade_1: {
    id: 'reports.grades.label.one',
    defaultMessage: '1',
  },
  reports_grade_2: {
    id: 'reports.grades.label.two',
    defaultMessage: '2',
  },
  reports_grade_3: {
    id: 'reports.grades.label.three',
    defaultMessage: '3',
  },
  reports_grade_4: {
    id: 'reports.grades.label.four',
    defaultMessage: '4',
  },
  reports_grade_5: {
    id: 'reports.grades.label.five',
    defaultMessage: '5',
  },
  reports_grade_6: {
    id: 'reports.grades.label.six',
    defaultMessage: '6',
  },
  reports_grade_7: {
    id: 'reports.grades.label.seven',
    defaultMessage: '7',
  },
  reports_grade_8: {
    id: 'reports.grades.label.eigth',
    defaultMessage: '8',
  },
  reports_grade_9: {
    id: 'reports.grades.label.nine',
    defaultMessage: '9',
  },
  reports_grade_10: {
    id: 'reports.grades.label.ten',
    defaultMessage: '10',
  },
  reports_grade_11: {
    id: 'reports.grades.label.eleven',
    defaultMessage: '11',
  },
  reports_grade_12: {
    id: 'reports.grades.label.twelve',
    defaultMessage: '12',
  },
  reports_grade_13: {
    id: 'reports.grades.label.thirteen',
    defaultMessage: '13',
  },
  reports_grade_14: {
    id: 'reports.grades.label.fourteen',
    defaultMessage: '14',
  },
  reports_grade_15: {
    id: 'reports.grades.label.fifteen',
    defaultMessage: '15',
  },
  reports_grade_16: {
    id: 'reports.grades.label.sixteen',
    defaultMessage: '16',
  },
  reports_school_admin_label: {
    id: 'user.title.school.admin',
    defaultMessage: 'School Admin',
  },
  reports_classes_performance_label: {
    id: 'reports.admin.classes.performance',
    defaultMessage: 'Classes Performance',
  },
  reports_students_progress_label: {
    id: 'reports.admin.students.progress',
    defaultMessage: `Student's Progress`,
  },
  reports_teachers_alert_logs_label: {
    id: 'reports.admin.teachers.alert.log',
    defaultMessage: `Teachers' Alerts Log`,
  },
  defaultError: {
    id: 'b2b.form.defaultError',
    defaultMessage:
      "Sorry, we couldn't complete your request, please check your internet connectivity or try again later.",
  },
  reports_student_name: {
    id: 'reports.admin.table.student.name',
    defaultMessage: 'Student Name',
  },
  reports_progress_status: {
    id: 'reports.admin.table.progress.status',
    defaultMessage: 'Progress Status (Overall)',
  },
  reports_rank: {
    id: 'reports.admin.table.rank',
    defaultMessage: 'Rank',
  },
  reports_filter_all_classes_label: {
    id: 'reports.admin.filter.label.all.classes',
    defaultMessage: 'All classes',
  },
  reports_filter_all_grades_label: {
    id: 'reports.admin.filter.label.all.grades',
    defaultMessage: 'All grades',
  },
  reports_filter_filter_label: {
    id: 'reports.admin.filter.label',
    defaultMessage: 'Filter by',
  },
  no_reports_alt_message: {
    id: 'no.reports.alt.message',
    defaultMessage: 'No Reports',
  },
  no_results_found: {
    id: 'no.resutls.found',
    defaultMessage: 'No Results Found',
  },
  teacher_label: {
    id: 'schedule.event.detail.trainee',
    defaultMessage: 'Teacher:',
  },
  email_teacher_label: {
    id: 'reports.email.teacher.label',
    defaultMessage: 'Email Teacher',
  },
  field_is_required: {
    id: 'form.field.is.required',
    defaultMessage: '{field} is required field, please fill it out.',
  },
  email_teacher_subheading: {
    id: 'reports.email.teacher.subheading',
    defaultMessage: 'Write a custom recommendation to teacher for these students',
  },
  email_teacher_note: {
    id: 'reports.email.teacher.note',
    defaultMessage:
      'We will send this email using ALWs email address. Teachers will know this recommendation is from you.',
  },
  email_teacher_submit: {
    id: 'assignments.form.button.submit',
    defaultMessage: 'Submit',
  },
  email_teacher_email_subject: {
    id: 'reports.email.teacher.email.subject',
    defaultMessage: 'Email Subject',
  },
  email_teacher_email_message: {
    id: 'reports.email.teacher.email.message',
    defaultMessage: 'Message',
  },
  action_label: {
    id: 'reports.action.label',
    defaultMessage: 'Action:',
  },
  alt_profile_picture: {
    id: 'alt.profile.picture',
    defaultMessage: 'ProfilePicture',
  },
  subjects_with_weak_students: {
    id: 'reports.subjects.weak.students',
    defaultMessage: '{subjects} Subjects with weak students in {filter}',
  },
  email_teacher_subject: {
    id: 'assign.teachers.assigned.subject',
    defaultMessage: 'Subject',
  },
  filter_last_month: {
    id: 'reports.filter.last.month',
    defaultMessage: 'Last Month',
  },
  filter_last_semester: {
    id: 'reports.filter.last.semester',
    defaultMessage: 'Last Semester',
  },
  filter_last_two_semester: {
    id: 'reports.filter.last.two.semester',
    defaultMessage: 'Last 2 Semester',
  },
  reports_recommendation_logs_label: {
    id: 'reports.admin.recommendation.logs.label',
    defaultMessage: 'Recommendation Logs',
  },
  table_alert_logs_teacher_name: {
    id: 'reports.alert.logs.teacher.name',
    defaultMessage: 'Teacher Name',
  },

  table_alert_logs_followup: {
    id: 'reports.alert.logs.followup',
    defaultMessage: 'Follow Up',
  },

  table_alert_logs_alerts_sent: {
    id: 'reports.alert.logs.alerts.sent',
    defaultMessage: 'Alerts Sent',
  },

  table_alert_logs_actions: {
    id: 'teacher.table.column.action',
    defaultMessage: 'Actions',
  },
  alert_logs_description: {
    id: 'reports.b2b.admin.alert.logs.description',
    defaultMessage:
      'Following are the classes that haven’t take any action against recommendation logs',
  },
  live_class_recommended: {
    id: 'reports.b2b.admin.alert.logs.live.class.recommended',
    defaultMessage: 'Live Class Recommended',
  },
  view_details: {
    id: 'reports.b2b.admin.alert.logs.view.details',
    defaultMessage: 'View Details',
  },
  not_scheduled: {
    id: 'reports.b2b.admin.alert.logs.not.scheduled',
    defaultMessage: 'Not Scheduled',
  },
  scheduled: {
    id: 'reports.b2b.admin.alert.logs.scheduled',
    defaultMessage: 'Scheduled',
  },
  send_email_to_all_teachers: {
    id: 'reports.b2b.admin.send.email.all.teachers',
    defaultMessage: 'Send Email to all Teachers',
  },
  recommendationsSent: {
    id: 'reports.b2b.admin.recommendations.sent',
    defaultMessage: 'Recommendations Sent - {teacherName}',
  },
  weakStudents: {
    id: 'reports.b2b.admin.weak.students',
    defaultMessage: '{students} weak students ',
  },
  closeButton: {
    id: 'dialog.button.close',
    defaultMessage: 'Close',
  },
  lastQuarter: {
    id: 'reports.filter.last.quarter',
    defaultMessage: 'Last Quarter',
  },
  lastOneWeek: {
    id: 'reports.filter.last.week',
    defaultMessage: 'Last 1 week',
  },
  lastTwoWeek: {
    id: 'reports.filter.last.two.weeks',
    defaultMessage: 'Last 2 weeks',
  },
  firstNameRequired: {
    id: 'registration.form.create-user.first-name.error',
    defaultMessage: 'First Name is required field, please fill it out.',
  },
  lastNameRequired: {
    id: 'registration.form.create-user.last-name.error',
    defaultMessage: 'Last Name is required field, please fill it out.',
  },
  confirmChildEmail: {
    id: 'confirm.child.email',
    defaultMessage: 'Confirm child email',
  },
  emailInvalid: {
    id: 'use.validate.invalid.email',
    defaultMessage: '{trimmedEmail} is not a valid email',
  },
  childUpdatedSuccess: {
    id: 'update.child.success',
    defaultMessage: 'Child Updated SuccessFully.',
  },
  editChildDetails: {
    id: 'reports.edit.modal.description',
    defaultMessage: 'Edit your child details',
  },
  firstNameTitle: {
    id: 'registration.form.add-info.first-name.title',
    defaultMessage: 'First name',
  },
  firstNamePlaceholder: {
    id: 'registration.form.add-info.first-name.placeholder',
    defaultMessage: 'Enter your first name"',
  },
  lastNameTitle: {
    id: 'registration.form.add-info.last-name.title',
    defaultMessage: 'Last name',
  },
  lastNamePlaceholder: {
    id: 'registration.form.add-info.last-name.placeholder',
    defaultMessage: 'Enter your last name"',
  },
  childEmailTitle: {
    id: 'registration.form.add-children.email.title',
    defaultMessage: "Your child's email",
  },
  childEmailPlaceholder: {
    id: 'registration.form.add-children.email.title',
    defaultMessage: "Your child's email",
  },
  updateSubscription: {
    id: 'reports.edit.modal.update.subscription',
    defaultMessage: 'Update Subscription',
  },
  update: {
    id: 'reports.edit.modal.update.button',
    defaultMessage: 'Update',
  },
  selectWeeks: {
    id: 'timetable.grade-schedule.slot-create-modal.select-weeks.label',
    defaultMessage: 'Select Weeks',
  },
  noWeeksFound: {
    id: 'reports.filter.no-weeks-found',
    defaultMessage: 'No weeks found',
  },
  noReportsForCurrentSemester: {
    id: 'reports.message.no-reports-for-current-semester',
    defaultMessage: 'Reports does not exists for current semester.',
  },
});
