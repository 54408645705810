import { css, CSSProp } from 'styled-components';

import { desktopLgWidth, desktopWidth, tabletWidth, mobileWidth } from './styled-variables';

export const sizeMobile = (content: CSSProp) => css`
  @media (min-width: ${mobileWidth}) {
    ${content};
  }
`;

export const sizeTablet = (content: CSSProp) => css`
  @media (min-width: ${tabletWidth}) {
    ${content};
  }
`;

export const sizeDesktop = (content: CSSProp) => css`
  @media (min-width: ${desktopWidth}) {
    ${content};
  }
`;

export const sizeDesktopLg = (content: CSSProp) => css`
  @media (min-width: ${desktopLgWidth}) {
    ${content};
  }
`;
