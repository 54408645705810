import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import { loadBreadcrumbsAction, resetBreadcrumbsAction } from './actions';
import { BreadcrumbsType } from './types';

export type BreadcrumbsActionType = ActionType<typeof actions>;

export const initialState = {
  data: {} as BreadcrumbsType,
  fetching: {
    load: true,
  },
  errors: {
    load: '',
  },
};

const data = createReducer<BreadcrumbsType, BreadcrumbsActionType>(initialState.data)
  .handleAction(loadBreadcrumbsAction.success, (state, action) => action.payload)
  .handleAction(resetBreadcrumbsAction, () => ({}));

const fetching = combineReducers({
  load: createReducer<boolean, BreadcrumbsActionType>(initialState.fetching.load)
    .handleAction(loadBreadcrumbsAction.request, () => true)
    .handleAction([loadBreadcrumbsAction.success, loadBreadcrumbsAction.failure], () => false)
    .handleAction(resetBreadcrumbsAction, () => initialState.fetching.load),
});

const errors = combineReducers({
  load: createReducer<string, BreadcrumbsActionType>(initialState.errors.load)
    .handleAction(loadBreadcrumbsAction.failure, (state, { payload }) => payload)
    .handleAction(loadBreadcrumbsAction.success, () => initialState.errors.load)
    .handleAction(resetBreadcrumbsAction, () => initialState.errors.load),
});

const breadcrumbsReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type BreadcrumbsStateType = StateType<typeof breadcrumbsReducer>;

export default breadcrumbsReducer;
