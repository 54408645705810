import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import { loadQuestionList } from './actions';
import { questionBankDataType, quizTypes } from './types';

type subjectsActionType = ActionType<typeof actions>;
const {
  loadSubjectsAction,
  resetSubjectAction,
  fetchLearningObjectivesAction,
  fetchUnitsActions,
  fetchLessonResultsActions,
  fetchSampleQuestionsActions,
  resetSampleQuestions,
  subjectSearchAction,
  unitsSearchActions,
  learningObjectiveQuizAction,
  submitQuestionCounter,
  fetchTeacherClassesAction,
  fetchSubjectsAction,
  fetchSubjectRelatedUnitsAction,
  fetchUnitRelatedLessonsAction,
  fetchLessonRelatedLearningObjectivesAction,
  fetchAnswerDetailOptionsAction,
  fetchSubjectsGradesAction,
  questionStatusUpdateAction,
  updateLearningObjectiveQuizAction,
  lastAttemptedProblemAction,
  nextProblemAction,
  fetchLearningObjectiveSmartScoreActions,
  updateLearningObjectiveSmartScoreActions,
} = actions;

const initialState = {
  data: {} as questionBankDataType,
  fetching: {
    fetchSubjects: false,
    fetchSubjectsSuccess: false,
    fetchSubjectsFailed: false,
    fetchLearningObjectives: false,
    fetchLearningObjectivesSuccess: false,
    fetchLearningObjectivesFailed: false,
    fetchUnits: false,
    fetchUnitsSuccess: false,
    fetchLessonResults: false,
    fetchSampleQuestions: false,
    fetchTeacherClasses: false,
    fetchQuestionList: false,
    fetchLearningObjectiveSmartScore: false,
  },
  errors: {
    subjectsRequestError: '',
    fetchLearningObjectivesError: '',
  },
};

export const initialQuizStates = {
  isQuestionStatusUpdated: false,
};

export const quizStateReducer = createReducer<quizTypes, subjectsActionType>(initialQuizStates)
  .handleAction([questionStatusUpdateAction], (state, action) => ({
    ...state,
    ...{ isQuestionStatusUpdated: action.payload },
  }))
  .handleAction([lastAttemptedProblemAction], (state, action) => ({
    ...state,
    ...{ lastAttemptedProblem: action.payload },
  }))
  .handleAction([nextProblemAction], (state, action) => ({
    ...state,
    ...{ nextProblem: action.payload },
  }));

const data = createReducer<questionBankDataType, subjectsActionType>(initialState.data)
  .handleAction([loadSubjectsAction.success], (state, action) => ({
    ...state,
    ...{ subjects: action.payload },
  }))
  .handleAction([fetchLearningObjectivesAction.success], (state, action) => ({
    ...state,
    ...{ learningObjectives: action.payload },
  }))
  .handleAction([fetchUnitsActions.success], (state, action) => ({
    ...state,
    ...{ units: action.payload },
  }))
  .handleAction([fetchLessonResultsActions.success], (state, action) => ({
    ...state,
    ...{ lessonResults: action.payload },
  }))
  .handleAction([fetchSampleQuestionsActions.success], (state, action) => ({
    ...state,
    ...{ sampleQuestions: action.payload },
  }))
  .handleAction(
    [learningObjectiveQuizAction.success, updateLearningObjectiveQuizAction.success],
    (state, action) => ({
      ...state,
      ...{ quiz: action.payload },
    }),
  )
  .handleAction([subjectSearchAction.success], (state, action) => ({
    ...state,
    ...{ subjectsSearch: action.payload },
  }))
  .handleAction([unitsSearchActions.success], (state, action) => ({
    ...state,
    ...{ unitsSearch: action.payload },
  }))
  .handleAction([fetchTeacherClassesAction.success], (state, action) => ({
    ...state,
    ...{ teacherClassesData: action.payload },
  }))
  .handleAction([fetchSubjectsAction.success], (state, action) => ({
    ...state,
    ...{ teacherSubjects: action.payload },
  }))
  .handleAction([fetchSubjectRelatedUnitsAction.success], (state, action) => ({
    ...state,
    ...{ teacherUnits: action.payload },
  }))
  .handleAction([fetchUnitRelatedLessonsAction.success], (state, action) => ({
    ...state,
    ...{ teacherLessons: action.payload },
  }))
  .handleAction([loadQuestionList.success], (state, action) => ({
    ...state,
    ...{ questionList: action.payload },
  }))
  .handleAction([fetchLessonRelatedLearningObjectivesAction.success], (state, action) => ({
    ...state,
    ...{ teacherLearningObjectives: action.payload },
  }))
  .handleAction([fetchAnswerDetailOptionsAction.success], (state, action) => ({
    ...state,
    ...{ answerDetailOptions: action.payload },
  }))
  .handleAction([submitQuestionCounter], (state, action) => ({
    ...state,
    ...{ submitCounter: action.payload + 1 },
  }))
  .handleAction([fetchSubjectsGradesAction.success], (state, action) => ({
    ...state,
    ...{ teacherGrades: action.payload },
  }))
  .handleAction(resetSubjectAction, () => initialState.data)
  .handleAction(resetSampleQuestions, (state) => ({
    ...state,
    sampleQuestions: undefined,
  }))
  .handleAction([updateLearningObjectiveSmartScoreActions], (state, action) => ({
    ...state,
    learningObjectiveSmartScore: action.payload,
  }));

const fetching = combineReducers({
  fetchSubjects: createReducer<boolean, subjectsActionType>(initialState.fetching.fetchSubjects)
    .handleAction(loadSubjectsAction.request, () => true)
    .handleAction([loadSubjectsAction.success, loadSubjectsAction.failure], () => false)
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchSubjects),
  fetchSubjectsSuccess: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchSubjectsSuccess,
  )
    .handleAction(loadSubjectsAction.success, () => true)
    .handleAction([loadSubjectsAction.request, loadSubjectsAction.failure], () => false)
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchSubjectsSuccess),
  fetchQuestionList: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchQuestionList,
  )
    .handleAction([loadQuestionList.success, loadQuestionList.failure], () => false)
    .handleAction([loadQuestionList.request], () => true),
  fetchSubjectsFailed: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchSubjectsFailed,
  )
    .handleAction(loadSubjectsAction.failure, () => true)
    .handleAction([loadSubjectsAction.request, loadSubjectsAction.success], () => false)
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchSubjectsFailed),
  fetchLearningObjectives: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchLearningObjectives,
  )
    .handleAction(fetchLearningObjectivesAction.request, () => true)
    .handleAction(
      [fetchLearningObjectivesAction.success, fetchLearningObjectivesAction.failure],
      () => false,
    )
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchSubjects),
  fetchLearningObjectivesSuccess: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchLearningObjectivesSuccess,
  )
    .handleAction(fetchLearningObjectivesAction.success, () => true)
    .handleAction(
      [fetchLearningObjectivesAction.request, fetchLearningObjectivesAction.failure],
      () => false,
    )
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchSubjectsSuccess),
  fetchLearningObjectivesFailed: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchLearningObjectivesFailed,
  )
    .handleAction(fetchLearningObjectivesAction.failure, () => true)
    .handleAction(
      [fetchLearningObjectivesAction.request, fetchLearningObjectivesAction.success],
      () => false,
    )
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchSubjectsFailed),
  fetchUnits: createReducer<boolean, subjectsActionType>(initialState.fetching.fetchUnits)
    .handleAction(fetchUnitsActions.request, () => true)
    .handleAction([fetchUnitsActions.success, fetchUnitsActions.failure], () => false)
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchUnits),
  fetchUnitsSuccess: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchUnitsSuccess,
  )
    .handleAction(fetchUnitsActions.success, () => true)
    .handleAction([fetchUnitsActions.request, fetchUnitsActions.failure], () => false)
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchUnitsSuccess),
  fetchLessonResults: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchLessonResults,
  )
    .handleAction(fetchLessonResultsActions.success, () => true)
    .handleAction(
      [fetchLessonResultsActions.request, fetchLessonResultsActions.failure],
      () => false,
    )
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchLessonResults),
  fetchSampleQuestions: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchSampleQuestions,
  )
    .handleAction(fetchSampleQuestionsActions.success, () => true)
    .handleAction(
      [fetchSampleQuestionsActions.request, fetchSampleQuestionsActions.failure],
      () => false,
    )
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchLessonResults),
  fetchLearningObjectiveSmartScore: createReducer<boolean, subjectsActionType>(
    initialState.fetching.fetchLearningObjectiveSmartScore,
  )
    .handleAction([fetchLearningObjectiveSmartScoreActions.success], () => false)
    .handleAction([resetSubjectAction], () => initialState.fetching.fetchLessonResults),
});

const errors = combineReducers({
  subjectsRequestError: createReducer<string, subjectsActionType>(
    initialState.errors.subjectsRequestError,
  )
    .handleAction(loadSubjectsAction.failure, (state, { payload }) => payload)
    .handleAction(
      [loadSubjectsAction.success, resetSubjectAction],
      () => initialState.errors.subjectsRequestError,
    ),
});

const questionBankReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type questionBankType = StateType<typeof questionBankReducer>;

export default questionBankReducer;
