import React, { useEffect, useRef } from 'react';
import { isArabicMode } from 'utils/helpers';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useIntl } from 'react-intl';

Quill.register('formats/direction', Quill.import('formats/direction'));
Quill.register('formats/align', Quill.import('formats/align'));

function QuillEditor({
  value,
  setValue,
}: {
  value?: string;
  setValue: (val: string) => void;
  useTextModules?: boolean;
}) {
  const { locale } = useIntl();
  const isArabic = isArabicMode(locale);
  const quillRef = useRef<ReactQuill | null>(null);

  const modules = {
    toolbar: [
      [{ font: [] }, { size: ['small', 'medium', 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link'],
      [{ color: [] }, { background: [] }],
      [{ header: '1' }, { header: '2' }],
      ['clean'], // Remove formatting button
    ],
  };

  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'align',
    'link',
    'image',
    'color',
    'background',
    'header',
    'table',
    'clean',
  ];

  useEffect(() => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      editor.root.setAttribute('dir', isArabic ? 'rtl' : 'ltr');
      editor.format('direction', isArabic ? 'rtl' : 'ltr');
      editor.format('align', isArabic ? 'right' : 'left');
    }
  }, [isArabic]);

  return (
    <div className="mb-30" dir={isArabic ? 'rtl' : 'ltr'}>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={(text: string) => {
          setValue?.(text);
        }}
      />
    </div>
  );
}

export default QuillEditor;
