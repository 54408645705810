import { RootStateType } from '../types';
import { SchoolBranchesStateType } from './reducer';
import { SchoolBranchStatStateType } from './statsReducer';
import { SchoolType } from './types';

export const selectSchoolBranches = (state: RootStateType): SchoolBranchesStateType =>
  state.schoolBranches;

export const selectSchoolDetail = (state: RootStateType): SchoolType =>
  state.schoolBranches.data.school;

export const selectSchoolBranchStats = (state: RootStateType): SchoolBranchStatStateType =>
  state.schoolBranchStats;

export const selectCurrentBranch = (state: RootStateType) => state.schoolBranches?.selectedBranch;
