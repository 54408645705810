import React from 'react';

import styles from './InputField.module.scss';
import { ErrorMessage } from './ErrorMessage/ErrorMessage';

type ErrorHelperTextProps = {
  error?: string | boolean;
  helperText?: string;
};

const ErrorHelperText = ({ error, helperText }: ErrorHelperTextProps) => {
  return (
    <>
      <ErrorMessage>{error}</ErrorMessage>
      {!error && helperText && <p className={styles['form-helper']}>{helperText}</p>}
    </>
  );
};

export default ErrorHelperText;
