import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { bulkImportTeachers, bulkImportStudents } from './actions';
import { bulkImportTeachersAPI, bulkImportStudentsAPI } from './api';
import { BulkImportType } from './types';

function* uploadBulkTeachers({
  payload: data,
}: ReturnType<typeof bulkImportTeachers.request>): SagaIterator<void> {
  try {
    data.bulkImportStatus(true);
    const teachersImported: BulkImportType = yield call(bulkImportTeachersAPI, data.payload);
    yield put(bulkImportTeachers.success(teachersImported));
  } catch (error) {
    data.bulkImportStatus(false);
    yield put(bulkImportTeachers.failure(error));
  }
}

function* uploadBulkStudents({
  payload: data,
}: ReturnType<typeof bulkImportStudents.request>): SagaIterator<void> {
  try {
    data.bulkImportStatus(true);
    const studentsImported: BulkImportType = yield call(bulkImportStudentsAPI, data.payload);
    yield put(bulkImportStudents.success(studentsImported));
  } catch (error) {
    yield put(bulkImportStudents.failure(error));
    data.bulkImportStatus(false);
  }
}

function* b2bBulkImportSaga(): SagaIterator {
  yield all([takeEvery(bulkImportTeachers.request, uploadBulkTeachers)]);
  yield all([takeEvery(bulkImportStudents.request, uploadBulkStudents)]);
}

export default b2bBulkImportSaga;
