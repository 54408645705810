import camelcaseKeys from 'camelcase-keys';
import API from 'utils/api';

import {
  assignLessonAPI,
  assignLiveClassContentAPI,
  b2bTeacherClassRoomOptionsWithoutPaginationApi,
  getContentLibraryContentAPI,
  getContentLibraryFiltersAPI,
  getContentLibrarySubjects,
  getLiveClassRecordingsAPI,
  getMentorGrades,
  getStudentGrades,
  markContentCompleteApi,
  selfAssignLesson,
} from 'utils/apiEndpoints';
import {
  FiltersDataType,
  ContentLibraryType,
  ContentLibraryStudentsByGradeType,
  AssignContentResponseType,
  LiveEventType,
  GradeClassroomStudentsRepsonseType,
} from './types';

export async function getFiltersData(semesterId: string): Promise<FiltersDataType> {
  const response = await API.get(getContentLibraryFiltersAPI(), {
    params: { semester_id: semesterId },
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function getContentData(
  grade?: string,
  subject?: string,
  searchText?: string,
  semesterId?: string,
): Promise<Array<ContentLibraryType>> {
  const response = await API.get(getContentLibraryContentAPI(), {
    params: {
      grade: grade ? [grade] : undefined,
      subject: subject ? [subject] : undefined,
      q: searchText || undefined,
      semester_id: semesterId || undefined,
    },
  });
  return response.data;
}

export async function getLiveClassContentData(
  grade?: string,
  subject?: string,
  searchText?: string,
  semesterId?: string,
): Promise<Array<LiveEventType>> {
  const response = await API.get(getLiveClassRecordingsAPI(), {
    params: {
      grade: grade ? [grade] : undefined,
      subject: subject ? [subject] : undefined,
      q: searchText || undefined,
      semester_id: semesterId || undefined,
    },
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function getStudentsData(): Promise<Array<ContentLibraryStudentsByGradeType>> {
  const response = await API.get('/api/alw/content_library/get-mentor-students/');
  return camelcaseKeys(response.data, { deep: true });
}

export async function assignContentData(
  students: Array<number>,
  role: string,
  contentId?: string,
  event?: string,
): Promise<any> {
  const data: { students: Array<number>; role: string; content_id?: string; event?: string } = {
    students,
    role,
  };
  if (event) {
    data.event = event;
  } else {
    data.content_id = contentId;
  }
  const response = await API.post(assignLessonAPI(), data);

  return camelcaseKeys(response.data, { deep: true });
}

export async function uploadAndAssignContent(
  students: Array<number>,
  role: string,
  subject: string,
  lessonName: string,
  file?: File,
  onUploadProgress?: any,
): Promise<any> {
  const data = new FormData();
  data.append('students', `[${students.toString()}]`);
  data.append('role', role);
  data.append('lesson_name', lessonName);
  data.append('subject', subject);
  data.append('file', file!);
  const response = await API.post(assignLessonAPI(), data, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function selfAssignContent(contentId?: string, event?: string): Promise<any> {
  const data: { event?: string; content_id?: string } = {};
  if (event) {
    data.event = event;
  } else {
    data.content_id = contentId;
  }

  const response = await API.post(selfAssignLesson(), data);
  return camelcaseKeys(response.data, { deep: true });
}

export async function assignLiveClassContentData(
  contentId: string,
  students: Array<string>,
  username: string,
): Promise<any> {
  const data = new FormData();
  data.append('event', contentId);
  data.append('assigned_by', username);
  students.forEach((student) => {
    data.append('assignees', student);
  });

  const response = await API.post(assignLiveClassContentAPI(), data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function completeContent(contentId: string): Promise<AssignContentResponseType> {
  const response = await API.put(markContentCompleteApi(contentId));
  return camelcaseKeys(response.data, { deep: true });
}

export async function getSubjects(): Promise<any> {
  const response = await API.get(getContentLibrarySubjects());
  return camelcaseKeys(response.data, { deep: true });
}

export async function getGrades(): Promise<any> {
  const response = await API.get(getMentorGrades());
  return camelcaseKeys(response.data, { deep: true });
}

export async function getGradeStudents(grade: string): Promise<any> {
  const response = await API.get(getStudentGrades(grade));
  const studentOptions: object = camelcaseKeys(response.data, { deep: true }).map(
    (student: GradeClassroomStudentsRepsonseType) => ({
      label: `${student.firstName} ${student.lastName}`,
      value: student.id,
    }),
  );
  return studentOptions;
}

export async function getClassroomStudents(teacherId: number, classroomId: number): Promise<any> {
  const response = await API.get(
    b2bTeacherClassRoomOptionsWithoutPaginationApi(teacherId, classroomId),
  );
  const studentOptions: object = camelcaseKeys(response.data, { deep: true }).map(
    (student: GradeClassroomStudentsRepsonseType) => ({
      label: `${student.firstName} ${student.lastName}`,
      value: student.id,
    }),
  );
  return studentOptions;
}
