import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import API from 'utils/api';
import { getRecommendationSubjectsAPI, getSubjectRecommendationsByIdAPI } from 'utils/apiEndpoints';
import {
  SubjectRecommendationsResponse,
  RecommendationSubject,
  DatesPayload,
  GetSubjectRecommendationsPayload,
} from './types';

export async function getRecommendationSubjects({
  startDate,
  endDate,
}: DatesPayload): Promise<RecommendationSubject[]> {
  const response = await API.get(getRecommendationSubjectsAPI(), {
    params: snakeCaseKeys({ startDate, endDate }),
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getSubjectRecommendationById({
  subjectId,
  startDate,
  endDate,
}: GetSubjectRecommendationsPayload): Promise<SubjectRecommendationsResponse> {
  const response = await API.get(getSubjectRecommendationsByIdAPI(subjectId), {
    params: snakeCaseKeys({ startDate, endDate }),
  });
  return camelcaseKeys(response.data, { deep: true });
}
