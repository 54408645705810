import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Field as FormField } from 'react-final-form';
import { ErrorMessage } from './ErrorMessage/ErrorMessage';

type FormFieldWithErrorProps = {
  name: string;
  render: (props: any) => ReactNode;
};

export const FormFieldWithError: FunctionComponent<FormFieldWithErrorProps> = ({
  name,
  render,
}) => {
  return (
    <FormField name={name}>
      {({ input, meta, ...props }): ReactElement => (
        <>
          {render({ ...input, ...props, ...meta })}
          {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
        </>
      )}
    </FormField>
  );
};
