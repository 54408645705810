import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { Icon, IconCommon } from 'lib';
import { ROUTES } from 'constants/routes';
import { teacherQuestionsStatusSelector } from 'store/childTeacherDetailQuestion/selectors';

import { MenusType } from 'components/LayoutBase/components/Sidebar/types';
import styles from './Sidebar.module.scss';

export function Menu({ id, path, label, icon, image, disabled, setIsOpen, setElement }: MenusType) {
  const { questionsStatus } = useSelector(teacherQuestionsStatusSelector);
  return (
    <NavLink
      to={path}
      key={id || label}
      id={id}
      activeClassName={styles.active}
      exact={path === ROUTES.root}
      className={cn(styles.navigation__item, {
        [styles.disabled]: disabled,
      })}
      ref={(el) => {
        setElement(el);
      }}
      onClick={() => setIsOpen()}
    >
      {image ? (
        <img src={image} alt="icon" className={styles.navigation__icon} />
      ) : (
        <Icon className={styles.navigation__icon} title={label} type={icon ?? 'info'} size="s24" />
      )}
      <div
        className={cn(styles.navigation__title, {
          [styles.title__highlighted]:
            path === `/parent-questions` && questionsStatus?.hasUnansweredQuestions,
        })}
      >
        {label}
      </div>

      {disabled && <IconCommon className="lock" materialIconType="outlined" isMaterialIcon />}
    </NavLink>
  );
}
