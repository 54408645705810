import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react';
import { Icon, IconType } from '../Icon/Icon';
import { Text } from '../Text/Text';
import styles from './styles/Button.module.scss';
import { ButtonSizes, ButtonTypes } from './Button.types';
import ButtonBase from './ButtonBase';

export type ButtonProps = {
  type?: keyof typeof ButtonTypes;
  disabled?: boolean;
  size?: keyof typeof ButtonSizes;
  icon?: IconType;
  isSubmit?: boolean;
  tabIndex?: number;
  onClick?: (e: SyntheticEvent) => void;
  children: ReactNode;
};

export const Button: FunctionComponent<ButtonProps> = ({
  type = ButtonTypes.basic,
  children,
  disabled = false,
  size = ButtonSizes.medium,
  icon,
  isSubmit = false,
  tabIndex,
  onClick = (): void => {},
}) => (
  <ButtonBase
    disabled={disabled}
    className={classNames(styles.button, styles[type], {
      [styles[`size-${size}`]]: size !== ButtonSizes.medium,
    })}
    tabIndex={tabIndex}
    onClick={onClick}
    isSubmit={isSubmit}
  >
    <>
      {icon && (
        <span className={styles.iconWrapper}>
          <Icon type={icon} size="s16" color={type === ButtonTypes.link ? 'primary' : 'dark'} />
        </span>
      )}
      <Text type={type === ButtonTypes.link ? 'caption1' : 'body2'} isInline>
        {children}
      </Text>
    </>
  </ButtonBase>
);
