import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';

import * as actions from './actions';
import {
  loadSemesterScheduleClassesAction,
  loadSemesterScheduleFiltersAction,
  loadSemesterScheduleSubjectsAction,
} from './actions';
import {
  SemesterScheduleClassType,
  SemesterScheduleSubjectType,
  ScheduleFiltersType,
} from './types';

export type SemesterSchedule = ActionType<typeof actions>;

const initialState = {
  data: {
    classes: [] as unknown as SemesterScheduleClassType[],
    subjects: [] as unknown as SemesterScheduleSubjectType[],
    filters: {} as unknown as ScheduleFiltersType,
  },
};

const isLoading = createReducer<boolean, SemesterSchedule>(true)
  .handleAction(
    [loadSemesterScheduleClassesAction.request, loadSemesterScheduleSubjectsAction.request],
    () => true,
  )
  .handleAction(
    [
      loadSemesterScheduleClassesAction.success,
      loadSemesterScheduleClassesAction.failure,
      loadSemesterScheduleSubjectsAction.success,
      loadSemesterScheduleSubjectsAction.failure,
    ],
    () => false,
  );

const data = createReducer<
  {
    classes: SemesterScheduleClassType[];
    subjects: SemesterScheduleSubjectType[];
    filters: ScheduleFiltersType;
  },
  SemesterSchedule
>(initialState.data)
  .handleAction(loadSemesterScheduleClassesAction.success, (state, action) => {
    return { ...state, classes: action.payload };
  })
  .handleAction(loadSemesterScheduleSubjectsAction.success, (state, action) => {
    return { ...state, subjects: action.payload };
  })
  .handleAction(loadSemesterScheduleFiltersAction.success, (state, action) => {
    return { ...state, filters: action.payload };
  });

const errors = createReducer<string, SemesterSchedule>('').handleAction(
  [
    loadSemesterScheduleClassesAction.success,
    loadSemesterScheduleClassesAction.failure,
    loadSemesterScheduleClassesAction.success,
    loadSemesterScheduleClassesAction.failure,
  ],
  (state, action) => action.payload.toString(),
);

const semesterScheduleReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export type SemesterScheduleStateType = StateType<typeof semesterScheduleReducer>;

export default semesterScheduleReducer;
