import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { loadBreadcrumbsAction } from './actions';
import { getBreadcrumbs } from './api';
import { BreadcrumbsType } from './types';

function* loadBreadcrumbRequest(
  action: ReturnType<typeof loadBreadcrumbsAction.request>,
): SagaIterator<void> {
  try {
    const breadcrumbs: BreadcrumbsType = yield call(getBreadcrumbs, action.payload);
    yield put(loadBreadcrumbsAction.success(breadcrumbs));
  } catch (error) {
    yield put(loadBreadcrumbsAction.failure(error));
  }
}

function* breadcrumbsSaga(): SagaIterator {
  yield all([takeEvery(loadBreadcrumbsAction.request, loadBreadcrumbRequest)]);
}

export default breadcrumbsSaga;
