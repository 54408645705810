import camelCaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import API from 'utils/api';
import {
  getAdditionalLessonsUrl,
  getBranchEventsAPI,
  getOverdueLessonsUrl,
  getStudentDashboardDataUrl,
  getUnitsBySubject,
} from 'utils/apiEndpoints';

import {
  StudentDashboardDataResponse,
  TodaySchedulePayload,
  TodayScheduleType,
  OverdueLessonsPayloadType,
  OverdueLessonsResponseType,
  UnitsRequestPayload,
  UnitsType,
  AdditionalLessonsResponseType,
  ChildUsernamePayload,
} from './types';

export const fetchStudentDashboardDataAPI = async ({
  childUsername,
}: ChildUsernamePayload): Promise<StudentDashboardDataResponse> => {
  const response = await API.get(getStudentDashboardDataUrl(), {
    params: { username: childUsername || undefined },
  });
  return camelCaseKeys(response.data, { deep: true });
};

export const fetchTodayScheduleAPI = async ({
  startDate,
  endDate,
  student,
}: TodaySchedulePayload): Promise<TodayScheduleType[]> => {
  const response = await API.get(getBranchEventsAPI(), {
    params: {
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      student,
    },
  });
  return camelCaseKeys(response.data, { deep: true });
};

export const fetchOverdueLessonsAPI = async ({
  childUsername,
  ...restPayload
}: OverdueLessonsPayloadType): Promise<OverdueLessonsResponseType> => {
  const response = await API.get(getOverdueLessonsUrl(), {
    params: snakecaseKeys({ ...restPayload, username: childUsername }, { deep: true }),
  });
  return camelCaseKeys(response.data, { deep: true });
};

export const fetchUnitsBySubjectAPI = async (
  payload: UnitsRequestPayload,
): Promise<UnitsType[]> => {
  const response = await API.get(getUnitsBySubject(payload.subjectId), {
    params: { username: payload.childUsername },
  });
  return camelCaseKeys(response.data, { deep: true });
};

export const fetchAdditionalLessonsAPI = async ({
  childUsername,
}: ChildUsernamePayload): Promise<AdditionalLessonsResponseType> => {
  const response = await API.get(getAdditionalLessonsUrl(), {
    params: snakecaseKeys({ childUsername }),
  });
  return camelCaseKeys(response.data, { deep: true });
};
