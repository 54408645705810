import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';

import * as actions from './actions';
import { RecommendationSubject, SubjectTabs, SubjectRecommendationsResponse } from './types';

type RecommendationsActionType = ActionType<typeof actions>;

const { getRecommendationSubjectsAction, getSubjectRecommendationByIdAction } = actions;

const initialState = {
  data: {
    subjects: [] as SubjectTabs[],
    subjectRecommendations: {} as SubjectRecommendationsResponse,
  },
  fetching: {
    subjects: false,
    subjectRecommendations: false,
  },
  errors: {
    subjects: '',
    subjectRecommendations: '',
  },
};

const updateSubjectsData = (subjects: RecommendationSubject[]) => {
  return subjects?.map(({ id, simpleSubject, ...rest }, index) => ({
    id: index,
    subjectId: id,
    title: simpleSubject,
    ...rest,
  }));
};

const data = combineReducers({
  subjects: createReducer<SubjectTabs[], RecommendationsActionType>(
    initialState.data.subjects,
  ).handleAction(getRecommendationSubjectsAction.success, (state, { payload }) =>
    updateSubjectsData(payload),
  ),
  subjectRecommendations: createReducer<SubjectRecommendationsResponse, RecommendationsActionType>(
    initialState.data.subjectRecommendations,
  ).handleAction(getSubjectRecommendationByIdAction.success, (state, { payload }) => payload),
});

const fetching = combineReducers({
  subjects: createReducer<boolean, RecommendationsActionType>(initialState.fetching.subjects)
    .handleAction(getRecommendationSubjectsAction.request, () => true)
    .handleAction(
      [getRecommendationSubjectsAction.success, getRecommendationSubjectsAction.failure],
      () => false,
    ),
  subjectRecommendations: createReducer<boolean, RecommendationsActionType>(
    initialState.fetching.subjectRecommendations,
  )
    .handleAction(getSubjectRecommendationByIdAction.request, () => true)
    .handleAction(
      [getSubjectRecommendationByIdAction.success, getSubjectRecommendationByIdAction.failure],
      () => false,
    ),
});

const errors = combineReducers({
  errorRecommendationSubjects: createReducer<string, RecommendationsActionType>(
    initialState.errors.subjects,
  ).handleAction(getRecommendationSubjectsAction.failure, (state, { payload }) => payload),
});

const recommendationsReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type RecommendationsType = StateType<typeof recommendationsReducer>;

export default recommendationsReducer;
