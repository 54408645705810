import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import { semesterCompletionProgressAPI, semesterCourseProgressAPI } from 'utils/apiEndpoints';
import { ProgressCourseType, CourseTilesType } from './types';

export async function getCompletionRateBySubject(subjectId: string): Promise<number> {
  const response = await API.get(semesterCompletionProgressAPI(subjectId));
  return camelcaseKeys(response.data.completion_rate, { deep: true });
}

export async function getCourseScore(courseId: string): Promise<ProgressCourseType> {
  const { data } = await API.get(semesterCourseProgressAPI(courseId));
  return data;
}

export async function getCourseTiles(username?: string): Promise<CourseTilesType> {
  const response = await API.get('api/mentora/enrollment/dashboard-course-tiles/', {
    params: { username },
  });
  return camelcaseKeys(response.data.course_tiles, { deep: true });
}
