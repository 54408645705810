import { createAsyncAction } from 'typesafe-actions';

import {
  GetSubjectRecommendationsPayload,
  RecommendationSubject,
  SubjectRecommendationsResponse,
  DatesPayload,
} from './types';

export const getRecommendationSubjectsAction = createAsyncAction(
  '@@recommendations/GET_RECOMMENDATIONS_SUBJECT_REQUEST',
  '@@recommendations/GET_RECOMMENDATIONS_SUBJECT_SUCCESS',
  '@@recommendations/GET_RECOMMENDATIONS_SUBJECT_FAILURE',
)<DatesPayload, RecommendationSubject[], string>();

export const getSubjectRecommendationByIdAction = createAsyncAction(
  '@@recommendations/GET_SUBJECT_RECOMMENDATION_BY_ID_REQUEST',
  '@@recommendations/GET_SUBJECT_RECOMMENDATION_BY_ID_SUCCESS',
  '@@recommendations/GET_SUBJECT_RECOMMENDATION_BY_ID_FAILURE',
)<GetSubjectRecommendationsPayload, SubjectRecommendationsResponse, string>();
