import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Loader } from 'lib';
import { selectIsV1, signupVersionSelector } from 'store/systemConfig/selectors';

import { getAuthRoutes } from './Routes';

export const AuthPaths = getAuthRoutes().map(({ path }) => path);

const AuthRoutes = () => {
  const { isLoading } = useSelector(signupVersionSelector);

  const isV1 = useSelector(selectIsV1);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Switch>
          {getAuthRoutes(isV1).map(({ path, component }) => (
            <Route key={path as string} path={path}>
              {component}
            </Route>
          ))}
        </Switch>
      )}
    </>
  );
};

export default AuthRoutes;
