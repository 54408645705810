import { ActionType, createReducer, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import * as actions from './actions';
import { inviteParentsActions, resetInviteAction } from './actions';

type InviteParentsActionsType = ActionType<typeof actions>;

const sentInvite = createReducer<boolean, InviteParentsActionsType>(false)
  .handleAction(inviteParentsActions.success, () => true)
  .handleAction(resetInviteAction, () => false);

const sendingInvite = createReducer<boolean, InviteParentsActionsType>(false)
  .handleAction(inviteParentsActions.request, () => true)
  .handleAction([inviteParentsActions.success, inviteParentsActions.failure], () => false);

const sendingInviteError = createReducer<string, InviteParentsActionsType>('').handleAction(
  inviteParentsActions.failure,
  (state, { payload }) => payload,
);

const inviteParentsReducer = combineReducers({
  sentInvite,
  sendingInvite,
  sendingInviteError,
});

export type InviteParentsReducerType = StateType<typeof inviteParentsReducer>;
export default inviteParentsReducer;
