import { createAsyncAction, createAction } from 'typesafe-actions';
import { UnitType } from './types';

export const loadUnitsAction = createAsyncAction(
  '@@units/LOAD_REQUEST',
  '@@units/LOAD_SUCCESS',
  '@@units/LOAD_FAILURE',
)<string, UnitType[], string>();

export const resetUnitsAction = createAction('@@units/RESET')();
