import { getPngURL, getSvgURL } from 'lib';

import { ROUTES } from 'constants/routes';
import { DISABLE_B2C_GROUP_CHAT } from 'constants/settings';
import { semesterSelectionGrades } from 'store/account/types';
import { getIntl } from 'utils/intl';
import { MenuItemType } from '../components/Sidebar/types';

export const alwTecherMenu = (grade?: number): MenuItemType[] => {
  const { formatMessage } = getIntl();
  const menu: MenuItemType[] = [
    {
      id: 'sidebar__schedule',
      path: ROUTES.schedule,
      label: formatMessage({ id: 'schedule.title', defaultMessage: 'Schedule' }),
      icon: 'schedule',
    },
    {
      id: 'sidebar__reports',
      path: ROUTES.reports,
      label: formatMessage({ id: 'b2bTeacher.reports.title', defaultMessage: 'Reports' }),
      image: getSvgURL('report'),
    },
    {
      id: 'sidebar__exams',
      path: ROUTES.teacherExams,
      label: formatMessage({ id: 'exams.examinations.title', defaultMessage: 'Examinations' }),
      icon: 'exam',
    },
    {
      id: 'sidebar__question_bank',
      path: ROUTES.questionBankSubjects,
      label: formatMessage({ id: 'question-bank.title', defaultMessage: 'Question Bank' }),
      image: getPngURL('question-bank'),
    },
    {
      id: 'sidebar__sessionQA',
      path: ROUTES.teacherLiveQuestions,
      label: formatMessage({
        id: 'b2bTeacher.sessions.title',
        defaultMessage: 'Sessions Q/A',
      }),
      icon: 'students',
    },
    {
      id: 'sidebar__semester_schedule',
      path: ROUTES.semesterSchedule,
      label: formatMessage({
        id: 'semester.schedule.title',
        defaultMessage: 'Semester Schedule',
      }),
      icon: 'schedule',
    },
    {
      id: 'sidebar__parents_questions',
      path: ROUTES.parentQuestions,
      label: formatMessage({ id: 'parentQuestions.title', defaultMessage: 'Parents Question' }),
      icon: 'question-icon',
    },
  ];

  if (!DISABLE_B2C_GROUP_CHAT) {
    menu.push({
      id: 'sidebar__chatgroup',
      path: ROUTES.groupChat,
      label: formatMessage({ id: 'group-chat.title', defaultMessage: 'Chat Group' }),
      icon: 'group-chat',
    });
  }

  if (grade === semesterSelectionGrades.twelve) {
    menu.push({
      id: 'sidebar__preparation',
      path: ROUTES.preparation,
      label: formatMessage({
        id: 'preparation.material.nav.university.admission.test',
        defaultMessage: 'University Admission Tests',
      }),
      icon: 'admission-test',
    });
  }
  return menu;
};
