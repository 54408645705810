import { SagaIterator } from 'redux-saga';
import { call, put, all, takeEvery } from 'redux-saga/effects';
import { sendFeedbackAction } from './actions';
import { sendFeedbackSystem, sendFeedbackContent } from './api';
import { FeedbackTypes } from './types';

function* sendFeedbackRequest({
  payload: { rating, text, type, callback, subjectId, lessonId, eventId },
}: ReturnType<typeof sendFeedbackAction.request>): SagaIterator<void> {
  try {
    type === FeedbackTypes.content && subjectId && lessonId && eventId
      ? yield call(sendFeedbackContent, subjectId, lessonId, eventId, rating, text)
      : yield call(sendFeedbackSystem, rating, type, text);
    yield put(sendFeedbackAction.success());
    if (callback) yield call(callback);
  } catch (error) {
    let errorMessage = error;
    if (error?.rating && Array.isArray(error?.rating)) {
      const [firstError] = error?.rating;
      errorMessage = firstError || errorMessage;
    }

    yield put(sendFeedbackAction.failure(errorMessage));
  }
}

function* feedbackSaga(): SagaIterator {
  yield all([takeEvery(sendFeedbackAction.request, sendFeedbackRequest)]);
}

export default feedbackSaga;
