import { compose, entries, map } from 'lodash/fp';
import React, { FunctionComponent, ReactElement, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { loadNotificationsAction } from 'store/notifications/actions';
import {
  notificationsV2Selector,
  notificationFetchingSelector,
} from 'store/notifications/selectors';

import { Loader } from 'lib';
import { resolveRelativeDate } from 'utils/date-time';

import { NotificationItem } from '../NotificationItem/NotificationItem';
import style from './NotificationList.module.scss';
import { NotificationListProps } from './NotificationList.types';

export const NotificationList: FunctionComponent<NotificationListProps> = ({
  items,
  onClose,
  isGradeOneToThree,
}): ReactElement => {
  const { formatDate, formatRelativeTime } = useIntl();
  const dispatch = useDispatch();

  const listInnerRef = useRef<HTMLDivElement>(null);

  const notifications = useSelector(notificationsV2Selector);
  const fetchingNotifications = useSelector(notificationFetchingSelector);

  const { numPages: maxPages, currentPage } = notifications || {};
  const page = currentPage + 1;
  const nextPage = page <= maxPages ? page : null;

  const onScroll = () => {
    const { scrollTop = 0, scrollHeight, clientHeight = 0 } = listInnerRef?.current || {};
    const isValidHeight = scrollTop + clientHeight === scrollHeight;
    if (isValidHeight && nextPage && !fetchingNotifications) {
      dispatch(loadNotificationsAction.request({ page: nextPage }));
    }
  };

  return (
    <div
      className={cn(style.notificationList, { [style.grade]: isGradeOneToThree })}
      ref={listInnerRef}
      onScroll={onScroll}
    >
      {compose(
        map(([key, item]) => (
          <div key={key} className={style.notificationList__daily}>
            <h4 className={style.notificationList__title}>
              {resolveRelativeDate(key, formatDate, formatRelativeTime)}
            </h4>
            {compose(
              map(([innerKey, props]) => (
                <NotificationItem
                  key={innerKey}
                  onClose={onClose}
                  isGradeOneToThree={isGradeOneToThree}
                  {...props}
                />
              )),
              entries,
            )(item)}
          </div>
        )),
        entries,
      )(items)}
      {fetchingNotifications && <Loader type="static" />}
    </div>
  );
};
