import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  getClassesAction,
  getTeachersScheduleAction,
  getWeeklyScheduleAction,
  saveWeeklyScheduleAction,
} from './actions';
import {
  getClasses,
  getTeachersSchedule,
  getWeeklyScheduleRequest,
  saveWeeklyScheduleRequest,
} from './api';

function* getClassroomSubjects({
  payload,
}: ReturnType<typeof getClassesAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getClasses, payload);
    yield put(getClassesAction.success(response));
  } catch (error) {
    yield put(getClassesAction.failure(error));
  }
}

function* getTeachersSchedules({
  payload,
}: ReturnType<typeof getTeachersScheduleAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getTeachersSchedule, payload);
    yield put(getTeachersScheduleAction.success(response));
  } catch (error) {
    yield put(getTeachersScheduleAction.failure(error));
  }
}

function* getWeeklySchedule({
  payload,
}: ReturnType<typeof getWeeklyScheduleAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getWeeklyScheduleRequest, payload);
    yield put(getWeeklyScheduleAction.success(response));
  } catch (error) {
    yield put(getWeeklyScheduleAction.failure(error));
  }
}

function* saveWeeklySchedule({
  payload,
}: ReturnType<typeof saveWeeklyScheduleAction.request>): SagaIterator<void> {
  try {
    const { payload: requestPayload, requestData } = payload;
    const response = yield call(saveWeeklyScheduleRequest, requestPayload, requestData);
    payload.callback?.('success');
    yield put(saveWeeklyScheduleAction.success(response));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(saveWeeklyScheduleAction.failure(error));
  }
}

function* flexibleSchedule(): SagaIterator {
  yield all([takeEvery(getClassesAction.request, getClassroomSubjects)]);
  yield all([takeEvery(getTeachersScheduleAction.request, getTeachersSchedules)]);
  yield all([takeEvery(getWeeklyScheduleAction.request, getWeeklySchedule)]);
  yield all([takeEvery(saveWeeklyScheduleAction.request, saveWeeklySchedule)]);
}

export default flexibleSchedule;
