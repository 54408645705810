import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './Avatar.module.scss';

export type AvatarProps = {
  src: string;
  srcLarge: string;
  size?: 16 | 24 | 32 | 40 | 140;
};

export const Avatar: FunctionComponent<AvatarProps> = ({ src, srcLarge, size = 140 }) => {
  const isLarge = [32, 40, 140].includes(size);

  return (
    <div className={classNames(styles.avatar, styles[`s${size}`])}>
      <img src={isLarge ? srcLarge : src} className={styles.image} alt="" />
    </div>
  );
};
