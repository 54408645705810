import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useRouteMatch, useHistory } from 'react-router-dom';

import { Loader } from 'lib';
import { ROUTES } from 'constants/routes';
import { DISABLE_ARS } from 'constants/settings';
import { useIsGradeOneToThree } from 'modules/GradeOneToThree/hooks/useIsGradeOneToThree';

import { getRedirectionUrl, iSubjectsLessThanMinimumLimit, isSemesterActive } from 'utils/helpers';
import { isNullOrUndefined } from 'utils/isNullOrUndefined';
import { isSummerSemester } from 'utils/summerSemesterUtils';
import { getDisabledRoutes } from 'utils/routing';

import { loadAccountAction, logoutAction } from 'store/account/actions';
import { AdminRole, Role, SchoolRole, semesterSelectionGrades } from 'store/account/types';
import { loadLearningProfilesAction } from 'store/learningProfiles/action';
import { selectorLearningProfiles } from 'store/learningProfiles/selectors';
import { loadPreferencesAction } from 'store/preferences/actions';
import { selectorLocale } from 'store/preferences/selectors';
import { loadChildrenSubscriptions } from 'store/childrenSubscriptions/actions';
import { loadSemestersAction, resetSemesterAction } from 'store/semesters/actions';
import {
  getCurrentSemesterDetails,
  getSemestersDetails,
  getSemestersIsLoading,
} from 'store/semesters/selectors';
import {
  isAlwRoleSelector,
  isAutorizedRoleSelector,
  isSchoolRoleSelector,
  selectorAccount,
  isAuthorOrAlwAdminSelector,
  disabledServicesSelector,
} from 'store/account/selectors';
import {
  isAnyB2bChildSelector,
  isAnyChildSubscribedServicesSelector,
  isChildSubscriptionLoadingSelecter,
} from 'store/childrenSubscriptions/selector';

import SchoolRoutes from './SchoolRoute';

export interface PrivateRouteProps extends RouteProps {
  roles?: Role[];
  schoolRoles?: SchoolRole[];
  adminRoles?: AdminRole[];
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  roles,
  schoolRoles,
  adminRoles,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isSubscriptionsPage = useRouteMatch(ROUTES.subscriptions);
  const isPreparationMaterialPage = useRouteMatch(ROUTES.preparation);
  const isRecommendationPage = useRouteMatch(ROUTES.recommendation);
  const isNativeEventsPage = useRouteMatch(ROUTES.nativeEvents);
  const isSelectSubjectsPage = useRouteMatch(ROUTES.semesterSubjects);
  const isQiyasPage = useRouteMatch(ROUTES.qiyas);
  const isAccountPage = useRouteMatch(ROUTES.account);
  const isHelpPage = useRouteMatch(ROUTES.help);
  const isPromotionPage = useRouteMatch(ROUTES.promotion);

  const isAuthorOrAlwAdmin = useSelector(isAuthorOrAlwAdminSelector);
  const isAlwRole = useSelector(isAlwRoleSelector);
  const isSchoolRole = useSelector(isSchoolRoleSelector);
  const isAuthorizedRole = useSelector(isAutorizedRoleSelector);
  const currentSemesterDetails = useSelector(getCurrentSemesterDetails);
  const semesters = useSelector(getSemestersDetails);
  const isSemesterLoading = useSelector(getSemestersIsLoading);

  const isValidInActiveRolePage =
    !!isPromotionPage || !!isHelpPage || !!isAccountPage || !!isSubscriptionsPage;

  const {
    fetching: { load: isLoadingAccount, logout: isLoadingLogout },
    data: account,
  } = useSelector(selectorAccount);
  const {
    fetching: { load: isLoadingLocale },
  } = useSelector(selectorLocale);
  const {
    fetching: { load: isLoadingLearningProfiles },
  } = useSelector(selectorLearningProfiles);
  const isChildSubscriptionLoading = useSelector(isChildSubscriptionLoadingSelecter);
  const isAnyChildSubscribedServices = useSelector(isAnyChildSubscribedServicesSelector);
  const isAnyB2bChild = useSelector(isAnyB2bChildSelector);
  const disabledServices = useSelector(disabledServicesSelector);

  const { grade, role, schoolRole, isArchived, subscribedServices } = account;
  const isParent = role === Role.parent;
  const isAlwStudent = role === Role.student;
  const isB2bStudent = schoolRole === SchoolRole.schoolStudent;
  const isStudentRole = isAlwStudent || isB2bStudent;
  const { minimumAllowedSubjects, gradeValue: studentGrade } = grade || {};

  const disabledRoutes = getDisabledRoutes(disabledServices, isAlwStudent);
  const isDisabledRoute = useRouteMatch(disabledRoutes);

  const isSubjectsLessThanMinimumLimit = useMemo(() => {
    if (currentSemesterDetails) {
      return iSubjectsLessThanMinimumLimit(
        currentSemesterDetails.enrollmentsCount,
        minimumAllowedSubjects,
      );
    }
    return false;
  }, [minimumAllowedSubjects, currentSemesterDetails]);

  const redirectToSubjectSelection =
    !isSummerSemester &&
    !isSelectSubjectsPage &&
    isB2bStudent &&
    (studentGrade === semesterSelectionGrades.eleven ||
      studentGrade === semesterSelectionGrades.twelve) &&
    isSubjectsLessThanMinimumLimit;

  const isPromotionalPages =
    !isSemesterLoading && isAlwStudent && !isSemesterActive(semesters) && !isValidInActiveRolePage;

  useEffect(() => {
    dispatch(loadAccountAction.request());
    dispatch(loadPreferencesAction.request());
    dispatch(loadLearningProfilesAction.request());
    dispatch(loadSemestersAction.request());

    return () => {
      dispatch(resetSemesterAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingAccount && isParent) {
      dispatch(loadChildrenSubscriptions.request());
    }
  }, [dispatch, isParent, isLoadingAccount]);

  const isGradeOneToThree = useIsGradeOneToThree();

  if (
    !currentSemesterDetails ||
    isLoadingAccount ||
    isLoadingLocale ||
    isEmpty(account) ||
    isLoadingLearningProfiles ||
    (isParent && isChildSubscriptionLoading)
  ) {
    return <Loader size={isGradeOneToThree ? 's100' : 's60'} />;
  }

  if (!isAuthorizedRole) {
    dispatch(logoutAction.request());
    if (!isLoadingLogout) history.push(getRedirectionUrl());
    return null;
  }

  if (isArchived) {
    dispatch(logoutAction.request());
    history.push(getRedirectionUrl());
  }

  if (isRecommendationPage && DISABLE_ARS) return <Redirect to={ROUTES.root} />;

  if (
    isSchoolRole &&
    ((isNullOrUndefined(schoolRoles) && isNullOrUndefined(roles)) ||
      (schoolRoles && schoolRoles?.length > 0)) &&
    !isNativeEventsPage
  ) {
    return <SchoolRoutes schoolRoles={schoolRoles} {...props} />;
  }
  if (
    !isSubscriptionsPage &&
    !isPromotionPage &&
    ((isAlwStudent && !subscribedServices) ||
      (isParent && !isAnyChildSubscribedServices && !isAnyB2bChild))
  ) {
    return <Redirect to={ROUTES.subscriptions} />;
  }

  if (isPromotionalPages) {
    return <Redirect to={ROUTES.promotion} />;
  }

  if (
    (isAlwStudent || role === Role.alwTeacher) &&
    isPreparationMaterialPage &&
    studentGrade !== semesterSelectionGrades.twelve
  ) {
    return <Redirect to={ROUTES.root} />;
  }
  if (redirectToSubjectSelection) {
    return <Redirect to={ROUTES.semesterSubjects} />;
  }

  if (isAlwRole && !isNullOrUndefined(roles) && !roles?.includes(role)) {
    return <Redirect to={ROUTES.root} />;
  }
  if (isSchoolRole && !isNullOrUndefined(schoolRoles) && !schoolRoles?.includes(schoolRole)) {
    return <Redirect to={ROUTES.root} />;
  }

  // if a user logged-in with ALW role But no ALW roles exist
  if (isAlwRole && isNullOrUndefined(roles) && schoolRoles?.length)
    return <Redirect to={ROUTES.root} />;

  // if a user logged-in with school role But no school roles exist
  if (isSchoolRole && isNullOrUndefined(schoolRoles) && roles?.length)
    return <Redirect to={ROUTES.root} />;

  // if a user logged-in with admin or content author role But no  admin or content author roles exist
  if (
    isAuthorOrAlwAdmin &&
    isNullOrUndefined(adminRoles) &&
    !roles?.includes(Role.contentAuthor) &&
    (schoolRoles?.length || roles?.length)
  )
    return <Redirect to={ROUTES.root} />;

  if (isQiyasPage && isStudentRole && !(studentGrade === 11 || studentGrade === 12))
    return <Redirect to={ROUTES.root} />;

  if (isAlwStudent && isDisabledRoute) return <Redirect to={ROUTES.root} />;

  return <Route {...props} />;
};

export default PrivateRoute;
