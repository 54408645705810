import camelcaseKeys from 'camelcase-keys';
import API from 'utils/api';
import { ScheduleDetailPayload, SemesterScheduleClassType } from './types';

export async function getSemesterScheduleClasses(): Promise<SemesterScheduleClassType> {
  const response = await API.get('/api/semester/teacher-semester-schedule/');
  return camelcaseKeys(response.data, { deep: true });
}
export async function getSemesterScheduleFilters(
  grade: string,
): Promise<SemesterScheduleClassType> {
  const response = await API.get('/api/semester/semester-subjects/', {
    params: {
      grade,
    },
  });
  return camelcaseKeys(response.data, { deep: true });
}

export async function getSemesterScheduleDetail(payload: ScheduleDetailPayload): Promise<any> {
  const response = await API.get(`/api/semester/teacher-semester-week-detail/${payload.grade}/`, {
    params: {
      week: payload.week,
      subject: payload.subject ? encodeURI(payload.subject) : undefined,
    },
  });

  return camelcaseKeys(response.data, { deep: true });
}
