import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { setConfiguration } from 'react-grid-system';
import { Provider } from 'react-redux';
import { configure } from 'axios-hooks';

import App from './components/App';
import { GOOGLE_KEY, ENABLE_GOOGLE_ANALYTICS } from './constants/settings';
import API from './utils/api';
import store from './store';
import { setUpSentry } from './setUpSentry';
import './index.scss';
import './polyfills';
import 'scss/variables/assets';

setConfiguration({
  gridColumns: 12,
  breakpoints: [568, 768, 992, 1200, 1440],
  gutterWidth: 24,
});

if (ENABLE_GOOGLE_ANALYTICS) ReactGA.initialize(GOOGLE_KEY);

configure({ axios: API });

setUpSentry();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
