import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { PersistedStateKeys, REDIRECT_LOGIN_URL, SELECTED_ROLE } from 'constants/entities';
import { removePresistState } from 'utils/helpers';
import { removeOrientationVideoWatched } from 'components/Orientation/utils';
import { clearDraftSubscriptionData } from 'store/subscriptions/actions';

import {
  activateAccountAction,
  confirmEmailAction,
  forgetPasswordActionV2,
  generateAccountOTPAction,
  generateLoginOtpAction,
  getCSRFAction,
  inviteParentAction,
  loadAccountAction,
  loginAction,
  loginOtpAction,
  logoutAction,
  resetPasswordAction,
  resetPasswordActionV2,
  setPasswordAction,
  updateAccountAction,
  updateAccountFirstNameAction,
  updateAccountLastNameAction,
  updateAccountPhoneAction,
  uploadAvatarAction,
  verifyAccountOTPAction,
  verifyLoginOtpAction,
} from './actions';
import {
  activateAccount,
  confirmEmail,
  forgetPasswordV2,
  generateLoginOtp,
  generateOTP,
  getAccount,
  getCSRF,
  getMe,
  inviteParent,
  login,
  logout,
  resetPassword,
  resetPasswordV2,
  setPassword,
  updateAccount,
  uploadAvatar,
  verifyOTP,
  verifyOtpLogin,
  verifyPhoneOtpLogin,
} from './api';
import { AccountStateType } from './reducer';
import { selectorAccount } from './selectors';
import {
  AccountType,
  InvitationStatus,
  MeType,
  ParentInvitationType,
  ResetPasswordResponseType,
  UploadAvatarResponseErrorType,
} from './types';
import { resetOrientationDataAction } from '../orientation/actions';
import { resetPreferencesAction } from '../preferences/actions';

function* loadAccountRequest(): SagaIterator<void> {
  try {
    const { username, isArchived }: MeType = yield call(getMe);
    const account: AccountType = yield call(getAccount, username);
    yield put(loadAccountAction.success({ ...account, isArchived }));
    localStorage.removeItem('engtMenu');
  } catch (error) {
    yield put(loadAccountAction.failure(error));
  }
}

function* updateAccountRequest({
  payload,
}: ReturnType<typeof updateAccountAction.request>): SagaIterator<void> {
  try {
    const {
      data: { username },
    }: AccountStateType = yield select(selectorAccount);
    const account: AccountType = yield call(updateAccount, username, payload);
    yield put(updateAccountAction.success(account));
    if (payload?.callback) payload.callback(true, null);
  } catch (error) {
    let errorMessage = error;

    if (Array.isArray(error)) {
      const [firstError] = error;
      errorMessage = firstError?.userMessage || errorMessage;
    }
    if (error?.user_message) {
      errorMessage = error.user_message;
    }
    if (payload?.callback) payload.callback(false, errorMessage);
    yield put(updateAccountAction.failure(errorMessage));
  }
}

function* updateAccountPhoneRequest({
  payload,
}: ReturnType<typeof updateAccountPhoneAction.request>): SagaIterator<void> {
  try {
    const {
      data: { username },
    }: AccountStateType = yield select(selectorAccount);
    const account: AccountType = yield call(updateAccount, username, payload);
    yield put(updateAccountPhoneAction.success(account));
  } catch (error) {
    let errorMessage = error;
    if (Array.isArray(error)) {
      const [firstError] = error;
      errorMessage = firstError?.userMessage || errorMessage;
    }
    yield put(updateAccountPhoneAction.failure(errorMessage));
  }
}

function* updateAccountFirstName({
  payload: { username, firstName },
}: ReturnType<any>): SagaIterator<void> {
  try {
    const account: AccountType = yield call(updateAccount, username, { firstName });
    yield put(updateAccountFirstNameAction.success(account));
  } catch (error) {
    let errorMessage = error;
    if (Array.isArray(error)) {
      const [firstError] = error;
      errorMessage = firstError?.userMessage || errorMessage;
    }
    yield put(updateAccountFirstNameAction.failure(errorMessage));
  }
}

function* updateAccountLastName({
  payload: { username, lastName },
}: ReturnType<any>): SagaIterator<void> {
  try {
    const account: AccountType = yield call(updateAccount, username, { lastName });
    yield put(updateAccountLastNameAction.success(account));
  } catch (error) {
    let errorMessage = error;
    if (Array.isArray(error)) {
      const [firstError] = error;
      errorMessage = firstError?.userMessage || errorMessage;
    }
    yield put(updateAccountLastNameAction.failure(errorMessage));
  }
}

function* uploadAvatarRequest({
  payload: { file, callback },
}: ReturnType<typeof uploadAvatarAction.request>): SagaIterator<void> {
  try {
    const {
      data: { username },
    }: AccountStateType = yield select(selectorAccount);
    yield call(uploadAvatar, username, file);
    const account: AccountType = yield call(getAccount, username);
    yield put(uploadAvatarAction.success(account));
    callback('success');
  } catch (error) {
    yield put(uploadAvatarAction.failure(error));
    callback('error', error as UploadAvatarResponseErrorType);
  }
}

function* resetPasswordRequest({
  payload,
}: ReturnType<typeof resetPasswordAction.request>): SagaIterator<void> {
  try {
    yield call(resetPassword, payload.email);
    yield put(resetPasswordAction.success());
    payload.callback();
  } catch (error) {
    yield put(resetPasswordAction.failure(error));
  }
}

function* resetPasswordRequestV2({
  payload,
}: ReturnType<typeof resetPasswordActionV2.request>): SagaIterator<void> {
  try {
    yield call(resetPasswordV2, payload);
    yield put(resetPasswordActionV2.success());
    payload.callback();
  } catch (error) {
    yield put(resetPasswordActionV2.failure(error));
  }
}

function* forgetPasswordRequestV2({
  payload,
}: ReturnType<typeof forgetPasswordActionV2.request>): SagaIterator<void> {
  try {
    const data: { success: string; error: string } = yield call(forgetPasswordV2, payload.email);
    yield put(forgetPasswordActionV2.success());
    if (data.success) payload.callback(data.success, true);
    else payload.callback(data.error, false);
  } catch (error) {
    payload.callback(error, false);
    yield put(forgetPasswordActionV2.failure(error));
  }
}

function* setPasswordRequest({
  payload: { password1, email, token, callback },
}: ReturnType<typeof setPasswordAction.request>): SagaIterator<void> {
  try {
    const response: ResetPasswordResponseType = yield call(setPassword, password1, token, email);
    yield put(setPasswordAction.success());
    callback(response.redirectLoginUrl);
  } catch (error) {
    yield put(setPasswordAction.failure(error));
  }
}

function* loginOtpRequest({
  payload: { email, signature, loginPage, otp, callback },
}: ReturnType<typeof loginOtpAction.request>): SagaIterator<void> {
  try {
    const data = yield call(verifyOtpLogin, email, signature, loginPage, otp);
    localStorage.removeItem('schoolBranchId');
    localStorage.removeItem('engtMenu');
    localStorage.setItem(REDIRECT_LOGIN_URL, loginPage);
    yield put(loginOtpAction.success(data));
    callback?.({ type: 'success' });
  } catch (error) {
    yield put(loginOtpAction.failure(error));
    callback?.({ type: 'error', error });
  }
}

function* loginRequest({
  payload: { email, password, loginPage, callback },
}: ReturnType<typeof loginAction.request>): SagaIterator<void> {
  try {
    const data = yield call(login, email, password, loginPage);
    localStorage.removeItem('schoolBranchId');
    localStorage.removeItem('engtMenu');
    localStorage.setItem(REDIRECT_LOGIN_URL, loginPage);
    removeOrientationVideoWatched();
    callback?.(true);
    yield put(loginAction.success(data));
    yield put(resetOrientationDataAction());
  } catch (error) {
    callback?.(false);
    yield put(loginAction.failure(error));
  }
}

function* logoutRequest(): SagaIterator<void> {
  try {
    yield call(logout);
    localStorage.removeItem('schoolBranchId');
    localStorage.removeItem(SELECTED_ROLE);
    removePresistState(PersistedStateKeys.registrationFields);
    removeOrientationVideoWatched();
    yield put(resetOrientationDataAction());
    yield put(resetPreferencesAction());
    yield put(clearDraftSubscriptionData());
    yield put(logoutAction.success());
  } catch (error) {
    yield put(logoutAction.failure(error));
  }
}

function* getCSRFRequest(): SagaIterator<void> {
  try {
    yield call(getCSRF);
    yield put(getCSRFAction.success());
  } catch (error) {
    yield put(getCSRFAction.failure(error));
  }
}

function* activateAccountRequest({
  payload: { token, callback },
}: ReturnType<typeof activateAccountAction.request>): SagaIterator<void> {
  try {
    yield call(activateAccount, token);
    callback?.({ type: 'success' });
    yield put(activateAccountAction.success());
  } catch (error) {
    callback?.({ type: 'error', error });
    yield put(activateAccountAction.failure(error));
  }
}

function* confirmEmailRequest({
  payload: { token, callback },
}: ReturnType<typeof confirmEmailAction.request>): SagaIterator<void> {
  try {
    yield call(confirmEmail, token);
    callback?.({ type: 'success' });
    yield put(confirmEmailAction.success());
  } catch (error) {
    yield put(confirmEmailAction.failure(error));
  }
}

function* inviteParentRequest({
  payload,
}: ReturnType<typeof inviteParentAction.request>): SagaIterator<void> {
  try {
    yield call(inviteParent, payload);
    const parentInvitation: ParentInvitationType = {
      status: InvitationStatus.sent,
      email: payload,
    };

    yield put(inviteParentAction.success({ parentInvitation }));
  } catch (error) {
    yield put(inviteParentAction.failure(error));
  }
}

function* generateLoginOtpRequest({
  payload: { phone, callback },
}: ReturnType<typeof generateLoginOtpAction.request>): SagaIterator<void> {
  try {
    const data = yield call(generateLoginOtp, phone);
    callback({ isSuccess: true, data });
    yield put(generateLoginOtpAction.success(data));
  } catch (error) {
    callback({ isSuccess: false, error });
    yield put(generateLoginOtpAction.failure(error));
  }
}

function* verifyLoginOtp({
  payload: { phone, signature, otp, callback },
}: ReturnType<typeof verifyLoginOtpAction.request>): SagaIterator<void> {
  try {
    const data = yield call(verifyPhoneOtpLogin, phone, signature, otp);
    callback({ isSuccess: true, data });
    yield put(verifyLoginOtpAction.success(data));
  } catch (error) {
    callback({ isSuccess: false, error });
    yield put(verifyLoginOtpAction.failure(error));
  }
}

function* generateAccountOTPRequest({
  payload,
}: ReturnType<typeof generateAccountOTPAction.request>): SagaIterator<void> {
  try {
    const otp = yield call(generateOTP, payload);
    yield put(generateAccountOTPAction.success(otp));
  } catch (error) {
    yield put(generateAccountOTPAction.failure(error));
  }
}

function* verifyAccountOTPRequest({
  payload: { callback, phone, email, ...payload },
}: ReturnType<typeof verifyAccountOTPAction.request>): SagaIterator<void> {
  try {
    const updatedData = phone ? { phone: `${phone.code}${phone.number}` } : { email };
    const otpVerifyResponse = yield call(verifyOTP, { ...updatedData, ...payload });
    yield put(verifyAccountOTPAction.success(otpVerifyResponse));
    callback(true);
    if (otpVerifyResponse.isOtpVerified) {
      const accountData = phone ? { phone } : { email, username: email };
      yield put(updateAccountAction.success(accountData));
    }
  } catch (error) {
    yield put(verifyAccountOTPAction.failure(error));
    callback(false);
  }
}

function* accountSaga(): SagaIterator {
  yield all([
    takeEvery(loadAccountAction.request, loadAccountRequest),
    takeEvery(updateAccountAction.request, updateAccountRequest),
    takeEvery(updateAccountPhoneAction.request, updateAccountPhoneRequest),
    takeEvery(uploadAvatarAction.request, uploadAvatarRequest),
    takeEvery(forgetPasswordActionV2.request, forgetPasswordRequestV2),
    takeEvery(resetPasswordAction.request, resetPasswordRequest),
    takeEvery(resetPasswordActionV2.request, resetPasswordRequestV2),
    takeEvery(inviteParentAction.request, inviteParentRequest),
    takeEvery(setPasswordAction.request, setPasswordRequest),
    takeEvery(loginOtpAction.request, loginOtpRequest),
    takeEvery(loginAction.request, loginRequest),
    takeEvery(logoutAction.request, logoutRequest),
    takeEvery(getCSRFAction.request, getCSRFRequest),
    takeEvery(activateAccountAction.request, activateAccountRequest),
    takeEvery(confirmEmailAction.request, confirmEmailRequest),
    takeEvery(updateAccountFirstNameAction.request, updateAccountFirstName),
    takeEvery(updateAccountLastNameAction.request, updateAccountLastName),
    takeEvery(generateLoginOtpAction.request, generateLoginOtpRequest),
    takeEvery(verifyLoginOtpAction.request, verifyLoginOtp),
    takeEvery(generateAccountOTPAction.request, generateAccountOTPRequest),
    takeEvery(verifyAccountOTPAction.request, verifyAccountOTPRequest),
  ]);
}

export default accountSaga;
