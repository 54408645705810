import API from 'utils/api';

import { PreferencesType, ResultType } from './types';

const preferredLanguage = 'pref-lang';
const getSystemPreferencesAPI = (username?: string) => `/api/user/v1/preferences/${username}`;

export async function getPreferences(username: string): Promise<PreferencesType> {
  const response = await API.get(getSystemPreferencesAPI(username));

  return response.data;
}

export async function updatePreferences(
  username?: string,
  preferences?: PreferencesType,
): Promise<PreferencesType | ResultType | undefined> {
  await API.patch(
    getSystemPreferencesAPI(username),
    {
      ...preferences,
      ...(preferences?.prefLang && { [preferredLanguage]: preferences?.prefLang.substring(0, 2) }),
      ...(preferences?.theme && { theme: preferences.theme }),
    },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    },
  );
  return preferences;
}
