import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { loadAssignedSubjectsAction, addAssignedSubjectsAction } from './actions';
import { getAssignedSubjects, addAssignedSubjects } from './api';
import { AssignedSubjectType, SemesterSubjectType } from './types';

function* loadAssignedSubjectsRequest({
  payload,
}: ReturnType<typeof loadAssignedSubjectsAction.request>): SagaIterator<void> {
  try {
    const semesterSubject: SemesterSubjectType = yield call(getAssignedSubjects, payload);
    yield put(loadAssignedSubjectsAction.success([semesterSubject]));
  } catch (error) {
    yield put(loadAssignedSubjectsAction.failure(error));
  }
}

function* addAssignedSubjectsRequest(
  action: ReturnType<typeof addAssignedSubjectsAction.request>,
): SagaIterator<void> {
  try {
    const subjects: AssignedSubjectType[] = yield call(addAssignedSubjects, action.payload);
    yield put(addAssignedSubjectsAction.success(subjects));
  } catch (error) {
    yield put(addAssignedSubjectsAction.failure(error));
  }
}

function* assignedSubjectsSaga(): SagaIterator {
  yield all([
    takeEvery(loadAssignedSubjectsAction.request, loadAssignedSubjectsRequest),
    takeEvery(addAssignedSubjectsAction.request, addAssignedSubjectsRequest),
  ]);
}

export default assignedSubjectsSaga;
