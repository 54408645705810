import { createSelector } from 'reselect';

import { RootStateType } from '../types';
import { RewardsStateType } from './reducer';

export const rewardsSelector = (state: RootStateType): RewardsStateType => state.rewards;

export const rewardStatsSelector = createSelector(rewardsSelector, ({ data }) => data);

export const rewardStatsLoadingSelector = createSelector(
  rewardsSelector,
  ({ isLoading }) => isLoading,
);
