import camelcaseKeys from 'camelcase-keys';
import { ChildrenSubscriptionsType } from './types';
import API from '../../utils/api';
import { SubscriptionSate } from '../account/adapter';
import { dataChildrenSubscriptions } from './adapter';

export async function getChildrenSubscriptions(): Promise<ChildrenSubscriptionsType[]> {
  const { data } = await API.get('/api/parent/children-list');

  return dataChildrenSubscriptions(camelcaseKeys(data, { deep: true }));
}

export async function cancelChildrenSubscription(userID: number): Promise<SubscriptionSate> {
  const { data } = await API.post('/api/subscriptions/cancel', { user_id: userID });
  return camelcaseKeys(data, { deep: true });
}
