import { Lang } from '../../lang';
import { LearningProfilesType } from './types';

export type ResponseLearningProfilesType = {
  abbreviationAr: string;
  abbreviationEn: string;
  definitionAr: string;
  definitionEn: string;
  descriptionAr: string;
  descriptionEn: string;
  id: number;
};

export const adapterLearningProfiles = (
  data: ResponseLearningProfilesType[],
): LearningProfilesType => {
  return data
    .map((item: ResponseLearningProfilesType, i, srcArr) => {
      return {
        [Lang.en]: srcArr.map((profile: ResponseLearningProfilesType) => ({
          abbreviation: profile.abbreviationEn,
          definition: profile.definitionEn,
          description: profile.descriptionEn,
          id: profile.id,
        })),
        [Lang.ar]: srcArr.map((profile: ResponseLearningProfilesType) => ({
          abbreviation: profile.abbreviationAr,
          definition: profile.definitionAr,
          description: profile.descriptionAr,
          id: profile.id,
        })),
      };
    })
    .reduce((prev) => ({ ...prev }));
};
