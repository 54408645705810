import React, { ReactElement, useContext, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useToggle, useClickAway, useKey, useEvent } from 'react-use';

import { Avatar, IconCommon } from 'lib';
import { ROUTES } from 'constants/routes';
import { SELECTED_ROLE } from 'constants/entities';
import { getRedirectionUrl } from 'utils/helpers';
import { TourContext } from 'components/TourManagement/TourManagement';

import { Role } from 'store/account/types';
import { updateAccountRoleAction } from 'store/account/actions';
import { isAlwTeacherMentorRoleSelector } from 'store/account/selectors';

import HelpDialog from 'modules/GradeOneToThree/components/BaseLayout/TopHeader/HelpDialog';

import AccountLang from '../AccountLang';
import { ProfileDetailsType } from '../../Sidebar/types';
import { LogoutButton } from '../LogoutButton/LogoutButton';
import styles from './LogoutMenu.module.scss';

export const LogoutMenu = ({
  profileDetails,
}: {
  profileDetails: ProfileDetailsType;
}): ReactElement => {
  const [isOpenLogoutTooltip, setLogoutTooltip] = useToggle(false);
  const { isExist, handleTourOpen } = useContext(TourContext);
  const { formatMessage } = useIntl();
  const currentEl = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isAlwTeacherMentor = useSelector(isAlwTeacherMentorRoleSelector);

  const { name, src, srcLarge, role: userTitle, isGradeOneToThree } = profileDetails;

  useClickAway(currentEl, (): void => {
    setLogoutTooltip(false);
  });

  useKey('Escape', (): void => {
    setLogoutTooltip(false);
  });

  useEvent('blur', () => setLogoutTooltip(false));

  const handleTour = (): void => {
    setLogoutTooltip(false);
    handleTourOpen();
  };

  const onRoleChange = (role: number) => {
    dispatch(updateAccountRoleAction(role));
    localStorage.setItem(SELECTED_ROLE, role.toString());
    history.push(ROUTES.roleSelection);
  };

  const handleOpenHelpDialog = () => {
    if (!isGradeOneToThree) {
      history.push(ROUTES.help);
      return;
    }

    setLogoutTooltip(false);
    setIsDialogOpen(true);
  };

  return (
    <div
      id="topbar__logout"
      ref={currentEl}
      className={cn(styles.topheader__operation, { [styles.grade]: isGradeOneToThree })}
    >
      <div>
        <div role="button" onClick={setLogoutTooltip}>
          <Avatar size={24} src={src} srcLarge={srcLarge} />
        </div>
        {isOpenLogoutTooltip && (
          <div className={styles.menu}>
            <div className={styles.menu__user}>
              <Link to={ROUTES.account} className={styles.menu__name}>
                {name}
              </Link>
              <div className={styles.menu__role}>
                {userTitle}
                {isAlwTeacherMentor && (
                  <span onClick={() => onRoleChange(Role.teacherAndMentor)} role="button">
                    (
                    {formatMessage({
                      id: 'role.switch-button.label',
                      defaultMessage: 'Switch Role',
                    })}
                    )
                  </span>
                )}
              </div>
            </div>
            <div className={styles.menu__items}>
              <div className={styles.menu__item} onClick={handleOpenHelpDialog}>
                <IconCommon className="icon-help" classes={styles.menu__icon} />
                {formatMessage({ id: 'help.title', defaultMessage: 'Help' })}
              </div>
              <Link to={ROUTES.account} className={styles.menu__item}>
                <IconCommon className="icon-setting" classes={styles.menu__icon} />
                {formatMessage({ id: 'settings.title', defaultMessage: 'Settings' })}
              </Link>

              {isExist && (
                <span role="button" onClick={handleTour} className={styles.menu__item}>
                  <IconCommon className="icon-tour" classes={styles.menu__icon} />
                  {formatMessage({ id: 'tour.startButton.label', defaultMessage: 'Start Tour' })}
                </span>
              )}
              <div className={styles.menu__item}>
                <IconCommon className="icon-global-01" classes={styles.menu__icon} />
                <AccountLang type={isGradeOneToThree ? 'yellow' : 'primary'} />
              </div>

              <div className={styles.menu__item}>
                <IconCommon className="icon-signout" classes={styles.menu__icon} />
                <LogoutButton onClick={(): void => history.push(getRedirectionUrl())} />
              </div>
            </div>
          </div>
        )}
      </div>
      <HelpDialog isDialogOpen={isDialogOpen} closeDialog={() => setIsDialogOpen(false)} />
    </div>
  );
};
