import { createSelector } from 'reselect';

import { RootStateType } from '../types';
import { ChildrenSubscriptionsStateType } from './reducer';

export const selectorChildrenSubscriptions = (
  state: RootStateType,
): ChildrenSubscriptionsStateType => state.childrenSubscriptions;

export const childSelector = (state: RootStateType) => (username: string) =>
  state.childrenSubscriptions.data.find((child) => child.username === username);

export const isChildSubscriptionLoadingSelecter = createSelector(
  selectorChildrenSubscriptions,
  ({ fetching }) => fetching.load,
);

export const isAnyChildSubscribedServicesSelector = createSelector(
  selectorChildrenSubscriptions,
  ({ data }) => !!data?.some(({ subscribedServices }) => !!subscribedServices),
);

export const isAnyB2bChildSelector = createSelector(
  selectorChildrenSubscriptions,
  ({ data }) => !!data?.some(({ schoolRole }) => !!schoolRole),
);
