import camelcaseKeys from 'camelcase-keys';
import API from '../../utils/api';
import { FeedbackType, FeedbackTypes } from './types';

export async function sendFeedbackSystem(
  rating: number,
  type: FeedbackTypes,
  text?: string,
): Promise<FeedbackType> {
  const data = new FormData();
  data.append('rating', rating.toString());
  if (text) data.append('text', text);

  const url =
    type === FeedbackTypes.mentor ? '/api/alw/feedback/mentor' : '/api/alw/feedback/platform';

  const response = await API.post(url, data, {
    responseType: 'text',
  });

  return camelcaseKeys(response.data, { deep: true });
}

export async function sendFeedbackContent(
  subjectId: string,
  lessonId: string,
  eventId: string,
  rating: number,
  text?: string,
): Promise<FeedbackType> {
  const data = new FormData();
  data.append('subject', subjectId);
  data.append('lesson', lessonId);
  data.append('event', eventId);
  data.append('rating', rating.toString());
  if (text) data.append('text', text);

  const response = await API.post('/api/alw/feedback/content', data, {
    responseType: 'text',
  });

  return camelcaseKeys(response.data, { deep: true });
}
