import camelcaseKeys from 'camelcase-keys';

import API from 'utils/api';
import { getQiyasExamDetailUrl, getQiyasQuestionsUrl, createExamAttempt } from 'utils/apiEndpoints';
import { containsLatex } from 'utils/helpers';

import { TFetchQuestionsPayload, TQiyasQuestion } from './types';

export async function fetchQiyasExamDetailAPI(examID: number) {
  const response = await API.get(getQiyasExamDetailUrl(examID));
  return camelcaseKeys(response.data, { deep: true });
}

export async function fetchQuestionsByExamAPI({ examID, sectionID }: TFetchQuestionsPayload) {
  const response = await API.get(getQiyasQuestionsUrl(examID, sectionID));
  const data = camelcaseKeys(response.data, { deep: true });

  return data.map((d: TQiyasQuestion) => ({
    ...d,
    answerExplanation: d.answerExplanation
      ? { ...d.answerExplanation, isMath: containsLatex(d.answerExplanation?.explanationText) }
      : undefined,
    answers: d.answers.map((a) => ({ ...a, isMath: containsLatex(a.statement) })),
  }));
}

export async function createExamAttemptAPI(examId: number) {
  const response = await API.post(createExamAttempt(examId));
  return camelcaseKeys(response.data, { deep: true });
}
