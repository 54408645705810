import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';

import * as actions from './actions';
import { loadAvatarsAction } from './actions';
import { AvatarsResponseType } from './types';

export type AvatarsType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, AvatarsType>(true)
  .handleAction([loadAvatarsAction.request], () => true)
  .handleAction([loadAvatarsAction.success, loadAvatarsAction.failure], () => false);

const data = createReducer<AvatarsResponseType, AvatarsType>(
  [] as AvatarsResponseType,
).handleAction(loadAvatarsAction.success, (state, action) => action.payload);

const errors = createReducer<{ [key: string]: string }, AvatarsType>({})
  .handleAction(loadAvatarsAction.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadAvatarsAction.success, (state) => ({
    ...state,
    loading: '',
  }));

const AvatarsReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export type AvatarsStateType = StateType<typeof AvatarsReducer>;

export default AvatarsReducer;
