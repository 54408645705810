import { startCase, gte } from 'lodash/fp';

import { formatDate, diff as timeDifference } from './helpers';

export const resolveRelativeDate = (
  date: string,
  formatRegular: Function,
  formatRelative: Function,
): string => {
  const formatted = formatDate(date);
  const diff = timeDifference(new Date(), formatted, 'days');
  const relativeFormat = {
    numeric: 'auto',
  };
  const regularFormat = {
    month: 'long',
    day: 'numeric',
  };

  const result = gte(1, diff)
    ? formatRelative(-diff, 'day', relativeFormat)
    : formatRegular(date, regularFormat);

  return startCase(result);
};
