import API from 'utils/api';
import { getSignupConfig } from 'utils/apiEndpoints';

import { SystemConfigResponseType } from './types';

export async function getSignupVersion(): Promise<SystemConfigResponseType> {
  const response = await API.get(getSignupConfig());

  return response.data;
}
