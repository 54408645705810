import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';
import * as actions from './actions';
import {
  listGroupAction,
  groupDetailAction,
  unblockParticipantAction,
  blockParticipantAction,
  getAccessTokenAction,
  resetChatAction,
  getSpamWords,
  getBlockStatus,
  getPeer,
} from './actions';
import { GroupChatType } from './types';

export type GroupChatActionType = ActionType<typeof actions>;

const initialState = {
  fetching: {
    groups: false,
    groupDetails: false,
    token: false,
    blockUser: false,
    unblockUser: false,
    blockStatus: false,
    peer: false,
  },
  errors: {
    groups: '',
    groupDetails: '',
    token: '',
    blockUser: '',
    unblockUser: '',
    peer: '',
  },

  data: {} as GroupChatType,
};

const initialSpamWords = {
  data: {},
  error: '',
};

const data = createReducer<GroupChatType, GroupChatActionType>(initialState.data)
  .handleAction(getAccessTokenAction.success, (state, { payload }) => ({ ...state, ...payload }))
  .handleAction(listGroupAction.success, (state, { payload }) => ({
    ...state,
    groups: payload,
  }))
  .handleAction(
    [groupDetailAction.success, blockParticipantAction.success, unblockParticipantAction.success],
    (state, { payload }) => ({
      ...state,
      groupDetails: {
        ...state.groupDetails,
        ...payload,
      },
    }),
  )
  .handleAction([getPeer.success], (state, { payload }) => ({
    ...state,
    peer: [...payload],
  }))
  .handleAction([getBlockStatus.success], (state, { payload }) => ({
    ...state,
    blockStatus: payload.isBlocked,
  }))
  .handleAction(resetChatAction, () => initialState.data);

const fetching = combineReducers({
  token: createReducer<boolean, GroupChatActionType>(initialState.fetching.token)
    .handleAction(getAccessTokenAction.request, () => true)
    .handleAction([getAccessTokenAction.success, getAccessTokenAction.failure], () => false),
  groups: createReducer<boolean, GroupChatActionType>(initialState.fetching.groups)
    .handleAction(listGroupAction.request, () => true)
    .handleAction([listGroupAction.success, listGroupAction.failure], () => false),
  groupDetails: createReducer<boolean, GroupChatActionType>(initialState.fetching.groupDetails)
    .handleAction(groupDetailAction.request, () => true)
    .handleAction([groupDetailAction.success, groupDetailAction.failure], () => false),
  peer: createReducer<boolean, GroupChatActionType>(initialState.fetching.groupDetails)
    .handleAction(getPeer.request, () => true)
    .handleAction([getPeer.success, getPeer.failure], () => false),
  blockUser: createReducer<boolean, GroupChatActionType>(initialState.fetching.blockUser)
    .handleAction([blockParticipantAction.request], () => true)
    .handleAction([blockParticipantAction.success, blockParticipantAction.failure], () => false),
  unblockUser: createReducer<boolean, GroupChatActionType>(initialState.fetching.unblockUser)
    .handleAction([unblockParticipantAction.request], () => true)
    .handleAction(
      [unblockParticipantAction.success, unblockParticipantAction.failure],
      () => false,
    ),
});

const errors = combineReducers({
  token: createReducer<string, GroupChatActionType>(initialState.errors.token)
    .handleAction(getAccessTokenAction.failure, (state, { payload }) => payload)
    .handleAction([getAccessTokenAction.success, getAccessTokenAction.request], () => ''),
  groups: createReducer<string, GroupChatActionType>(initialState.errors.groups)
    .handleAction(listGroupAction.failure, (state, { payload }) => payload)
    .handleAction(listGroupAction.success, () => ''),
  groupDetails: createReducer<string, GroupChatActionType>(initialState.errors.groupDetails)
    .handleAction(groupDetailAction.failure, (state, { payload }) => payload)
    .handleAction(groupDetailAction.success, () => ''),
  blockUser: createReducer<string, GroupChatActionType>(initialState.errors.blockUser)
    .handleAction(blockParticipantAction.failure, (state, { payload }) => payload)
    .handleAction(blockParticipantAction.success, () => ''),
  unblockUser: createReducer<string, GroupChatActionType>(initialState.errors.unblockUser)
    .handleAction(blockParticipantAction.failure, (state, { payload }) => payload)
    .handleAction(blockParticipantAction.success, () => ''),
});

const filteredData = createReducer<Set<string>, GroupChatActionType>(
  {} as Set<string>,
).handleAction(
  getSpamWords.success,
  (state, { payload }) => new Set([...payload.arabicWords, ...payload.englishWords]),
);
const filteredDataError = createReducer<string, GroupChatActionType>(
  initialSpamWords.error,
).handleAction(getSpamWords.failure, (state, { payload }) => payload);

const groupChatReducer = combineReducers({
  data,
  fetching,
  errors,
});

export const spamWordsReducer = combineReducers({
  filteredData,
  filteredDataError,
});

export type GroupChatStateType = StateType<typeof groupChatReducer>;
export type SpamWordsStateType = StateType<typeof spamWordsReducer>;

export default groupChatReducer;
