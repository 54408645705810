import { combineReducers } from 'redux';
import { createReducer, ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import { addStudentAction, loadStudentOptionsAction } from './actions';

export type SchoolStudentActionType = ActionType<typeof actions>;

export const initialState = {
  data: {},
};
const data = createReducer<any, SchoolStudentActionType>(initialState.data)
  .handleAction(addStudentAction.success, (state) => state)
  .handleAction(loadStudentOptionsAction.success, (state, action) => ({
    ...state,
    ...action.payload,
  }));

const fetching = combineReducers({
  create: createReducer<boolean, SchoolStudentActionType>(false)
    .handleAction(addStudentAction.request, () => true)
    .handleAction([addStudentAction.success, addStudentAction.failure], () => false),
  options: createReducer<boolean, SchoolStudentActionType>(false)
    .handleAction(loadStudentOptionsAction.request, () => true)
    .handleAction(
      [loadStudentOptionsAction.success, loadStudentOptionsAction.failure],
      () => false,
    ),
});

const errors = combineReducers({
  create: createReducer<string, SchoolStudentActionType>('')
    .handleAction(addStudentAction.failure, (state, { payload }) => payload)
    .handleAction(addStudentAction.success, () => ''),
  options: createReducer<string, SchoolStudentActionType>('').handleAction(
    loadStudentOptionsAction.failure,
    (state, { payload }) => payload,
  ),
});

const schoolStudentReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type SchoolStudentStateType = StateType<typeof schoolStudentReducer>;

export default schoolStudentReducer;
