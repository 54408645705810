import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  fetchGradesAction,
  fetchGradeDetailAction,
  studentReportsAction,
  reportsStatsScaleAction,
  parentDashboardReportsAction,
  updateReportsChildAction,
  fetchClassroomsAction,
  fetchClassroomDetailsAction,
  fetchStudentProgressClassesAction,
  fetchPerformanceStatsAction,
  fetchStatsStudentsListAction,
  sendTeacherEmailAction,
  fetchRecommendationClasses,
  fetchClassroomRecommendations,
  fetchLiveClassRecommendations,
  reportsStatsScaleByGradeAction,
} from './actions';

import {
  getTeacherGrades,
  getGradeDetail,
  getStudentReports,
  getStatsScales,
  getChildrenReports,
  updateChildDetails,
  getBranchClassrooms,
  getBranchClassroomDetails,
  getStudentProgressClasses,
  getPerformanceStats,
  getPerformanceStatsStudents,
  sendTeacherEmailRequest,
  getRecommendationsRequest,
  getClassroomRecommendations,
  getLiveClassRecommendationsList,
  getStatsScalesByGrade,
} from './api';

function* fetchTeacherGrades({
  payload,
}: ReturnType<typeof fetchGradesAction.request>): SagaIterator<void> {
  try {
    const grades = yield call(getTeacherGrades, payload);
    yield put(fetchGradesAction.success(grades));
  } catch (error) {
    yield put(fetchGradesAction.failure(error));
  }
}

function* fetchGradeDetail({
  payload,
}: ReturnType<typeof fetchGradeDetailAction.request>): SagaIterator<void> {
  try {
    const isSuccess = yield call(
      getGradeDetail,
      payload.gradeId,
      payload.pageIndex,
      payload.name,
      payload.pageSize,
      payload.isALWTeacher,
      payload.classId,
      payload.id,
    );
    yield put(fetchGradeDetailAction.success(isSuccess));
  } catch (error) {
    yield put(fetchGradeDetailAction.failure(error));
  }
}

function* fetchStudentReports({
  payload,
}: ReturnType<typeof studentReportsAction.request>): SagaIterator<void> {
  try {
    const isSuccess = yield call(getStudentReports, payload);
    yield put(studentReportsAction.success(isSuccess));
  } catch (error) {
    yield put(studentReportsAction.failure(error));
  }
}

function* fetchStatsScale(): SagaIterator<void> {
  try {
    const scalesList = yield call(getStatsScales);
    yield put(reportsStatsScaleAction.success(scalesList));
  } catch (error) {
    yield put(reportsStatsScaleAction.failure(error));
  }
}

function* fetchChildrenReports(): SagaIterator<void> {
  try {
    const childrenReports = yield call(getChildrenReports);
    yield put(parentDashboardReportsAction.success(childrenReports));
  } catch (error) {
    yield put(parentDashboardReportsAction.failure(error));
  }
}

function* updateReportsChildDetails(payload: any): SagaIterator<void> {
  const {
    payload: { callBack },
  } = payload;
  try {
    const scalesList = yield call(updateChildDetails, payload);
    callBack();
    yield put(updateReportsChildAction.success(scalesList));
  } catch (error) {
    yield put(updateReportsChildAction.failure(error));
    callBack(error);
  }
}

function* fetchBranchClassrooms({
  payload,
}: ReturnType<typeof fetchClassroomsAction.request>): SagaIterator<void> {
  try {
    const classrooms = yield call(getBranchClassrooms, payload);
    payload.callback?.('success', classrooms);
    yield put(fetchClassroomsAction.success(classrooms));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(fetchClassroomsAction.failure(error));
  }
}

function* fetchClassroomsDetails({
  payload,
}: ReturnType<typeof fetchClassroomDetailsAction.request>): SagaIterator<void> {
  try {
    const classroomDetails = yield call(getBranchClassroomDetails, payload);
    payload.callback?.('success', classroomDetails);
    yield put(fetchClassroomDetailsAction.success(classroomDetails));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(fetchClassroomDetailsAction.failure(error));
  }
}

function* fetchStudentProgressClasses({
  payload,
}: ReturnType<typeof fetchStudentProgressClassesAction.request>): SagaIterator<void> {
  try {
    const classes = yield call(getStudentProgressClasses, payload);
    payload.callback?.('success', classes);
    yield put(fetchStudentProgressClassesAction.success(classes));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(fetchStudentProgressClassesAction.failure(error));
  }
}

function* fetchPerformanceStats({
  payload,
}: ReturnType<typeof fetchPerformanceStatsAction.request>): SagaIterator<void> {
  try {
    const classes = yield call(getPerformanceStats, payload);
    payload.callback?.('success', classes);
    yield put(fetchPerformanceStatsAction.success(classes));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(fetchPerformanceStatsAction.failure(error));
  }
}

function* fetchStatsStudents({
  payload,
}: ReturnType<typeof fetchStatsStudentsListAction.request>): SagaIterator<void> {
  try {
    const classes = yield call(getPerformanceStatsStudents, payload);
    payload.callback?.('success', classes);
    yield put(fetchStatsStudentsListAction.success(classes));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(fetchStatsStudentsListAction.failure(error));
  }
}

function* sendTeacherEmail({
  payload,
}: ReturnType<typeof sendTeacherEmailAction.request>): SagaIterator<void> {
  try {
    const response = yield call(sendTeacherEmailRequest, payload);
    payload.callback?.('success', response);
    yield put(sendTeacherEmailAction.success(response));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(sendTeacherEmailAction.failure(error));
  }
}

function* fetchRecommendations({
  payload,
}: ReturnType<typeof fetchRecommendationClasses.request>): SagaIterator<void> {
  try {
    const classrooms = yield call(getRecommendationsRequest, payload);
    payload.callback?.('success', classrooms);
    yield put(fetchRecommendationClasses.success(classrooms));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(fetchRecommendationClasses.failure(error));
  }
}

function* fetchClassroomRecommendation({
  payload,
}: ReturnType<typeof fetchClassroomRecommendations.request>): SagaIterator<void> {
  try {
    const classrooms = yield call(getClassroomRecommendations, payload);
    payload.callback?.('success', classrooms);
    yield put(fetchClassroomRecommendations.success(classrooms));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(fetchClassroomRecommendations.failure(error));
  }
}

function* fetchLiveClassRecommendationsList({
  payload,
}: ReturnType<typeof fetchLiveClassRecommendations.request>): SagaIterator<void> {
  try {
    const classrooms = yield call(getLiveClassRecommendationsList, payload);
    payload.callback?.('success', classrooms);
    yield put(fetchLiveClassRecommendations.success(classrooms));
  } catch (error) {
    payload.callback?.('error', error);
    yield put(fetchLiveClassRecommendations.failure(error));
  }
}

function* fetchStatsScaleByGrade({
  payload,
}: ReturnType<typeof reportsStatsScaleByGradeAction.request>): SagaIterator<void> {
  try {
    const scalesList = yield call(getStatsScalesByGrade, payload);
    yield put(reportsStatsScaleByGradeAction.success(scalesList));
  } catch (error) {
    yield put(reportsStatsScaleByGradeAction.failure(error));
  }
}

function* reportsSaga(): SagaIterator {
  yield all([
    takeEvery(fetchGradesAction.request, fetchTeacherGrades),
    takeEvery(fetchGradeDetailAction.request, fetchGradeDetail),
    takeEvery(studentReportsAction.request, fetchStudentReports),
    takeEvery(reportsStatsScaleAction.request, fetchStatsScale),
    takeEvery(parentDashboardReportsAction.request, fetchChildrenReports),
    takeEvery(updateReportsChildAction.request, updateReportsChildDetails),
    takeEvery(fetchClassroomsAction.request, fetchBranchClassrooms),
    takeEvery(fetchClassroomDetailsAction.request, fetchClassroomsDetails),
    takeEvery(fetchStudentProgressClassesAction.request, fetchStudentProgressClasses),
    takeEvery(fetchPerformanceStatsAction.request, fetchPerformanceStats),
    takeEvery(fetchStatsStudentsListAction.request, fetchStatsStudents),
    takeEvery(sendTeacherEmailAction.request, sendTeacherEmail),
    takeEvery(fetchRecommendationClasses.request, fetchRecommendations),
    takeEvery(fetchClassroomRecommendations.request, fetchClassroomRecommendation),
    takeEvery(fetchLiveClassRecommendations.request, fetchLiveClassRecommendationsList),
    takeEvery(reportsStatsScaleByGradeAction.request, fetchStatsScaleByGrade),
  ]);
}

export default reportsSaga;
