import { ActionType, StateType, createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';

import { FormType } from 'components/Subscription/types';

import * as actions from './actions';
import { PaymentPlans, Subjects } from './types';
import {
  loadDraftSubscriptionData,
  loadSubscriptionPlans,
  loadSubscriptionSubjects,
  clearDraftSubscriptionData,
} from './actions';

// Define the state shape

const initialSubjectsState = {
  data: { subjects: [] as Subjects[], plans: [] as PaymentPlans[] },
};

export type SubscriptionsActionType = ActionType<typeof actions>;

// Define the reducers
const subjects = combineReducers({
  isLoading: createReducer<boolean, SubscriptionsActionType>(true)
    .handleAction([loadSubscriptionSubjects.success, loadSubscriptionSubjects.failure], () => false)
    .handleAction([loadSubscriptionSubjects.request], () => true),
  data: createReducer<Subjects[], SubscriptionsActionType>(
    initialSubjectsState.data.subjects,
  ).handleAction([loadSubscriptionSubjects.success], (_, action) => action.payload),
  error: createReducer<string, SubscriptionsActionType>('')
    .handleAction([loadSubscriptionSubjects.failure], (_, action) => action.payload)
    .handleAction([loadSubscriptionSubjects.request, loadSubscriptionSubjects.success], () => ''),
});
const plans = combineReducers({
  isLoading: createReducer<boolean, SubscriptionsActionType>(false)
    .handleAction([loadSubscriptionPlans.success, loadSubscriptionPlans.failure], () => false)
    .handleAction([loadSubscriptionPlans.request], () => true),
  data: createReducer<PaymentPlans[], SubscriptionsActionType>(
    initialSubjectsState.data.plans,
  ).handleAction([loadSubscriptionPlans.success], (_, action) => action.payload),
  error: createReducer<string, SubscriptionsActionType>('')
    .handleAction([loadSubscriptionPlans.failure], (_, action) => action.payload)
    .handleAction([loadSubscriptionPlans.request, loadSubscriptionPlans.success], () => ''),
});

const draftedSubscription = combineReducers({
  isLoading: createReducer<boolean, SubscriptionsActionType>(true)
    .handleAction(
      [loadDraftSubscriptionData.success, loadDraftSubscriptionData.failure],
      () => false,
    )
    .handleAction([loadDraftSubscriptionData.request], () => true),
  data: createReducer<FormType, SubscriptionsActionType>({} as unknown as FormType)
    .handleAction([loadDraftSubscriptionData.success], (_, action) => action.payload)
    .handleAction(clearDraftSubscriptionData, () => ({} as unknown as FormType)),
  error: createReducer<string, SubscriptionsActionType>('')
    .handleAction([loadDraftSubscriptionData.failure], (_, action) => action.payload)
    .handleAction([loadDraftSubscriptionData.request, loadDraftSubscriptionData.success], () => ''),
});

const subscriptionsV2Reducer = combineReducers({
  subjects,
  plans,
  draftedSubscription,
});

export type SubscriptionsV2StateType = StateType<typeof subscriptionsV2Reducer>;

export default subscriptionsV2Reducer;
