export type FeedbackType = {
  rating: number;
  type: FeedbackTypes;
  text?: string;
  subjectId?: string;
  lessonId?: string;
  eventId?: string;
};

export type FeedbackRequestType = FeedbackType & {
  callback?: () => void;
};

export enum FeedbackTypes {
  system = 'system',
  mentor = 'mentor',
  content = 'content',
}
