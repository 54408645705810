import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  assignmentResubmission,
  createAssignment,
  createAssignmentGroups,
  deleteAssignmentGroup,
  getAssignmentGroups,
  getStudentAssignments,
  getStudentSubjects,
  getTeacherAssignmentDetail,
  getTeacherAssignments,
  renameAssignmentGroup,
  submitStudentAssignment,
  updateAssignmentDeadline,
  updateStudentAssignmentGroup,
  updateStudentAssignmentScore,
} from './api';
import {
  assignmentResubmissionAction,
  createAssignmentAction,
  createAssignmentGroupsAction,
  deleteAssignmentGroupAction,
  loadAssignmentGroupsAction,
  loadStudentAssignmentsAction,
  loadStudentSubjectsAction,
  loadTeacherAssignmentDetailAction,
  loadTeacherAssignmentsAction,
  renameAssignmentGroupAction,
  submitStudentAssignmentAction,
  updateAssignmentDeadlineAction,
  updateStudentAssignmentGroupAction,
  updateStudentAssignmentScoreAction,
} from './actions';

function* loadTeacherAssignments({
  payload: teacherId,
}: ReturnType<typeof loadTeacherAssignmentsAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getTeacherAssignments, teacherId);
    yield put(loadTeacherAssignmentsAction.success(response));
  } catch (error) {
    yield put(loadTeacherAssignmentsAction.failure(error));
  }
}

function* loadTeacherAssignmentDetail({
  payload,
}: ReturnType<typeof loadTeacherAssignmentDetailAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getTeacherAssignmentDetail, payload);
    yield put(loadTeacherAssignmentDetailAction.success(response));
  } catch (error) {
    yield put(loadTeacherAssignmentDetailAction.failure(error));
  }
}

function* addAssignment({
  payload: { callback, ...payload },
}: ReturnType<typeof createAssignmentAction.request>): SagaIterator<void> {
  try {
    const response = yield call(createAssignment, payload);
    yield put(createAssignmentAction.success(response));
    callback('success');
  } catch (error) {
    yield put(createAssignmentAction.failure(error));
    callback('failure', error);
  }
}

function* updateStudentAssignmentScoreSaga({
  payload: { callback, groupId, ...payload },
}: ReturnType<typeof updateStudentAssignmentScoreAction.request>): SagaIterator<void> {
  try {
    const response = yield call(updateStudentAssignmentScore, payload);
    yield put(
      updateStudentAssignmentScoreAction.success({
        ...response,
        groupId,
        obtainedMarks: payload.score,
      }),
    );
    callback && callback('success');
  } catch (error) {
    yield put(updateStudentAssignmentScoreAction.failure(error));
    callback && callback('failure', error);
  }
}

function* loadStudentSubjects({
  payload,
}: ReturnType<typeof loadStudentSubjectsAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getStudentSubjects, payload);
    yield put(loadStudentSubjectsAction.success(response));
  } catch (error) {
    yield put(loadStudentSubjectsAction.failure(error));
  }
}

function* loadStudentAssignments({
  payload,
}: ReturnType<typeof loadStudentAssignmentsAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getStudentAssignments, payload);
    yield put(loadStudentAssignmentsAction.success(response));
  } catch (error) {
    yield put(loadStudentAssignmentsAction.failure(error));
  }
}

function* submitStudentAssignmentSaga({
  payload: { callback, ...payload },
}: ReturnType<typeof submitStudentAssignmentAction.request>): SagaIterator<void> {
  try {
    const response = yield call(submitStudentAssignment, payload);
    yield put(submitStudentAssignmentAction.success(response));
    callback?.('success');
  } catch (error) {
    yield put(submitStudentAssignmentAction.failure(error));
    callback?.('failure', error);
  }
}

function* loadAssignmentGroups({
  payload,
}: ReturnType<typeof loadAssignmentGroupsAction.request>): SagaIterator<void> {
  try {
    const response = yield call(getAssignmentGroups, payload);
    yield put(loadAssignmentGroupsAction.success(response));
  } catch (error) {
    yield put(loadAssignmentGroupsAction.failure(error));
  }
}

function* createAssignmentGroupsSaga({
  payload: { unassignedStudents, callback, ...payload },
}: ReturnType<typeof createAssignmentGroupsAction.request>): SagaIterator<void> {
  try {
    const groups = yield call(createAssignmentGroups, payload);
    yield put(createAssignmentGroupsAction.success({ unassignedStudents, groups }));
    callback?.('success');
  } catch (error) {
    yield put(createAssignmentGroupsAction.failure(error));
    callback?.('failure', error);
  }
}

function* updateStudentAssignmentGroupSaga({
  payload: { updatedGroupsSettings, callback, ...payload },
}: ReturnType<typeof updateStudentAssignmentGroupAction.request>): SagaIterator<void> {
  try {
    yield call(updateStudentAssignmentGroup, payload);
    if (updatedGroupsSettings)
      yield put(updateStudentAssignmentGroupAction.success(updatedGroupsSettings));
    callback?.('success');
  } catch (error) {
    yield put(updateStudentAssignmentGroupAction.failure(error));
    callback?.('failure', error);
  }
}

function* renameAssignmentGroupSaga({
  payload: { updatedGroupsSettings, callback, ...payload },
}: ReturnType<typeof renameAssignmentGroupAction.request>): SagaIterator<void> {
  try {
    yield call(renameAssignmentGroup, payload);
    if (updatedGroupsSettings)
      yield put(renameAssignmentGroupAction.success(updatedGroupsSettings));
    callback?.('success');
  } catch (error) {
    yield put(renameAssignmentGroupAction.failure(error));
    callback?.('failure', error);
  }
}

function* deleteAssignmentGroupSaga({
  payload: { updatedGroupsSettings, callback, ...payload },
}: ReturnType<typeof deleteAssignmentGroupAction.request>): SagaIterator<void> {
  try {
    yield call(deleteAssignmentGroup, payload);
    if (updatedGroupsSettings)
      yield put(deleteAssignmentGroupAction.success(updatedGroupsSettings));
    callback?.('success');
  } catch (error) {
    yield put(deleteAssignmentGroupAction.failure(error));
    callback?.('failure', error);
  }
}

function* updateAssignmentDeadlineSaga({
  payload: { callback, ...payload },
}: ReturnType<typeof updateAssignmentDeadlineAction.request>): SagaIterator<void> {
  try {
    const response = yield call(updateAssignmentDeadline, payload);
    yield put(updateAssignmentDeadlineAction.success(response));
    callback?.('success');
  } catch (error) {
    yield put(updateAssignmentDeadlineAction.failure(error));
    callback?.('failure', error);
  }
}

function* assignmentResubmissionSaga({
  payload: { callback, ...payload },
}: ReturnType<typeof assignmentResubmissionAction.request>): SagaIterator<void> {
  try {
    yield call(assignmentResubmission, payload);
    yield put(assignmentResubmissionAction.success(payload));
    callback?.('success');
  } catch (error) {
    yield put(assignmentResubmissionAction.failure(error));
    callback?.('failure', error);
  }
}

function* assignmentsSaga(): SagaIterator {
  yield all([takeEvery(loadTeacherAssignmentsAction.request, loadTeacherAssignments)]);
  yield all([takeEvery(loadTeacherAssignmentDetailAction.request, loadTeacherAssignmentDetail)]);
  yield all([takeEvery(createAssignmentAction.request, addAssignment)]);
  yield all([
    takeEvery(updateStudentAssignmentScoreAction.request, updateStudentAssignmentScoreSaga),
  ]);
  yield all([takeEvery(loadStudentSubjectsAction.request, loadStudentSubjects)]);
  yield all([takeEvery(loadStudentAssignmentsAction.request, loadStudentAssignments)]);
  yield all([takeEvery(submitStudentAssignmentAction.request, submitStudentAssignmentSaga)]);
  yield all([takeEvery(loadAssignmentGroupsAction.request, loadAssignmentGroups)]);
  yield all([takeEvery(createAssignmentGroupsAction.request, createAssignmentGroupsSaga)]);
  yield all([takeEvery(renameAssignmentGroupAction.request, renameAssignmentGroupSaga)]);
  yield all([takeEvery(deleteAssignmentGroupAction.request, deleteAssignmentGroupSaga)]);
  yield all([
    takeEvery(updateStudentAssignmentGroupAction.request, updateStudentAssignmentGroupSaga),
  ]);
  yield all([takeEvery(updateAssignmentDeadlineAction.request, updateAssignmentDeadlineSaga)]);
  yield all([takeEvery(assignmentResubmissionAction.request, assignmentResubmissionSaga)]);
}

export default assignmentsSaga;
