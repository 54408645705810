import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { isEmptyObject } from 'utils/helpers';
import {
  loadSubscriptionSubjects,
  loadSubscriptionPlans,
  loadCheckoutId,
  loadDraftSubscriptionData,
} from './actions';
import {
  fetchPlans,
  fetchSubjects,
  loadCheckoutIdRequest,
  loadDraftedSubscriptionRequest,
} from './api';
import { flattenObject, transformUserSubjects } from './adaptor';
import { PaymentPlans, Subjects } from './types';

function* handleLoadSubjects({ payload }: ReturnType<typeof loadSubscriptionSubjects.request>) {
  try {
    const subjects: Subjects[] = yield call(fetchSubjects, payload);
    const newSubject = transformUserSubjects(
      subjects,
      payload.draftedSubjects || [],
      payload.planId,
      payload.draftPlan,
    );
    payload.callback({
      type: 'success',
      data: newSubject.filter((subject) => !!subject.services.some((s) => s.checked)),
    });
    yield put(loadSubscriptionSubjects.success(newSubject));
  } catch (error) {
    yield put(loadSubscriptionSubjects.failure(error));
  }
}

function* loadSubscriptionV2plans() {
  try {
    const plans: PaymentPlans[] = yield call(fetchPlans);
    yield put(loadSubscriptionPlans.success(plans));
  } catch (error) {
    yield put(loadSubscriptionPlans.failure(error));
  }
}

function* handleLoadCheckoutId({
  payload,
}: ReturnType<typeof loadCheckoutId.request>): SagaIterator<void> {
  try {
    const { checkoutId } = yield call(loadCheckoutIdRequest, payload);
    payload.callback?.({ type: 'success', data: checkoutId });
    yield put(loadCheckoutId.success(checkoutId));
  } catch (error) {
    const err: string[] = flattenObject(error);
    payload.callback?.({ type: 'error', error: isEmptyObject(err) ? error : Object.values(err) });
    yield put(loadCheckoutId.failure(error));
  }
}

function* handleDrafterSubscription({
  payload,
}: ReturnType<typeof loadDraftSubscriptionData.request>): SagaIterator<void> {
  try {
    const response = yield call(
      loadDraftedSubscriptionRequest,
      payload.shouldPaidOnly,
      payload.shouldUpgradePlan,
      payload.userId,
      payload.isStudent,
    );
    yield put(loadDraftSubscriptionData.success(response));
  } catch (error) {
    yield put(loadDraftSubscriptionData.failure(error));
  }
}

function* watchLoadData() {
  yield takeEvery(loadSubscriptionSubjects.request, handleLoadSubjects);
  yield takeEvery(loadCheckoutId.request, handleLoadCheckoutId);
  yield takeEvery(loadSubscriptionPlans.request, loadSubscriptionV2plans);
  yield takeEvery(loadDraftSubscriptionData.request, handleDrafterSubscription);
}

export default watchLoadData;
