import { createAsyncAction, createAction } from 'typesafe-actions';
import { LessonType } from './types';

export const loadLessonsAction = createAsyncAction(
  '@@lessons/LOAD_REQUEST',
  '@@lessons/LOAD_SUCCESS',
  '@@lessons/LOAD_FAILURE',
)<string, LessonType[], string>();

export const resetLessonsAction = createAction('@@lessons/RESET')();
