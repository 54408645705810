import { getLocale, LOCALES } from 'lang';
import { createIntl, createIntlCache, MessageFormatElement } from 'react-intl';

const cache = createIntlCache();

/**
 * Returns an Intl Object which can be used to access react-intl methods outside react components
 * @param {Record<string, string> | Record<string, MessageFormatElement[]>} messages optional Massages object
 * @return {IntlShape} intl object that provides access to all react intl methods
 */

export const getIntl = (
  messages?: Record<string, string> | Record<string, MessageFormatElement[]>,
) => {
  const locale = getLocale(document.documentElement.lang);
  const msgs = messages || LOCALES[locale].messages;
  const intl = createIntl({ locale, messages: msgs }, cache);
  return intl;
};
