import { combineReducers } from 'redux';
import { ActionType, createReducer, StateType } from 'typesafe-actions';
import * as actions from './actions';
import { loadBadgesAction, resetBadgesAction } from './actions';
import { BadgesType } from './types';

type BadgesActionsType = ActionType<typeof actions>;

const data = createReducer<BadgesType[], BadgesActionsType>([])
  .handleAction(loadBadgesAction.success, (state, { payload }) => payload)
  .handleAction(resetBadgesAction, () => []);

const fetching = combineReducers({
  load: createReducer<boolean, BadgesActionsType>(true)
    .handleAction(loadBadgesAction.request, () => true)
    .handleAction([loadBadgesAction.success, loadBadgesAction.failure], () => false)
    .handleAction(resetBadgesAction, () => true),
});

const errors = combineReducers({
  load: createReducer<string, BadgesActionsType>('')
    .handleAction(loadBadgesAction.failure, (state, { payload }) => payload)
    .handleAction(loadBadgesAction.success, () => '')
    .handleAction(resetBadgesAction, () => ''),
});

const badgesReducer = combineReducers({
  data,
  fetching,
  errors,
});

export type BadgesStateType = StateType<typeof badgesReducer>;

export default badgesReducer;
