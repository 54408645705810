import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { StudentType, SchoolUserListResponse } from './types';
import { loadSchoolStudents } from './actions';
import * as actions from './actions';

export type SchoolStudestType = ActionType<typeof actions>;

const isLoading = createReducer<boolean, SchoolStudestType>(true)
  .handleAction(loadSchoolStudents.request, () => true)
  .handleAction([loadSchoolStudents.success, loadSchoolStudents.failure], () => false);

const data = createReducer<SchoolUserListResponse, SchoolStudestType>({
  results: [] as StudentType[],
} as SchoolUserListResponse).handleAction(
  loadSchoolStudents.success,
  (state, action) => action.payload,
);
const errors = createReducer<any, SchoolStudestType>({})
  .handleAction(loadSchoolStudents.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(loadSchoolStudents.success, (state) => ({
    ...state,
    error: '',
  }));

const schoolStudentsReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export type SchoolStudentsStateType = StateType<typeof schoolStudentsReducer>;

export default schoolStudentsReducer;
