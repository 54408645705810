import { createReducer, ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { branchStats } from './types';
import { loadSchoolBranchStats } from './actions';

export type SchoolBranchesType = ActionType<typeof loadSchoolBranchStats>;

const isLoading = createReducer<boolean, SchoolBranchesType>(true)
  .handleAction(loadSchoolBranchStats.request, () => true)
  .handleAction([loadSchoolBranchStats.success, loadSchoolBranchStats.failure], () => false);

const data = createReducer<branchStats, SchoolBranchesType>({} as branchStats).handleAction(
  loadSchoolBranchStats.success,
  (state, action) => action.payload,
);

const errors = createReducer<any, SchoolBranchesType>({})
  .handleAction(loadSchoolBranchStats.failure, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
  .handleAction(loadSchoolBranchStats.success, (state) => ({
    ...state,
    loading: '',
  }));

const schoolBranchStatReducer = combineReducers({
  isLoading,
  data,
  errors,
});

export type SchoolBranchStatStateType = StateType<typeof schoolBranchStatReducer>;

export default schoolBranchStatReducer;
