import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { loadAvatarsAction } from './actions';
import { getAvatars } from './api';
import { AvatarsResponseType } from './types';

function* loadAvatarsRequest(): SagaIterator<void> {
  try {
    const rewardStats: AvatarsResponseType = yield call(getAvatars);
    yield put(loadAvatarsAction.success(rewardStats));
  } catch (error) {
    yield put(loadAvatarsAction.failure(error as string));
  }
}
function* rewardsSaga(): SagaIterator {
  yield all([takeEvery(loadAvatarsAction.request, loadAvatarsRequest)]);
}

export default rewardsSaga;
