import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { IconCommon } from 'lib';
import { ROUTES } from 'constants/routes';

import { disabledServicesSelector, isB2bStudentRoleSelector } from 'store/account/selectors';

import { getStudentMenu, MenuItemType } from 'modules/GradeOneToThree/utils/getStudentMenu';
import { Root, NavbarList, NavbarListItem } from './elements';

import styles from './Navbar.module.scss';

const Navbars: FunctionComponent = () => {
  const isB2BStudent = useSelector(isB2bStudentRoleSelector);
  const disabledServices = useSelector(disabledServicesSelector);

  const menu: MenuItemType[] = getStudentMenu(false, isB2BStudent, disabledServices);

  return (
    <Root id="navbar">
      <NavbarList>
        {menu.map(({ id, label, path = '/not-found', disabled }: MenuItemType) => {
          return (
            <NavLink
              key={id}
              to={path}
              id={id}
              exact={path === ROUTES.root}
              className={cn(styles.navLink, {
                [styles.disabled]: disabled,
              })}
              activeClassName={styles.active}
            >
              <NavbarListItem>
                <span>{label}</span>
                {disabled && (
                  <IconCommon className="lock" materialIconType="outlined" isMaterialIcon />
                )}
              </NavbarListItem>
            </NavLink>
          );
        })}
      </NavbarList>
    </Root>
  );
};

export default Navbars;
