import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { loadScheduleForTodayAction } from './actions';
import { getScheduleForToday } from './api';

function* loadScheduleForToday({
  payload,
}: ReturnType<typeof loadScheduleForTodayAction.request>): SagaIterator<void> {
  try {
    const lessons = yield call(
      getScheduleForToday,
      payload.startDate,
      payload.endDate,
      payload.username,
    );
    yield put(loadScheduleForTodayAction.success(lessons));
  } catch (error) {
    yield put(loadScheduleForTodayAction.failure(error));
  }
}

export default function* scheduleForTodaySaga(): SagaIterator<void> {
  yield all([takeEvery(loadScheduleForTodayAction.request, loadScheduleForToday)]);
}
