import React, { FunctionComponent, ReactNode } from 'react';
import cn from 'classnames';
import styles from './Icon.module.scss';

export type IconProps = {
  title?: string;
  children: ReactNode;
  className?: string;
  ariaLabel?: string;
  ariaHidden?: boolean;
  disabled?: boolean;
  disableMirror?: boolean;
};

export const SvgIcon: FunctionComponent<IconProps> = ({
  title,
  children,
  className,
  ariaLabel,
  ariaHidden,
  disabled = false,
  disableMirror = false,
}: IconProps) => (
  <span
    title={title}
    className={cn(className, styles.icon, {
      [styles.disable]: disabled,
      [styles.flipped]: !disableMirror,
    })}
    aria-label={ariaLabel}
    aria-hidden={ariaHidden}
    role="img"
  >
    <title>{title}</title>
    {children}
  </span>
);
