import { AdminRole, Role, SchoolRole, summerRole } from '../store/account/types';

export const schoolRoles = [
  SchoolRole.schoolAdmin,
  SchoolRole.schoolTeacher,
  SchoolRole.schoolBranchManager,
  SchoolRole.schoolStudent,
];
export const summerRoles = [summerRole.Student, summerRole.Teacher];
export const alwRoles = [
  Role.student,
  Role.mentor,
  Role.parent,
  Role.alwTeacher,
  Role.teacherAndMentor,
  Role.contentAuthor,
];

export const adminRoles = [AdminRole.alwAdmin];
