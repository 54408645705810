import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Lang } from 'lang';

import styles from './RegisterationHelp.module.scss';

const RegistrationHelp: FunctionComponent = () => {
  const { formatMessage, locale } = useIntl();
  const isArabic = locale === Lang.ar;

  const video1Title = formatMessage({
    id: 'registration.help.video1',
    defaultMessage: 'Work Online',
  });
  const video2Title = formatMessage({
    id: 'registration.help.video2',
    defaultMessage: 'Electric Charge',
  });

  const video3Title = formatMessage({
    id: 'registration.help.video3',
    defaultMessage: 'Family',
  });

  return (
    <div className={styles.registerHelp}>
      <h1 className={styles.registerHelp__title}>
        {formatMessage({
          id: 'registration.help.title',
          defaultMessage: 'Vast Library of Content on-demand, Quizzes,',
        })}
        <span className="inlineFlex">
          {formatMessage({
            id: 'registration.help.title.end',
            defaultMessage: 'and Exams',
          })}
        </span>
      </h1>
      <p className={styles.registerHelp__subtitle}>
        {formatMessage({
          id: 'registration.help.description',
          defaultMessage:
            'What we learn with pleasure, we never forget. With thousands of recorded videos with rich animations covering every topic, learning is both effective and fun at the same time.',
        })}
      </p>
      <div className={styles.row}>
        <div className="col-md-4">
          <div className={styles.registerHelp__block}>
            <div className={styles.registerHelp__iframe}>
              <iframe
                title={video1Title}
                width="640"
                height="360"
                allowFullScreen
                src={`https://www.youtube.com/embed/${isArabic ? '50ankUceZcE' : 'VysC5rRyLyU'}`}
              />
            </div>
            <h4 className={styles.registerHelp__iframe__title}>{video1Title}</h4>
          </div>
        </div>
        <div className="col-md-4">
          <div className={styles.registerHelp__block}>
            <div className={styles.registerHelp__iframe}>
              <iframe
                title={video2Title}
                width="640"
                height="360"
                allowFullScreen
                src={`https://www.youtube.com/embed/${isArabic ? '2PAtNJPLLFE' : 'ptBkwcLMi0Y'}`}
              />
            </div>
            <h4 className={styles.registerHelp__iframe__title}>{video2Title}</h4>
          </div>
        </div>
        <div className="col-md-4">
          <div className={styles.registerHelp__block}>
            <div className={styles.registerHelp__iframe}>
              <iframe
                title={video3Title}
                width="640"
                height="360"
                allowFullScreen
                src={`https://www.youtube.com/embed/${isArabic ? 'fIpPjj7Famc' : 'n79DIYLCCBk'}`}
              />
            </div>
            <h4 className={styles.registerHelp__iframe__title}>{video3Title}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationHelp;
