import { lessonsType, subjectsType, subject } from './types';

export const subjectsAdapter = (subjects: Array<subject>): Array<subjectsType> => {
  return subjects.map((subject) => {
    const { display_name: displayName, units, course_id: courseId } = subject;
    return {
      courseId,
      displayName,
      units,
      totalUnits: units.length || 0,
      totalLessons:
        units.reduce((acc: number, current: lessonsType) => current?.lessons?.length + acc, 0) || 0,
    };
  });
};

export const subjectsClassesAdapter = (classesData: any) => {
  return Object.keys(classesData)?.map((classKey: string) => {
    const subjects = classesData[classKey];
    const [subject] = subjects || [];
    return {
      className: classKey,
      classId: subject?.gradeId,
      subjects: subjects?.map((subjectData: any) => {
        const { displayName, units, numOfUnits, questionBankId, numOfLessons, allowMathsKeyboard } =
          subjectData || {};
        return {
          courseId: questionBankId,
          displayName,
          units,
          totalUnits: numOfUnits,
          totalLessons: numOfLessons,
          allowMathsKeyboard,
        };
      }),
    };
  });
};
