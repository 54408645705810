import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  invalidFileType: {
    id: 'invalid-file-type',
    defaultMessage: 'Invalid file type',
  },
  maxFileSize: {
    id: 'max-file-size',
    defaultMessage: 'Max allowed file size is {filesize}',
  },
  assignedGrade: {
    id: 'assign.teachers.assigned.grade',
    defaultMessage: 'Grade { grade }',
  },
});
